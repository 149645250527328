import * as React from "react";
import moment from 'moment';

const CustomDateTimeField = ({record = {}, source}) =>
    <span>
        {record[source] ? moment(record[source]).format('YYYY-MM-DD') : ''}
    </span>;

CustomDateTimeField.defaultProps = {
    addLabel: true,
};

export default CustomDateTimeField;