import * as React from "react";
import {
    DeleteButton,
    Edit,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    SimpleShowLayout
} from 'react-admin';
import DeleteWithCustomConfirmButton from 'ra-delete-with-custom-confirm-button';
import Delete from '@material-ui/icons/Delete';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import {makeStyles} from '@material-ui/core/styles';
import checkPermission from "../../helpers/permission_handler";
import CustomAside from "../CustomAside/CustomAside";

const PageTitle = ({record}) => {
    return <span>User Types - Edit User Type {record ? `${record.name}` : ''}</span>;
};

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    name: {display: 'inline-block'},
    type: {display: 'inline-block', marginLeft: 32},
});

// Define your custom contents of confirm dialog
const DeleteConfirmContent = props => {
    return (
        <SimpleShowLayout {...props} />
    );
};

const CustomToolbar = ({permissions, ...props}) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton/>
        {permissions && checkPermission(permissions, {adminUserRole: ['delete']}) &&
        <DeleteWithCustomConfirmButton
            title={"Are you sure?"}
            content={DeleteConfirmContent}
            label='Delete'                  // label of delete button (default: 'Delete')
            confirmColor='warning'          // color of delete button ('warning' or 'primary', default: 'warning')
            ConfirmIcon={Delete}            // icon of delete button (default: 'Delete')
            cancel='Cancel'                 // label of cancel button (default: 'Cancel')
            CancelIcon={ErrorOutline}       // icon of cancel button (default: 'ErrorOutline')
            undoable={false}/>}
    </Toolbar>
);

export const TypeEdit = ({permissions, ...props}) => {
    const classes = useStyles(props);

    return (
        <Edit {...props} aside={<CustomAside {...props}/>} title={<PageTitle/>}>
            <SimpleForm
                toolbar={<CustomToolbar permissions={permissions}/>}
                variant="outlined"
                redirect={'/types'}
            >
                <TextInput
                    source="name"
                    validate={[required()]}
                    formClassName={classes.name}
                />
            </SimpleForm>
        </Edit>
    );
};