import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";

const palette = createPalette(
    merge({}, defaultTheme.palette, {
        primary: {
            main: "#1bb934", // Not far from red
        },
        secondary: {
            main: "#00ba00", // Not far from green
        },
    })
);

const rawTheme = {
    shape: {
        borderRadius: 10,
    },
    overrides: {
        RaLayout: {
            content: {
                paddingLeft: '260px !important',
                background: '#fafafa'
            }
        },
        RaMenuItemLink: {
            root: {
                color: '#fff',
                // borderLeft: '3px solid #fff',
            },
            active: {
                color: '#fff',
                borderLeft: '3px solid #1bb934',
                background: '#1bb934'
            },
        },
        MuiListItemIcon: {
            root: {
                color: '#fff'
            }
        },
        MuiListItem: {
          button: {
              '&:hover': {
                  background: '#1bb934 !important'
              }
          }
        },
        RaSidebar: {
            color:'#fff',
            drawerPaper: {
                backgroundColor: '#222d32',
                color:'#fff',
                height: "100%",
                boxShadow:
                    "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
            },
            paper: {
                position: 'fixed !important',
                zIndex: '999 !important',
                paddingTop: '4%'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#4f3cc9',
                boxShadow: 'none',
            },
        },
        MuiAppBar: {
            root: {
                border: '#fff',
            },
            colorSecondary: {
                color: '#fff',
                backgroundColor: '#1bb934',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiTableRow: {
            root: {
                '&:hover': {
                    background: 'rgb(27 185 52 / 20%) !important'
                }
            },
            head: {
                backgroundColor: 'black',
                "& > th ": {
                    // background: 'antiquewhite',
                    background: 'black',
                    color:"white",
                },
                
            },
        },
        RaLogout: {
            icon: {
                color: '#1bb934'
            }
        }
    },
    palette,
};

export const theme = createMuiTheme(
    merge({}, defaultTheme, rawTheme)
);
