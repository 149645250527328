import * as React from "react";
import {
    AutocompleteInput,
    BooleanInput, DateInput,
    DeleteButton,
    Edit,
    email, FileField, FileInput, ImageField, ImageInput, maxLength,
    minLength, NumberInput, PasswordInput,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    ArrayField,
    SingleFieldList,
    Datagrid,
    UrlField, useDataProvider,
    SimpleShowLayout
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import DeleteWithCustomConfirmButton from 'ra-delete-with-custom-confirm-button';
import Delete from '@material-ui/icons/Delete';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import checkPermission from "../../helpers/permission_handler";
import CustomAside from "../CustomAside/CustomAside";
import {SUBURBS} from "../../constants/suburbs";
import RichTextInput from "ra-input-rich-text";
import {useFormState} from "react-final-form";
import {Typography} from '@material-ui/core';
import CustomUrlField from "../CustomFields/CustomUrlField";
import {useState} from "react";
import {useEffect} from "react";
import {FILESTATUS} from "../../constants/fileStatus";
import {USERTYPES} from "../../constants/userTypes";

const SectionTitle = ({label}) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

const PageTitle = ({record}) => {
    return <span>Files - Edit File {record ? `${record.referencesNo}` : ''}</span>;
};

export const styles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    inline: {display: 'inline-block', margin: '0 16px'},
    uploadSection: {
        background: 'aliceblue',
        borderRadius: '12px',
        border: '1px dashed darkcyan',
        display: 'inline-block',
        margin: '0 16px'
    }
};

const useStyles = makeStyles(styles);

const PostalCodeInput = props => {
    const { values } = useFormState();
    let choices;
    if (values.clientSuburb) {
        SUBURBS.forEach((item) => {
            if (values.clientSuburb === item.suburb) {
                choices = item
            }
        })
    }

    return (
        <TextInput
            initialValue={choices ? choices.postcode : undefined}
            {...props}
        />
    );
};

const StateInput = props => {
    const { values } = useFormState();
    let choices;
    if (values.clientSuburb) {
        SUBURBS.forEach((item) => {
            if (values.clientSuburb === item.suburb) {
                choices = item
            }
        })
    }

    return (
        <TextInput
            initialValue={choices ? choices.state : undefined}
            {...props}
        />
    );
};

const OptionTextField = ({ record }) => {
    let rate = '';
    if (record.commissionPercentage) {
        rate += `Commission - ${record.commissionPercentage}%`
    }
    if (record.flatRate) {
        rate += `Flat Rate - ${record.flatRate}`
    }
    return (<span>{record.firstName} ({rate})</span>);
};

const AssignInput = props => {
    const {values} = useFormState();
    let obj = {};
    if (values.suburb) {
        obj.suburb = values.suburb
    }
    if (values.type && props.roleList) {
        let lst = [];
        const data = props.roleList;
        data.forEach(item => {
            if (values.type === item.type) {
                lst.push(item.id);
            }
        });
        obj.roleId = lst;
    }
    return (
        <ReferenceInput
            filter={obj}
            {...props}
            style={{ width: '400px' }}
        >
            <AssignSelectInput optionText={<OptionTextField/>} />
        </ReferenceInput>
    );
};

const AssignSelectInput = props => {
    const {values} = useFormState();
    let choices = [];
    if (props.choices && props.choices.length > 0) {
        props.choices.forEach(item => {
            if (values.type && item.role && item.role.type !== values.type) {
                values.assignee = undefined;
            } else {
                choices.push(item);
            }
        })
    }

    return <SelectInput {...props} choices={choices} />
}

// Define your custom contents of confirm dialog
const DeleteConfirmContent = props => {
    return (
        <SimpleShowLayout {...props} />
    );
};

const CustomToolbar = ({permissions, ...props}) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton/>
        {permissions && checkPermission(permissions, {adminFile: ['delete']}) &&
        <DeleteWithCustomConfirmButton
            title={"Are you sure?"}
            content={DeleteConfirmContent}
            label='Delete'                  // label of delete button (default: 'Delete')
            confirmColor='warning'          // color of delete button ('warning' or 'primary', default: 'warning')
            ConfirmIcon={Delete}            // icon of delete button (default: 'Delete')
            cancel='Cancel'                 // label of cancel button (default: 'Cancel')
            CancelIcon={ErrorOutline}       // icon of cancel button (default: 'ErrorOutline')
            undoable={false}/>}
    </Toolbar>
);

export const FileEdit = ({permissions, ...props}) => {
    const classes = useStyles(props);
    const dataProvider = useDataProvider();
    const [listRole, setListRole] = useState();
    const [loading, setLoading] = useState(true);
    const [ error,setError] = useState();

    useEffect(() => {
        dataProvider.getList('roles', {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'id', order: 'ASC'},

        }).then(({data}) => {
            setListRole(data);
            setLoading(false);
        })
            .catch(error => {
                console.log(error);
                setError(error);
                setLoading(false);
            })
    }, []);
    return (
        <Edit {...props} aside={<CustomAside {...props}/>} title={<PageTitle/>} undoable={false}>
            <SimpleForm toolbar={<CustomToolbar permissions={permissions}/>} variant="outlined">
                <SectionTitle label="Basic Details"/>
                <TextInput source="clientName" validate={[required()]} formClassName={classes.inline}/>
                <NumberInput source="value" validate={[required()]} step={1} formClassName={classes.inline}/>
                <TextInput multiline source="note" formClassName={classes.inline}/>
                <SelectInput
                    source="status"
                    choices={[
                        {id: FILESTATUS.PENDING, name: FILESTATUS.PENDING},
                        {id: FILESTATUS.ACCEPTED, name: FILESTATUS.ACCEPTED},
                        {id: FILESTATUS.DECLINED, name: FILESTATUS.DECLINED},
                        {id: FILESTATUS.PAID, name: FILESTATUS.PAID},
                        {id: FILESTATUS.SETTLED, name: FILESTATUS.SETTLED},
                    ]}
                    validate={[required()]}
                    formClassName={classes.inline}
                />
                <DateInput source="assigneeAcceptedAt" label="Date Accepted/Declined" formClassName={classes.inline}/>

                <SectionTitle label="Contact Details"/>

                <TextInput source="clientEmail" validate={[required(), email()]} formClassName={classes.inline}/>
                <TextInput source="clientPhoneNumber" validate={[required(), minLength(10), maxLength(12)]} formClassName={classes.inline}/>
                <TextInput multiline source="clientAddress" formClassName={classes.inline}/>
                <AutocompleteInput
                    source="clientSuburb"
                    optionText="suburb"
                    resettable={true}
                    clearAlwaysVisible={true}
                    suggestionLimit={10}
                    shouldRenderSuggestions={(val) => { return val.trim().length > 3 }}
                    optionValue="suburb"
                    choices={SUBURBS}
                    formClassName={classes.inline}
                />
                <PostalCodeInput source="clientPostalCode" formClassName={classes.inline}/>
                <StateInput source="clientState" formClassName={classes.inline}/>

                <SectionTitle label="Referred to"/>

                <ReferenceInput
                    source="type"
                    reference="types"
                    label="Type"
                    formClassName={classes.inline}
                ><SelectInput optionText="name"/>
                </ReferenceInput>
                <AutocompleteInput
                    source="assigneeSuburb"
                    label="Referrer suburb"
                    optionText="suburb"
                    resettable={true}
                    clearAlwaysVisible={true}
                    suggestionLimit={10}
                    shouldRenderSuggestions={(val) => { return val.trim().length > 3 }}
                    optionValue="suburb"
                    choices={SUBURBS}
                    formClassName={classes.inline}
                />
                <AssignInput source="assignee" label="Referrer" reference="users" roleList={listRole} formClassName={classes.inline}/>
                <TextInput multiline source="assigneeNote" label="Referrer note" formClassName={classes.inline}/>

                <SectionTitle label="Files"/>
                <FileInput source="files" label="Related files" formClassName={classes.uploadSection} multiple={true}>
                    <FileField source="src" title="title" />
                </FileInput>
                <ArrayField source="file_uploads" label="">
                    <Datagrid>
                        <CustomUrlField label="Related files" source="filePath" fileName="fileName" />
                    </Datagrid>
                </ArrayField>
            </SimpleForm>
        </Edit>
    );
};