import * as React from "react";
import {
    AutocompleteInput,
    BulkDeleteButton, BulkExportButton,
    Datagrid,
    EditButton,
    EmailField,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ShowButton,
    TextField,
    TextInput,
    downloadCSV
} from 'react-admin';
import checkPermission from "../../helpers/permission_handler";
import CustomDateTimeField from '../CustomFields/CustomDateTimeField'
import {SUBURBS} from "../../constants/suburbs";
import moment from 'moment';
import jsonExport from 'jsonexport/dist';
const exporter = posts => {
    const postsForExport = posts.map(post => {
        return {
            username: post.username,
            firstName: post.firstName,
            lastName: post.lastName,
            email: post.email,
            phoneNumber: post.phoneNumber,
            suburb: post.suburb,
            flatRate: post.flatRate,
            commissionPercentage: post.commissionPercentage,
            role: post.role ? post.role.name : undefined,
            createdAt: post.createdAt ? moment(post.createdAt).format('YYYY-MM-DD') : undefined,
        }
    });
    jsonExport(postsForExport, {
        headers: [] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'users'); // download as 'posts.csv` file
    });
};

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput source="firstName" alwaysOn variant="outlined" label="First Name"/>
        <TextInput source="lastName" alwaysOn variant="outlined" label="Last Name"/>
        <TextInput source="email" alwaysOn variant="outlined"/>
        <ReferenceInput source="roleId" reference="roles" allowEmpty alwaysOn variant="outlined">
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <TextInput source="username" variant="outlined"/>
        <TextInput source="phoneNumber" variant="outlined" label="Phone Number"/>
        <AutocompleteInput
            source="suburb"
            optionText="suburb"
            suggestionLimit={10}
            shouldRenderSuggestions={(val) => { return val.trim().length > 3 }}
            optionValue="suburb"
            choices={SUBURBS}
            variant="outlined"
        />
    </Filter>
);

const PostBulkActionButtons = props => (
    <React.Fragment>
        <BulkExportButton />
        <BulkDeleteButton {...props} undoable={false}/>
    </React.Fragment>
);

export const ReferralList = ({permissions, ...props}) => (
    <List {...props}
          title="User Manager"
          filters={<UserFilter/>}
          bulkActionButtons={permissions && checkPermission(permissions, {adminUser: ['delete']}) ?
              <PostBulkActionButtons/> : false}
          sort={{ field: 'createdAt', order: 'DESC' }}
          exporter={exporter}
    >
        <Datagrid
            rowClick={permissions && checkPermission(permissions, {adminUser: ['edit']}) ? 'edit' : 'show'}>
            <TextField source="username"/>
            <TextField source="firstName" label="First Name"/>
            <TextField source="lastName" label="Last Name"/>
            <EmailField source="email"/>
            <TextField source="phoneNumber" label="Phone Number"/>
            <TextField source="suburb"/>
            <TextField source="flatRate" label="Flat Rate"/>
            <TextField source="commissionPercentage" label="Commission Percentage(%)"/>
            <ReferenceField source="roleId" reference="roles" label="User Type" linkType="">
                <TextField source="name"/>
            </ReferenceField>
            <CustomDateTimeField source="createdAt" label="Created At"/>
            {permissions && checkPermission(permissions, {adminUser: ['edit']}) ? <EditButton/> :
                <ShowButton/>}
        </Datagrid>
    </List>
);