import simpleRestProvider from 'ra-data-simple-rest';
import {fetchUtils} from "react-admin";
import {config} from "./config/config";

const httpClient = (url, options = {}) => {
    options.headers = new Headers({Accept: 'application/json'});
    const token = localStorage.getItem('addon_token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const simpleDataProvider = simpleRestProvider(config.apiUrl, httpClient);

const dataProvider = {
    ...simpleDataProvider,
    create: (resource, params) => {
        if (resource === 'users') {
            if (!params.data.avatar && !params.data.files) {
                // fallback to the default implementation
                return simpleDataProvider.create(resource, params);
            } else {
                const formData  = new FormData();

                for(const name in params.data) {
                    if (name === 'files') {
                        params.data.files.forEach((item) => {
                            formData.append(`files`, item.rawFile);
                        });
                    } else if (name === 'avatar') {
                        formData.append(name, params.data[name].rawFile);
                    } else {
                        formData.append(name, params.data[name]);
                    }
                }

                return uploadFile(resource, formData)
            }
        } else if (resource === 'files' || resource === 'outgoings' || resource === 'incomings' ||  resource === 'referrals') {
            if (!params.data.files) {
                // fallback to the default implementation
                console.log("%%%%%%%%%%% resource ",resource)
                console.log("%%%%%%%%%%% params ", params)
                return simpleDataProvider.create(resource, params);
            } else {
                const formData  = new FormData();

                for(const name in params.data) {
                    if (name === 'files') {
                        params.data.files.forEach((item) => {
                            formData.append(`files`, item.rawFile);
                        });
                    } else {
                        formData.append(name, params.data[name]);
                    }
                }

                return uploadFile(resource, formData)
            }
        } else {
            return simpleDataProvider.create(resource, params);
        }
    },
    update: (resource, params) => {
        if (resource === 'users') {
            if (!params.data.avatar && !params.data.files) {
                // fallback to the default implementation
                return simpleDataProvider.update(resource, params);
            } else {
                const formData  = new FormData();

                for(const name in params.data) {
                    if (name === 'files') {
                        params.data.files.forEach((item) => {
                            formData.append(`files`, item.rawFile);
                        });
                    } else if (name === 'avatar') {
                        formData.append(name, params.data[name].rawFile);
                    } else {
                        formData.append(name, params.data[name]);
                    }
                }

                return uploadFileUpdate(resource, formData, params.id)
            }
        } else if (resource === 'files' || resource === 'outgoings' || resource === 'incomings' || resource === 'referrals') {
            if (!params.data.files) {
                // fallback to the default implementation
                return simpleDataProvider.update(resource, params);
            } else {
                const formData  = new FormData();

                for(const name in params.data) {
                    if (name === 'files') {
                        params.data.files.forEach((item) => {
                            formData.append(`files`, item.rawFile);
                        });
                    } else {
                        formData.append(name, params.data[name]);
                    }
                }

                return uploadFileUpdate(resource, formData, params.id)
            }
        } else {
            return simpleDataProvider.update(resource, params);
        }
    },
};

const uploadFile = (resource, params) => {
    return new Promise((resolve, reject) => {
        httpClient(`${config.apiUrl}/${resource}`, {
            method: 'POST',
            body: params,
        }).then(({ json }) => {
            console.log({data: json});
            return (resolve({data: json}))
        }).catch(err => {
            console.log(err);
            return reject(err);
        });
    });
};

const uploadFileUpdate = (resource, params, id) => {

    return new Promise((resolve, reject) => {
        httpClient(`${config.apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: params,
        }).then(({ json }) => {
            console.log({ data: json });
            return (resolve({ data: json }))
        }).catch(err => {
            console.log(err);
            return reject(err);
        });
    });
};

export default dataProvider;