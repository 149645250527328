import * as React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DeleteButton,
  BulkDeleteButton,
  BulkExportButton,
  SimpleShowLayout,
  TextField,
  CheckboxGroupInput,
  BooleanField,
  Edit,
  email,
  Show,
  TabbedShowLayout,
  Tab,
  FileField,
  RichTextField,
  FunctionField,
  FileInput,
  ImageField,
  ImageInput,
  maxLength,
  minLength,
  NumberInput,
  PasswordInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Filter,
  TextInput,
  Toolbar,
  ArrayField,
  SingleFieldList,
  Datagrid,
  UrlField,
  List,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import checkPermission from "../../helpers/permission_handler";
import CustomAside from "../CustomAside/CustomAside";
import { SUBURBS } from "../../constants/suburbs";
import RichTextInput from "ra-input-rich-text";
import { useFormState } from "react-final-form";
import {
  Typography,
  Grid,
  GridListTileBar,
  IconButton,
} from "@material-ui/core";
import CustomUrlField from "../CustomFields/CustomUrlField";
import { functions } from "lodash";
const _ = require("lodash");

const SectionTitle = ({ label }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

const PageTitle = ({ record }) => {
  return (
    <span>Users Manager - Edit User {record ? `${record.username}` : ""}</span>
  );
};

export const styles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  inline: { display: "inline-block", margin: "0 16px" },
  uploadSection: {
    background: "aliceblue",
    borderRadius: "12px",
    border: "1px dashed darkcyan",
    display: "inline-block",
    margin: "0 16px",
  },
  tabsContainer: {
    flexGrow: 1,
    paddingBottom: "1vw",
  },

  tabs: {
    minWidth: "50%",
  },
  editFormContainer: {
    border: "1px solid rgba(0,0,0,0.0)",
  },
  editReferralContainer: {},

  datagridContainer: {
    minWidth: "90vw",
    Width: "90%",
  },
};

const useStyles = makeStyles(styles);

const PostalCodeInput = (props) => {
  const { values } = useFormState();
  let choices;
  if (values.suburb) {
    SUBURBS.forEach((item) => {
      if (values.suburb === item.suburb) {
        choices = item;
      }
    });
  }

  return (
    <TextInput
      initialValue={choices ? choices.postcode : undefined}
      validate={[required()]}
      {...props}
    />
  );
};

const StateInput = (props) => {
  const { values } = useFormState();
  let choices;
  if (values.suburb) {
    SUBURBS.forEach((item) => {
      if (values.suburb === item.suburb) {
        choices = item;
      }
    });
  }

  return (
    <TextInput
      initialValue={choices ? choices.state : undefined}
      validate={[required()]}
      {...props}
    />
  );
};

// Define your custom contents of confirm dialog
const DeleteConfirmContent = (props) => {
  return <SimpleShowLayout {...props} />;
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    {permissions && checkPermission(permissions, { adminUser: ["delete"] }) && (
      <DeleteWithCustomConfirmButton
        title={"Are you sure?"}
        content={DeleteConfirmContent}
        label="Delete" // label of delete button (default: 'Delete')
        confirmColor="warning" // color of delete button ('warning' or 'primary', default: 'warning')
        ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
        cancel="Cancel" // label of cancel button (default: 'Cancel')
        CancelIcon={ErrorOutline} // icon of cancel button (default: 'ErrorOutline')
        undoable={false}
      />
    )}
  </Toolbar>
);

const CustomToolBar_Referrals = ({ permissions, ...props }) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

export const ReferralEdit = ({ permissions, ...props }) => {
  const classes = useStyles(props);

  const [tabValue, setTabValue] = React.useState(0);
  const [referals, setReferrals] = React.useState([]);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const checkForExistingRefs = (e) => {
    let mapped = referals.map((referal) => referal);
    return mapped.includes(e);
  };

  const handleRemoveReferal = (id) => {
    const newList = referals.filter((item) => item !== id);
    setReferrals(newList);
  };

  const handleCheckBox = (e) => {};

  const handleReferrals = (e, path, record) => {
    console.log(",props.record[props.source]", record);
    let found = checkForExistingRefs(e);
    found
      ? handleRemoveReferal(e)
      : setReferrals((prevState) => [...prevState, record]);
  };

  React.useEffect(() => {
    console.log("updated referrals", referals);
  }, [referals]);

  const EditForm = (
    <SimpleForm
      toolbar={<CustomToolbar permissions={permissions} />}
      className={classes.editFormContainer}
    >
      <SectionTitle label="Identity" />
      <ReferenceInput
        source="roleId"
        reference="roles"
        label="User Type"
        validate={[required()]}
        formClassName={classes.inline}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="username"
        validate={[required()]}
        formClassName={classes.inline}
      />
      <TextInput
        source="firstName"
        validate={[required()]}
        formClassName={classes.inline}
      />
      <TextInput
        source="lastName"
        validate={[required()]}
        formClassName={classes.inline}
      />
      <DateInput source="dateOfBirth" formClassName={classes.inline} />
      <PasswordInput source="password" formClassName={classes.inline} />
      <SectionTitle label="Contact Details" />
      <TextInput
        source="email"
        validate={[required(), email()]}
        formClassName={classes.inline}
      />
      <TextInput
        source="phoneNumber"
        validate={[required(), minLength(10), maxLength(12)]}
        formClassName={classes.inline}
      />
      <TextInput
        multiline
        label="Address"
        source="address"
        validate={[required()]}
        formClassName={classes.inline}
      />
      <AutocompleteInput
        source="suburb"
        optionText="suburb"
        resettable={true}
        clearAlwaysVisible={true}
        suggestionLimit={20}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 3;
        }}
        optionValue="suburb"
        choices={SUBURBS}
        formClassName={classes.inline}
        validate={[required()]}
      />
      <PostalCodeInput source="postalCode" formClassName={classes.inline} />
      <StateInput source="state" formClassName={classes.inline} />
      <SectionTitle label="Business Details" />
      <TextInput
        source="businessName"
        validate={[required()]}
        formClassName={classes.inline}
      />
      <TextInput
        label="ABN"
        source="ABN"
        validate={[required()]}
        formClassName={classes.inline}
      />
      <TextInput label="ACN" source="ACN" formClassName={classes.inline} />
      <NumberInput
        source="commissionPercentage"
        min={0}
        max={100}
        formClassName={classes.inline}
      />
      <NumberInput source="flatRate" min={0} formClassName={classes.inline} />
      <SectionTitle label="Files" />
      <ImageInput
        source="avatar"
        label="Avatar"
        accept="image/*"
        formClassName={classes.uploadSection}
      >
        <ImageField source="avatar" title="title" />
      </ImageInput>
      <FileInput
        source="files"
        label="Related files"
        formClassName={classes.uploadSection}
        multiple={true}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <ImageField source="avatar" title="title" />
      <ArrayField source="user_files" label="">
        <Datagrid>
          <CustomUrlField
            label="Related files"
            source="filePath"
            fileName="fileName"
          />
        </Datagrid>
      </ArrayField>
      <SectionTitle label="Admin section" />
      <RichTextInput
        label="Note"
        source="adminNote"
        fullWidth={false}
        formClassName={classes.inline}
      />
      <BooleanInput
        source="isActive"
        label="(Inactive/Active)"
        formClassName={classes.inline}
      />
    </SimpleForm>
  );

  const listOfReferals = () => {};

  const PostBulkActionButtons = (props) => (
    <React.Fragment>
      <SaveButton />
    </React.Fragment>
  );

  const Roles = [
    "MORTGAGE BROKER",
    "EQUIPMENT FINANCE",
    "CONVEYANCER",
    "Business Analyst",
    "ACCOUNTANT",
    "Software Engineer",
  ];

  const ReferalFilter = (props) => (
    <Filter {...props}>
        <TextInput
            source="name"
            variant="outlined"
        />
    </Filter>
);
 

  const ManageRefferals = (
    <SimpleForm
      toolbar={<CustomToolBar_Referrals permissions={permissions} />}
      className={classes.editReferralContainer}
    >
      {Roles.map((role,index) => (
        <List
          {...props}
          title={role}
          filterDefaultValues={{ role : role }}
          bulkActionButtons={true}
          bulkActionButtons={
            permissions &&
            checkPermission(permissions, { adminUser: ["edit"] }) ? (
              <PostBulkActionButtons />
            ) : (
              false
            )
          }
        >
          <Datagrid
            // filters={<ReferalFilter/>}
            filters={{"record.role.type" : role }} 
            rowClick={(e, path, record) => {
              handleReferrals(e, path, record);
            }}
            className={classes.datagridContainer}
          >
            <TextField source="firstName" label="First Name" />
            <TextField source="lastName" label="Last Name" />
          </Datagrid>
        </List>
      ))}
    </SimpleForm>
  );

  const TabPanel = (
    <Show {...props}>
      <TabbedShowLayout
        syncWithLocation={false}
        className={classes.tabsContainer}
      >
        <Tab label="OVERVIEW" className={classes.tabs}>
          <Edit
            {...props}
            aside={<CustomAside {...props} />}
            title={<PageTitle />}
          >
            {EditForm}
          </Edit>
        </Tab>
        <Tab label="MANAGE REFFERALS" path="body" className={classes.tabs}>
          <Edit {...props} title={<PageTitle />}>
            {ManageRefferals}
          </Edit>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );

  return <>{TabPanel}</>;
};
