import * as React from "react";
import {
    CheckboxGroupInput, DeleteButton,
    EditButton, ReferenceInput,
    required,
    SelectInput,
    Show, SimpleForm,
    TextInput, Toolbar,
    TopToolbar,
    SimpleShowLayout
} from 'react-admin';
import DeleteWithCustomConfirmButton from 'ra-delete-with-custom-confirm-button';
import Delete from '@material-ui/icons/Delete';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import checkPermission from "../../helpers/permission_handler";
import CustomAside from "../CustomAside/CustomAside";
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {USERTYPES} from "../../constants/userTypes";
import getUserTypes from "../../helpers/get_user_types";

const PageTitle = ({record}) => {
    return <span>User Types - View Type {record ? `${record.name}` : ''}</span>;
};

const SectionTitle = ({label}) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

export const styles = {
    name: {display: 'inline-block'},
    type: {display: 'inline-block', marginLeft: 32},
};

const useStyles = makeStyles(styles);

const RoleShowActions = ({basePath, data, resource}) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data}/>
    </TopToolbar>
);

// Define your custom contents of confirm dialog
const DeleteConfirmContent = props => {
    return (
        <SimpleShowLayout {...props} />
    );
};

const CustomToolbar = ({permissions, ...props}) => (
    <Toolbar {...props} classes={useStyles()}>
        {permissions && checkPermission(permissions, {adminUserRole: ['delete']}) &&
        <DeleteWithCustomConfirmButton
            title={"Are you sure?"}
            content={DeleteConfirmContent}
            label='Delete'                  // label of delete button (default: 'Delete')
            confirmColor='warning'          // color of delete button ('warning' or 'primary', default: 'warning')
            ConfirmIcon={Delete}            // icon of delete button (default: 'Delete')
            cancel='Cancel'                 // label of cancel button (default: 'Cancel')
            CancelIcon={ErrorOutline}       // icon of cancel button (default: 'ErrorOutline')
            undoable={false}/>}
    </Toolbar>
);

export const RoleShow = ({permissions, ...props}) => {
    const classes = useStyles(props);
    return (
        <Show {...props} aside={<CustomAside {...props}/>} title={<PageTitle/>}
              actions={permissions && checkPermission(permissions, {adminUserRole: ['edit']}) ?
                  <RoleShowActions/> : <React.Fragment/>}>
            <SimpleForm
                toolbar={<CustomToolbar permissions={permissions}/>}
                variant="outlined"
                redirect={'/roles'}
            >
                <TextInput
                    source="name"
                    validate={[required()]}
                    formClassName={classes.name}
                    disabled
                />
                <ReferenceInput
                    source="type"
                    reference="types"
                    label="Type"
                    validate={[required()]}
                    formClassName={classes.inline}
                    disabled
                ><SelectInput optionText="name"/>
                </ReferenceInput>
                <SectionTitle label="User Permissions"/>
                <CheckboxGroupInput
                    source="permissions.outgoing"
                    label="Outgoing Referrals"
                    choices={[
                        {id: 'view', name: 'View'},
                        {id: 'create', name: 'Create'},
                        {id: 'edit', name: 'Edit'},
                        {id: 'delete', name: 'Delete'},
                    ]}
                    disabled
                />
                <CheckboxGroupInput
                    source="permissions.incoming"
                    label="Incoming Referrals"
                    choices={[
                        {id: 'view', name: 'View'},
                        {id: 'edit', name: 'Edit'},
                    ]}
                    disabled
                />
                <CheckboxGroupInput source="permissions.report" label="Reports" choices={[
                    {id: 'create', name: 'Create'},
                ]} disabled/>
                <SectionTitle label="Admin Permissions"/>
                <CheckboxGroupInput
                    source="permissions.adminUser"
                    label="User Manager"
                    choices={[
                        {id: 'view', name: 'View'},
                        {id: 'create', name: 'Create'},
                        {id: 'edit', name: 'Edit'},
                        {id: 'delete', name: 'Delete'},
                    ]}
                    disabled
                />
                <CheckboxGroupInput source="permissions.adminUserRole" label="User Type" choices={[
                    {id: 'view', name: 'View'},
                    {id: 'create', name: 'Create'},
                    {id: 'edit', name: 'Edit'},
                    {id: 'delete', name: 'Delete'},
                ]} disabled/>
                <CheckboxGroupInput source="permissions.adminFile" label="Files" choices={[
                    {id: 'view', name: 'View'},
                    {id: 'create', name: 'Create'},
                    {id: 'edit', name: 'Edit'},
                    {id: 'delete', name: 'Delete'},
                ]} disabled/>
                <CheckboxGroupInput source="permissions.adminReport" label="Reports" choices={[
                    {id: 'create', name: 'Create'},
                ]} disabled/>
            </SimpleForm>
        </Show>
    );
};