import * as React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DeleteButton,
  Edit,
  email,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  maxLength,
  minLength,
  NumberInput,
  PasswordInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ArrayField,
  SingleFieldList,
  Datagrid,
  UrlField,
  useDataProvider,
  SimpleShowLayout,
  useEditController,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import checkPermission from "../../helpers/permission_handler";
import CustomAside from "../CustomAside/CustomAside";
import { SUBURBS } from "../../constants/suburbs";
import RichTextInput from "ra-input-rich-text";
import { useFormState } from "react-final-form";
import { Typography } from "@material-ui/core";
import CustomUrlField from "../CustomFields/CustomUrlField";
import { useState } from "react";
import { useEffect } from "react";
import { FILESTATUS } from "../../constants/fileStatus";
import { USERTYPES } from "../../constants/userTypes";

const _ = require("lodash");

let Isadmin = false;
JSON.parse(localStorage.getItem("addon_user"))
  ? JSON.parse(localStorage.getItem("addon_user")).username === "admin"
    ? (Isadmin = true)
    : (Isadmin = false)
  : (Isadmin = false);

const SectionTitle = ({ label }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

const PageTitle = ({ record }) => {
  return (
    <span>
      Outgoing Referrals - Edit File {record ? `${record.referencesNo}` : ""}
    </span>
  );
};

export const styles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  inline: { display: "inline-block", margin: "0 16px" },
  uploadSection: {
    background: "aliceblue",
    borderRadius: "12px",
    border: "1px dashed darkcyan",
    display: "inline-block",
    margin: "0 16px",
  },
  options: {},
};

const useStyles = makeStyles(styles);

const PostalCodeInput = (props) => {
  const { values } = useFormState();
  let choices;
  if (values.clientSuburb) {
    SUBURBS.forEach((item) => {
      if (values.clientSuburb === item.suburb) {
        choices = item;
      }
    });
  }

  return (
    <TextInput
      initialValue={choices ? choices.postcode : undefined}
      {...props}
    />
  );
};

const StateInput = (props) => {
  const { values } = useFormState();
  let choices;
  if (values.clientSuburb) {
    SUBURBS.forEach((item) => {
      if (values.clientSuburb === item.suburb) {
        choices = item;
      }
    });
  }

  return (
    <TextInput initialValue={choices ? choices.state : undefined} {...props} />
  );
};

// Define your custom contents of confirm dialog
const DeleteConfirmContent = (props) => {
  return <SimpleShowLayout {...props} />;
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    {permissions &&
      checkPermission(permissions, { outgoing: ["delete"] }) &&
      props.record.status === FILESTATUS.PENDING && (
        <DeleteWithCustomConfirmButton
          title={"Are you sure?"}
          content={DeleteConfirmContent}
          label="Delete" // label of delete button (default: 'Delete')
          confirmColor="warning" // color of delete button ('warning' or 'primary', default: 'warning')
          ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
          cancel="Cancel" // label of cancel button (default: 'Cancel')
          CancelIcon={ErrorOutline} // icon of cancel button (default: 'ErrorOutline')
          undoable={false}
        />
      )}
  </Toolbar>
);

const CustomInputField = ({ record = {}, ...props }) => {
  return (
    <TextInput
      record={record}
      {...props}
      disabled={
        record.status !== FILESTATUS.PENDING &&
        record.status !== FILESTATUS.DECLINED
      }
    />
  );
};

const CustomNumberField = ({ record = {}, ...props }) => {
  return (
    <NumberInput
      record={record}
      {...props}
      disabled={
        record.status !== FILESTATUS.PENDING &&
        record.status !== FILESTATUS.DECLINED
      }
    />
  );
};

const CustomSelectField = ({ record = {}, ...props }) => {
  return (
    <ReferenceInput
      record={record}
      {...props}
      disabled={
        record.status !== FILESTATUS.PENDING &&
        record.status !== FILESTATUS.DECLINED
      }
    />
  );
};

const CustomAutoCompleteField = ({ record = {}, ...props }) => {
  return (
    <AutocompleteInput
      record={record}
      {...props}
      disabled={
        record.status !== FILESTATUS.PENDING &&
        record.status !== FILESTATUS.DECLINED
      }
    />
  );
};

const CustomPostalCodeInputField = ({ record = {}, ...props }) => {
  return (
    <PostalCodeInput
      record={record}
      {...props}
      disabled={
        record.status !== FILESTATUS.PENDING &&
        record.status !== FILESTATUS.DECLINED
      }
    />
  );
};

const CustomStateInputField = ({ record = {}, ...props }) => {
  return (
    <StateInput
      record={record}
      {...props}
      disabled={
        record.status !== FILESTATUS.PENDING &&
        record.status !== FILESTATUS.DECLINED
      }
    />
  );
};

export const OutgoingEdit = ({ permissions, ...props }) => {
  const classes = useStyles(props);
  const dataProvider = useDataProvider();
  const [listRole, setListRole] = useState();
  const [current_user, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("addon_user")).id
  );
  const [defaultRefArray, setDefaultRefArray] = React.useState([]);
  const [RefArray, setRefArray] = React.useState([]);
  const [hasSuburb, setHasSuburb] = React.useState(false);
  let defaultArr = [];
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const controllerProps = useEditController(props);
  const { record } = controllerProps;
  // console.log("RECORD IDk",record.createdUser.id);

  const [user, setUser] = useState(record);
  useEffect(() => {
    dataProvider
      .getList("roles", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "ASC" },
      })
      .then(({ data }) => {
        setListRole(data);
        setLoading(false);

        Isadmin
          ? dataProvider
              .getList("users", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "firstName", order: "ASC" },
              })
              .then((result) => {
                if (result.data.length > 0) {
                  let mapped_array = _.map(result.data, "id");
                  let showOnlyTheseUsers = [];
                  mapped_array.map((ele) => {
                    if (!defaultArr.includes(ele)) {
                      showOnlyTheseUsers.push(ele);
                    }
                  });
                  setDefaultRefArray(showOnlyTheseUsers);
                }
              })
          : dataProvider
              .getOne("users", {
                id: JSON.parse(localStorage.getItem("addon_user")).id,
              })
              .then((response) => {
                response.data.referralusers.map((refUser) => {
                  defaultArr.push(refUser.id);
                });

                setRefArray(defaultArr);
                setDefaultRefArray(defaultArr);
              });
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const CustomAssignInputField = ({ record = {}, ...props }) => {
    return (
      <AssignInput
        record={record}
        {...props}
        disabled={
          record.status !== FILESTATUS.PENDING &&
          record.status !== FILESTATUS.DECLINED
        }
      />
    );
  };

  const OptionTextField = ({ record }) => {
    let rate = "";
    if (record.commissionPercentage) {
      rate += `Commission - ${record.commissionPercentage}%`;
    }
    if (record.flatRate) {
      rate += `Flat Rate - ${record.flatRate}`;
    }
    return (
      <span className={classes.options}>
        {record.firstName} ({rate})
      </span>
    );
  };

  const AssignInput = (props) => {
    const { values } = useFormState();
    let obj = {};
    if (values.suburb) {
      obj.suburb = values.suburb;
    }
    if (values.type && props.roleList) {
      setHasSuburb(true);
      let lst = [];
      const data = props.roleList;
      data.forEach((item) => {
        if (values.type === item.type) {
          lst.push(item.id);
        }
      });
      obj.roleId = lst;
    }

    if (defaultRefArray.length > 0) {
      obj.id = defaultRefArray;
    }

    return defaultRefArray.length > 0 || defaultRefArray !== [] ? (
      <ReferenceInput
        filter={obj}
        sort={{ field: "firstName", order: "ASC" }}
        {...props}
        style={{ width: "400px" }}
        defaultValue={"hello"}
      >
        
        <AssignSelectInput optionText={<OptionTextField />} />
      </ReferenceInput>
    ) : (
      <div style={divStyle}>No Referrals found under the Role</div>
    );
  };

  const AssignSelectInput = (props) => {
    const { values } = useFormState();
    let choices = [];
    let apprvd_choices = [];
    if (props.choices && props.choices.length > 0) {
      props.choices.forEach((item) => {
        if (values.type && item.role && item.role.type !== values.type) {
          values.assignee = undefined;
        } else {
          choices.push(item);
        }
      });

      choices.forEach((item) => {
        let e = defaultRefArray;
        if (e.indexOf(item.id) === -1) {
          values.assignee = undefined;
        } else {
          apprvd_choices.push(item);
        }
      });
    }

    return <SelectInput {...props} choices={apprvd_choices} />;
  };

  const divStyle = {
    minWidth: "12vw",
    height: "2vw",
    borderRadius: "10px",
    backgroundColor: "#f4f4f4",
    padding: "1vw 1vw 1vw 1vw",
    margin: "auto auto auto 1vw",
  };

  return (
    <Edit
      {...props}
      aside={<CustomAside {...props} />}
      title={<PageTitle />}
      undoable={false}
    >
      <SimpleForm
        toolbar={<CustomToolbar permissions={permissions} />}
        variant="outlined"
      >
        <SectionTitle label="Basic Details" />
        <CustomInputField
          source="clientName"
          validate={[required()]}
          formClassName={classes.inline}
        />
        <CustomNumberField
          source="value"
          validate={[required()]}
          step={1}
          formClassName={classes.inline}
        />
        <CustomInputField
          multiline
          source="note"
          formClassName={classes.inline}
        />
        <SelectInput
          source="status"
          choices={[
            { id: FILESTATUS.PENDING, name: FILESTATUS.PENDING },
            { id: FILESTATUS.ACCEPTED, name: FILESTATUS.ACCEPTED },
            { id: FILESTATUS.DECLINED, name: FILESTATUS.DECLINED },
            { id: FILESTATUS.PAID, name: FILESTATUS.PAID },
            { id: FILESTATUS.SETTLED, name: FILESTATUS.SETTLED },
          ]}
          validate={[required()]}
          formClassName={classes.inline}
          disabled
        />
        <DateInput
          source="assigneeAcceptedAt"
          label="Date Accepted/Declined"
          formClassName={classes.inline}
          disabled
        />

        <SectionTitle label="Contact Details" />

        <CustomInputField
          source="clientEmail"
          validate={[required(), email()]}
          formClassName={classes.inline}
        />
        <CustomInputField
          source="clientPhoneNumber"
          validate={[required(), minLength(10), maxLength(12)]}
          formClassName={classes.inline}
        />
        <CustomInputField
          multiline
          source="clientAddress"
          formClassName={classes.inline}
        />
        <CustomAutoCompleteField
          source="clientSuburb"
          optionText="suburb"
          resettable={true}
          clearAlwaysVisible={true}
          suggestionLimit={10}
          shouldRenderSuggestions={(val) => {
            return val.trim().length > 3;
          }}
          optionValue="suburb"
          choices={SUBURBS}
          formClassName={classes.inline}
        />
        <CustomPostalCodeInputField
          source="clientPostalCode"
          formClassName={classes.inline}
        />
        <CustomStateInputField
          source="clientState"
          formClassName={classes.inline}
        />

        <SectionTitle label="Referred to" />
        <CustomSelectField
          source="type"
          reference="types"
          label="Type"
          formClassName={classes.inline}
        >
          <SelectInput optionText="name" />
        </CustomSelectField>

        <CustomAutoCompleteField
          source="assigneeSuburb"
          label="Referrer suburb"
          optionText="suburb"
          resettable={true}
          clearAlwaysVisible={true}
          suggestionLimit={10}
          shouldRenderSuggestions={(val) => {
            return val.trim().length > 3;
          }}
          optionValue="suburb"
          choices={SUBURBS}
          formClassName={classes.inline}
        />

        <FormDataConsumer formClassName={classes.inline}>
          {({ formData, ...rest }) =>
            formData.type && (
              <CustomAssignInputField
                source="assignee"
                label="Referrer"
                reference="users"
                roleList={listRole}
                defaultRefArray={defaultRefArray}
                formClassName={classes.inline}
                optionText="firstName"
                {...rest}
                
                
              >
    
                </CustomAssignInputField>
        //      <ReferenceInput source="assignee" label="Referrer" reference="users" disabled formClassName={classes.inline}>
        //      <SelectInput optionText="firstName"/>
        //  </ReferenceInput>
              
            )
          }
        </FormDataConsumer>
    

        <TextInput
          multiline
          source="assigneeNote"
          label="Referrer note"
          formClassName={classes.inline}
          disabled
        />

        <SectionTitle label="Files" />
        <FileInput
          source="files"
          label="Related files"
          formClassName={classes.uploadSection}
          multiple={true}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <ArrayField source="file_uploads" label="">
          <Datagrid>
            <CustomUrlField
              label="Related files"
              source="filePath"
              fileName="fileName"
            />
          </Datagrid>
        </ArrayField>
      </SimpleForm>
    </Edit>
  );
};
