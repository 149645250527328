import * as React from "react";
import {
    BulkDeleteButton, BulkExportButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceField,
    SelectInput,
    ShowButton,
    TextField,
    TextInput,
    downloadCSV
} from 'react-admin';

import checkPermission from "../../helpers/permission_handler";
import CustomDateTimeField from '../CustomFields/CustomDateTimeField'
import {SUBURBS} from "../../constants/suburbs";
import {FILESTATUS} from "../../constants/fileStatus";
import {USERTYPES} from "../../constants/userTypes";
import moment from "moment";
import jsonExport from 'jsonexport/dist';
const exporter = posts => {
    const postsForExport = posts.map(post => {
        return {
            referencesNo: post.referencesNo,
            clientName: post.clientName,
            dateSubmitted: post.createdAt ? moment(post.createdAt).format('YYYY-MM-DD') : undefined,
            dateAccepted: post.assigneeAcceptedAt ? moment(post.assigneeAcceptedAt).format('YYYY-MM-DD') : undefined,
            commissionPercentage: post.commissionPercentage,
            flatRate: post.flatRate,
            referredToNote: post.assigneeNote,
            referredBy: post.createdUser ? post.createdUser.firstName : undefined,
            note: post.note,
            value: post.value,
            referredByType: post.createdUser && post.createdUser.role && post.createdUser.role.type ? post.createdUser.role.type : undefined,
            status: post.status,
            createdAt: post.createdAt ? moment(post.createdAt).format('YYYY-MM-DD') : undefined,
        }
    });
    jsonExport(postsForExport, {
        headers: [] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'incomings'); // download as 'posts.csv` file
    });
};

const FileFilter = (props) => (
    <Filter {...props}>
        <TextInput source="referencesNo" alwaysOn variant="outlined" label="Reference No"/>
        <TextInput source="clientName" label="Client Name" alwaysOn variant="outlined"/>
        <TextInput source="note" alwaysOn variant="outlined" label="Notes"/>
        <SelectInput source="type" alwaysOn choices={[
            { id: USERTYPES.ACCOUNTANT, name: USERTYPES.ACCOUNTANT },
            { id: USERTYPES.CONVEYANCER, name: USERTYPES.CONVEYANCER },
            { id: USERTYPES.FINANCIAL_PLANER, name: USERTYPES.FINANCIAL_PLANER },
            { id: USERTYPES.MORTGAGE_BROKER, name: USERTYPES.MORTGAGE_BROKER },
        ]} variant="outlined" />
        <SelectInput source="status" label="Status" alwaysOn choices={[
            { id: FILESTATUS.PENDING, name: FILESTATUS.PENDING },
            { id: FILESTATUS.ACCEPTED, name: FILESTATUS.ACCEPTED },
            { id: FILESTATUS.SETTLED, name: FILESTATUS.SETTLED },
            { id: FILESTATUS.PAID, name: FILESTATUS.PAID },
            { id: FILESTATUS.DECLINED, name: FILESTATUS.DECLINED },
        ]} variant="outlined" />
    </Filter>
);

const PostBulkActionButtons = props => (
    <React.Fragment>
        <BulkExportButton />
        <BulkDeleteButton {...props} undoable={false}/>
    </React.Fragment>
);

export const IncomingList = ({permissions, ...props}) => (
    <List {...props}
          title="Incoming Referrals"
          filters={<FileFilter/>}
          bulkActionButtons={permissions && checkPermission(permissions, {outgoing: ['delete']}) ?
              <PostBulkActionButtons/> : false}
          sort={{ field: 'createdAt', order: 'DESC' }}
          exporter={exporter}
    >
        <Datagrid
            rowClick={permissions && checkPermission(permissions, {outgoing: ['edit']}) ? 'edit' : 'show'}>
            <TextField source="referencesNo" label="Reference No"/>
            <TextField source="clientName" label="Client Name"/>
            <CustomDateTimeField source="createdAt" label="Date Submitted"/>
            <CustomDateTimeField source="assigneeAcceptedAt" label="Date Accepted/Declined"/>
            <TextField source="commissionPercentage" label="Commission Percentage(%)"/>
            <TextField source="flatRate" label="Flat Rate"/>
            <TextField source="assigneeNote" label="Note"/>
            <ReferenceField source="createdBy" reference="users" label="Referred By" linkType="">
                <TextField source="firstName"/>
            </ReferenceField>
            <TextField source="note"/>
            <TextField source="value"/>
            <ReferenceField source="createdBy" reference="users" label="Referred by Type" linkType="">
                <ReferenceField source="roleId" reference="roles" linkType="">
                    <TextField source="type"/>
                </ReferenceField>
            </ReferenceField>
            <TextField source="status"/>
            <CustomDateTimeField source="createdAt" label="Created At"/>
            {permissions && checkPermission(permissions, {outgoing: ['edit']}) ? <EditButton/> :
                <ShowButton/>}
        </Datagrid>
    </List>
);