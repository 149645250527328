import * as React from "react";
import {
    Create,
    required,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
    name: {display: 'inline-block'},
    type: {display: 'inline-block', marginLeft: 32},
};

const useStyles = makeStyles(styles);

export const TypeCreate = props => {
    const classes = useStyles(props);

    return (
        <Create
            {...props}
            title="Types - Create Type"
        >
            <SimpleForm
                variant="outlined"
                redirect={'/types'}
            >
                <TextInput
                    source="name"
                    validate={[required()]}
                    formClassName={classes.name}
                />
            </SimpleForm>
        </Create>)
}