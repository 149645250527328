import * as React from "react";
import {
    BulkDeleteButton, BulkExportButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceField, ReferenceInput,
    SelectInput,
    ShowButton,
    TextField,
    TextInput,
    downloadCSV
} from 'react-admin';

import checkPermission from "../../helpers/permission_handler";
import CustomDateTimeField from '../CustomFields/CustomDateTimeField'
import {USERTYPES} from "../../constants/userTypes";
import jsonExport from 'jsonexport/dist';
import moment from "moment";

const RoleFilter = (props) => (
    <Filter {...props}>
        <TextInput
            source="name"
            alwaysOn
            inputProps={{ autocomplete: 'false' }}
            variant="outlined"
        />
        <ReferenceInput
            alwaysOn
            source="type"
            reference="types"
            label="Type"
            variant="outlined"
        ><SelectInput optionText="name"/>
        </ReferenceInput>
        {/* <ReferenceInput source="id" reference="roles" label="Roles" allowEmpty alwaysOn variant="outlined">
            <SelectInput optionText="name"/>
        </ReferenceInput> */}
    </Filter>
);

const exporter = roles => {
    const rolesForExport = roles.map(role => {
        return {
            name: role.name,
            type: role.type,
            createdAt: role.createdAt ? moment(role.createdAt).format('YYYY-MM-DD') : undefined
        };
    });
    jsonExport(rolesForExport, {
        headers: [] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'roles'); // download as 'posts.csv` file
    });
};

const PostBulkActionButtons = props => (
    <React.Fragment>
        <BulkExportButton />
        <BulkDeleteButton {...props} undoable={false}/>
    </React.Fragment>
);

export const RoleList = ({permissions, ...props}) => (
    <List {...props}
          title="User Types"
          exporter={exporter}
          filters={<RoleFilter/>}
          bulkActionButtons={permissions && checkPermission(permissions, {adminUserRole: ['delete']}) ?
              <PostBulkActionButtons/> : false}
          sort={{ field: 'createdAt', order: 'DESC' }}
    >
        <Datagrid
            rowClick={permissions && checkPermission(permissions, {adminUserRole: ['edit']}) ? 'edit' : 'show'}>
            <TextField source="name"/>
            <TextField source="type"/>
            <CustomDateTimeField source="createdAt"/>
            {permissions && checkPermission(permissions, {adminUserRole: ['edit']}) ? <EditButton/> :
                <ShowButton/>}
        </Datagrid>
    </List>
);