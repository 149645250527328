import * as React from "react";
import moment from 'moment';

const CustomUrlField = ({record = {}, source, fileName}) =>
    <span>
        <a target="_blank" href={record[source]}>{record[fileName]}</a>
    </span>;

CustomUrlField.defaultProps = {
    addLabel: false,
};

export default CustomUrlField;