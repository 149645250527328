import * as React from "react";
import {Layout} from 'react-admin';
import CustomMenu from './CustomMenu';
import CustomAppBar from "./CustomAppBar";

const CustomMenuLayout = (props) => <Layout
    {...props}
    menu={CustomMenu}
    appBar={CustomAppBar}
/>;

export default CustomMenuLayout;