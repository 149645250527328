import * as React from "react";
import {
    downloadCSV, useDataProvider
} from 'react-admin';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import moment from "moment";
import jsonExport from 'jsonexport/dist';
import {useEffect, useState} from "react";
const exporter = (posts, params) => {
    const postsForExport = posts.map(post => {
        const res = {};

        if (params.referencesNo) {
            res.referencesNo = post.referencesNo;
        }
        if (params.clientName) {
            res.clientName = post.clientName;
        }
        if (params.createdAt) {
            res.dateSubmitted = post.createdAt ? moment(post.createdAt).format('YYYY-MM-DD') : undefined;
        }
        if (params.assigneeAcceptedAt) {
            res.dateAccepted = post.assigneeAcceptedAt ? moment(post.assigneeAcceptedAt).format('YYYY-MM-DD') : undefined;
        }
        if (params.commissionPercentage) {
            res.commissionPercentage = post.commissionPercentage;
        }
        if (params.flatRate) {
            res.flatRate = post.flatRate;
        }
        if (params.note) {
            res.note = post.note;
        }
        if (params.assignee) {
            res.referredTo = post.assignedUser ? post.assignedUser.firstName : undefined;
        }
        if (params.assigneeNote) {
            res.referredToNote = post.assigneeNote;
        }
        if (params.value) {
            res.value = post.value;
        }
        if (params.type) {
            res.referredToType = post.type;
        }
        if (params.status) {
            res.status = post.status;
        }
        if (params.createdAt2) {
            res.createdAt = post.createdAt ? moment(post.createdAt).format('YYYY-MM-DD') : undefined;
        }

        return res;
    });
    jsonExport(postsForExport, {
        headers: [] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, params.reportName.trim() !== '' ? params.reportName.trim() : 'report'); // download as 'posts.csv` file
    });
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


export const OutgoingsReport = ({permissions, ...props}) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        referencesNo: false,
        clientName: false,
        createdAt: false,
        assigneeAcceptedAt: false,
        commissionPercentage: false,
        flatRate: false,
        note: false,
        assignee: false,
        assigneeNote: false,
        value: false,
        type: false,
        status: false,
        createdAt2: false,
        reportName: ''
    });
    const dataProvider = useDataProvider();
    const [listData, setListData] = useState();
    const [loading, setLoading] = useState(true);
    const [ error,setError] = useState();

    useEffect(() => {
        dataProvider.getList('outgoings', {
            pagination: {page: 1, perPage: 1000},
            sort: {field: 'createdAt', order: 'DESC'},

        }).then(({data}) => {
            setListData(data);
            setLoading(false);
        })
            .catch(error => {
                console.log(error);
                setError(error);
                setLoading(false);
            })
    }, []);

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleReportName = (event) => {
        setState({ ...state, reportName: event.target.value })
    }

    return (
        <Grid xs={3} style={{ margin: '5%'}}>
            <Paper className={classes.paper}>
            <FormGroup>
                <TextField id="standard-basic" label="Report Name" variant="outlined" value={state.reportName} onChange={handleReportName}/>
            </FormGroup>
            <br/>
            <FormControl component="fieldset">
                <FormLabel component="legend">Select Fields</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.referencesNo} onChange={handleChange} name="referencesNo" />}
                        label="Reference No"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.clientName} onChange={handleChange} name="clientName" />}
                        label="Client Name"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.createdAt} onChange={handleChange} name="createdAt" />}
                        label="Date Submitted"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.assigneeAcceptedAt} onChange={handleChange} name="assigneeAcceptedAt" />}
                        label="Date Accepted/Declined"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.commissionPercentage} onChange={handleChange} name="commissionPercentage" />}
                        label="Commission Percentage(%)"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.flatRate} onChange={handleChange} name="flatRate" />}
                        label="Flat Rate"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.note} onChange={handleChange} name="note" />}
                        label="Note"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.assignee} onChange={handleChange} name="assignee" />}
                        label="Referred To"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.assigneeNote} onChange={handleChange} name="assigneeNote" />}
                        label="Referred Note"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.value} onChange={handleChange} name="value" />}
                        label="Value"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.type} onChange={handleChange} name="type" />}
                        label="Type"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.status} onChange={handleChange} name="status" />}
                        label="Status"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={state.createdAt2} onChange={handleChange} name="createdAt2" />}
                        label="Created At"
                    />
                </FormGroup>
                <br/>
                <Button variant="contained" color="secondary" onClick={() => exporter(listData, state)}>
                    Download
                </Button>
            </FormControl>
            </Paper>
        </Grid>
    )
};