import * as React from "react";
import {
    BulkDeleteButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceField,
    SelectInput,
    ShowButton,
    TextField,
    TextInput,
    ReferenceInput, BulkExportButton,
    downloadCSV
} from 'react-admin';

import checkPermission from "../../helpers/permission_handler";
import CustomDateTimeField from '../CustomFields/CustomDateTimeField'
import {FILESTATUS} from "../../constants/fileStatus";
import moment from "moment";
import jsonExport from 'jsonexport/dist';
const exporter = posts => {
    const postsForExport = posts.map(post => {
        return {
            referencesNo: post.referencesNo,
            clientName: post.clientName,
            dateSubmitted: post.createdAt ? moment(post.createdAt).format('YYYY-MM-DD') : undefined,
            dateAccepted: post.assigneeAcceptedAt ? moment(post.assigneeAcceptedAt).format('YYYY-MM-DD') : undefined,
            commissionPercentage: post.commissionPercentage,
            flatRate: post.flatRate,
            referredBy: post.createdUser ? post.createdUser.firstName : undefined,
            note: post.note,
            referredTo: post.assignedUser ? post.assignedUser.firstName : undefined,
            referredToNote: post.assigneeNote,
            value: post.value,
            referredToType: post.type,
            status: post.status,
            createdAt: post.createdAt ? moment(post.createdAt).format('YYYY-MM-DD') : undefined,
        }
    });
    jsonExport(postsForExport, {
        headers: [] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'incomings'); // download as 'posts.csv` file
    });
};

const FileFilter = (props) => (
    <Filter {...props}>
        <TextInput source="referencesNo" alwaysOn variant="outlined" label="Reference No"/>
        <TextInput source="clientName" label="Client Name" alwaysOn variant="outlined"/>
        <TextInput source="note" alwaysOn variant="outlined" label="Notes"/>
        <ReferenceInput
            alwaysOn
            source="type"
            reference="types"
            label="Type"
            variant="outlined"
        ><SelectInput optionText="name"/>
        </ReferenceInput>
        <SelectInput source="status" label="Status" alwaysOn choices={[
            { id: FILESTATUS.PENDING, name: FILESTATUS.PENDING },
            { id: FILESTATUS.ACCEPTED, name: FILESTATUS.ACCEPTED },
            { id: FILESTATUS.SETTLED, name: FILESTATUS.SETTLED },
            { id: FILESTATUS.PAID, name: FILESTATUS.PAID },
            { id: FILESTATUS.DECLINED, name: FILESTATUS.DECLINED },
        ]} variant="outlined" />
    </Filter>
);

const PostBulkActionButtons = props => (
    <React.Fragment>
        <BulkExportButton />
        <BulkDeleteButton {...props} undoable={false}/>
    </React.Fragment>
);

export const FileList = ({permissions, ...props}) => (
    <List {...props}
          filters={<FileFilter/>}
          bulkActionButtons={permissions && checkPermission(permissions, {adminFile: ['delete']}) ?
              <PostBulkActionButtons/> : false}
          sort={{ field: 'createdAt', order: 'DESC' }}
          exporter={exporter}
    >
        <Datagrid
            rowClick={permissions && checkPermission(permissions, {adminFile: ['edit']}) ? 'edit' : 'show'}>
            <TextField source="referencesNo" label="Reference No"/>
            <TextField source="clientName" label="Client Name"/>
            <CustomDateTimeField source="createdAt" label="Date Submitted"/>
            <CustomDateTimeField source="assigneeAcceptedAt" label="Date Accepted/Declined"/>
            <TextField source="commissionPercentage" label="Commission Percentage(%)"/>
            <TextField source="flatRate" label="Flat Rate"/>
            <ReferenceField source="createdBy" reference="users" label="Referred By" linkType="">
                <TextField source="firstName"/>
            </ReferenceField>
            <TextField source="note"/>
            <ReferenceField source="assignee" reference="users" label="Referred To" linkType="">
                <TextField source="firstName"/>
            </ReferenceField>
            <TextField source="assigneeNote" label="Note"/>
            <TextField source="value"/>
            <TextField source="type" label="Referred to Type"/>
            <TextField source="status"/>
            <CustomDateTimeField source="createdAt" label="Created At"/>
            {permissions && checkPermission(permissions, {adminFile: ['edit']}) ? <EditButton/> :
                <ShowButton/>}
        </Datagrid>
    </List>
);