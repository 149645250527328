import * as React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  email,
  maxLength,
  minLength,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  DateInput,
  PasswordInput,
  ImageInput,
  ImageField,
  NumberInput,
  FileInput,
  FileField,
  useDataProvider,
  TextField,
} from "react-admin";
import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RichTextInput from "ra-input-rich-text";
import { SUBURBS } from "../../constants/suburbs";
import { useFormState } from "react-final-form";
import { USERTYPES } from "../../constants/userTypes";
import { FILESTATUS } from "../../constants/fileStatus";


const _ = require("lodash");
let Isadmin = false;
JSON.parse(localStorage.getItem("addon_user"))
    ? JSON.parse(localStorage.getItem("addon_user")).username === "admin"
    ? (Isadmin = true)
    : (Isadmin = false)
    : (Isadmin = false);


const SectionTitle = ({ label }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

export const styles = {
  inline: { display: "inline-block", margin: "0 16px" },
  uploadSection: {
    background: "aliceblue",
    borderRadius: "12px",
    border: "1px dashed darkcyan",
    display: "inline-block",
    margin: "0 16px",
  },
};

const useStyles = makeStyles(styles);

const PostalCodeInput = (props) => {
  const { values } = useFormState();
  let choices;
  if (values.clientSuburb) {
    SUBURBS.forEach((item) => {
      if (values.clientSuburb === item.suburb) {
        choices = item;
      }
    });
  }

  return (
    <TextInput
      initialValue={choices ? choices.postcode : undefined}
      {...props}
    />
  );
};

const StateInput = (props) => {
  const { values } = useFormState();
  let choices;
  if (values.clientSuburb) {
    SUBURBS.forEach((item) => {
      if (values.clientSuburb === item.suburb) {
        choices = item;
      }
    });
  }

  return (
    <TextInput initialValue={choices ? choices.state : undefined} {...props} />
  );
};

export const OutgoingCreate = (props) => {
  const classes = useStyles(props);
  const dataProvider = useDataProvider();
  const [listRole, setListRole] = useState();
  const [current_user, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("addon_user")).id
  );
  const [defaultRefArray, setDefaultRefArray] = React.useState([]);
  let defaultArr = [];
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getList("roles", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "ASC" },
      })
      .then(({ data }) => {
        setListRole(data);
        setLoading(false);

        Isadmin
            ? dataProvider
                .getList("users", {
                  pagination: { page: 1, perPage: 50 },
                  sort: { field: "firstName", order: "ASC" },
                })
                .then((result) => {

                  if (result.data.length > 0) {
                    let mapped_array = _.map(result.data, "id");
                    console.log("userlist",mapped_array);
                    let showOnlyTheseUsers = [];
                    mapped_array.map((ele) => {
                      if (!defaultArr.includes(ele)) {
                        showOnlyTheseUsers.push(ele);
                      }
                    });
                    console.log("showOnlyTheseUsers",showOnlyTheseUsers);
                    setDefaultRefArray(showOnlyTheseUsers);
                  }
                })
            : dataProvider
                .getOne("users", { id: JSON.parse(localStorage.getItem("addon_user")).id }).then((response) => {
                  response.data.referralusers.map((refUser) => {
                    defaultArr.push(refUser.id);
                  });
                  setDefaultRefArray(defaultArr);
                });
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, []);



  
  const OptionTextField = ({ record }) => {
    let rate = "";
    if (record.commissionPercentage) {
      rate += `Commission - ${record.commissionPercentage}%`;
    }
    if (record.flatRate) {
      rate += `Flat Rate - ${record.flatRate}`;
    }
    return (
      <span>
        {record.firstName} ({rate})
      </span>
    );
  };

  const AssignInput = (props) => {
    const { values } = useFormState();

    let obj = {};
    if (values.suburb) {
      obj.suburb = values.suburb;
    }
    if (values.type) {
      let lst = [];
      const data = props.roleList;

      data.forEach((item) => {
        if (values.type === item.type) {
          lst.push(item.id);
        }
      });
      obj.roleId = lst;  
    }

    if (defaultRefArray.length > 0) {
      obj.id = defaultRefArray;
    }

    return (

     <ReferenceInput 
      filter={obj} 
      sort={{ field: 'firstName', order: 'ASC' }}
       {...props} 

       style={{ width: "400px" }}>
        <AssignSelectInput optionText={<OptionTextField />} />
      </ReferenceInput>
    );
  };

  const AssignSelectInput = (props) => {
    const { values } = useFormState();
    let choices = [];
    let apprvd_choices = [];
    if (props.choices && props.choices.length > 0) {
      props.choices.forEach((item) => {
        if (values.type && item.role && item.role.type !== values.type) {
          values.assignee = undefined;
        } else {
          choices.push(item);
        }
      });

      choices.forEach((item) => {
        let e = defaultRefArray;
        if (e.indexOf(item.id) === -1) {
          values.assignee = undefined;
        } else {
          apprvd_choices.push(item);
        }
      });

    }

    return <SelectInput {...props} choices={apprvd_choices} />;
  };

  const divStyle = {
    minWidth:"12vw",
    height:"2vw",
    borderRadius:"10px",
    backgroundColor:"#f4f4f4",
    padding:"1vw 1vw 1vw 1vw",
    margin:"auto auto auto 1vw"
  };

  return (
    <Create {...props} title="Outgoing Referrals - Create File">
      <SimpleForm variant="outlined" redirect={"/outgoings"}>
        <SectionTitle label="Basic Details" />
        <TextInput
          source="clientName"
          validate={[required()]}
          formClassName={classes.inline}
        />
        <NumberInput
          source="value"
          validate={[required()]}
          step={1}
          formClassName={classes.inline}
        />
        <TextInput multiline source="note" formClassName={classes.inline} />

        <SectionTitle label="Contact Details" />

        <TextInput
          source="clientEmail"
          validate={[required(), email()]}
          formClassName={classes.inline}
        />
        <TextInput
          source="clientPhoneNumber"
          validate={[required(), minLength(10), maxLength(12)]}
          formClassName={classes.inline}
        />
        <TextInput
          multiline
          source="clientAddress"
          formClassName={classes.inline}
        />
        <AutocompleteInput
          source="clientSuburb"
          optionText="suburb"
          resettable={true}
          clearAlwaysVisible={true}
          suggestionLimit={10}
          shouldRenderSuggestions={(val) => {
            return val.trim().length > 3;
          }}
          optionValue="suburb"
          choices={SUBURBS}
          formClassName={classes.inline}
        />
        <PostalCodeInput
          source="clientPostalCode"
          formClassName={classes.inline}
        />
        <StateInput source="clientState" formClassName={classes.inline} />

        <SectionTitle label="Referred to" />

        <ReferenceInput
          source="type"
          reference="types"
          label="Type"
          formClassName={classes.inline}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <AutocompleteInput
          source="assigneeSuburb"
          label="Referrer suburb"
          optionText="suburb"
          resettable={true}
          clearAlwaysVisible={true}
          suggestionLimit={10}
          shouldRenderSuggestions={(val) => {
            return val.trim().length > 3;
          }}
          optionValue="suburb"
          choices={SUBURBS}
          formClassName={classes.inline}
        />

        {defaultRefArray.length === 0 ?
         <div style={divStyle}> No referrals found...</div>
         :
        <AssignInput
          source="assignee"
          label="Referrer"
          reference="users"
          roleList={listRole}
          defaultRefArray={defaultRefArray}
          formClassName={classes.inline}
        />
        
        }
       

        <SectionTitle label="Files" />
        <FileInput
          source="files"
          label="Related files"
          formClassName={classes.uploadSection}
          multiple={true}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
