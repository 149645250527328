import * as React from "react";
import { useCallback } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  useDataProvider,
  SimpleShowLayout,
  CheckboxGroupInput,
  Edit,
  email,
  Toolbar,
  FormTab,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  maxLength,
  minLength,
  NumberInput,
  PasswordInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  ArrayField,
  Datagrid,
  TabbedForm,
  redirectionSaga,
  FunctionField,
  Labeled,
  Button,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { ReferralCreate } from "../Referrals/ReferralCreate";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import checkPermission from "../../helpers/permission_handler";
import CustomAside from "../CustomAside/CustomAside";
import { SUBURBS } from "../../constants/suburbs";
import { USERTYPES } from "../../constants/userTypes";
import RichTextInput from "ra-input-rich-text";
import { useFormState } from "react-final-form";
import { useForm } from "react-final-form";
import {
  Typography,
  Grid,
  GridListTileBar,
  IconButton,
} from "@material-ui/core";
import { EditButton } from "react-admin";
import CustomUrlField from "../CustomFields/CustomUrlField";
import { Field } from "react-final-form";

const _ = require("lodash");

const SectionTitle = ({ label }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

const PageTitle = ({ record }) => {
  return (
    <span>Users Manager - Edit User {record ? `${record.username}` : ""}</span>
  );
};

export const styles = {
  root: {
    "& .MuiFormControlLabel": {
      width: "20%",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  inline: {
    display: "inline-block",
    margin: "0 16px",
  },
  uploadSection: {
    background: "aliceblue",
    borderRadius: "12px",
    border: "1px dashed darkcyan",
    display: "inline-block",
    margin: "0 16px",
  },
  tabsContainer: {
    flexGrow: 1,
    paddingBottom: "1vw",
    width: "100%",
  },

  tabs: {
    minWidth: "50%",
    width: "50%",
  },

  CityInput: {
    width: "55vw",
    marginLeft: "1vw",
  },

  editFormContainer: {
    border: "1px solid red",
    width: "100%",
  },
  editReferralContainer: {},

  checkboxes: {
    backgroundColor: "blue",
  },

  checkbox: {
    border: "2px solid red",
    borderRadius: "0px",
    padding: "5vh 0vh 0vh 0vh",
    display: "inline-block",
  },

  label_checkbox: {
    border: "2px solid red",
    borderRadius: "0px",
    padding: "15vh 0vh 0vh 0vh",
    display: "inline-block",
  },

  button: {
    fontWeight: "bold",
    // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
    "& svg": { color: "orange" },

    link: {
      textDecoration: "none",
    },
    icon: {
      width: "0.5em",
      paddingLeft: 2,
    },
    options: { color: "green" },
  },

  "& .MuiFormControlLabel-root": {
    backgroundColor: "yellow",
    width: "150px",
  },

  span: {
    display: "inline-block",
  },

  inline_span: {
    display: "inline-block",
  },
};

const useStyles = makeStyles(styles);

const PostalCodeInput = (props) => {
  const { values } = useFormState();
  let choices;
  if (values.suburb) {
    SUBURBS.forEach((item) => {
      if (values.suburb === item.suburb) {
        choices = item;
      }
    });
  }

  return (
    <TextInput
      initialValue={choices ? choices.postcode : undefined}
      validate={[required()]}
      {...props}
    />
  );
};

const StateInput = (props) => {
  const { values } = useFormState();
  let choices;
  if (values.suburb) {
    SUBURBS.forEach((item) => {
      if (values.suburb === item.suburb) {
        choices = item;
      }
    });
  }

  return (
    <TextInput
      initialValue={choices ? choices.state : undefined}
      validate={[required()]}
      {...props}
    />
  );
};


// Define your custom contents of confirm dialog
const DeleteConfirmContent = (props) => {
  return <SimpleShowLayout {...props} />;
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    {permissions && checkPermission(permissions, { adminUser: ["delete"] }) && (
      <DeleteWithCustomConfirmButton
        title={"Are you sure?"}
        content={DeleteConfirmContent}
        label="Delete" // label of delete button (default: 'Delete')
        confirmColor="warning" // color of delete button ('warning' or 'primary', default: 'warning')
        ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
        cancel="Cancel" // label of cancel button (default: 'Cancel')
        CancelIcon={ErrorOutline} // icon of cancel button (default: 'ErrorOutline')
        undoable={false}
      />
    )}
  </Toolbar>
);

export const UserEdit = ({ permissions, ...props }) => {
  const classes = useStyles(props);
  const dataProvider = useDataProvider();
  const [tabValue, setTabValue] = React.useState(0);
  const [checkBoxArray, setCheckboxArray] = React.useState([]);
  const [defaultRefArray, setDefaultRefArray] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [roleBaseduserList, setRoleBasedUserList] = React.useState([]);
  const [currentRole, setCurrentRole] = React.useState("");
  const [ROLE_USER, setROLEUSER] = React.useState([]);
  const [currentRoleUser, setCurrentRoleUser] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [checked, setChecked] = React.useState(null);
  let objectArr = [];
  let defaultArr = [];
  const [listRole, setListRole] = React.useState([]);

  const user_roles = [
    "CONVEYANCER",
    "ACCOUNTANT",
    "MORTGAGE BROKER",
    "Software Engineer",
    "EQUIPMENT FINANCE",
    "Business Analyst",
    "Builder",
  ];
  
  React.useEffect(() => {
    dataProvider
      .getList("types", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "ASC" },
      })
      .then(({ data }) => {
        console.log("QWQWQ",_.map(data,"name"))
        var arr = _.map(data,"name")
        // arr = arr.map(function(x){ return x.toUpperCase(); })
        setListRole(arr);
        // setLoading(false);

        // Isadmin
        //   ? dataProvider
        //       .getList("users", {
        //         pagination: { page: 1, perPage: 50 },
        //         sort: { field: "firstName", order: "ASC" },
        //       })
        //       .then((result) => {
        //         if (result.data.length > 0) {
        //           let mapped_array = _.map(result.data, "id");
        //           let showOnlyTheseUsers = [];
        //           mapped_array.map((ele) => {
        //             if (!defaultArr.includes(ele)) {
        //               showOnlyTheseUsers.push(ele);
        //             }
        //           });
        //           setDefaultRefArray(showOnlyTheseUsers);
        //         }
        //       })
        //   : dataProvider
        //       .getOne("users", {
        //         id: JSON.parse(localStorage.getItem("addon_user")).id,
        //       })
        //       .then((response) => {
        //         response.data.referralusers.map((refUser) => {
        //           defaultArr.push(refUser.id);
        //         });

        //         setRefArray(defaultArr);
        //         setDefaultRefArray(defaultArr);
        //       });
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const ClearFieldsButton = ({ variant, ...props }) => {
    const form = useForm();
    var formdata = form.getState().values;
    const { values } = useFormState();
    let form_reff = _.uniqBy(values.referrals);
    let showResetLable = props.filtered_role_only_users.length > 0

   

    let _filtered = roleBaseduserList.filter((user) => {
      return user.role.name === formdata.country;
    });
    console.log("qwqwqw",_filtered)
    let _selected_id_list = _.map(_filtered, "id");
    let _mapped_roleBaseduserList = _.union(form_reff, _selected_id_list);

    let reset_filtered = roleBaseduserList.filter((user) => {
      return user.role.name === formdata.country;
    });
    let selected_ids_to_remove = _.map(reset_filtered, "id");
    let reset_mapped_roleBaseduserList = _.difference(
      selected_ids_to_remove,
      form_reff
    );

    const handleResetClick = useCallback(() => {
      let backup = values.referrals;
      let e = _.difference(backup, selected_ids_to_remove);
      form.change("referrals", e);
    }, [form]);

    const handleSelectAllClick = useCallback(() => {
      form.change("referrals", _mapped_roleBaseduserList);
    }, [form]);

    const handleSelectAllChange = (event) => {
      setChecked(event.target.checked);
      if (event.target.checked) {
        handleSelectAllClick();
        // setDefaultRefArray(_mapped_roleBaseduserList)
      } else {
        handleResetClick();
        // setDefaultRefArray(reset_mapped_roleBaseduserList)
      }
    };

    return (
      <>
        <Labeled
          label={
            <>
              <Checkbox
                style={{ fontSize: "40vw" }}
                size={"medium"}
                checked={checked}
                onChange={handleSelectAllChange}
              />
              <label style={{ fontSize: "1.25rem" }}>
                { showResetLable ? "Select all users..":"Reset Selection.." }
              </label>
            </>
          }
          formClassName={classes.inline}
        ></Labeled>
      </>
    );
  };

  const toChoices = (items) => items?.map((item) => ({ id: item, name: item }));

  function filtered(users, role) {
    let newUsers = users.filter((user) => user.role === role);
    return newUsers.map((user) => {
      if (user.role === role) {
        return {
          id: user.id,
          name: user.name,
        };
      } else {
        return null;
      }
    });
  }

  const divStyle = {
    minWidth: "175px",
    marginTop: "1vh",
    marginBottom: "1vh",
  };

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  const truncater = (name) => {
    return (
      <span
        style={{
          display: "block",
          whiteSpace: "nowrap",
          width: "10em",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </span>
    );
  };

  React.useEffect(() => {
    dataProvider
      .getList("users", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "firstName", order: "ASC" },
      })
      .then(({ data }) => {
        let mapped_array = _.map(data, "id");
        setUserList(mapped_array);
        setRoleBasedUserList(data);
        data.map((user) => {

          if (user.firstName === "Admin") {
            objectArr.push({
              id: user.id,
              name: "" + user.firstName,
              role: user.role.type,
            });
          } else {
            objectArr.push({
              id: user.id,
              name: truncater("" + user.firstName + " " + user.lastName + ""),
              role: user.role.type,
            });
          }
        });
        setCheckboxArray(objectArr);
        dataProvider.getOne("users", { id: props.id }).then((response) => {
          setDefaultRefArray([]);
          response.data.referralusers.map((refUser) => {
            defaultArr.push(refUser.id);
          });
          setDefaultRefArray(defaultArr);
        });
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, [
    setCheckboxArray,
    setRoleBasedUserList,
    setDefaultRefArray,
    setError,
    setLoading,
  ]);

  React.useEffect(() => {
    setDefaultRefArray([]);
    let _filtered = roleBaseduserList.filter((user) => {
      return user.role.type === currentRole;
    });
    let _mapped_roleBaseduserList = _.map(_filtered, "id");
    setDefaultRefArray(_mapped_roleBaseduserList);
  }, [checked, setChecked, setDefaultRefArray]);

  React.useEffect(() => {
    setDefaultRefArray([]);
    dataProvider.getOne("users", { id: props.id }).then((response) => {
      response.data.referralusers.map((refUser) => {
        defaultArr.push(refUser.id);
      });
      setDefaultRefArray(defaultArr);
    });
  }, []);

  React.useEffect(() => {
    setDefaultRefArray([]);
    dataProvider.getOne("users", { id: props.id }).then((response) => {
      response.data.referralusers.map((refUser) => {
        defaultArr.push(refUser.id);
      });
      setDefaultRefArray(defaultArr);
    });
  }, [setCheckboxArray]);

  React.useEffect(() => {}, [defaultRefArray]);

  const optionRenderer = (choice) => (
    <div style={divStyle}>{truncate(choice.name, 20)}</div>
  );

  const CustomReferralCheckboxGroup = (props) => {
    const { values } = useFormState();
    const f = filtered(checkBoxArray, values.country);


    return (
      <>
        <CheckboxGroupInput
          optionText={optionRenderer}
          choices={
            values.country ? filtered(checkBoxArray, values.country) : []
          }
          defaultValue={props.existingReferrals}
          {...props}
        />
      </>
    );
  };

  const EditForm = (
    <TabbedForm
      toolbar={<CustomToolbar permissions={permissions} />}
      className={styles.editFormContainer}
    >
      <FormTab label="Overview" className={styles.tabs}>
        <SectionTitle label="Identity" />
        <ReferenceInput
          source="roleId"
          reference="roles"
          label="User Type Name"
          validate={[required()]}
          formClassName={classes.inline}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="username"
          validate={[required()]}
          formClassName={classes.inline}
        />
        <TextInput
          source="firstName"
          validate={[required()]}
          formClassName={classes.inline}
        />
        <TextInput
          source="lastName"
          validate={[required()]}
          formClassName={classes.inline}
        />
        <DateInput source="dateOfBirth" formClassName={classes.inline} />
        <PasswordInput source="password" formClassName={classes.inline} />
        <SectionTitle label="Contact Details" />
        <TextInput
          source="email"
          validate={[required(), email()]}
          formClassName={classes.inline}
        />
        <TextInput
          source="phoneNumber"
          validate={[required(), minLength(10), maxLength(12)]}
          formClassName={classes.inline}
        />
        <TextInput
          multiline
          label="Address"
          source="address"
          validate={[required()]}
          formClassName={classes.inline}
        />
        <AutocompleteInput
          source="suburb"
          optionText="suburb"
          resettable={true}
          clearAlwaysVisible={true}
          suggestionLimit={20}
          shouldRenderSuggestions={(val) => {
            return val.trim().length > 3;
          }}
          optionValue="suburb"
          choices={SUBURBS}
          formClassName={classes.inline}
          validate={[required()]}
        />
        <PostalCodeInput source="postalCode" formClassName={classes.inline} />
        <StateInput source="state" formClassName={classes.inline} />
        <SectionTitle label="Business Details" />
        <TextInput
          source="businessName"
          validate={[required()]}
          formClassName={classes.inline}
        />
        <TextInput
          label="ABN"
          source="ABN"
          validate={[required()]}
          formClassName={classes.inline}
        />
        <TextInput label="ACN" source="ACN" formClassName={classes.inline} />
        <NumberInput
          source="commissionPercentage"
          min={0}
          max={100}
          formClassName={classes.inline}
        />
        <NumberInput source="flatRate" min={0} formClassName={classes.inline} />
        <SectionTitle label="Files" />
        <ImageInput
          source="avatar"
          label="Avatar"
          accept="image/*"
          formClassName={classes.uploadSection}
        >
          <ImageField source="avatar" title="title" />
        </ImageInput>
        <FileInput
          source="files"
          label="Related files"
          formClassName={classes.uploadSection}
          multiple={true}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <ImageField source="avatar" title="title" />
        <ArrayField source="user_files" label="">
          <Datagrid>
            <CustomUrlField
              label="Related files"
              source="filePath"
              fileName="fileName"
            />
          </Datagrid>
        </ArrayField>
        <SectionTitle label="Admin section" />
        <RichTextInput
          label="Note"
          source="adminNote"
          fullWidth={false}
          formClassName={classes.inline}
        />
        <BooleanInput
          source="isActive"
          label="(Inactive/Active)"
          formClassName={classes.inline}
        />
      </FormTab>
      <FormTab label="Manage Brokers">
        <SelectInput
          defaultValue={toChoices(listRole)[3]}
          label="User Type"
          source="country"
          choices={toChoices(listRole)}
          formClassName={classes.inline}
        />
                {/* <ReferenceInput
            alwaysOn
            source="type"
            reference="types"
            label="Type"
            variant="outlined"
        ><SelectInput optionText="name"/>
        </ReferenceInput> */}

        <FormDataConsumer
          formClassName={classes.inline}
          label="Clear Selection"
        >
          {({ formData, ...rest }) => {
            let e;
            let filtered_role_only_users;
            let role_user_list = [];
            let total_user_list = _.uniqBy(formData.referrals);
            if (formData.country !== undefined) {
              e = roleBaseduserList;
              e.map((user) => {
                if (user.role.type === formData.country) {
                  role_user_list.push(user.id);
                }
              });
              filtered_role_only_users= _.difference(role_user_list,total_user_list);
            }
            return (
              formData.country !== undefined && (
                <>
                  <ClearFieldsButton
                    label="Clear Selection"
                    formClassName={classes.inline}
                    existingReferrals={defaultRefArray}
                    roleBasedReferrals={role_user_list}
                    filtered_role_only_users={filtered_role_only_users}
                    {...rest}
                  />
                </>
              )
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer className={classes.CityInput}>
          {({ formData, ...rest }) => {
            let e;
            let f = [];
            let b = [];
            setCurrentRole(formData.country);


            if (formData.country !== undefined) {
              e = roleBaseduserList;
              e.map((user) => {
                if (user.role.type === formData.country) {
                  f.push(user);
                  b.push(user.id)
                }
              });

            }
            return (
              formData.country !== undefined && (
                <>
                  {defaultRefArray ? (
                    <CustomReferralCheckboxGroup
                      source="referrals"
                      label="Avaliable Brokers/Referrals"
                      existingReferrals={defaultRefArray}
                      roleBasedReferrals={f}
                      {...rest}
                    />
                  ) : (
                    <div>fetching referrals...</div>
                  )}
                </>
              )
            );
          }}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  );

  const TabPanel = (
    <Edit {...props} aside={<CustomAside {...props} />} title={<PageTitle />}>
      {EditForm}
    </Edit>
  );

  return <>{TabPanel}</>;
};
