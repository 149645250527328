import * as React from "react";
import {
    BulkDeleteButton,
    Datagrid,
    DeleteButton,
    List,
    ShowButton,
    TextField,
    downloadCSV
} from 'react-admin';

import checkPermission from "../../helpers/permission_handler";
import CustomDateTimeField from '../CustomFields/CustomDateTimeField'
import jsonExport from 'jsonexport/dist';
import moment from "moment";
const exporter = posts => {
    const postsForExport = posts.map(post => {
        return {
            name: post.name,
            createdAt: post.createdAt ? moment(post.createdAt).format('YYYY-MM-DD') : undefined,
        }
    });
    jsonExport(postsForExport, {
        headers: [] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'types'); // download as 'posts.csv` file
    });
};

const PostBulkActionButtons = props => (
    <React.Fragment>
        <BulkDeleteButton {...props} undoable={false}/>
    </React.Fragment>
);

export const TypeList = ({permissions, ...props}) => (
    <List {...props}
          title="Types"
          bulkActionButtons={permissions && checkPermission(permissions, {adminUserRole: ['delete']}) ?
              <PostBulkActionButtons/> : false}
          sort={{ field: 'createdAt', order: 'DESC' }}
          exporter={exporter}
    >
        <Datagrid
            rowClick={permissions && checkPermission(permissions, {adminUserRole: ['edit']}) ? 'edit' : 'show'}>
            <TextField source="name"/>
            <CustomDateTimeField source="createdAt" label="Created At"/>
            {permissions && checkPermission(permissions, {adminUserRole: ['delete']}) ? <DeleteButton/> :
                <ShowButton/>}
        </Datagrid>
    </List>
);