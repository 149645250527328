import * as React from "react";
import {useState, useEffect} from 'react';
import {useDataProvider} from 'react-admin';
import checkPermission from "../../helpers/permission_handler";
// import Welcome from "./Welcome";
import CardWithIcon from "./CardWithIcon";
import AssignmentIcon from '@material-ui/icons/Assignment';
import {Piechart} from "./Piechart";

export default ({permissions, ...props}) => {

    const styles = {
        flex: {display: 'flex'},
        flexColumn: {display: 'flex', flexDirection: 'column'},
        leftCol: {flex: 1, marginRight: '0.5em'},
        rightCol: {flex: 1, marginLeft: '0.5em'},
        singleCol: {marginTop: '1em', marginBottom: '1em'},
    };

    const Spacer = () => <span style={{width: '1em'}}/>;
    const VerticalSpacer = () => <span style={{height: '1em'}}/>;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const dataProvider = useDataProvider();

    //get all files
    const [files, setFiles] = useState();

    useEffect(() => {
        dataProvider.getOne('dashboard', {
            id: 'files'
        }).then(({data}) => {
            setFiles(data);
            setLoading(false);
        })
            .catch(error => {
                console.log(error);
                setError(error);
                setLoading(false);
            })
    }, []);

    //get MYFILES for count files
    const [outgoings, setOutgoings] = useState();

    useEffect(() => {
        dataProvider.getOne('dashboard', {
            id: 'outgoings'
        }).then(({data}) => {
            setOutgoings(data);
            setLoading(false);
        })
            .catch(error => {
                console.log(error);
                setError(error);
                setLoading(false);
            })
    }, []);

    //get REQUESTS for count files
    const [incomings, setIncomings] = useState();

    useEffect(() => {
        dataProvider.getOne('dashboard', {
            id: 'incomings'
        }).then(({data}) => {
            setIncomings(data);
            setLoading(false);
        })
            .catch(error => {
                console.log(error);
                setError(error);
                setLoading(false);
            })
    }, []);


    return (
        <>
            {/*<Welcome/>*/}
            {permissions && checkPermission(permissions, {adminFile: ['view']}) &&
            <>
                <div style={{...styles.flex, paddingTop: '3%' }}>
                    <CardWithIcon
                        to="/files"
                        icon={AssignmentIcon}
                        title="Pending Files"
                        subtitle={files ? `${files.pending}` : '0'}
                    />
                    <Spacer/>
                    <CardWithIcon
                        to="/files"
                        icon={AssignmentIcon}
                        title="Accepted Files"
                        subtitle={files ? `${files.accepted}` : '0'}
                    />
                    <Spacer/>
                    <CardWithIcon
                        to="/files"
                        icon={AssignmentIcon}
                        title="Settled Files"
                        subtitle={files ? `${files.settled}` : '0'}
                    />
                    <Spacer/>
                    <CardWithIcon
                        to="/files"
                        icon={AssignmentIcon}
                        title="Paid Files"
                        subtitle={files ? `${files.paid}` : '0'}
                    />
                    <Spacer/>
                    <CardWithIcon
                        to="/files"
                        icon={AssignmentIcon}
                        title="Declined Files"
                        subtitle={files ? `${files.declined}` : '0'}
                    />
                </div>
                <VerticalSpacer/>
            </>
            }
            {permissions && !checkPermission(permissions, {adminFile: ['view']}) &&
            <>
                <div style={{...styles.flex, paddingTop: '3%' }}>
                    <CardWithIcon
                        to="/outgoings"
                        icon={AssignmentIcon}
                        title="Pending Files"
                        subtitle={outgoings ? `${outgoings.pending}` : '0'}
                    />
                    <Spacer/>
                    <CardWithIcon
                        to="/outgoings"
                        icon={AssignmentIcon}
                        title="Accepted Files"
                        subtitle={outgoings ? `${outgoings.accepted}` : '0'}
                    />
                    <Spacer/>
                    <CardWithIcon
                        to="/outgoings"
                        icon={AssignmentIcon}
                        title="Settled Files"
                        subtitle={outgoings ? `${outgoings.settled}` : '0'}
                    />
                    <Spacer/>
                    <CardWithIcon
                        to="/outgoings"
                        icon={AssignmentIcon}
                        title="Paid Files"
                        subtitle={outgoings ? `${outgoings.paid}` : '0'}
                    />
                    <Spacer/>
                    <CardWithIcon
                        to="/outgoings"
                        icon={AssignmentIcon}
                        title="Declined Files"
                        subtitle={outgoings ? `${outgoings.declined}` : '0'}
                    />
                </div>
                <VerticalSpacer/>
            </>
            }
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <Piechart files={outgoings ? outgoings : {}} tittle="Outgoing Referrals"/>
                </div>
                <div style={styles.rightCol}>
                    <Piechart files={incomings ? incomings : {}} tittle="Incoming Referrals"/>
                </div>
            </div>
            <VerticalSpacer/>
            <div style={styles.flex}>
                <CardWithIcon
                    to="/outgoings"
                    icon={AssignmentIcon}
                    title="Outgoing Files"
                    subtitle={outgoings ? `${outgoings.total}` : '0'}
                />
                <Spacer/>
                <CardWithIcon
                    to="/incomings"
                    icon={AssignmentIcon}
                    title="Incoming Files"
                    subtitle={incomings ? `${incomings.total}` : '0'}
                />
            </div>
        </>
    )
};