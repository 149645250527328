import * as React from "react";
import {
    ArrayField,
    AutocompleteInput, BooleanInput, Datagrid,
    DateInput, DeleteButton,
    EditButton, email,
    EmailField, FileField, FileInput, ImageField, ImageInput, maxLength, minLength, NumberInput, PasswordInput,
    ReferenceField,
    ReferenceInput, required, SelectInput,
    Show, SimpleForm,
    SimpleShowLayout,
    TextField, TextInput, Toolbar,
    TopToolbar,
} from 'react-admin';
import DeleteWithCustomConfirmButton from 'ra-delete-with-custom-confirm-button';
import Delete from '@material-ui/icons/Delete';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import {makeStyles} from '@material-ui/core/styles';
import checkPermission from "../../helpers/permission_handler";
import CustomAside from "../CustomAside/CustomAside";
import {SUBURBS} from "../../constants/suburbs";
import CustomUrlField from "../CustomFields/CustomUrlField";
import {Typography} from '@material-ui/core';
import RichTextInput from "ra-input-rich-text";
import {FILESTATUS} from "../../constants/fileStatus";
import {USERTYPES} from "../../constants/userTypes";

const SectionTitle = ({label}) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

export const styles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    inline: {display: 'inline-block', margin: '0 16px'},
    uploadSection: {
        background: 'aliceblue',
        borderRadius: '12px',
        border: '1px dashed darkcyan',
        display: 'inline-block',
        margin: '0 16px'
    }
};

const useStyles = makeStyles(styles);

const PageTitle = ({record}) => {
    return <span>Outgoing Referrals - View File {record ? `${record.referencesNo}` : ''}</span>;
};

const UserShowActions = ({basePath, data, resource}) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data}/>
    </TopToolbar>
);

// Define your custom contents of confirm dialog
const DeleteConfirmContent = props => {
    return (
        <SimpleShowLayout {...props} />
    );
};

const CustomToolbar = ({permissions, ...props}) => (
    <Toolbar {...props} classes={useStyles()}>
        {permissions && checkPermission(permissions, {outgoing: ['delete']}) && props.record.status === FILESTATUS.PENDING &&
        <DeleteWithCustomConfirmButton
            title={"Are you sure?"}
            content={DeleteConfirmContent}
            label='Delete'                  // label of delete button (default: 'Delete')
            confirmColor='warning'          // color of delete button ('warning' or 'primary', default: 'warning')
            ConfirmIcon={Delete}            // icon of delete button (default: 'Delete')
            cancel='Cancel'                 // label of cancel button (default: 'Cancel')
            CancelIcon={ErrorOutline}       // icon of cancel button (default: 'ErrorOutline')
            undoable={false}/>}
    </Toolbar>
);

export const OutgoingShow = ({permissions, ...props}) => {
    const classes = useStyles(props);
    return (
        <Show {...props} aside={<CustomAside {...props}/>} title={<PageTitle/>}
              actions={permissions && checkPermission(permissions, {outgoing: ['edit']}) ?
                  <UserShowActions/> : <React.Fragment/>}>
            <SimpleForm
                toolbar={<CustomToolbar permissions={permissions}/>}
                variant="outlined"
                redirect={'/outgoings'}
            >
                <SectionTitle label="Basic Details"/>
                <TextInput source="clientName" disabled formClassName={classes.inline}/>
                <NumberInput source="value" disabled step={1} formClassName={classes.inline}/>
                <TextInput multiline source="note" disabled formClassName={classes.inline}/>
                <TextInput source="status" disabled formClassName={classes.inline}/>
                <DateInput source="assigneeAcceptedAt" disabled label="Date Accepted/Declined" formClassName={classes.inline}/>

                <SectionTitle label="Contact Details"/>

                <TextInput source="clientEmail" disabled formClassName={classes.inline}/>
                <TextInput source="clientPhoneNumber" disabled formClassName={classes.inline}/>
                <TextInput source="clientAddress" disabled formClassName={classes.inline}/>
                <TextInput multiline source="clientSuburb" disabled formClassName={classes.inline}/>
                <TextInput source="clientPostalCode" disabled formClassName={classes.inline}/>
                <TextInput source="clientState" disabled formClassName={classes.inline}/>

                <SectionTitle label="Referred to"/>

                <TextInput source="type" disabled formClassName={classes.inline}/>
                <TextInput source="assigneeSuburb" label="Referrer suburb" disabled formClassName={classes.inline}/>
                <ReferenceInput source="assignee" label="Referrer" reference="users" disabled formClassName={classes.inline}>
                    <SelectInput optionText="firstName"/>
                </ReferenceInput>
                <TextInput source="commissionPercentage" disabled formClassName={classes.inline}/>
                <TextInput source="flatRate" disabled formClassName={classes.inline}/>
                <TextInput multiline source="assigneeNote" label="Referrer note" disabled formClassName={classes.inline}/>

                <SectionTitle label="Files"/>
                <ArrayField source="file_uploads" label="">
                    <Datagrid>
                        <CustomUrlField label="Related files" source="filePath" fileName="fileName" />
                    </Datagrid>
                </ArrayField>
            </SimpleForm>
        </Show>
    );
};