import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery, Box, Avatar, Typography } from "@material-ui/core";
import UserIcon from "@material-ui/icons/Group";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import DescriptionIcon from "@material-ui/icons/Description";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DefaultAvatar from "../../Components/professional-image.jpg"

import {
  DashboardMenuItem,
  MenuItemLink,
  getResources,
  useGetIdentity,
} from "react-admin";

import SubMenu from "./SubMenu";

const useStyles = makeStyles(() => ({
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const CustomMenu = ({ onMenuClick, logout, dense = false }) => {
  const classes = useStyles();
  const resources = useSelector(getResources);
  const { identity } = useGetIdentity();
  const [state, setState] = useState({
    menuAdmin: false,
    menuReport: false,
  });
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box mt={1}>
      <Box
        alignItems="center"
        display={open ? "flex" : "none"}
        flexDirection="column"
        p={2}
      >
        <img
          src="/sidebarLogo.png"
          alt="logo"
          style={{ width: "115px", marginBottom: "10%" }}
        />
        {/* <Avatar
          className={classes.avatar}
          src={ DefaultAvatar}
        /> */}
        {/*<Typography*/}
        {/*    className={classes.name}*/}
        {/*    color="white"*/}
        {/*    variant="h5"*/}
        {/*>*/}
        {/*    {identity ? `${identity.firstName} ${identity.lastName}` : ''}*/}
        {/*</Typography>*/}
        {/*<Typography*/}
        {/*    color="white"*/}
        {/*    variant="body2"*/}
        {/*>*/}
        {/*    {identity ? identity.role.name : ''}*/}
        {/*</Typography>*/}
      </Box>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {identity &&
        identity.role &&
        identity.role.permissions &&
        ((identity.role.permissions.adminUser &&
          identity.role.permissions.adminUser.length > 0) ||
          (identity.role.permissions.adminUserRole &&
            identity.role.permissions.adminUserRole.length > 0) ||
          (identity.role.permissions.adminFile &&
            identity.role.permissions.adminFile.length > 0) ||
          (identity.role.permissions.adminReport &&
            identity.role.permissions.adminReport.length > 0)) && (
          <SubMenu
            handleToggle={() => handleToggle("menuAdmin")}
            isOpen={state.menuAdmin}
            sidebarIsOpen={open}
            name="Admin"
            icon={<SupervisorAccountIcon />}
            dense={dense}
          >
            {identity.role.permissions.adminUserRole &&
              identity.role.permissions.adminUserRole.length > 0 && (
                <MenuItemLink
                  key="types"
                  to={`/types`}
                  primaryText="Types"
                  sidebarIsOpen={open}
                  leftIcon={<PeopleIcon />}
                  onClick={onMenuClick}
                  dense={dense}
                />
              )}
            {identity.role.permissions.adminUserRole &&
              identity.role.permissions.adminUserRole.length > 0 && (
                <MenuItemLink
                  key="roles"
                  to={`/roles`}
                  primaryText="User Type"
                  sidebarIsOpen={open}
                  leftIcon={<PeopleIcon />}
                  onClick={onMenuClick}
                  dense={dense}
                />
              )}
            {identity.role.permissions.adminUser &&
              identity.role.permissions.adminUser.length > 0 && (
                <MenuItemLink
                  key="users"
                  to={`/users`}
                  primaryText="User Manager"
                  sidebarIsOpen={open}
                  leftIcon={<PersonIcon />}
                  onClick={onMenuClick}
                  dense={dense}
                />
              )}
            {identity.role.permissions.adminFile &&
              identity.role.permissions.adminFile.length > 0 && (
                <MenuItemLink
                  key="files"
                  to={`/files`}
                  primaryText="Files"
                  sidebarIsOpen={open}
                  leftIcon={<DescriptionIcon />}
                  onClick={onMenuClick}
                  dense={dense}
                />
              )}
            {identity.role.permissions.adminReport &&
              identity.role.permissions.adminReport.length > 0 && (
                <MenuItemLink
                  key="adminReport"
                  to={`/adminReport`}
                  primaryText="Report"
                  sidebarIsOpen={open}
                  leftIcon={<AssessmentIcon />}
                  onClick={onMenuClick}
                  dense={dense}
                />
              )}
          </SubMenu>
        )}
      {identity &&
        identity.role &&
        identity.role.permissions &&
        identity.role.permissions.outgoing &&
        identity.role.permissions.outgoing.length > 0 && (
          <MenuItemLink
            key="outgoings"
            to={`/outgoings`}
            primaryText="Outgoing Referrals"
            sidebarIsOpen={open}
            leftIcon={<CallMadeIcon />}
            onClick={onMenuClick}
            dense={dense}
          />
        )}
      {identity &&
        identity.role &&
        identity.role.permissions &&
        identity.role.permissions.incoming &&
        identity.role.permissions.incoming.length > 0 && (
          <MenuItemLink
            key="incoming"
            to={`/incomings`}
            primaryText="Incoming Referrals"
            sidebarIsOpen={open}
            leftIcon={<CallReceivedIcon />}
            onClick={onMenuClick}
            dense={dense}
          />
        )}
      {/* {identity &&
        identity.role &&
        identity.role.permissions &&
        identity.role.permissions.report &&
        identity.role.permissions.report.length > 0 && (
          <SubMenu
            handleToggle={() => handleToggle("menuReport")}
            isOpen={state.menuReport}
            sidebarIsOpen={open}
            name="Reports"
            icon={<AssessmentIcon />}
            dense={dense}
          >
            <MenuItemLink
              key="outgoingsReport"
              to={`/outgoingsReport`}
              primaryText="Outgoing Report"
              sidebarIsOpen={open}
              leftIcon={<CallMadeIcon />}
              onClick={onMenuClick}
              dense={dense}
            />
            <MenuItemLink
              key="incomingsReport"
              to={`/incomingsReport`}
              primaryText="Incoming Report"
              sidebarIsOpen={open}
              leftIcon={<CallReceivedIcon />}
              onClick={onMenuClick}
              dense={dense}
            />
          </SubMenu>
        )} */}
      {isXSmall && logout}
    </Box>
  );
};

export default CustomMenu;
