import * as React from "react";
import { Admin, Resource } from "react-admin";

import UserIcon from "@material-ui/icons/Group";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

import checkPermission from "./helpers/permission_handler";
import { UserList } from "./Components/User/UserList";
import { UserEdit } from "./Components/User/UserEdit";
import { UserCreate } from "./Components/User/UserCreate";
import { UserShow } from "./Components/User/UserShow";
import { ReferralList } from "./Components/Referrals/ReferralList";
import { ReferralEdit } from "./Components/Referrals/ReferralEdit";
import { ReferralCreate } from "./Components/Referrals/ReferralCreate";
import { ReferralShow } from "./Components/Referrals/ReferralShow";
import { RoleList } from "./Components/Role/RoleList";
import CustomMenuLayout from "./Components/CustomMenu/CustomMenuLayout";
import { RoleEdit } from "./Components/Role/RoleEdit";
import { RoleCreate } from "./Components/Role/RoleCreate";
import { RoleShow } from "./Components/Role/RoleShow";
import { FileList } from "./Components/File/FileList";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import { theme } from "./theme";
import { FileCreate } from "./Components/File/FileCreate";
import { FileEdit } from "./Components/File/FileEdit";
import { FileShow } from "./Components/File/FileShow";
import { OutgoingList } from "./Components/Outgoing/OutgoingList";
import { OutgoingShow } from "./Components/Outgoing/OutgoingShow";
import { OutgoingCreate } from "./Components/Outgoing/OutgoingCreate";
import { OutgoingEdit } from "./Components/Outgoing/OutgoingEdit";
import { IncomingList } from "./Components/Incoming/IncomingList";
import { IncomingShow } from "./Components/Incoming/IncomingShow";
import { IncomingEdit } from "./Components/Incoming/IncomingEdit";
import Dashboard from "./Components/Dashboard/Dashboard";
import CustomLoginPage from "./Components/CustomLogin/CustomLoginPage";
import {TypeList} from "./Components/Type/TypeList";
import {TypeShow} from "./Components/Type/TypeShow";
import {TypeEdit} from "./Components/Type/TypeEdit";
import {TypeCreate} from "./Components/Type/TypeCreate";
import {AdminReport} from "./Components/Report/AdminReport";
import {OutgoingsReport} from "./Components/Report/OutgoingsReport";
import {IncomingsReport} from "./Components/Report/IncomingsReport";

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    title="Addon Brokers"
    layout={CustomMenuLayout}
    theme={theme}
    dashboard={Dashboard}
    loginPage={CustomLoginPage}
  >
    {(permissions) => [
      checkPermission(permissions, {
        adminUser: ["view"],
        adminFile: ["view"],
        outgoing: ["view"],
        incoming: ["view"],
      }) ? (
        <Resource
          name="users"
          list={UserList}
          show={UserShow}
          edit={
            checkPermission(permissions, { adminUser: ["edit"] })
              ? UserEdit
              : null
          }
          create={
            checkPermission(permissions, { adminUser: ["create"] })
              ? UserCreate
              : null
          }
          icon={UserIcon}
        />
      ) : null,
      checkPermission(permissions, {
        adminUserRole: ["view"],
        adminFile: ["view"],
        outgoing: ["view"],
        incoming: ["view"],
      }) ? (
        <Resource
          name="roles"
          list={RoleList}
          show={RoleShow}
          edit={
            checkPermission(permissions, { adminUserRole: ["edit"] })
              ? RoleEdit
              : null
          }
          create={
            checkPermission(permissions, { adminUserRole: ["create"] })
              ? RoleCreate
              : null
          }
          icon={SupervisedUserCircleIcon}
        />
      ) : null,
      checkPermission(permissions, {
        adminUserRole: ["view"],
        adminFile: ["view"],
        outgoing: ["view"],
        incoming: ["view"],
      }) ? (
        <Resource
          name="types"
          list={TypeList}
          show={TypeShow}
          // edit={checkPermission(permissions, {adminUserRole: ['edit']}) ? TypeEdit : null}
          create={
            checkPermission(permissions, { adminUserRole: ["create"] })
              ? TypeCreate
              : null
          }
          icon={SupervisedUserCircleIcon}
        />
      ) : null,
      checkPermission(permissions, { adminFile: ["view"] }) ? (
        <Resource
          name="files"
          list={FileList}
          show={FileShow}
          create={
            checkPermission(permissions, { adminFile: ["create"] })
              ? FileCreate
              : null
          }
          edit={
            checkPermission(permissions, { adminFile: ["edit"] })
              ? FileEdit
              : null
          }
          icon={SupervisedUserCircleIcon}
        />
      ) : null,
      checkPermission(permissions, { outgoing: ["view"] }) ? (
        <Resource
          name="outgoings"
          list={OutgoingList}
          show={OutgoingShow}
          create={
            checkPermission(permissions, { outgoing: ["create"] })
              ? OutgoingCreate
              : null
          }
          edit={
            checkPermission(permissions, { outgoing: ["edit"] })
              ? OutgoingEdit
              : null
          }
          icon={SupervisedUserCircleIcon}
        />
        ) : null,
      checkPermission(permissions, { adminReport: ["create"] }) ? (
        <Resource
          name="adminReport"
          list={AdminReport}
          show={AdminReport}
          create={
            checkPermission(permissions, { adminReport: ["create"] })
              ? AdminReport
              : null
          }
          edit={
            checkPermission(permissions, { adminReport: ["edit"] })
              ? AdminReport
              : null
          }
          icon={SupervisedUserCircleIcon}
        />
      ) : null,
      checkPermission(permissions, { incoming: ["view"] }) ? (
        <Resource
          name="incomings"
          list={IncomingList}
          show={IncomingShow}
          edit={
            checkPermission(permissions, { incoming: ["edit"] })
              ? IncomingEdit
              : null
          }
          icon={SupervisedUserCircleIcon}
        />
      ) : null,
      checkPermission(permissions, {
        adminUser: ["view"],
        adminFile: ["view"],
        outgoing: ["view"],
        incoming: ["view"],
      }) ? (
        <Resource
          name="referrals"
          list={ReferralList}
          show={ReferralShow}
          edit={
            checkPermission(permissions, { adminUser: ["edit"] })
              ? ReferralEdit
              : null
          }
          create={
            checkPermission(permissions, { adminUser: ["create"] })
              ? ReferralCreate
              : null
          }
          icon={UserIcon}
        />
      ) : null,
    ]}
  </Admin>
);

export default App;
