import * as React from "react";
import {
    CheckboxGroupInput,
    Create, ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {USERTYPES} from "../../constants/userTypes";
import getUserTypes from "../../helpers/get_user_types";

const SectionTitle = ({label}) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

export const styles = {
    name: {display: 'inline-block'},
    type: {display: 'inline-block', marginLeft: 32},
};

const useStyles = makeStyles(styles);

export const RoleCreate = props => {
    const classes = useStyles(props);

    return (
        <Create
            {...props}
            title="User Types - Create User Type"
        >
            <SimpleForm
                variant="outlined"
                redirect={'/roles'}
            >
                <TextInput
                    source="name"
                    validate={[required()]}
                    formClassName={classes.name}
                />
                <ReferenceInput
                    source="type"
                    reference="types"
                    label="Type"
                    validate={[required()]}
                    formClassName={classes.inline}
                ><SelectInput optionText="name"/>
                </ReferenceInput>
                <SectionTitle label="User Permissions"/>
                <CheckboxGroupInput
                    source="permissions.outgoing"
                    label="Outgoing Referrals"
                    choices={[
                        {id: 'view', name: 'View'},
                        {id: 'create', name: 'Create'},
                        {id: 'edit', name: 'Edit'},
                        {id: 'delete', name: 'Delete'},
                    ]}
                />
                <CheckboxGroupInput
                    source="permissions.incoming"
                    label="Incoming Referrals"
                    choices={[
                        {id: 'view', name: 'View'},
                        {id: 'edit', name: 'Edit'},
                    ]}
                />
                <CheckboxGroupInput source="permissions.report" label="Reports" choices={[
                    {id: 'create', name: 'Create'},
                ]}/>
                <SectionTitle label="Admin Permissions"/>
                <CheckboxGroupInput
                    source="permissions.adminUser"
                    label="User Manager"
                    choices={[
                        {id: 'view', name: 'View'},
                        {id: 'create', name: 'Create'},
                        {id: 'edit', name: 'Edit'},
                        {id: 'delete', name: 'Delete'},
                    ]}
                />
                <CheckboxGroupInput source="permissions.adminUserRole" label="User Type" choices={[
                    {id: 'view', name: 'View'},
                    {id: 'create', name: 'Create'},
                    {id: 'edit', name: 'Edit'},
                    {id: 'delete', name: 'Delete'},
                ]}/>
                <CheckboxGroupInput source="permissions.adminFile" label="Files" choices={[
                    {id: 'view', name: 'View'},
                    {id: 'create', name: 'Create'},
                    {id: 'edit', name: 'Edit'},
                    {id: 'delete', name: 'Delete'},
                ]}/>
                <CheckboxGroupInput source="permissions.adminReport" label="Reports" choices={[
                    {id: 'create', name: 'Create'},
                ]}/>
            </SimpleForm>
        </Create>)
}