import * as React from "react";
import { Fragment } from "react";
import {
  Show,
  BulkDeleteButton,
  Create,
  required,
  SimpleForm,
  TextInput,
  List,
  Edit,
  BooleanInput,
  CheckboxGroupInput,
  useDataProvider,
} from "react-admin";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import RichTextInput from "ra-input-rich-text";
import { SUBURBS } from "../../constants/suburbs";
import { USERTYPES } from "../../constants/userTypes";
import { useFormState } from "react-final-form";
import checkPermission from "../../helpers/permission_handler";



const SectionTitle = ({ label }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

export const styles = {
  root: {
    "& .MuiListItem": {
      paddingTop: 0,
      paddingbottom: 0,
    },
  },

  item: {
    border: "0.5px solid lightGrey",
    borderRadius: "5px",
    paddingTop: "0",
    paddingBottom: "0",
    height: "4vw",
  },

  inline: { display: "inline-block", margin: "0 16px" },
  uploadSection: {
    background: "aliceblue",
    borderRadius: "12px",
    border: "1px dashed darkcyan",
    display: "inline-block",
    margin: "0 16px",
  },

  datagridContainer: {
    minWidth: "70vw",
    Width: "70%",
  },

  listItem: {
    listStyleType: "none",
    height: "25%",
    width: "20vw",
  },

  boolean: {
    margin: "auto",
  },

  listDiv: {
    // width:"20vw"
  },

  ListItemHeader: {
    marginLeft: "2.5vw",
    textTransform: "Capitalize",
  },

  ListContentContainer: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "20%",
    height: "40vh",
  },

  SimpleForm: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  checkboxArray:{
    width:"30vw",
    border:"1px solid red",
    paddingLeft:"1vw",
    paddingRight:"3vw"
  }
};

const useStyles = makeStyles(styles);

const StateInput = (props) => {
  const { values } = useFormState();
  let choices;
  if (values.suburb) {
    SUBURBS.forEach((item) => {
      if (values.suburb === item.suburb) {
        choices = item;
      }
    });
  }

  return (
    <TextInput
      initialValue={choices ? choices.state : undefined}
      validate={[required()]}
      {...props}
    />
  );
};

export const ReferralCreate = (props) => {
  const classes = useStyles(props);
  const [referals, setReferrals] = React.useState([]);
  const [checkBoxArray, setCheckboxArray] = React.useState([]);
  const [refObject, setRefObject] = React.useState([]);
  const [userlist, setUserList] = React.useState();
  const [formattedUserList, setFormattedUserList] = React.useState();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = React.useState(true);
  const [roleTypes, setRoleTypes] = React.useState(true);
  const [error, setError] = React.useState();
  let objectArr = [];
  const roleTypes_ = [];
  const filtered_roleTypes_ = [];

  const BulkAddReferral = (props) => {

  };

  const PostBulkActionButtons = (props) => (
    <Fragment>
      <BulkDeleteButton label="Hide Selected Referrals" {...props} />
    </Fragment>
  );

  const setReferralObject = (id) => {


    userlist.map((user) => {
      let obj = {};
      if (user.id === id) {
        obj = {
          id: user.id,
          lastName: user.lastName,
          firstName: user.firstName,
          role: user.role.name,
        };
        setRefObject((prevState) => [...prevState, obj]);
      }
    });
  };

  const checkForExistingRefs = (e) => {
    let mapped = referals.map((referal) => referal);
    return mapped.includes(e);
  };

  const handleRemoveReferal = (id) => {
    const newList = referals.filter((item) => item !== id);
    setReferrals(newList);
  };

  const handleRemoveReferalObject = (id) => {
    const newList = refObject.filter((item) => item.id !== id);
    setRefObject(newList);
  };

  const handleReferrals = (id) => {
    let found = checkForExistingRefs(id);
    found
      ? handleRemoveReferal(id)
      : setReferrals((prevState) => [...prevState, id]);

    found ? handleRemoveReferalObject(id) : setReferralObject(id);
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function handleUserData(data) {
    data.map((user) => {
      roleTypes_.push(user.role.type);
    });

    setRoleTypes(roleTypes_.filter(onlyUnique));

    let filteredData = [];

    roleTypes_.filter(onlyUnique).map((role_type) => {
      filteredData.push({
        type: role_type,
        userList: data.filter((user) => user.role.type === role_type),
      });
    });

    setFormattedUserList(filteredData);
    setLoading(false);
  }

  React.useEffect(() => {
    dataProvider
      .getList("users", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
      })
      .then(({ data }) => {
        data.map((user) => {
          objectArr.push({
            id: user.id,
            name: "" + user.firstName + " " + user.lastName + "",
            role: user.role.name,
          });
        });
        setCheckboxArray(objectArr);
        setUserList(data);
        handleUserData(data);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, [setFormattedUserList, setUserList, setReferrals, setError, setLoading]);

  React.useEffect(() => {
    console.log("data");
  }, [userlist]);

  React.useEffect(() => {
    console.log("updated referrals", referals);
  }, [referals]);

  React.useEffect(() => {
    console.log("updated referral object", refObject);
  }, [refObject]);

  const FullNameField = ({ record }) => (
    <span>{record.name}</span>
  );


  return (
    <Edit {...props}>
      <SimpleForm 
        variant="outlined"
        redirect={"/referrals"}
        className={classes.SimpleForm}
      >
        <CheckboxGroupInput
          source="referrals"
          choices={checkBoxArray}
          optionText={<FullNameField />}
          row={false}
        />
      </SimpleForm>
      </Edit>
  );
};
