import * as React from "react";
import {
    AutocompleteInput,
    BooleanInput, DateInput,
    DeleteButton,
    Edit,
    email, FileField, FileInput, ImageField, ImageInput, maxLength,
    minLength, NumberInput, PasswordInput,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    ArrayField,
    SingleFieldList,
    Datagrid,
    UrlField, useDataProvider
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

import checkPermission from "../../helpers/permission_handler";
import CustomAside from "../CustomAside/CustomAside";
import {SUBURBS} from "../../constants/suburbs";
import RichTextInput from "ra-input-rich-text";
import {useFormState} from "react-final-form";
import {Typography} from '@material-ui/core';
import CustomUrlField from "../CustomFields/CustomUrlField";
import {useState} from "react";
import {useEffect} from "react";
import {FILESTATUS} from "../../constants/fileStatus";
import {USERTYPES} from "../../constants/userTypes";

const SectionTitle = ({label}) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

const PageTitle = ({record}) => {
    return <span>Incoming Referrals - Edit File {record ? `${record.referencesNo}` : ''}</span>;
};

export const styles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    inline: {display: 'inline-block', margin: '0 16px'},
    uploadSection: {
        background: 'aliceblue',
        borderRadius: '12px',
        border: '1px dashed darkcyan',
        display: 'inline-block',
        margin: '0 16px'
    }
};

const useStyles = makeStyles(styles);

const PostalCodeInput = props => {
    const { values } = useFormState();
    let choices;
    if (values.clientSuburb) {
        SUBURBS.forEach((item) => {
            if (values.clientSuburb === item.suburb) {
                choices = item
            }
        })
    }

    return (
        <TextInput
            initialValue={choices ? choices.postcode : undefined}
            {...props}
        />
    );
};

const StateInput = props => {
    const { values } = useFormState();
    let choices;
    if (values.clientSuburb) {
        SUBURBS.forEach((item) => {
            if (values.clientSuburb === item.suburb) {
                choices = item
            }
        })
    }

    return (
        <TextInput
            initialValue={choices ? choices.state : undefined}
            {...props}
        />
    );
};

const CustomToolbar = ({permissions, ...props}) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const IncomingEdit = ({permissions, ...props}) => {
    const classes = useStyles(props);
    return (
        <Edit {...props} aside={<CustomAside {...props}/>} title={<PageTitle/>}>
            <SimpleForm toolbar={<CustomToolbar permissions={permissions}/>} variant="outlined">
                <SectionTitle label="Basic Details"/>
                <TextInput source="clientName" validate={[required()]} formClassName={classes.inline} disabled/>
                <NumberInput source="value" validate={[required()]} step={1} formClassName={classes.inline} disabled/>
                <TextInput multiline source="note" formClassName={classes.inline} disabled/>

                <SectionTitle label="Contact Details"/>

                <TextInput source="clientEmail" validate={[required(), email()]} formClassName={classes.inline} disabled/>
                <TextInput source="clientPhoneNumber" validate={[required(), minLength(10), maxLength(12)]} formClassName={classes.inline} disabled/>
                <TextInput multiline source="clientAddress" formClassName={classes.inline} disabled/>
                <AutocompleteInput
                    source="clientSuburb"
                    optionText="suburb"
                    resettable={true}
                    clearAlwaysVisible={true}
                    suggestionLimit={10}
                    shouldRenderSuggestions={(val) => { return val.trim().length > 3 }}
                    optionValue="suburb"
                    choices={SUBURBS}
                    formClassName={classes.inline}
                    disabled
                />
                <PostalCodeInput source="clientPostalCode" formClassName={classes.inline} disabled/>
                <StateInput source="clientState" formClassName={classes.inline} disabled/>

                <SectionTitle label="Referred to"/>

                <ReferenceInput
                    source="type"
                    reference="types"
                    label="Type"
                    formClassName={classes.inline}
                    disabled
                ><SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput source="commissionPercentage" disabled formClassName={classes.inline}/>
                <TextInput source="flatRate" disabled formClassName={classes.inline}/>
                <TextInput multiline source="assigneeNote" label="Referrer note" formClassName={classes.inline}/>
                <SelectInput
                    source="status"
                    choices={[
                        {id: FILESTATUS.PENDING, name: FILESTATUS.PENDING},
                        {id: FILESTATUS.ACCEPTED, name: FILESTATUS.ACCEPTED},
                        {id: FILESTATUS.DECLINED, name: FILESTATUS.DECLINED},
                        {id: FILESTATUS.PAID, name: FILESTATUS.PAID},
                        {id: FILESTATUS.SETTLED, name: FILESTATUS.SETTLED},
                    ]}
                    validate={[required()]}
                    formClassName={classes.inline}
                />
                <DateInput source="assigneeAcceptedAt" label="Date Accepted/Declined" formClassName={classes.inline} disabled/>

                <SectionTitle label="Files"/>
                <ArrayField source="file_uploads" label="">
                    <Datagrid>
                        <CustomUrlField label="Related files" source="filePath" fileName="fileName" />
                    </Datagrid>
                </ArrayField>
            </SimpleForm>
        </Edit>
    );
};