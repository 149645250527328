import _ from 'lodash';

const checkPermission = function (user, permissions, userTypes = [true]) {
    let userTypeMatch = false;
    if (!_.includes(userTypes, true)) {
        _.forEach(userTypes, (type) => {
            if (!_.isNull(user.role) && user.role.type === type) {
                userTypeMatch = true;
            }
        });
    } else {
        userTypeMatch = true;
    }

    if (userTypeMatch) {
        let permissionMatch = false;
        _.forEach(permissions, (permission, module) => {
            if (!_.isNull(user.role) && user.role) {
                if (user.role.permissions[module] && _.intersection(user.role.permissions[module], permission).length > 0) {
                    permissionMatch = true;
                }
            }
        });

        return permissionMatch;
    } else {
        return false;
    }
};


export default checkPermission;