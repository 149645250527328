import * as React from 'react';
import {AppBar} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import DefaultAvatar from "../../Components/professional-image.jpg"
import {Avatar } from "@material-ui/core";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    logo: {
        width: '200px',
        marginRight: '20px',
    },
    avatar:{
        position:"absolute",
        right:"125px",
        zIndex:+1,
        height:"35px",
        width:"35px"
    }
});

const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar
            {...props}
            elevation={1}
        >     
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            {/* <img src="/logo.png" alt="Logo" className={classes.logo}/> */}
            <span className={classes.spacer}/>
            {/* <Avatar
                className={classes.avatar}
                src={ DefaultAvatar}
                /> */}
        </AppBar>
    );
};

export default CustomAppBar;
