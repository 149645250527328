import * as React from "react";
import {
    ArrayField,
    AutocompleteInput, BooleanInput, Datagrid,
    DateInput, DeleteButton,
    EditButton, email,
    EmailField, FileField, FileInput, ImageField, ImageInput, maxLength, minLength, NumberInput, PasswordInput,
    ReferenceField,
    ReferenceInput, required, SelectInput,
    Show, SimpleForm,
    SimpleShowLayout,
    TextField, TextInput, Toolbar,
    TopToolbar
} from 'react-admin';
import DeleteWithCustomConfirmButton from 'ra-delete-with-custom-confirm-button';
import Delete from '@material-ui/icons/Delete';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import {makeStyles} from '@material-ui/core/styles';
import checkPermission from "../../helpers/permission_handler";
import CustomAside from "../CustomAside/CustomAside";
import {SUBURBS} from "../../constants/suburbs";
import CustomUrlField from "../CustomFields/CustomUrlField";
import {Typography} from '@material-ui/core';
import RichTextInput from "ra-input-rich-text";

const SectionTitle = ({label}) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

export const styles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    inline: {display: 'inline-block', margin: '0 16px'},
    uploadSection: {
        background: 'aliceblue',
        borderRadius: '12px',
        border: '1px dashed darkcyan',
        display: 'inline-block',
        margin: '0 16px'
    }
};

const useStyles = makeStyles(styles);

const PageTitle = ({record}) => {
    return <span>User Manager - View User {record ? `${record.username}` : ''}</span>;
};

const UserShowActions = ({basePath, data, resource}) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data}/>
    </TopToolbar>
);

// Define your custom contents of confirm dialog
const DeleteConfirmContent = props => {
    return (
        <SimpleShowLayout {...props} />
    );
};

const CustomToolbar = ({permissions, ...props}) => (
    <Toolbar {...props} classes={useStyles()}>
        {permissions && checkPermission(permissions, {adminUserRole: ['delete']}) &&
        <DeleteWithCustomConfirmButton
            title={"Are you sure?"}
            content={DeleteConfirmContent}
            label='Delete'                  // label of delete button (default: 'Delete')
            confirmColor='warning'          // color of delete button ('warning' or 'primary', default: 'warning')
            ConfirmIcon={Delete}            // icon of delete button (default: 'Delete')
            cancel='Cancel'                 // label of cancel button (default: 'Cancel')
            CancelIcon={ErrorOutline}       // icon of cancel button (default: 'ErrorOutline')
            undoable={false}/>}
    </Toolbar>
);

export const UserShow = ({permissions, ...props}) => {
    const classes = useStyles(props);
    return (
        <Show {...props} aside={<CustomAside {...props}/>} title={<PageTitle/>}
              actions={permissions && checkPermission(permissions, {adminUser: ['edit']}) ?
                  <UserShowActions/> : <React.Fragment/>}>
            <SimpleForm
                toolbar={<CustomToolbar permissions={permissions}/>}
                variant="outlined"
                redirect={'/users'}
            >
                <SectionTitle label="Identity"/>
                <ReferenceInput
                    source="roleId"
                    reference="roles"
                    label="User Type"
                    formClassName={classes.inline}
                    disabled
                ><SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput source="username" disabled formClassName={classes.inline}/>
                <TextInput source="firstName" disabled formClassName={classes.inline}/>
                <TextInput source="lastName" disabled formClassName={classes.inline}/>
                <DateInput source="dateOfBirth" disabled formClassName={classes.inline}/>
                <PasswordInput source="password" disabled formClassName={classes.inline}/>
                <SectionTitle label="Contact disabled Details"/>
                <TextInput source="email" disabled formClassName={classes.inline}/>
                <TextInput source="phoneNumber"
                           formClassName={classes.inline} disabled/>
                <TextInput multiline label="Address" source="address"
                           formClassName={classes.inline} disabled/>
                <TextInput source="suburb" formClassName={classes.inline} disabled/>
                <TextInput source="postalCode" formClassName={classes.inline} disabled/>
                <TextInput source="state" formClassName={classes.inline} disabled/>
                <SectionTitle label="Business Details"/>
                <TextInput source="businessName" validate={[required()]} formClassName={classes.inline} disabled/>
                <TextInput label="ABN" source="ABN" validate={[required()]} formClassName={classes.inline} disabled/>
                <TextInput label="ACN" source="ACN" formClassName={classes.inline} disabled/>
                <NumberInput source="commissionPercentage" min={0} max={100} formClassName={classes.inline} disabled/>
                <NumberInput source="flatRate" min={0} formClassName={classes.inline} disabled/>
                <SectionTitle label="Files"/>
                <ImageField source="avatar" title="title"/>
                <ArrayField source="user_files" label="">
                    <Datagrid>
                        <CustomUrlField label="Related files" source="filePath" fileName="fileName" />
                    </Datagrid>
                </ArrayField>
                <SectionTitle label="Admin section"/>
                <RichTextInput label="Note" source="adminNote" fullWidth={false} formClassName={classes.inline} disabled/>
                <BooleanInput source="isActive" label="(Inactive/Active)" formClassName={classes.inline} disabled/>
            </SimpleForm>
        </Show>
    );
};