export const SUBURBS = [
    {
        "suburb": "East Albury",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Lavington",
        "postcode": "2641",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenroy",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Albury",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "North Albury",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Table Top",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Splitters Creek",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "West Albury",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Springdale Heights",
        "postcode": "2641",
        "state": "New South Wales"
    },
    {
        "suburb": "Thurgoona",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "South Albury",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Wirlinga",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Hamilton Valley",
        "postcode": "2641",
        "state": "New South Wales"
    },
    {
        "suburb": "Jindera",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Armidale",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Boorolong",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Dumaresq",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Duval",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Donald Creek",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Black Mountain",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Aberfoyle",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Thalgarrah",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollomombi",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Jeogla",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Ebor",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Dangarsleigh",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Metz",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Hillgrove",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Castle Doyle",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Enmore",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Creek",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrai",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Tilbuster",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Kellys Plains",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Wongwibinda",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Lyndhurst",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Puddledock",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Croydon Park",
        "postcode": "2133",
        "state": "New South Wales"
    },
    {
        "suburb": "Croydon",
        "postcode": "2132",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashfield",
        "postcode": "2131",
        "state": "New South Wales"
    },
    {
        "suburb": "Haberfield",
        "postcode": "2045",
        "state": "New South Wales"
    },
    {
        "suburb": "Summer Hill",
        "postcode": "2130",
        "state": "New South Wales"
    },
    {
        "suburb": "Hurlstone Park",
        "postcode": "2193",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashbury",
        "postcode": "2193",
        "state": "New South Wales"
    },
    {
        "suburb": "Guyong",
        "postcode": "2798",
        "state": "New South Wales"
    },
    {
        "suburb": "Newington",
        "postcode": "2127",
        "state": "New South Wales"
    },
    {
        "suburb": "Lidcombe",
        "postcode": "2141",
        "state": "New South Wales"
    },
    {
        "suburb": "Berala",
        "postcode": "2141",
        "state": "New South Wales"
    },
    {
        "suburb": "Auburn",
        "postcode": "2144",
        "state": "New South Wales"
    },
    {
        "suburb": "Sydney Olympic Park",
        "postcode": "2127",
        "state": "New South Wales"
    },
    {
        "suburb": "Silverwater",
        "postcode": "2128",
        "state": "New South Wales"
    },
    {
        "suburb": "Regents Park",
        "postcode": "2143",
        "state": "New South Wales"
    },
    {
        "suburb": "Wentworth Point",
        "postcode": "2127",
        "state": "New South Wales"
    },
    {
        "suburb": "Rookwood",
        "postcode": "2141",
        "state": "New South Wales"
    },
    {
        "suburb": "East Ballina",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Tintenbar",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Cumbalum",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Broken Head",
        "postcode": "2481",
        "state": "New South Wales"
    },
    {
        "suburb": "Lennox Head",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Newrybar",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Knockrow",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Skennars Head",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Wardell",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "West Ballina",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Alstonvale",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "East Wardell",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollongbar",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Bagotville",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Ballina",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Fernleigh",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Meerschaum Vale",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Keith Hall",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "South Ballina",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Teven",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Alstonville",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Cabbage Tree Island",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Goat Island",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Pimlico Island",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Pimlico",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Rous Mill",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Dalwood",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Marom Creek",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Patchs Beach",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Rous",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Lynwood",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Uralba",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Empire Vale",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuckombil",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Brooklet",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolgardie",
        "postcode": "2478",
        "state": "New South Wales"
    },
    {
        "suburb": "McLeans Ridges",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Euston",
        "postcode": "2737",
        "state": "New South Wales"
    },
    {
        "suburb": "Balranald",
        "postcode": "2715",
        "state": "New South Wales"
    },
    {
        "suburb": "Hatfield",
        "postcode": "2715",
        "state": "New South Wales"
    },
    {
        "suburb": "Oxley",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Ivanhoe",
        "postcode": "2878",
        "state": "New South Wales"
    },
    {
        "suburb": "Mossgiel",
        "postcode": "2878",
        "state": "New South Wales"
    },
    {
        "suburb": "Corrong",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Arumpo",
        "postcode": "2715",
        "state": "New South Wales"
    },
    {
        "suburb": "Hornsby",
        "postcode": "2077",
        "state": "New South Wales"
    },
    {
        "suburb": "Clare",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Kyalite",
        "postcode": "2715",
        "state": "New South Wales"
    },
    {
        "suburb": "Mungo",
        "postcode": "2715",
        "state": "New South Wales"
    },
    {
        "suburb": "Booligal",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Condell Park",
        "postcode": "2200",
        "state": "New South Wales"
    },
    {
        "suburb": "Bankstown",
        "postcode": "2200",
        "state": "New South Wales"
    },
    {
        "suburb": "Punchbowl",
        "postcode": "2196",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Lewis",
        "postcode": "2190",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenacre",
        "postcode": "2190",
        "state": "New South Wales"
    },
    {
        "suburb": "Yagoona",
        "postcode": "2199",
        "state": "New South Wales"
    },
    {
        "suburb": "Chullora",
        "postcode": "2190",
        "state": "New South Wales"
    },
    {
        "suburb": "Bonny Hills",
        "postcode": "2445",
        "state": "New South Wales"
    },
    {
        "suburb": "Georges Hall",
        "postcode": "2198",
        "state": "New South Wales"
    },
    {
        "suburb": "Villawood",
        "postcode": "2163",
        "state": "New South Wales"
    },
    {
        "suburb": "Bass Hill",
        "postcode": "2197",
        "state": "New South Wales"
    },
    {
        "suburb": "Chester Hill",
        "postcode": "2162",
        "state": "New South Wales"
    },
    {
        "suburb": "Sefton",
        "postcode": "2162",
        "state": "New South Wales"
    },
    {
        "suburb": "Revesby",
        "postcode": "2212",
        "state": "New South Wales"
    },
    {
        "suburb": "Lansdowne",
        "postcode": "2163",
        "state": "New South Wales"
    },
    {
        "suburb": "Potts Hill",
        "postcode": "2143",
        "state": "New South Wales"
    },
    {
        "suburb": "Birrong",
        "postcode": "2143",
        "state": "New South Wales"
    },
    {
        "suburb": "Bankstown Aerodrome",
        "postcode": "2200",
        "state": "New South Wales"
    },
    {
        "suburb": "Milperra",
        "postcode": "2214",
        "state": "New South Wales"
    },
    {
        "suburb": "East Hills",
        "postcode": "2213",
        "state": "New South Wales"
    },
    {
        "suburb": "Panania",
        "postcode": "2213",
        "state": "New South Wales"
    },
    {
        "suburb": "Picnic Point",
        "postcode": "2213",
        "state": "New South Wales"
    },
    {
        "suburb": "Revesby Heights",
        "postcode": "2212",
        "state": "New South Wales"
    },
    {
        "suburb": "Padstow",
        "postcode": "2211",
        "state": "New South Wales"
    },
    {
        "suburb": "Padstow Heights",
        "postcode": "2211",
        "state": "New South Wales"
    },
    {
        "suburb": "Eglinton",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "West Bathurst",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Bathurst",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "South Bathurst",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Kelso",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Raglan",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Panorama",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Mitchell",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "White Rock",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Windradyne",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Gormans Hill",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Orton Park",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Forest Grove",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Llanarth",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Abercrombie",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Robin Hill",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Laffing Waters",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Georges Plains",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Wimbledon",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Wisemans Creek",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Charlton",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Curragh",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Abercrombie River",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Caloola",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Copperhannia",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Colo",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Arkell",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Cow Flat",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Fosters Valley",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Trunkey Creek",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Triangle Flat",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Meadow Flat",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Millah Murrah",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Wiagdon",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Clear Creek",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Turon",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Sunny Corner",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Yetholme",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Locksley",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Milkers Flat",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Sallys Flat",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Kirkconnell",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Gemalla",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Gowan",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Freemantle",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Killongbutta",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Bruinbun",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Peel",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Crudine",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Glanmire",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Paling Yards",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Napoleon Reef",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarana",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Wattle Flat",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Sofala",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Walang",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Tambaroora",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Duramana",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Rankin",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Turondale",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Billywillinga",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Rock Forest",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Watton",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarras",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "The Rocks",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Bathampton",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Dunkeld",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Vittoria",
        "postcode": "2799",
        "state": "New South Wales"
    },
    {
        "suburb": "Fitzgeralds Valley",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Fitzgeralds Mount",
        "postcode": "2799",
        "state": "New South Wales"
    },
    {
        "suburb": "O'Connell",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Rockley Mount",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Brewongle",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "The Lagoon",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Bald Ridge",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Bella Vista",
        "postcode": "2153",
        "state": "New South Wales"
    },
    {
        "suburb": "Baulkham Hills",
        "postcode": "2153",
        "state": "New South Wales"
    },
    {
        "suburb": "Castle Hill",
        "postcode": "2154",
        "state": "New South Wales"
    },
    {
        "suburb": "Kellyville",
        "postcode": "2155",
        "state": "New South Wales"
    },
    {
        "suburb": "West Pennant Hills",
        "postcode": "2125",
        "state": "New South Wales"
    },
    {
        "suburb": "Winston Hills",
        "postcode": "2153",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenhaven",
        "postcode": "2156",
        "state": "New South Wales"
    },
    {
        "suburb": "Rouse Hill",
        "postcode": "2155",
        "state": "New South Wales"
    },
    {
        "suburb": "Beaumont Hills",
        "postcode": "2155",
        "state": "New South Wales"
    },
    {
        "suburb": "Kenthurst",
        "postcode": "2156",
        "state": "New South Wales"
    },
    {
        "suburb": "Cattai",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Dural",
        "postcode": "2158",
        "state": "New South Wales"
    },
    {
        "suburb": "Wisemans Ferry",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Portland",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Maroota",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Sackville North",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "South Maroota",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenorie",
        "postcode": "2157",
        "state": "New South Wales"
    },
    {
        "suburb": "Maraylya",
        "postcode": "2765",
        "state": "New South Wales"
    },
    {
        "suburb": "Middle Dural",
        "postcode": "2158",
        "state": "New South Wales"
    },
    {
        "suburb": "Box Hill",
        "postcode": "2765",
        "state": "New South Wales"
    },
    {
        "suburb": "Nelson",
        "postcode": "2765",
        "state": "New South Wales"
    },
    {
        "suburb": "Annangrove",
        "postcode": "2156",
        "state": "New South Wales"
    },
    {
        "suburb": "North Rocks",
        "postcode": "2151",
        "state": "New South Wales"
    },
    {
        "suburb": "Oatlands",
        "postcode": "2117",
        "state": "New South Wales"
    },
    {
        "suburb": "Carlingford",
        "postcode": "2118",
        "state": "New South Wales"
    },
    {
        "suburb": "Northmead",
        "postcode": "2152",
        "state": "New South Wales"
    },
    {
        "suburb": "North Parramatta",
        "postcode": "2151",
        "state": "New South Wales"
    },
    {
        "suburb": "Wonboyn North",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Mumbulla Mountain",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Angledale",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Frogs Hollow",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Kanoona",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Devils Hole",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Morans Crossing",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Yellow Pinch",
        "postcode": "2548",
        "state": "New South Wales"
    },
    {
        "suburb": "Bermagui",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Barragga Bay",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Cuttagee",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Cape",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Tinpot",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarraganda",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Tanja",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Edrom",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Wolumla",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Quaama",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Pambula",
        "postcode": "2549",
        "state": "New South Wales"
    },
    {
        "suburb": "Eden",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Bega",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Merimbula",
        "postcode": "2548",
        "state": "New South Wales"
    },
    {
        "suburb": "Pambula Beach",
        "postcode": "2549",
        "state": "New South Wales"
    },
    {
        "suburb": "Candelo",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyndham",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingswood",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "South Pambula",
        "postcode": "2549",
        "state": "New South Wales"
    },
    {
        "suburb": "Mirador",
        "postcode": "2548",
        "state": "New South Wales"
    },
    {
        "suburb": "Berrambool",
        "postcode": "2548",
        "state": "New South Wales"
    },
    {
        "suburb": "Tathra",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallaga Lake",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Wapengo",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallagoot",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Boydtown",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Kalaru",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Nungatta",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Nungatta South",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Chinnock",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Nelson",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Bemboka",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Millingandi",
        "postcode": "2549",
        "state": "New South Wales"
    },
    {
        "suburb": "Nullica",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Steeple Flat",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Darragh",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "New Buildings",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Kiah",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Greendale",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Numbugga",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Brogo",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Tantawangalo",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Burragate",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "South Wolumla",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Towamba",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Mogilla",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Yowrie",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Lochiel",
        "postcode": "2549",
        "state": "New South Wales"
    },
    {
        "suburb": "Verona",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Nethercote",
        "postcode": "2549",
        "state": "New South Wales"
    },
    {
        "suburb": "Buckajo",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolagolite",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Tura Beach",
        "postcode": "2548",
        "state": "New South Wales"
    },
    {
        "suburb": "Cobargo",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Dignams Creek",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Wandella",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Stony Creek",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Toothdale",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Pericoe",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Myrtle Mountain",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Rocky Hall",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Broadwater",
        "postcode": "2549",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrah",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Bournda",
        "postcode": "2548",
        "state": "New South Wales"
    },
    {
        "suburb": "Wonboyn",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Greigs Flat",
        "postcode": "2549",
        "state": "New South Wales"
    },
    {
        "suburb": "Black Range",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Narrabarba",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Kameruka",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Bald Hills",
        "postcode": "2549",
        "state": "New South Wales"
    },
    {
        "suburb": "Yankees Creek",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolangubra",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Jellat Jellat",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Wadbilliga",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Nadgee",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Timbillica",
        "postcode": "2551",
        "state": "New South Wales"
    },
    {
        "suburb": "Wog Wog",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Cathcart",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Reedy Swamp",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellingen",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Urunga",
        "postcode": "2455",
        "state": "New South Wales"
    },
    {
        "suburb": "Mylestom",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Megan",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Deer Vale",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Cascade",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Kalang",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Spicketts Creek",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Valla",
        "postcode": "2448",
        "state": "New South Wales"
    },
    {
        "suburb": "Bostobrick",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Darkwood",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Brierfield",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Tallowwood Ridge",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Thora",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Gleniffer",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Never Never",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Valery",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Fernmount",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Brinerville",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Dorrigo",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Repton",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Kennaicle Creek",
        "postcode": "2449",
        "state": "New South Wales"
    },
    {
        "suburb": "Fernbrook",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Dorrigo Mountain",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Bielsdown Hills",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "North Dorrigo",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Raleigh",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Tocumwal",
        "postcode": "2714",
        "state": "New South Wales"
    },
    {
        "suburb": "Finley",
        "postcode": "2713",
        "state": "New South Wales"
    },
    {
        "suburb": "Berrigan",
        "postcode": "2712",
        "state": "New South Wales"
    },
    {
        "suburb": "Barooga",
        "postcode": "3644",
        "state": "New South Wales"
    },
    {
        "suburb": "Boomanoomana",
        "postcode": "2712",
        "state": "New South Wales"
    },
    {
        "suburb": "Savernake",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Lalalty",
        "postcode": "3644",
        "state": "New South Wales"
    },
    {
        "suburb": "Mulwala",
        "postcode": "2647",
        "state": "New South Wales"
    },
    {
        "suburb": "Dean Park",
        "postcode": "2761",
        "state": "New South Wales"
    },
    {
        "suburb": "Riverstone",
        "postcode": "2765",
        "state": "New South Wales"
    },
    {
        "suburb": "Quakers Hill",
        "postcode": "2763",
        "state": "New South Wales"
    },
    {
        "suburb": "Marayong",
        "postcode": "2148",
        "state": "New South Wales"
    },
    {
        "suburb": "Kings Park",
        "postcode": "2148",
        "state": "New South Wales"
    },
    {
        "suburb": "Kings Langley",
        "postcode": "2147",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenwood",
        "postcode": "2768",
        "state": "New South Wales"
    },
    {
        "suburb": "Colebee",
        "postcode": "2761",
        "state": "New South Wales"
    },
    {
        "suburb": "Marsden Park",
        "postcode": "2765",
        "state": "New South Wales"
    },
    {
        "suburb": "Shanes Park",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Acacia Gardens",
        "postcode": "2763",
        "state": "New South Wales"
    },
    {
        "suburb": "Parklea",
        "postcode": "2768",
        "state": "New South Wales"
    },
    {
        "suburb": "Stanhope Gardens",
        "postcode": "2768",
        "state": "New South Wales"
    },
    {
        "suburb": "Schofields",
        "postcode": "2762",
        "state": "New South Wales"
    },
    {
        "suburb": "Kellyville Ridge",
        "postcode": "2155",
        "state": "New South Wales"
    },
    {
        "suburb": "Fairy Meadow",
        "postcode": "2519",
        "state": "New South Wales"
    },
    {
        "suburb": "Balgownie",
        "postcode": "2519",
        "state": "New South Wales"
    },
    {
        "suburb": "Towradgi",
        "postcode": "2518",
        "state": "New South Wales"
    },
    {
        "suburb": "North Wollongong",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "East Corrimal",
        "postcode": "2518",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollongong",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Keira",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "West Wollongong",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "Mangerton",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "Coniston",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Saint Thomas",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "Port Kembla",
        "postcode": "2505",
        "state": "New South Wales"
    },
    {
        "suburb": "Doonside",
        "postcode": "2767",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodcroft",
        "postcode": "2767",
        "state": "New South Wales"
    },
    {
        "suburb": "Blacktown",
        "postcode": "2148",
        "state": "New South Wales"
    },
    {
        "suburb": "Prospect",
        "postcode": "2148",
        "state": "New South Wales"
    },
    {
        "suburb": "Seven Hills",
        "postcode": "2147",
        "state": "New South Wales"
    },
    {
        "suburb": "Lalor Park",
        "postcode": "2147",
        "state": "New South Wales"
    },
    {
        "suburb": "Toongabbie",
        "postcode": "2146",
        "state": "New South Wales"
    },
    {
        "suburb": "Huntingwood",
        "postcode": "2148",
        "state": "New South Wales"
    },
    {
        "suburb": "Arndell Park",
        "postcode": "2148",
        "state": "New South Wales"
    },
    {
        "suburb": "Eastern Creek",
        "postcode": "2766",
        "state": "New South Wales"
    },
    {
        "suburb": "Tregear",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Lethbridge Park",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Shalvey",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Whalan",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Druitt",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Blackett",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Dharruk",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Hebersham",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Minchinbury",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Rooty Hill",
        "postcode": "2766",
        "state": "New South Wales"
    },
    {
        "suburb": "Hassall Grove",
        "postcode": "2761",
        "state": "New South Wales"
    },
    {
        "suburb": "Glendenning",
        "postcode": "2761",
        "state": "New South Wales"
    },
    {
        "suburb": "Emerton",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Bidwill",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Willmot",
        "postcode": "2770",
        "state": "New South Wales"
    },
    {
        "suburb": "Oakhurst",
        "postcode": "2761",
        "state": "New South Wales"
    },
    {
        "suburb": "Plumpton",
        "postcode": "2761",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungarribee",
        "postcode": "2767",
        "state": "New South Wales"
    },
    {
        "suburb": "Ropes Crossing",
        "postcode": "2760",
        "state": "New South Wales"
    },
    {
        "suburb": "Naradhan",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "Weethalle",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "Ungarie",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "West Wyalong",
        "postcode": "2671",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyalong",
        "postcode": "2671",
        "state": "New South Wales"
    },
    {
        "suburb": "Girral",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "Back Creek",
        "postcode": "2671",
        "state": "New South Wales"
    },
    {
        "suburb": "Barmedman",
        "postcode": "2668",
        "state": "New South Wales"
    },
    {
        "suburb": "Barellan",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Kikoira",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "Beckom",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Mirrool",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Ariah Park",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Alleena",
        "postcode": "2671",
        "state": "New South Wales"
    },
    {
        "suburb": "North Yalgogrin",
        "postcode": "2671",
        "state": "New South Wales"
    },
    {
        "suburb": "Tallimba",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Cowal",
        "postcode": "2671",
        "state": "New South Wales"
    },
    {
        "suburb": "Bland",
        "postcode": "2721",
        "state": "New South Wales"
    },
    {
        "suburb": "Quandialla",
        "postcode": "2721",
        "state": "New South Wales"
    },
    {
        "suburb": "Morangarell",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Rankins Springs",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "Ardlethan",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Kamarah",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Blayney",
        "postcode": "2799",
        "state": "New South Wales"
    },
    {
        "suburb": "Errowanbang",
        "postcode": "2791",
        "state": "New South Wales"
    },
    {
        "suburb": "Carcoar",
        "postcode": "2791",
        "state": "New South Wales"
    },
    {
        "suburb": "Newbridge",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Forest Reefs",
        "postcode": "2798",
        "state": "New South Wales"
    },
    {
        "suburb": "Browns Creek",
        "postcode": "2799",
        "state": "New South Wales"
    },
    {
        "suburb": "Millthorpe",
        "postcode": "2798",
        "state": "New South Wales"
    },
    {
        "suburb": "Kings Plains",
        "postcode": "2799",
        "state": "New South Wales"
    },
    {
        "suburb": "Tallwood",
        "postcode": "2798",
        "state": "New South Wales"
    },
    {
        "suburb": "Garland",
        "postcode": "2797",
        "state": "New South Wales"
    },
    {
        "suburb": "Neville",
        "postcode": "2799",
        "state": "New South Wales"
    },
    {
        "suburb": "Barry",
        "postcode": "2799",
        "state": "New South Wales"
    },
    {
        "suburb": "Mandurama",
        "postcode": "2792",
        "state": "New South Wales"
    },
    {
        "suburb": "Burnt Yards",
        "postcode": "2792",
        "state": "New South Wales"
    },
    {
        "suburb": "Panuara",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Blackheath",
        "postcode": "2785",
        "state": "New South Wales"
    },
    {
        "suburb": "Katoomba",
        "postcode": "2780",
        "state": "New South Wales"
    },
    {
        "suburb": "Blue Mountains National Park",
        "postcode": "2780",
        "state": "New South Wales"
    },
    {
        "suburb": "Leura",
        "postcode": "2780",
        "state": "New South Wales"
    },
    {
        "suburb": "Wentworth Falls",
        "postcode": "2782",
        "state": "New South Wales"
    },
    {
        "suburb": "Lawson",
        "postcode": "2783",
        "state": "New South Wales"
    },
    {
        "suburb": "Hazelbrook",
        "postcode": "2779",
        "state": "New South Wales"
    },
    {
        "suburb": "Faulconbridge",
        "postcode": "2776",
        "state": "New South Wales"
    },
    {
        "suburb": "Springwood",
        "postcode": "2777",
        "state": "New South Wales"
    },
    {
        "suburb": "Winmalee",
        "postcode": "2777",
        "state": "New South Wales"
    },
    {
        "suburb": "Blaxland",
        "postcode": "2774",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenbrook",
        "postcode": "2773",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Riverview",
        "postcode": "2774",
        "state": "New South Wales"
    },
    {
        "suburb": "Lapstone",
        "postcode": "2773",
        "state": "New South Wales"
    },
    {
        "suburb": "Yellow Rock",
        "postcode": "2777",
        "state": "New South Wales"
    },
    {
        "suburb": "Medlow Bath",
        "postcode": "2780",
        "state": "New South Wales"
    },
    {
        "suburb": "Bullaburra",
        "postcode": "2784",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodford",
        "postcode": "2778",
        "state": "New South Wales"
    },
    {
        "suburb": "Linden",
        "postcode": "2778",
        "state": "New South Wales"
    },
    {
        "suburb": "Valley Heights",
        "postcode": "2777",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrimoo",
        "postcode": "2774",
        "state": "New South Wales"
    },
    {
        "suburb": "Bell",
        "postcode": "2786",
        "state": "New South Wales"
    },
    {
        "suburb": "Berambing",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Irvine",
        "postcode": "2786",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Victoria",
        "postcode": "2786",
        "state": "New South Wales"
    },
    {
        "suburb": "Megalong Valley",
        "postcode": "2785",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Wilson",
        "postcode": "2786",
        "state": "New South Wales"
    },
    {
        "suburb": "Hawkesbury Heights",
        "postcode": "2777",
        "state": "New South Wales"
    },
    {
        "suburb": "Sun Valley",
        "postcode": "2777",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Tomah",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Hermidale",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Bobadah",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Pangee",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "Five Ways",
        "postcode": "2873",
        "state": "New South Wales"
    },
    {
        "suburb": "Miandetta",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "Nyngan",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "Honeybugle",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolabah",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Girilambone",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Canonba",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "Babinda",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "The Marra",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Byrock",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrawombie",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "Buddabadah",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "Mulla",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "Canbelego",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungarby",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Lords Hill",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Bombala",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Craigie",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Mila",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Palarang",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Gunningrah",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Cooper",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Tombong",
        "postcode": "2633",
        "state": "New South Wales"
    },
    {
        "suburb": "Bondi Forest",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Bukalong",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Cambalong",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Holts Flat",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Boco",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Jincumbilly",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Allen",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Creewah",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Paddys Flat",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Byadbo Wilderness",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Corrowong",
        "postcode": "2633",
        "state": "New South Wales"
    },
    {
        "suburb": "Delegate",
        "postcode": "2633",
        "state": "New South Wales"
    },
    {
        "suburb": "Bibbenluke",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Ando",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Rockton",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Rosemeath",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolumbooka",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Quidong",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Merriangaah",
        "postcode": "2632",
        "state": "New South Wales"
    },
    {
        "suburb": "Boorowa",
        "postcode": "2586",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyangala",
        "postcode": "2808",
        "state": "New South Wales"
    },
    {
        "suburb": "Reids Flat",
        "postcode": "2586",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Collins",
        "postcode": "2794",
        "state": "New South Wales"
    },
    {
        "suburb": "Rugby",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Rye Park",
        "postcode": "2586",
        "state": "New South Wales"
    },
    {
        "suburb": "Hovells Creek",
        "postcode": "2794",
        "state": "New South Wales"
    },
    {
        "suburb": "Frogmore",
        "postcode": "2586",
        "state": "New South Wales"
    },
    {
        "suburb": "Taylors Flat",
        "postcode": "2586",
        "state": "New South Wales"
    },
    {
        "suburb": "Godfreys Creek",
        "postcode": "2586",
        "state": "New South Wales"
    },
    {
        "suburb": "Mascot",
        "postcode": "2020",
        "state": "New South Wales"
    },
    {
        "suburb": "Botany",
        "postcode": "2019",
        "state": "New South Wales"
    },
    {
        "suburb": "Rosebery",
        "postcode": "2018",
        "state": "New South Wales"
    },
    {
        "suburb": "Eastlakes",
        "postcode": "2018",
        "state": "New South Wales"
    },
    {
        "suburb": "Pagewood",
        "postcode": "2035",
        "state": "New South Wales"
    },
    {
        "suburb": "Daceyville",
        "postcode": "2032",
        "state": "New South Wales"
    },
    {
        "suburb": "Hillsdale",
        "postcode": "2036",
        "state": "New South Wales"
    },
    {
        "suburb": "Banksmeadow",
        "postcode": "2019",
        "state": "New South Wales"
    },
    {
        "suburb": "Eastgardens",
        "postcode": "2036",
        "state": "New South Wales"
    },
    {
        "suburb": "Louth",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Wanaaring",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Gumbalie",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Fords Bridge",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Enngonia",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Bourke",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Hungerford",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Gunderbooka",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Angledool",
        "postcode": "2834",
        "state": "New South Wales"
    },
    {
        "suburb": "Collerina",
        "postcode": "2839",
        "state": "New South Wales"
    },
    {
        "suburb": "Narran Lake",
        "postcode": "2839",
        "state": "New South Wales"
    },
    {
        "suburb": "Talawanta",
        "postcode": "2839",
        "state": "New South Wales"
    },
    {
        "suburb": "Goodooga",
        "postcode": "2838",
        "state": "New South Wales"
    },
    {
        "suburb": "Brewarrina",
        "postcode": "2839",
        "state": "New South Wales"
    },
    {
        "suburb": "Gongolgon",
        "postcode": "2839",
        "state": "New South Wales"
    },
    {
        "suburb": "Broken Hill",
        "postcode": "2880",
        "state": "New South Wales"
    },
    {
        "suburb": "Enfield",
        "postcode": "2136",
        "state": "New South Wales"
    },
    {
        "suburb": "Burwood",
        "postcode": "2134",
        "state": "New South Wales"
    },
    {
        "suburb": "Burwood Heights",
        "postcode": "2136",
        "state": "New South Wales"
    },
    {
        "suburb": "Strathfield",
        "postcode": "2135",
        "state": "New South Wales"
    },
    {
        "suburb": "The Pocket",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Coopers Shoot",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Tyagarah",
        "postcode": "2481",
        "state": "New South Wales"
    },
    {
        "suburb": "New Brighton",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Koonyum Range",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Palmwoods",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Brunswick Heads",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Ocean Shores",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "South Golden Beach",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Middle Pocket",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Mullumbimby",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Wilsons Creek",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Wilsons Creek",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Main Arm",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Huonbrook",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Goonengerry",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Byron Bay",
        "postcode": "2481",
        "state": "New South Wales"
    },
    {
        "suburb": "Suffolk Park",
        "postcode": "2481",
        "state": "New South Wales"
    },
    {
        "suburb": "Skinners Shoot",
        "postcode": "2481",
        "state": "New South Wales"
    },
    {
        "suburb": "Main Arm",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Federal",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Coorabell",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Montecollum",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Mullumbimby Creek",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Wanganui",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Coopers Creek",
        "postcode": "2482",
        "state": "New South Wales"
    },
    {
        "suburb": "Myocum",
        "postcode": "2481",
        "state": "New South Wales"
    },
    {
        "suburb": "Billinudgel",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Yelgun",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Ewingsdale",
        "postcode": "2481",
        "state": "New South Wales"
    },
    {
        "suburb": "Bangalow",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Wooyung",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Eureka",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Clunes",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Nashua",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "McLeods Shoot",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Binna Burra",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Possum Creek",
        "postcode": "2479",
        "state": "New South Wales"
    },
    {
        "suburb": "Hayters Hill",
        "postcode": "2481",
        "state": "New South Wales"
    },
    {
        "suburb": "Talofa",
        "postcode": "2481",
        "state": "New South Wales"
    },
    {
        "suburb": "Ophir",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Lidster",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Cargo",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowan Park",
        "postcode": "2864",
        "state": "New South Wales"
    },
    {
        "suburb": "Cadia",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Waldegrave",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "March",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Clifton Grove",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Summer Hill Creek",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Four Mile Creek",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Lewis Ponds",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Lewis Ponds",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Borenore",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Nashdale",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Canobolas",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Belgravia",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Springside",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Orange",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Byng",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Terrace",
        "postcode": "2798",
        "state": "New South Wales"
    },
    {
        "suburb": "Kangaroobie",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Emu Swamp",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Eugowra",
        "postcode": "2806",
        "state": "New South Wales"
    },
    {
        "suburb": "Yullundry",
        "postcode": "2867",
        "state": "New South Wales"
    },
    {
        "suburb": "Yeoval",
        "postcode": "2868",
        "state": "New South Wales"
    },
    {
        "suburb": "Canowindra",
        "postcode": "2804",
        "state": "New South Wales"
    },
    {
        "suburb": "Garra",
        "postcode": "2866",
        "state": "New South Wales"
    },
    {
        "suburb": "Molong",
        "postcode": "2866",
        "state": "New South Wales"
    },
    {
        "suburb": "Gumble",
        "postcode": "2865",
        "state": "New South Wales"
    },
    {
        "suburb": "Boomey",
        "postcode": "2866",
        "state": "New South Wales"
    },
    {
        "suburb": "Obley",
        "postcode": "2868",
        "state": "New South Wales"
    },
    {
        "suburb": "Gooloogong",
        "postcode": "2805",
        "state": "New South Wales"
    },
    {
        "suburb": "Nyrang Creek",
        "postcode": "2804",
        "state": "New South Wales"
    },
    {
        "suburb": "Cudal",
        "postcode": "2864",
        "state": "New South Wales"
    },
    {
        "suburb": "Cumnock",
        "postcode": "2867",
        "state": "New South Wales"
    },
    {
        "suburb": "Manildra",
        "postcode": "2865",
        "state": "New South Wales"
    },
    {
        "suburb": "Boree",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Murga",
        "postcode": "2864",
        "state": "New South Wales"
    },
    {
        "suburb": "Moorbel",
        "postcode": "2804",
        "state": "New South Wales"
    },
    {
        "suburb": "Amaroo",
        "postcode": "2866",
        "state": "New South Wales"
    },
    {
        "suburb": "Toogong",
        "postcode": "2864",
        "state": "New South Wales"
    },
    {
        "suburb": "Baldry",
        "postcode": "2867",
        "state": "New South Wales"
    },
    {
        "suburb": "Larras Lee",
        "postcode": "2866",
        "state": "New South Wales"
    },
    {
        "suburb": "Eurimbla",
        "postcode": "2867",
        "state": "New South Wales"
    },
    {
        "suburb": "Mandagery",
        "postcode": "2870",
        "state": "New South Wales"
    },
    {
        "suburb": "Mullion Creek",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Kerrs Creek",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Clergate",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Camden South",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Camden",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Elderslie",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Harrington Park",
        "postcode": "2567",
        "state": "New South Wales"
    },
    {
        "suburb": "Narellan Vale",
        "postcode": "2567",
        "state": "New South Wales"
    },
    {
        "suburb": "Narellan",
        "postcode": "2567",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Annan",
        "postcode": "2567",
        "state": "New South Wales"
    },
    {
        "suburb": "Currans Hill",
        "postcode": "2567",
        "state": "New South Wales"
    },
    {
        "suburb": "Leppington",
        "postcode": "2179",
        "state": "New South Wales"
    },
    {
        "suburb": "Gregory Hills",
        "postcode": "2557",
        "state": "New South Wales"
    },
    {
        "suburb": "Rossmore",
        "postcode": "2557",
        "state": "New South Wales"
    },
    {
        "suburb": "Ellis Lane",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Cawdor",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Kirkham",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Catherine Field",
        "postcode": "2557",
        "state": "New South Wales"
    },
    {
        "suburb": "Bringelly",
        "postcode": "2556",
        "state": "New South Wales"
    },
    {
        "suburb": "Cobbitty",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Smeaton Grange",
        "postcode": "2567",
        "state": "New South Wales"
    },
    {
        "suburb": "Grasmere",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Kearns",
        "postcode": "2558",
        "state": "New South Wales"
    },
    {
        "suburb": "Raby",
        "postcode": "2566",
        "state": "New South Wales"
    },
    {
        "suburb": "Eschol Park",
        "postcode": "2558",
        "state": "New South Wales"
    },
    {
        "suburb": "Eagle Vale",
        "postcode": "2558",
        "state": "New South Wales"
    },
    {
        "suburb": "St Andrews",
        "postcode": "2566",
        "state": "New South Wales"
    },
    {
        "suburb": "Ingleburn",
        "postcode": "2565",
        "state": "New South Wales"
    },
    {
        "suburb": "Bow Bowing",
        "postcode": "2566",
        "state": "New South Wales"
    },
    {
        "suburb": "Denham Court",
        "postcode": "2565",
        "state": "New South Wales"
    },
    {
        "suburb": "Minto",
        "postcode": "2566",
        "state": "New South Wales"
    },
    {
        "suburb": "Macquarie Fields",
        "postcode": "2564",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenfield",
        "postcode": "2167",
        "state": "New South Wales"
    },
    {
        "suburb": "Blairmount",
        "postcode": "2559",
        "state": "New South Wales"
    },
    {
        "suburb": "Claymore",
        "postcode": "2559",
        "state": "New South Wales"
    },
    {
        "suburb": "Kentlyn",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Macquarie Links",
        "postcode": "2565",
        "state": "New South Wales"
    },
    {
        "suburb": "Minto Heights",
        "postcode": "2566",
        "state": "New South Wales"
    },
    {
        "suburb": "Varroville",
        "postcode": "2566",
        "state": "New South Wales"
    },
    {
        "suburb": "Long Point",
        "postcode": "2564",
        "state": "New South Wales"
    },
    {
        "suburb": "Campbelltown",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Bradbury",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Wedderburn",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "St Helens Park",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Leumeah",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Ambarvale",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Alpine",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Rosemeadow",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodbine",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Gilead",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Holsworthy",
        "postcode": "2173",
        "state": "New South Wales"
    },
    {
        "suburb": "Woronora Dam",
        "postcode": "2508",
        "state": "New South Wales"
    },
    {
        "suburb": "Ruse",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Airds",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Blair Athol",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Menangle Park",
        "postcode": "2563",
        "state": "New South Wales"
    },
    {
        "suburb": "Englorie Park",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Concord West",
        "postcode": "2138",
        "state": "New South Wales"
    },
    {
        "suburb": "North Strathfield",
        "postcode": "2137",
        "state": "New South Wales"
    },
    {
        "suburb": "Concord",
        "postcode": "2137",
        "state": "New South Wales"
    },
    {
        "suburb": "Cabarita",
        "postcode": "2137",
        "state": "New South Wales"
    },
    {
        "suburb": "Rhodes",
        "postcode": "2138",
        "state": "New South Wales"
    },
    {
        "suburb": "Breakfast Point",
        "postcode": "2137",
        "state": "New South Wales"
    },
    {
        "suburb": "Liberty Grove",
        "postcode": "2138",
        "state": "New South Wales"
    },
    {
        "suburb": "Mortlake",
        "postcode": "2137",
        "state": "New South Wales"
    },
    {
        "suburb": "Five Dock",
        "postcode": "2046",
        "state": "New South Wales"
    },
    {
        "suburb": "Abbotsford",
        "postcode": "2046",
        "state": "New South Wales"
    },
    {
        "suburb": "Chiswick",
        "postcode": "2046",
        "state": "New South Wales"
    },
    {
        "suburb": "Russell Lea",
        "postcode": "2046",
        "state": "New South Wales"
    },
    {
        "suburb": "Rodd Point",
        "postcode": "2046",
        "state": "New South Wales"
    },
    {
        "suburb": "Drummoyne",
        "postcode": "2047",
        "state": "New South Wales"
    },
    {
        "suburb": "Canada Bay",
        "postcode": "2046",
        "state": "New South Wales"
    },
    {
        "suburb": "Wareemba",
        "postcode": "2046",
        "state": "New South Wales"
    },
    {
        "suburb": "Riverwood",
        "postcode": "2210",
        "state": "New South Wales"
    },
    {
        "suburb": "Roselands",
        "postcode": "2196",
        "state": "New South Wales"
    },
    {
        "suburb": "Belmore",
        "postcode": "2192",
        "state": "New South Wales"
    },
    {
        "suburb": "Belfield",
        "postcode": "2191",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingsgrove",
        "postcode": "2208",
        "state": "New South Wales"
    },
    {
        "suburb": "Campsie",
        "postcode": "2194",
        "state": "New South Wales"
    },
    {
        "suburb": "Clemton Park",
        "postcode": "2206",
        "state": "New South Wales"
    },
    {
        "suburb": "Earlwood",
        "postcode": "2206",
        "state": "New South Wales"
    },
    {
        "suburb": "Canterbury",
        "postcode": "2193",
        "state": "New South Wales"
    },
    {
        "suburb": "Beverly Hills",
        "postcode": "2209",
        "state": "New South Wales"
    },
    {
        "suburb": "Narwee",
        "postcode": "2209",
        "state": "New South Wales"
    },
    {
        "suburb": "Wiley Park",
        "postcode": "2195",
        "state": "New South Wales"
    },
    {
        "suburb": "Lakemba",
        "postcode": "2195",
        "state": "New South Wales"
    },
    {
        "suburb": "Goolgowi",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Melbergen",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "Erigolia",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "Merriwagga",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrathool",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Willbriggie",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Yenda",
        "postcode": "2681",
        "state": "New South Wales"
    },
    {
        "suburb": "Binya",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Hillston",
        "postcode": "2675",
        "state": "New South Wales"
    },
    {
        "suburb": "Roto",
        "postcode": "2675",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallanthery",
        "postcode": "2675",
        "state": "New South Wales"
    },
    {
        "suburb": "Boorga",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Tabbita",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Benerembah",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrawidgee",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Monia Gap",
        "postcode": "2675",
        "state": "New South Wales"
    },
    {
        "suburb": "Yorklea",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Naughtons Gap",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Tomki",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Coraki",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "Evans Head",
        "postcode": "2473",
        "state": "New South Wales"
    },
    {
        "suburb": "New Italy",
        "postcode": "2472",
        "state": "New South Wales"
    },
    {
        "suburb": "Stratheden",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Fairy Hill",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Backmede",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "North Casino",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Bentley",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Grove",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Irvington",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "McKees Hill",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenridge",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "Dobies Bight",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodview",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Piora",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Hogarth Range",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Mongogarie",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyan",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Coombell",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Mongogarie",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Busbys Flat",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Six Mile Swamp",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Marsh",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Kippenduff",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Myrtle Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Camira",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Clearfield",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Whiporie",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Gibberagee",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Shannon Brook",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Rappville",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Ellangowan",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Leeville",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "West Bungawalbin",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "Bora Ridge",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "Tatham",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungawalbin",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Esk",
        "postcode": "2472",
        "state": "New South Wales"
    },
    {
        "suburb": "Codrington",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "West Coraki",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "East Coraki",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "Swan Bay",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodburn",
        "postcode": "2472",
        "state": "New South Wales"
    },
    {
        "suburb": "Doonbah",
        "postcode": "2473",
        "state": "New South Wales"
    },
    {
        "suburb": "Rileys Hill",
        "postcode": "2472",
        "state": "New South Wales"
    },
    {
        "suburb": "Broadwater",
        "postcode": "2472",
        "state": "New South Wales"
    },
    {
        "suburb": "Tabbimoble",
        "postcode": "2472",
        "state": "New South Wales"
    },
    {
        "suburb": "The Gap",
        "postcode": "2472",
        "state": "New South Wales"
    },
    {
        "suburb": "Menindee",
        "postcode": "2879",
        "state": "New South Wales"
    },
    {
        "suburb": "Wilcannia",
        "postcode": "2836",
        "state": "New South Wales"
    },
    {
        "suburb": "Tilpa",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Weston",
        "postcode": "2326",
        "state": "New South Wales"
    },
    {
        "suburb": "Loxford",
        "postcode": "2326",
        "state": "New South Wales"
    },
    {
        "suburb": "Kurri Kurri",
        "postcode": "2327",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellbird",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Abermain",
        "postcode": "2326",
        "state": "New South Wales"
    },
    {
        "suburb": "Paxton",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Kitchener",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Abernethy",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Cessnock",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Pelton",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Ellalong",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount View",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Greta Main",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Sawyers Gully",
        "postcode": "2326",
        "state": "New South Wales"
    },
    {
        "suburb": "Lovedale",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Neath",
        "postcode": "2326",
        "state": "New South Wales"
    },
    {
        "suburb": "Kearsley",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Nulkaba",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Cliftleigh",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Fernances Crossing",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Keinbah",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Black Hill",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Paynes Crossing",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollombi",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Narone Creek",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrays Run",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Watagan",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "North Rothbury",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Pokolbin",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Greta",
        "postcode": "2334",
        "state": "New South Wales"
    },
    {
        "suburb": "Aberdare",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "East Branxton",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Branxton",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellbird Heights",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Elrington",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Mulbring",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Stockrington",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Yengo National Park",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Olney",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Buchanan",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Cedar Creek",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Vincent",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Bucketty",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Bishops Bridge",
        "postcode": "2326",
        "state": "New South Wales"
    },
    {
        "suburb": "Richmond Vale",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Pelaw Main",
        "postcode": "2327",
        "state": "New South Wales"
    },
    {
        "suburb": "Millfield",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Heddon Greta",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Stanford Merthyr",
        "postcode": "2327",
        "state": "New South Wales"
    },
    {
        "suburb": "Congewai",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Dairy Arm",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Laguna",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Corrabare",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Brunkerville",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Quorrobolong",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Buttai",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Sweetmans Creek",
        "postcode": "2325",
        "state": "New South Wales"
    },
    {
        "suburb": "Allandale",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "The Sandon",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Diggers Camp",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Minnie Water",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Barcoongere",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandy Crossing",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandon",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Pillar Valley",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Bookram",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Wooli",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Coldstream",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Gilletts Ridge",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Cowper",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenugie",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Halfway Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Tucabia",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Ulmarra",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Calliope",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Maclean",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Freeburn Island",
        "postcode": "2464",
        "state": "New South Wales"
    },
    {
        "suburb": "Brooms Head",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Yamba",
        "postcode": "2464",
        "state": "New South Wales"
    },
    {
        "suburb": "Yuraygir",
        "postcode": "2464",
        "state": "New South Wales"
    },
    {
        "suburb": "Wooloweyah",
        "postcode": "2464",
        "state": "New South Wales"
    },
    {
        "suburb": "Iluka",
        "postcode": "2466",
        "state": "New South Wales"
    },
    {
        "suburb": "Micalo Island",
        "postcode": "2464",
        "state": "New South Wales"
    },
    {
        "suburb": "Lawrence",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Tullymorgan",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Jacky Bulbin Flat",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Mororo",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Warregah Island",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Palmers Channel",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Palmers Island",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Woody Head",
        "postcode": "2466",
        "state": "New South Wales"
    },
    {
        "suburb": "Woombah",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Goodwood Island",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodford Island",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Ilarwill",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashby Island",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "The Freshwater",
        "postcode": "2466",
        "state": "New South Wales"
    },
    {
        "suburb": "Brushgrove",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Townsend",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "South Arm",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Tyndale",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Chatsworth",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Harwood",
        "postcode": "2465",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashby Heights",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Angourie",
        "postcode": "2464",
        "state": "New South Wales"
    },
    {
        "suburb": "Gulmarrad",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashby",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Swan Creek",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Clarenza",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Braunstone",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Coutts Crossing",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Elland",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Ramornie",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Eatonsville",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Waterview",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Seelands",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Waterview Heights",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Rushforth",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Grafton",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "South Grafton",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrs Island",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Junction Hill",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Great Marlow",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Crowther Island",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Alumy Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrs Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Southgate",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrs Peninsula",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Trenayr",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Bom Bom",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Lanitza",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Sherwood",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenreagh",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Kungala",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Kremnos",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Wells Crossing",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Marengo",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Cangai",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Jackadgery",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Coombadjha",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Billys Creek",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Lilydale",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Chaelundi",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Heifer Station",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Dundurrabin",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Buccarumbi",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Chambigne",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Clouds Creek",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Nymboida",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Wild Cattle Creek",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Moonpar",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Levenstrath",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Hernani",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Towallum",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Tyringham",
        "postcode": "2453",
        "state": "New South Wales"
    },
    {
        "suburb": "Blaxlands Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Newton Boyd",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Kangaroo Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Shannondale",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Fine Flower",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Stockyard Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Southgate",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Coaldale",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Gurranang",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Ewingar",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulldog",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Mookima Wybra",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Louisa Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Pikapene",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Lionsville",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Alice",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Keybarbin",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Coongbar",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Deep Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Banyabba",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Clifden",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Copmanhurst",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Whiteman Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "The Whiteman",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Mylneford",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Mountain View",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Koolkhan",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Dilkoon",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Fortis Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Warragai Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Washpool",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Copmanhurst",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Eighteen Mile",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Collum Collum",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Dumbudgery",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Fine Flower",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Carnham",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Barretts Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Pulganbar",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Wombat Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Smiths Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Newbold",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Winegrove",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "The Pinnacles",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Moleville Creek",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Kyarran",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Noona",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Cobar",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Kerrigundi",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Kulwin",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Nymagee",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Eremerang",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Tindarey",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Irymple",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Cubba",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulla",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Hope",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Boambee East",
        "postcode": "2452",
        "state": "New South Wales"
    },
    {
        "suburb": "Toormina",
        "postcode": "2452",
        "state": "New South Wales"
    },
    {
        "suburb": "Sawtell",
        "postcode": "2452",
        "state": "New South Wales"
    },
    {
        "suburb": "Coffs Harbour",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Moonee Beach",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Bucca",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Sapphire Beach",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Korora",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundagen",
        "postcode": "2454",
        "state": "New South Wales"
    },
    {
        "suburb": "Bonville",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Orara",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Boambee",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "North Boambee Valley",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Karangi",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Emerald Beach",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Woolgoolga",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Corindi Beach",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandy Beach",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Arrawarra Headland",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Mullaway",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Red Rock",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Lowanna",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Brooklana",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Corindi",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Coramba",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Nana Glen",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Ulong",
        "postcode": "2450",
        "state": "New South Wales"
    },
    {
        "suburb": "Dirty Creek",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Arrawarra",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Safety Beach",
        "postcode": "2456",
        "state": "New South Wales"
    },
    {
        "suburb": "Conargo",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Blighty",
        "postcode": "2713",
        "state": "New South Wales"
    },
    {
        "suburb": "Wanganella",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Coree",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Logie Brae",
        "postcode": "2713",
        "state": "New South Wales"
    },
    {
        "suburb": "Lindifferon",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Stud Park",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Wandook",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Deniliquin",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Booroorban",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Mabins Well",
        "postcode": "2716",
        "state": "New South Wales"
    },
    {
        "suburb": "Moonbria",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Moulamein",
        "postcode": "2733",
        "state": "New South Wales"
    },
    {
        "suburb": "Warragoon",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Birganbigil",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Myrtle Park",
        "postcode": "2713",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuppal",
        "postcode": "2714",
        "state": "New South Wales"
    },
    {
        "suburb": "Pine Lodge",
        "postcode": "2714",
        "state": "New South Wales"
    },
    {
        "suburb": "Four Corners",
        "postcode": "2716",
        "state": "New South Wales"
    },
    {
        "suburb": "Ganmain",
        "postcode": "2702",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolamon",
        "postcode": "2701",
        "state": "New South Wales"
    },
    {
        "suburb": "Rannock",
        "postcode": "2701",
        "state": "New South Wales"
    },
    {
        "suburb": "Cowabbie",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Marrar",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Walleroobie",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Matong",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Methul",
        "postcode": "2701",
        "state": "New South Wales"
    },
    {
        "suburb": "Berry Jerry",
        "postcode": "2701",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrulebale",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Williamsdale",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "The Angle",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Bumbalong",
        "postcode": "2626",
        "state": "New South Wales"
    },
    {
        "suburb": "Michelago",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Tinderry",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Clear Range",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Billilingra",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooma",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Middle Flat",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Fergus",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Yaouk",
        "postcode": "2629",
        "state": "New South Wales"
    },
    {
        "suburb": "Countegany",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Carlaminda",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Chakola",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Rose Valley",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Badja",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Peak View",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Tantangara",
        "postcode": "2629",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenlands",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Winifred",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Jingera",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Anembo",
        "postcode": "2621",
        "state": "New South Wales"
    },
    {
        "suburb": "Bredbo",
        "postcode": "2626",
        "state": "New South Wales"
    },
    {
        "suburb": "Numeralla",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Polo Flat",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Bunyan",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Binjura",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Shannons Flat",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Nimmitabel",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Dairymans Plains",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuross",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Maffra",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Colinton",
        "postcode": "2626",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrumbucca",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Springfield",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Dangelong",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerangle",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Kybeyan",
        "postcode": "2631",
        "state": "New South Wales"
    },
    {
        "suburb": "Rock Flat",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Burra",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Conimbia",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Gungalman",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Macquarie Marshes",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Coonamble",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Gulargambone",
        "postcode": "2828",
        "state": "New South Wales"
    },
    {
        "suburb": "Gilgooma",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Billeroy",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Urawilkie",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Pilliga",
        "postcode": "2388",
        "state": "New South Wales"
    },
    {
        "suburb": "Teridgerie",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Nebea",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Pine Grove",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Magometon",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Tooloon",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Quambone",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Bourbah",
        "postcode": "2828",
        "state": "New South Wales"
    },
    {
        "suburb": "Combara",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Tenandra",
        "postcode": "2828",
        "state": "New South Wales"
    },
    {
        "suburb": "Quanda",
        "postcode": "2828",
        "state": "New South Wales"
    },
    {
        "suburb": "Black Hollow",
        "postcode": "2828",
        "state": "New South Wales"
    },
    {
        "suburb": "Carinda",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Cootamundra",
        "postcode": "2590",
        "state": "New South Wales"
    },
    {
        "suburb": "Milvale",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Stockinbingal",
        "postcode": "2725",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallendbeen",
        "postcode": "2588",
        "state": "New South Wales"
    },
    {
        "suburb": "Muttama",
        "postcode": "2722",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolac",
        "postcode": "2727",
        "state": "New South Wales"
    },
    {
        "suburb": "Howlong",
        "postcode": "2643",
        "state": "New South Wales"
    },
    {
        "suburb": "Corowa",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Oaklands",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Daysdale",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Coreen",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Lowesdale",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Balldale",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Collendina",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Hopefield",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Ringwood",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Sanger",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Rennie",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Cowra",
        "postcode": "2794",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodstock",
        "postcode": "2793",
        "state": "New South Wales"
    },
    {
        "suburb": "Roseberg",
        "postcode": "2793",
        "state": "New South Wales"
    },
    {
        "suburb": "Billimari",
        "postcode": "2804",
        "state": "New South Wales"
    },
    {
        "suburb": "Koorawatha",
        "postcode": "2807",
        "state": "New South Wales"
    },
    {
        "suburb": "Darbys Falls",
        "postcode": "2793",
        "state": "New South Wales"
    },
    {
        "suburb": "Bumbaldry",
        "postcode": "2794",
        "state": "New South Wales"
    },
    {
        "suburb": "Wattamondara",
        "postcode": "2794",
        "state": "New South Wales"
    },
    {
        "suburb": "Dubbo",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Minore",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Brocklehurst",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Toongi",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Mogriguy",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Ballimore",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Wongarbon",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Benolong",
        "postcode": "2818",
        "state": "New South Wales"
    },
    {
        "suburb": "Wambangalang",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Elong Elong",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Goonoo Forest",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Terramungamine",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Eumungerie",
        "postcode": "2822",
        "state": "New South Wales"
    },
    {
        "suburb": "Rawsonville",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrabolla",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Allyn",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Salisbury",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Lostock",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Bingleburra",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Dungog",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Marshdale",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Rivers",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Chichester",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Main Creek",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Oak",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Clarence Town",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Fosterton",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen William",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Allynbrook",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Halton",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Eccleston",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Underbank",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Bandon Grove",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Vacy",
        "postcode": "2421",
        "state": "New South Wales"
    },
    {
        "suburb": "Summer Hill",
        "postcode": "2421",
        "state": "New South Wales"
    },
    {
        "suburb": "Fishers Hill",
        "postcode": "2421",
        "state": "New South Wales"
    },
    {
        "suburb": "Paterson",
        "postcode": "2421",
        "state": "New South Wales"
    },
    {
        "suburb": "Martins Creek",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Gresford",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "East Gresford",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Lewinsbrook",
        "postcode": "2311",
        "state": "New South Wales"
    },
    {
        "suburb": "Cambra",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Flat Tops",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Bendolba",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Munni",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Alison",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Tabbil Creek",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Hanleys Creek",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Sugarloaf",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Stroud Hill",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Martin",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Webbers Creek",
        "postcode": "2421",
        "state": "New South Wales"
    },
    {
        "suburb": "Tocal",
        "postcode": "2421",
        "state": "New South Wales"
    },
    {
        "suburb": "Torryburn",
        "postcode": "2421",
        "state": "New South Wales"
    },
    {
        "suburb": "Wirragulla",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallaringa",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallarobba",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Brookfield",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Hilldale",
        "postcode": "2420",
        "state": "New South Wales"
    },
    {
        "suburb": "Moruya Heads",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Narooma",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Broulee",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Kianga",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Malua Bay",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Benandarah",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Dalmeny",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuross Head",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Guerilla Bay",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Mossy Point",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Batehaven",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "South Durras",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Deua River Valley",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Sunshine Bay",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Lilli Pilli",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Rosedale",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "North Narooma",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Belowra",
        "postcode": "2545",
        "state": "New South Wales"
    },
    {
        "suburb": "Merricumbene",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Mystery Bay",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Currowan",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Tilba Tilba",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Bodalla",
        "postcode": "2545",
        "state": "New South Wales"
    },
    {
        "suburb": "Potato Point",
        "postcode": "2545",
        "state": "New South Wales"
    },
    {
        "suburb": "Batemans Bay",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Corunna",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Neringla",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Deua",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrengenburg",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Buckenbowra",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Denhams Beach",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Surfside",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Akolele",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "East Lynne",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Central Tilba",
        "postcode": "2546",
        "state": "New South Wales"
    },
    {
        "suburb": "Wamban",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Congo",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Moruya",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Long Beach",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Maloneys Beach",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Bingie",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Tomakin",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Nelligen",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "North Batemans Bay",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Catalina",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Kiora",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Jeremadra",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Surf Beach",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Nerrigundah",
        "postcode": "2545",
        "state": "New South Wales"
    },
    {
        "suburb": "Bergalia",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Bimbimbie",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Mogo",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Eurobodalla",
        "postcode": "2545",
        "state": "New South Wales"
    },
    {
        "suburb": "Turlinjah",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Mogendoura",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Runnyford",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Cadgee",
        "postcode": "2545",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodlands",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Coila",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Meringo",
        "postcode": "2537",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Pritchard",
        "postcode": "2170",
        "state": "New South Wales"
    },
    {
        "suburb": "Canley Heights",
        "postcode": "2166",
        "state": "New South Wales"
    },
    {
        "suburb": "Cabramatta West",
        "postcode": "2166",
        "state": "New South Wales"
    },
    {
        "suburb": "Fairfield West",
        "postcode": "2165",
        "state": "New South Wales"
    },
    {
        "suburb": "Cabramatta",
        "postcode": "2166",
        "state": "New South Wales"
    },
    {
        "suburb": "Smithfield",
        "postcode": "2164",
        "state": "New South Wales"
    },
    {
        "suburb": "Fairfield",
        "postcode": "2165",
        "state": "New South Wales"
    },
    {
        "suburb": "Canley Vale",
        "postcode": "2166",
        "state": "New South Wales"
    },
    {
        "suburb": "Fairfield Heights",
        "postcode": "2165",
        "state": "New South Wales"
    },
    {
        "suburb": "Lansvale",
        "postcode": "2166",
        "state": "New South Wales"
    },
    {
        "suburb": "Fairfield East",
        "postcode": "2165",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Guildford",
        "postcode": "2161",
        "state": "New South Wales"
    },
    {
        "suburb": "Yennora",
        "postcode": "2161",
        "state": "New South Wales"
    },
    {
        "suburb": "Carramar",
        "postcode": "2163",
        "state": "New South Wales"
    },
    {
        "suburb": "Greystanes",
        "postcode": "2145",
        "state": "New South Wales"
    },
    {
        "suburb": "Abbotsbury",
        "postcode": "2176",
        "state": "New South Wales"
    },
    {
        "suburb": "Bonnyrigg Heights",
        "postcode": "2177",
        "state": "New South Wales"
    },
    {
        "suburb": "Edensor Park",
        "postcode": "2176",
        "state": "New South Wales"
    },
    {
        "suburb": "Bossley Park",
        "postcode": "2176",
        "state": "New South Wales"
    },
    {
        "suburb": "Bonnyrigg",
        "postcode": "2177",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenfield Park",
        "postcode": "2176",
        "state": "New South Wales"
    },
    {
        "suburb": "St Johns Park",
        "postcode": "2176",
        "state": "New South Wales"
    },
    {
        "suburb": "Wetherill Park",
        "postcode": "2164",
        "state": "New South Wales"
    },
    {
        "suburb": "Wakeley",
        "postcode": "2176",
        "state": "New South Wales"
    },
    {
        "suburb": "Prairiewood",
        "postcode": "2176",
        "state": "New South Wales"
    },
    {
        "suburb": "Horsley Park",
        "postcode": "2175",
        "state": "New South Wales"
    },
    {
        "suburb": "Cecil Park",
        "postcode": "2178",
        "state": "New South Wales"
    },
    {
        "suburb": "Wirrinya",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Gunning Gap",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Forbes",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Cookamidgera",
        "postcode": "2870",
        "state": "New South Wales"
    },
    {
        "suburb": "Mulyandry",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Ootha",
        "postcode": "2875",
        "state": "New South Wales"
    },
    {
        "suburb": "Corinella",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrabandai",
        "postcode": "2875",
        "state": "New South Wales"
    },
    {
        "suburb": "Ooma",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Paytens Bridge",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Garema",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Jemalong",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Warroo",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Mulguthrie",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Derriwong",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Gilgandra",
        "postcode": "2827",
        "state": "New South Wales"
    },
    {
        "suburb": "Bearbong",
        "postcode": "2827",
        "state": "New South Wales"
    },
    {
        "suburb": "Armatree",
        "postcode": "2828",
        "state": "New South Wales"
    },
    {
        "suburb": "Tooraweenah",
        "postcode": "2817",
        "state": "New South Wales"
    },
    {
        "suburb": "Kickabil",
        "postcode": "2830",
        "state": "New South Wales"
    },
    {
        "suburb": "Collie",
        "postcode": "2827",
        "state": "New South Wales"
    },
    {
        "suburb": "Tonderburine",
        "postcode": "2817",
        "state": "New South Wales"
    },
    {
        "suburb": "Balladoran",
        "postcode": "2822",
        "state": "New South Wales"
    },
    {
        "suburb": "Curban",
        "postcode": "2827",
        "state": "New South Wales"
    },
    {
        "suburb": "Breelong",
        "postcode": "2827",
        "state": "New South Wales"
    },
    {
        "suburb": "Mendooran",
        "postcode": "2842",
        "state": "New South Wales"
    },
    {
        "suburb": "Biddon",
        "postcode": "2827",
        "state": "New South Wales"
    },
    {
        "suburb": "Merrigal",
        "postcode": "2827",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Innes",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Matheson",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Rangers Valley",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Reddestone",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Diehard",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingsgate",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Elgin",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Kookabookra",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Bald Nob",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Wellington Vale",
        "postcode": "2371",
        "state": "New South Wales"
    },
    {
        "suburb": "Torrington",
        "postcode": "2371",
        "state": "New South Wales"
    },
    {
        "suburb": "Stannum",
        "postcode": "2371",
        "state": "New South Wales"
    },
    {
        "suburb": "Emmaville",
        "postcode": "2371",
        "state": "New South Wales"
    },
    {
        "suburb": "Deepwater",
        "postcode": "2371",
        "state": "New South Wales"
    },
    {
        "suburb": "Dundee",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Shannon Vale",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Red Range",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Lambs Valley",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Furracabad",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Stonehenge",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Glencoe",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Mitchell",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrowford",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Capoompeta",
        "postcode": "2371",
        "state": "New South Wales"
    },
    {
        "suburb": "Ben Lomond",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Maybole",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Gibraltar Range",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Moggs Swamp",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Pinkett",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Moogem",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Morven",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Gloucester",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallanbah",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Cobark",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Rawdon Vale",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Berrico",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Stratford",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Craven",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Invergordon",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundook",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Barrington",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Forbesdale",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Tugrabakh",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Waukivory",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Mograni",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Copeland",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Bindera",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Tibbuc",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Woko",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Giro",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Bretti",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Callaghans Creek",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowman Farm",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Terreel",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowman",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Mares Run",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Baxters Ridge",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Mernot",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Curricabark",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Dewitt",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Coneac",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Tomalla",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Barrington Tops",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Craven Plateau",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Titaatee Creek",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Belbora",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Bakers Creek",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Gangat",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Kia Ora",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulliac",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Back Creek",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Tiri",
        "postcode": "2424",
        "state": "New South Wales"
    },
    {
        "suburb": "Davistown",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Saratoga",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Empire Bay",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Point",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Killcare",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "Killcare Heights",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "Kincumber",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Copacabana",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Terrigal",
        "postcode": "2260",
        "state": "New South Wales"
    },
    {
        "suburb": "Wamberal",
        "postcode": "2260",
        "state": "New South Wales"
    },
    {
        "suburb": "Forresters Beach",
        "postcode": "2260",
        "state": "New South Wales"
    },
    {
        "suburb": "Holgate",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Macmasters Beach",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Box Head",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "Pretty Beach",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "Hardys Bay",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "North Avoca",
        "postcode": "2260",
        "state": "New South Wales"
    },
    {
        "suburb": "Matcham",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Erina Heights",
        "postcode": "2260",
        "state": "New South Wales"
    },
    {
        "suburb": "Erina",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Yattalunga",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Bensville",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Avoca Beach",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Picketts Valley",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Bouddi",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Daleys Point",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Point",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Lisarow",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Elliot",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyoming",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "East Gosford",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Wagstaffe",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "St Huberts Island",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "Kincumber South",
        "postcode": "2251",
        "state": "New South Wales"
    },
    {
        "suburb": "Kariong",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Pearl Beach",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "Umina Beach",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "Point Clare",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Woy Woy",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "West Gosford",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Ettalong Beach",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "Blackwall",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "Gosford",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Narara",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Point Frederick",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Booker Bay",
        "postcode": "2257",
        "state": "New South Wales"
    },
    {
        "suburb": "North Gosford",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Niagara Park",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Springfield",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Wondabyne",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "Ten Mile Hollow",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Kulnura",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Mangrove",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Mangrove Mountain",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Mangrove Creek",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Peats Ridge",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Central Mangrove",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Somersby",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Gunderman",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Mangrove",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Spencer",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenworth Valley",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Wendoree Park",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Marlow",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Bar Point",
        "postcode": "2083",
        "state": "New South Wales"
    },
    {
        "suburb": "Mooney Mooney",
        "postcode": "2083",
        "state": "New South Wales"
    },
    {
        "suburb": "Cheero Point",
        "postcode": "2083",
        "state": "New South Wales"
    },
    {
        "suburb": "Cogra Bay",
        "postcode": "2083",
        "state": "New South Wales"
    },
    {
        "suburb": "Woy Woy Bay",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "Mooney Mooney Creek",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Patonga",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "Calga",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount White",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Tascott",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Koolewong",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "Horsfield Bay",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "Phegans Bay",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "Goulburn",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Bathurst",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenwich Park",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Wayo",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodhouselee",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Marulan",
        "postcode": "2579",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungonia",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Tallong",
        "postcode": "2579",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrick",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Brayton",
        "postcode": "2579",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Boro",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Oallen",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Middle Arm",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarlo",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Towrang",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Boxers Creek",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Pomeroy",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Mummel",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Baw Baw",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingsdale",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Parkesbourne",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarra",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Tirrannaville",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollogorang",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarago",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Windellama",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Quialigo",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Brisbane Grove",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Run-o-Waters",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Gundary",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Kundibakh",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Dollys Flat",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Strathcedar",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Wingham",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Tinonee",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Taree",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Purfleet",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Cundletown",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Waitui",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Diamond Beach",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Red Head",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Black Head",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallabi Point",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Bar",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Mitchells Island",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Coopernook",
        "postcode": "2426",
        "state": "New South Wales"
    },
    {
        "suburb": "Harrington",
        "postcode": "2427",
        "state": "New South Wales"
    },
    {
        "suburb": "Cells River",
        "postcode": "2424",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooplacurripa",
        "postcode": "2424",
        "state": "New South Wales"
    },
    {
        "suburb": "Cundle Flat",
        "postcode": "2424",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulga Forest",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Knorrit Flat",
        "postcode": "2424",
        "state": "New South Wales"
    },
    {
        "suburb": "Karaak Flat",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "The Bight",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Killabakh",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Dumaresq Island",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Tallwoods Village",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Koorainghat",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Rainbow Flat",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Manning Point",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Lansdowne",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Langley Vale",
        "postcode": "2426",
        "state": "New South Wales"
    },
    {
        "suburb": "Croki",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Cabbage Tree Island",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Jones Island",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Coralville",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Johns River",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Caffreys Flat",
        "postcode": "2424",
        "state": "New South Wales"
    },
    {
        "suburb": "Bucca Wauka",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Tipperary",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Knorrit Forest",
        "postcode": "2424",
        "state": "New South Wales"
    },
    {
        "suburb": "Caparra",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Hillville",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Possum Brush",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Wang Wauk",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Mooral Creek",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Kippaxs",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Lansdowne",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Brimbin",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Darawank",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Hallidays Point",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Lansdowne Forest",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Bohnock",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Wherrol Flat",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Nabiac",
        "postcode": "2312",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount George",
        "postcode": "2424",
        "state": "New South Wales"
    },
    {
        "suburb": "Ghinni Ghinni",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Crowdy Head",
        "postcode": "2427",
        "state": "New South Wales"
    },
    {
        "suburb": "Krambach",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Dingo Forest",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Kimbriki",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Kundle Kundle",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenthorne",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Firefly",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Cedar Party",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Marlee",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Oxley Island",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Bootawa",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Taree South",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Pampoolah",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Number One",
        "postcode": "2424",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrell Creek",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Hannam Vale",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Stewarts River",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Melinga",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Moorland",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Elands",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Crowdy Bay National Park",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Khatambuhl",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Moto",
        "postcode": "2426",
        "state": "New South Wales"
    },
    {
        "suburb": "Saltwater",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Kiwarrak",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarratt Forest",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Bunyah",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Gerogery",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Brocklesby",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Walla Walla",
        "postcode": "2659",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowna",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Goombargana",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Mullengandra",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungowannah",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrumbuttock",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Wymah",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Moorwatha",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Holbrook",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Talmalmo",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Lankeys Creek",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Jingellic",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Alma Park",
        "postcode": "2659",
        "state": "New South Wales"
    },
    {
        "suburb": "Cookardinia",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Humula",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Rosewood",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Coppabella",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarara",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Carabost",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Woomargama",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Mountain Creek",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Culcairn",
        "postcode": "2660",
        "state": "New South Wales"
    },
    {
        "suburb": "Henty",
        "postcode": "2658",
        "state": "New South Wales"
    },
    {
        "suburb": "Morven",
        "postcode": "2660",
        "state": "New South Wales"
    },
    {
        "suburb": "Wantagong",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Billabong",
        "postcode": "2644",
        "state": "New South Wales"
    },
    {
        "suburb": "Rand",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Walbundrie",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Limeburners Creek",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "North Arm Cove",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Tea Gardens",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Hawks Nest",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Markwell",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulahdelah",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolongolook",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallis Lake",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Coomba Park",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuncurry",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Smiths Lake",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Forster",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Point",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Seal Rocks",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Bombah Point",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Blueys Beach",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Allworth",
        "postcode": "2425",
        "state": "New South Wales"
    },
    {
        "suburb": "Weismantels",
        "postcode": "2415",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Myall",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Wootton",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallingat",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungwahl",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Elizabeth Beach",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Monkerai",
        "postcode": "2415",
        "state": "New South Wales"
    },
    {
        "suburb": "Booral",
        "postcode": "2425",
        "state": "New South Wales"
    },
    {
        "suburb": "Girvan",
        "postcode": "2425",
        "state": "New South Wales"
    },
    {
        "suburb": "Nerong",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Crawford River",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Warranulla",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Boolambayte",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Topi Topi",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Shallow Bay",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Coomba Bay",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Yagon",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Whoota",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Tiona",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Boomerang Beach",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Stroud",
        "postcode": "2425",
        "state": "New South Wales"
    },
    {
        "suburb": "Tahlee",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Washpool",
        "postcode": "2425",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrington",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Wards River",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarbuck Bay",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Myall Lake",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Stroud Road",
        "postcode": "2415",
        "state": "New South Wales"
    },
    {
        "suburb": "The Branch",
        "postcode": "2425",
        "state": "New South Wales"
    },
    {
        "suburb": "Failford",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Pindimar",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Karuah",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundabah",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandbar",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Booti Booti",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Minimbah",
        "postcode": "2312",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayers Flat",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Violet Hill",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Charlotte Bay",
        "postcode": "2428",
        "state": "New South Wales"
    },
    {
        "suburb": "Mungo Brush",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Willina",
        "postcode": "2423",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Karuah River",
        "postcode": "2415",
        "state": "New South Wales"
    },
    {
        "suburb": "Nooroo",
        "postcode": "2415",
        "state": "New South Wales"
    },
    {
        "suburb": "Griffith",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Hanwood",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Yoogali",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Beelbangera",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Bilbul",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Wyangan",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Whitton",
        "postcode": "2705",
        "state": "New South Wales"
    },
    {
        "suburb": "Kooba",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Nericon",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Warburn",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Widgelli",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Tharbogang",
        "postcode": "2680",
        "state": "New South Wales"
    },
    {
        "suburb": "Gundagai",
        "postcode": "2722",
        "state": "New South Wales"
    },
    {
        "suburb": "South Gundagai",
        "postcode": "2722",
        "state": "New South Wales"
    },
    {
        "suburb": "Burra Creek",
        "postcode": "2722",
        "state": "New South Wales"
    },
    {
        "suburb": "Tumblong",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Darbalara",
        "postcode": "2722",
        "state": "New South Wales"
    },
    {
        "suburb": "Mundarlo",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Jones Creek",
        "postcode": "2722",
        "state": "New South Wales"
    },
    {
        "suburb": "Adjungbilly",
        "postcode": "2727",
        "state": "New South Wales"
    },
    {
        "suburb": "Nangus",
        "postcode": "2722",
        "state": "New South Wales"
    },
    {
        "suburb": "Gunnedah",
        "postcode": "2380",
        "state": "New South Wales"
    },
    {
        "suburb": "Kelvin",
        "postcode": "2380",
        "state": "New South Wales"
    },
    {
        "suburb": "Curlewis",
        "postcode": "2381",
        "state": "New South Wales"
    },
    {
        "suburb": "The Pilliga",
        "postcode": "2388",
        "state": "New South Wales"
    },
    {
        "suburb": "Tambar Springs",
        "postcode": "2381",
        "state": "New South Wales"
    },
    {
        "suburb": "Carroll",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Keepit",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Goolhi",
        "postcode": "2379",
        "state": "New South Wales"
    },
    {
        "suburb": "Wean",
        "postcode": "2382",
        "state": "New South Wales"
    },
    {
        "suburb": "Breeza",
        "postcode": "2381",
        "state": "New South Wales"
    },
    {
        "suburb": "Orange Grove",
        "postcode": "2380",
        "state": "New South Wales"
    },
    {
        "suburb": "Marys Mount",
        "postcode": "2380",
        "state": "New South Wales"
    },
    {
        "suburb": "Rangari",
        "postcode": "2380",
        "state": "New South Wales"
    },
    {
        "suburb": "Mullaley",
        "postcode": "2379",
        "state": "New South Wales"
    },
    {
        "suburb": "Premer",
        "postcode": "2381",
        "state": "New South Wales"
    },
    {
        "suburb": "Willala",
        "postcode": "2382",
        "state": "New South Wales"
    },
    {
        "suburb": "Ghoolendaadi",
        "postcode": "2380",
        "state": "New South Wales"
    },
    {
        "suburb": "Emerald Hill",
        "postcode": "2380",
        "state": "New South Wales"
    },
    {
        "suburb": "Boggabri",
        "postcode": "2382",
        "state": "New South Wales"
    },
    {
        "suburb": "Milroy",
        "postcode": "2380",
        "state": "New South Wales"
    },
    {
        "suburb": "Piallaway",
        "postcode": "2342",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Ridge",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Caroona",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Blue Vale",
        "postcode": "2380",
        "state": "New South Wales"
    },
    {
        "suburb": "Brushy Creek",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "South Guyra",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Guyra",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Tubbamurra",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "New Valley",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Llangothlin",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Baldersleigh",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Wandsworth",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Howell",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Falconer",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Bassendean",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Georges Creek",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundarra",
        "postcode": "2359",
        "state": "New South Wales"
    },
    {
        "suburb": "Tingha",
        "postcode": "2369",
        "state": "New South Wales"
    },
    {
        "suburb": "Wards Mistake",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Stanborough",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Tenterden",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Briarbrook",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "The Gulf",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Backwater",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Oban",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Bald Blair",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Hills",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Brockley",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Bingara",
        "postcode": "2404",
        "state": "New South Wales"
    },
    {
        "suburb": "Whitlow",
        "postcode": "2404",
        "state": "New South Wales"
    },
    {
        "suburb": "Copeton",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Rocky Creek",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Myall Creek",
        "postcode": "2403",
        "state": "New South Wales"
    },
    {
        "suburb": "Dinoga",
        "postcode": "2404",
        "state": "New South Wales"
    },
    {
        "suburb": "Bangheet",
        "postcode": "2404",
        "state": "New South Wales"
    },
    {
        "suburb": "Gineroi",
        "postcode": "2404",
        "state": "New South Wales"
    },
    {
        "suburb": "Elcombe",
        "postcode": "2404",
        "state": "New South Wales"
    },
    {
        "suburb": "Back Creek",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Horton",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Pallal",
        "postcode": "2404",
        "state": "New South Wales"
    },
    {
        "suburb": "Keera",
        "postcode": "2404",
        "state": "New South Wales"
    },
    {
        "suburb": "Warialda Rail",
        "postcode": "2402",
        "state": "New South Wales"
    },
    {
        "suburb": "Warialda",
        "postcode": "2402",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolatai",
        "postcode": "2402",
        "state": "New South Wales"
    },
    {
        "suburb": "Delungra",
        "postcode": "2403",
        "state": "New South Wales"
    },
    {
        "suburb": "Pallamallawa",
        "postcode": "2399",
        "state": "New South Wales"
    },
    {
        "suburb": "Gravesend",
        "postcode": "2401",
        "state": "New South Wales"
    },
    {
        "suburb": "Croppa Creek",
        "postcode": "2411",
        "state": "New South Wales"
    },
    {
        "suburb": "North Star",
        "postcode": "2408",
        "state": "New South Wales"
    },
    {
        "suburb": "Yallaroi",
        "postcode": "2408",
        "state": "New South Wales"
    },
    {
        "suburb": "Crooble",
        "postcode": "2400",
        "state": "New South Wales"
    },
    {
        "suburb": "Balfours Peak",
        "postcode": "2403",
        "state": "New South Wales"
    },
    {
        "suburb": "Graman",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Boonal",
        "postcode": "2409",
        "state": "New South Wales"
    },
    {
        "suburb": "Tulloona",
        "postcode": "2400",
        "state": "New South Wales"
    },
    {
        "suburb": "Blue Nobby",
        "postcode": "2408",
        "state": "New South Wales"
    },
    {
        "suburb": "Gundamulda",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Gulf Creek",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Cobbadah",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Barraba",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Lindesay",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingsvale",
        "postcode": "2587",
        "state": "New South Wales"
    },
    {
        "suburb": "Jugiong",
        "postcode": "2726",
        "state": "New South Wales"
    },
    {
        "suburb": "Galong",
        "postcode": "2585",
        "state": "New South Wales"
    },
    {
        "suburb": "Cunningar",
        "postcode": "2587",
        "state": "New South Wales"
    },
    {
        "suburb": "Harden",
        "postcode": "2587",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrumburrah",
        "postcode": "2587",
        "state": "New South Wales"
    },
    {
        "suburb": "Wombat",
        "postcode": "2587",
        "state": "New South Wales"
    },
    {
        "suburb": "Barwang",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Nubba",
        "postcode": "2587",
        "state": "New South Wales"
    },
    {
        "suburb": "Beggan Beggan",
        "postcode": "2587",
        "state": "New South Wales"
    },
    {
        "suburb": "McMahons Reef",
        "postcode": "2587",
        "state": "New South Wales"
    },
    {
        "suburb": "Berremangra",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Port Macquarie",
        "postcode": "2444",
        "state": "New South Wales"
    },
    {
        "suburb": "Fernbank Creek",
        "postcode": "2444",
        "state": "New South Wales"
    },
    {
        "suburb": "Thrumster",
        "postcode": "2444",
        "state": "New South Wales"
    },
    {
        "suburb": "Riverside",
        "postcode": "2444",
        "state": "New South Wales"
    },
    {
        "suburb": "North Shore",
        "postcode": "2444",
        "state": "New South Wales"
    },
    {
        "suburb": "Innes View",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Pappinbarra",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Bril Bril",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellangry",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Cathie",
        "postcode": "2445",
        "state": "New South Wales"
    },
    {
        "suburb": "Bago",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Cairncross",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Kendall",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Yippin Creek",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Wauchope",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Diamond Head",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "King Creek",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "West Haven",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Laurieton",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Dunbogan",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "North Haven",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Camden Head",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Limeburners Creek",
        "postcode": "2444",
        "state": "New South Wales"
    },
    {
        "suburb": "Blackmans Point",
        "postcode": "2444",
        "state": "New South Wales"
    },
    {
        "suburb": "Byabarra",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Comboyne",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Lorne",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Frazers Creek",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Marlo Merrican",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Middle Brother",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Upsalls Creek",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Pembrooke",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Innes",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Dondingalong",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooperabung",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Kindee",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Swans Crossing",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Kerewong",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Hyndmans Creek",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Rollands Plains",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Black Creek",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Herons Creek",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Logans Crossing",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Bobs Creek",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Grants Beach",
        "postcode": "2445",
        "state": "New South Wales"
    },
    {
        "suburb": "Lakewood",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Rawdon Island",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "The Hatch",
        "postcode": "2444",
        "state": "New South Wales"
    },
    {
        "suburb": "Sancrox",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Hacks Ferry",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Seaview",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Werrikimbe",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Debenham",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarras",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Birdwood",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Boorganna",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Pappinbarra",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Hartys Plains",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Pipeclay",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Gum Scrub",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Batar Creek",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Ballengarra",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Beechwood",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Rossglen",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Crosslands",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Telegraph Point",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Long Flat",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Kew",
        "postcode": "2439",
        "state": "New South Wales"
    },
    {
        "suburb": "Hollisdale",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Deauville",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Ellenborough",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Pappinbarra",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Bagnoo",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Forbes River",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Rosewood",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Mortons Creek",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Redbank",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "North Brother",
        "postcode": "2443",
        "state": "New South Wales"
    },
    {
        "suburb": "Toms Creek",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Jolly Nose",
        "postcode": "2445",
        "state": "New South Wales"
    },
    {
        "suburb": "Huntingdon",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Gearys Flat",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Doyles River",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Banda Banda",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Kippara",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Brombin",
        "postcode": "2446",
        "state": "New South Wales"
    },
    {
        "suburb": "Bilpin",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Kurrajong",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Oakville",
        "postcode": "2765",
        "state": "New South Wales"
    },
    {
        "suburb": "Freemans Reach",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Mountain Lagoon",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Grose Vale",
        "postcode": "2753",
        "state": "New South Wales"
    },
    {
        "suburb": "Blaxlands Ridge",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Macdonald",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Pitt Town Bottoms",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Webbs Creek",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "South Windsor",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Bligh Park",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Higher Macdonald",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Sackville",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarramundi",
        "postcode": "2753",
        "state": "New South Wales"
    },
    {
        "suburb": "Windsor Downs",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Mogo Creek",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Vineyard",
        "postcode": "2765",
        "state": "New South Wales"
    },
    {
        "suburb": "Leets Vale",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Mellong",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Perrys Crossing",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "The Devils Wilderness",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Ebenezer",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Cumberland Reach",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Wrights Creek",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Agnes Banks",
        "postcode": "2753",
        "state": "New South Wales"
    },
    {
        "suburb": "St Albans",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Wilberforce",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "East Kurrajong",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Kurrajong Hills",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Central Macdonald",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Clarendon",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Colo Heights",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Hobartville",
        "postcode": "2753",
        "state": "New South Wales"
    },
    {
        "suburb": "North Richmond",
        "postcode": "2754",
        "state": "New South Wales"
    },
    {
        "suburb": "Richmond",
        "postcode": "2753",
        "state": "New South Wales"
    },
    {
        "suburb": "Mulgrave",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "McGraths Hill",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowen Mountain",
        "postcode": "2753",
        "state": "New South Wales"
    },
    {
        "suburb": "Glossodia",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Kurrajong Heights",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "Kurmond",
        "postcode": "2757",
        "state": "New South Wales"
    },
    {
        "suburb": "Central Colo",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Windsor",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Pitt Town",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Scheyville",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Macdonald",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Wheeny Creek",
        "postcode": "2758",
        "state": "New South Wales"
    },
    {
        "suburb": "The Slopes",
        "postcode": "2754",
        "state": "New South Wales"
    },
    {
        "suburb": "Grose Wold",
        "postcode": "2753",
        "state": "New South Wales"
    },
    {
        "suburb": "Tennyson",
        "postcode": "2754",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Colo",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Womerah",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Fernances",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Putty",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Colo",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Maude",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Hay",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "One Tree",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Hay South",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodpark",
        "postcode": "2164",
        "state": "New South Wales"
    },
    {
        "suburb": "South Wentworthville",
        "postcode": "2145",
        "state": "New South Wales"
    },
    {
        "suburb": "Girraween",
        "postcode": "2145",
        "state": "New South Wales"
    },
    {
        "suburb": "Westmead",
        "postcode": "2145",
        "state": "New South Wales"
    },
    {
        "suburb": "Mays Hill",
        "postcode": "2145",
        "state": "New South Wales"
    },
    {
        "suburb": "Guildford",
        "postcode": "2161",
        "state": "New South Wales"
    },
    {
        "suburb": "Merrylands",
        "postcode": "2160",
        "state": "New South Wales"
    },
    {
        "suburb": "Merrylands West",
        "postcode": "2160",
        "state": "New South Wales"
    },
    {
        "suburb": "Guildford West",
        "postcode": "2161",
        "state": "New South Wales"
    },
    {
        "suburb": "Pendle Hill",
        "postcode": "2145",
        "state": "New South Wales"
    },
    {
        "suburb": "Granville",
        "postcode": "2142",
        "state": "New South Wales"
    },
    {
        "suburb": "Holroyd",
        "postcode": "2142",
        "state": "New South Wales"
    },
    {
        "suburb": "Wentworthville",
        "postcode": "2145",
        "state": "New South Wales"
    },
    {
        "suburb": "Pemulwuy",
        "postcode": "2145",
        "state": "New South Wales"
    },
    {
        "suburb": "Parramatta",
        "postcode": "2150",
        "state": "New South Wales"
    },
    {
        "suburb": "Cherrybrook",
        "postcode": "2126",
        "state": "New South Wales"
    },
    {
        "suburb": "Arcadia",
        "postcode": "2159",
        "state": "New South Wales"
    },
    {
        "suburb": "Hornsby Heights",
        "postcode": "2077",
        "state": "New South Wales"
    },
    {
        "suburb": "Asquith",
        "postcode": "2077",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Colah",
        "postcode": "2079",
        "state": "New South Wales"
    },
    {
        "suburb": "Berowra",
        "postcode": "2081",
        "state": "New South Wales"
    },
    {
        "suburb": "Cowan",
        "postcode": "2081",
        "state": "New South Wales"
    },
    {
        "suburb": "Galston",
        "postcode": "2159",
        "state": "New South Wales"
    },
    {
        "suburb": "Berrilee",
        "postcode": "2159",
        "state": "New South Wales"
    },
    {
        "suburb": "Berowra Waters",
        "postcode": "2082",
        "state": "New South Wales"
    },
    {
        "suburb": "Berowra Heights",
        "postcode": "2082",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Kuring-Gai",
        "postcode": "2080",
        "state": "New South Wales"
    },
    {
        "suburb": "Fiddletown",
        "postcode": "2159",
        "state": "New South Wales"
    },
    {
        "suburb": "Brooklyn",
        "postcode": "2083",
        "state": "New South Wales"
    },
    {
        "suburb": "Dangar Island",
        "postcode": "2083",
        "state": "New South Wales"
    },
    {
        "suburb": "Canoelands",
        "postcode": "2157",
        "state": "New South Wales"
    },
    {
        "suburb": "Laughtondale",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Singletons Mill",
        "postcode": "2775",
        "state": "New South Wales"
    },
    {
        "suburb": "Milsons Passage",
        "postcode": "2083",
        "state": "New South Wales"
    },
    {
        "suburb": "Berowra Creek",
        "postcode": "2082",
        "state": "New South Wales"
    },
    {
        "suburb": "Forest Glen",
        "postcode": "2157",
        "state": "New South Wales"
    },
    {
        "suburb": "Epping",
        "postcode": "2121",
        "state": "New South Wales"
    },
    {
        "suburb": "Beecroft",
        "postcode": "2119",
        "state": "New South Wales"
    },
    {
        "suburb": "Pennant Hills",
        "postcode": "2120",
        "state": "New South Wales"
    },
    {
        "suburb": "Westleigh",
        "postcode": "2120",
        "state": "New South Wales"
    },
    {
        "suburb": "Cheltenham",
        "postcode": "2119",
        "state": "New South Wales"
    },
    {
        "suburb": "Thornleigh",
        "postcode": "2120",
        "state": "New South Wales"
    },
    {
        "suburb": "North Epping",
        "postcode": "2121",
        "state": "New South Wales"
    },
    {
        "suburb": "Waitara",
        "postcode": "2077",
        "state": "New South Wales"
    },
    {
        "suburb": "Wahroonga",
        "postcode": "2076",
        "state": "New South Wales"
    },
    {
        "suburb": "Eastwood",
        "postcode": "2122",
        "state": "New South Wales"
    },
    {
        "suburb": "Normanhurst",
        "postcode": "2076",
        "state": "New South Wales"
    },
    {
        "suburb": "Gladesville",
        "postcode": "2111",
        "state": "New South Wales"
    },
    {
        "suburb": "Hunters Hill",
        "postcode": "2110",
        "state": "New South Wales"
    },
    {
        "suburb": "Woolwich",
        "postcode": "2110",
        "state": "New South Wales"
    },
    {
        "suburb": "Huntleys Point",
        "postcode": "2111",
        "state": "New South Wales"
    },
    {
        "suburb": "Huntleys Cove",
        "postcode": "2111",
        "state": "New South Wales"
    },
    {
        "suburb": "Henley",
        "postcode": "2111",
        "state": "New South Wales"
    },
    {
        "suburb": "Lugarno",
        "postcode": "2210",
        "state": "New South Wales"
    },
    {
        "suburb": "Peakhurst",
        "postcode": "2210",
        "state": "New South Wales"
    },
    {
        "suburb": "Carlton",
        "postcode": "2218",
        "state": "New South Wales"
    },
    {
        "suburb": "Oatley",
        "postcode": "2223",
        "state": "New South Wales"
    },
    {
        "suburb": "Mortdale",
        "postcode": "2223",
        "state": "New South Wales"
    },
    {
        "suburb": "Penshurst",
        "postcode": "2222",
        "state": "New South Wales"
    },
    {
        "suburb": "Hurstville",
        "postcode": "2220",
        "state": "New South Wales"
    },
    {
        "suburb": "Peakhurst Heights",
        "postcode": "2210",
        "state": "New South Wales"
    },
    {
        "suburb": "Rob Roy",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Auburn Vale",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Gilgai",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Inverell",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Gum Flat",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Russell",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Bukkulla",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Yetman",
        "postcode": "2410",
        "state": "New South Wales"
    },
    {
        "suburb": "Camp Creek",
        "postcode": "4385",
        "state": "New South Wales"
    },
    {
        "suburb": "Texas",
        "postcode": "4385",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashford",
        "postcode": "2361",
        "state": "New South Wales"
    },
    {
        "suburb": "Gragin",
        "postcode": "2403",
        "state": "New South Wales"
    },
    {
        "suburb": "Cherry Tree Hill",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Oakwood",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Pindaroi",
        "postcode": "2361",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallangra",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Atholwood",
        "postcode": "2361",
        "state": "New South Wales"
    },
    {
        "suburb": "Bonshaw",
        "postcode": "2361",
        "state": "New South Wales"
    },
    {
        "suburb": "Twin Rivers",
        "postcode": "2410",
        "state": "New South Wales"
    },
    {
        "suburb": "Limestone",
        "postcode": "2361",
        "state": "New South Wales"
    },
    {
        "suburb": "Yellow Dam",
        "postcode": "2371",
        "state": "New South Wales"
    },
    {
        "suburb": "Rocky Creek",
        "postcode": "2371",
        "state": "New South Wales"
    },
    {
        "suburb": "Wellingrove",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Nullamanna",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Stannifer",
        "postcode": "2369",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Mill",
        "postcode": "2369",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Plain",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Sapphire",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodstock",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Long Plain",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Swanbrook",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Elsmore",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Brodies Plains",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Kings Plains",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingsland",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Swan Vale",
        "postcode": "2370",
        "state": "New South Wales"
    },
    {
        "suburb": "Newstead",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Paradise",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Mountain",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Gala Vale",
        "postcode": "2716",
        "state": "New South Wales"
    },
    {
        "suburb": "Mairjimmy",
        "postcode": "2716",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerilderie",
        "postcode": "2716",
        "state": "New South Wales"
    },
    {
        "suburb": "Argoon",
        "postcode": "2707",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundure",
        "postcode": "2700",
        "state": "New South Wales"
    },
    {
        "suburb": "Coleambally",
        "postcode": "2707",
        "state": "New South Wales"
    },
    {
        "suburb": "Junee",
        "postcode": "2663",
        "state": "New South Wales"
    },
    {
        "suburb": "Dirnaseer",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Bethungra",
        "postcode": "2590",
        "state": "New South Wales"
    },
    {
        "suburb": "Illabo",
        "postcode": "2590",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Junee",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Erin Vale",
        "postcode": "2663",
        "state": "New South Wales"
    },
    {
        "suburb": "Junee Reefs",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Harefield",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Yathella",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Wantabadgery",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Eurongilly",
        "postcode": "2663",
        "state": "New South Wales"
    },
    {
        "suburb": "Wantiool",
        "postcode": "2663",
        "state": "New South Wales"
    },
    {
        "suburb": "Marinna",
        "postcode": "2663",
        "state": "New South Wales"
    },
    {
        "suburb": "Willi Willi",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Moparrabah",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Comara",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellbrook",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Deep Creek",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "South Kempsey",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "West Kempsey",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "East Kempsey",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Frederickton",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Station",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Crescent Head",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "South West Rocks",
        "postcode": "2431",
        "state": "New South Wales"
    },
    {
        "suburb": "Arakoon",
        "postcode": "2431",
        "state": "New South Wales"
    },
    {
        "suburb": "Millbank",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Wittitrin",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Mungay Creek",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Skillion Flat",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Collombatti",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Kundabung",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Pola Creek",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Stuarts Point",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Hampden Hall",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Hat Head",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Barraganyatti",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Grassy Head",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Summer Island",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Rainbow Reach",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Fishermans Reach",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerseyville",
        "postcode": "2431",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrahapinni",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Toorooka",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Temagog",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Willawarrin",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Corangula",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Mooneba",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Turners Flat",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Sherwood",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellimbopinni",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Seven Oaks",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Austral Eden",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarravel",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Gladstone",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenhill",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Kinchela",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Smithtown",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Kempsey",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Verges Creek",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Euroka",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Belmore River",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Aldavilla",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Yessabah",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Clybucca",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Hickeys Creek",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Rose Valley",
        "postcode": "2534",
        "state": "New South Wales"
    },
    {
        "suburb": "Gerringong",
        "postcode": "2534",
        "state": "New South Wales"
    },
    {
        "suburb": "Werri Beach",
        "postcode": "2534",
        "state": "New South Wales"
    },
    {
        "suburb": "Kiama Downs",
        "postcode": "2533",
        "state": "New South Wales"
    },
    {
        "suburb": "Kiama Heights",
        "postcode": "2533",
        "state": "New South Wales"
    },
    {
        "suburb": "Kiama",
        "postcode": "2533",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Kangaroo River",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Minnamurra",
        "postcode": "2533",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Kangaroo Valley",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrington Falls",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Barren Grounds",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Budderoo",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Yellow Rock",
        "postcode": "2527",
        "state": "New South Wales"
    },
    {
        "suburb": "Knights Hill",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Jamberoo",
        "postcode": "2533",
        "state": "New South Wales"
    },
    {
        "suburb": "Curramore",
        "postcode": "2533",
        "state": "New South Wales"
    },
    {
        "suburb": "Croom",
        "postcode": "2527",
        "state": "New South Wales"
    },
    {
        "suburb": "Foxground",
        "postcode": "2534",
        "state": "New South Wales"
    },
    {
        "suburb": "Toolijooa",
        "postcode": "2534",
        "state": "New South Wales"
    },
    {
        "suburb": "Broughton Village",
        "postcode": "2534",
        "state": "New South Wales"
    },
    {
        "suburb": "Gerroa",
        "postcode": "2534",
        "state": "New South Wales"
    },
    {
        "suburb": "Willow Vale",
        "postcode": "2534",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerrara",
        "postcode": "2533",
        "state": "New South Wales"
    },
    {
        "suburb": "Bombo",
        "postcode": "2533",
        "state": "New South Wales"
    },
    {
        "suburb": "Saddleback Mountain",
        "postcode": "2533",
        "state": "New South Wales"
    },
    {
        "suburb": "Ramsgate",
        "postcode": "2217",
        "state": "New South Wales"
    },
    {
        "suburb": "Hurstville Grove",
        "postcode": "2220",
        "state": "New South Wales"
    },
    {
        "suburb": "Connells Point",
        "postcode": "2221",
        "state": "New South Wales"
    },
    {
        "suburb": "South Hurstville",
        "postcode": "2221",
        "state": "New South Wales"
    },
    {
        "suburb": "Blakehurst",
        "postcode": "2221",
        "state": "New South Wales"
    },
    {
        "suburb": "Allawah",
        "postcode": "2218",
        "state": "New South Wales"
    },
    {
        "suburb": "Carss Park",
        "postcode": "2221",
        "state": "New South Wales"
    },
    {
        "suburb": "Beverley Park",
        "postcode": "2217",
        "state": "New South Wales"
    },
    {
        "suburb": "Kogarah Bay",
        "postcode": "2217",
        "state": "New South Wales"
    },
    {
        "suburb": "Sans Souci",
        "postcode": "2219",
        "state": "New South Wales"
    },
    {
        "suburb": "Kogarah",
        "postcode": "2217",
        "state": "New South Wales"
    },
    {
        "suburb": "Kyle Bay",
        "postcode": "2221",
        "state": "New South Wales"
    },
    {
        "suburb": "South Turramurra",
        "postcode": "2074",
        "state": "New South Wales"
    },
    {
        "suburb": "North Wahroonga",
        "postcode": "2076",
        "state": "New South Wales"
    },
    {
        "suburb": "Turramurra",
        "postcode": "2074",
        "state": "New South Wales"
    },
    {
        "suburb": "West Pymble",
        "postcode": "2073",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrawee",
        "postcode": "2074",
        "state": "New South Wales"
    },
    {
        "suburb": "Pymble",
        "postcode": "2073",
        "state": "New South Wales"
    },
    {
        "suburb": "Killara",
        "postcode": "2071",
        "state": "New South Wales"
    },
    {
        "suburb": "North Turramurra",
        "postcode": "2074",
        "state": "New South Wales"
    },
    {
        "suburb": "Lindfield",
        "postcode": "2070",
        "state": "New South Wales"
    },
    {
        "suburb": "St Ives",
        "postcode": "2075",
        "state": "New South Wales"
    },
    {
        "suburb": "Gordon",
        "postcode": "2072",
        "state": "New South Wales"
    },
    {
        "suburb": "St Ives Chase",
        "postcode": "2075",
        "state": "New South Wales"
    },
    {
        "suburb": "Roseville",
        "postcode": "2069",
        "state": "New South Wales"
    },
    {
        "suburb": "East Killara",
        "postcode": "2071",
        "state": "New South Wales"
    },
    {
        "suburb": "East Lindfield",
        "postcode": "2070",
        "state": "New South Wales"
    },
    {
        "suburb": "Roseville Chase",
        "postcode": "2069",
        "state": "New South Wales"
    },
    {
        "suburb": "Kilgra",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Gorge Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Peacock Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Sextonville",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Tabulam",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Edenville",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Doubtful Creek",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Tooloom",
        "postcode": "2475",
        "state": "New South Wales"
    },
    {
        "suburb": "Mummulgum",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Dyraaba",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Peacock",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Bottle Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Jacksons Flat",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Dyraaba",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Culmaran Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Terrace Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Sawpit Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Findon Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Ettrick",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Kyogle",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Bonalbo",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Ghinni Ghi",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Deep Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Horseshoe Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Paddys Flat",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Pagans Flat",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Sherwood",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Unumgar",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Dairy Flat",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Mallanganee",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Urbenville",
        "postcode": "2475",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Duck Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Iron Pot Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Roseberry",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyneden",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Cambridge Plateau",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Theresa Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Roseberry Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Pigeon",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Geneva",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Yabbra",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Simpkins Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Bingeebeebra Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "West Wiangaree",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodenbong",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandilands",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Gradys Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Toonumbar",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Wiangaree",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Tunglebung",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Horse Station Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Horseshoe Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Bonalbo",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Joes Box",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Woolners Arm",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Collins Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Homeleigh",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Boorabee Park",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Stony Chute",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Capeen Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Brumby Plains",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Lindesay Creek",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Bean Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "The Glen",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Rukenvale",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "The Risk",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Afterlee",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Smiths Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Cougal",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Loadstone",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Lynchs Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrazambil Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Boomi Creek",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Babyl Creek",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Duck Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Haystack",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Border Ranges",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Back Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Cawongla",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Duck Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Larnook",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Grevillia",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Bottle Creek",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Grevillia",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Cedar Point",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Eden Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Lillian Rock",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Muli Muli",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Cargelligo",
        "postcode": "2672",
        "state": "New South Wales"
    },
    {
        "suburb": "Condobolin",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Burcher",
        "postcode": "2671",
        "state": "New South Wales"
    },
    {
        "suburb": "Fifield",
        "postcode": "2875",
        "state": "New South Wales"
    },
    {
        "suburb": "Tottenham",
        "postcode": "2873",
        "state": "New South Wales"
    },
    {
        "suburb": "Boona Mount",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Albert",
        "postcode": "2873",
        "state": "New South Wales"
    },
    {
        "suburb": "Tullamore",
        "postcode": "2874",
        "state": "New South Wales"
    },
    {
        "suburb": "Curlew Waters",
        "postcode": "2672",
        "state": "New South Wales"
    },
    {
        "suburb": "Tullibigeal",
        "postcode": "2669",
        "state": "New South Wales"
    },
    {
        "suburb": "Fairholme",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Kiacatoo",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Miamley",
        "postcode": "2873",
        "state": "New South Wales"
    },
    {
        "suburb": "Valentine",
        "postcode": "2280",
        "state": "New South Wales"
    },
    {
        "suburb": "Swansea",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Belmont",
        "postcode": "2280",
        "state": "New South Wales"
    },
    {
        "suburb": "Caves Beach",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Pelican",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Blacksmiths",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Marks Point",
        "postcode": "2280",
        "state": "New South Wales"
    },
    {
        "suburb": "Swansea Heads",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Belmont North",
        "postcode": "2280",
        "state": "New South Wales"
    },
    {
        "suburb": "Windale",
        "postcode": "2306",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Hutton",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Jewells",
        "postcode": "2280",
        "state": "New South Wales"
    },
    {
        "suburb": "Gateshead",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Redhead",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Nords Wharf",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Macquarie",
        "postcode": "2280",
        "state": "New South Wales"
    },
    {
        "suburb": "Croudace Bay",
        "postcode": "2280",
        "state": "New South Wales"
    },
    {
        "suburb": "Belmont South",
        "postcode": "2280",
        "state": "New South Wales"
    },
    {
        "suburb": "Eleebana",
        "postcode": "2282",
        "state": "New South Wales"
    },
    {
        "suburb": "Charlestown",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Catherine Hill Bay",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Pinny Beach",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Cams Wharf",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Pelican",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Dudley",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Bennetts Green",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Floraville",
        "postcode": "2280",
        "state": "New South Wales"
    },
    {
        "suburb": "Warners Bay",
        "postcode": "2282",
        "state": "New South Wales"
    },
    {
        "suburb": "Tingira Heights",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Seahampton",
        "postcode": "2286",
        "state": "New South Wales"
    },
    {
        "suburb": "West Wallsend",
        "postcode": "2286",
        "state": "New South Wales"
    },
    {
        "suburb": "Highfields",
        "postcode": "2289",
        "state": "New South Wales"
    },
    {
        "suburb": "Cardiff Heights",
        "postcode": "2285",
        "state": "New South Wales"
    },
    {
        "suburb": "Cardiff",
        "postcode": "2285",
        "state": "New South Wales"
    },
    {
        "suburb": "Barnsley",
        "postcode": "2278",
        "state": "New South Wales"
    },
    {
        "suburb": "Edgeworth",
        "postcode": "2285",
        "state": "New South Wales"
    },
    {
        "suburb": "Glendale",
        "postcode": "2285",
        "state": "New South Wales"
    },
    {
        "suburb": "Whitebridge",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Speers Point",
        "postcode": "2284",
        "state": "New South Wales"
    },
    {
        "suburb": "Holmesville",
        "postcode": "2286",
        "state": "New South Wales"
    },
    {
        "suburb": "Hillsborough",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Cameron Park",
        "postcode": "2285",
        "state": "New South Wales"
    },
    {
        "suburb": "Wakefield",
        "postcode": "2278",
        "state": "New South Wales"
    },
    {
        "suburb": "Teralba",
        "postcode": "2284",
        "state": "New South Wales"
    },
    {
        "suburb": "Killingworth",
        "postcode": "2278",
        "state": "New South Wales"
    },
    {
        "suburb": "Argenton",
        "postcode": "2284",
        "state": "New South Wales"
    },
    {
        "suburb": "Boolaroo",
        "postcode": "2284",
        "state": "New South Wales"
    },
    {
        "suburb": "Kotara South",
        "postcode": "2289",
        "state": "New South Wales"
    },
    {
        "suburb": "Adamstown Heights",
        "postcode": "2289",
        "state": "New South Wales"
    },
    {
        "suburb": "Rankin Park",
        "postcode": "2287",
        "state": "New South Wales"
    },
    {
        "suburb": "Garden Suburb",
        "postcode": "2289",
        "state": "New South Wales"
    },
    {
        "suburb": "New Lambton Heights",
        "postcode": "2305",
        "state": "New South Wales"
    },
    {
        "suburb": "Macquarie Hills",
        "postcode": "2285",
        "state": "New South Wales"
    },
    {
        "suburb": "Cardiff South",
        "postcode": "2285",
        "state": "New South Wales"
    },
    {
        "suburb": "Kahibah",
        "postcode": "2290",
        "state": "New South Wales"
    },
    {
        "suburb": "Lakelands",
        "postcode": "2282",
        "state": "New South Wales"
    },
    {
        "suburb": "Dora Creek",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyee",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Windermere Park",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Bonnells Bay",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Awaba",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Brightwaters",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Balcolyn",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Sunshine",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Toronto",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Wangi Wangi",
        "postcode": "2267",
        "state": "New South Wales"
    },
    {
        "suburb": "Blackalls Park",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Buttaba",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Arcadia Vale",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Rathmines",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Fishing Point",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Fennell Bay",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Bolton Point",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Booragul",
        "postcode": "2284",
        "state": "New South Wales"
    },
    {
        "suburb": "Marmong Point",
        "postcode": "2284",
        "state": "New South Wales"
    },
    {
        "suburb": "Myuna Bay",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Eraring",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Freemans Waterhole",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Fassifern",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodrising",
        "postcode": "2284",
        "state": "New South Wales"
    },
    {
        "suburb": "Carey Bay",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Kilaben Bay",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Coal Point",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Balmoral",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooranbong",
        "postcode": "2265",
        "state": "New South Wales"
    },
    {
        "suburb": "Morisset",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Martinsville",
        "postcode": "2265",
        "state": "New South Wales"
    },
    {
        "suburb": "Mandalong",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyee Point",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Morisset Park",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrawonga Park",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Silverwater",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Mirrabooka",
        "postcode": "2264",
        "state": "New South Wales"
    },
    {
        "suburb": "Lane Cove West",
        "postcode": "2066",
        "state": "New South Wales"
    },
    {
        "suburb": "Lane Cove",
        "postcode": "2066",
        "state": "New South Wales"
    },
    {
        "suburb": "Riverview",
        "postcode": "2066",
        "state": "New South Wales"
    },
    {
        "suburb": "Lane Cove North",
        "postcode": "2066",
        "state": "New South Wales"
    },
    {
        "suburb": "Longueville",
        "postcode": "2066",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenwich",
        "postcode": "2065",
        "state": "New South Wales"
    },
    {
        "suburb": "St Leonards",
        "postcode": "2065",
        "state": "New South Wales"
    },
    {
        "suburb": "Northwood",
        "postcode": "2066",
        "state": "New South Wales"
    },
    {
        "suburb": "Linley Point",
        "postcode": "2066",
        "state": "New South Wales"
    },
    {
        "suburb": "Euroley",
        "postcode": "2700",
        "state": "New South Wales"
    },
    {
        "suburb": "Leeton",
        "postcode": "2705",
        "state": "New South Wales"
    },
    {
        "suburb": "Gogeldrie",
        "postcode": "2705",
        "state": "New South Wales"
    },
    {
        "suburb": "Yanco",
        "postcode": "2703",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrami",
        "postcode": "2705",
        "state": "New South Wales"
    },
    {
        "suburb": "Cudgel",
        "postcode": "2700",
        "state": "New South Wales"
    },
    {
        "suburb": "Corbie Hill",
        "postcode": "2705",
        "state": "New South Wales"
    },
    {
        "suburb": "Merungle Hill",
        "postcode": "2705",
        "state": "New South Wales"
    },
    {
        "suburb": "Stanbridge",
        "postcode": "2705",
        "state": "New South Wales"
    },
    {
        "suburb": "Leichhardt",
        "postcode": "2040",
        "state": "New South Wales"
    },
    {
        "suburb": "Lilyfield",
        "postcode": "2040",
        "state": "New South Wales"
    },
    {
        "suburb": "Annandale",
        "postcode": "2038",
        "state": "New South Wales"
    },
    {
        "suburb": "Rozelle",
        "postcode": "2039",
        "state": "New South Wales"
    },
    {
        "suburb": "Balmain",
        "postcode": "2041",
        "state": "New South Wales"
    },
    {
        "suburb": "Birchgrove",
        "postcode": "2041",
        "state": "New South Wales"
    },
    {
        "suburb": "Balmain East",
        "postcode": "2041",
        "state": "New South Wales"
    },
    {
        "suburb": "East Lismore",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Lismore",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Goonellabah",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "South Lismore",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "North Lismore",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Girards Hill",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Lismore Heights",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Loftville",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Howards Grass",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Lagoon Grass",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Chilcotts Grass",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyrallah",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "South Gundurimba",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Caniaba",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Boat Harbour",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Richmond Hill",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Lindendale",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Tregeagle",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Monaltrie",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Tucki Tucki",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Keerrong",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Mountain Top",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuckurimba",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Modanville",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Whian Whian",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Repentance Creek",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Ruthven",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Nimbin",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Blue Knob",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Georgica",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Terania Creek",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuntable Creek",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Coffee Camp",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Koonorigan",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Jiggi",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Rock Valley",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungabbee",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Blakebrook",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Tullera",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodlawn",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Numulgi",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Dunoon",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Dorroughby",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Rosebank",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Corndale",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Pearces Creek",
        "postcode": "2477",
        "state": "New South Wales"
    },
    {
        "suburb": "Bexhill",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Fernside",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuncester",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "North Woodburn",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "Buckendoon",
        "postcode": "2472",
        "state": "New South Wales"
    },
    {
        "suburb": "Kilgin",
        "postcode": "2472",
        "state": "New South Wales"
    },
    {
        "suburb": "The Channon",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Eltham",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Dungarubba",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Forest",
        "postcode": "2471",
        "state": "New South Wales"
    },
    {
        "suburb": "Nightcap",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Goolmangar",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Leycester",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowenfels",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Hampton",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Sodwalls",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Running Stream",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Brogans Creek",
        "postcode": "2848",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Nile",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Palmers Oaky",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Hartley Vale",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Dargan",
        "postcode": "2786",
        "state": "New South Wales"
    },
    {
        "suburb": "Round Swamp",
        "postcode": "2846",
        "state": "New South Wales"
    },
    {
        "suburb": "Capertee",
        "postcode": "2846",
        "state": "New South Wales"
    },
    {
        "suburb": "Portland",
        "postcode": "2847",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Hartley",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Ganbenang",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Wirraba",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Kanimbla",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Davis",
        "postcode": "2846",
        "state": "New South Wales"
    },
    {
        "suburb": "Newnes",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Cullen Bullen",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollemi",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Dark Corner",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollangambe",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "McKellars Park",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Bogee",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Marsden",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "South Littleton",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Oakey Park",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Vale Of Clwydd",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Corney Town",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Lidsdale",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Hermitage Flat",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Lithgow",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Cobar Park",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Pottery Estate",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Sheedys Gully",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Littleton",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "South Bowenfels",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Morts Estate",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallerawang",
        "postcode": "2845",
        "state": "New South Wales"
    },
    {
        "suburb": "Marrangaroo",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Hassans Walls",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Clarence",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Newnes Plateau",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "State Mine Gully",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Ben Bullen",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Springvale",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Hartley",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Alice",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Dunville Loop",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Rydal",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Lowther",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Wolgan Valley",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Lambie",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Doctors Gap",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Blackmans Flat",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Good Forest",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Busby",
        "postcode": "2168",
        "state": "New South Wales"
    },
    {
        "suburb": "Miller",
        "postcode": "2168",
        "state": "New South Wales"
    },
    {
        "suburb": "Casula",
        "postcode": "2170",
        "state": "New South Wales"
    },
    {
        "suburb": "Cartwright",
        "postcode": "2168",
        "state": "New South Wales"
    },
    {
        "suburb": "Heckenberg",
        "postcode": "2168",
        "state": "New South Wales"
    },
    {
        "suburb": "Sadleir",
        "postcode": "2168",
        "state": "New South Wales"
    },
    {
        "suburb": "Lurnea",
        "postcode": "2170",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashcroft",
        "postcode": "2168",
        "state": "New South Wales"
    },
    {
        "suburb": "Liverpool",
        "postcode": "2170",
        "state": "New South Wales"
    },
    {
        "suburb": "Moorebank",
        "postcode": "2170",
        "state": "New South Wales"
    },
    {
        "suburb": "Warwick Farm",
        "postcode": "2170",
        "state": "New South Wales"
    },
    {
        "suburb": "Wattle Grove",
        "postcode": "2173",
        "state": "New South Wales"
    },
    {
        "suburb": "Chipping Norton",
        "postcode": "2170",
        "state": "New South Wales"
    },
    {
        "suburb": "Hammondville",
        "postcode": "2170",
        "state": "New South Wales"
    },
    {
        "suburb": "Prestons",
        "postcode": "2170",
        "state": "New South Wales"
    },
    {
        "suburb": "Voyager Point",
        "postcode": "2172",
        "state": "New South Wales"
    },
    {
        "suburb": "Pleasure Point",
        "postcode": "2172",
        "state": "New South Wales"
    },
    {
        "suburb": "West Hoxton",
        "postcode": "2171",
        "state": "New South Wales"
    },
    {
        "suburb": "Cecil Hills",
        "postcode": "2171",
        "state": "New South Wales"
    },
    {
        "suburb": "Hoxton Park",
        "postcode": "2171",
        "state": "New South Wales"
    },
    {
        "suburb": "Hinchinbrook",
        "postcode": "2168",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Valley",
        "postcode": "2168",
        "state": "New South Wales"
    },
    {
        "suburb": "Horningsea Park",
        "postcode": "2171",
        "state": "New South Wales"
    },
    {
        "suburb": "Kemps Creek",
        "postcode": "2178",
        "state": "New South Wales"
    },
    {
        "suburb": "Austral",
        "postcode": "2179",
        "state": "New South Wales"
    },
    {
        "suburb": "Middleton Grange",
        "postcode": "2171",
        "state": "New South Wales"
    },
    {
        "suburb": "Edmondson Park",
        "postcode": "2174",
        "state": "New South Wales"
    },
    {
        "suburb": "Badgerys Creek",
        "postcode": "2555",
        "state": "New South Wales"
    },
    {
        "suburb": "Luddenham",
        "postcode": "2745",
        "state": "New South Wales"
    },
    {
        "suburb": "Greendale",
        "postcode": "2745",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallacia",
        "postcode": "2745",
        "state": "New South Wales"
    },
    {
        "suburb": "Blackville",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrah Creek",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Big Jacks Creek",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Ardglen",
        "postcode": "2338",
        "state": "New South Wales"
    },
    {
        "suburb": "Willow Tree",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Werris Creek",
        "postcode": "2341",
        "state": "New South Wales"
    },
    {
        "suburb": "Quirindi",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Quipolly",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallabadah",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Pine Ridge",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Currabubula",
        "postcode": "2342",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundella",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Braefield",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrah Ridge",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Yannergee",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Colly Blue",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarraman",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Coomoo Coomoo",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Windy",
        "postcode": "2343",
        "state": "New South Wales"
    },
    {
        "suburb": "Cattle Creek",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Parraweena",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Macdonalds Creek",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Jacks Creek",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Blandford",
        "postcode": "2338",
        "state": "New South Wales"
    },
    {
        "suburb": "Chilcotts Creek",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Gowrie",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrah",
        "postcode": "2339",
        "state": "New South Wales"
    },
    {
        "suburb": "Lockhart",
        "postcode": "2656",
        "state": "New South Wales"
    },
    {
        "suburb": "Cullivel",
        "postcode": "2645",
        "state": "New South Wales"
    },
    {
        "suburb": "Yerong Creek",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Milbrulong",
        "postcode": "2656",
        "state": "New South Wales"
    },
    {
        "suburb": "The Rock",
        "postcode": "2655",
        "state": "New South Wales"
    },
    {
        "suburb": "Boree Creek",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Osborne",
        "postcode": "2656",
        "state": "New South Wales"
    },
    {
        "suburb": "Fargunyah",
        "postcode": "2656",
        "state": "New South Wales"
    },
    {
        "suburb": "Urana",
        "postcode": "2645",
        "state": "New South Wales"
    },
    {
        "suburb": "Urangeline",
        "postcode": "2656",
        "state": "New South Wales"
    },
    {
        "suburb": "Bidgeemia",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Urangeline East",
        "postcode": "2656",
        "state": "New South Wales"
    },
    {
        "suburb": "Pleasant Hills",
        "postcode": "2658",
        "state": "New South Wales"
    },
    {
        "suburb": "Ryan",
        "postcode": "2658",
        "state": "New South Wales"
    },
    {
        "suburb": "Munyabla",
        "postcode": "2658",
        "state": "New South Wales"
    },
    {
        "suburb": "Wrathall",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "French Park",
        "postcode": "2655",
        "state": "New South Wales"
    },
    {
        "suburb": "Tootool",
        "postcode": "2655",
        "state": "New South Wales"
    },
    {
        "suburb": "Gillieston Heights",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Lambs Valley",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Mindaribba",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Metford",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Lochinvar",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodberry",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Maitland",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Windella",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Harpers Hill",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Thornton",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Anambah",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Rutherford",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "South Maitland",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "East Maitland",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Louth Park",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodville",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Farley",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Bolwarra",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Rosebrook",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Berry Park",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Morpeth",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Millers Forest",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Duckenfield",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Oswald",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Melville",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Phoenix Park",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Luskintyre",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Gosforth",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Lorn",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Bolwarra Heights",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Telarah",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Raworth",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashtonfield",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Aberglasslyn",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Tenambit",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Hillsborough",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Largs",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Pitnacree",
        "postcode": "2323",
        "state": "New South Wales"
    },
    {
        "suburb": "Horseshoe Bend",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Dee",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Windermere",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Chisholm",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Oakhampton Heights",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Oakhampton",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Maitland Vale",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Seaforth",
        "postcode": "2092",
        "state": "New South Wales"
    },
    {
        "suburb": "Balgowlah",
        "postcode": "2093",
        "state": "New South Wales"
    },
    {
        "suburb": "Balgowlah Heights",
        "postcode": "2093",
        "state": "New South Wales"
    },
    {
        "suburb": "Fairlight",
        "postcode": "2094",
        "state": "New South Wales"
    },
    {
        "suburb": "Manly",
        "postcode": "2095",
        "state": "New South Wales"
    },
    {
        "suburb": "Clontarf",
        "postcode": "2093",
        "state": "New South Wales"
    },
    {
        "suburb": "Dulwich Hill",
        "postcode": "2203",
        "state": "New South Wales"
    },
    {
        "suburb": "Marrickville",
        "postcode": "2204",
        "state": "New South Wales"
    },
    {
        "suburb": "Lewisham",
        "postcode": "2049",
        "state": "New South Wales"
    },
    {
        "suburb": "Petersham",
        "postcode": "2049",
        "state": "New South Wales"
    },
    {
        "suburb": "Tempe",
        "postcode": "2044",
        "state": "New South Wales"
    },
    {
        "suburb": "Stanmore",
        "postcode": "2048",
        "state": "New South Wales"
    },
    {
        "suburb": "Sydenham",
        "postcode": "2044",
        "state": "New South Wales"
    },
    {
        "suburb": "St Peters",
        "postcode": "2044",
        "state": "New South Wales"
    },
    {
        "suburb": "Enmore",
        "postcode": "2042",
        "state": "New South Wales"
    },
    {
        "suburb": "Newtown",
        "postcode": "2042",
        "state": "New South Wales"
    },
    {
        "suburb": "Camperdown",
        "postcode": "2050",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Gully",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Windeyer",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Gulgong",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Mudgee",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Stubbo",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Linburn",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Stony Creek",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Turill",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Bara",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrabin",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Cudgegong",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Tallawang",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Cope",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Bombira",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Beryl",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Mebul",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Birriwa",
        "postcode": "2844",
        "state": "New South Wales"
    },
    {
        "suburb": "Merotherie",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Barneys Reef",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Moolarben",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Cumbo",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Mogo",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Ulan",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Wilpinjong",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollar",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrawonga",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Cumbandry",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Home Rule",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Frog Rock",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "St Fillans",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Eurunderee",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Apple Tree Flat",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Meroo",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Carcalgong",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Bocoble",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Pyramul",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Hargraves",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungaba",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Twelve Mile",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Worlds End",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Menah",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrundulla",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Flat",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Knowles",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Barigan",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Tichular",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Avisford",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Buckaroo",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Totnes Valley",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Erudgere",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Budgee Budgee",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Botobolar",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooyal",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Cross Roads",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Hayes Gap",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Lue",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Frome",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Galambine",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Cullenbone",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Wilbetree",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Triamble",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Grattai",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Maitland Bar",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Goolma",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Two Mile Flat",
        "postcode": "2852",
        "state": "New South Wales"
    },
    {
        "suburb": "Queens Pinch",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Milroy",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Putta Bucca",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Munghorn",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Kains Flat",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Piambong",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooks Gap",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Collingwood",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Kandos",
        "postcode": "2848",
        "state": "New South Wales"
    },
    {
        "suburb": "Rylstone",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Bylong",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrumbo",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Lee Creek",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Coggan",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Budden",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Nullo Mountain",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Ginghi",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Growee",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Pyangle",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Monivae",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Pinnacle Swamp",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Coxs Crown",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Olinda",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Clandulla",
        "postcode": "2848",
        "state": "New South Wales"
    },
    {
        "suburb": "Dabee",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Charbon",
        "postcode": "2848",
        "state": "New South Wales"
    },
    {
        "suburb": "Ilford",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Aarons Pass",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Carwell",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Camboon",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Breakfast Creek",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Growee",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Weemelah",
        "postcode": "2406",
        "state": "New South Wales"
    },
    {
        "suburb": "Bullarah",
        "postcode": "2400",
        "state": "New South Wales"
    },
    {
        "suburb": "Collarenebri",
        "postcode": "2833",
        "state": "New South Wales"
    },
    {
        "suburb": "Rowena",
        "postcode": "2387",
        "state": "New South Wales"
    },
    {
        "suburb": "Mallowa",
        "postcode": "2400",
        "state": "New South Wales"
    },
    {
        "suburb": "Millie",
        "postcode": "2397",
        "state": "New South Wales"
    },
    {
        "suburb": "Gurley",
        "postcode": "2398",
        "state": "New South Wales"
    },
    {
        "suburb": "Boomi",
        "postcode": "2405",
        "state": "New South Wales"
    },
    {
        "suburb": "Moree",
        "postcode": "2400",
        "state": "New South Wales"
    },
    {
        "suburb": "Berrigal",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Terry Hie Hie",
        "postcode": "2400",
        "state": "New South Wales"
    },
    {
        "suburb": "Garah",
        "postcode": "2405",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashley",
        "postcode": "2400",
        "state": "New South Wales"
    },
    {
        "suburb": "Mungindi",
        "postcode": "2406",
        "state": "New South Wales"
    },
    {
        "suburb": "Boggabilla",
        "postcode": "2409",
        "state": "New South Wales"
    },
    {
        "suburb": "Biniguy",
        "postcode": "2399",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellata",
        "postcode": "2397",
        "state": "New South Wales"
    },
    {
        "suburb": "Mosman",
        "postcode": "2088",
        "state": "New South Wales"
    },
    {
        "suburb": "Cremorne Point",
        "postcode": "2090",
        "state": "New South Wales"
    },
    {
        "suburb": "Moama",
        "postcode": "2731",
        "state": "New South Wales"
    },
    {
        "suburb": "Calimo",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Thule",
        "postcode": "2732",
        "state": "New South Wales"
    },
    {
        "suburb": "Mathoura",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Bullatale",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Caldwell",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Tantonan",
        "postcode": "2731",
        "state": "New South Wales"
    },
    {
        "suburb": "Womboota",
        "postcode": "2731",
        "state": "New South Wales"
    },
    {
        "suburb": "Aratula",
        "postcode": "2714",
        "state": "New South Wales"
    },
    {
        "suburb": "Bunnaloo",
        "postcode": "2731",
        "state": "New South Wales"
    },
    {
        "suburb": "Steam Plains",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Darlington Point",
        "postcode": "2706",
        "state": "New South Wales"
    },
    {
        "suburb": "McCullys Gap",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowmans Creek",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerrys Plains",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Denman",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Bengalla",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Muswellbrook",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Baerami Creek",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Hebden",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Edderton",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Muscle Creek",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Kayuga",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Manobalai",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Castle Rock",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Giants Creek",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Wybong",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Mangoola",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandy Hollow",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Hollydeen",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Bureen",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Dalswinton",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Martindale",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Widden",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Kerrabee",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Baerami",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrawa",
        "postcode": "2328",
        "state": "New South Wales"
    },
    {
        "suburb": "Rouchel Brook",
        "postcode": "2336",
        "state": "New South Wales"
    },
    {
        "suburb": "Nambucca Heads",
        "postcode": "2448",
        "state": "New South Wales"
    },
    {
        "suburb": "Scotts Head",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Killiekrankie",
        "postcode": "2449",
        "state": "New South Wales"
    },
    {
        "suburb": "Thumb Creek",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Taylors Arm",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Bakers Creek",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Allgomera",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Way Way",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Hyland Park",
        "postcode": "2448",
        "state": "New South Wales"
    },
    {
        "suburb": "Valla Beach",
        "postcode": "2448",
        "state": "New South Wales"
    },
    {
        "suburb": "Gumma",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "North Macksville",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrapine",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Buckra Bendinni",
        "postcode": "2449",
        "state": "New South Wales"
    },
    {
        "suburb": "Argents Hill",
        "postcode": "2449",
        "state": "New South Wales"
    },
    {
        "suburb": "Girralong",
        "postcode": "2449",
        "state": "New South Wales"
    },
    {
        "suburb": "Tewinga",
        "postcode": "2449",
        "state": "New South Wales"
    },
    {
        "suburb": "Eungai Creek",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Macksville",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Wirrimbi",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Tamban",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Newee Creek",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowraville",
        "postcode": "2449",
        "state": "New South Wales"
    },
    {
        "suburb": "Taylors Arm",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "South Arm",
        "postcode": "2449",
        "state": "New South Wales"
    },
    {
        "suburb": "Missabotti",
        "postcode": "2449",
        "state": "New South Wales"
    },
    {
        "suburb": "Congarinni North",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrell Creek",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Congarinni",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Utungun",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Eungai Rail",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarranbella",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Talarm",
        "postcode": "2447",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulyeroi",
        "postcode": "2387",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Plains",
        "postcode": "2388",
        "state": "New South Wales"
    },
    {
        "suburb": "Wee Waa",
        "postcode": "2388",
        "state": "New South Wales"
    },
    {
        "suburb": "Jacks Creek",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Narrabri",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Eulah Creek",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Maules Creek",
        "postcode": "2382",
        "state": "New South Wales"
    },
    {
        "suburb": "Harparary",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Baan Baa",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Bohena Creek",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Gwabegar",
        "postcode": "2356",
        "state": "New South Wales"
    },
    {
        "suburb": "Couradda",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Merah North",
        "postcode": "2388",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarriaro",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Turrawan",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Bullawa Creek",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Kaputar",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrie Lake",
        "postcode": "2388",
        "state": "New South Wales"
    },
    {
        "suburb": "Drildool",
        "postcode": "2386",
        "state": "New South Wales"
    },
    {
        "suburb": "Cuttabri",
        "postcode": "2388",
        "state": "New South Wales"
    },
    {
        "suburb": "Jews Lagoon",
        "postcode": "2397",
        "state": "New South Wales"
    },
    {
        "suburb": "Nowley",
        "postcode": "2386",
        "state": "New South Wales"
    },
    {
        "suburb": "Boolcarroll",
        "postcode": "2388",
        "state": "New South Wales"
    },
    {
        "suburb": "Corobimilla",
        "postcode": "2700",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandigo",
        "postcode": "2700",
        "state": "New South Wales"
    },
    {
        "suburb": "Moombooldool",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Galore",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Grong Grong",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Narrandera",
        "postcode": "2700",
        "state": "New South Wales"
    },
    {
        "suburb": "Brobenah",
        "postcode": "2705",
        "state": "New South Wales"
    },
    {
        "suburb": "Colinroobie",
        "postcode": "2700",
        "state": "New South Wales"
    },
    {
        "suburb": "Morundah",
        "postcode": "2700",
        "state": "New South Wales"
    },
    {
        "suburb": "Gillenbah",
        "postcode": "2700",
        "state": "New South Wales"
    },
    {
        "suburb": "Landervale",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Narromine",
        "postcode": "2821",
        "state": "New South Wales"
    },
    {
        "suburb": "Trangie",
        "postcode": "2823",
        "state": "New South Wales"
    },
    {
        "suburb": "Tomingley",
        "postcode": "2869",
        "state": "New South Wales"
    },
    {
        "suburb": "Burroway",
        "postcode": "2821",
        "state": "New South Wales"
    },
    {
        "suburb": "Dandaloo",
        "postcode": "2823",
        "state": "New South Wales"
    },
    {
        "suburb": "Gin Gin",
        "postcode": "2823",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundemar",
        "postcode": "2823",
        "state": "New South Wales"
    },
    {
        "suburb": "Merewether",
        "postcode": "2291",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayfield West",
        "postcode": "2304",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayfield",
        "postcode": "2304",
        "state": "New South Wales"
    },
    {
        "suburb": "Wickham",
        "postcode": "2293",
        "state": "New South Wales"
    },
    {
        "suburb": "Maryville",
        "postcode": "2293",
        "state": "New South Wales"
    },
    {
        "suburb": "Merewether Heights",
        "postcode": "2291",
        "state": "New South Wales"
    },
    {
        "suburb": "The Junction",
        "postcode": "2291",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrington",
        "postcode": "2294",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayfield North",
        "postcode": "2304",
        "state": "New South Wales"
    },
    {
        "suburb": "Stockton",
        "postcode": "2295",
        "state": "New South Wales"
    },
    {
        "suburb": "The Hill",
        "postcode": "2300",
        "state": "New South Wales"
    },
    {
        "suburb": "Newcastle West",
        "postcode": "2302",
        "state": "New South Wales"
    },
    {
        "suburb": "Kooragang",
        "postcode": "2304",
        "state": "New South Wales"
    },
    {
        "suburb": "Newcastle East",
        "postcode": "2300",
        "state": "New South Wales"
    },
    {
        "suburb": "Newcastle",
        "postcode": "2300",
        "state": "New South Wales"
    },
    {
        "suburb": "Bar Beach",
        "postcode": "2300",
        "state": "New South Wales"
    },
    {
        "suburb": "Islington",
        "postcode": "2296",
        "state": "New South Wales"
    },
    {
        "suburb": "Warabrook",
        "postcode": "2304",
        "state": "New South Wales"
    },
    {
        "suburb": "Tighes Hill",
        "postcode": "2297",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooks Hill",
        "postcode": "2300",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayfield East",
        "postcode": "2304",
        "state": "New South Wales"
    },
    {
        "suburb": "Beresfield",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Maryland",
        "postcode": "2287",
        "state": "New South Wales"
    },
    {
        "suburb": "Elermore Vale",
        "postcode": "2287",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallsend",
        "postcode": "2287",
        "state": "New South Wales"
    },
    {
        "suburb": "Jesmond",
        "postcode": "2299",
        "state": "New South Wales"
    },
    {
        "suburb": "Birmingham Gardens",
        "postcode": "2287",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandgate",
        "postcode": "2304",
        "state": "New South Wales"
    },
    {
        "suburb": "Shortland",
        "postcode": "2307",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarro",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Minmi",
        "postcode": "2287",
        "state": "New South Wales"
    },
    {
        "suburb": "Hexham",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Fletcher",
        "postcode": "2287",
        "state": "New South Wales"
    },
    {
        "suburb": "Lenaghan",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Callaghan",
        "postcode": "2308",
        "state": "New South Wales"
    },
    {
        "suburb": "Hamilton East",
        "postcode": "2303",
        "state": "New South Wales"
    },
    {
        "suburb": "Kotara",
        "postcode": "2289",
        "state": "New South Wales"
    },
    {
        "suburb": "Lambton",
        "postcode": "2299",
        "state": "New South Wales"
    },
    {
        "suburb": "Hamilton South",
        "postcode": "2303",
        "state": "New South Wales"
    },
    {
        "suburb": "North Lambton",
        "postcode": "2299",
        "state": "New South Wales"
    },
    {
        "suburb": "New Lambton",
        "postcode": "2305",
        "state": "New South Wales"
    },
    {
        "suburb": "Waratah West",
        "postcode": "2298",
        "state": "New South Wales"
    },
    {
        "suburb": "Adamstown",
        "postcode": "2289",
        "state": "New South Wales"
    },
    {
        "suburb": "Waratah",
        "postcode": "2298",
        "state": "New South Wales"
    },
    {
        "suburb": "Georgetown",
        "postcode": "2298",
        "state": "New South Wales"
    },
    {
        "suburb": "Hamilton North",
        "postcode": "2292",
        "state": "New South Wales"
    },
    {
        "suburb": "Hamilton",
        "postcode": "2303",
        "state": "New South Wales"
    },
    {
        "suburb": "Broadmeadow",
        "postcode": "2292",
        "state": "New South Wales"
    },
    {
        "suburb": "Waverton",
        "postcode": "2060",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollstonecraft",
        "postcode": "2065",
        "state": "New South Wales"
    },
    {
        "suburb": "Neutral Bay",
        "postcode": "2089",
        "state": "New South Wales"
    },
    {
        "suburb": "Kirribilli",
        "postcode": "2061",
        "state": "New South Wales"
    },
    {
        "suburb": "Crows Nest",
        "postcode": "2065",
        "state": "New South Wales"
    },
    {
        "suburb": "Milsons Point",
        "postcode": "2061",
        "state": "New South Wales"
    },
    {
        "suburb": "Cammeray",
        "postcode": "2062",
        "state": "New South Wales"
    },
    {
        "suburb": "North Sydney",
        "postcode": "2060",
        "state": "New South Wales"
    },
    {
        "suburb": "Lavender Bay",
        "postcode": "2060",
        "state": "New South Wales"
    },
    {
        "suburb": "Kurraba Point",
        "postcode": "2089",
        "state": "New South Wales"
    },
    {
        "suburb": "McMahons Point",
        "postcode": "2060",
        "state": "New South Wales"
    },
    {
        "suburb": "Cremorne",
        "postcode": "2090",
        "state": "New South Wales"
    },
    {
        "suburb": "Burraga",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Isabella",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Rockley",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Shooters Hill",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Porters Retreat",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Mozart",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Arkstone",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Black Springs",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Judds Creek",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount David",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Jeremy",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Gingkin",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Jaunter",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Edith",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Chatham Valley",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Norway",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Oberon",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Dog Rocks",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Essington",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Twenty Forests",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayfield",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Hazelgrove",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "The Meadows",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Duckmaloi",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Wiarborough",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Kanangra",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Olive",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Gurnang",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Werong",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "Jenolan",
        "postcode": "2790",
        "state": "New South Wales"
    },
    {
        "suburb": "Ballyroe",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Paling Yards",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerrong",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Lucknow",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Creek",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Hill",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Huntley",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Shadforth",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Forbes Creek",
        "postcode": "2621",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrow",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Urila",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Googong",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake George",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Rossi",
        "postcode": "2621",
        "state": "New South Wales"
    },
    {
        "suburb": "Hoskinstown",
        "postcode": "2621",
        "state": "New South Wales"
    },
    {
        "suburb": "Captains Flat",
        "postcode": "2623",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungendore",
        "postcode": "2621",
        "state": "New South Wales"
    },
    {
        "suburb": "Royalla",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Bywong",
        "postcode": "2621",
        "state": "New South Wales"
    },
    {
        "suburb": "Sutton",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Carwoola",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Wamboin",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Primrose Valley",
        "postcode": "2621",
        "state": "New South Wales"
    },
    {
        "suburb": "Jembaicumbene",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Braidwood",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Jinden",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Snowball",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Hereford Hall",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Back Creek",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Mongarlowe",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Monga",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Mulloon",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Farringdon",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Harolds Cross",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Kindervale",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Palerang",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Bombay",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Northangera",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Majors Creek",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Berlang",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyanbene",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerrabattgulla",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Manar",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Warri",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Boro",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Collector",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Currawang",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Bendoura",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayfield",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Marlowe",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Corang",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Wog Wog",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Charleys Forest",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Durran Durra",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Araluen",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Nerriga",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Budawang",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Tomboye",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooks Myalls",
        "postcode": "2870",
        "state": "New South Wales"
    },
    {
        "suburb": "Goonumbla",
        "postcode": "2870",
        "state": "New South Wales"
    },
    {
        "suburb": "Parkes",
        "postcode": "2870",
        "state": "New South Wales"
    },
    {
        "suburb": "Peak Hill",
        "postcode": "2869",
        "state": "New South Wales"
    },
    {
        "suburb": "Trundle",
        "postcode": "2875",
        "state": "New South Wales"
    },
    {
        "suburb": "Bogan Gate",
        "postcode": "2876",
        "state": "New South Wales"
    },
    {
        "suburb": "Nelungaloo",
        "postcode": "2876",
        "state": "New South Wales"
    },
    {
        "suburb": "Bruie Plains",
        "postcode": "2875",
        "state": "New South Wales"
    },
    {
        "suburb": "Gunningbland",
        "postcode": "2876",
        "state": "New South Wales"
    },
    {
        "suburb": "Trewilga",
        "postcode": "2869",
        "state": "New South Wales"
    },
    {
        "suburb": "Harris Park",
        "postcode": "2150",
        "state": "New South Wales"
    },
    {
        "suburb": "Rosehill",
        "postcode": "2142",
        "state": "New South Wales"
    },
    {
        "suburb": "Clyde",
        "postcode": "2142",
        "state": "New South Wales"
    },
    {
        "suburb": "Rydalmere",
        "postcode": "2116",
        "state": "New South Wales"
    },
    {
        "suburb": "Camellia",
        "postcode": "2142",
        "state": "New South Wales"
    },
    {
        "suburb": "Telopea",
        "postcode": "2117",
        "state": "New South Wales"
    },
    {
        "suburb": "Dundas",
        "postcode": "2117",
        "state": "New South Wales"
    },
    {
        "suburb": "Dundas Valley",
        "postcode": "2117",
        "state": "New South Wales"
    },
    {
        "suburb": "Ermington",
        "postcode": "2115",
        "state": "New South Wales"
    },
    {
        "suburb": "Melrose Park",
        "postcode": "2114",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Toongabbie",
        "postcode": "2146",
        "state": "New South Wales"
    },
    {
        "suburb": "Constitution Hill",
        "postcode": "2145",
        "state": "New South Wales"
    },
    {
        "suburb": "South Granville",
        "postcode": "2142",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingswood",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Cambridge Park",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Cambridge Gardens",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Werrington Downs",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Londonderry",
        "postcode": "2753",
        "state": "New South Wales"
    },
    {
        "suburb": "Werrington County",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Werrington",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "St Clair",
        "postcode": "2759",
        "state": "New South Wales"
    },
    {
        "suburb": "St Marys",
        "postcode": "2760",
        "state": "New South Wales"
    },
    {
        "suburb": "North St Marys",
        "postcode": "2760",
        "state": "New South Wales"
    },
    {
        "suburb": "Colyton",
        "postcode": "2760",
        "state": "New South Wales"
    },
    {
        "suburb": "Oxley Park",
        "postcode": "2760",
        "state": "New South Wales"
    },
    {
        "suburb": "Erskine Park",
        "postcode": "2759",
        "state": "New South Wales"
    },
    {
        "suburb": "Mulgoa",
        "postcode": "2745",
        "state": "New South Wales"
    },
    {
        "suburb": "Caddens",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Jordan Springs",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Berkshire Park",
        "postcode": "2765",
        "state": "New South Wales"
    },
    {
        "suburb": "Llandilo",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Claremont Meadows",
        "postcode": "2747",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Vernon",
        "postcode": "2178",
        "state": "New South Wales"
    },
    {
        "suburb": "Orchard Hills",
        "postcode": "2748",
        "state": "New South Wales"
    },
    {
        "suburb": "Emu Heights",
        "postcode": "2750",
        "state": "New South Wales"
    },
    {
        "suburb": "Emu Plains",
        "postcode": "2750",
        "state": "New South Wales"
    },
    {
        "suburb": "Leonay",
        "postcode": "2750",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenmore Park",
        "postcode": "2745",
        "state": "New South Wales"
    },
    {
        "suburb": "Regentville",
        "postcode": "2745",
        "state": "New South Wales"
    },
    {
        "suburb": "Jamisontown",
        "postcode": "2750",
        "state": "New South Wales"
    },
    {
        "suburb": "Penrith",
        "postcode": "2750",
        "state": "New South Wales"
    },
    {
        "suburb": "South Penrith",
        "postcode": "2750",
        "state": "New South Wales"
    },
    {
        "suburb": "Cranebrook",
        "postcode": "2749",
        "state": "New South Wales"
    },
    {
        "suburb": "Castlereagh",
        "postcode": "2749",
        "state": "New South Wales"
    },
    {
        "suburb": "North Narrabeen",
        "postcode": "2101",
        "state": "New South Wales"
    },
    {
        "suburb": "Warriewood",
        "postcode": "2102",
        "state": "New South Wales"
    },
    {
        "suburb": "Mona Vale",
        "postcode": "2103",
        "state": "New South Wales"
    },
    {
        "suburb": "Bayview",
        "postcode": "2104",
        "state": "New South Wales"
    },
    {
        "suburb": "Newport",
        "postcode": "2106",
        "state": "New South Wales"
    },
    {
        "suburb": "Bilgola Plateau",
        "postcode": "2107",
        "state": "New South Wales"
    },
    {
        "suburb": "Avalon Beach",
        "postcode": "2107",
        "state": "New South Wales"
    },
    {
        "suburb": "Whale Beach",
        "postcode": "2107",
        "state": "New South Wales"
    },
    {
        "suburb": "Great Mackerel Beach",
        "postcode": "2108",
        "state": "New South Wales"
    },
    {
        "suburb": "Lovett Bay",
        "postcode": "2105",
        "state": "New South Wales"
    },
    {
        "suburb": "Morning Bay",
        "postcode": "2105",
        "state": "New South Wales"
    },
    {
        "suburb": "Church Point",
        "postcode": "2105",
        "state": "New South Wales"
    },
    {
        "suburb": "Ingleside",
        "postcode": "2101",
        "state": "New South Wales"
    },
    {
        "suburb": "Bilgola Beach",
        "postcode": "2107",
        "state": "New South Wales"
    },
    {
        "suburb": "Palm Beach",
        "postcode": "2108",
        "state": "New South Wales"
    },
    {
        "suburb": "Scotland Island",
        "postcode": "2105",
        "state": "New South Wales"
    },
    {
        "suburb": "Clareville",
        "postcode": "2107",
        "state": "New South Wales"
    },
    {
        "suburb": "Elanora Heights",
        "postcode": "2101",
        "state": "New South Wales"
    },
    {
        "suburb": "Elvina Bay",
        "postcode": "2105",
        "state": "New South Wales"
    },
    {
        "suburb": "Ku-Ring-Gai Chase",
        "postcode": "2084",
        "state": "New South Wales"
    },
    {
        "suburb": "Raymond Terrace",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Medowie",
        "postcode": "2318",
        "state": "New South Wales"
    },
    {
        "suburb": "Port Stephens",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Tanilba Bay",
        "postcode": "2319",
        "state": "New South Wales"
    },
    {
        "suburb": "Tilligerry Creek",
        "postcode": "2319",
        "state": "New South Wales"
    },
    {
        "suburb": "Mallabula",
        "postcode": "2319",
        "state": "New South Wales"
    },
    {
        "suburb": "Lemon Tree Passage",
        "postcode": "2319",
        "state": "New South Wales"
    },
    {
        "suburb": "Soldiers Point",
        "postcode": "2317",
        "state": "New South Wales"
    },
    {
        "suburb": "Salamander Bay",
        "postcode": "2317",
        "state": "New South Wales"
    },
    {
        "suburb": "Anna Bay",
        "postcode": "2316",
        "state": "New South Wales"
    },
    {
        "suburb": "Fishermans Bay",
        "postcode": "2316",
        "state": "New South Wales"
    },
    {
        "suburb": "Corlette",
        "postcode": "2315",
        "state": "New South Wales"
    },
    {
        "suburb": "Boat Harbour",
        "postcode": "2316",
        "state": "New South Wales"
    },
    {
        "suburb": "Nelson Bay",
        "postcode": "2315",
        "state": "New South Wales"
    },
    {
        "suburb": "Fingal Bay",
        "postcode": "2315",
        "state": "New South Wales"
    },
    {
        "suburb": "Shoal Bay",
        "postcode": "2315",
        "state": "New South Wales"
    },
    {
        "suburb": "Taylors Beach",
        "postcode": "2316",
        "state": "New South Wales"
    },
    {
        "suburb": "One Mile",
        "postcode": "2316",
        "state": "New South Wales"
    },
    {
        "suburb": "Bobs Farm",
        "postcode": "2319",
        "state": "New South Wales"
    },
    {
        "suburb": "Salt Ash",
        "postcode": "2318",
        "state": "New South Wales"
    },
    {
        "suburb": "Fullerton Cove",
        "postcode": "2318",
        "state": "New South Wales"
    },
    {
        "suburb": "Williamtown",
        "postcode": "2314",
        "state": "New South Wales"
    },
    {
        "suburb": "Fern Bay",
        "postcode": "2295",
        "state": "New South Wales"
    },
    {
        "suburb": "Tomago",
        "postcode": "2322",
        "state": "New South Wales"
    },
    {
        "suburb": "Heatherbrae",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Nelsons Plains",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Ferodale",
        "postcode": "2318",
        "state": "New South Wales"
    },
    {
        "suburb": "Osterley",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Hinton",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallalong",
        "postcode": "2320",
        "state": "New South Wales"
    },
    {
        "suburb": "Butterwick",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "Seaham",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Brandy Hill",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Duns Creek",
        "postcode": "2321",
        "state": "New South Wales"
    },
    {
        "suburb": "East Seaham",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Eagleton",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Balickera",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Swan Bay",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Campvale",
        "postcode": "2318",
        "state": "New South Wales"
    },
    {
        "suburb": "Twelve Mile Creek",
        "postcode": "2324",
        "state": "New South Wales"
    },
    {
        "suburb": "Queanbeyan West",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Crestwood",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Karabar",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerrabomberra",
        "postcode": "2619",
        "state": "New South Wales"
    },
    {
        "suburb": "Queanbeyan",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Queanbeyan East",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Tralee",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenleigh",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "The Ridgeway",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Kensington",
        "postcode": "2033",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingsford",
        "postcode": "2032",
        "state": "New South Wales"
    },
    {
        "suburb": "Maroubra",
        "postcode": "2035",
        "state": "New South Wales"
    },
    {
        "suburb": "Matraville",
        "postcode": "2036",
        "state": "New South Wales"
    },
    {
        "suburb": "Clovelly",
        "postcode": "2031",
        "state": "New South Wales"
    },
    {
        "suburb": "Phillip Bay",
        "postcode": "2036",
        "state": "New South Wales"
    },
    {
        "suburb": "Randwick",
        "postcode": "2031",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Bay",
        "postcode": "2036",
        "state": "New South Wales"
    },
    {
        "suburb": "Chifley",
        "postcode": "2036",
        "state": "New South Wales"
    },
    {
        "suburb": "Centennial Park",
        "postcode": "2021",
        "state": "New South Wales"
    },
    {
        "suburb": "Malabar",
        "postcode": "2036",
        "state": "New South Wales"
    },
    {
        "suburb": "Coogee",
        "postcode": "2034",
        "state": "New South Wales"
    },
    {
        "suburb": "South Coogee",
        "postcode": "2034",
        "state": "New South Wales"
    },
    {
        "suburb": "La Perouse",
        "postcode": "2036",
        "state": "New South Wales"
    },
    {
        "suburb": "Port Botany",
        "postcode": "2036",
        "state": "New South Wales"
    },
    {
        "suburb": "Casino",
        "postcode": "2470",
        "state": "New South Wales"
    },
    {
        "suburb": "Bexley North",
        "postcode": "2207",
        "state": "New South Wales"
    },
    {
        "suburb": "Bexley",
        "postcode": "2207",
        "state": "New South Wales"
    },
    {
        "suburb": "Bardwell Park",
        "postcode": "2207",
        "state": "New South Wales"
    },
    {
        "suburb": "Arncliffe",
        "postcode": "2205",
        "state": "New South Wales"
    },
    {
        "suburb": "Rockdale",
        "postcode": "2216",
        "state": "New South Wales"
    },
    {
        "suburb": "Bardwell Valley",
        "postcode": "2207",
        "state": "New South Wales"
    },
    {
        "suburb": "Banksia",
        "postcode": "2216",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandringham",
        "postcode": "2219",
        "state": "New South Wales"
    },
    {
        "suburb": "Turrella",
        "postcode": "2205",
        "state": "New South Wales"
    },
    {
        "suburb": "Brighton-le-Sands",
        "postcode": "2216",
        "state": "New South Wales"
    },
    {
        "suburb": "Wolli Creek",
        "postcode": "2205",
        "state": "New South Wales"
    },
    {
        "suburb": "Kyeemagh",
        "postcode": "2216",
        "state": "New South Wales"
    },
    {
        "suburb": "Monterey",
        "postcode": "2217",
        "state": "New South Wales"
    },
    {
        "suburb": "Dolls Point",
        "postcode": "2219",
        "state": "New South Wales"
    },
    {
        "suburb": "Ramsgate Beach",
        "postcode": "2217",
        "state": "New South Wales"
    },
    {
        "suburb": "West Ryde",
        "postcode": "2114",
        "state": "New South Wales"
    },
    {
        "suburb": "Denistone",
        "postcode": "2114",
        "state": "New South Wales"
    },
    {
        "suburb": "Denistone East",
        "postcode": "2112",
        "state": "New South Wales"
    },
    {
        "suburb": "Ryde",
        "postcode": "2112",
        "state": "New South Wales"
    },
    {
        "suburb": "Marsfield",
        "postcode": "2122",
        "state": "New South Wales"
    },
    {
        "suburb": "Putney",
        "postcode": "2112",
        "state": "New South Wales"
    },
    {
        "suburb": "North Ryde",
        "postcode": "2113",
        "state": "New South Wales"
    },
    {
        "suburb": "Tennyson Point",
        "postcode": "2111",
        "state": "New South Wales"
    },
    {
        "suburb": "East Ryde",
        "postcode": "2113",
        "state": "New South Wales"
    },
    {
        "suburb": "Chatswood West",
        "postcode": "2067",
        "state": "New South Wales"
    },
    {
        "suburb": "Macquarie Park",
        "postcode": "2113",
        "state": "New South Wales"
    },
    {
        "suburb": "Denistone West",
        "postcode": "2114",
        "state": "New South Wales"
    },
    {
        "suburb": "Meadowbank",
        "postcode": "2114",
        "state": "New South Wales"
    },
    {
        "suburb": "Blackbutt",
        "postcode": "2529",
        "state": "New South Wales"
    },
    {
        "suburb": "Flinders",
        "postcode": "2529",
        "state": "New South Wales"
    },
    {
        "suburb": "Shellharbour",
        "postcode": "2529",
        "state": "New South Wales"
    },
    {
        "suburb": "Warilla",
        "postcode": "2528",
        "state": "New South Wales"
    },
    {
        "suburb": "Barrack Heights",
        "postcode": "2528",
        "state": "New South Wales"
    },
    {
        "suburb": "Oak Flats",
        "postcode": "2529",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Warrigal",
        "postcode": "2528",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Illawarra",
        "postcode": "2528",
        "state": "New South Wales"
    },
    {
        "suburb": "Albion Park",
        "postcode": "2527",
        "state": "New South Wales"
    },
    {
        "suburb": "Albion Park Rail",
        "postcode": "2527",
        "state": "New South Wales"
    },
    {
        "suburb": "Shell Cove",
        "postcode": "2529",
        "state": "New South Wales"
    },
    {
        "suburb": "Barrack Point",
        "postcode": "2528",
        "state": "New South Wales"
    },
    {
        "suburb": "Shellharbour City Centre",
        "postcode": "2529",
        "state": "New South Wales"
    },
    {
        "suburb": "Dunmore",
        "postcode": "2529",
        "state": "New South Wales"
    },
    {
        "suburb": "North Macquarie",
        "postcode": "2527",
        "state": "New South Wales"
    },
    {
        "suburb": "Tullimbar",
        "postcode": "2527",
        "state": "New South Wales"
    },
    {
        "suburb": "Numbaa",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "South Nowra",
        "postcode": "2541",
        "state": "New South Wales"
    },
    {
        "suburb": "Comberton",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Nowra",
        "postcode": "2541",
        "state": "New South Wales"
    },
    {
        "suburb": "North Nowra",
        "postcode": "2541",
        "state": "New South Wales"
    },
    {
        "suburb": "Watersleigh",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayfield",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Brundee",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Bolong",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Bomaderry",
        "postcode": "2541",
        "state": "New South Wales"
    },
    {
        "suburb": "Longreach",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Terara",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Worrigee",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "West Nowra",
        "postcode": "2541",
        "state": "New South Wales"
    },
    {
        "suburb": "Cambewarra",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Meroo Meadow",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Jaspers Brush",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Nowra Hill",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Tapitallee",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Mundamia",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Bamarang",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Cambewarra Village",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Bangalee",
        "postcode": "2541",
        "state": "New South Wales"
    },
    {
        "suburb": "Yerriyong",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Parma",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Beaumont",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Pyree",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrill Lake",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Erowal Bay",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Callala Bay",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Ulladulla",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Currarong",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Durras North",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Ettrema",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Tallowal",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Kangaroo Valley",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Barrengarry",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Sussex Inlet",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Budgong",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Beecroft Peninsula",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Mollymook Beach",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Orient Point",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Conjola",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Vincentia",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Milton",
        "postcode": "2538",
        "state": "New South Wales"
    },
    {
        "suburb": "Cunjurong Point",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Callala Beach",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Sanctuary Point",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Falls Creek",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Huskisson",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Bendalong",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Woollamia",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Culburra Beach",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Mollymook",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Shoalhaven Heads",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "St Georges Basin",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenwell Point",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Berry",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Cudmirrah",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Manyana",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Tabourie",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Basin View",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Kings Point",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodstock",
        "postcode": "2538",
        "state": "New South Wales"
    },
    {
        "suburb": "Berrara",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Tullarwalla",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollumboola",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Hyams Beach",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellawongarah",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Narrawallee",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Tomerong",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Kioloa",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Bawley Point",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Worrowing Heights",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Erowal Bay",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Wandandian",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Kinghorne",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Broughton Vale",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Far Meadow",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolangatta",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodburn",
        "postcode": "2538",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerrawangala",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Wrights Beach",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Mogood",
        "postcode": "2538",
        "state": "New South Wales"
    },
    {
        "suburb": "Fishermans Paradise",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Touga",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Conjola Park",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Sassafras",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Broughton",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "St George",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Pretty Beach",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Morton",
        "postcode": "2538",
        "state": "New South Wales"
    },
    {
        "suburb": "Yadboro",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Termeil",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Brooman",
        "postcode": "2538",
        "state": "New South Wales"
    },
    {
        "suburb": "Yatte Yattah",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Comerong Island",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Conjola",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Croobyar",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Forest",
        "postcode": "2538",
        "state": "New South Wales"
    },
    {
        "suburb": "Pointer Mountain",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Red Rocks",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Endrick",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Depot Beach",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Pebbly Beach",
        "postcode": "2536",
        "state": "New South Wales"
    },
    {
        "suburb": "Dolphin Point",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Swanhaven",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Tolwong",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolumburra",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulee",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundewallah",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Back Forest",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Bream Beach",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Brogers Creek",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Myola",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Bewong",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Quiera",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Wattamolla",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Berry Mountain",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Browns Mountain",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Berringer Lake",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Yalwal",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Porters Creek",
        "postcode": "2538",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Kingiman",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Boolijah",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Cockwhy",
        "postcode": "2539",
        "state": "New South Wales"
    },
    {
        "suburb": "Twelve Mile Peg",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Tianjara",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Buangla",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Barringella",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrier",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "St Clair",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Olive",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Falbrook",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Singleton Heights",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Singleton",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Dyrring",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Sedgefield",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Mitchells Flat",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Garland Valley",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Howes Valley",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Doyles Creek",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Dural",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Appletree Flat",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Milbrodale",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulga",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Broke",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Fordwich",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Singleton Military Area",
        "postcode": "2331",
        "state": "New South Wales"
    },
    {
        "suburb": "Belford",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Howick",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Liddell",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Ravensworth",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Royal",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Goorangoola",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Mirannie",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Dalwood",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Stanhope",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Carrowbrook",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenlands",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Glennies Creek",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Rixs Creek",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Obanvale",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Camberwell",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Gouldsville",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Long Point",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Lemington",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Reedy Creek",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Westbrook",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Bridgman",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Glendon Brook",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Leconfield",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Elderslie",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Clydesdale",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Big Ridge",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Roughit",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Scotts Flat",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Glendon",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Maison Dieu",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Gowrie",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenridding",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Hambledon Hill",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Wylies Flat",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Thorley",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Belford",
        "postcode": "2335",
        "state": "New South Wales"
    },
    {
        "suburb": "Whittingham",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Redbournberry",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Dunolly",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Darlington",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "McDougalls Hill",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Hunterview",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Fern Gully",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Wattle Ponds",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Middle Falbrook",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Jindabyne",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "Beloka",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Braemar Bay",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Buckenderra",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Frying Pan",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Numbla Vale",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Cootralantra",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Berridale",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Rhine Falls",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Arable",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Eucumbene",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Rocky Plain",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Adaminaby",
        "postcode": "2629",
        "state": "New South Wales"
    },
    {
        "suburb": "Bobundara",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Wambrook",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "The Brothers",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Myalla",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Paupong",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Ironmungy",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Pine Valley",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Grosses Plain",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "Snowy Plain",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Moonbah",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "Nimmo",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Kosciuszko National Park",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolringdon",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Kalkite",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "East Jindabyne",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "Middlingbank",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Old Adaminaby",
        "postcode": "2629",
        "state": "New South Wales"
    },
    {
        "suburb": "Dry Plain",
        "postcode": "2630",
        "state": "New South Wales"
    },
    {
        "suburb": "Ingebirah",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "Dalgety",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Anglers Reach",
        "postcode": "2629",
        "state": "New South Wales"
    },
    {
        "suburb": "Avonside",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Jimenbuen",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Crackenback",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "Homebush West",
        "postcode": "2140",
        "state": "New South Wales"
    },
    {
        "suburb": "Strathfield South",
        "postcode": "2136",
        "state": "New South Wales"
    },
    {
        "suburb": "Homebush",
        "postcode": "2140",
        "state": "New South Wales"
    },
    {
        "suburb": "Sylvania Waters",
        "postcode": "2224",
        "state": "New South Wales"
    },
    {
        "suburb": "Caringbah South",
        "postcode": "2229",
        "state": "New South Wales"
    },
    {
        "suburb": "Burraneer",
        "postcode": "2230",
        "state": "New South Wales"
    },
    {
        "suburb": "Cronulla",
        "postcode": "2230",
        "state": "New South Wales"
    },
    {
        "suburb": "Taren Point",
        "postcode": "2229",
        "state": "New South Wales"
    },
    {
        "suburb": "Caringbah",
        "postcode": "2229",
        "state": "New South Wales"
    },
    {
        "suburb": "Lilli Pilli",
        "postcode": "2229",
        "state": "New South Wales"
    },
    {
        "suburb": "Dolans Bay",
        "postcode": "2229",
        "state": "New South Wales"
    },
    {
        "suburb": "Kurnell",
        "postcode": "2231",
        "state": "New South Wales"
    },
    {
        "suburb": "Gymea Bay",
        "postcode": "2227",
        "state": "New South Wales"
    },
    {
        "suburb": "Gymea",
        "postcode": "2227",
        "state": "New South Wales"
    },
    {
        "suburb": "Miranda",
        "postcode": "2228",
        "state": "New South Wales"
    },
    {
        "suburb": "Woolooware",
        "postcode": "2230",
        "state": "New South Wales"
    },
    {
        "suburb": "Port Hacking",
        "postcode": "2229",
        "state": "New South Wales"
    },
    {
        "suburb": "Sylvania",
        "postcode": "2224",
        "state": "New South Wales"
    },
    {
        "suburb": "Yowie Bay",
        "postcode": "2228",
        "state": "New South Wales"
    },
    {
        "suburb": "Maianbar",
        "postcode": "2230",
        "state": "New South Wales"
    },
    {
        "suburb": "Kirrawee",
        "postcode": "2232",
        "state": "New South Wales"
    },
    {
        "suburb": "Royal National Park",
        "postcode": "2233",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundeena",
        "postcode": "2230",
        "state": "New South Wales"
    },
    {
        "suburb": "Kangaroo Point",
        "postcode": "2224",
        "state": "New South Wales"
    },
    {
        "suburb": "Kareela",
        "postcode": "2232",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenhills Beach",
        "postcode": "2230",
        "state": "New South Wales"
    },
    {
        "suburb": "Grays Point",
        "postcode": "2232",
        "state": "New South Wales"
    },
    {
        "suburb": "Bonnet Bay",
        "postcode": "2226",
        "state": "New South Wales"
    },
    {
        "suburb": "Woronora",
        "postcode": "2232",
        "state": "New South Wales"
    },
    {
        "suburb": "Sutherland",
        "postcode": "2232",
        "state": "New South Wales"
    },
    {
        "suburb": "Jannali",
        "postcode": "2226",
        "state": "New South Wales"
    },
    {
        "suburb": "Loftus",
        "postcode": "2232",
        "state": "New South Wales"
    },
    {
        "suburb": "Como",
        "postcode": "2226",
        "state": "New South Wales"
    },
    {
        "suburb": "Bangor",
        "postcode": "2234",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrawarrah",
        "postcode": "2233",
        "state": "New South Wales"
    },
    {
        "suburb": "Illawong",
        "postcode": "2234",
        "state": "New South Wales"
    },
    {
        "suburb": "Heathcote",
        "postcode": "2233",
        "state": "New South Wales"
    },
    {
        "suburb": "Menai",
        "postcode": "2234",
        "state": "New South Wales"
    },
    {
        "suburb": "Engadine",
        "postcode": "2233",
        "state": "New South Wales"
    },
    {
        "suburb": "Woronora Heights",
        "postcode": "2233",
        "state": "New South Wales"
    },
    {
        "suburb": "Waterfall",
        "postcode": "2233",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandy Point",
        "postcode": "2172",
        "state": "New South Wales"
    },
    {
        "suburb": "Barden Ridge",
        "postcode": "2234",
        "state": "New South Wales"
    },
    {
        "suburb": "Oyster Bay",
        "postcode": "2225",
        "state": "New South Wales"
    },
    {
        "suburb": "Lucas Heights",
        "postcode": "2234",
        "state": "New South Wales"
    },
    {
        "suburb": "Alfords Point",
        "postcode": "2234",
        "state": "New South Wales"
    },
    {
        "suburb": "Sydney",
        "postcode": "2000",
        "state": "New South Wales"
    },
    {
        "suburb": "Haymarket",
        "postcode": "2000",
        "state": "New South Wales"
    },
    {
        "suburb": "Darlinghurst",
        "postcode": "2010",
        "state": "New South Wales"
    },
    {
        "suburb": "Dawes Point",
        "postcode": "2000",
        "state": "New South Wales"
    },
    {
        "suburb": "Millers Point",
        "postcode": "2000",
        "state": "New South Wales"
    },
    {
        "suburb": "The Rocks",
        "postcode": "2000",
        "state": "New South Wales"
    },
    {
        "suburb": "Potts Point",
        "postcode": "2011",
        "state": "New South Wales"
    },
    {
        "suburb": "Surry Hills",
        "postcode": "2010",
        "state": "New South Wales"
    },
    {
        "suburb": "Woolloomooloo",
        "postcode": "2011",
        "state": "New South Wales"
    },
    {
        "suburb": "Rushcutters Bay",
        "postcode": "2011",
        "state": "New South Wales"
    },
    {
        "suburb": "Elizabeth Bay",
        "postcode": "2011",
        "state": "New South Wales"
    },
    {
        "suburb": "Moore Park",
        "postcode": "2021",
        "state": "New South Wales"
    },
    {
        "suburb": "Paddington",
        "postcode": "2021",
        "state": "New South Wales"
    },
    {
        "suburb": "Darlington",
        "postcode": "2008",
        "state": "New South Wales"
    },
    {
        "suburb": "Redfern",
        "postcode": "2016",
        "state": "New South Wales"
    },
    {
        "suburb": "Waterloo",
        "postcode": "2017",
        "state": "New South Wales"
    },
    {
        "suburb": "Erskineville",
        "postcode": "2043",
        "state": "New South Wales"
    },
    {
        "suburb": "Zetland",
        "postcode": "2017",
        "state": "New South Wales"
    },
    {
        "suburb": "Alexandria",
        "postcode": "2015",
        "state": "New South Wales"
    },
    {
        "suburb": "Beaconsfield",
        "postcode": "2015",
        "state": "New South Wales"
    },
    {
        "suburb": "Eveleigh",
        "postcode": "2015",
        "state": "New South Wales"
    },
    {
        "suburb": "Glebe",
        "postcode": "2037",
        "state": "New South Wales"
    },
    {
        "suburb": "Ultimo",
        "postcode": "2007",
        "state": "New South Wales"
    },
    {
        "suburb": "Chippendale",
        "postcode": "2008",
        "state": "New South Wales"
    },
    {
        "suburb": "Forest Lodge",
        "postcode": "2037",
        "state": "New South Wales"
    },
    {
        "suburb": "Pyrmont",
        "postcode": "2009",
        "state": "New South Wales"
    },
    {
        "suburb": "West Tamworth",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Hillvue",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Oxley Vale",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "South Tamworth",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "North Tamworth",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Tamworth",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "East Tamworth",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Calala",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Kootingal",
        "postcode": "2352",
        "state": "New South Wales"
    },
    {
        "suburb": "Moonbi",
        "postcode": "2353",
        "state": "New South Wales"
    },
    {
        "suburb": "Nemingha",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Moore Creek",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Tintinhull",
        "postcode": "2352",
        "state": "New South Wales"
    },
    {
        "suburb": "Taminda",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Westdale",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingswood",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Warral",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Daruka",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Attunga",
        "postcode": "2345",
        "state": "New South Wales"
    },
    {
        "suburb": "Hallsville",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "New Mexico",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Manilla",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Timbumburi",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Garoo",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Watsons Creek",
        "postcode": "2355",
        "state": "New South Wales"
    },
    {
        "suburb": "Thirldene",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Ogunbil",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Wongo Creek",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Mulla Creek",
        "postcode": "2352",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayvale",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Manilla",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodsreef",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrabah",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Retreat",
        "postcode": "2355",
        "state": "New South Wales"
    },
    {
        "suburb": "Bendemeer",
        "postcode": "2355",
        "state": "New South Wales"
    },
    {
        "suburb": "Limbri",
        "postcode": "2352",
        "state": "New South Wales"
    },
    {
        "suburb": "Garthowen",
        "postcode": "2345",
        "state": "New South Wales"
    },
    {
        "suburb": "Namoi River",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Wimborne",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Halls Creek",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowling Alley Point",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Klori",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Rushes Creek",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Somerton",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Bective",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallamore",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Gidley",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Winton",
        "postcode": "2344",
        "state": "New South Wales"
    },
    {
        "suburb": "Duri",
        "postcode": "2344",
        "state": "New South Wales"
    },
    {
        "suburb": "Goonoo Goonoo",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Loomberah",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Hanging Rock",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Woolomin",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Crawney",
        "postcode": "2338",
        "state": "New South Wales"
    },
    {
        "suburb": "Ironbark",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Banoon",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Red Hill",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Longarm",
        "postcode": "2347",
        "state": "New South Wales"
    },
    {
        "suburb": "Barry",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Duncans Creek",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Niangala",
        "postcode": "2354",
        "state": "New South Wales"
    },
    {
        "suburb": "Woolbrook",
        "postcode": "2354",
        "state": "New South Wales"
    },
    {
        "suburb": "Kentucky",
        "postcode": "2354",
        "state": "New South Wales"
    },
    {
        "suburb": "Bithramere",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Temora",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Combaning",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Tara",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Pucawan",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Mimosa",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Reefton",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Springdale",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Narraburra",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Quandary",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Sebastopol",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Grogan",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Trungley Hall",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Gidginbung",
        "postcode": "2666",
        "state": "New South Wales"
    },
    {
        "suburb": "Tenterfield",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Maryland",
        "postcode": "4377",
        "state": "New South Wales"
    },
    {
        "suburb": "Legume",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Drake",
        "postcode": "2469",
        "state": "New South Wales"
    },
    {
        "suburb": "Back Creek",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarban",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Bookookoorara",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Tooloom",
        "postcode": "2475",
        "state": "New South Wales"
    },
    {
        "suburb": "Koreelah",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Cottonvale",
        "postcode": "4375",
        "state": "New South Wales"
    },
    {
        "suburb": "Acacia Creek",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Cullendore",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Acacia Creek",
        "postcode": "2476",
        "state": "New South Wales"
    },
    {
        "suburb": "Wylie Creek",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Liston",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Ruby Creek",
        "postcode": "4380",
        "state": "New South Wales"
    },
    {
        "suburb": "Amosfield",
        "postcode": "4380",
        "state": "New South Wales"
    },
    {
        "suburb": "Boonoo Boonoo",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Undercliffe",
        "postcode": "4380",
        "state": "New South Wales"
    },
    {
        "suburb": "Boorook",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandy Hill",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Rocky River",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Timbarra",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Rivertree",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodside",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Jennings",
        "postcode": "4383",
        "state": "New South Wales"
    },
    {
        "suburb": "Dumaresq Valley",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Forest Land",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandy Flat",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Mingoola",
        "postcode": "4380",
        "state": "New South Wales"
    },
    {
        "suburb": "Mole River",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Bolivia",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Silent Grove",
        "postcode": "2372",
        "state": "New South Wales"
    },
    {
        "suburb": "Buddong",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Willigobung",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Tumbarumba",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Hills",
        "postcode": "2730",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenroy",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Geehi",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Bringenbrong",
        "postcode": "3707",
        "state": "New South Wales"
    },
    {
        "suburb": "Pilot Wilderness",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "Jagungal Wilderness",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Cabramurra",
        "postcode": "2629",
        "state": "New South Wales"
    },
    {
        "suburb": "Jagumba",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Nurenmerenmong",
        "postcode": "2649",
        "state": "New South Wales"
    },
    {
        "suburb": "Burra",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Greg Greg",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Khancoban",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Indi",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Tooma",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Ournie",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Munderoo",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Paddys River",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Courabyra",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Maragle",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Laurel Hill",
        "postcode": "2649",
        "state": "New South Wales"
    },
    {
        "suburb": "Westdale",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Taradale",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Mannus",
        "postcode": "2653",
        "state": "New South Wales"
    },
    {
        "suburb": "Gilmore",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Tumut",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Lacmalac",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Tumorrama",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Califat",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Wereboldera",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Minjary",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Batlow",
        "postcode": "2730",
        "state": "New South Wales"
    },
    {
        "suburb": "Gocup",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Goobarragandra",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Blowering",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Tumut Plains",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Little River",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Jones Bridge",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandy Gully",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Grahamstown",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Black Creek",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Adrah",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Kunama",
        "postcode": "2730",
        "state": "New South Wales"
    },
    {
        "suburb": "Long Plain",
        "postcode": "2629",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooleman",
        "postcode": "2611",
        "state": "New South Wales"
    },
    {
        "suburb": "Sharps Creek",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Gadara",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Wondalga",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Talbingo",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Couragago",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrangobilly",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Brindabella",
        "postcode": "2611",
        "state": "New South Wales"
    },
    {
        "suburb": "Uriarra",
        "postcode": "2611",
        "state": "New South Wales"
    },
    {
        "suburb": "Adelong",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Horeb",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Killimicat",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Red Hill",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Westwood",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Darlow",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Lower Bago",
        "postcode": "2730",
        "state": "New South Wales"
    },
    {
        "suburb": "Wermatong",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Pinbeyan",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Bangadang",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Bogong Peaks Wilderness",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyangle",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Argalong",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Mundongo",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Cooleys Creek",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Brungle",
        "postcode": "2722",
        "state": "New South Wales"
    },
    {
        "suburb": "Bombowlee Creek",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Carool",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Bilambil Heights",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Terranora",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Tweed Heads West",
        "postcode": "2485",
        "state": "New South Wales"
    },
    {
        "suburb": "Banora Point",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Tweed Heads South",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Tweed Heads",
        "postcode": "2485",
        "state": "New South Wales"
    },
    {
        "suburb": "Cudgen",
        "postcode": "2487",
        "state": "New South Wales"
    },
    {
        "suburb": "Fingal Head",
        "postcode": "2487",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingscliff",
        "postcode": "2487",
        "state": "New South Wales"
    },
    {
        "suburb": "Chinderah",
        "postcode": "2487",
        "state": "New South Wales"
    },
    {
        "suburb": "Piggabeen",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Bilambil",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Casuarina",
        "postcode": "2487",
        "state": "New South Wales"
    },
    {
        "suburb": "Cobaki",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "North Tumbulgum",
        "postcode": "2490",
        "state": "New South Wales"
    },
    {
        "suburb": "Cobaki Lakes",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Sleepy Hollow",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Cudgera Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Pottsville",
        "postcode": "2489",
        "state": "New South Wales"
    },
    {
        "suburb": "Bogangar",
        "postcode": "2488",
        "state": "New South Wales"
    },
    {
        "suburb": "Clothiers Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Hastings Point",
        "postcode": "2489",
        "state": "New South Wales"
    },
    {
        "suburb": "Crabbes Creek",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Kings Forest",
        "postcode": "2487",
        "state": "New South Wales"
    },
    {
        "suburb": "Tanglewood",
        "postcode": "2488",
        "state": "New South Wales"
    },
    {
        "suburb": "Cabarita Beach",
        "postcode": "2488",
        "state": "New South Wales"
    },
    {
        "suburb": "Round Mountain",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Duranbah",
        "postcode": "2487",
        "state": "New South Wales"
    },
    {
        "suburb": "Mooball",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Chillingham",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Murwillumbah",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "South Murwillumbah",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Duroby",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Wardrop Valley",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Duroby",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Tumbulgum",
        "postcode": "2490",
        "state": "New South Wales"
    },
    {
        "suburb": "Terragon",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Doon Doon",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Rowlands Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Bungalora",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Byangum",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Tomewin",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Chowan Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Smiths Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Palmvale",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Back Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Mebbin",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Warning",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Hopkins Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Kunghur",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Cedar Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Zara",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Commissioners Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Glengarrie",
        "postcode": "2486",
        "state": "New South Wales"
    },
    {
        "suburb": "Stokers Siding",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Numinbah",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Stotts Creek",
        "postcode": "2487",
        "state": "New South Wales"
    },
    {
        "suburb": "Kunghur Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Uki",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Condong",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Bray Park",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Kynnumboon",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Tyalgum",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Pumpenbil",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Limpinwood",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Nobbys Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Burringbar",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Kielvale",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Tygalgah",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Fernvale",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Nunderi",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Farrants Hill",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Dunbible",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Urliup",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Eviron",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Tyalgum Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Burrell",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Crystal Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Dungay",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Dulguigan",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "North Arm",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Brays Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Dum Dum",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Reserve Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Eungella",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Crystal Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Rouchel",
        "postcode": "2336",
        "state": "New South Wales"
    },
    {
        "suburb": "Cassilis",
        "postcode": "2329",
        "state": "New South Wales"
    },
    {
        "suburb": "Brawboy",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Scone",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Aberdeen",
        "postcode": "2336",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandy Creek",
        "postcode": "2338",
        "state": "New South Wales"
    },
    {
        "suburb": "Scotts Creek",
        "postcode": "2338",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Rouchel",
        "postcode": "2336",
        "state": "New South Wales"
    },
    {
        "suburb": "Rossgole",
        "postcode": "2336",
        "state": "New South Wales"
    },
    {
        "suburb": "Gungal",
        "postcode": "2333",
        "state": "New South Wales"
    },
    {
        "suburb": "Merriwa",
        "postcode": "2329",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenbawn",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Wingen",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Waverly",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Dry Creek",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Pages Creek",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Dartbrook",
        "postcode": "2336",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenrock",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Omadale",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrurundi",
        "postcode": "2338",
        "state": "New South Wales"
    },
    {
        "suburb": "Timor",
        "postcode": "2338",
        "state": "New South Wales"
    },
    {
        "suburb": "Green Creek",
        "postcode": "2338",
        "state": "New South Wales"
    },
    {
        "suburb": "Segenhoe",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Gundy",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Woolooma",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Belltrees",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Kars Springs",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Middle Brook",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Moonan Flat",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Dartbrook",
        "postcode": "2336",
        "state": "New South Wales"
    },
    {
        "suburb": "Parkville",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Bunnan",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Moobi",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Davis Creek",
        "postcode": "2336",
        "state": "New South Wales"
    },
    {
        "suburb": "Owens Gap",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Moonan Brook",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Pages River",
        "postcode": "2338",
        "state": "New South Wales"
    },
    {
        "suburb": "Murulla",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Stewarts Brook",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Limerick",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Crookwell",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Roslyn",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Gurrundah",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Lerida",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Taralga",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Bannister",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Wheeo",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Dalton",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Grabben Gullen",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Laggan",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Binda",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Wombeyan Caves",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Fullerton",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuena",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Bigga",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Peelwood",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Crooked Corner",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Golspie",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Yalbraith",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Curraweela",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Bannaby",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Stonequarry",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Richlands",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Myrtleville",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Lost River",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Narrawa",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Bevendale",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Blakney Creek",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Broadway",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Jerrawa",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Oolong",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Lade Vale",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Gunning",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Merrill",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Biala",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Cullerin",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Pejar",
        "postcode": "2583",
        "state": "New South Wales"
    },
    {
        "suburb": "Chatsbury",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Big Hill",
        "postcode": "2579",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellmount Forest",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Uralla",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Kentucky South",
        "postcode": "2354",
        "state": "New South Wales"
    },
    {
        "suburb": "Wollun",
        "postcode": "2354",
        "state": "New South Wales"
    },
    {
        "suburb": "Salisbury Plains",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Torryburn",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Camerons Creek",
        "postcode": "2359",
        "state": "New South Wales"
    },
    {
        "suburb": "Abington",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingstown",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrowyck",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Saumarez Ponds",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Invergowrie",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Saumarez",
        "postcode": "2350",
        "state": "New South Wales"
    },
    {
        "suburb": "Arding",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Rocky River",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Balala",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Gostwyck",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Mihi",
        "postcode": "2358",
        "state": "New South Wales"
    },
    {
        "suburb": "Ashmont",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenfield Park",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Tolland",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Austin",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Bourkelands",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Wagga Wagga",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Turvey Park",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Albert",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Kooringal",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "North Wagga Wagga",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "East Wagga Wagga",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Forest Hill",
        "postcode": "2651",
        "state": "New South Wales"
    },
    {
        "suburb": "Gregadoo",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Uranquinty",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Lloyd",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Estella",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarragundry",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Moorong",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Tatton",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Gumly Gumly",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Kapooka",
        "postcode": "2661",
        "state": "New South Wales"
    },
    {
        "suburb": "Springvale",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "San Isidore",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Charles Sturt University",
        "postcode": "2678",
        "state": "New South Wales"
    },
    {
        "suburb": "Cartwrights Hill",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Bomen",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Rowan",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Collingullie",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Belfrayden",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Gelston Park",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Currawarna",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Maxwell",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Big Springs",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Pulletop",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Alfredtown",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallacetown",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Ladysmith",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Mangoplah",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulgary",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "The Gap",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Brucedale",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Borambola",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarcutta",
        "postcode": "2652",
        "state": "New South Wales"
    },
    {
        "suburb": "Dhulura",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Kyeamba",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Oura",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Euberta",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Brookdale",
        "postcode": "2656",
        "state": "New South Wales"
    },
    {
        "suburb": "Eunanoreenya",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Downside",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Gobbagombalin",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Hillgrove",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrandana",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Tooleybuc",
        "postcode": "2736",
        "state": "New South Wales"
    },
    {
        "suburb": "Moolpa",
        "postcode": "2734",
        "state": "New South Wales"
    },
    {
        "suburb": "Mellool",
        "postcode": "2734",
        "state": "New South Wales"
    },
    {
        "suburb": "Waugorah",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Wakool",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Stony Crossing",
        "postcode": "2734",
        "state": "New South Wales"
    },
    {
        "suburb": "Yanga",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Mallan",
        "postcode": "2734",
        "state": "New South Wales"
    },
    {
        "suburb": "Barham",
        "postcode": "2732",
        "state": "New South Wales"
    },
    {
        "suburb": "Koraleigh",
        "postcode": "2735",
        "state": "New South Wales"
    },
    {
        "suburb": "Gonn",
        "postcode": "2732",
        "state": "New South Wales"
    },
    {
        "suburb": "Tullakool",
        "postcode": "2732",
        "state": "New South Wales"
    },
    {
        "suburb": "Goodnight",
        "postcode": "2736",
        "state": "New South Wales"
    },
    {
        "suburb": "Keri Keri",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Murray Downs",
        "postcode": "2734",
        "state": "New South Wales"
    },
    {
        "suburb": "Speewa",
        "postcode": "2735",
        "state": "New South Wales"
    },
    {
        "suburb": "Cunninyeuk",
        "postcode": "2734",
        "state": "New South Wales"
    },
    {
        "suburb": "Noorong",
        "postcode": "2732",
        "state": "New South Wales"
    },
    {
        "suburb": "Dhuragoon",
        "postcode": "2733",
        "state": "New South Wales"
    },
    {
        "suburb": "Dilpurra",
        "postcode": "2734",
        "state": "New South Wales"
    },
    {
        "suburb": "Cobramunga",
        "postcode": "2732",
        "state": "New South Wales"
    },
    {
        "suburb": "Burraboi",
        "postcode": "2732",
        "state": "New South Wales"
    },
    {
        "suburb": "Wetuppa",
        "postcode": "2734",
        "state": "New South Wales"
    },
    {
        "suburb": "Tooranie",
        "postcode": "2734",
        "state": "New South Wales"
    },
    {
        "suburb": "Niemur",
        "postcode": "2733",
        "state": "New South Wales"
    },
    {
        "suburb": "Walcha",
        "postcode": "2354",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarrowitch",
        "postcode": "2354",
        "state": "New South Wales"
    },
    {
        "suburb": "Nowendoc",
        "postcode": "2354",
        "state": "New South Wales"
    },
    {
        "suburb": "Lightning Ridge",
        "postcode": "2834",
        "state": "New South Wales"
    },
    {
        "suburb": "Burren Junction",
        "postcode": "2386",
        "state": "New South Wales"
    },
    {
        "suburb": "Cumborah",
        "postcode": "2832",
        "state": "New South Wales"
    },
    {
        "suburb": "Come By Chance",
        "postcode": "2832",
        "state": "New South Wales"
    },
    {
        "suburb": "Walgett",
        "postcode": "2832",
        "state": "New South Wales"
    },
    {
        "suburb": "Cryon",
        "postcode": "2832",
        "state": "New South Wales"
    },
    {
        "suburb": "Warren",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Ravenswood",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Tenandra",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Bogan",
        "postcode": "2826",
        "state": "New South Wales"
    },
    {
        "suburb": "Oxley",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Nevertire",
        "postcode": "2826",
        "state": "New South Wales"
    },
    {
        "suburb": "Beemunnel",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Harris",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Foster",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Bullagreen",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Pine Clump",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Pigeonbah",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Marthaguy",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Eenaweena",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Mumblebone Plain",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Mullengudgery",
        "postcode": "2825",
        "state": "New South Wales"
    },
    {
        "suburb": "Red Hill",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Snakes Plain",
        "postcode": "2824",
        "state": "New South Wales"
    },
    {
        "suburb": "Cathundral",
        "postcode": "2823",
        "state": "New South Wales"
    },
    {
        "suburb": "Belrose",
        "postcode": "2085",
        "state": "New South Wales"
    },
    {
        "suburb": "Collaroy Plateau",
        "postcode": "2097",
        "state": "New South Wales"
    },
    {
        "suburb": "North Manly",
        "postcode": "2100",
        "state": "New South Wales"
    },
    {
        "suburb": "Narraweena",
        "postcode": "2099",
        "state": "New South Wales"
    },
    {
        "suburb": "Narrabeen",
        "postcode": "2101",
        "state": "New South Wales"
    },
    {
        "suburb": "Cromer",
        "postcode": "2099",
        "state": "New South Wales"
    },
    {
        "suburb": "Beacon Hill",
        "postcode": "2100",
        "state": "New South Wales"
    },
    {
        "suburb": "Curl Curl",
        "postcode": "2096",
        "state": "New South Wales"
    },
    {
        "suburb": "Brookvale",
        "postcode": "2100",
        "state": "New South Wales"
    },
    {
        "suburb": "North Curl Curl",
        "postcode": "2099",
        "state": "New South Wales"
    },
    {
        "suburb": "Dee Why",
        "postcode": "2099",
        "state": "New South Wales"
    },
    {
        "suburb": "Frenchs Forest",
        "postcode": "2086",
        "state": "New South Wales"
    },
    {
        "suburb": "Freshwater",
        "postcode": "2096",
        "state": "New South Wales"
    },
    {
        "suburb": "Collaroy",
        "postcode": "2097",
        "state": "New South Wales"
    },
    {
        "suburb": "Wheeler Heights",
        "postcode": "2097",
        "state": "New South Wales"
    },
    {
        "suburb": "Manly Vale",
        "postcode": "2093",
        "state": "New South Wales"
    },
    {
        "suburb": "Oxford Falls",
        "postcode": "2100",
        "state": "New South Wales"
    },
    {
        "suburb": "Killarney Heights",
        "postcode": "2087",
        "state": "New South Wales"
    },
    {
        "suburb": "Terrey Hills",
        "postcode": "2084",
        "state": "New South Wales"
    },
    {
        "suburb": "Davidson",
        "postcode": "2085",
        "state": "New South Wales"
    },
    {
        "suburb": "Forestville",
        "postcode": "2087",
        "state": "New South Wales"
    },
    {
        "suburb": "Allambie Heights",
        "postcode": "2100",
        "state": "New South Wales"
    },
    {
        "suburb": "North Balgowlah",
        "postcode": "2093",
        "state": "New South Wales"
    },
    {
        "suburb": "Cottage Point",
        "postcode": "2084",
        "state": "New South Wales"
    },
    {
        "suburb": "Queenscliff",
        "postcode": "2096",
        "state": "New South Wales"
    },
    {
        "suburb": "Duffys Forest",
        "postcode": "2084",
        "state": "New South Wales"
    },
    {
        "suburb": "Coonabarabran",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Dunedoo",
        "postcode": "2844",
        "state": "New South Wales"
    },
    {
        "suburb": "Ropers Road",
        "postcode": "2395",
        "state": "New South Wales"
    },
    {
        "suburb": "Weetaliba",
        "postcode": "2395",
        "state": "New South Wales"
    },
    {
        "suburb": "Coolah",
        "postcode": "2843",
        "state": "New South Wales"
    },
    {
        "suburb": "Bomera",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Tannabar",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Kenebri",
        "postcode": "2396",
        "state": "New South Wales"
    },
    {
        "suburb": "Baradine",
        "postcode": "2396",
        "state": "New South Wales"
    },
    {
        "suburb": "Bugaldie",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Goorianawa",
        "postcode": "2396",
        "state": "New South Wales"
    },
    {
        "suburb": "Dandry",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Rocky Glen",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Binnaway",
        "postcode": "2395",
        "state": "New South Wales"
    },
    {
        "suburb": "Merrygoen",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Uarbry",
        "postcode": "2329",
        "state": "New South Wales"
    },
    {
        "suburb": "Leadville",
        "postcode": "2844",
        "state": "New South Wales"
    },
    {
        "suburb": "Wattle Springs",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Gowang",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Mollyan",
        "postcode": "2842",
        "state": "New South Wales"
    },
    {
        "suburb": "Neilrex",
        "postcode": "2831",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarragrin",
        "postcode": "2842",
        "state": "New South Wales"
    },
    {
        "suburb": "Box Ridge",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Barwon",
        "postcode": "2396",
        "state": "New South Wales"
    },
    {
        "suburb": "Nombi",
        "postcode": "2379",
        "state": "New South Wales"
    },
    {
        "suburb": "Napier Lane",
        "postcode": "2379",
        "state": "New South Wales"
    },
    {
        "suburb": "Purlewaugh",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "North Bondi",
        "postcode": "2026",
        "state": "New South Wales"
    },
    {
        "suburb": "Vaucluse",
        "postcode": "2030",
        "state": "New South Wales"
    },
    {
        "suburb": "Waverley",
        "postcode": "2024",
        "state": "New South Wales"
    },
    {
        "suburb": "Rose Bay",
        "postcode": "2029",
        "state": "New South Wales"
    },
    {
        "suburb": "Bondi Junction",
        "postcode": "2022",
        "state": "New South Wales"
    },
    {
        "suburb": "Bondi Beach",
        "postcode": "2026",
        "state": "New South Wales"
    },
    {
        "suburb": "Dover Heights",
        "postcode": "2030",
        "state": "New South Wales"
    },
    {
        "suburb": "Tamarama",
        "postcode": "2026",
        "state": "New South Wales"
    },
    {
        "suburb": "Bronte",
        "postcode": "2024",
        "state": "New South Wales"
    },
    {
        "suburb": "Bondi",
        "postcode": "2026",
        "state": "New South Wales"
    },
    {
        "suburb": "Queens Park",
        "postcode": "2022",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenelg",
        "postcode": "2810",
        "state": "New South Wales"
    },
    {
        "suburb": "Grenfell",
        "postcode": "2810",
        "state": "New South Wales"
    },
    {
        "suburb": "Caragabal",
        "postcode": "2810",
        "state": "New South Wales"
    },
    {
        "suburb": "Bribbaree",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Bimbi",
        "postcode": "2810",
        "state": "New South Wales"
    },
    {
        "suburb": "Pinnacle",
        "postcode": "2810",
        "state": "New South Wales"
    },
    {
        "suburb": "Pullabooka",
        "postcode": "2810",
        "state": "New South Wales"
    },
    {
        "suburb": "Warraderry",
        "postcode": "2810",
        "state": "New South Wales"
    },
    {
        "suburb": "Piney Range",
        "postcode": "2810",
        "state": "New South Wales"
    },
    {
        "suburb": "Suntop",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Curra Creek",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Gollan",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Montefiores",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Wellington",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Farnham",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "North Yeoval",
        "postcode": "2868",
        "state": "New South Wales"
    },
    {
        "suburb": "Ponto",
        "postcode": "2818",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarragal",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Arthurville",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Cundumbul",
        "postcode": "2866",
        "state": "New South Wales"
    },
    {
        "suburb": "Comobella",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Geurie",
        "postcode": "2818",
        "state": "New South Wales"
    },
    {
        "suburb": "Mookerawa",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Aquila",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Spicers Creek",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Terrabella",
        "postcode": "2818",
        "state": "New South Wales"
    },
    {
        "suburb": "Walmer",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Wuuluman",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Bodangora",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Arthur",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Stuart Town",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Mumbil",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Burrendong",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Dripstone",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Maryvale",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Neurea",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Bakers Swamp",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Euchareena",
        "postcode": "2866",
        "state": "New South Wales"
    },
    {
        "suburb": "Bournewood",
        "postcode": "2868",
        "state": "New South Wales"
    },
    {
        "suburb": "Rufus",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Scotia",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Wentworth",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Curlwaa",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Pooncarie",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Anabranch North",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Anabranch South",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Pan Ban",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Mallee",
        "postcode": "2738",
        "state": "New South Wales"
    },
    {
        "suburb": "Monak",
        "postcode": "2738",
        "state": "New South Wales"
    },
    {
        "suburb": "Coomealla",
        "postcode": "2717",
        "state": "New South Wales"
    },
    {
        "suburb": "Dareton",
        "postcode": "2717",
        "state": "New South Wales"
    },
    {
        "suburb": "Buronga",
        "postcode": "2739",
        "state": "New South Wales"
    },
    {
        "suburb": "Gol Gol",
        "postcode": "2738",
        "state": "New South Wales"
    },
    {
        "suburb": "Trentham Cliffs",
        "postcode": "2738",
        "state": "New South Wales"
    },
    {
        "suburb": "Chatswood",
        "postcode": "2067",
        "state": "New South Wales"
    },
    {
        "suburb": "Artarmon",
        "postcode": "2064",
        "state": "New South Wales"
    },
    {
        "suburb": "Willoughby",
        "postcode": "2068",
        "state": "New South Wales"
    },
    {
        "suburb": "Naremburn",
        "postcode": "2065",
        "state": "New South Wales"
    },
    {
        "suburb": "North Willoughby",
        "postcode": "2068",
        "state": "New South Wales"
    },
    {
        "suburb": "Northbridge",
        "postcode": "2063",
        "state": "New South Wales"
    },
    {
        "suburb": "Castlecrag",
        "postcode": "2068",
        "state": "New South Wales"
    },
    {
        "suburb": "Castle Cove",
        "postcode": "2069",
        "state": "New South Wales"
    },
    {
        "suburb": "Middle Cove",
        "postcode": "2068",
        "state": "New South Wales"
    },
    {
        "suburb": "Willoughby East",
        "postcode": "2068",
        "state": "New South Wales"
    },
    {
        "suburb": "New Berrima",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Moss Vale",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Burradoo",
        "postcode": "2576",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowral",
        "postcode": "2576",
        "state": "New South Wales"
    },
    {
        "suburb": "Welby",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Mittagong",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Willow Vale",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Colo Vale",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Werai",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Wingello",
        "postcode": "2579",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Murray",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Avon",
        "postcode": "2574",
        "state": "New South Wales"
    },
    {
        "suburb": "Bargo",
        "postcode": "2574",
        "state": "New South Wales"
    },
    {
        "suburb": "Buxton",
        "postcode": "2571",
        "state": "New South Wales"
    },
    {
        "suburb": "High Range",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodlands",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Yerrinbool",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Lindsey",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Meryla",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Wattle Ridge",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenquarry",
        "postcode": "2576",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrawang",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Kangaloon",
        "postcode": "2576",
        "state": "New South Wales"
    },
    {
        "suburb": "Sutton Forest",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Penrose",
        "postcode": "2579",
        "state": "New South Wales"
    },
    {
        "suburb": "Wildes Meadow",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Robertson",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Fitzroy Falls",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "East Kangaloon",
        "postcode": "2576",
        "state": "New South Wales"
    },
    {
        "suburb": "Balmoral",
        "postcode": "2571",
        "state": "New South Wales"
    },
    {
        "suburb": "Hill Top",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Bullio",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Canyonleigh",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Joadja",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Bundanoon",
        "postcode": "2578",
        "state": "New South Wales"
    },
    {
        "suburb": "Exeter",
        "postcode": "2579",
        "state": "New South Wales"
    },
    {
        "suburb": "Berrima",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Medway",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Aylmerton",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Avoca",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Belanglo",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Braemar",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Balaclava",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Renwick",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Mandemar",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Manchester Square",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Orangeville",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Silverdale",
        "postcode": "2752",
        "state": "New South Wales"
    },
    {
        "suburb": "Pheasants Nest",
        "postcode": "2574",
        "state": "New South Wales"
    },
    {
        "suburb": "Yerranderie",
        "postcode": "2787",
        "state": "New South Wales"
    },
    {
        "suburb": "The Oaks",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Appin",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Lakesland",
        "postcode": "2572",
        "state": "New South Wales"
    },
    {
        "suburb": "Brownlow Hill",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Camden Park",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Theresa Park",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Warragamba",
        "postcode": "2752",
        "state": "New South Wales"
    },
    {
        "suburb": "Tahmoor",
        "postcode": "2573",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Hunter",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Menangle",
        "postcode": "2568",
        "state": "New South Wales"
    },
    {
        "suburb": "Nattai",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Picton",
        "postcode": "2571",
        "state": "New South Wales"
    },
    {
        "suburb": "Oakdale",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Mowbray Park",
        "postcode": "2571",
        "state": "New South Wales"
    },
    {
        "suburb": "Wilton",
        "postcode": "2571",
        "state": "New South Wales"
    },
    {
        "suburb": "Thirlmere",
        "postcode": "2572",
        "state": "New South Wales"
    },
    {
        "suburb": "Douglas Park",
        "postcode": "2569",
        "state": "New South Wales"
    },
    {
        "suburb": "Werombi",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Yanderra",
        "postcode": "2574",
        "state": "New South Wales"
    },
    {
        "suburb": "Couridjah",
        "postcode": "2571",
        "state": "New South Wales"
    },
    {
        "suburb": "Razorback",
        "postcode": "2571",
        "state": "New South Wales"
    },
    {
        "suburb": "Belimbla Park",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Cataract",
        "postcode": "2560",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenmore",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Maldon",
        "postcode": "2571",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Pleasant",
        "postcode": "2519",
        "state": "New South Wales"
    },
    {
        "suburb": "Keiraville",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "Gwynneville",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "Tarrawanna",
        "postcode": "2518",
        "state": "New South Wales"
    },
    {
        "suburb": "Fernhill",
        "postcode": "2519",
        "state": "New South Wales"
    },
    {
        "suburb": "Corrimal",
        "postcode": "2518",
        "state": "New South Wales"
    },
    {
        "suburb": "Austinmer",
        "postcode": "2515",
        "state": "New South Wales"
    },
    {
        "suburb": "Thirroul",
        "postcode": "2515",
        "state": "New South Wales"
    },
    {
        "suburb": "Russell Vale",
        "postcode": "2517",
        "state": "New South Wales"
    },
    {
        "suburb": "Woonona",
        "postcode": "2517",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellambi",
        "postcode": "2518",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulli",
        "postcode": "2516",
        "state": "New South Wales"
    },
    {
        "suburb": "Coledale",
        "postcode": "2515",
        "state": "New South Wales"
    },
    {
        "suburb": "Wombarra",
        "postcode": "2515",
        "state": "New South Wales"
    },
    {
        "suburb": "Scarborough",
        "postcode": "2515",
        "state": "New South Wales"
    },
    {
        "suburb": "Clifton",
        "postcode": "2515",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Ousley",
        "postcode": "2519",
        "state": "New South Wales"
    },
    {
        "suburb": "Figtree",
        "postcode": "2525",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Hill",
        "postcode": "2500",
        "state": "New South Wales"
    },
    {
        "suburb": "Horsley",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Avondale",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Cordeaux",
        "postcode": "2526",
        "state": "New South Wales"
    },
    {
        "suburb": "Dapto",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Kanahooka",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Koonawarra",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Farmborough Heights",
        "postcode": "2526",
        "state": "New South Wales"
    },
    {
        "suburb": "Cordeaux Heights",
        "postcode": "2526",
        "state": "New South Wales"
    },
    {
        "suburb": "Unanderra",
        "postcode": "2526",
        "state": "New South Wales"
    },
    {
        "suburb": "Berkeley",
        "postcode": "2506",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Heights",
        "postcode": "2502",
        "state": "New South Wales"
    },
    {
        "suburb": "Windang",
        "postcode": "2528",
        "state": "New South Wales"
    },
    {
        "suburb": "Cringila",
        "postcode": "2502",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrawong",
        "postcode": "2502",
        "state": "New South Wales"
    },
    {
        "suburb": "Helensburgh",
        "postcode": "2508",
        "state": "New South Wales"
    },
    {
        "suburb": "Otford",
        "postcode": "2508",
        "state": "New South Wales"
    },
    {
        "suburb": "Brownsville",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Stanwell Park",
        "postcode": "2508",
        "state": "New South Wales"
    },
    {
        "suburb": "Kembla Grange",
        "postcode": "2526",
        "state": "New South Wales"
    },
    {
        "suburb": "Primbee",
        "postcode": "2502",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Kembla",
        "postcode": "2526",
        "state": "New South Wales"
    },
    {
        "suburb": "Wongawilli",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Huntley",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Dombarton",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Kembla Heights",
        "postcode": "2526",
        "state": "New South Wales"
    },
    {
        "suburb": "Coalcliff",
        "postcode": "2508",
        "state": "New South Wales"
    },
    {
        "suburb": "Darkes Forest",
        "postcode": "2508",
        "state": "New South Wales"
    },
    {
        "suburb": "Stanwell Tops",
        "postcode": "2508",
        "state": "New South Wales"
    },
    {
        "suburb": "Lilyvale",
        "postcode": "2508",
        "state": "New South Wales"
    },
    {
        "suburb": "Haywards Bay",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Yallah",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Maddens Plains",
        "postcode": "2508",
        "state": "New South Wales"
    },
    {
        "suburb": "Cleveland",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Darling Point",
        "postcode": "2027",
        "state": "New South Wales"
    },
    {
        "suburb": "Woollahra",
        "postcode": "2025",
        "state": "New South Wales"
    },
    {
        "suburb": "Bellevue Hill",
        "postcode": "2023",
        "state": "New South Wales"
    },
    {
        "suburb": "Double Bay",
        "postcode": "2028",
        "state": "New South Wales"
    },
    {
        "suburb": "Point Piper",
        "postcode": "2027",
        "state": "New South Wales"
    },
    {
        "suburb": "Edgecliff",
        "postcode": "2027",
        "state": "New South Wales"
    },
    {
        "suburb": "Watsons Bay",
        "postcode": "2030",
        "state": "New South Wales"
    },
    {
        "suburb": "Wadalba",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Kanwal",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyongah",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Blue Haven",
        "postcode": "2262",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Haven",
        "postcode": "2263",
        "state": "New South Wales"
    },
    {
        "suburb": "Gorokan",
        "postcode": "2263",
        "state": "New South Wales"
    },
    {
        "suburb": "Charmhaven",
        "postcode": "2263",
        "state": "New South Wales"
    },
    {
        "suburb": "San Remo",
        "postcode": "2262",
        "state": "New South Wales"
    },
    {
        "suburb": "Buff Point",
        "postcode": "2262",
        "state": "New South Wales"
    },
    {
        "suburb": "Toukley",
        "postcode": "2263",
        "state": "New South Wales"
    },
    {
        "suburb": "Mannering Park",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Colongra",
        "postcode": "2262",
        "state": "New South Wales"
    },
    {
        "suburb": "Canton Beach",
        "postcode": "2263",
        "state": "New South Wales"
    },
    {
        "suburb": "Budgewoi",
        "postcode": "2262",
        "state": "New South Wales"
    },
    {
        "suburb": "Noraville",
        "postcode": "2263",
        "state": "New South Wales"
    },
    {
        "suburb": "Halekulani",
        "postcode": "2262",
        "state": "New South Wales"
    },
    {
        "suburb": "Summerland Point",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Norah Head",
        "postcode": "2263",
        "state": "New South Wales"
    },
    {
        "suburb": "Chain Valley Bay",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Munmorah",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Gwandalan",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyong",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Durren Durren",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Kingfisher Shores",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Doyalson North",
        "postcode": "2262",
        "state": "New South Wales"
    },
    {
        "suburb": "Doyalson",
        "postcode": "2262",
        "state": "New South Wales"
    },
    {
        "suburb": "Point Wolstoncroft",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Crangan Bay",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Moonee",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Hamlyn Terrace",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Woongarrah",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Warnervale",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallarah",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Halloran",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Bushells Ridge",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Kiar",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Alison",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Jilliby",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Freemans",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Frazer Park",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Wybung",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Dooralong",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Mardi",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuggerah",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Watanobbi",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Berkeley Vale",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Tumbi Umbi",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Chittaway Bay",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Killarney Vale",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Bateau Bay",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Long Jetty",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Shelly Beach",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "The Entrance",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Blue Bay",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "The Entrance North",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Magenta",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Tuggerawong",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Ourimbah",
        "postcode": "2258",
        "state": "New South Wales"
    },
    {
        "suburb": "Toowoon Bay",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenning Valley",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Chittaway Point",
        "postcode": "2261",
        "state": "New South Wales"
    },
    {
        "suburb": "Fountaindale",
        "postcode": "2258",
        "state": "New South Wales"
    },
    {
        "suburb": "Tacoma South",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Tacoma",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Rocky Point",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Kangy Angy",
        "postcode": "2258",
        "state": "New South Wales"
    },
    {
        "suburb": "Palm Grove",
        "postcode": "2258",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Jilliby",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Wyong Creek",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Yarramalong",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Ravensdale",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Cedar Brush Creek",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Lemon Tree",
        "postcode": "2259",
        "state": "New South Wales"
    },
    {
        "suburb": "Palmdale",
        "postcode": "2258",
        "state": "New South Wales"
    },
    {
        "suburb": "Wallaroo",
        "postcode": "2618",
        "state": "New South Wales"
    },
    {
        "suburb": "Binalong",
        "postcode": "2584",
        "state": "New South Wales"
    },
    {
        "suburb": "Yass",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrumbateman",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Nanima",
        "postcode": "2618",
        "state": "New South Wales"
    },
    {
        "suburb": "Kangiara",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Wee Jasper",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Bowning",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Bookham",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrinjuck",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Narrangullen",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Good Hope",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Boambolo",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Cavan",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Gundaroo",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Manton",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Bango",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Springrange",
        "postcode": "2618",
        "state": "New South Wales"
    },
    {
        "suburb": "Yass River",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Woolgarlo",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Marchmont",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Jeir",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Mullion",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Young",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Bulla Creek",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Wirrimah",
        "postcode": "2803",
        "state": "New South Wales"
    },
    {
        "suburb": "Crowther",
        "postcode": "2803",
        "state": "New South Wales"
    },
    {
        "suburb": "Burrangong",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Murringo",
        "postcode": "2586",
        "state": "New South Wales"
    },
    {
        "suburb": "Bendick Murrell",
        "postcode": "2803",
        "state": "New South Wales"
    },
    {
        "suburb": "Monteagle",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Kikiamah",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Thuddungra",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Maimuru",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Tubbul",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Weedallion",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Memagong",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Berthong",
        "postcode": "2594",
        "state": "New South Wales"
    },
    {
        "suburb": "Milparinka",
        "postcode": "2880",
        "state": "New South Wales"
    },
    {
        "suburb": "Silverton",
        "postcode": "2880",
        "state": "New South Wales"
    },
    {
        "suburb": "Broughams Gate",
        "postcode": "2880",
        "state": "New South Wales"
    },
    {
        "suburb": "Packsaddle",
        "postcode": "2880",
        "state": "New South Wales"
    },
    {
        "suburb": "Mutawintji",
        "postcode": "2880",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Topar",
        "postcode": "2880",
        "state": "New South Wales"
    },
    {
        "suburb": "Tibooburra",
        "postcode": "2880",
        "state": "New South Wales"
    },
    {
        "suburb": "White Cliffs",
        "postcode": "2836",
        "state": "New South Wales"
    },
    {
        "suburb": "Lord Howe Island",
        "postcode": "2898",
        "state": "New South Wales"
    },
    {
        "suburb": "Winburndale",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Moollattoo",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Mondayong",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Calderwood",
        "postcode": "2527",
        "state": "New South Wales"
    },
    {
        "suburb": "Budgewoi Peninsula",
        "postcode": "2262",
        "state": "New South Wales"
    },
    {
        "suburb": "Greengrove",
        "postcode": "2250",
        "state": "New South Wales"
    },
    {
        "suburb": "Little Wobby",
        "postcode": "2256",
        "state": "New South Wales"
    },
    {
        "suburb": "Calamia",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Warrumbungle",
        "postcode": "2828",
        "state": "New South Wales"
    },
    {
        "suburb": "Ryhope",
        "postcode": "2283",
        "state": "New South Wales"
    },
    {
        "suburb": "Havilah",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Riverlea",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Mullamuddy",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Coxs Creek",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Bylong",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "The Ponds",
        "postcode": "2769",
        "state": "New South Wales"
    },
    {
        "suburb": "Sunset Strip",
        "postcode": "2879",
        "state": "New South Wales"
    },
    {
        "suburb": "Carnes Hill",
        "postcode": "2171",
        "state": "New South Wales"
    },
    {
        "suburb": "New Park",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Tichborne",
        "postcode": "2870",
        "state": "New South Wales"
    },
    {
        "suburb": "Elizabeth Hills",
        "postcode": "2171",
        "state": "New South Wales"
    },
    {
        "suburb": "Lavadia",
        "postcode": "2462",
        "state": "New South Wales"
    },
    {
        "suburb": "Wambool",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Oyster Cove",
        "postcode": "2318",
        "state": "New South Wales"
    },
    {
        "suburb": "Bardia",
        "postcode": "2565",
        "state": "New South Wales"
    },
    {
        "suburb": "Boorooma",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Gobarralong",
        "postcode": "2727",
        "state": "New South Wales"
    },
    {
        "suburb": "Barkers Vale",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "North Bourke",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Alectown",
        "postcode": "2870",
        "state": "New South Wales"
    },
    {
        "suburb": "Spring Farm",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Nundle",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Barangaroo",
        "postcode": "2000",
        "state": "New South Wales"
    },
    {
        "suburb": "Euabalong",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Walcha Road",
        "postcode": "2354",
        "state": "New South Wales"
    },
    {
        "suburb": "Ellerslie",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Euabalong West",
        "postcode": "2877",
        "state": "New South Wales"
    },
    {
        "suburb": "Para",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Moorara",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Boeill Creek",
        "postcode": "2739",
        "state": "New South Wales"
    },
    {
        "suburb": "Oran Park",
        "postcode": "2570",
        "state": "New South Wales"
    },
    {
        "suburb": "Illaroo",
        "postcode": "2540",
        "state": "New South Wales"
    },
    {
        "suburb": "Yambulla",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Brookong",
        "postcode": "2656",
        "state": "New South Wales"
    },
    {
        "suburb": "Tongarra",
        "postcode": "2527",
        "state": "New South Wales"
    },
    {
        "suburb": "Perthville",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Evans Plains",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Tannas Mount",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Hill Top",
        "postcode": "2628",
        "state": "New South Wales"
    },
    {
        "suburb": "Bocobra",
        "postcode": "2865",
        "state": "New South Wales"
    },
    {
        "suburb": "Wadeville",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Lyndhurst",
        "postcode": "2797",
        "state": "New South Wales"
    },
    {
        "suburb": "Pomona",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Piallamore",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Macquarie Pass",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Marshall Mount",
        "postcode": "2530",
        "state": "New South Wales"
    },
    {
        "suburb": "Rollands Plains",
        "postcode": "2441",
        "state": "New South Wales"
    },
    {
        "suburb": "Killawarra",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Greenethorpe",
        "postcode": "2809",
        "state": "New South Wales"
    },
    {
        "suburb": "Thyra",
        "postcode": "2731",
        "state": "New South Wales"
    },
    {
        "suburb": "Apsley",
        "postcode": "2820",
        "state": "New South Wales"
    },
    {
        "suburb": "Dyers Crossing",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Ellerslie",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Mogareeka",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Burringbar",
        "postcode": "2483",
        "state": "New South Wales"
    },
    {
        "suburb": "Bedgerabong",
        "postcode": "2871",
        "state": "New South Wales"
    },
    {
        "suburb": "Hill End",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Hume Village",
        "postcode": "3691",
        "state": "New South Wales"
    },
    {
        "suburb": "Midginbil",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Reidsdale",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "James Creek",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Gloucester Tops",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Weilmoringle",
        "postcode": "2839",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrays Beach",
        "postcode": "2281",
        "state": "New South Wales"
    },
    {
        "suburb": "Bobin",
        "postcode": "2429",
        "state": "New South Wales"
    },
    {
        "suburb": "Faulkland",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Booerie Creek",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Edgeroi",
        "postcode": "2390",
        "state": "New South Wales"
    },
    {
        "suburb": "Booyong",
        "postcode": "2480",
        "state": "New South Wales"
    },
    {
        "suburb": "Barratta",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Ellerston",
        "postcode": "2337",
        "state": "New South Wales"
    },
    {
        "suburb": "Cornwallis",
        "postcode": "2756",
        "state": "New South Wales"
    },
    {
        "suburb": "Richmond Lowlands",
        "postcode": "2753",
        "state": "New South Wales"
    },
    {
        "suburb": "Hobbys Yards",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Rookhurst",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Dungowan",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Krawarree",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Mondrook",
        "postcode": "2430",
        "state": "New South Wales"
    },
    {
        "suburb": "Mount Fairy",
        "postcode": "2580",
        "state": "New South Wales"
    },
    {
        "suburb": "Windera",
        "postcode": "2800",
        "state": "New South Wales"
    },
    {
        "suburb": "Limekilns",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Gledswood Hills",
        "postcode": "2557",
        "state": "New South Wales"
    },
    {
        "suburb": "Dungeree",
        "postcode": "2849",
        "state": "New South Wales"
    },
    {
        "suburb": "Yaven Creek",
        "postcode": "2729",
        "state": "New South Wales"
    },
    {
        "suburb": "Myall Park",
        "postcode": "2681",
        "state": "New South Wales"
    },
    {
        "suburb": "Bombowlee",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Bimberi",
        "postcode": "2611",
        "state": "New South Wales"
    },
    {
        "suburb": "Len Waters Estate",
        "postcode": "2171",
        "state": "New South Wales"
    },
    {
        "suburb": "Alpine",
        "postcode": "2575",
        "state": "New South Wales"
    },
    {
        "suburb": "Hartwood",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Mourquong",
        "postcode": "2739",
        "state": "New South Wales"
    },
    {
        "suburb": "Morago",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Sandy Creek",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Coopers Gully",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Fowlers Gap",
        "postcode": "2880",
        "state": "New South Wales"
    },
    {
        "suburb": "Lake Brewster",
        "postcode": "2675",
        "state": "New South Wales"
    },
    {
        "suburb": "Murray Gorge",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Kosciuszko",
        "postcode": "2627",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Nevis",
        "postcode": "2365",
        "state": "New South Wales"
    },
    {
        "suburb": "Baryulgil",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Eden Creek",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Fawcetts Plain",
        "postcode": "2474",
        "state": "New South Wales"
    },
    {
        "suburb": "Wandera",
        "postcode": "2360",
        "state": "New South Wales"
    },
    {
        "suburb": "Byrrill Creek",
        "postcode": "2484",
        "state": "New South Wales"
    },
    {
        "suburb": "Burnt Bridge",
        "postcode": "2440",
        "state": "New South Wales"
    },
    {
        "suburb": "Coasters Retreat",
        "postcode": "2108",
        "state": "New South Wales"
    },
    {
        "suburb": "Ulamambri",
        "postcode": "2357",
        "state": "New South Wales"
    },
    {
        "suburb": "Doctor George Mountain",
        "postcode": "2550",
        "state": "New South Wales"
    },
    {
        "suburb": "Ettamogah",
        "postcode": "2640",
        "state": "New South Wales"
    },
    {
        "suburb": "Appleby",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Yantabulla",
        "postcode": "2840",
        "state": "New South Wales"
    },
    {
        "suburb": "Windowie",
        "postcode": "2720",
        "state": "New South Wales"
    },
    {
        "suburb": "Brungle Creek",
        "postcode": "2722",
        "state": "New South Wales"
    },
    {
        "suburb": "Woodhill",
        "postcode": "2535",
        "state": "New South Wales"
    },
    {
        "suburb": "Pretty Pine",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Nyora",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Willurah",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Paringi",
        "postcode": "2738",
        "state": "New South Wales"
    },
    {
        "suburb": "Mayrung",
        "postcode": "2710",
        "state": "New South Wales"
    },
    {
        "suburb": "Gilgunnia",
        "postcode": "2835",
        "state": "New South Wales"
    },
    {
        "suburb": "Pine Camp",
        "postcode": "2648",
        "state": "New South Wales"
    },
    {
        "suburb": "Murrin Bridge",
        "postcode": "2672",
        "state": "New South Wales"
    },
    {
        "suburb": "Gilmandyke",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Ballalaba",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Canadian Lead",
        "postcode": "2850",
        "state": "New South Wales"
    },
    {
        "suburb": "Gunbar",
        "postcode": "2711",
        "state": "New South Wales"
    },
    {
        "suburb": "Glen Ward",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Dalmorton",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Redlands",
        "postcode": "2646",
        "state": "New South Wales"
    },
    {
        "suburb": "Oberne Creek",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Book Book",
        "postcode": "2650",
        "state": "New South Wales"
    },
    {
        "suburb": "Paddys River",
        "postcode": "2577",
        "state": "New South Wales"
    },
    {
        "suburb": "Bakers Creek",
        "postcode": "2359",
        "state": "New South Wales"
    },
    {
        "suburb": "Bectric",
        "postcode": "2665",
        "state": "New South Wales"
    },
    {
        "suburb": "Weabonga",
        "postcode": "2340",
        "state": "New South Wales"
    },
    {
        "suburb": "Borah Creek",
        "postcode": "2346",
        "state": "New South Wales"
    },
    {
        "suburb": "Taloumbi",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Shark Creek",
        "postcode": "2463",
        "state": "New South Wales"
    },
    {
        "suburb": "Larbert",
        "postcode": "2622",
        "state": "New South Wales"
    },
    {
        "suburb": "Breadalbane",
        "postcode": "2581",
        "state": "New South Wales"
    },
    {
        "suburb": "Warkworth",
        "postcode": "2330",
        "state": "New South Wales"
    },
    {
        "suburb": "Moppy",
        "postcode": "2422",
        "state": "New South Wales"
    },
    {
        "suburb": "Moorilda",
        "postcode": "2795",
        "state": "New South Wales"
    },
    {
        "suburb": "Wingadee",
        "postcode": "2829",
        "state": "New South Wales"
    },
    {
        "suburb": "Laverstock",
        "postcode": "2582",
        "state": "New South Wales"
    },
    {
        "suburb": "Punchbowl",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Bolaro",
        "postcode": "2629",
        "state": "New South Wales"
    },
    {
        "suburb": "Welaregang",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Upper Bingara",
        "postcode": "2404",
        "state": "New South Wales"
    },
    {
        "suburb": "Southampton",
        "postcode": "2460",
        "state": "New South Wales"
    },
    {
        "suburb": "Environa",
        "postcode": "2620",
        "state": "New South Wales"
    },
    {
        "suburb": "Glenellen",
        "postcode": "2642",
        "state": "New South Wales"
    },
    {
        "suburb": "Tawonga",
        "postcode": "3697",
        "state": "Victoria"
    },
    {
        "suburb": "Bright",
        "postcode": "3741",
        "state": "Victoria"
    },
    {
        "suburb": "Tawonga South",
        "postcode": "3698",
        "state": "Victoria"
    },
    {
        "suburb": "Porepunkah",
        "postcode": "3740",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Beauty",
        "postcode": "3699",
        "state": "Victoria"
    },
    {
        "suburb": "Glen Creek",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Harrietville",
        "postcode": "3741",
        "state": "Victoria"
    },
    {
        "suburb": "Falls Creek",
        "postcode": "3699",
        "state": "Victoria"
    },
    {
        "suburb": "Dederang",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Kergunyah South",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Gundowring",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Upper Gundowring",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Smoko",
        "postcode": "3741",
        "state": "Victoria"
    },
    {
        "suburb": "Hotham Heights",
        "postcode": "3741",
        "state": "Victoria"
    },
    {
        "suburb": "Wongungarra",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Wonnangatta",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Dargo",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Cobungra",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Selwyn",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Dinner Plain",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Buffalo",
        "postcode": "3740",
        "state": "Victoria"
    },
    {
        "suburb": "Coral Bank",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Germantown",
        "postcode": "3741",
        "state": "Victoria"
    },
    {
        "suburb": "Buckland",
        "postcode": "3740",
        "state": "Victoria"
    },
    {
        "suburb": "Wandiligong",
        "postcode": "3744",
        "state": "Victoria"
    },
    {
        "suburb": "Mudgegonga",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Kancoona",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Running Creek",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Freeburgh",
        "postcode": "3741",
        "state": "Victoria"
    },
    {
        "suburb": "Eurobin",
        "postcode": "3739",
        "state": "Victoria"
    },
    {
        "suburb": "Mongans Bridge",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Myrtleford",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Buffalo River",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Barwidgee",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Gapsted",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Ovens",
        "postcode": "3738",
        "state": "Victoria"
    },
    {
        "suburb": "Havilah",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Rosewhite",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Nug Nug",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Merriang South",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Merriang",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Abbeyard",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Dandongadale",
        "postcode": "3737",
        "state": "Victoria"
    },
    {
        "suburb": "Ararat",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Rossbridge",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Mininera",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Nerrin Nerrin",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Bolac",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Streatham",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Bornes Hill",
        "postcode": "3379",
        "state": "Victoria"
    },
    {
        "suburb": "Willaura",
        "postcode": "3379",
        "state": "Victoria"
    },
    {
        "suburb": "Moyston",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Elmhurst",
        "postcode": "3469",
        "state": "Victoria"
    },
    {
        "suburb": "Pomonal",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Black Range",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Westmere",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Wickliffe",
        "postcode": "3379",
        "state": "Victoria"
    },
    {
        "suburb": "Willaura North",
        "postcode": "3379",
        "state": "Victoria"
    },
    {
        "suburb": "Cathcart",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Rocky Point",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Rhymney",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Great Western",
        "postcode": "3374",
        "state": "Victoria"
    },
    {
        "suburb": "Norval",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Armstrong",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Narrapumelap South",
        "postcode": "3293",
        "state": "Victoria"
    },
    {
        "suburb": "Chatsworth",
        "postcode": "3379",
        "state": "Victoria"
    },
    {
        "suburb": "Mafeking",
        "postcode": "3379",
        "state": "Victoria"
    },
    {
        "suburb": "Stavely",
        "postcode": "3379",
        "state": "Victoria"
    },
    {
        "suburb": "Glenthompson",
        "postcode": "3293",
        "state": "Victoria"
    },
    {
        "suburb": "Dunkeld",
        "postcode": "3379",
        "state": "Victoria"
    },
    {
        "suburb": "Warrak",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Cole",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Tatyoon",
        "postcode": "3378",
        "state": "Victoria"
    },
    {
        "suburb": "Glenlogie",
        "postcode": "3469",
        "state": "Victoria"
    },
    {
        "suburb": "Woorndoo",
        "postcode": "3272",
        "state": "Victoria"
    },
    {
        "suburb": "Buangor",
        "postcode": "3375",
        "state": "Victoria"
    },
    {
        "suburb": "Middle Creek",
        "postcode": "3375",
        "state": "Victoria"
    },
    {
        "suburb": "Pura Pura",
        "postcode": "3271",
        "state": "Victoria"
    },
    {
        "suburb": "Yalla-Y-Poora",
        "postcode": "3378",
        "state": "Victoria"
    },
    {
        "suburb": "Denicull Creek",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Ballyrogan",
        "postcode": "3375",
        "state": "Victoria"
    },
    {
        "suburb": "Dobie",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Langi Logan",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Dunneworthy",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Cole Creek",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Eversley",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Bayindeen",
        "postcode": "3375",
        "state": "Victoria"
    },
    {
        "suburb": "Crowlands",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Maroona",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Soldiers Hill",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Ballarat North",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Wendouree",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Ballarat Central",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Black Hill",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Brown Hill",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Newington",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Eureka",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Ballarat East",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Alfredton",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Bakery Hill",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Pleasant",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Redan",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Canadian",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Golden Point",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Delacombe",
        "postcode": "3356",
        "state": "Victoria"
    },
    {
        "suburb": "Nerrina",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Glen Park",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Wendouree",
        "postcode": "3355",
        "state": "Victoria"
    },
    {
        "suburb": "Burrumbeet",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Smythes Creek",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Gardens",
        "postcode": "3355",
        "state": "Victoria"
    },
    {
        "suburb": "Invermay Park",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Blowhard",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Mitchell Park",
        "postcode": "3355",
        "state": "Victoria"
    },
    {
        "suburb": "Miners Rest",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Warrenheip",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Bald Hills",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Cardigan Village",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Rowan",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Sulky",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Windermere",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Invermay",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Gong Gong",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Wattle Flat",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Bo Peep",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Bunkers Hill",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Cardigan",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Learmonth",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Bolton",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Waubra",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Tourello",
        "postcode": "3363",
        "state": "Victoria"
    },
    {
        "suburb": "Glendonald",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Creswick",
        "postcode": "3363",
        "state": "Victoria"
    },
    {
        "suburb": "Ascot",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Glendaruel",
        "postcode": "3363",
        "state": "Victoria"
    },
    {
        "suburb": "Coghills Creek",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Ercildoune",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Addington",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Weatherboard",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Clear",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Buninyong",
        "postcode": "3357",
        "state": "Victoria"
    },
    {
        "suburb": "Sebastopol",
        "postcode": "3356",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Helen",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Magpie",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Scotchmans Lead",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Durham Lead",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Scotsburn",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Bonshaw",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Ivanhoe",
        "postcode": "3079",
        "state": "Victoria"
    },
    {
        "suburb": "Viewbank",
        "postcode": "3084",
        "state": "Victoria"
    },
    {
        "suburb": "Yallambie",
        "postcode": "3085",
        "state": "Victoria"
    },
    {
        "suburb": "Bellfield",
        "postcode": "3081",
        "state": "Victoria"
    },
    {
        "suburb": "Heidelberg West",
        "postcode": "3081",
        "state": "Victoria"
    },
    {
        "suburb": "Ivanhoe East",
        "postcode": "3079",
        "state": "Victoria"
    },
    {
        "suburb": "Heidelberg",
        "postcode": "3084",
        "state": "Victoria"
    },
    {
        "suburb": "Rosanna",
        "postcode": "3084",
        "state": "Victoria"
    },
    {
        "suburb": "Eaglemont",
        "postcode": "3084",
        "state": "Victoria"
    },
    {
        "suburb": "Heidelberg Heights",
        "postcode": "3081",
        "state": "Victoria"
    },
    {
        "suburb": "Macleod",
        "postcode": "3085",
        "state": "Victoria"
    },
    {
        "suburb": "Greensborough",
        "postcode": "3088",
        "state": "Victoria"
    },
    {
        "suburb": "Watsonia North",
        "postcode": "3087",
        "state": "Victoria"
    },
    {
        "suburb": "Bundoora",
        "postcode": "3083",
        "state": "Victoria"
    },
    {
        "suburb": "Watsonia",
        "postcode": "3087",
        "state": "Victoria"
    },
    {
        "suburb": "St Helena",
        "postcode": "3088",
        "state": "Victoria"
    },
    {
        "suburb": "Montmorency",
        "postcode": "3094",
        "state": "Victoria"
    },
    {
        "suburb": "Lower Plenty",
        "postcode": "3093",
        "state": "Victoria"
    },
    {
        "suburb": "Briar Hill",
        "postcode": "3088",
        "state": "Victoria"
    },
    {
        "suburb": "Eltham North",
        "postcode": "3095",
        "state": "Victoria"
    },
    {
        "suburb": "Eltham",
        "postcode": "3095",
        "state": "Victoria"
    },
    {
        "suburb": "Cowes",
        "postcode": "3922",
        "state": "Victoria"
    },
    {
        "suburb": "Silverleaves",
        "postcode": "3922",
        "state": "Victoria"
    },
    {
        "suburb": "Rhyll",
        "postcode": "3923",
        "state": "Victoria"
    },
    {
        "suburb": "Smiths Beach",
        "postcode": "3922",
        "state": "Victoria"
    },
    {
        "suburb": "Sunset Strip",
        "postcode": "3922",
        "state": "Victoria"
    },
    {
        "suburb": "Sunderland Bay",
        "postcode": "3922",
        "state": "Victoria"
    },
    {
        "suburb": "Surf Beach",
        "postcode": "3922",
        "state": "Victoria"
    },
    {
        "suburb": "Cape Woolamai",
        "postcode": "3925",
        "state": "Victoria"
    },
    {
        "suburb": "Newhaven",
        "postcode": "3925",
        "state": "Victoria"
    },
    {
        "suburb": "Summerlands",
        "postcode": "3922",
        "state": "Victoria"
    },
    {
        "suburb": "Ventnor",
        "postcode": "3922",
        "state": "Victoria"
    },
    {
        "suburb": "Wimbledon Heights",
        "postcode": "3922",
        "state": "Victoria"
    },
    {
        "suburb": "Churchill Island",
        "postcode": "3925",
        "state": "Victoria"
    },
    {
        "suburb": "Kilcunda",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Dalyston",
        "postcode": "3992",
        "state": "Victoria"
    },
    {
        "suburb": "Wonthaggi",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "The Gurdies",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "North Wonthaggi",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Inverloch",
        "postcode": "3996",
        "state": "Victoria"
    },
    {
        "suburb": "Ryanston",
        "postcode": "3992",
        "state": "Victoria"
    },
    {
        "suburb": "Almurta",
        "postcode": "3979",
        "state": "Victoria"
    },
    {
        "suburb": "Adams Estate",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "Grantville",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "San Remo",
        "postcode": "3925",
        "state": "Victoria"
    },
    {
        "suburb": "South Dudley",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Harmers Haven",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Cape Paterson",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Anderson",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Glen Forbes",
        "postcode": "3990",
        "state": "Victoria"
    },
    {
        "suburb": "Lang Lang",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "Jam Jerrup",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "Woodleigh",
        "postcode": "3945",
        "state": "Victoria"
    },
    {
        "suburb": "Pound Creek",
        "postcode": "3996",
        "state": "Victoria"
    },
    {
        "suburb": "Loch",
        "postcode": "3945",
        "state": "Victoria"
    },
    {
        "suburb": "Wattle Bank",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Kongwak",
        "postcode": "3951",
        "state": "Victoria"
    },
    {
        "suburb": "Lance Creek",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Outtrim",
        "postcode": "3951",
        "state": "Victoria"
    },
    {
        "suburb": "Coronet Bay",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "Corinella",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "West Creek",
        "postcode": "3992",
        "state": "Victoria"
    },
    {
        "suburb": "St Clair",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Tenby Point",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "Kernot",
        "postcode": "3979",
        "state": "Victoria"
    },
    {
        "suburb": "Pioneer Bay",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "Archies Creek",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Bass",
        "postcode": "3991",
        "state": "Victoria"
    },
    {
        "suburb": "Woolamai",
        "postcode": "3995",
        "state": "Victoria"
    },
    {
        "suburb": "Trafalgar",
        "postcode": "3824",
        "state": "Victoria"
    },
    {
        "suburb": "Erica",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Willow Grove",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Trafalgar East",
        "postcode": "3824",
        "state": "Victoria"
    },
    {
        "suburb": "Moondarra",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Jacob Creek",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Narracan",
        "postcode": "3824",
        "state": "Victoria"
    },
    {
        "suburb": "Coalville",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Trafalgar South",
        "postcode": "3824",
        "state": "Victoria"
    },
    {
        "suburb": "Moe South",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Westbury",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Moe",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Tanjil South",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Yarragon South",
        "postcode": "3823",
        "state": "Victoria"
    },
    {
        "suburb": "Rawson",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Thomson",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Yarragon",
        "postcode": "3823",
        "state": "Victoria"
    },
    {
        "suburb": "Thorpdale",
        "postcode": "3835",
        "state": "Victoria"
    },
    {
        "suburb": "Neerim East",
        "postcode": "3831",
        "state": "Victoria"
    },
    {
        "suburb": "Allambee Reserve",
        "postcode": "3871",
        "state": "Victoria"
    },
    {
        "suburb": "Allambee",
        "postcode": "3823",
        "state": "Victoria"
    },
    {
        "suburb": "Trida",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Darnum",
        "postcode": "3822",
        "state": "Victoria"
    },
    {
        "suburb": "Shady Creek",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Fumina South",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Tanjil Bren",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Thorpdale South",
        "postcode": "3824",
        "state": "Victoria"
    },
    {
        "suburb": "Baw Baw",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Drouin",
        "postcode": "3818",
        "state": "Victoria"
    },
    {
        "suburb": "Buln Buln",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Warragul",
        "postcode": "3820",
        "state": "Victoria"
    },
    {
        "suburb": "Noojee",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Longwarry",
        "postcode": "3816",
        "state": "Victoria"
    },
    {
        "suburb": "Drouin East",
        "postcode": "3818",
        "state": "Victoria"
    },
    {
        "suburb": "Tetoora Road",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Torwood",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Neerim South",
        "postcode": "3831",
        "state": "Victoria"
    },
    {
        "suburb": "Vesper",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Icy Creek",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Fumina",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Neerim North",
        "postcode": "3832",
        "state": "Victoria"
    },
    {
        "suburb": "Piedmont",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Nayook",
        "postcode": "3832",
        "state": "Victoria"
    },
    {
        "suburb": "Neerim Junction",
        "postcode": "3832",
        "state": "Victoria"
    },
    {
        "suburb": "Jindivick",
        "postcode": "3818",
        "state": "Victoria"
    },
    {
        "suburb": "Labertouche",
        "postcode": "3816",
        "state": "Victoria"
    },
    {
        "suburb": "Drouin West",
        "postcode": "3818",
        "state": "Victoria"
    },
    {
        "suburb": "Buln Buln East",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Nilma North",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Lillico",
        "postcode": "3820",
        "state": "Victoria"
    },
    {
        "suburb": "Brandy Creek",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Ripplebrook",
        "postcode": "3818",
        "state": "Victoria"
    },
    {
        "suburb": "Drouin South",
        "postcode": "3818",
        "state": "Victoria"
    },
    {
        "suburb": "Warragul West",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Warragul South",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Bona Vista",
        "postcode": "3820",
        "state": "Victoria"
    },
    {
        "suburb": "Athlone",
        "postcode": "3818",
        "state": "Victoria"
    },
    {
        "suburb": "Nyora",
        "postcode": "3987",
        "state": "Victoria"
    },
    {
        "suburb": "Poowong North",
        "postcode": "3988",
        "state": "Victoria"
    },
    {
        "suburb": "Modella",
        "postcode": "3816",
        "state": "Victoria"
    },
    {
        "suburb": "Heath Hill",
        "postcode": "3981",
        "state": "Victoria"
    },
    {
        "suburb": "Hallora",
        "postcode": "3818",
        "state": "Victoria"
    },
    {
        "suburb": "Poowong East",
        "postcode": "3988",
        "state": "Victoria"
    },
    {
        "suburb": "Mountain View",
        "postcode": "3988",
        "state": "Victoria"
    },
    {
        "suburb": "Seaview",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Strzelecki",
        "postcode": "3950",
        "state": "Victoria"
    },
    {
        "suburb": "Ferndale",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Ellinbank",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Rokeby",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Bravington",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Brighton",
        "postcode": "3186",
        "state": "Victoria"
    },
    {
        "suburb": "Brighton East",
        "postcode": "3187",
        "state": "Victoria"
    },
    {
        "suburb": "Black Rock",
        "postcode": "3193",
        "state": "Victoria"
    },
    {
        "suburb": "Hampton",
        "postcode": "3188",
        "state": "Victoria"
    },
    {
        "suburb": "Sandringham",
        "postcode": "3191",
        "state": "Victoria"
    },
    {
        "suburb": "Highett",
        "postcode": "3190",
        "state": "Victoria"
    },
    {
        "suburb": "Cheltenham",
        "postcode": "3192",
        "state": "Victoria"
    },
    {
        "suburb": "Hampton East",
        "postcode": "3188",
        "state": "Victoria"
    },
    {
        "suburb": "Beaumaris",
        "postcode": "3193",
        "state": "Victoria"
    },
    {
        "suburb": "Benalla",
        "postcode": "3672",
        "state": "Victoria"
    },
    {
        "suburb": "Samaria",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Thoona",
        "postcode": "3726",
        "state": "Victoria"
    },
    {
        "suburb": "Devenish",
        "postcode": "3726",
        "state": "Victoria"
    },
    {
        "suburb": "Lima South",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Tatong",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Baddaginnie",
        "postcode": "3670",
        "state": "Victoria"
    },
    {
        "suburb": "Goorambat",
        "postcode": "3725",
        "state": "Victoria"
    },
    {
        "suburb": "Warrenbayne",
        "postcode": "3670",
        "state": "Victoria"
    },
    {
        "suburb": "Lima East",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Winton North",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Glenrowan",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Bruno",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Taminick",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Bridge Creek",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Stewarton",
        "postcode": "3725",
        "state": "Victoria"
    },
    {
        "suburb": "Boxwood",
        "postcode": "3725",
        "state": "Victoria"
    },
    {
        "suburb": "Major Plains",
        "postcode": "3725",
        "state": "Victoria"
    },
    {
        "suburb": "Goomalibee",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Tarnook",
        "postcode": "3670",
        "state": "Victoria"
    },
    {
        "suburb": "Broken Creek",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Winton",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Glenrowan West",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Bungeet West",
        "postcode": "3726",
        "state": "Victoria"
    },
    {
        "suburb": "Boweya",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Bungeet",
        "postcode": "3726",
        "state": "Victoria"
    },
    {
        "suburb": "Lurg",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Upper Lurg",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Molyullah",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Upper Ryans Creek",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Moorngag",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Swanpool",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Lima",
        "postcode": "3673",
        "state": "Victoria"
    },
    {
        "suburb": "Boho South",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Chesney Vale",
        "postcode": "3725",
        "state": "Victoria"
    },
    {
        "suburb": "Creek Junction",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Balwyn North",
        "postcode": "3104",
        "state": "Victoria"
    },
    {
        "suburb": "Surrey Hills",
        "postcode": "3127",
        "state": "Victoria"
    },
    {
        "suburb": "Deepdene",
        "postcode": "3103",
        "state": "Victoria"
    },
    {
        "suburb": "Balwyn",
        "postcode": "3103",
        "state": "Victoria"
    },
    {
        "suburb": "Canterbury",
        "postcode": "3126",
        "state": "Victoria"
    },
    {
        "suburb": "Kew East",
        "postcode": "3102",
        "state": "Victoria"
    },
    {
        "suburb": "Camberwell",
        "postcode": "3124",
        "state": "Victoria"
    },
    {
        "suburb": "Mont Albert",
        "postcode": "3127",
        "state": "Victoria"
    },
    {
        "suburb": "Glen Iris",
        "postcode": "3146",
        "state": "Victoria"
    },
    {
        "suburb": "Ashburton",
        "postcode": "3147",
        "state": "Victoria"
    },
    {
        "suburb": "Hawthorn",
        "postcode": "3122",
        "state": "Victoria"
    },
    {
        "suburb": "Hawthorn East",
        "postcode": "3123",
        "state": "Victoria"
    },
    {
        "suburb": "Kew",
        "postcode": "3101",
        "state": "Victoria"
    },
    {
        "suburb": "Keilor Park",
        "postcode": "3042",
        "state": "Victoria"
    },
    {
        "suburb": "Keilor",
        "postcode": "3036",
        "state": "Victoria"
    },
    {
        "suburb": "St Albans",
        "postcode": "3021",
        "state": "Victoria"
    },
    {
        "suburb": "Kealba",
        "postcode": "3021",
        "state": "Victoria"
    },
    {
        "suburb": "Keilor Downs",
        "postcode": "3038",
        "state": "Victoria"
    },
    {
        "suburb": "Taylors Lakes",
        "postcode": "3038",
        "state": "Victoria"
    },
    {
        "suburb": "Keilor Lodge",
        "postcode": "3038",
        "state": "Victoria"
    },
    {
        "suburb": "Kings Park",
        "postcode": "3021",
        "state": "Victoria"
    },
    {
        "suburb": "Delahey",
        "postcode": "3037",
        "state": "Victoria"
    },
    {
        "suburb": "Sydenham",
        "postcode": "3037",
        "state": "Victoria"
    },
    {
        "suburb": "Hillside",
        "postcode": "3037",
        "state": "Victoria"
    },
    {
        "suburb": "Tullamarine",
        "postcode": "3043",
        "state": "Victoria"
    },
    {
        "suburb": "Keilor East",
        "postcode": "3033",
        "state": "Victoria"
    },
    {
        "suburb": "Calder Park",
        "postcode": "3037",
        "state": "Victoria"
    },
    {
        "suburb": "Keilor North",
        "postcode": "3036",
        "state": "Victoria"
    },
    {
        "suburb": "Sunshine North",
        "postcode": "3020",
        "state": "Victoria"
    },
    {
        "suburb": "Sunshine",
        "postcode": "3020",
        "state": "Victoria"
    },
    {
        "suburb": "Sunshine West",
        "postcode": "3020",
        "state": "Victoria"
    },
    {
        "suburb": "Albion",
        "postcode": "3020",
        "state": "Victoria"
    },
    {
        "suburb": "Ardeer",
        "postcode": "3022",
        "state": "Victoria"
    },
    {
        "suburb": "Deer Park",
        "postcode": "3023",
        "state": "Victoria"
    },
    {
        "suburb": "Cairnlea",
        "postcode": "3023",
        "state": "Victoria"
    },
    {
        "suburb": "Albanvale",
        "postcode": "3021",
        "state": "Victoria"
    },
    {
        "suburb": "Derrimut",
        "postcode": "3030",
        "state": "Victoria"
    },
    {
        "suburb": "Brooklyn",
        "postcode": "3012",
        "state": "Victoria"
    },
    {
        "suburb": "Lalbert",
        "postcode": "3542",
        "state": "Victoria"
    },
    {
        "suburb": "Culgoa",
        "postcode": "3530",
        "state": "Victoria"
    },
    {
        "suburb": "Nullawil",
        "postcode": "3529",
        "state": "Victoria"
    },
    {
        "suburb": "Wycheproof",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Nandaly",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Ninda",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Sea Lake",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Birchip",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Tyrrell Downs",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Bunguluke",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Wycheproof South",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Chirrip",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Glenloth",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Teddywaddy West",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Jeruk",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Tyrrell",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Ballapur",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Wilkur",
        "postcode": "3393",
        "state": "Victoria"
    },
    {
        "suburb": "Warmur",
        "postcode": "3482",
        "state": "Victoria"
    },
    {
        "suburb": "Reedy Dam",
        "postcode": "3395",
        "state": "Victoria"
    },
    {
        "suburb": "Morton Plains",
        "postcode": "3482",
        "state": "Victoria"
    },
    {
        "suburb": "Birchip West",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Karyrie",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Narraport",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Thalia",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Watchem",
        "postcode": "3482",
        "state": "Victoria"
    },
    {
        "suburb": "Boigbeat",
        "postcode": "3531",
        "state": "Victoria"
    },
    {
        "suburb": "Tyrrell",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Dumosa",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Kalpienung",
        "postcode": "3529",
        "state": "Victoria"
    },
    {
        "suburb": "Towaninny",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Towaninny South",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Berriwillock",
        "postcode": "3531",
        "state": "Victoria"
    },
    {
        "suburb": "Springfield",
        "postcode": "3544",
        "state": "Victoria"
    },
    {
        "suburb": "Sutton",
        "postcode": "3530",
        "state": "Victoria"
    },
    {
        "suburb": "Warne",
        "postcode": "3530",
        "state": "Victoria"
    },
    {
        "suburb": "Wangie",
        "postcode": "3530",
        "state": "Victoria"
    },
    {
        "suburb": "Cokum",
        "postcode": "3530",
        "state": "Victoria"
    },
    {
        "suburb": "Whirily",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Marlbed",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Jil Jil",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Willangie",
        "postcode": "3485",
        "state": "Victoria"
    },
    {
        "suburb": "Watchupga",
        "postcode": "3485",
        "state": "Victoria"
    },
    {
        "suburb": "Curyo",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Kinnabulla",
        "postcode": "3483",
        "state": "Victoria"
    },
    {
        "suburb": "Myall",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Banyan",
        "postcode": "3485",
        "state": "Victoria"
    },
    {
        "suburb": "Turriff East",
        "postcode": "3488",
        "state": "Victoria"
    },
    {
        "suburb": "Pier Milan",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Bimbourie",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Tyenna",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Nyarrin",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Straten",
        "postcode": "3533",
        "state": "Victoria"
    },
    {
        "suburb": "Cope Cope",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Donald",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Massey",
        "postcode": "3482",
        "state": "Victoria"
    },
    {
        "suburb": "Charlton",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Woosang",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Yeungroon East",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Dooboobetic",
        "postcode": "3478",
        "state": "Victoria"
    },
    {
        "suburb": "Coonooer Bridge",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Nine Mile",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Yeungroon",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Yawong Hills",
        "postcode": "3478",
        "state": "Victoria"
    },
    {
        "suburb": "Richmond Plains",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Buckrabanyule",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Barrakee",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Terrappee",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Marmal",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Nareewillock",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Glenloth East",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Rich Avon",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Lawler",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Litchfield",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Carron",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Laen North",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Laen East",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Minyip",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Jeffcott",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Gil Gil",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Wooroonook",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Jeffcott North",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Teddywaddy",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Watchem West",
        "postcode": "3482",
        "state": "Victoria"
    },
    {
        "suburb": "Corack",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Corack East",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Echuca",
        "postcode": "3564",
        "state": "Victoria"
    },
    {
        "suburb": "Kyabram",
        "postcode": "3620",
        "state": "Victoria"
    },
    {
        "suburb": "Tongala",
        "postcode": "3621",
        "state": "Victoria"
    },
    {
        "suburb": "Lancaster",
        "postcode": "3620",
        "state": "Victoria"
    },
    {
        "suburb": "Girgarre",
        "postcode": "3624",
        "state": "Victoria"
    },
    {
        "suburb": "Wyuna East",
        "postcode": "3620",
        "state": "Victoria"
    },
    {
        "suburb": "Kyvalley",
        "postcode": "3621",
        "state": "Victoria"
    },
    {
        "suburb": "Wyuna",
        "postcode": "3620",
        "state": "Victoria"
    },
    {
        "suburb": "Koyuga",
        "postcode": "3622",
        "state": "Victoria"
    },
    {
        "suburb": "Echuca Village",
        "postcode": "3564",
        "state": "Victoria"
    },
    {
        "suburb": "Strathallan",
        "postcode": "3622",
        "state": "Victoria"
    },
    {
        "suburb": "Nanneella",
        "postcode": "3561",
        "state": "Victoria"
    },
    {
        "suburb": "Corop",
        "postcode": "3559",
        "state": "Victoria"
    },
    {
        "suburb": "Timmering",
        "postcode": "3561",
        "state": "Victoria"
    },
    {
        "suburb": "Yambuna",
        "postcode": "3621",
        "state": "Victoria"
    },
    {
        "suburb": "Kanyapella",
        "postcode": "3564",
        "state": "Victoria"
    },
    {
        "suburb": "Rochester",
        "postcode": "3561",
        "state": "Victoria"
    },
    {
        "suburb": "Lockington",
        "postcode": "3563",
        "state": "Victoria"
    },
    {
        "suburb": "Bamawm",
        "postcode": "3561",
        "state": "Victoria"
    },
    {
        "suburb": "Patho",
        "postcode": "3564",
        "state": "Victoria"
    },
    {
        "suburb": "Gunbower",
        "postcode": "3566",
        "state": "Victoria"
    },
    {
        "suburb": "Pine Grove",
        "postcode": "3573",
        "state": "Victoria"
    },
    {
        "suburb": "Echuca West",
        "postcode": "3564",
        "state": "Victoria"
    },
    {
        "suburb": "Wharparilla",
        "postcode": "3564",
        "state": "Victoria"
    },
    {
        "suburb": "Torrumbarry",
        "postcode": "3562",
        "state": "Victoria"
    },
    {
        "suburb": "Tennyson",
        "postcode": "3572",
        "state": "Victoria"
    },
    {
        "suburb": "Fairy Dell",
        "postcode": "3561",
        "state": "Victoria"
    },
    {
        "suburb": "Ballendella",
        "postcode": "3561",
        "state": "Victoria"
    },
    {
        "suburb": "Diggora",
        "postcode": "3561",
        "state": "Victoria"
    },
    {
        "suburb": "Bamawm Extension",
        "postcode": "3564",
        "state": "Victoria"
    },
    {
        "suburb": "Bonn",
        "postcode": "3561",
        "state": "Victoria"
    },
    {
        "suburb": "Kotta",
        "postcode": "3565",
        "state": "Victoria"
    },
    {
        "suburb": "Milloo",
        "postcode": "3572",
        "state": "Victoria"
    },
    {
        "suburb": "Roslynmead",
        "postcode": "3564",
        "state": "Victoria"
    },
    {
        "suburb": "Terrick Terrick East",
        "postcode": "3573",
        "state": "Victoria"
    },
    {
        "suburb": "Mitiamo",
        "postcode": "3573",
        "state": "Victoria"
    },
    {
        "suburb": "Rushworth",
        "postcode": "3612",
        "state": "Victoria"
    },
    {
        "suburb": "Waranga Shores",
        "postcode": "3612",
        "state": "Victoria"
    },
    {
        "suburb": "Stanhope",
        "postcode": "3623",
        "state": "Victoria"
    },
    {
        "suburb": "Toolleen",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Cornella",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Burramboot",
        "postcode": "3559",
        "state": "Victoria"
    },
    {
        "suburb": "Carag Carag",
        "postcode": "3623",
        "state": "Victoria"
    },
    {
        "suburb": "Moora",
        "postcode": "3612",
        "state": "Victoria"
    },
    {
        "suburb": "Colbinabbin",
        "postcode": "3559",
        "state": "Victoria"
    },
    {
        "suburb": "Redcastle",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Whroo",
        "postcode": "3612",
        "state": "Victoria"
    },
    {
        "suburb": "Myola",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Gobarup",
        "postcode": "3559",
        "state": "Victoria"
    },
    {
        "suburb": "Runnymede",
        "postcode": "3558",
        "state": "Victoria"
    },
    {
        "suburb": "Burnewang",
        "postcode": "3558",
        "state": "Victoria"
    },
    {
        "suburb": "Emerald",
        "postcode": "3782",
        "state": "Victoria"
    },
    {
        "suburb": "Clematis",
        "postcode": "3782",
        "state": "Victoria"
    },
    {
        "suburb": "Avonsleigh",
        "postcode": "3782",
        "state": "Victoria"
    },
    {
        "suburb": "Cockatoo",
        "postcode": "3781",
        "state": "Victoria"
    },
    {
        "suburb": "Gembrook",
        "postcode": "3783",
        "state": "Victoria"
    },
    {
        "suburb": "Beaconsfield Upper",
        "postcode": "3808",
        "state": "Victoria"
    },
    {
        "suburb": "Guys Hill",
        "postcode": "3807",
        "state": "Victoria"
    },
    {
        "suburb": "Nar Nar Goon",
        "postcode": "3812",
        "state": "Victoria"
    },
    {
        "suburb": "Tynong",
        "postcode": "3813",
        "state": "Victoria"
    },
    {
        "suburb": "Garfield",
        "postcode": "3814",
        "state": "Victoria"
    },
    {
        "suburb": "Bunyip",
        "postcode": "3815",
        "state": "Victoria"
    },
    {
        "suburb": "Maryknoll",
        "postcode": "3812",
        "state": "Victoria"
    },
    {
        "suburb": "Tynong North",
        "postcode": "3813",
        "state": "Victoria"
    },
    {
        "suburb": "Tonimbuk",
        "postcode": "3815",
        "state": "Victoria"
    },
    {
        "suburb": "Garfield North",
        "postcode": "3814",
        "state": "Victoria"
    },
    {
        "suburb": "Bunyip North",
        "postcode": "3815",
        "state": "Victoria"
    },
    {
        "suburb": "Iona",
        "postcode": "3815",
        "state": "Victoria"
    },
    {
        "suburb": "Vervale",
        "postcode": "3814",
        "state": "Victoria"
    },
    {
        "suburb": "Cora Lynn",
        "postcode": "3814",
        "state": "Victoria"
    },
    {
        "suburb": "Koo Wee Rup North",
        "postcode": "3810",
        "state": "Victoria"
    },
    {
        "suburb": "Nar Nar Goon North",
        "postcode": "3812",
        "state": "Victoria"
    },
    {
        "suburb": "Pakenham Upper",
        "postcode": "3810",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Burnett",
        "postcode": "3781",
        "state": "Victoria"
    },
    {
        "suburb": "Pakenham",
        "postcode": "3810",
        "state": "Victoria"
    },
    {
        "suburb": "Dewhurst",
        "postcode": "3808",
        "state": "Victoria"
    },
    {
        "suburb": "Nangana",
        "postcode": "3781",
        "state": "Victoria"
    },
    {
        "suburb": "Menzies Creek",
        "postcode": "3159",
        "state": "Victoria"
    },
    {
        "suburb": "Beaconsfield",
        "postcode": "3807",
        "state": "Victoria"
    },
    {
        "suburb": "Officer",
        "postcode": "3809",
        "state": "Victoria"
    },
    {
        "suburb": "Cardinia",
        "postcode": "3978",
        "state": "Victoria"
    },
    {
        "suburb": "Pakenham South",
        "postcode": "3810",
        "state": "Victoria"
    },
    {
        "suburb": "Rythdale",
        "postcode": "3810",
        "state": "Victoria"
    },
    {
        "suburb": "Officer South",
        "postcode": "3809",
        "state": "Victoria"
    },
    {
        "suburb": "Koo Wee Rup",
        "postcode": "3981",
        "state": "Victoria"
    },
    {
        "suburb": "Tooradin",
        "postcode": "3980",
        "state": "Victoria"
    },
    {
        "suburb": "Dalmore",
        "postcode": "3981",
        "state": "Victoria"
    },
    {
        "suburb": "Monomeith",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "Caldermeade",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "Yannathan",
        "postcode": "3981",
        "state": "Victoria"
    },
    {
        "suburb": "Lang Lang East",
        "postcode": "3984",
        "state": "Victoria"
    },
    {
        "suburb": "Bayles",
        "postcode": "3981",
        "state": "Victoria"
    },
    {
        "suburb": "Catani",
        "postcode": "3981",
        "state": "Victoria"
    },
    {
        "suburb": "Berwick",
        "postcode": "3806",
        "state": "Victoria"
    },
    {
        "suburb": "Harkaway",
        "postcode": "3806",
        "state": "Victoria"
    },
    {
        "suburb": "Narre Warren North",
        "postcode": "3804",
        "state": "Victoria"
    },
    {
        "suburb": "Narre Warren",
        "postcode": "3805",
        "state": "Victoria"
    },
    {
        "suburb": "Narre Warren South",
        "postcode": "3805",
        "state": "Victoria"
    },
    {
        "suburb": "Cranbourne North",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Hampton Park",
        "postcode": "3976",
        "state": "Victoria"
    },
    {
        "suburb": "Lynbrook",
        "postcode": "3975",
        "state": "Victoria"
    },
    {
        "suburb": "Lyndhurst",
        "postcode": "3975",
        "state": "Victoria"
    },
    {
        "suburb": "Cranbourne",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Cranbourne West",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Cranbourne East",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Junction Village",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Botanic Ridge",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Hallam",
        "postcode": "3803",
        "state": "Victoria"
    },
    {
        "suburb": "Endeavour Hills",
        "postcode": "3802",
        "state": "Victoria"
    },
    {
        "suburb": "Eumemmerring",
        "postcode": "3177",
        "state": "Victoria"
    },
    {
        "suburb": "Doveton",
        "postcode": "3177",
        "state": "Victoria"
    },
    {
        "suburb": "Lysterfield South",
        "postcode": "3156",
        "state": "Victoria"
    },
    {
        "suburb": "Cranbourne South",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Blind Bight",
        "postcode": "3980",
        "state": "Victoria"
    },
    {
        "suburb": "Warneet",
        "postcode": "3980",
        "state": "Victoria"
    },
    {
        "suburb": "Cannons Creek",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Devon Meadows",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Pearcedale",
        "postcode": "3912",
        "state": "Victoria"
    },
    {
        "suburb": "Clyde",
        "postcode": "3978",
        "state": "Victoria"
    },
    {
        "suburb": "Clyde North",
        "postcode": "3978",
        "state": "Victoria"
    },
    {
        "suburb": "Maryborough",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Flagstaff",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Dunolly",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Carisbrook",
        "postcode": "3464",
        "state": "Victoria"
    },
    {
        "suburb": "Bowenvale",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Talbot",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Bealiba",
        "postcode": "3475",
        "state": "Victoria"
    },
    {
        "suburb": "Betley",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Dunach",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Cameron",
        "postcode": "3370",
        "state": "Victoria"
    },
    {
        "suburb": "Daisy Hill",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Lillicur",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Majorca",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Simson",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Timor",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Caralulup",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Bung Bong",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Natte Yallock",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Archdale",
        "postcode": "3475",
        "state": "Victoria"
    },
    {
        "suburb": "Archdale Junction",
        "postcode": "3475",
        "state": "Victoria"
    },
    {
        "suburb": "Joyces Creek",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Eddington",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Moliagul",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Goldsborough",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Dunluce",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Bromley",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Bet Bet",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Hooghly",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Timor West",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Alma",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Adelaide Lead",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Moonlight Flat",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Wareek",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Havelock",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Red Lion",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Craigie",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Glasgow",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Clunes",
        "postcode": "3370",
        "state": "Victoria"
    },
    {
        "suburb": "Stony Creek",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Amherst",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Cotswold",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Strathlea",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Campbelltown",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Glengower",
        "postcode": "3370",
        "state": "Victoria"
    },
    {
        "suburb": "Moolort",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Colac",
        "postcode": "3250",
        "state": "Victoria"
    },
    {
        "suburb": "Elliminyt",
        "postcode": "3250",
        "state": "Victoria"
    },
    {
        "suburb": "Colac East",
        "postcode": "3250",
        "state": "Victoria"
    },
    {
        "suburb": "Beeac",
        "postcode": "3251",
        "state": "Victoria"
    },
    {
        "suburb": "Ondit",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Cundare",
        "postcode": "3251",
        "state": "Victoria"
    },
    {
        "suburb": "Wool Wool",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Dreeite",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Barwon Downs",
        "postcode": "3243",
        "state": "Victoria"
    },
    {
        "suburb": "Irrewarra",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Cundare North",
        "postcode": "3251",
        "state": "Victoria"
    },
    {
        "suburb": "Weering",
        "postcode": "3251",
        "state": "Victoria"
    },
    {
        "suburb": "Eurack",
        "postcode": "3251",
        "state": "Victoria"
    },
    {
        "suburb": "Warncoort",
        "postcode": "3243",
        "state": "Victoria"
    },
    {
        "suburb": "Yeodene",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Birregurra",
        "postcode": "3242",
        "state": "Victoria"
    },
    {
        "suburb": "Pirron Yallock",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Irrewillipe East",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Whoorel",
        "postcode": "3243",
        "state": "Victoria"
    },
    {
        "suburb": "Kawarren",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Murroon",
        "postcode": "3243",
        "state": "Victoria"
    },
    {
        "suburb": "Yeo",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Barongarook West",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Colac West",
        "postcode": "3250",
        "state": "Victoria"
    },
    {
        "suburb": "Cressy",
        "postcode": "3322",
        "state": "Victoria"
    },
    {
        "suburb": "Irrewillipe",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Gerangamete",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Carpendeit",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Nalangil",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Ombersley",
        "postcode": "3241",
        "state": "Victoria"
    },
    {
        "suburb": "Stonyford",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Larpent",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Barongarook",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Swan Marsh",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Barunah Plains",
        "postcode": "3329",
        "state": "Victoria"
    },
    {
        "suburb": "Wingeel",
        "postcode": "3321",
        "state": "Victoria"
    },
    {
        "suburb": "Warrion",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Alvie",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Dreeite South",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Corunnun",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Coragulac",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Forrest",
        "postcode": "3236",
        "state": "Victoria"
    },
    {
        "suburb": "Cororooke",
        "postcode": "3254",
        "state": "Victoria"
    },
    {
        "suburb": "Balintore",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Bungador",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Jancourt East",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "Gellibrand",
        "postcode": "3239",
        "state": "Victoria"
    },
    {
        "suburb": "Apollo Bay",
        "postcode": "3233",
        "state": "Victoria"
    },
    {
        "suburb": "Aire Valley",
        "postcode": "3237",
        "state": "Victoria"
    },
    {
        "suburb": "Skenes Creek North",
        "postcode": "3233",
        "state": "Victoria"
    },
    {
        "suburb": "Barramunga",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Glenaire",
        "postcode": "3238",
        "state": "Victoria"
    },
    {
        "suburb": "Marengo",
        "postcode": "3233",
        "state": "Victoria"
    },
    {
        "suburb": "Skenes Creek",
        "postcode": "3233",
        "state": "Victoria"
    },
    {
        "suburb": "Kennett River",
        "postcode": "3234",
        "state": "Victoria"
    },
    {
        "suburb": "Wye River",
        "postcode": "3234",
        "state": "Victoria"
    },
    {
        "suburb": "Carlisle River",
        "postcode": "3239",
        "state": "Victoria"
    },
    {
        "suburb": "Weeaproinah",
        "postcode": "3237",
        "state": "Victoria"
    },
    {
        "suburb": "Ferguson",
        "postcode": "3237",
        "state": "Victoria"
    },
    {
        "suburb": "Wongarra",
        "postcode": "3234",
        "state": "Victoria"
    },
    {
        "suburb": "Lavers Hill",
        "postcode": "3238",
        "state": "Victoria"
    },
    {
        "suburb": "Tanybryn",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Beech Forest",
        "postcode": "3237",
        "state": "Victoria"
    },
    {
        "suburb": "Hordern Vale",
        "postcode": "3238",
        "state": "Victoria"
    },
    {
        "suburb": "Johanna",
        "postcode": "3238",
        "state": "Victoria"
    },
    {
        "suburb": "Chapple Vale",
        "postcode": "3239",
        "state": "Victoria"
    },
    {
        "suburb": "Wyelangta",
        "postcode": "3237",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Sabine",
        "postcode": "3236",
        "state": "Victoria"
    },
    {
        "suburb": "Separation Creek",
        "postcode": "3234",
        "state": "Victoria"
    },
    {
        "suburb": "Grey River",
        "postcode": "3234",
        "state": "Victoria"
    },
    {
        "suburb": "Sugarloaf",
        "postcode": "3234",
        "state": "Victoria"
    },
    {
        "suburb": "Petticoat Creek",
        "postcode": "3233",
        "state": "Victoria"
    },
    {
        "suburb": "Cape Otway",
        "postcode": "3233",
        "state": "Victoria"
    },
    {
        "suburb": "Yuulong",
        "postcode": "3237",
        "state": "Victoria"
    },
    {
        "suburb": "Gellibrand Lower",
        "postcode": "3237",
        "state": "Victoria"
    },
    {
        "suburb": "Mingay",
        "postcode": "3324",
        "state": "Victoria"
    },
    {
        "suburb": "Skipton",
        "postcode": "3361",
        "state": "Victoria"
    },
    {
        "suburb": "Derrinallum",
        "postcode": "3325",
        "state": "Victoria"
    },
    {
        "suburb": "Terang",
        "postcode": "3264",
        "state": "Victoria"
    },
    {
        "suburb": "Noorat",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Bookaar",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Camperdown",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Kariah",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Leslie Manor",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Lismore",
        "postcode": "3324",
        "state": "Victoria"
    },
    {
        "suburb": "Duverney",
        "postcode": "3323",
        "state": "Victoria"
    },
    {
        "suburb": "Kolora",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Pomborneit North",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Glenormiston North",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Berrybank",
        "postcode": "3323",
        "state": "Victoria"
    },
    {
        "suburb": "Werneth",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Foxhow",
        "postcode": "3323",
        "state": "Victoria"
    },
    {
        "suburb": "Chocolyn",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Naroghid",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "Gnotuk",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Skibo",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Vite Vite North",
        "postcode": "3325",
        "state": "Victoria"
    },
    {
        "suburb": "Vite Vite",
        "postcode": "3325",
        "state": "Victoria"
    },
    {
        "suburb": "Garvoc",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Boorcan",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Darlington",
        "postcode": "3271",
        "state": "Victoria"
    },
    {
        "suburb": "Glenormiston South",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Noorat East",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Weerite",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Bute",
        "postcode": "3324",
        "state": "Victoria"
    },
    {
        "suburb": "Bradvale",
        "postcode": "3361",
        "state": "Victoria"
    },
    {
        "suburb": "Cobden",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "Port Campbell",
        "postcode": "3269",
        "state": "Victoria"
    },
    {
        "suburb": "Wattle Hill",
        "postcode": "3237",
        "state": "Victoria"
    },
    {
        "suburb": "Princetown",
        "postcode": "3269",
        "state": "Victoria"
    },
    {
        "suburb": "Elingamite North",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "South Purrumbete",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Bostocks Creek",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Cobrico",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "Dixie",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Taroon",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Ecklin South",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Pomborneit East",
        "postcode": "3249",
        "state": "Victoria"
    },
    {
        "suburb": "Bullaharre",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "Jancourt",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "Brucknell",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Glenfyne",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "Curdies River",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Ayrford",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Peterborough",
        "postcode": "3270",
        "state": "Victoria"
    },
    {
        "suburb": "Casterton",
        "postcode": "3311",
        "state": "Victoria"
    },
    {
        "suburb": "Heytesbury Lower",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Timboon West",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Paaratte",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Newfield",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Waarre",
        "postcode": "3269",
        "state": "Victoria"
    },
    {
        "suburb": "Cooriemungle",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Kennedys Creek",
        "postcode": "3239",
        "state": "Victoria"
    },
    {
        "suburb": "Scotts Creek",
        "postcode": "3267",
        "state": "Victoria"
    },
    {
        "suburb": "Timboon",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Cowleys Creek",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Simpson",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "Tesbury",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Tandarook",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Pomborneit",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Koallah",
        "postcode": "3260",
        "state": "Victoria"
    },
    {
        "suburb": "Elingamite",
        "postcode": "3266",
        "state": "Victoria"
    },
    {
        "suburb": "Northcote",
        "postcode": "3070",
        "state": "Victoria"
    },
    {
        "suburb": "Thornbury",
        "postcode": "3071",
        "state": "Victoria"
    },
    {
        "suburb": "Fairfield",
        "postcode": "3078",
        "state": "Victoria"
    },
    {
        "suburb": "Alphington",
        "postcode": "3078",
        "state": "Victoria"
    },
    {
        "suburb": "Preston",
        "postcode": "3072",
        "state": "Victoria"
    },
    {
        "suburb": "Reservoir",
        "postcode": "3073",
        "state": "Victoria"
    },
    {
        "suburb": "Kingsbury",
        "postcode": "3083",
        "state": "Victoria"
    },
    {
        "suburb": "Bairnsdale",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Eastwood",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Lucknow",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Swan Reach",
        "postcode": "3903",
        "state": "Victoria"
    },
    {
        "suburb": "East Bairnsdale",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Lakes Entrance",
        "postcode": "3909",
        "state": "Victoria"
    },
    {
        "suburb": "Nyerimilang",
        "postcode": "3909",
        "state": "Victoria"
    },
    {
        "suburb": "Kalimna",
        "postcode": "3909",
        "state": "Victoria"
    },
    {
        "suburb": "Eagle Point",
        "postcode": "3878",
        "state": "Victoria"
    },
    {
        "suburb": "Paynesville",
        "postcode": "3880",
        "state": "Victoria"
    },
    {
        "suburb": "Metung",
        "postcode": "3904",
        "state": "Victoria"
    },
    {
        "suburb": "Nicholson",
        "postcode": "3882",
        "state": "Victoria"
    },
    {
        "suburb": "Raymond Island",
        "postcode": "3880",
        "state": "Victoria"
    },
    {
        "suburb": "Newlands Arm",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Wy Yung",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Johnsonville",
        "postcode": "3902",
        "state": "Victoria"
    },
    {
        "suburb": "Nungurner",
        "postcode": "3909",
        "state": "Victoria"
    },
    {
        "suburb": "Kalimna West",
        "postcode": "3909",
        "state": "Victoria"
    },
    {
        "suburb": "Bumberrah",
        "postcode": "3902",
        "state": "Victoria"
    },
    {
        "suburb": "Tambo Upper",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Ocean Grange",
        "postcode": "3880",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Bunga",
        "postcode": "3909",
        "state": "Victoria"
    },
    {
        "suburb": "Boole Poole",
        "postcode": "3880",
        "state": "Victoria"
    },
    {
        "suburb": "Forge Creek",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Toorloo Arm",
        "postcode": "3909",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Tyers Beach",
        "postcode": "3909",
        "state": "Victoria"
    },
    {
        "suburb": "Mallacoota",
        "postcode": "3892",
        "state": "Victoria"
    },
    {
        "suburb": "Marlo",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Brodribb River",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Orbost",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Newmerella",
        "postcode": "3886",
        "state": "Victoria"
    },
    {
        "suburb": "Wairewa",
        "postcode": "3887",
        "state": "Victoria"
    },
    {
        "suburb": "Bemm River",
        "postcode": "3889",
        "state": "Victoria"
    },
    {
        "suburb": "Cabbage Tree Creek",
        "postcode": "3889",
        "state": "Victoria"
    },
    {
        "suburb": "Tamboon",
        "postcode": "3890",
        "state": "Victoria"
    },
    {
        "suburb": "Cann River",
        "postcode": "3890",
        "state": "Victoria"
    },
    {
        "suburb": "Bendoc",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Wangarabell",
        "postcode": "3891",
        "state": "Victoria"
    },
    {
        "suburb": "Wroxham",
        "postcode": "3891",
        "state": "Victoria"
    },
    {
        "suburb": "Maramingo Creek",
        "postcode": "3891",
        "state": "Victoria"
    },
    {
        "suburb": "Gipsy Point",
        "postcode": "3891",
        "state": "Victoria"
    },
    {
        "suburb": "Corringle",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Tostaree",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Buchan South",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Waygara",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Wombat Creek",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Nowa Nowa",
        "postcode": "3887",
        "state": "Victoria"
    },
    {
        "suburb": "Bete Bolong",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Jarrahmond",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Errinundra",
        "postcode": "3889",
        "state": "Victoria"
    },
    {
        "suburb": "Wingan River",
        "postcode": "3891",
        "state": "Victoria"
    },
    {
        "suburb": "Tonghi Creek",
        "postcode": "3890",
        "state": "Victoria"
    },
    {
        "suburb": "Club Terrace",
        "postcode": "3889",
        "state": "Victoria"
    },
    {
        "suburb": "Combienbar",
        "postcode": "3889",
        "state": "Victoria"
    },
    {
        "suburb": "Noorinbee",
        "postcode": "3890",
        "state": "Victoria"
    },
    {
        "suburb": "Noorinbee North",
        "postcode": "3890",
        "state": "Victoria"
    },
    {
        "suburb": "Chandlers Creek",
        "postcode": "3890",
        "state": "Victoria"
    },
    {
        "suburb": "Genoa",
        "postcode": "3891",
        "state": "Victoria"
    },
    {
        "suburb": "Deddick Valley",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Tubbut",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Wulgulmerang",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Nurran",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Goongerah",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Bonang",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Manorina",
        "postcode": "3889",
        "state": "Victoria"
    },
    {
        "suburb": "Suggan Buggan",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Wulgulmerang East",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Buchan",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Bruthen",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Gelantipy",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Cobberas",
        "postcode": "3900",
        "state": "Victoria"
    },
    {
        "suburb": "Wulgulmerang West",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Nunniong",
        "postcode": "3896",
        "state": "Victoria"
    },
    {
        "suburb": "Murrindal",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Cape Conran",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Tyers",
        "postcode": "3887",
        "state": "Victoria"
    },
    {
        "suburb": "Simpsons Creek",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Hillside",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Taylor",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Fernbank",
        "postcode": "3864",
        "state": "Victoria"
    },
    {
        "suburb": "Wuk Wuk",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Lindenow",
        "postcode": "3865",
        "state": "Victoria"
    },
    {
        "suburb": "Calulu",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Flaggy Creek",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Melwood",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Ellaswood",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Clifton Creek",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Sarsfield",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Granite Rock",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Lindenow South",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Goon Nure",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Bengworden",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Benambra",
        "postcode": "3900",
        "state": "Victoria"
    },
    {
        "suburb": "Omeo",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Bindi",
        "postcode": "3896",
        "state": "Victoria"
    },
    {
        "suburb": "Ensay",
        "postcode": "3895",
        "state": "Victoria"
    },
    {
        "suburb": "Deptford",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Swifts Creek",
        "postcode": "3896",
        "state": "Victoria"
    },
    {
        "suburb": "Brookville",
        "postcode": "3896",
        "state": "Victoria"
    },
    {
        "suburb": "Tongio",
        "postcode": "3896",
        "state": "Victoria"
    },
    {
        "suburb": "Cassilis",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Mossiface",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Wiseleigh",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Anglers Rest",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Nariel Valley",
        "postcode": "3707",
        "state": "Victoria"
    },
    {
        "suburb": "Dartmouth",
        "postcode": "3701",
        "state": "Victoria"
    },
    {
        "suburb": "Bundara",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Doctors Flat",
        "postcode": "3895",
        "state": "Victoria"
    },
    {
        "suburb": "Reedy Flat",
        "postcode": "3895",
        "state": "Victoria"
    },
    {
        "suburb": "Ensay North",
        "postcode": "3895",
        "state": "Victoria"
    },
    {
        "suburb": "Hinnomunjie",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Bingo Munjie",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Marthavale",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Carrum Downs",
        "postcode": "3201",
        "state": "Victoria"
    },
    {
        "suburb": "Skye",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Langwarrin",
        "postcode": "3910",
        "state": "Victoria"
    },
    {
        "suburb": "Langwarrin South",
        "postcode": "3911",
        "state": "Victoria"
    },
    {
        "suburb": "Sandhurst",
        "postcode": "3977",
        "state": "Victoria"
    },
    {
        "suburb": "Seaford",
        "postcode": "3198",
        "state": "Victoria"
    },
    {
        "suburb": "Frankston North",
        "postcode": "3200",
        "state": "Victoria"
    },
    {
        "suburb": "Frankston",
        "postcode": "3199",
        "state": "Victoria"
    },
    {
        "suburb": "Frankston South",
        "postcode": "3199",
        "state": "Victoria"
    },
    {
        "suburb": "Capels Crossing",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Dingwall",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Tragowel",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Cohuna",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Beauchamp",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Charm",
        "postcode": "3581",
        "state": "Victoria"
    },
    {
        "suburb": "Bael Bael",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Mystic Park",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Quambatook",
        "postcode": "3540",
        "state": "Victoria"
    },
    {
        "suburb": "Sandhill Lake",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Fairley",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Wandella",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Leitchville",
        "postcode": "3567",
        "state": "Victoria"
    },
    {
        "suburb": "Murrabit",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Teal Point",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Koondrook",
        "postcode": "3580",
        "state": "Victoria"
    },
    {
        "suburb": "Reedy Lake",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Kerang",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Macorna",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Meran",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Myall",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Benjeroop",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Murrabit West",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Gonn Crossing",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Meatian",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Tittybong",
        "postcode": "3542",
        "state": "Victoria"
    },
    {
        "suburb": "Cannie",
        "postcode": "3540",
        "state": "Victoria"
    },
    {
        "suburb": "Normanville",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Meering West",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Appin",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Appin South",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Daltons Bridge",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Wee Wee Rup",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Burkes Bridge",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Keely",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Horfield",
        "postcode": "3567",
        "state": "Victoria"
    },
    {
        "suburb": "Mincha West",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Mead",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "McMillans",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Macorna North",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Kerang East",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Koroop",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Milnes Bridge",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Westby",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Cullen",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Gannawarra",
        "postcode": "3568",
        "state": "Victoria"
    },
    {
        "suburb": "Pine View",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Budgerum East",
        "postcode": "3579",
        "state": "Victoria"
    },
    {
        "suburb": "Ninyeunook",
        "postcode": "3527",
        "state": "Victoria"
    },
    {
        "suburb": "Gredgwin",
        "postcode": "3537",
        "state": "Victoria"
    },
    {
        "suburb": "Oakvale",
        "postcode": "3540",
        "state": "Victoria"
    },
    {
        "suburb": "Elsternwick",
        "postcode": "3185",
        "state": "Victoria"
    },
    {
        "suburb": "Caulfield North",
        "postcode": "3161",
        "state": "Victoria"
    },
    {
        "suburb": "Caulfield South",
        "postcode": "3162",
        "state": "Victoria"
    },
    {
        "suburb": "Caulfield",
        "postcode": "3162",
        "state": "Victoria"
    },
    {
        "suburb": "Glen Huntly",
        "postcode": "3163",
        "state": "Victoria"
    },
    {
        "suburb": "Carnegie",
        "postcode": "3163",
        "state": "Victoria"
    },
    {
        "suburb": "Ormond",
        "postcode": "3204",
        "state": "Victoria"
    },
    {
        "suburb": "Murrumbeena",
        "postcode": "3163",
        "state": "Victoria"
    },
    {
        "suburb": "St Kilda East",
        "postcode": "3183",
        "state": "Victoria"
    },
    {
        "suburb": "Gardenvale",
        "postcode": "3185",
        "state": "Victoria"
    },
    {
        "suburb": "Caulfield East",
        "postcode": "3145",
        "state": "Victoria"
    },
    {
        "suburb": "Bentleigh",
        "postcode": "3204",
        "state": "Victoria"
    },
    {
        "suburb": "McKinnon",
        "postcode": "3204",
        "state": "Victoria"
    },
    {
        "suburb": "Bentleigh East",
        "postcode": "3165",
        "state": "Victoria"
    },
    {
        "suburb": "Heywood",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Milltown",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Narrawong",
        "postcode": "3285",
        "state": "Victoria"
    },
    {
        "suburb": "Nelson",
        "postcode": "3292",
        "state": "Victoria"
    },
    {
        "suburb": "Dartmoor",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Drumborg",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Drik Drik",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Gorae West",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Breakaway Creek",
        "postcode": "3303",
        "state": "Victoria"
    },
    {
        "suburb": "Cashmore",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Portland West",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Heathmere",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Allestree",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Bolwarra",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Mumbannar",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Condah",
        "postcode": "3303",
        "state": "Victoria"
    },
    {
        "suburb": "Tyrendarra",
        "postcode": "3285",
        "state": "Victoria"
    },
    {
        "suburb": "Strathdownie",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Richmond",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Cape Bridgewater",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Greenwald",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Portland North",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Gorae",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Homerton",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Condah",
        "postcode": "3303",
        "state": "Victoria"
    },
    {
        "suburb": "Bessiebelle",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Wallacedale",
        "postcode": "3303",
        "state": "Victoria"
    },
    {
        "suburb": "Branxholme",
        "postcode": "3302",
        "state": "Victoria"
    },
    {
        "suburb": "Lyons",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Hotspur",
        "postcode": "3303",
        "state": "Victoria"
    },
    {
        "suburb": "Digby",
        "postcode": "3309",
        "state": "Victoria"
    },
    {
        "suburb": "Myamyn",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Winnap",
        "postcode": "3304",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Mundi",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Killara",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Merino",
        "postcode": "3310",
        "state": "Victoria"
    },
    {
        "suburb": "Dunrobin",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Corndale",
        "postcode": "3311",
        "state": "Victoria"
    },
    {
        "suburb": "Nangeela",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Wando Vale",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Carapook",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Nareen",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Wando Bridge",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Brimboal",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Warrock",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Dergholm",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Chetwynd",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Grassdale",
        "postcode": "3302",
        "state": "Victoria"
    },
    {
        "suburb": "Tahara West",
        "postcode": "3310",
        "state": "Victoria"
    },
    {
        "suburb": "Sandford",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Henty",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Clover Flat",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Muntham",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Bahgallah",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Lindsay",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Tahara",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Paschendale",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Tahara Bridge",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Portland",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Dutton Way",
        "postcode": "3305",
        "state": "Victoria"
    },
    {
        "suburb": "Haddon",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Nintingbool",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Smythesdale",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Ross Creek",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Happy Valley",
        "postcode": "3360",
        "state": "Victoria"
    },
    {
        "suburb": "Cape Clear",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Enfield",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Staffordshire Reef",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Linton",
        "postcode": "3360",
        "state": "Victoria"
    },
    {
        "suburb": "Scarsdale",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Willowvale",
        "postcode": "3360",
        "state": "Victoria"
    },
    {
        "suburb": "Pitfield",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Wallinduc",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Napoleons",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Cambrian Hill",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Newtown",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Springdallah",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Piggoreet",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Berringa",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Dereel",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Garibaldi",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Grenville",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Illabarook",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Rokewood Junction",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Mannibadar",
        "postcode": "3360",
        "state": "Victoria"
    },
    {
        "suburb": "Pittong",
        "postcode": "3360",
        "state": "Victoria"
    },
    {
        "suburb": "Bannockburn",
        "postcode": "3331",
        "state": "Victoria"
    },
    {
        "suburb": "Durdidwarrah",
        "postcode": "3342",
        "state": "Victoria"
    },
    {
        "suburb": "Meredith",
        "postcode": "3333",
        "state": "Victoria"
    },
    {
        "suburb": "Teesdale",
        "postcode": "3328",
        "state": "Victoria"
    },
    {
        "suburb": "Corindhap",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Rokewood",
        "postcode": "3330",
        "state": "Victoria"
    },
    {
        "suburb": "Lethbridge",
        "postcode": "3332",
        "state": "Victoria"
    },
    {
        "suburb": "Inverleigh",
        "postcode": "3321",
        "state": "Victoria"
    },
    {
        "suburb": "Shelford",
        "postcode": "3329",
        "state": "Victoria"
    },
    {
        "suburb": "Bamganie",
        "postcode": "3333",
        "state": "Victoria"
    },
    {
        "suburb": "She Oaks",
        "postcode": "3331",
        "state": "Victoria"
    },
    {
        "suburb": "Anakie",
        "postcode": "3213",
        "state": "Victoria"
    },
    {
        "suburb": "Steiglitz",
        "postcode": "3331",
        "state": "Victoria"
    },
    {
        "suburb": "Russells Bridge",
        "postcode": "3331",
        "state": "Victoria"
    },
    {
        "suburb": "Stonehaven",
        "postcode": "3218",
        "state": "Victoria"
    },
    {
        "suburb": "Murgheboluc",
        "postcode": "3218",
        "state": "Victoria"
    },
    {
        "suburb": "Gheringhap",
        "postcode": "3331",
        "state": "Victoria"
    },
    {
        "suburb": "Batesford",
        "postcode": "3213",
        "state": "Victoria"
    },
    {
        "suburb": "Maude",
        "postcode": "3331",
        "state": "Victoria"
    },
    {
        "suburb": "Sutherlands Creek",
        "postcode": "3331",
        "state": "Victoria"
    },
    {
        "suburb": "Barunah Park",
        "postcode": "3329",
        "state": "Victoria"
    },
    {
        "suburb": "Hesse",
        "postcode": "3321",
        "state": "Victoria"
    },
    {
        "suburb": "Bendigo",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Long Gully",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "North Bendigo",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Golden Square",
        "postcode": "3555",
        "state": "Victoria"
    },
    {
        "suburb": "West Bendigo",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Ironbark",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Eaglehawk",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "California Gully",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "Jackass Flat",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "Sailors Gully",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "East Bendigo",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Strathdale",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Kennington",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Flora Hill",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Spring Gully",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Quarry Hill",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Golden Gully",
        "postcode": "3555",
        "state": "Victoria"
    },
    {
        "suburb": "Kangaroo Flat",
        "postcode": "3555",
        "state": "Victoria"
    },
    {
        "suburb": "Junortoun",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Strathfieldsaye",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Huntly",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Whipstick",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "White Hills",
        "postcode": "3550",
        "state": "Victoria"
    },
    {
        "suburb": "Epsom",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Wellsford",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Ascot",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Bagshot",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Huntly North",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Woodvale",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "Eaglehawk North",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "Maiden Gully",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Big Hill",
        "postcode": "3555",
        "state": "Victoria"
    },
    {
        "suburb": "Marong",
        "postcode": "3515",
        "state": "Victoria"
    },
    {
        "suburb": "Myers Flat",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "Lockwood",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Lockwood South",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Leichardt",
        "postcode": "3516",
        "state": "Victoria"
    },
    {
        "suburb": "Ravenswood",
        "postcode": "3453",
        "state": "Victoria"
    },
    {
        "suburb": "Mandurang",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Longlea",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Emu Creek",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Goornong",
        "postcode": "3557",
        "state": "Victoria"
    },
    {
        "suburb": "Elmore",
        "postcode": "3558",
        "state": "Victoria"
    },
    {
        "suburb": "Myrtle Creek",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Axe Creek",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Eppalock",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Mia Mia",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Fosterville",
        "postcode": "3557",
        "state": "Victoria"
    },
    {
        "suburb": "Sedgwick",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Redesdale",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Argyle",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Heathcote",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Camel",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Axedale",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Barnadown",
        "postcode": "3557",
        "state": "Victoria"
    },
    {
        "suburb": "Kimbolton",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Avonmore",
        "postcode": "3559",
        "state": "Victoria"
    },
    {
        "suburb": "Harcourt North",
        "postcode": "3453",
        "state": "Victoria"
    },
    {
        "suburb": "Wilsons Hill",
        "postcode": "3515",
        "state": "Victoria"
    },
    {
        "suburb": "Shelbourne",
        "postcode": "3515",
        "state": "Victoria"
    },
    {
        "suburb": "Sebastian",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "Hunter",
        "postcode": "3558",
        "state": "Victoria"
    },
    {
        "suburb": "Kamarooka",
        "postcode": "3570",
        "state": "Victoria"
    },
    {
        "suburb": "Drummartin",
        "postcode": "3570",
        "state": "Victoria"
    },
    {
        "suburb": "Knowsley",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Neilborough",
        "postcode": "3570",
        "state": "Victoria"
    },
    {
        "suburb": "Mandurang South",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Bagshot North",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Raywood",
        "postcode": "3570",
        "state": "Victoria"
    },
    {
        "suburb": "Derrinal",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Ladys Pass",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Eppalock",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Costerfield",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Noble Park North",
        "postcode": "3174",
        "state": "Victoria"
    },
    {
        "suburb": "Dandenong",
        "postcode": "3175",
        "state": "Victoria"
    },
    {
        "suburb": "Dandenong North",
        "postcode": "3175",
        "state": "Victoria"
    },
    {
        "suburb": "Dandenong South",
        "postcode": "3175",
        "state": "Victoria"
    },
    {
        "suburb": "Noble Park",
        "postcode": "3174",
        "state": "Victoria"
    },
    {
        "suburb": "Keysborough",
        "postcode": "3173",
        "state": "Victoria"
    },
    {
        "suburb": "Springvale",
        "postcode": "3171",
        "state": "Victoria"
    },
    {
        "suburb": "Springvale South",
        "postcode": "3172",
        "state": "Victoria"
    },
    {
        "suburb": "Bangholme",
        "postcode": "3175",
        "state": "Victoria"
    },
    {
        "suburb": "Leopold",
        "postcode": "3224",
        "state": "Victoria"
    },
    {
        "suburb": "St Albans Park",
        "postcode": "3219",
        "state": "Victoria"
    },
    {
        "suburb": "Whittington",
        "postcode": "3219",
        "state": "Victoria"
    },
    {
        "suburb": "Moolap",
        "postcode": "3224",
        "state": "Victoria"
    },
    {
        "suburb": "Newcomb",
        "postcode": "3219",
        "state": "Victoria"
    },
    {
        "suburb": "Hamlyn Heights",
        "postcode": "3215",
        "state": "Victoria"
    },
    {
        "suburb": "Lara",
        "postcode": "3212",
        "state": "Victoria"
    },
    {
        "suburb": "Norlane",
        "postcode": "3214",
        "state": "Victoria"
    },
    {
        "suburb": "Corio",
        "postcode": "3214",
        "state": "Victoria"
    },
    {
        "suburb": "North Shore",
        "postcode": "3214",
        "state": "Victoria"
    },
    {
        "suburb": "Bell Park",
        "postcode": "3215",
        "state": "Victoria"
    },
    {
        "suburb": "North Geelong",
        "postcode": "3215",
        "state": "Victoria"
    },
    {
        "suburb": "Bell Post Hill",
        "postcode": "3215",
        "state": "Victoria"
    },
    {
        "suburb": "Herne Hill",
        "postcode": "3218",
        "state": "Victoria"
    },
    {
        "suburb": "Lovely Banks",
        "postcode": "3213",
        "state": "Victoria"
    },
    {
        "suburb": "Rippleside",
        "postcode": "3215",
        "state": "Victoria"
    },
    {
        "suburb": "Fyansford",
        "postcode": "3218",
        "state": "Victoria"
    },
    {
        "suburb": "Moorabool",
        "postcode": "3213",
        "state": "Victoria"
    },
    {
        "suburb": "Geelong",
        "postcode": "3220",
        "state": "Victoria"
    },
    {
        "suburb": "East Geelong",
        "postcode": "3219",
        "state": "Victoria"
    },
    {
        "suburb": "Breakwater",
        "postcode": "3219",
        "state": "Victoria"
    },
    {
        "suburb": "South Geelong",
        "postcode": "3220",
        "state": "Victoria"
    },
    {
        "suburb": "Thomson",
        "postcode": "3219",
        "state": "Victoria"
    },
    {
        "suburb": "Geelong West",
        "postcode": "3218",
        "state": "Victoria"
    },
    {
        "suburb": "Manifold Heights",
        "postcode": "3218",
        "state": "Victoria"
    },
    {
        "suburb": "Drumcondra",
        "postcode": "3215",
        "state": "Victoria"
    },
    {
        "suburb": "Newtown",
        "postcode": "3220",
        "state": "Victoria"
    },
    {
        "suburb": "Highton",
        "postcode": "3216",
        "state": "Victoria"
    },
    {
        "suburb": "Belmont",
        "postcode": "3216",
        "state": "Victoria"
    },
    {
        "suburb": "Wandana Heights",
        "postcode": "3216",
        "state": "Victoria"
    },
    {
        "suburb": "Grovedale",
        "postcode": "3216",
        "state": "Victoria"
    },
    {
        "suburb": "Waurn Ponds",
        "postcode": "3216",
        "state": "Victoria"
    },
    {
        "suburb": "Marshall",
        "postcode": "3216",
        "state": "Victoria"
    },
    {
        "suburb": "Connewarre",
        "postcode": "3227",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Duneed",
        "postcode": "3217",
        "state": "Victoria"
    },
    {
        "suburb": "Ceres",
        "postcode": "3221",
        "state": "Victoria"
    },
    {
        "suburb": "Charlemont",
        "postcode": "3217",
        "state": "Victoria"
    },
    {
        "suburb": "Indented Head",
        "postcode": "3223",
        "state": "Victoria"
    },
    {
        "suburb": "St Leonards",
        "postcode": "3223",
        "state": "Victoria"
    },
    {
        "suburb": "Clifton Springs",
        "postcode": "3222",
        "state": "Victoria"
    },
    {
        "suburb": "Portarlington",
        "postcode": "3223",
        "state": "Victoria"
    },
    {
        "suburb": "Point Lonsdale",
        "postcode": "3225",
        "state": "Victoria"
    },
    {
        "suburb": "Drysdale",
        "postcode": "3222",
        "state": "Victoria"
    },
    {
        "suburb": "Ocean Grove",
        "postcode": "3226",
        "state": "Victoria"
    },
    {
        "suburb": "Wallington",
        "postcode": "3222",
        "state": "Victoria"
    },
    {
        "suburb": "Barwon Heads",
        "postcode": "3227",
        "state": "Victoria"
    },
    {
        "suburb": "Curlewis",
        "postcode": "3222",
        "state": "Victoria"
    },
    {
        "suburb": "Breamlea",
        "postcode": "3227",
        "state": "Victoria"
    },
    {
        "suburb": "Bellarine",
        "postcode": "3223",
        "state": "Victoria"
    },
    {
        "suburb": "Mannerim",
        "postcode": "3222",
        "state": "Victoria"
    },
    {
        "suburb": "Marcus Hill",
        "postcode": "3222",
        "state": "Victoria"
    },
    {
        "suburb": "Avalon",
        "postcode": "3212",
        "state": "Victoria"
    },
    {
        "suburb": "Little River",
        "postcode": "3211",
        "state": "Victoria"
    },
    {
        "suburb": "Staughton Vale",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Balliang",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Point Wilson",
        "postcode": "3212",
        "state": "Victoria"
    },
    {
        "suburb": "Grahamvale",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Kialla",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Shepparton East",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Shepparton",
        "postcode": "3630",
        "state": "Victoria"
    },
    {
        "suburb": "Lemnos",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Mooroopna",
        "postcode": "3629",
        "state": "Victoria"
    },
    {
        "suburb": "Toolamba",
        "postcode": "3614",
        "state": "Victoria"
    },
    {
        "suburb": "Mooroopna North",
        "postcode": "3629",
        "state": "Victoria"
    },
    {
        "suburb": "Orrvale",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Shepparton North",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Congupna",
        "postcode": "3633",
        "state": "Victoria"
    },
    {
        "suburb": "Tatura East",
        "postcode": "3616",
        "state": "Victoria"
    },
    {
        "suburb": "Ardmona",
        "postcode": "3629",
        "state": "Victoria"
    },
    {
        "suburb": "Pine Lodge",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Kialla East",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Violet Town",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Dookie",
        "postcode": "3646",
        "state": "Victoria"
    },
    {
        "suburb": "Katandra West",
        "postcode": "3634",
        "state": "Victoria"
    },
    {
        "suburb": "Tallygaroopna",
        "postcode": "3634",
        "state": "Victoria"
    },
    {
        "suburb": "Bunbartha",
        "postcode": "3634",
        "state": "Victoria"
    },
    {
        "suburb": "Zeerust",
        "postcode": "3634",
        "state": "Victoria"
    },
    {
        "suburb": "Marionvale",
        "postcode": "3634",
        "state": "Victoria"
    },
    {
        "suburb": "Marungi",
        "postcode": "3634",
        "state": "Victoria"
    },
    {
        "suburb": "Katandra",
        "postcode": "3634",
        "state": "Victoria"
    },
    {
        "suburb": "Cosgrove",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Dookie College",
        "postcode": "3647",
        "state": "Victoria"
    },
    {
        "suburb": "Nalinga",
        "postcode": "3646",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Major",
        "postcode": "3646",
        "state": "Victoria"
    },
    {
        "suburb": "Cosgrove South",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Tamleugh North",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Karramomus",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Kialla West",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Arcadia",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Caniambo",
        "postcode": "3630",
        "state": "Victoria"
    },
    {
        "suburb": "Gowangardie",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Merrigum",
        "postcode": "3618",
        "state": "Victoria"
    },
    {
        "suburb": "Tatura",
        "postcode": "3616",
        "state": "Victoria"
    },
    {
        "suburb": "Gillieston",
        "postcode": "3616",
        "state": "Victoria"
    },
    {
        "suburb": "Murchison",
        "postcode": "3610",
        "state": "Victoria"
    },
    {
        "suburb": "Toolamba West",
        "postcode": "3614",
        "state": "Victoria"
    },
    {
        "suburb": "Coomboona",
        "postcode": "3629",
        "state": "Victoria"
    },
    {
        "suburb": "Murchison North",
        "postcode": "3610",
        "state": "Victoria"
    },
    {
        "suburb": "Harston",
        "postcode": "3616",
        "state": "Victoria"
    },
    {
        "suburb": "Mooroopna North West",
        "postcode": "3616",
        "state": "Victoria"
    },
    {
        "suburb": "Byrneside",
        "postcode": "3617",
        "state": "Victoria"
    },
    {
        "suburb": "Dhurringile",
        "postcode": "3610",
        "state": "Victoria"
    },
    {
        "suburb": "Undera",
        "postcode": "3629",
        "state": "Victoria"
    },
    {
        "suburb": "Murchison East",
        "postcode": "3610",
        "state": "Victoria"
    },
    {
        "suburb": "Moorilim",
        "postcode": "3610",
        "state": "Victoria"
    },
    {
        "suburb": "Kyabram South",
        "postcode": "3620",
        "state": "Victoria"
    },
    {
        "suburb": "Cooma",
        "postcode": "3616",
        "state": "Victoria"
    },
    {
        "suburb": "St Germains",
        "postcode": "3620",
        "state": "Victoria"
    },
    {
        "suburb": "Trentham",
        "postcode": "3458",
        "state": "Victoria"
    },
    {
        "suburb": "Drummond",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Wheatsheaf",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Daylesford",
        "postcode": "3460",
        "state": "Victoria"
    },
    {
        "suburb": "Hepburn Springs",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Coomoora",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Porcupine Ridge",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Hepburn",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Yandoit",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Bullarto",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Dry Diggings",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Glenlyon",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Eganstown",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Elevated Plains",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Drummond North",
        "postcode": "3446",
        "state": "Victoria"
    },
    {
        "suburb": "Spring Hill",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "North Blackwood",
        "postcode": "3458",
        "state": "Victoria"
    },
    {
        "suburb": "Musk Vale",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Clydesdale",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Strangways",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Newbury",
        "postcode": "3458",
        "state": "Victoria"
    },
    {
        "suburb": "Lyonville",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Sailors Falls",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Leonards Hill",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Guildford",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Franklin",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Sailors Hill",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Denver",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Musk",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Bullarto South",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Little Hampton",
        "postcode": "3458",
        "state": "Victoria"
    },
    {
        "suburb": "Tylden",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Fern Hill",
        "postcode": "3458",
        "state": "Victoria"
    },
    {
        "suburb": "Trentham East",
        "postcode": "3458",
        "state": "Victoria"
    },
    {
        "suburb": "Franklinford",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Shepherds Flat",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Kooroocheang",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Basalt",
        "postcode": "3460",
        "state": "Victoria"
    },
    {
        "suburb": "Broomfield",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Kingston",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Cabbage Tree",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Smeaton",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Blampied",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Newlyn North",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Allendale",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Beckworth",
        "postcode": "3363",
        "state": "Victoria"
    },
    {
        "suburb": "Evansford",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Ullina",
        "postcode": "3370",
        "state": "Victoria"
    },
    {
        "suburb": "Lawrence",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Creswick North",
        "postcode": "3363",
        "state": "Victoria"
    },
    {
        "suburb": "Werona",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Mollongghip",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Langdons Hill",
        "postcode": "3363",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Prospect",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Rocklyn",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Newlyn",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Springmount",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Smokeytown",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Dean",
        "postcode": "3363",
        "state": "Victoria"
    },
    {
        "suburb": "Nhill",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Rainbow",
        "postcode": "3424",
        "state": "Victoria"
    },
    {
        "suburb": "Dimboola",
        "postcode": "3414",
        "state": "Victoria"
    },
    {
        "suburb": "Jeparit",
        "postcode": "3423",
        "state": "Victoria"
    },
    {
        "suburb": "Little Desert",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Antwerp",
        "postcode": "3414",
        "state": "Victoria"
    },
    {
        "suburb": "Big Desert",
        "postcode": "3490",
        "state": "Victoria"
    },
    {
        "suburb": "Broughton",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Yanac",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Kiata",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Gerang Gerung",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Lorquon",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Netherby",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Glenlee",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Tarranyurk",
        "postcode": "3414",
        "state": "Victoria"
    },
    {
        "suburb": "Kenmare",
        "postcode": "3395",
        "state": "Victoria"
    },
    {
        "suburb": "Altona North",
        "postcode": "3025",
        "state": "Victoria"
    },
    {
        "suburb": "Altona",
        "postcode": "3018",
        "state": "Victoria"
    },
    {
        "suburb": "Seaholme",
        "postcode": "3018",
        "state": "Victoria"
    },
    {
        "suburb": "Laverton",
        "postcode": "3028",
        "state": "Victoria"
    },
    {
        "suburb": "Altona Meadows",
        "postcode": "3028",
        "state": "Victoria"
    },
    {
        "suburb": "Seabrook",
        "postcode": "3028",
        "state": "Victoria"
    },
    {
        "suburb": "Newport",
        "postcode": "3015",
        "state": "Victoria"
    },
    {
        "suburb": "Williamstown",
        "postcode": "3016",
        "state": "Victoria"
    },
    {
        "suburb": "Williamstown North",
        "postcode": "3016",
        "state": "Victoria"
    },
    {
        "suburb": "Spotswood",
        "postcode": "3015",
        "state": "Victoria"
    },
    {
        "suburb": "South Kingsville",
        "postcode": "3015",
        "state": "Victoria"
    },
    {
        "suburb": "Horsham",
        "postcode": "3400",
        "state": "Victoria"
    },
    {
        "suburb": "Natimuk",
        "postcode": "3409",
        "state": "Victoria"
    },
    {
        "suburb": "Duchembegarra",
        "postcode": "3409",
        "state": "Victoria"
    },
    {
        "suburb": "Mitre",
        "postcode": "3409",
        "state": "Victoria"
    },
    {
        "suburb": "Drung",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Tooan",
        "postcode": "3409",
        "state": "Victoria"
    },
    {
        "suburb": "Toolondo",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Noradjuha",
        "postcode": "3409",
        "state": "Victoria"
    },
    {
        "suburb": "Kanagulk",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Clear Lake",
        "postcode": "3409",
        "state": "Victoria"
    },
    {
        "suburb": "Nurrabiel",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Bungalally",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "St Helens Plains",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Telangatuk East",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Wail",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Haven",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "McKenzie Creek",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Wonwondah",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Pimpinio",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Dooen",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Riverside",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Lower Norton",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Quantong",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Vectis",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Jung",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Douglas",
        "postcode": "3409",
        "state": "Victoria"
    },
    {
        "suburb": "Laharum",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Dadswells Bridge",
        "postcode": "3385",
        "state": "Victoria"
    },
    {
        "suburb": "Mockinya",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Murra Warra",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Kalkee",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Wartook",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Campbellfield",
        "postcode": "3061",
        "state": "Victoria"
    },
    {
        "suburb": "Broadmeadows",
        "postcode": "3047",
        "state": "Victoria"
    },
    {
        "suburb": "Dallas",
        "postcode": "3047",
        "state": "Victoria"
    },
    {
        "suburb": "Coolaroo",
        "postcode": "3048",
        "state": "Victoria"
    },
    {
        "suburb": "Jacana",
        "postcode": "3047",
        "state": "Victoria"
    },
    {
        "suburb": "Meadow Heights",
        "postcode": "3048",
        "state": "Victoria"
    },
    {
        "suburb": "Gladstone Park",
        "postcode": "3043",
        "state": "Victoria"
    },
    {
        "suburb": "Westmeadows",
        "postcode": "3049",
        "state": "Victoria"
    },
    {
        "suburb": "Melbourne Airport",
        "postcode": "3045",
        "state": "Victoria"
    },
    {
        "suburb": "Fawkner",
        "postcode": "3060",
        "state": "Victoria"
    },
    {
        "suburb": "Roxburgh Park",
        "postcode": "3064",
        "state": "Victoria"
    },
    {
        "suburb": "Somerton",
        "postcode": "3062",
        "state": "Victoria"
    },
    {
        "suburb": "Attwood",
        "postcode": "3049",
        "state": "Victoria"
    },
    {
        "suburb": "Greenvale",
        "postcode": "3059",
        "state": "Victoria"
    },
    {
        "suburb": "Craigieburn",
        "postcode": "3064",
        "state": "Victoria"
    },
    {
        "suburb": "Bulla",
        "postcode": "3428",
        "state": "Victoria"
    },
    {
        "suburb": "Mickleham",
        "postcode": "3064",
        "state": "Victoria"
    },
    {
        "suburb": "Kalkallo",
        "postcode": "3064",
        "state": "Victoria"
    },
    {
        "suburb": "Yuroke",
        "postcode": "3063",
        "state": "Victoria"
    },
    {
        "suburb": "Oaklands Junction",
        "postcode": "3063",
        "state": "Victoria"
    },
    {
        "suburb": "Sunbury",
        "postcode": "3429",
        "state": "Victoria"
    },
    {
        "suburb": "Wildwood",
        "postcode": "3429",
        "state": "Victoria"
    },
    {
        "suburb": "Diggers Rest",
        "postcode": "3427",
        "state": "Victoria"
    },
    {
        "suburb": "Clarkefield",
        "postcode": "3430",
        "state": "Victoria"
    },
    {
        "suburb": "Barnawartha",
        "postcode": "3688",
        "state": "Victoria"
    },
    {
        "suburb": "Beechworth",
        "postcode": "3747",
        "state": "Victoria"
    },
    {
        "suburb": "Yackandandah",
        "postcode": "3749",
        "state": "Victoria"
    },
    {
        "suburb": "Kiewa",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Tangambalanga",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Chiltern",
        "postcode": "3683",
        "state": "Victoria"
    },
    {
        "suburb": "Kergunyah",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Staghorn Flat",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Bruarong",
        "postcode": "3749",
        "state": "Victoria"
    },
    {
        "suburb": "Indigo Valley",
        "postcode": "3688",
        "state": "Victoria"
    },
    {
        "suburb": "Cornishtown",
        "postcode": "3683",
        "state": "Victoria"
    },
    {
        "suburb": "Chiltern Valley",
        "postcode": "3683",
        "state": "Victoria"
    },
    {
        "suburb": "Browns Plains",
        "postcode": "3685",
        "state": "Victoria"
    },
    {
        "suburb": "Stanley",
        "postcode": "3747",
        "state": "Victoria"
    },
    {
        "suburb": "Charleroi",
        "postcode": "3695",
        "state": "Victoria"
    },
    {
        "suburb": "Sandy Creek",
        "postcode": "3695",
        "state": "Victoria"
    },
    {
        "suburb": "Wooragee",
        "postcode": "3747",
        "state": "Victoria"
    },
    {
        "suburb": "Eldorado",
        "postcode": "3746",
        "state": "Victoria"
    },
    {
        "suburb": "Allans Flat",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Osbornes Flat",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Huon",
        "postcode": "3695",
        "state": "Victoria"
    },
    {
        "suburb": "Wahgunyah",
        "postcode": "3687",
        "state": "Victoria"
    },
    {
        "suburb": "Rutherglen",
        "postcode": "3685",
        "state": "Victoria"
    },
    {
        "suburb": "Brimin",
        "postcode": "3685",
        "state": "Victoria"
    },
    {
        "suburb": "Carlyle",
        "postcode": "3685",
        "state": "Victoria"
    },
    {
        "suburb": "Norong",
        "postcode": "3682",
        "state": "Victoria"
    },
    {
        "suburb": "Lilliput",
        "postcode": "3682",
        "state": "Victoria"
    },
    {
        "suburb": "Gooramadda",
        "postcode": "3685",
        "state": "Victoria"
    },
    {
        "suburb": "Parkdale",
        "postcode": "3195",
        "state": "Victoria"
    },
    {
        "suburb": "Dingley Village",
        "postcode": "3172",
        "state": "Victoria"
    },
    {
        "suburb": "Clayton South",
        "postcode": "3169",
        "state": "Victoria"
    },
    {
        "suburb": "Clarinda",
        "postcode": "3169",
        "state": "Victoria"
    },
    {
        "suburb": "Oakleigh South",
        "postcode": "3167",
        "state": "Victoria"
    },
    {
        "suburb": "Moorabbin",
        "postcode": "3189",
        "state": "Victoria"
    },
    {
        "suburb": "Heatherton",
        "postcode": "3202",
        "state": "Victoria"
    },
    {
        "suburb": "Mentone",
        "postcode": "3194",
        "state": "Victoria"
    },
    {
        "suburb": "Mordialloc",
        "postcode": "3195",
        "state": "Victoria"
    },
    {
        "suburb": "Braeside",
        "postcode": "3195",
        "state": "Victoria"
    },
    {
        "suburb": "Waterways",
        "postcode": "3195",
        "state": "Victoria"
    },
    {
        "suburb": "Moorabbin Airport",
        "postcode": "3194",
        "state": "Victoria"
    },
    {
        "suburb": "Aspendale Gardens",
        "postcode": "3195",
        "state": "Victoria"
    },
    {
        "suburb": "Aspendale",
        "postcode": "3195",
        "state": "Victoria"
    },
    {
        "suburb": "Edithvale",
        "postcode": "3196",
        "state": "Victoria"
    },
    {
        "suburb": "Bonbeach",
        "postcode": "3196",
        "state": "Victoria"
    },
    {
        "suburb": "Chelsea",
        "postcode": "3196",
        "state": "Victoria"
    },
    {
        "suburb": "Carrum",
        "postcode": "3197",
        "state": "Victoria"
    },
    {
        "suburb": "Chelsea Heights",
        "postcode": "3196",
        "state": "Victoria"
    },
    {
        "suburb": "Patterson Lakes",
        "postcode": "3197",
        "state": "Victoria"
    },
    {
        "suburb": "Bayswater",
        "postcode": "3153",
        "state": "Victoria"
    },
    {
        "suburb": "Boronia",
        "postcode": "3155",
        "state": "Victoria"
    },
    {
        "suburb": "Ferntree Gully",
        "postcode": "3156",
        "state": "Victoria"
    },
    {
        "suburb": "Upper Ferntree Gully",
        "postcode": "3156",
        "state": "Victoria"
    },
    {
        "suburb": "The Basin",
        "postcode": "3154",
        "state": "Victoria"
    },
    {
        "suburb": "Wantirna South",
        "postcode": "3152",
        "state": "Victoria"
    },
    {
        "suburb": "Scoresby",
        "postcode": "3179",
        "state": "Victoria"
    },
    {
        "suburb": "Wantirna",
        "postcode": "3152",
        "state": "Victoria"
    },
    {
        "suburb": "Knoxfield",
        "postcode": "3180",
        "state": "Victoria"
    },
    {
        "suburb": "Rowville",
        "postcode": "3178",
        "state": "Victoria"
    },
    {
        "suburb": "Lysterfield",
        "postcode": "3156",
        "state": "Victoria"
    },
    {
        "suburb": "Yallourn North",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Newborough",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Yallourn",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Hernes Oak",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Morwell",
        "postcode": "3840",
        "state": "Victoria"
    },
    {
        "suburb": "Delburn",
        "postcode": "3871",
        "state": "Victoria"
    },
    {
        "suburb": "Boolarra",
        "postcode": "3870",
        "state": "Victoria"
    },
    {
        "suburb": "Hazelwood",
        "postcode": "3840",
        "state": "Victoria"
    },
    {
        "suburb": "Churchill",
        "postcode": "3842",
        "state": "Victoria"
    },
    {
        "suburb": "Jeeralang",
        "postcode": "3840",
        "state": "Victoria"
    },
    {
        "suburb": "Yinnar",
        "postcode": "3869",
        "state": "Victoria"
    },
    {
        "suburb": "Yinnar South",
        "postcode": "3869",
        "state": "Victoria"
    },
    {
        "suburb": "Jeeralang Junction",
        "postcode": "3840",
        "state": "Victoria"
    },
    {
        "suburb": "Jumbuk",
        "postcode": "3869",
        "state": "Victoria"
    },
    {
        "suburb": "Maryvale",
        "postcode": "3840",
        "state": "Victoria"
    },
    {
        "suburb": "Driffield",
        "postcode": "3840",
        "state": "Victoria"
    },
    {
        "suburb": "Traralgon",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Glengarry",
        "postcode": "3854",
        "state": "Victoria"
    },
    {
        "suburb": "Traralgon East",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Toongabbie",
        "postcode": "3856",
        "state": "Victoria"
    },
    {
        "suburb": "Hazelwood North",
        "postcode": "3840",
        "state": "Victoria"
    },
    {
        "suburb": "Traralgon South",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Tyers",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Glengarry North",
        "postcode": "3854",
        "state": "Victoria"
    },
    {
        "suburb": "Glengarry West",
        "postcode": "3854",
        "state": "Victoria"
    },
    {
        "suburb": "Flynn",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Flynns Creek",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Loy Yang",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Hazelwood South",
        "postcode": "3840",
        "state": "Victoria"
    },
    {
        "suburb": "Callignee",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Boolarra South",
        "postcode": "3870",
        "state": "Victoria"
    },
    {
        "suburb": "Mirboo",
        "postcode": "3871",
        "state": "Victoria"
    },
    {
        "suburb": "Grand Ridge",
        "postcode": "3870",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Tassie",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Koornalla",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Balook",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Budgeree",
        "postcode": "3870",
        "state": "Victoria"
    },
    {
        "suburb": "Yando",
        "postcode": "3537",
        "state": "Victoria"
    },
    {
        "suburb": "Leaghur",
        "postcode": "3537",
        "state": "Victoria"
    },
    {
        "suburb": "Boort",
        "postcode": "3537",
        "state": "Victoria"
    },
    {
        "suburb": "Serpentine",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Pyramid Hill",
        "postcode": "3575",
        "state": "Victoria"
    },
    {
        "suburb": "Calivil",
        "postcode": "3573",
        "state": "Victoria"
    },
    {
        "suburb": "Mologa",
        "postcode": "3575",
        "state": "Victoria"
    },
    {
        "suburb": "Yarrawalla",
        "postcode": "3575",
        "state": "Victoria"
    },
    {
        "suburb": "Sylvaterre",
        "postcode": "3575",
        "state": "Victoria"
    },
    {
        "suburb": "Terrick Terrick",
        "postcode": "3575",
        "state": "Victoria"
    },
    {
        "suburb": "Jungaburra",
        "postcode": "3575",
        "state": "Victoria"
    },
    {
        "suburb": "Minmindie",
        "postcode": "3537",
        "state": "Victoria"
    },
    {
        "suburb": "Prairie",
        "postcode": "3572",
        "state": "Victoria"
    },
    {
        "suburb": "Bears Lagoon",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Wychitella North",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Barraport West",
        "postcode": "3537",
        "state": "Victoria"
    },
    {
        "suburb": "Barraport",
        "postcode": "3537",
        "state": "Victoria"
    },
    {
        "suburb": "Dingee",
        "postcode": "3571",
        "state": "Victoria"
    },
    {
        "suburb": "Pompapiel",
        "postcode": "3571",
        "state": "Victoria"
    },
    {
        "suburb": "Auchmore",
        "postcode": "3570",
        "state": "Victoria"
    },
    {
        "suburb": "Tandarra",
        "postcode": "3571",
        "state": "Victoria"
    },
    {
        "suburb": "Kamarooka North",
        "postcode": "3571",
        "state": "Victoria"
    },
    {
        "suburb": "Durham Ox",
        "postcode": "3576",
        "state": "Victoria"
    },
    {
        "suburb": "Jarklin",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Fernihurst",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Gladfield",
        "postcode": "3575",
        "state": "Victoria"
    },
    {
        "suburb": "Loddon Vale",
        "postcode": "3575",
        "state": "Victoria"
    },
    {
        "suburb": "Canary Island",
        "postcode": "3537",
        "state": "Victoria"
    },
    {
        "suburb": "Mincha",
        "postcode": "3575",
        "state": "Victoria"
    },
    {
        "suburb": "Emu",
        "postcode": "3475",
        "state": "Victoria"
    },
    {
        "suburb": "Inglewood",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Laanecoorie",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Wedderburn",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Borung",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Brenanah",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Wychitella",
        "postcode": "3525",
        "state": "Victoria"
    },
    {
        "suburb": "Rheola",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Murphys Creek",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Tarnagulla",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Bridgewater On Loddon",
        "postcode": "3516",
        "state": "Victoria"
    },
    {
        "suburb": "Logan",
        "postcode": "3475",
        "state": "Victoria"
    },
    {
        "suburb": "Burkes Flat",
        "postcode": "3475",
        "state": "Victoria"
    },
    {
        "suburb": "Wehla",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Woodstock West",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Gowar East",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Berrimal",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Waanyarra",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Newbridge",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Llanelly",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Arnold West",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Arnold",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Glenalbyn",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Painswick",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Salisbury West",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Kurraca West",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Kurraca",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Kingower",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Fentons Creek",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Bridgewater North",
        "postcode": "3516",
        "state": "Victoria"
    },
    {
        "suburb": "Yarraberb",
        "postcode": "3516",
        "state": "Victoria"
    },
    {
        "suburb": "Eastville",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Powlett Plains",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Kurting",
        "postcode": "3517",
        "state": "Victoria"
    },
    {
        "suburb": "Wedderburn Junction",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Fiery Flat",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Woodstock On Loddon",
        "postcode": "3551",
        "state": "Victoria"
    },
    {
        "suburb": "Derby",
        "postcode": "3516",
        "state": "Victoria"
    },
    {
        "suburb": "Campbells Forest",
        "postcode": "3556",
        "state": "Victoria"
    },
    {
        "suburb": "Bridgewater",
        "postcode": "3516",
        "state": "Victoria"
    },
    {
        "suburb": "Korong Vale",
        "postcode": "3520",
        "state": "Victoria"
    },
    {
        "suburb": "Kinypanial",
        "postcode": "3520",
        "state": "Victoria"
    },
    {
        "suburb": "Woolshed Flat",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Kyneton",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Malmsbury",
        "postcode": "3446",
        "state": "Victoria"
    },
    {
        "suburb": "Lauriston",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Benloch",
        "postcode": "3435",
        "state": "Victoria"
    },
    {
        "suburb": "Sidonia",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Pastoria East",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Baynton",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Pastoria",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Pipers Creek",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Carlsruhe",
        "postcode": "3442",
        "state": "Victoria"
    },
    {
        "suburb": "Kyneton South",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Greenhill",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Edgecombe",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Lancefield",
        "postcode": "3435",
        "state": "Victoria"
    },
    {
        "suburb": "Bolinda",
        "postcode": "3432",
        "state": "Victoria"
    },
    {
        "suburb": "Romsey",
        "postcode": "3434",
        "state": "Victoria"
    },
    {
        "suburb": "Riddells Creek",
        "postcode": "3431",
        "state": "Victoria"
    },
    {
        "suburb": "Rochford",
        "postcode": "3442",
        "state": "Victoria"
    },
    {
        "suburb": "New Gisborne",
        "postcode": "3438",
        "state": "Victoria"
    },
    {
        "suburb": "Kerrie",
        "postcode": "3434",
        "state": "Victoria"
    },
    {
        "suburb": "Springfield",
        "postcode": "3434",
        "state": "Victoria"
    },
    {
        "suburb": "Goldie",
        "postcode": "3435",
        "state": "Victoria"
    },
    {
        "suburb": "Monegeetta",
        "postcode": "3433",
        "state": "Victoria"
    },
    {
        "suburb": "Chintin",
        "postcode": "3756",
        "state": "Victoria"
    },
    {
        "suburb": "Darraweit Guim",
        "postcode": "3756",
        "state": "Victoria"
    },
    {
        "suburb": "Bylands",
        "postcode": "3762",
        "state": "Victoria"
    },
    {
        "suburb": "Cherokee",
        "postcode": "3434",
        "state": "Victoria"
    },
    {
        "suburb": "Hesket",
        "postcode": "3442",
        "state": "Victoria"
    },
    {
        "suburb": "Gisborne",
        "postcode": "3437",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Macedon",
        "postcode": "3441",
        "state": "Victoria"
    },
    {
        "suburb": "Woodend",
        "postcode": "3442",
        "state": "Victoria"
    },
    {
        "suburb": "Macedon",
        "postcode": "3440",
        "state": "Victoria"
    },
    {
        "suburb": "Bullengarook",
        "postcode": "3437",
        "state": "Victoria"
    },
    {
        "suburb": "Newham",
        "postcode": "3442",
        "state": "Victoria"
    },
    {
        "suburb": "Gisborne South",
        "postcode": "3437",
        "state": "Victoria"
    },
    {
        "suburb": "Ashbourne",
        "postcode": "3442",
        "state": "Victoria"
    },
    {
        "suburb": "Woodend North",
        "postcode": "3442",
        "state": "Victoria"
    },
    {
        "suburb": "Cadello",
        "postcode": "3442",
        "state": "Victoria"
    },
    {
        "suburb": "Cobaw",
        "postcode": "3442",
        "state": "Victoria"
    },
    {
        "suburb": "Park Orchards",
        "postcode": "3114",
        "state": "Victoria"
    },
    {
        "suburb": "Wonga Park",
        "postcode": "3115",
        "state": "Victoria"
    },
    {
        "suburb": "Warrandyte",
        "postcode": "3113",
        "state": "Victoria"
    },
    {
        "suburb": "Warrandyte South",
        "postcode": "3134",
        "state": "Victoria"
    },
    {
        "suburb": "Donvale",
        "postcode": "3111",
        "state": "Victoria"
    },
    {
        "suburb": "Ringwood North",
        "postcode": "3134",
        "state": "Victoria"
    },
    {
        "suburb": "Doncaster East",
        "postcode": "3109",
        "state": "Victoria"
    },
    {
        "suburb": "Templestowe",
        "postcode": "3106",
        "state": "Victoria"
    },
    {
        "suburb": "Doncaster",
        "postcode": "3108",
        "state": "Victoria"
    },
    {
        "suburb": "Bulleen",
        "postcode": "3105",
        "state": "Victoria"
    },
    {
        "suburb": "Templestowe Lower",
        "postcode": "3107",
        "state": "Victoria"
    },
    {
        "suburb": "Nunawading",
        "postcode": "3131",
        "state": "Victoria"
    },
    {
        "suburb": "Mansfield",
        "postcode": "3722",
        "state": "Victoria"
    },
    {
        "suburb": "Bonnie Doon",
        "postcode": "3720",
        "state": "Victoria"
    },
    {
        "suburb": "Goughs Bay",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Howqua Inlet",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Macs Cove",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Merrijig",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Jamieson",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Piries",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Barjarg",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Ancona",
        "postcode": "3715",
        "state": "Victoria"
    },
    {
        "suburb": "Howes Creek",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Gaffneys Creek",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Buller",
        "postcode": "3722",
        "state": "Victoria"
    },
    {
        "suburb": "Tolmie",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Howqua",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Boorolite",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Delatite",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Sawmill Settlement",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Barwite",
        "postcode": "3722",
        "state": "Victoria"
    },
    {
        "suburb": "Matlock",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Reynard",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Howqua Hills",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Eildon",
        "postcode": "3713",
        "state": "Victoria"
    },
    {
        "suburb": "Merton",
        "postcode": "3715",
        "state": "Victoria"
    },
    {
        "suburb": "Kevington",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Licola North",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Howitt Plains",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Knockwood",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Enochs Point",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Woodfield",
        "postcode": "3715",
        "state": "Victoria"
    },
    {
        "suburb": "Footscray",
        "postcode": "3011",
        "state": "Victoria"
    },
    {
        "suburb": "Yarraville",
        "postcode": "3013",
        "state": "Victoria"
    },
    {
        "suburb": "Seddon",
        "postcode": "3011",
        "state": "Victoria"
    },
    {
        "suburb": "Kingsville",
        "postcode": "3012",
        "state": "Victoria"
    },
    {
        "suburb": "West Footscray",
        "postcode": "3012",
        "state": "Victoria"
    },
    {
        "suburb": "Maidstone",
        "postcode": "3012",
        "state": "Victoria"
    },
    {
        "suburb": "Braybrook",
        "postcode": "3019",
        "state": "Victoria"
    },
    {
        "suburb": "Maribyrnong",
        "postcode": "3032",
        "state": "Victoria"
    },
    {
        "suburb": "Tottenham",
        "postcode": "3012",
        "state": "Victoria"
    },
    {
        "suburb": "Croydon",
        "postcode": "3136",
        "state": "Victoria"
    },
    {
        "suburb": "Warranwood",
        "postcode": "3134",
        "state": "Victoria"
    },
    {
        "suburb": "Bayswater North",
        "postcode": "3153",
        "state": "Victoria"
    },
    {
        "suburb": "Croydon Hills",
        "postcode": "3136",
        "state": "Victoria"
    },
    {
        "suburb": "Croydon North",
        "postcode": "3136",
        "state": "Victoria"
    },
    {
        "suburb": "Ringwood East",
        "postcode": "3135",
        "state": "Victoria"
    },
    {
        "suburb": "Croydon South",
        "postcode": "3136",
        "state": "Victoria"
    },
    {
        "suburb": "Kilsyth",
        "postcode": "3137",
        "state": "Victoria"
    },
    {
        "suburb": "Kilsyth South",
        "postcode": "3137",
        "state": "Victoria"
    },
    {
        "suburb": "Heathmont",
        "postcode": "3135",
        "state": "Victoria"
    },
    {
        "suburb": "Ringwood",
        "postcode": "3134",
        "state": "Victoria"
    },
    {
        "suburb": "Vermont",
        "postcode": "3133",
        "state": "Victoria"
    },
    {
        "suburb": "Melbourne",
        "postcode": "3000",
        "state": "Victoria"
    },
    {
        "suburb": "Docklands",
        "postcode": "3008",
        "state": "Victoria"
    },
    {
        "suburb": "Southbank",
        "postcode": "3006",
        "state": "Victoria"
    },
    {
        "suburb": "South Wharf",
        "postcode": "3006",
        "state": "Victoria"
    },
    {
        "suburb": "East Melbourne",
        "postcode": "3002",
        "state": "Victoria"
    },
    {
        "suburb": "South Yarra",
        "postcode": "3141",
        "state": "Victoria"
    },
    {
        "suburb": "Carlton",
        "postcode": "3053",
        "state": "Victoria"
    },
    {
        "suburb": "North Melbourne",
        "postcode": "3051",
        "state": "Victoria"
    },
    {
        "suburb": "Parkville",
        "postcode": "3052",
        "state": "Victoria"
    },
    {
        "suburb": "Kensington",
        "postcode": "3031",
        "state": "Victoria"
    },
    {
        "suburb": "West Melbourne",
        "postcode": "3003",
        "state": "Victoria"
    },
    {
        "suburb": "Port Melbourne",
        "postcode": "3207",
        "state": "Victoria"
    },
    {
        "suburb": "Carlton North",
        "postcode": "3054",
        "state": "Victoria"
    },
    {
        "suburb": "Flemington",
        "postcode": "3031",
        "state": "Victoria"
    },
    {
        "suburb": "Caroline Springs",
        "postcode": "3023",
        "state": "Victoria"
    },
    {
        "suburb": "Taylors Hill",
        "postcode": "3037",
        "state": "Victoria"
    },
    {
        "suburb": "Burnside",
        "postcode": "3023",
        "state": "Victoria"
    },
    {
        "suburb": "Burnside Heights",
        "postcode": "3023",
        "state": "Victoria"
    },
    {
        "suburb": "Rockbank",
        "postcode": "3335",
        "state": "Victoria"
    },
    {
        "suburb": "Ravenhall",
        "postcode": "3023",
        "state": "Victoria"
    },
    {
        "suburb": "Plumpton",
        "postcode": "3335",
        "state": "Victoria"
    },
    {
        "suburb": "Melton",
        "postcode": "3337",
        "state": "Victoria"
    },
    {
        "suburb": "Melton West",
        "postcode": "3337",
        "state": "Victoria"
    },
    {
        "suburb": "Kurunjang",
        "postcode": "3337",
        "state": "Victoria"
    },
    {
        "suburb": "Brookfield",
        "postcode": "3338",
        "state": "Victoria"
    },
    {
        "suburb": "Melton South",
        "postcode": "3338",
        "state": "Victoria"
    },
    {
        "suburb": "Toolern Vale",
        "postcode": "3337",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Cottrell",
        "postcode": "3024",
        "state": "Victoria"
    },
    {
        "suburb": "Truganina",
        "postcode": "3029",
        "state": "Victoria"
    },
    {
        "suburb": "Parwan",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Exford",
        "postcode": "3338",
        "state": "Victoria"
    },
    {
        "suburb": "Mildura",
        "postcode": "3500",
        "state": "Victoria"
    },
    {
        "suburb": "Merbein",
        "postcode": "3505",
        "state": "Victoria"
    },
    {
        "suburb": "Cabarita",
        "postcode": "3505",
        "state": "Victoria"
    },
    {
        "suburb": "Irymple",
        "postcode": "3498",
        "state": "Victoria"
    },
    {
        "suburb": "Nichols Point",
        "postcode": "3501",
        "state": "Victoria"
    },
    {
        "suburb": "Cardross",
        "postcode": "3496",
        "state": "Victoria"
    },
    {
        "suburb": "Koorlong",
        "postcode": "3501",
        "state": "Victoria"
    },
    {
        "suburb": "Red Cliffs",
        "postcode": "3496",
        "state": "Victoria"
    },
    {
        "suburb": "Birdwoodton",
        "postcode": "3505",
        "state": "Victoria"
    },
    {
        "suburb": "Merbein South",
        "postcode": "3505",
        "state": "Victoria"
    },
    {
        "suburb": "Merbein West",
        "postcode": "3505",
        "state": "Victoria"
    },
    {
        "suburb": "Yelta",
        "postcode": "3505",
        "state": "Victoria"
    },
    {
        "suburb": "Iraak",
        "postcode": "3494",
        "state": "Victoria"
    },
    {
        "suburb": "Carwarp",
        "postcode": "3494",
        "state": "Victoria"
    },
    {
        "suburb": "Murray-Sunset",
        "postcode": "3490",
        "state": "Victoria"
    },
    {
        "suburb": "Neds Corner",
        "postcode": "3496",
        "state": "Victoria"
    },
    {
        "suburb": "Ouyen",
        "postcode": "3490",
        "state": "Victoria"
    },
    {
        "suburb": "Underbool",
        "postcode": "3509",
        "state": "Victoria"
    },
    {
        "suburb": "Torrita",
        "postcode": "3490",
        "state": "Victoria"
    },
    {
        "suburb": "Colignan",
        "postcode": "3494",
        "state": "Victoria"
    },
    {
        "suburb": "Cullulleraine",
        "postcode": "3496",
        "state": "Victoria"
    },
    {
        "suburb": "Kulwin",
        "postcode": "3490",
        "state": "Victoria"
    },
    {
        "suburb": "Carina",
        "postcode": "3512",
        "state": "Victoria"
    },
    {
        "suburb": "Murrayville",
        "postcode": "3512",
        "state": "Victoria"
    },
    {
        "suburb": "Werrimull",
        "postcode": "3496",
        "state": "Victoria"
    },
    {
        "suburb": "Mittyack",
        "postcode": "3490",
        "state": "Victoria"
    },
    {
        "suburb": "Boinka",
        "postcode": "3490",
        "state": "Victoria"
    },
    {
        "suburb": "Linga",
        "postcode": "3509",
        "state": "Victoria"
    },
    {
        "suburb": "Tutye",
        "postcode": "3490",
        "state": "Victoria"
    },
    {
        "suburb": "Nangiloc",
        "postcode": "3494",
        "state": "Victoria"
    },
    {
        "suburb": "Panitya",
        "postcode": "3512",
        "state": "Victoria"
    },
    {
        "suburb": "Hattah",
        "postcode": "3501",
        "state": "Victoria"
    },
    {
        "suburb": "Tempy",
        "postcode": "3489",
        "state": "Victoria"
    },
    {
        "suburb": "Walpeup",
        "postcode": "3507",
        "state": "Victoria"
    },
    {
        "suburb": "Meringur",
        "postcode": "3496",
        "state": "Victoria"
    },
    {
        "suburb": "Lindsay Point",
        "postcode": "3496",
        "state": "Victoria"
    },
    {
        "suburb": "Seymour",
        "postcode": "3660",
        "state": "Victoria"
    },
    {
        "suburb": "Tallarook",
        "postcode": "3659",
        "state": "Victoria"
    },
    {
        "suburb": "Hilldene",
        "postcode": "3660",
        "state": "Victoria"
    },
    {
        "suburb": "Tooborac",
        "postcode": "3522",
        "state": "Victoria"
    },
    {
        "suburb": "Puckapunyal",
        "postcode": "3662",
        "state": "Victoria"
    },
    {
        "suburb": "Northwood",
        "postcode": "3660",
        "state": "Victoria"
    },
    {
        "suburb": "Whiteheads Creek",
        "postcode": "3660",
        "state": "Victoria"
    },
    {
        "suburb": "Tarcombe",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Highlands",
        "postcode": "3660",
        "state": "Victoria"
    },
    {
        "suburb": "High Camp",
        "postcode": "3764",
        "state": "Victoria"
    },
    {
        "suburb": "Moranding",
        "postcode": "3764",
        "state": "Victoria"
    },
    {
        "suburb": "Heathcote South",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Glenhope",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Glenhope East",
        "postcode": "3522",
        "state": "Victoria"
    },
    {
        "suburb": "Pyalong",
        "postcode": "3521",
        "state": "Victoria"
    },
    {
        "suburb": "Avenel",
        "postcode": "3664",
        "state": "Victoria"
    },
    {
        "suburb": "Mangalore",
        "postcode": "3663",
        "state": "Victoria"
    },
    {
        "suburb": "Sugarloaf Creek",
        "postcode": "3658",
        "state": "Victoria"
    },
    {
        "suburb": "Glenaroua",
        "postcode": "3764",
        "state": "Victoria"
    },
    {
        "suburb": "Nulla Vale",
        "postcode": "3435",
        "state": "Victoria"
    },
    {
        "suburb": "Trawool",
        "postcode": "3660",
        "state": "Victoria"
    },
    {
        "suburb": "Wallan",
        "postcode": "3756",
        "state": "Victoria"
    },
    {
        "suburb": "Broadford",
        "postcode": "3658",
        "state": "Victoria"
    },
    {
        "suburb": "Heathcote Junction",
        "postcode": "3758",
        "state": "Victoria"
    },
    {
        "suburb": "Wandong",
        "postcode": "3758",
        "state": "Victoria"
    },
    {
        "suburb": "Kilmore",
        "postcode": "3764",
        "state": "Victoria"
    },
    {
        "suburb": "Sunday Creek",
        "postcode": "3658",
        "state": "Victoria"
    },
    {
        "suburb": "Willowmavin",
        "postcode": "3764",
        "state": "Victoria"
    },
    {
        "suburb": "Forbes",
        "postcode": "3764",
        "state": "Victoria"
    },
    {
        "suburb": "Kilmore East",
        "postcode": "3764",
        "state": "Victoria"
    },
    {
        "suburb": "Reedy Creek",
        "postcode": "3658",
        "state": "Victoria"
    },
    {
        "suburb": "Clonbinane",
        "postcode": "3658",
        "state": "Victoria"
    },
    {
        "suburb": "Upper Plenty",
        "postcode": "3756",
        "state": "Victoria"
    },
    {
        "suburb": "Waterford Park",
        "postcode": "3658",
        "state": "Victoria"
    },
    {
        "suburb": "Tyaak",
        "postcode": "3658",
        "state": "Victoria"
    },
    {
        "suburb": "Yarrawonga",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "Tungamah",
        "postcode": "3728",
        "state": "Victoria"
    },
    {
        "suburb": "Burramine",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "Wilby",
        "postcode": "3728",
        "state": "Victoria"
    },
    {
        "suburb": "Bundalong",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "St James",
        "postcode": "3727",
        "state": "Victoria"
    },
    {
        "suburb": "Telford",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "Yabba North",
        "postcode": "3646",
        "state": "Victoria"
    },
    {
        "suburb": "Burramine South",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "Yarrawonga South",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "Pelluebla",
        "postcode": "3727",
        "state": "Victoria"
    },
    {
        "suburb": "Boosey",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "Esmond",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "Bundalong South",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "Peechelba",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Yundool",
        "postcode": "3727",
        "state": "Victoria"
    },
    {
        "suburb": "Invergordon",
        "postcode": "3636",
        "state": "Victoria"
    },
    {
        "suburb": "Youanmite",
        "postcode": "3646",
        "state": "Victoria"
    },
    {
        "suburb": "Youarang",
        "postcode": "3728",
        "state": "Victoria"
    },
    {
        "suburb": "Katamatite East",
        "postcode": "3649",
        "state": "Victoria"
    },
    {
        "suburb": "Katamatite",
        "postcode": "3649",
        "state": "Victoria"
    },
    {
        "suburb": "Cobram East",
        "postcode": "3644",
        "state": "Victoria"
    },
    {
        "suburb": "Bathumi",
        "postcode": "3730",
        "state": "Victoria"
    },
    {
        "suburb": "Boomahnoomoonah",
        "postcode": "3728",
        "state": "Victoria"
    },
    {
        "suburb": "Almonds",
        "postcode": "3727",
        "state": "Victoria"
    },
    {
        "suburb": "Boweya North",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Rowan",
        "postcode": "3727",
        "state": "Victoria"
    },
    {
        "suburb": "Waggarandall",
        "postcode": "3646",
        "state": "Victoria"
    },
    {
        "suburb": "Yabba South",
        "postcode": "3646",
        "state": "Victoria"
    },
    {
        "suburb": "Cobram",
        "postcode": "3644",
        "state": "Victoria"
    },
    {
        "suburb": "Wunghnu",
        "postcode": "3635",
        "state": "Victoria"
    },
    {
        "suburb": "Strathmerton",
        "postcode": "3641",
        "state": "Victoria"
    },
    {
        "suburb": "Numurkah",
        "postcode": "3636",
        "state": "Victoria"
    },
    {
        "suburb": "Katunga",
        "postcode": "3640",
        "state": "Victoria"
    },
    {
        "suburb": "Nathalia",
        "postcode": "3638",
        "state": "Victoria"
    },
    {
        "suburb": "Barmah",
        "postcode": "3639",
        "state": "Victoria"
    },
    {
        "suburb": "Ulupna",
        "postcode": "3641",
        "state": "Victoria"
    },
    {
        "suburb": "Koonoomoo",
        "postcode": "3644",
        "state": "Victoria"
    },
    {
        "suburb": "Lower Moira",
        "postcode": "3639",
        "state": "Victoria"
    },
    {
        "suburb": "Yarroweyah",
        "postcode": "3644",
        "state": "Victoria"
    },
    {
        "suburb": "Waaia",
        "postcode": "3637",
        "state": "Victoria"
    },
    {
        "suburb": "Picola",
        "postcode": "3639",
        "state": "Victoria"
    },
    {
        "suburb": "Picola West",
        "postcode": "3639",
        "state": "Victoria"
    },
    {
        "suburb": "Yielima",
        "postcode": "3638",
        "state": "Victoria"
    },
    {
        "suburb": "Kotupna",
        "postcode": "3638",
        "state": "Victoria"
    },
    {
        "suburb": "Yalca",
        "postcode": "3637",
        "state": "Victoria"
    },
    {
        "suburb": "Kaarimba",
        "postcode": "3635",
        "state": "Victoria"
    },
    {
        "suburb": "Mundoona",
        "postcode": "3635",
        "state": "Victoria"
    },
    {
        "suburb": "Bearii",
        "postcode": "3641",
        "state": "Victoria"
    },
    {
        "suburb": "Mywee",
        "postcode": "3641",
        "state": "Victoria"
    },
    {
        "suburb": "Drumanure",
        "postcode": "3636",
        "state": "Victoria"
    },
    {
        "suburb": "Naring",
        "postcode": "3636",
        "state": "Victoria"
    },
    {
        "suburb": "Muckatah",
        "postcode": "3644",
        "state": "Victoria"
    },
    {
        "suburb": "Chadstone",
        "postcode": "3148",
        "state": "Victoria"
    },
    {
        "suburb": "Oakleigh East",
        "postcode": "3166",
        "state": "Victoria"
    },
    {
        "suburb": "Hughesdale",
        "postcode": "3166",
        "state": "Victoria"
    },
    {
        "suburb": "Clayton",
        "postcode": "3168",
        "state": "Victoria"
    },
    {
        "suburb": "Huntingdale",
        "postcode": "3166",
        "state": "Victoria"
    },
    {
        "suburb": "Oakleigh",
        "postcode": "3166",
        "state": "Victoria"
    },
    {
        "suburb": "Mulgrave",
        "postcode": "3170",
        "state": "Victoria"
    },
    {
        "suburb": "Wheelers Hill",
        "postcode": "3150",
        "state": "Victoria"
    },
    {
        "suburb": "Glen Waverley",
        "postcode": "3150",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Waverley",
        "postcode": "3149",
        "state": "Victoria"
    },
    {
        "suburb": "Ashwood",
        "postcode": "3147",
        "state": "Victoria"
    },
    {
        "suburb": "Notting Hill",
        "postcode": "3168",
        "state": "Victoria"
    },
    {
        "suburb": "Burwood",
        "postcode": "3125",
        "state": "Victoria"
    },
    {
        "suburb": "Travancore",
        "postcode": "3032",
        "state": "Victoria"
    },
    {
        "suburb": "Ascot Vale",
        "postcode": "3032",
        "state": "Victoria"
    },
    {
        "suburb": "Moonee Ponds",
        "postcode": "3039",
        "state": "Victoria"
    },
    {
        "suburb": "Essendon",
        "postcode": "3040",
        "state": "Victoria"
    },
    {
        "suburb": "Strathmore",
        "postcode": "3041",
        "state": "Victoria"
    },
    {
        "suburb": "Essendon North",
        "postcode": "3041",
        "state": "Victoria"
    },
    {
        "suburb": "Aberfeldie",
        "postcode": "3040",
        "state": "Victoria"
    },
    {
        "suburb": "Niddrie",
        "postcode": "3042",
        "state": "Victoria"
    },
    {
        "suburb": "Airport West",
        "postcode": "3042",
        "state": "Victoria"
    },
    {
        "suburb": "Strathmore Heights",
        "postcode": "3041",
        "state": "Victoria"
    },
    {
        "suburb": "Avondale Heights",
        "postcode": "3034",
        "state": "Victoria"
    },
    {
        "suburb": "Essendon West",
        "postcode": "3040",
        "state": "Victoria"
    },
    {
        "suburb": "Essendon Fields",
        "postcode": "3041",
        "state": "Victoria"
    },
    {
        "suburb": "Bacchus Marsh",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Darley",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Maddingley",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Merrimu",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Long Forest",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Coimadai",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Myrniong",
        "postcode": "3341",
        "state": "Victoria"
    },
    {
        "suburb": "Hopetoun Park",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Rowsley",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Balliang East",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Lerderderg",
        "postcode": "3458",
        "state": "Victoria"
    },
    {
        "suburb": "Greendale",
        "postcode": "3341",
        "state": "Victoria"
    },
    {
        "suburb": "Blackwood",
        "postcode": "3458",
        "state": "Victoria"
    },
    {
        "suburb": "Barrys Reef",
        "postcode": "3458",
        "state": "Victoria"
    },
    {
        "suburb": "Gordon",
        "postcode": "3345",
        "state": "Victoria"
    },
    {
        "suburb": "Ballan",
        "postcode": "3342",
        "state": "Victoria"
    },
    {
        "suburb": "Beremboke",
        "postcode": "3342",
        "state": "Victoria"
    },
    {
        "suburb": "Bungal",
        "postcode": "3334",
        "state": "Victoria"
    },
    {
        "suburb": "Morrisons",
        "postcode": "3334",
        "state": "Victoria"
    },
    {
        "suburb": "Ingliston",
        "postcode": "3342",
        "state": "Victoria"
    },
    {
        "suburb": "Korweinguboora",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Spargo Creek",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Bunding",
        "postcode": "3342",
        "state": "Victoria"
    },
    {
        "suburb": "Colbrook",
        "postcode": "3342",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Egerton",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Fiskville",
        "postcode": "3342",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Wallace",
        "postcode": "3342",
        "state": "Victoria"
    },
    {
        "suburb": "Dales Creek",
        "postcode": "3341",
        "state": "Victoria"
    },
    {
        "suburb": "Bolwarrah",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Dunnstown",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Millbrook",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Springbank",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Pootilla",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Navigators",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Yendon",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Lal Lal",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Clarendon",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Cargerie",
        "postcode": "3334",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Doran",
        "postcode": "3334",
        "state": "Victoria"
    },
    {
        "suburb": "Bungaree",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Bullarook",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Claretown",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Leigh Creek",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Clarkes Hill",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Barkstead",
        "postcode": "3364",
        "state": "Victoria"
    },
    {
        "suburb": "Elaine",
        "postcode": "3334",
        "state": "Victoria"
    },
    {
        "suburb": "Wallace",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Brunswick East",
        "postcode": "3057",
        "state": "Victoria"
    },
    {
        "suburb": "Brunswick",
        "postcode": "3056",
        "state": "Victoria"
    },
    {
        "suburb": "Brunswick West",
        "postcode": "3055",
        "state": "Victoria"
    },
    {
        "suburb": "Fitzroy North",
        "postcode": "3068",
        "state": "Victoria"
    },
    {
        "suburb": "Pascoe Vale South",
        "postcode": "3044",
        "state": "Victoria"
    },
    {
        "suburb": "Pascoe Vale",
        "postcode": "3044",
        "state": "Victoria"
    },
    {
        "suburb": "Coburg",
        "postcode": "3058",
        "state": "Victoria"
    },
    {
        "suburb": "Coburg North",
        "postcode": "3058",
        "state": "Victoria"
    },
    {
        "suburb": "Hadfield",
        "postcode": "3046",
        "state": "Victoria"
    },
    {
        "suburb": "Glenroy",
        "postcode": "3046",
        "state": "Victoria"
    },
    {
        "suburb": "Oak Park",
        "postcode": "3046",
        "state": "Victoria"
    },
    {
        "suburb": "Gowanbrae",
        "postcode": "3043",
        "state": "Victoria"
    },
    {
        "suburb": "Balnarring Beach",
        "postcode": "3926",
        "state": "Victoria"
    },
    {
        "suburb": "Baxter",
        "postcode": "3911",
        "state": "Victoria"
    },
    {
        "suburb": "Somerville",
        "postcode": "3912",
        "state": "Victoria"
    },
    {
        "suburb": "Somers",
        "postcode": "3927",
        "state": "Victoria"
    },
    {
        "suburb": "Hastings",
        "postcode": "3915",
        "state": "Victoria"
    },
    {
        "suburb": "Tyabb",
        "postcode": "3913",
        "state": "Victoria"
    },
    {
        "suburb": "Crib Point",
        "postcode": "3919",
        "state": "Victoria"
    },
    {
        "suburb": "Tuerong",
        "postcode": "3915",
        "state": "Victoria"
    },
    {
        "suburb": "Bittern",
        "postcode": "3918",
        "state": "Victoria"
    },
    {
        "suburb": "Balnarring",
        "postcode": "3926",
        "state": "Victoria"
    },
    {
        "suburb": "Hmas Cerberus",
        "postcode": "3920",
        "state": "Victoria"
    },
    {
        "suburb": "Merricks Beach",
        "postcode": "3926",
        "state": "Victoria"
    },
    {
        "suburb": "Point Leo",
        "postcode": "3916",
        "state": "Victoria"
    },
    {
        "suburb": "Red Hill South",
        "postcode": "3937",
        "state": "Victoria"
    },
    {
        "suburb": "Red Hill",
        "postcode": "3937",
        "state": "Victoria"
    },
    {
        "suburb": "Moorooduc",
        "postcode": "3933",
        "state": "Victoria"
    },
    {
        "suburb": "Merricks",
        "postcode": "3916",
        "state": "Victoria"
    },
    {
        "suburb": "Shoreham",
        "postcode": "3916",
        "state": "Victoria"
    },
    {
        "suburb": "Merricks North",
        "postcode": "3926",
        "state": "Victoria"
    },
    {
        "suburb": "Dromana",
        "postcode": "3936",
        "state": "Victoria"
    },
    {
        "suburb": "Safety Beach",
        "postcode": "3936",
        "state": "Victoria"
    },
    {
        "suburb": "Flinders",
        "postcode": "3929",
        "state": "Victoria"
    },
    {
        "suburb": "McCrae",
        "postcode": "3938",
        "state": "Victoria"
    },
    {
        "suburb": "Rosebud",
        "postcode": "3939",
        "state": "Victoria"
    },
    {
        "suburb": "Arthurs Seat",
        "postcode": "3936",
        "state": "Victoria"
    },
    {
        "suburb": "Blairgowrie",
        "postcode": "3942",
        "state": "Victoria"
    },
    {
        "suburb": "Rye",
        "postcode": "3941",
        "state": "Victoria"
    },
    {
        "suburb": "Tootgarook",
        "postcode": "3941",
        "state": "Victoria"
    },
    {
        "suburb": "Rosebud West",
        "postcode": "3940",
        "state": "Victoria"
    },
    {
        "suburb": "Boneo",
        "postcode": "3939",
        "state": "Victoria"
    },
    {
        "suburb": "Sorrento",
        "postcode": "3943",
        "state": "Victoria"
    },
    {
        "suburb": "Portsea",
        "postcode": "3944",
        "state": "Victoria"
    },
    {
        "suburb": "St Andrews Beach",
        "postcode": "3941",
        "state": "Victoria"
    },
    {
        "suburb": "Cape Schanck",
        "postcode": "3939",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Martha",
        "postcode": "3934",
        "state": "Victoria"
    },
    {
        "suburb": "Fingal",
        "postcode": "3939",
        "state": "Victoria"
    },
    {
        "suburb": "Main Ridge",
        "postcode": "3928",
        "state": "Victoria"
    },
    {
        "suburb": "Mornington",
        "postcode": "3931",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Eliza",
        "postcode": "3930",
        "state": "Victoria"
    },
    {
        "suburb": "Castlemaine",
        "postcode": "3450",
        "state": "Victoria"
    },
    {
        "suburb": "Moonlight Flat",
        "postcode": "3450",
        "state": "Victoria"
    },
    {
        "suburb": "McKenzie Hill",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Chewton Bushlands",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Taradale",
        "postcode": "3447",
        "state": "Victoria"
    },
    {
        "suburb": "Maldon",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Campbells Creek",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Chewton",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Harcourt",
        "postcode": "3453",
        "state": "Victoria"
    },
    {
        "suburb": "Muckleford",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Welshmans Reef",
        "postcode": "3462",
        "state": "Victoria"
    },
    {
        "suburb": "Newstead",
        "postcode": "3462",
        "state": "Victoria"
    },
    {
        "suburb": "Faraday",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Barkers Creek",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Fryerstown",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Golden Point",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Baringhup",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Bradford",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Ravenswood South",
        "postcode": "3453",
        "state": "Victoria"
    },
    {
        "suburb": "Nuggetty",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Walmer",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Gower",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Sandon",
        "postcode": "3462",
        "state": "Victoria"
    },
    {
        "suburb": "Yandoit Hills",
        "postcode": "3461",
        "state": "Victoria"
    },
    {
        "suburb": "Yapeen",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Vaughan",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Elphinstone",
        "postcode": "3448",
        "state": "Victoria"
    },
    {
        "suburb": "Metcalfe",
        "postcode": "3448",
        "state": "Victoria"
    },
    {
        "suburb": "Sutton Grange",
        "postcode": "3448",
        "state": "Victoria"
    },
    {
        "suburb": "Muckleford South",
        "postcode": "3462",
        "state": "Victoria"
    },
    {
        "suburb": "Green Gully",
        "postcode": "3462",
        "state": "Victoria"
    },
    {
        "suburb": "Barfold",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Langley",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Metcalfe East",
        "postcode": "3444",
        "state": "Victoria"
    },
    {
        "suburb": "Tarilta",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Tarrengower",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Baringhup West",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Neereman",
        "postcode": "3463",
        "state": "Victoria"
    },
    {
        "suburb": "Dundonnell",
        "postcode": "3271",
        "state": "Victoria"
    },
    {
        "suburb": "Mortlake",
        "postcode": "3272",
        "state": "Victoria"
    },
    {
        "suburb": "The Sisters",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Framlingham East",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Framlingham",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Ellerslie",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Hexham",
        "postcode": "3273",
        "state": "Victoria"
    },
    {
        "suburb": "Macarthur",
        "postcode": "3286",
        "state": "Victoria"
    },
    {
        "suburb": "Byaduk",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Knebsworth",
        "postcode": "3286",
        "state": "Victoria"
    },
    {
        "suburb": "Gerrigerrup",
        "postcode": "3289",
        "state": "Victoria"
    },
    {
        "suburb": "Warrabkook",
        "postcode": "3286",
        "state": "Victoria"
    },
    {
        "suburb": "Caramut",
        "postcode": "3274",
        "state": "Victoria"
    },
    {
        "suburb": "Minjah",
        "postcode": "3276",
        "state": "Victoria"
    },
    {
        "suburb": "Minhamite",
        "postcode": "3287",
        "state": "Victoria"
    },
    {
        "suburb": "Penshurst",
        "postcode": "3289",
        "state": "Victoria"
    },
    {
        "suburb": "Purdeet",
        "postcode": "3289",
        "state": "Victoria"
    },
    {
        "suburb": "Woolsthorpe",
        "postcode": "3276",
        "state": "Victoria"
    },
    {
        "suburb": "Broadwater",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Hawkesdale",
        "postcode": "3287",
        "state": "Victoria"
    },
    {
        "suburb": "Willatook",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Tarrone",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Orford",
        "postcode": "3284",
        "state": "Victoria"
    },
    {
        "suburb": "Grassmere",
        "postcode": "3281",
        "state": "Victoria"
    },
    {
        "suburb": "Nareeb",
        "postcode": "3293",
        "state": "Victoria"
    },
    {
        "suburb": "Port Fairy",
        "postcode": "3284",
        "state": "Victoria"
    },
    {
        "suburb": "Koroit",
        "postcode": "3282",
        "state": "Victoria"
    },
    {
        "suburb": "Tower Hill",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Yambuk",
        "postcode": "3285",
        "state": "Victoria"
    },
    {
        "suburb": "Southern Cross",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Naringal",
        "postcode": "3277",
        "state": "Victoria"
    },
    {
        "suburb": "Naringal East",
        "postcode": "3277",
        "state": "Victoria"
    },
    {
        "suburb": "Toolong",
        "postcode": "3285",
        "state": "Victoria"
    },
    {
        "suburb": "Rosebrook",
        "postcode": "3285",
        "state": "Victoria"
    },
    {
        "suburb": "Kirkstall",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Crossley",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Illowa",
        "postcode": "3282",
        "state": "Victoria"
    },
    {
        "suburb": "Dennington",
        "postcode": "3280",
        "state": "Victoria"
    },
    {
        "suburb": "Yarpturk",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Yangery",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Mailors Flat",
        "postcode": "3275",
        "state": "Victoria"
    },
    {
        "suburb": "Bushfield",
        "postcode": "3281",
        "state": "Victoria"
    },
    {
        "suburb": "Woodford",
        "postcode": "3281",
        "state": "Victoria"
    },
    {
        "suburb": "Purnim West",
        "postcode": "3278",
        "state": "Victoria"
    },
    {
        "suburb": "Purnim",
        "postcode": "3278",
        "state": "Victoria"
    },
    {
        "suburb": "Wangoom",
        "postcode": "3279",
        "state": "Victoria"
    },
    {
        "suburb": "Warrnambool",
        "postcode": "3280",
        "state": "Victoria"
    },
    {
        "suburb": "The Cove",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Mepunga East",
        "postcode": "3277",
        "state": "Victoria"
    },
    {
        "suburb": "Mepunga",
        "postcode": "3277",
        "state": "Victoria"
    },
    {
        "suburb": "Nullawarre",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Panmure",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Nullawarre North",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Laang",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Nirranda East",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Nirranda",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Curdievale",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Mepunga West",
        "postcode": "3277",
        "state": "Victoria"
    },
    {
        "suburb": "Tyrendarra East",
        "postcode": "3285",
        "state": "Victoria"
    },
    {
        "suburb": "Allansford",
        "postcode": "3277",
        "state": "Victoria"
    },
    {
        "suburb": "Cudgee",
        "postcode": "3265",
        "state": "Victoria"
    },
    {
        "suburb": "Alexandra",
        "postcode": "3714",
        "state": "Victoria"
    },
    {
        "suburb": "Devils River",
        "postcode": "3714",
        "state": "Victoria"
    },
    {
        "suburb": "Taylor Bay",
        "postcode": "3713",
        "state": "Victoria"
    },
    {
        "suburb": "Acheron",
        "postcode": "3714",
        "state": "Victoria"
    },
    {
        "suburb": "Whanregarwen",
        "postcode": "3714",
        "state": "Victoria"
    },
    {
        "suburb": "Buxton",
        "postcode": "3711",
        "state": "Victoria"
    },
    {
        "suburb": "Taggerty",
        "postcode": "3714",
        "state": "Victoria"
    },
    {
        "suburb": "Gobur",
        "postcode": "3719",
        "state": "Victoria"
    },
    {
        "suburb": "Yarck",
        "postcode": "3719",
        "state": "Victoria"
    },
    {
        "suburb": "Thornton",
        "postcode": "3712",
        "state": "Victoria"
    },
    {
        "suburb": "Eildon",
        "postcode": "3713",
        "state": "Victoria"
    },
    {
        "suburb": "Marysville",
        "postcode": "3779",
        "state": "Victoria"
    },
    {
        "suburb": "Narbethong",
        "postcode": "3778",
        "state": "Victoria"
    },
    {
        "suburb": "Limestone",
        "postcode": "3717",
        "state": "Victoria"
    },
    {
        "suburb": "Terip Terip",
        "postcode": "3719",
        "state": "Victoria"
    },
    {
        "suburb": "Fawcett",
        "postcode": "3714",
        "state": "Victoria"
    },
    {
        "suburb": "Kanumbra",
        "postcode": "3719",
        "state": "Victoria"
    },
    {
        "suburb": "Maintongoon",
        "postcode": "3714",
        "state": "Victoria"
    },
    {
        "suburb": "Koriella",
        "postcode": "3714",
        "state": "Victoria"
    },
    {
        "suburb": "Cathkin",
        "postcode": "3714",
        "state": "Victoria"
    },
    {
        "suburb": "Rubicon",
        "postcode": "3712",
        "state": "Victoria"
    },
    {
        "suburb": "Murrindindi",
        "postcode": "3717",
        "state": "Victoria"
    },
    {
        "suburb": "Toolangi",
        "postcode": "3777",
        "state": "Victoria"
    },
    {
        "suburb": "Kinglake",
        "postcode": "3763",
        "state": "Victoria"
    },
    {
        "suburb": "Homewood",
        "postcode": "3717",
        "state": "Victoria"
    },
    {
        "suburb": "Yea",
        "postcode": "3717",
        "state": "Victoria"
    },
    {
        "suburb": "Kinglake West",
        "postcode": "3757",
        "state": "Victoria"
    },
    {
        "suburb": "Flowerdale",
        "postcode": "3658",
        "state": "Victoria"
    },
    {
        "suburb": "Kinglake Central",
        "postcode": "3757",
        "state": "Victoria"
    },
    {
        "suburb": "Castella",
        "postcode": "3777",
        "state": "Victoria"
    },
    {
        "suburb": "Ruffy",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Caveat",
        "postcode": "3660",
        "state": "Victoria"
    },
    {
        "suburb": "Dropmore",
        "postcode": "3660",
        "state": "Victoria"
    },
    {
        "suburb": "Killingworth",
        "postcode": "3717",
        "state": "Victoria"
    },
    {
        "suburb": "Kerrisdale",
        "postcode": "3660",
        "state": "Victoria"
    },
    {
        "suburb": "Strath Creek",
        "postcode": "3658",
        "state": "Victoria"
    },
    {
        "suburb": "Molesworth",
        "postcode": "3718",
        "state": "Victoria"
    },
    {
        "suburb": "Pheasant Creek",
        "postcode": "3757",
        "state": "Victoria"
    },
    {
        "suburb": "Glenburn",
        "postcode": "3717",
        "state": "Victoria"
    },
    {
        "suburb": "Ghin Ghin",
        "postcode": "3717",
        "state": "Victoria"
    },
    {
        "suburb": "Research",
        "postcode": "3095",
        "state": "Victoria"
    },
    {
        "suburb": "North Warrandyte",
        "postcode": "3113",
        "state": "Victoria"
    },
    {
        "suburb": "Kangaroo Ground",
        "postcode": "3097",
        "state": "Victoria"
    },
    {
        "suburb": "Wattle Glen",
        "postcode": "3096",
        "state": "Victoria"
    },
    {
        "suburb": "Plenty",
        "postcode": "3090",
        "state": "Victoria"
    },
    {
        "suburb": "Diamond Creek",
        "postcode": "3089",
        "state": "Victoria"
    },
    {
        "suburb": "Hurstbridge",
        "postcode": "3099",
        "state": "Victoria"
    },
    {
        "suburb": "Yarrambat",
        "postcode": "3091",
        "state": "Victoria"
    },
    {
        "suburb": "Christmas Hills",
        "postcode": "3775",
        "state": "Victoria"
    },
    {
        "suburb": "Panton Hill",
        "postcode": "3759",
        "state": "Victoria"
    },
    {
        "suburb": "St Andrews",
        "postcode": "3761",
        "state": "Victoria"
    },
    {
        "suburb": "Cottles Bridge",
        "postcode": "3099",
        "state": "Victoria"
    },
    {
        "suburb": "Nutfield",
        "postcode": "3099",
        "state": "Victoria"
    },
    {
        "suburb": "Watsons Creek",
        "postcode": "3097",
        "state": "Victoria"
    },
    {
        "suburb": "Doreen",
        "postcode": "3754",
        "state": "Victoria"
    },
    {
        "suburb": "Smiths Gully",
        "postcode": "3760",
        "state": "Victoria"
    },
    {
        "suburb": "Arthurs Creek",
        "postcode": "3099",
        "state": "Victoria"
    },
    {
        "suburb": "Strathewen",
        "postcode": "3099",
        "state": "Victoria"
    },
    {
        "suburb": "Bend Of Islands",
        "postcode": "3097",
        "state": "Victoria"
    },
    {
        "suburb": "St Arnaud",
        "postcode": "3478",
        "state": "Victoria"
    },
    {
        "suburb": "St Arnaud East",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Avon Plains",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Paradise",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Tottington",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Navarre",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Stuart Mill",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Carapooee West",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Carapooee",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Dalyenong",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Redbank",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Sutherland",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Moolerr",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Gre Gre North",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "St Arnaud North",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Swanwater",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Coonooer West",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Swanwater West",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Gooroc",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Rich Avon East",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Rich Avon West",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Banyena",
        "postcode": "3388",
        "state": "Victoria"
    },
    {
        "suburb": "York Plains",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Marnoo East",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Traynors Lagoon",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Marnoo",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Bolangum",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Gre Gre South",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Gre Gre",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Beazleys Bridge",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Rostron",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Winjallok",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Moyreisk",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Slaty Creek",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Kooreh",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Fyans",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Bellfield",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Lonsdale",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Glenorchy",
        "postcode": "3385",
        "state": "Victoria"
    },
    {
        "suburb": "Stawell",
        "postcode": "3380",
        "state": "Victoria"
    },
    {
        "suburb": "Illawarra",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Callawadda",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Wallaloo East",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Kanya",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Halls Gap",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Fyans Creek",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Mokepilly",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Wal Wal",
        "postcode": "3385",
        "state": "Victoria"
    },
    {
        "suburb": "Lubeck",
        "postcode": "3385",
        "state": "Victoria"
    },
    {
        "suburb": "Riachella",
        "postcode": "3385",
        "state": "Victoria"
    },
    {
        "suburb": "Ledcourt",
        "postcode": "3385",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Dryden",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Deep Lead",
        "postcode": "3385",
        "state": "Victoria"
    },
    {
        "suburb": "Landsborough West",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Wattle Creek",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Tulkara",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Concongella",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Bellellen",
        "postcode": "3381",
        "state": "Victoria"
    },
    {
        "suburb": "Grays Bridge",
        "postcode": "3477",
        "state": "Victoria"
    },
    {
        "suburb": "Wallaloo",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Marnoo West",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Zumsteins",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Greens Creek",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Morrl Morrl",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Campbells Bridge",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Joel Joel",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Germania",
        "postcode": "3387",
        "state": "Victoria"
    },
    {
        "suburb": "Bulgana",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Joel South",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Shays Flat",
        "postcode": "3377",
        "state": "Victoria"
    },
    {
        "suburb": "Elwood",
        "postcode": "3184",
        "state": "Victoria"
    },
    {
        "suburb": "Balaclava",
        "postcode": "3183",
        "state": "Victoria"
    },
    {
        "suburb": "St Kilda",
        "postcode": "3182",
        "state": "Victoria"
    },
    {
        "suburb": "St Kilda West",
        "postcode": "3182",
        "state": "Victoria"
    },
    {
        "suburb": "Ripponlea",
        "postcode": "3185",
        "state": "Victoria"
    },
    {
        "suburb": "Windsor",
        "postcode": "3181",
        "state": "Victoria"
    },
    {
        "suburb": "South Melbourne",
        "postcode": "3205",
        "state": "Victoria"
    },
    {
        "suburb": "Middle Park",
        "postcode": "3206",
        "state": "Victoria"
    },
    {
        "suburb": "Albert Park",
        "postcode": "3206",
        "state": "Victoria"
    },
    {
        "suburb": "Avoca",
        "postcode": "3467",
        "state": "Victoria"
    },
    {
        "suburb": "Rathscar",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Glenbrae",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Moonambel",
        "postcode": "3478",
        "state": "Victoria"
    },
    {
        "suburb": "Glenlofty",
        "postcode": "3469",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Lonarch",
        "postcode": "3468",
        "state": "Victoria"
    },
    {
        "suburb": "Trawalla",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Burnbank",
        "postcode": "3371",
        "state": "Victoria"
    },
    {
        "suburb": "Lamplough",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Homebush",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Rathscar West",
        "postcode": "3465",
        "state": "Victoria"
    },
    {
        "suburb": "Chute",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Warrenmang",
        "postcode": "3478",
        "state": "Victoria"
    },
    {
        "suburb": "Percydale",
        "postcode": "3478",
        "state": "Victoria"
    },
    {
        "suburb": "Waterloo",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Tanwood",
        "postcode": "3478",
        "state": "Victoria"
    },
    {
        "suburb": "Nowhere Creek",
        "postcode": "3469",
        "state": "Victoria"
    },
    {
        "suburb": "Lexton",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Landsborough",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Amphitheatre",
        "postcode": "3468",
        "state": "Victoria"
    },
    {
        "suburb": "Glenpatrick",
        "postcode": "3469",
        "state": "Victoria"
    },
    {
        "suburb": "Langi Kal Kal",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Barkly",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Frenchmans",
        "postcode": "3384",
        "state": "Victoria"
    },
    {
        "suburb": "Beaufort",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Snake Valley",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Cross Roads",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Stockyard Hill",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Raglan",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Wongan",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Goldsmith",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Mena Park",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Nerring",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Hillcrest",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Carngham",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Brewster",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Chepstowe",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Main Lead",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Stoneleigh",
        "postcode": "3373",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Emu",
        "postcode": "3351",
        "state": "Victoria"
    },
    {
        "suburb": "Carranballac",
        "postcode": "3361",
        "state": "Victoria"
    },
    {
        "suburb": "Queenscliff",
        "postcode": "3225",
        "state": "Victoria"
    },
    {
        "suburb": "Swan Island",
        "postcode": "3225",
        "state": "Victoria"
    },
    {
        "suburb": "Leongatha",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Meeniyan",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Mirboo North",
        "postcode": "3871",
        "state": "Victoria"
    },
    {
        "suburb": "Venus Bay",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Darlimurla",
        "postcode": "3871",
        "state": "Victoria"
    },
    {
        "suburb": "Walkerville South",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Walkerville",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Tarwin Lower",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Stony Creek",
        "postcode": "3957",
        "state": "Victoria"
    },
    {
        "suburb": "Tarwin",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Middle Tarwin",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Leongatha South",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Koonwarra",
        "postcode": "3954",
        "state": "Victoria"
    },
    {
        "suburb": "Nerrena",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Hallston",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Baromi",
        "postcode": "3871",
        "state": "Victoria"
    },
    {
        "suburb": "Gunyah",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Dumbalk North",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Dollar",
        "postcode": "3871",
        "state": "Victoria"
    },
    {
        "suburb": "Koorooman",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Boorool",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Wild Dog Valley",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Eccles",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Fairbank",
        "postcode": "3951",
        "state": "Victoria"
    },
    {
        "suburb": "Walkerville North",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Allambee South",
        "postcode": "3871",
        "state": "Victoria"
    },
    {
        "suburb": "Port Welshpool",
        "postcode": "3965",
        "state": "Victoria"
    },
    {
        "suburb": "Toora",
        "postcode": "3962",
        "state": "Victoria"
    },
    {
        "suburb": "Foster",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Sandy Point",
        "postcode": "3959",
        "state": "Victoria"
    },
    {
        "suburb": "Yanakie",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Waratah Bay",
        "postcode": "3959",
        "state": "Victoria"
    },
    {
        "suburb": "Fish Creek",
        "postcode": "3959",
        "state": "Victoria"
    },
    {
        "suburb": "Port Franklin",
        "postcode": "3964",
        "state": "Victoria"
    },
    {
        "suburb": "Foster North",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Woorarra West",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Binginwarri",
        "postcode": "3966",
        "state": "Victoria"
    },
    {
        "suburb": "Toora North",
        "postcode": "3962",
        "state": "Victoria"
    },
    {
        "suburb": "Hedley",
        "postcode": "3967",
        "state": "Victoria"
    },
    {
        "suburb": "Welshpool",
        "postcode": "3966",
        "state": "Victoria"
    },
    {
        "suburb": "Hazel Park",
        "postcode": "3966",
        "state": "Victoria"
    },
    {
        "suburb": "Woorarra East",
        "postcode": "3962",
        "state": "Victoria"
    },
    {
        "suburb": "Wonga",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Turtons Creek",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Best",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Wilsons Promontory",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Korumburra",
        "postcode": "3950",
        "state": "Victoria"
    },
    {
        "suburb": "Kardella South",
        "postcode": "3950",
        "state": "Victoria"
    },
    {
        "suburb": "Korumburra South",
        "postcode": "3950",
        "state": "Victoria"
    },
    {
        "suburb": "Bena",
        "postcode": "3951",
        "state": "Victoria"
    },
    {
        "suburb": "Jumbunna",
        "postcode": "3951",
        "state": "Victoria"
    },
    {
        "suburb": "Arawata",
        "postcode": "3951",
        "state": "Victoria"
    },
    {
        "suburb": "Kardella",
        "postcode": "3951",
        "state": "Victoria"
    },
    {
        "suburb": "Ranceby",
        "postcode": "3951",
        "state": "Victoria"
    },
    {
        "suburb": "Poowong",
        "postcode": "3988",
        "state": "Victoria"
    },
    {
        "suburb": "Jeetho",
        "postcode": "3945",
        "state": "Victoria"
    },
    {
        "suburb": "Whitelaw",
        "postcode": "3950",
        "state": "Victoria"
    },
    {
        "suburb": "Hamilton",
        "postcode": "3300",
        "state": "Victoria"
    },
    {
        "suburb": "Coleraine",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Balmoral",
        "postcode": "3407",
        "state": "Victoria"
    },
    {
        "suburb": "Konongwootong",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Rocklands",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Vasey",
        "postcode": "3407",
        "state": "Victoria"
    },
    {
        "suburb": "Englefield",
        "postcode": "3407",
        "state": "Victoria"
    },
    {
        "suburb": "Gringegalgona",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Coojar",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Brit Brit",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Hilgay",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Culla",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Harrow",
        "postcode": "3317",
        "state": "Victoria"
    },
    {
        "suburb": "Tarrayoukyan",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Pigeon Ponds",
        "postcode": "3407",
        "state": "Victoria"
    },
    {
        "suburb": "Wootong Vale",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Croxton East",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Bochara",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Yulecart",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Napier",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Gazette",
        "postcode": "3289",
        "state": "Victoria"
    },
    {
        "suburb": "Yatchaw",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Tabor",
        "postcode": "3289",
        "state": "Victoria"
    },
    {
        "suburb": "Buckley Swamp",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Byaduk North",
        "postcode": "3300",
        "state": "Victoria"
    },
    {
        "suburb": "Tarrington",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Woodhouse",
        "postcode": "3294",
        "state": "Victoria"
    },
    {
        "suburb": "Cavendish",
        "postcode": "3314",
        "state": "Victoria"
    },
    {
        "suburb": "Mooralla",
        "postcode": "3314",
        "state": "Victoria"
    },
    {
        "suburb": "Melville Forest",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Hensley Park",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Karabeal",
        "postcode": "3294",
        "state": "Victoria"
    },
    {
        "suburb": "Mirranatwa",
        "postcode": "3294",
        "state": "Victoria"
    },
    {
        "suburb": "Victoria Valley",
        "postcode": "3294",
        "state": "Victoria"
    },
    {
        "suburb": "Strathkellar",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Warrayure",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Moutajup",
        "postcode": "3294",
        "state": "Victoria"
    },
    {
        "suburb": "Grampians",
        "postcode": "3314",
        "state": "Victoria"
    },
    {
        "suburb": "Victoria Point",
        "postcode": "3294",
        "state": "Victoria"
    },
    {
        "suburb": "Morgiana",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Prahran",
        "postcode": "3181",
        "state": "Victoria"
    },
    {
        "suburb": "Toorak",
        "postcode": "3142",
        "state": "Victoria"
    },
    {
        "suburb": "Armadale",
        "postcode": "3143",
        "state": "Victoria"
    },
    {
        "suburb": "Malvern",
        "postcode": "3144",
        "state": "Victoria"
    },
    {
        "suburb": "Kooyong",
        "postcode": "3144",
        "state": "Victoria"
    },
    {
        "suburb": "Malvern East",
        "postcode": "3145",
        "state": "Victoria"
    },
    {
        "suburb": "Tamleugh",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Euroa",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Marraweeney",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Strathbogie",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Boho",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Upton Hill",
        "postcode": "3664",
        "state": "Victoria"
    },
    {
        "suburb": "Longwood",
        "postcode": "3665",
        "state": "Victoria"
    },
    {
        "suburb": "Nagambie",
        "postcode": "3608",
        "state": "Victoria"
    },
    {
        "suburb": "Bailieston",
        "postcode": "3608",
        "state": "Victoria"
    },
    {
        "suburb": "Kirwans Bridge",
        "postcode": "3608",
        "state": "Victoria"
    },
    {
        "suburb": "Wirrate",
        "postcode": "3608",
        "state": "Victoria"
    },
    {
        "suburb": "Moormbool West",
        "postcode": "3523",
        "state": "Victoria"
    },
    {
        "suburb": "Graytown",
        "postcode": "3608",
        "state": "Victoria"
    },
    {
        "suburb": "Goulburn Weir",
        "postcode": "3608",
        "state": "Victoria"
    },
    {
        "suburb": "Wahring",
        "postcode": "3608",
        "state": "Victoria"
    },
    {
        "suburb": "Tabilk",
        "postcode": "3607",
        "state": "Victoria"
    },
    {
        "suburb": "Locksley",
        "postcode": "3665",
        "state": "Victoria"
    },
    {
        "suburb": "Arcadia South",
        "postcode": "3631",
        "state": "Victoria"
    },
    {
        "suburb": "Miepoll",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Riggs Creek",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Moglonemby",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Upotipotpon",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Koonda",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Earlston",
        "postcode": "3669",
        "state": "Victoria"
    },
    {
        "suburb": "Balmattum",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Kelvin View",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Kithbrook",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Sheans Creek",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Gooram",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Longwood East",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Mitchellstown",
        "postcode": "3608",
        "state": "Victoria"
    },
    {
        "suburb": "Pranjip",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Molka",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Creightons Creek",
        "postcode": "3666",
        "state": "Victoria"
    },
    {
        "suburb": "Torquay",
        "postcode": "3228",
        "state": "Victoria"
    },
    {
        "suburb": "Jan Juc",
        "postcode": "3228",
        "state": "Victoria"
    },
    {
        "suburb": "Moriac",
        "postcode": "3240",
        "state": "Victoria"
    },
    {
        "suburb": "Gnarwarre",
        "postcode": "3221",
        "state": "Victoria"
    },
    {
        "suburb": "Freshwater Creek",
        "postcode": "3217",
        "state": "Victoria"
    },
    {
        "suburb": "Barrabool",
        "postcode": "3221",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Moriac",
        "postcode": "3240",
        "state": "Victoria"
    },
    {
        "suburb": "Bellbrae",
        "postcode": "3228",
        "state": "Victoria"
    },
    {
        "suburb": "Bells Beach",
        "postcode": "3228",
        "state": "Victoria"
    },
    {
        "suburb": "Modewarre",
        "postcode": "3240",
        "state": "Victoria"
    },
    {
        "suburb": "Paraparap",
        "postcode": "3240",
        "state": "Victoria"
    },
    {
        "suburb": "Buckley",
        "postcode": "3240",
        "state": "Victoria"
    },
    {
        "suburb": "Anglesea",
        "postcode": "3230",
        "state": "Victoria"
    },
    {
        "suburb": "Gherang",
        "postcode": "3240",
        "state": "Victoria"
    },
    {
        "suburb": "Eastern View",
        "postcode": "3231",
        "state": "Victoria"
    },
    {
        "suburb": "Fairhaven",
        "postcode": "3231",
        "state": "Victoria"
    },
    {
        "suburb": "Bambra",
        "postcode": "3241",
        "state": "Victoria"
    },
    {
        "suburb": "Winchelsea",
        "postcode": "3241",
        "state": "Victoria"
    },
    {
        "suburb": "Aireys Inlet",
        "postcode": "3231",
        "state": "Victoria"
    },
    {
        "suburb": "Winchelsea South",
        "postcode": "3241",
        "state": "Victoria"
    },
    {
        "suburb": "Moggs Creek",
        "postcode": "3231",
        "state": "Victoria"
    },
    {
        "suburb": "Deans Marsh",
        "postcode": "3235",
        "state": "Victoria"
    },
    {
        "suburb": "Lorne",
        "postcode": "3232",
        "state": "Victoria"
    },
    {
        "suburb": "Pennyroyal",
        "postcode": "3235",
        "state": "Victoria"
    },
    {
        "suburb": "Benwerrin",
        "postcode": "3235",
        "state": "Victoria"
    },
    {
        "suburb": "Wensleydale",
        "postcode": "3241",
        "state": "Victoria"
    },
    {
        "suburb": "Boonah",
        "postcode": "3235",
        "state": "Victoria"
    },
    {
        "suburb": "Big Hill",
        "postcode": "3231",
        "state": "Victoria"
    },
    {
        "suburb": "Wurdiboluc",
        "postcode": "3241",
        "state": "Victoria"
    },
    {
        "suburb": "Swan Hill",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Robinvale",
        "postcode": "3549",
        "state": "Victoria"
    },
    {
        "suburb": "Wemen",
        "postcode": "3549",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Powell",
        "postcode": "3597",
        "state": "Victoria"
    },
    {
        "suburb": "Tol Tol",
        "postcode": "3549",
        "state": "Victoria"
    },
    {
        "suburb": "Liparoo",
        "postcode": "3549",
        "state": "Victoria"
    },
    {
        "suburb": "Happy Valley",
        "postcode": "3549",
        "state": "Victoria"
    },
    {
        "suburb": "Annuello",
        "postcode": "3549",
        "state": "Victoria"
    },
    {
        "suburb": "Boundary Bend",
        "postcode": "3599",
        "state": "Victoria"
    },
    {
        "suburb": "Bannerton",
        "postcode": "3549",
        "state": "Victoria"
    },
    {
        "suburb": "Manangatang",
        "postcode": "3546",
        "state": "Victoria"
    },
    {
        "suburb": "Bolton",
        "postcode": "3546",
        "state": "Victoria"
    },
    {
        "suburb": "Nyah West",
        "postcode": "3595",
        "state": "Victoria"
    },
    {
        "suburb": "Nyah",
        "postcode": "3594",
        "state": "Victoria"
    },
    {
        "suburb": "Beverford",
        "postcode": "3590",
        "state": "Victoria"
    },
    {
        "suburb": "Waitchie",
        "postcode": "3544",
        "state": "Victoria"
    },
    {
        "suburb": "Turoar",
        "postcode": "3546",
        "state": "Victoria"
    },
    {
        "suburb": "Gerahmin",
        "postcode": "3546",
        "state": "Victoria"
    },
    {
        "suburb": "Chinkapook",
        "postcode": "3546",
        "state": "Victoria"
    },
    {
        "suburb": "Piangil",
        "postcode": "3597",
        "state": "Victoria"
    },
    {
        "suburb": "Woorinen South",
        "postcode": "3588",
        "state": "Victoria"
    },
    {
        "suburb": "Woorinen",
        "postcode": "3589",
        "state": "Victoria"
    },
    {
        "suburb": "Tresco West",
        "postcode": "3584",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Boga",
        "postcode": "3584",
        "state": "Victoria"
    },
    {
        "suburb": "Polisbet",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Ultima",
        "postcode": "3544",
        "state": "Victoria"
    },
    {
        "suburb": "Murrawee",
        "postcode": "3586",
        "state": "Victoria"
    },
    {
        "suburb": "Tyntynder",
        "postcode": "3586",
        "state": "Victoria"
    },
    {
        "suburb": "Woorinen North",
        "postcode": "3589",
        "state": "Victoria"
    },
    {
        "suburb": "Tresco",
        "postcode": "3583",
        "state": "Victoria"
    },
    {
        "suburb": "Wandown",
        "postcode": "3549",
        "state": "Victoria"
    },
    {
        "suburb": "Kooloonong",
        "postcode": "3597",
        "state": "Victoria"
    },
    {
        "suburb": "Narrung",
        "postcode": "3597",
        "state": "Victoria"
    },
    {
        "suburb": "Kenley",
        "postcode": "3597",
        "state": "Victoria"
    },
    {
        "suburb": "Natya",
        "postcode": "3597",
        "state": "Victoria"
    },
    {
        "suburb": "Wood Wood",
        "postcode": "3596",
        "state": "Victoria"
    },
    {
        "suburb": "Cocamba",
        "postcode": "3546",
        "state": "Victoria"
    },
    {
        "suburb": "Winnambool",
        "postcode": "3546",
        "state": "Victoria"
    },
    {
        "suburb": "Miralie",
        "postcode": "3596",
        "state": "Victoria"
    },
    {
        "suburb": "Gowanford",
        "postcode": "3544",
        "state": "Victoria"
    },
    {
        "suburb": "Chinangin",
        "postcode": "3544",
        "state": "Victoria"
    },
    {
        "suburb": "Murnungin",
        "postcode": "3544",
        "state": "Victoria"
    },
    {
        "suburb": "Tyntynder South",
        "postcode": "3586",
        "state": "Victoria"
    },
    {
        "suburb": "Kunat",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Ultima East",
        "postcode": "3544",
        "state": "Victoria"
    },
    {
        "suburb": "Goschen",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Pental Island",
        "postcode": "3586",
        "state": "Victoria"
    },
    {
        "suburb": "Castle Donnington",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Winlaton",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Fish Point",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Vinifera",
        "postcode": "3591",
        "state": "Victoria"
    },
    {
        "suburb": "Chillingollah",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Murraydale",
        "postcode": "3586",
        "state": "Victoria"
    },
    {
        "suburb": "Towan",
        "postcode": "3596",
        "state": "Victoria"
    },
    {
        "suburb": "Nyrraby",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Swan Hill West",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Pira",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Nowie",
        "postcode": "3585",
        "state": "Victoria"
    },
    {
        "suburb": "Bulga",
        "postcode": "3586",
        "state": "Victoria"
    },
    {
        "suburb": "Tallangatta",
        "postcode": "3700",
        "state": "Victoria"
    },
    {
        "suburb": "Bellbridge",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Bethanga",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Talgarno",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Jarvis Creek",
        "postcode": "3700",
        "state": "Victoria"
    },
    {
        "suburb": "Georges Creek",
        "postcode": "3700",
        "state": "Victoria"
    },
    {
        "suburb": "Bullioh",
        "postcode": "3700",
        "state": "Victoria"
    },
    {
        "suburb": "Old Tallangatta",
        "postcode": "3701",
        "state": "Victoria"
    },
    {
        "suburb": "Tallangatta South",
        "postcode": "3701",
        "state": "Victoria"
    },
    {
        "suburb": "Granya",
        "postcode": "3701",
        "state": "Victoria"
    },
    {
        "suburb": "Corryong",
        "postcode": "3707",
        "state": "Victoria"
    },
    {
        "suburb": "Eskdale",
        "postcode": "3701",
        "state": "Victoria"
    },
    {
        "suburb": "Tallangatta Valley",
        "postcode": "3701",
        "state": "Victoria"
    },
    {
        "suburb": "Mitta Mitta",
        "postcode": "3701",
        "state": "Victoria"
    },
    {
        "suburb": "Walwa",
        "postcode": "3709",
        "state": "Victoria"
    },
    {
        "suburb": "Cudgewa",
        "postcode": "3705",
        "state": "Victoria"
    },
    {
        "suburb": "Tallandoon",
        "postcode": "3701",
        "state": "Victoria"
    },
    {
        "suburb": "Tallangatta East",
        "postcode": "3700",
        "state": "Victoria"
    },
    {
        "suburb": "Guys Forest",
        "postcode": "3709",
        "state": "Victoria"
    },
    {
        "suburb": "Koetong",
        "postcode": "3704",
        "state": "Victoria"
    },
    {
        "suburb": "Shelley",
        "postcode": "3701",
        "state": "Victoria"
    },
    {
        "suburb": "Berringama",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Lucyvale",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Biggara",
        "postcode": "3707",
        "state": "Victoria"
    },
    {
        "suburb": "Thowgla Valley",
        "postcode": "3707",
        "state": "Victoria"
    },
    {
        "suburb": "Towong Upper",
        "postcode": "3707",
        "state": "Victoria"
    },
    {
        "suburb": "Tom Groggin",
        "postcode": "3707",
        "state": "Victoria"
    },
    {
        "suburb": "Pine Mountain",
        "postcode": "3709",
        "state": "Victoria"
    },
    {
        "suburb": "Bungil",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Thologolong",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Colac Colac",
        "postcode": "3707",
        "state": "Victoria"
    },
    {
        "suburb": "Towong",
        "postcode": "3707",
        "state": "Victoria"
    },
    {
        "suburb": "Burrowye",
        "postcode": "3709",
        "state": "Victoria"
    },
    {
        "suburb": "Tintaldra",
        "postcode": "3708",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Alfred",
        "postcode": "3709",
        "state": "Victoria"
    },
    {
        "suburb": "Wangaratta",
        "postcode": "3677",
        "state": "Victoria"
    },
    {
        "suburb": "Wangaratta South",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Waldara",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Londrigan",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Everton Upper",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Boorhaman",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Springhurst",
        "postcode": "3682",
        "state": "Victoria"
    },
    {
        "suburb": "Tarrawingee",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Killawarra",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Boorhaman North",
        "postcode": "3685",
        "state": "Victoria"
    },
    {
        "suburb": "Peechelba East",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Wangandary",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Byawatha",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Murmungee",
        "postcode": "3747",
        "state": "Victoria"
    },
    {
        "suburb": "Bowmans Forest",
        "postcode": "3735",
        "state": "Victoria"
    },
    {
        "suburb": "East Wangaratta",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Boorhaman East",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Everton",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Boralma",
        "postcode": "3682",
        "state": "Victoria"
    },
    {
        "suburb": "North Wangaratta",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Bowser",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Dockers Plains",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Bobinawarrah",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Myrrhee",
        "postcode": "3732",
        "state": "Victoria"
    },
    {
        "suburb": "Whitfield",
        "postcode": "3733",
        "state": "Victoria"
    },
    {
        "suburb": "Edi",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Moyhu",
        "postcode": "3732",
        "state": "Victoria"
    },
    {
        "suburb": "Oxley",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Cheshunt South",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Milawa",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Whorouly",
        "postcode": "3735",
        "state": "Victoria"
    },
    {
        "suburb": "Greta South",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Hansonville",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Carboor",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Greta West",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Meadow Creek",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Rose River",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Whitlands",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Laceby",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Greta",
        "postcode": "3675",
        "state": "Victoria"
    },
    {
        "suburb": "Docker",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Markwood",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Oxley Flats",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Cheshunt",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "King Valley",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Edi Upper",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Whorouly East",
        "postcode": "3735",
        "state": "Victoria"
    },
    {
        "suburb": "Whorouly South",
        "postcode": "3735",
        "state": "Victoria"
    },
    {
        "suburb": "Port Albert",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Robertsons Beach",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Manns Beach",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Yarram",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Woodside",
        "postcode": "3874",
        "state": "Victoria"
    },
    {
        "suburb": "Giffard",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Snake Island",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Jack River",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Hunterston",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Langsborough",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "McLoughlins Beach",
        "postcode": "3874",
        "state": "Victoria"
    },
    {
        "suburb": "Woodside Beach",
        "postcode": "3874",
        "state": "Victoria"
    },
    {
        "suburb": "Darriman",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Tarra Valley",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Macks Creek",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Blackwarry",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Carrajung",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Devon North",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Calrossie",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Won Wron",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Alberton",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Tarraville",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Gelliondale",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Alberton West",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Giffard West",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Carrajung South",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Willung South",
        "postcode": "3847",
        "state": "Victoria"
    },
    {
        "suburb": "Carrajung Lower",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Staceys Bridge",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Madalya",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Hiawatha",
        "postcode": "3971",
        "state": "Victoria"
    },
    {
        "suburb": "Wonyip",
        "postcode": "3962",
        "state": "Victoria"
    },
    {
        "suburb": "Gormandale",
        "postcode": "3873",
        "state": "Victoria"
    },
    {
        "suburb": "Stratford",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Perry Bridge",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Meerlieu",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Cobains",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Moornapa",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Stockdale",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Briagolong",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Myrtlebank",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Clydebank",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Hollands Landing",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "The Heart",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "East Sale",
        "postcode": "3852",
        "state": "Victoria"
    },
    {
        "suburb": "Sale",
        "postcode": "3850",
        "state": "Victoria"
    },
    {
        "suburb": "Lake Wellington",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Maffra",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Heyfield",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Glenmaggie",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Coongulla",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Arbuckle",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Crookayan",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Budgee Budgee",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Miowera",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Moroka",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Buragwonduc",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Wrathung",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Tamboritha",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Toolome",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Nap Nap Marra",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Sargood",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Worrowing",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Wrixon",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Gillum",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Koorool",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Woods Point",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Boisdale",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Valencia Creek",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Newry",
        "postcode": "3859",
        "state": "Victoria"
    },
    {
        "suburb": "Walhalla",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Yangoura",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Woolenook",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Maffra West Upper",
        "postcode": "3859",
        "state": "Victoria"
    },
    {
        "suburb": "Seaton",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Crooked River",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Billabong",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Bushy Park",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Riverslea",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Bundalaguah",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Licola",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Tinamba West",
        "postcode": "3859",
        "state": "Victoria"
    },
    {
        "suburb": "Monomak",
        "postcode": "3860",
        "state": "Victoria"
    },
    {
        "suburb": "Glenfalloch",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Dawson",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Tinamba",
        "postcode": "3859",
        "state": "Victoria"
    },
    {
        "suburb": "Walhalla East",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Longford",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Rosedale",
        "postcode": "3847",
        "state": "Victoria"
    },
    {
        "suburb": "Seaspray",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "The Honeysuckles",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Dutson Downs",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Golden Beach",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Loch Sport",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Dutson",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Willung",
        "postcode": "3847",
        "state": "Victoria"
    },
    {
        "suburb": "Glomar Beach",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Paradise Beach",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Winnindoo",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Cowwarr",
        "postcode": "3857",
        "state": "Victoria"
    },
    {
        "suburb": "Denison",
        "postcode": "3858",
        "state": "Victoria"
    },
    {
        "suburb": "Nambrok",
        "postcode": "3847",
        "state": "Victoria"
    },
    {
        "suburb": "Pearsondale",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Kilmany",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Hiamdale",
        "postcode": "3847",
        "state": "Victoria"
    },
    {
        "suburb": "Callignee South",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Callignee North",
        "postcode": "3844",
        "state": "Victoria"
    },
    {
        "suburb": "Stradbroke",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Wurruk",
        "postcode": "3850",
        "state": "Victoria"
    },
    {
        "suburb": "Edenhope",
        "postcode": "3318",
        "state": "Victoria"
    },
    {
        "suburb": "Wombelano",
        "postcode": "3409",
        "state": "Victoria"
    },
    {
        "suburb": "Dorodong",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Langkoop",
        "postcode": "3318",
        "state": "Victoria"
    },
    {
        "suburb": "Kadnook",
        "postcode": "3318",
        "state": "Victoria"
    },
    {
        "suburb": "Neuarpurr",
        "postcode": "3413",
        "state": "Victoria"
    },
    {
        "suburb": "Minimay",
        "postcode": "3413",
        "state": "Victoria"
    },
    {
        "suburb": "Peronne",
        "postcode": "3413",
        "state": "Victoria"
    },
    {
        "suburb": "Goroke",
        "postcode": "3412",
        "state": "Victoria"
    },
    {
        "suburb": "Gymbowen",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Charam",
        "postcode": "3318",
        "state": "Victoria"
    },
    {
        "suburb": "Karnak",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Ullswater",
        "postcode": "3318",
        "state": "Victoria"
    },
    {
        "suburb": "Ozenkadnook",
        "postcode": "3413",
        "state": "Victoria"
    },
    {
        "suburb": "Patyah",
        "postcode": "3318",
        "state": "Victoria"
    },
    {
        "suburb": "Bringalbert",
        "postcode": "3319",
        "state": "Victoria"
    },
    {
        "suburb": "Kaniva",
        "postcode": "3419",
        "state": "Victoria"
    },
    {
        "suburb": "Nurcoung",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Miga Lake",
        "postcode": "3409",
        "state": "Victoria"
    },
    {
        "suburb": "Apsley",
        "postcode": "3319",
        "state": "Victoria"
    },
    {
        "suburb": "Poolaijelo",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Connewirricoo",
        "postcode": "3318",
        "state": "Victoria"
    },
    {
        "suburb": "Benayeo",
        "postcode": "3319",
        "state": "Victoria"
    },
    {
        "suburb": "Powers Creek",
        "postcode": "3312",
        "state": "Victoria"
    },
    {
        "suburb": "Lillimur",
        "postcode": "3420",
        "state": "Victoria"
    },
    {
        "suburb": "Serviceton",
        "postcode": "3420",
        "state": "Victoria"
    },
    {
        "suburb": "Telopea Downs",
        "postcode": "3420",
        "state": "Victoria"
    },
    {
        "suburb": "Lawloit",
        "postcode": "3418",
        "state": "Victoria"
    },
    {
        "suburb": "Miram",
        "postcode": "3415",
        "state": "Victoria"
    },
    {
        "suburb": "Mont Albert North",
        "postcode": "3129",
        "state": "Victoria"
    },
    {
        "suburb": "Box Hill South",
        "postcode": "3128",
        "state": "Victoria"
    },
    {
        "suburb": "Box Hill",
        "postcode": "3128",
        "state": "Victoria"
    },
    {
        "suburb": "Box Hill North",
        "postcode": "3129",
        "state": "Victoria"
    },
    {
        "suburb": "Mitcham",
        "postcode": "3132",
        "state": "Victoria"
    },
    {
        "suburb": "Vermont South",
        "postcode": "3133",
        "state": "Victoria"
    },
    {
        "suburb": "Forest Hill",
        "postcode": "3131",
        "state": "Victoria"
    },
    {
        "suburb": "Blackburn South",
        "postcode": "3130",
        "state": "Victoria"
    },
    {
        "suburb": "Burwood East",
        "postcode": "3151",
        "state": "Victoria"
    },
    {
        "suburb": "Blackburn",
        "postcode": "3130",
        "state": "Victoria"
    },
    {
        "suburb": "Blackburn North",
        "postcode": "3130",
        "state": "Victoria"
    },
    {
        "suburb": "Yan Yean",
        "postcode": "3755",
        "state": "Victoria"
    },
    {
        "suburb": "South Morang",
        "postcode": "3752",
        "state": "Victoria"
    },
    {
        "suburb": "Epping",
        "postcode": "3076",
        "state": "Victoria"
    },
    {
        "suburb": "Mernda",
        "postcode": "3754",
        "state": "Victoria"
    },
    {
        "suburb": "Woodstock",
        "postcode": "3751",
        "state": "Victoria"
    },
    {
        "suburb": "Humevale",
        "postcode": "3757",
        "state": "Victoria"
    },
    {
        "suburb": "Eden Park",
        "postcode": "3757",
        "state": "Victoria"
    },
    {
        "suburb": "Whittlesea",
        "postcode": "3757",
        "state": "Victoria"
    },
    {
        "suburb": "Wollert",
        "postcode": "3750",
        "state": "Victoria"
    },
    {
        "suburb": "Beveridge",
        "postcode": "3753",
        "state": "Victoria"
    },
    {
        "suburb": "Donnybrook",
        "postcode": "3064",
        "state": "Victoria"
    },
    {
        "suburb": "Mill Park",
        "postcode": "3082",
        "state": "Victoria"
    },
    {
        "suburb": "Thomastown",
        "postcode": "3074",
        "state": "Victoria"
    },
    {
        "suburb": "Lalor",
        "postcode": "3075",
        "state": "Victoria"
    },
    {
        "suburb": "Wodonga",
        "postcode": "3690",
        "state": "Victoria"
    },
    {
        "suburb": "West Wodonga",
        "postcode": "3690",
        "state": "Victoria"
    },
    {
        "suburb": "Baranduda",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Ebden",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Killara",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Leneva",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Bonegilla",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Bandiana",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Barnawartha North",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Gateway Island",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Huon Creek",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Castle Creek",
        "postcode": "3691",
        "state": "Victoria"
    },
    {
        "suburb": "Hoppers Crossing",
        "postcode": "3029",
        "state": "Victoria"
    },
    {
        "suburb": "Werribee",
        "postcode": "3030",
        "state": "Victoria"
    },
    {
        "suburb": "Tarneit",
        "postcode": "3029",
        "state": "Victoria"
    },
    {
        "suburb": "Laverton North",
        "postcode": "3026",
        "state": "Victoria"
    },
    {
        "suburb": "Williams Landing",
        "postcode": "3027",
        "state": "Victoria"
    },
    {
        "suburb": "Lucas",
        "postcode": "3350",
        "state": "Victoria"
    },
    {
        "suburb": "Wyndham Vale",
        "postcode": "3024",
        "state": "Victoria"
    },
    {
        "suburb": "Chirnside Park",
        "postcode": "3116",
        "state": "Victoria"
    },
    {
        "suburb": "Abbotsford",
        "postcode": "3067",
        "state": "Victoria"
    },
    {
        "suburb": "Fulham",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Woori Yallock",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Yarra Junction",
        "postcode": "3797",
        "state": "Victoria"
    },
    {
        "suburb": "East Warburton",
        "postcode": "3799",
        "state": "Victoria"
    },
    {
        "suburb": "Warburton",
        "postcode": "3799",
        "state": "Victoria"
    },
    {
        "suburb": "Point Cook",
        "postcode": "3030",
        "state": "Victoria"
    },
    {
        "suburb": "Skinners Flat",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Leongatha North",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Wooreen",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Lilydale",
        "postcode": "3140",
        "state": "Victoria"
    },
    {
        "suburb": "Fitzroy",
        "postcode": "3065",
        "state": "Victoria"
    },
    {
        "suburb": "Mardan",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Berrys Creek",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Swan Bay",
        "postcode": "3225",
        "state": "Victoria"
    },
    {
        "suburb": "Wentworth",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Hill End",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Mooroolbark",
        "postcode": "3138",
        "state": "Victoria"
    },
    {
        "suburb": "Armstrong Creek",
        "postcode": "3217",
        "state": "Victoria"
    },
    {
        "suburb": "Cherrypool",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Brimpaen",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Delegate River",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Big Pats Creek",
        "postcode": "3799",
        "state": "Victoria"
    },
    {
        "suburb": "Cowangie",
        "postcode": "3506",
        "state": "Victoria"
    },
    {
        "suburb": "Richmond",
        "postcode": "3121",
        "state": "Victoria"
    },
    {
        "suburb": "Nilma",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Collingwood",
        "postcode": "3066",
        "state": "Victoria"
    },
    {
        "suburb": "Wannon",
        "postcode": "3301",
        "state": "Victoria"
    },
    {
        "suburb": "Wargan",
        "postcode": "3505",
        "state": "Victoria"
    },
    {
        "suburb": "Tarrenlea",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Burnley",
        "postcode": "3121",
        "state": "Victoria"
    },
    {
        "suburb": "Bullumwaal",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Nirranda South",
        "postcode": "3268",
        "state": "Victoria"
    },
    {
        "suburb": "Cremorne",
        "postcode": "3121",
        "state": "Victoria"
    },
    {
        "suburb": "Dumbalk",
        "postcode": "3956",
        "state": "Victoria"
    },
    {
        "suburb": "Glen Valley",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Llowalong",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Eynesbury",
        "postcode": "3338",
        "state": "Victoria"
    },
    {
        "suburb": "Glen Wills",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Bulart",
        "postcode": "3314",
        "state": "Victoria"
    },
    {
        "suburb": "Ada",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Boolarong",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "St Helens",
        "postcode": "3285",
        "state": "Victoria"
    },
    {
        "suburb": "Pentland Hills",
        "postcode": "3341",
        "state": "Victoria"
    },
    {
        "suburb": "Mountain Bay",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Winslow",
        "postcode": "3281",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Mercer",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Buffalo",
        "postcode": "3958",
        "state": "Victoria"
    },
    {
        "suburb": "Girgarre East",
        "postcode": "3616",
        "state": "Victoria"
    },
    {
        "suburb": "Catumnal",
        "postcode": "3537",
        "state": "Victoria"
    },
    {
        "suburb": "Maindample",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Korobeit",
        "postcode": "3341",
        "state": "Victoria"
    },
    {
        "suburb": "Glenmore",
        "postcode": "3340",
        "state": "Victoria"
    },
    {
        "suburb": "Glenluce",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Glen Alvie",
        "postcode": "3979",
        "state": "Victoria"
    },
    {
        "suburb": "Agnes",
        "postcode": "3962",
        "state": "Victoria"
    },
    {
        "suburb": "Krowera",
        "postcode": "3945",
        "state": "Victoria"
    },
    {
        "suburb": "Toorongo",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Omeo Valley",
        "postcode": "3898",
        "state": "Victoria"
    },
    {
        "suburb": "Munro",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Glenisla",
        "postcode": "3314",
        "state": "Victoria"
    },
    {
        "suburb": "Crossover",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "W Tree",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Clifton Hill",
        "postcode": "3068",
        "state": "Victoria"
    },
    {
        "suburb": "Coldstream",
        "postcode": "3770",
        "state": "Victoria"
    },
    {
        "suburb": "Werribee South",
        "postcode": "3030",
        "state": "Victoria"
    },
    {
        "suburb": "Ballangeich",
        "postcode": "3279",
        "state": "Victoria"
    },
    {
        "suburb": "Killarney",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Ruby",
        "postcode": "3953",
        "state": "Victoria"
    },
    {
        "suburb": "Butchers Ridge",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Merrinee",
        "postcode": "3496",
        "state": "Victoria"
    },
    {
        "suburb": "McMahons Creek",
        "postcode": "3799",
        "state": "Victoria"
    },
    {
        "suburb": "Lardner",
        "postcode": "3821",
        "state": "Victoria"
    },
    {
        "suburb": "Montgomery",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Chapel Flat",
        "postcode": "3352",
        "state": "Victoria"
    },
    {
        "suburb": "Childers",
        "postcode": "3824",
        "state": "Victoria"
    },
    {
        "suburb": "Airly",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Cloverlea",
        "postcode": "3822",
        "state": "Victoria"
    },
    {
        "suburb": "Gritjurk",
        "postcode": "3315",
        "state": "Victoria"
    },
    {
        "suburb": "Walpa",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Warrong",
        "postcode": "3283",
        "state": "Victoria"
    },
    {
        "suburb": "Gatum",
        "postcode": "3407",
        "state": "Victoria"
    },
    {
        "suburb": "Cobbannah",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Iguana Creek",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Gainsborough",
        "postcode": "3822",
        "state": "Victoria"
    },
    {
        "suburb": "Neerim",
        "postcode": "3831",
        "state": "Victoria"
    },
    {
        "suburb": "Longwarry North",
        "postcode": "3816",
        "state": "Victoria"
    },
    {
        "suburb": "Bennison",
        "postcode": "3960",
        "state": "Victoria"
    },
    {
        "suburb": "Patchewollock",
        "postcode": "3491",
        "state": "Victoria"
    },
    {
        "suburb": "Powelltown",
        "postcode": "3797",
        "state": "Victoria"
    },
    {
        "suburb": "Woodglen",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Glenaladale",
        "postcode": "3864",
        "state": "Victoria"
    },
    {
        "suburb": "Codrington",
        "postcode": "3285",
        "state": "Victoria"
    },
    {
        "suburb": "Cocoroc",
        "postcode": "3030",
        "state": "Victoria"
    },
    {
        "suburb": "Quandong",
        "postcode": "3030",
        "state": "Victoria"
    },
    {
        "suburb": "Mambourin",
        "postcode": "3024",
        "state": "Victoria"
    },
    {
        "suburb": "Princes Hill",
        "postcode": "3054",
        "state": "Victoria"
    },
    {
        "suburb": "Don Valley",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Millgrove",
        "postcode": "3799",
        "state": "Victoria"
    },
    {
        "suburb": "Launching Place",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Wesburn",
        "postcode": "3799",
        "state": "Victoria"
    },
    {
        "suburb": "Gladysdale",
        "postcode": "3797",
        "state": "Victoria"
    },
    {
        "suburb": "Three Bridges",
        "postcode": "3797",
        "state": "Victoria"
    },
    {
        "suburb": "Gilderoy",
        "postcode": "3797",
        "state": "Victoria"
    },
    {
        "suburb": "Healesville",
        "postcode": "3777",
        "state": "Victoria"
    },
    {
        "suburb": "Hoddles Creek",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Yellingbo",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Beenak",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Upwey",
        "postcode": "3158",
        "state": "Victoria"
    },
    {
        "suburb": "Tecoma",
        "postcode": "3160",
        "state": "Victoria"
    },
    {
        "suburb": "Belgrave",
        "postcode": "3160",
        "state": "Victoria"
    },
    {
        "suburb": "Belgrave Heights",
        "postcode": "3160",
        "state": "Victoria"
    },
    {
        "suburb": "Belgrave South",
        "postcode": "3160",
        "state": "Victoria"
    },
    {
        "suburb": "Olinda",
        "postcode": "3788",
        "state": "Victoria"
    },
    {
        "suburb": "Selby",
        "postcode": "3159",
        "state": "Victoria"
    },
    {
        "suburb": "The Patch",
        "postcode": "3792",
        "state": "Victoria"
    },
    {
        "suburb": "Silvan",
        "postcode": "3795",
        "state": "Victoria"
    },
    {
        "suburb": "Kallista",
        "postcode": "3791",
        "state": "Victoria"
    },
    {
        "suburb": "Ferny Creek",
        "postcode": "3786",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Dandenong",
        "postcode": "3767",
        "state": "Victoria"
    },
    {
        "suburb": "Sassafras",
        "postcode": "3787",
        "state": "Victoria"
    },
    {
        "suburb": "Sherbrooke",
        "postcode": "3789",
        "state": "Victoria"
    },
    {
        "suburb": "Kalorama",
        "postcode": "3766",
        "state": "Victoria"
    },
    {
        "suburb": "Narre Warren East",
        "postcode": "3804",
        "state": "Victoria"
    },
    {
        "suburb": "Monbulk",
        "postcode": "3793",
        "state": "Victoria"
    },
    {
        "suburb": "Montrose",
        "postcode": "3765",
        "state": "Victoria"
    },
    {
        "suburb": "Tremont",
        "postcode": "3785",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Evelyn",
        "postcode": "3796",
        "state": "Victoria"
    },
    {
        "suburb": "Wandin North",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Badger Creek",
        "postcode": "3777",
        "state": "Victoria"
    },
    {
        "suburb": "Chum Creek",
        "postcode": "3777",
        "state": "Victoria"
    },
    {
        "suburb": "Yarra Glen",
        "postcode": "3775",
        "state": "Victoria"
    },
    {
        "suburb": "Steels Creek",
        "postcode": "3775",
        "state": "Victoria"
    },
    {
        "suburb": "Dixons Creek",
        "postcode": "3775",
        "state": "Victoria"
    },
    {
        "suburb": "Fernshaw",
        "postcode": "3778",
        "state": "Victoria"
    },
    {
        "suburb": "Mount Toolebewong",
        "postcode": "3777",
        "state": "Victoria"
    },
    {
        "suburb": "Tarrawarra",
        "postcode": "3775",
        "state": "Victoria"
    },
    {
        "suburb": "Seville East",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Seville",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Yering",
        "postcode": "3770",
        "state": "Victoria"
    },
    {
        "suburb": "Gruyere",
        "postcode": "3770",
        "state": "Victoria"
    },
    {
        "suburb": "Wandin East",
        "postcode": "3139",
        "state": "Victoria"
    },
    {
        "suburb": "Macclesfield",
        "postcode": "3782",
        "state": "Victoria"
    },
    {
        "suburb": "Cambarville",
        "postcode": "3779",
        "state": "Victoria"
    },
    {
        "suburb": "Reefton",
        "postcode": "3799",
        "state": "Victoria"
    },
    {
        "suburb": "Hopetoun",
        "postcode": "3396",
        "state": "Victoria"
    },
    {
        "suburb": "Woomelang",
        "postcode": "3485",
        "state": "Victoria"
    },
    {
        "suburb": "Yaapeet",
        "postcode": "3424",
        "state": "Victoria"
    },
    {
        "suburb": "Beulah",
        "postcode": "3395",
        "state": "Victoria"
    },
    {
        "suburb": "Rosebery",
        "postcode": "3395",
        "state": "Victoria"
    },
    {
        "suburb": "Lascelles",
        "postcode": "3487",
        "state": "Victoria"
    },
    {
        "suburb": "Speed",
        "postcode": "3488",
        "state": "Victoria"
    },
    {
        "suburb": "Turriff",
        "postcode": "3488",
        "state": "Victoria"
    },
    {
        "suburb": "Albacutya",
        "postcode": "3424",
        "state": "Victoria"
    },
    {
        "suburb": "Warracknabeal",
        "postcode": "3393",
        "state": "Victoria"
    },
    {
        "suburb": "Brim",
        "postcode": "3391",
        "state": "Victoria"
    },
    {
        "suburb": "Murtoa",
        "postcode": "3390",
        "state": "Victoria"
    },
    {
        "suburb": "Rupanyup",
        "postcode": "3388",
        "state": "Victoria"
    },
    {
        "suburb": "Cannum",
        "postcode": "3393",
        "state": "Victoria"
    },
    {
        "suburb": "Crymelon",
        "postcode": "3393",
        "state": "Victoria"
    },
    {
        "suburb": "Aubrey",
        "postcode": "3393",
        "state": "Victoria"
    },
    {
        "suburb": "Bangerang",
        "postcode": "3393",
        "state": "Victoria"
    },
    {
        "suburb": "Areegra",
        "postcode": "3480",
        "state": "Victoria"
    },
    {
        "suburb": "Boolite",
        "postcode": "3392",
        "state": "Victoria"
    },
    {
        "suburb": "Willenabrina",
        "postcode": "3393",
        "state": "Victoria"
    },
    {
        "suburb": "Lah",
        "postcode": "3393",
        "state": "Victoria"
    },
    {
        "suburb": "Kellalac",
        "postcode": "3393",
        "state": "Victoria"
    },
    {
        "suburb": "Wallup",
        "postcode": "3401",
        "state": "Victoria"
    },
    {
        "suburb": "Kewell",
        "postcode": "3390",
        "state": "Victoria"
    },
    {
        "suburb": "Sheep Hills",
        "postcode": "3392",
        "state": "Victoria"
    },
    {
        "suburb": "Baw Baw Village",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "French Island",
        "postcode": "3921",
        "state": "Victoria"
    },
    {
        "suburb": "Elizabeth Island",
        "postcode": "3921",
        "state": "Victoria"
    },
    {
        "suburb": "Brumby",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Fairy Dell",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Stirling",
        "postcode": "3893",
        "state": "Victoria"
    },
    {
        "suburb": "Double Bridges",
        "postcode": "3893",
        "state": "Victoria"
    },
    {
        "suburb": "Tambo Crossing",
        "postcode": "3893",
        "state": "Victoria"
    },
    {
        "suburb": "Nelse",
        "postcode": "3699",
        "state": "Victoria"
    },
    {
        "suburb": "Timbarra",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Merrijig",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Tabberabbera",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Ryans",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Seacombe",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Flamingo Beach",
        "postcode": "3851",
        "state": "Victoria"
    },
    {
        "suburb": "Wallagaraugh",
        "postcode": "3891",
        "state": "Victoria"
    },
    {
        "suburb": "Bellbird Creek",
        "postcode": "3889",
        "state": "Victoria"
    },
    {
        "suburb": "Buldah",
        "postcode": "3890",
        "state": "Victoria"
    },
    {
        "suburb": "Bete Bolong North",
        "postcode": "3888",
        "state": "Victoria"
    },
    {
        "suburb": "Yalmy",
        "postcode": "3885",
        "state": "Victoria"
    },
    {
        "suburb": "Bogong",
        "postcode": "3699",
        "state": "Victoria"
    },
    {
        "suburb": "Tanjil",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Boola",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Amor",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Caringal",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Jericho",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Toombon",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Aberfeldy",
        "postcode": "3825",
        "state": "Victoria"
    },
    {
        "suburb": "Gentle Annie",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Loch Valley",
        "postcode": "3833",
        "state": "Victoria"
    },
    {
        "suburb": "Archerton",
        "postcode": "3723",
        "state": "Victoria"
    },
    {
        "suburb": "Mysia",
        "postcode": "3518",
        "state": "Victoria"
    },
    {
        "suburb": "Cochranes Creek",
        "postcode": "3475",
        "state": "Victoria"
    },
    {
        "suburb": "McIntyre",
        "postcode": "3472",
        "state": "Victoria"
    },
    {
        "suburb": "Cowa",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Hawkhurst",
        "postcode": "3862",
        "state": "Victoria"
    },
    {
        "suburb": "Wabonga",
        "postcode": "3678",
        "state": "Victoria"
    },
    {
        "suburb": "Irishtown",
        "postcode": "3451",
        "state": "Victoria"
    },
    {
        "suburb": "Blakeville",
        "postcode": "3342",
        "state": "Victoria"
    },
    {
        "suburb": "Roses Gap",
        "postcode": "3385",
        "state": "Victoria"
    },
    {
        "suburb": "Waterholes",
        "postcode": "3875",
        "state": "Victoria"
    },
    {
        "suburb": "Muskerry",
        "postcode": "3557",
        "state": "Victoria"
    },
    {
        "suburb": "Coral Sea",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Aramac",
        "postcode": "4726",
        "state": "Queensland"
    },
    {
        "suburb": "Muttaburra",
        "postcode": "4732",
        "state": "Queensland"
    },
    {
        "suburb": "Bangall",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Tablederry",
        "postcode": "4732",
        "state": "Queensland"
    },
    {
        "suburb": "Cornish Creek",
        "postcode": "4732",
        "state": "Queensland"
    },
    {
        "suburb": "Ibis",
        "postcode": "4726",
        "state": "Queensland"
    },
    {
        "suburb": "Pelican Creek",
        "postcode": "4726",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Cornish Creek",
        "postcode": "4726",
        "state": "Queensland"
    },
    {
        "suburb": "Galilee",
        "postcode": "4726",
        "state": "Queensland"
    },
    {
        "suburb": "Upland",
        "postcode": "4726",
        "state": "Queensland"
    },
    {
        "suburb": "Atherton",
        "postcode": "4883",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Tinaroo",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Walkamin",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Tolga",
        "postcode": "4882",
        "state": "Queensland"
    },
    {
        "suburb": "Tinaroo",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Kairi",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Danbulla",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Barrine",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "East Barron",
        "postcode": "4883",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Barron",
        "postcode": "4883",
        "state": "Queensland"
    },
    {
        "suburb": "Wongabel",
        "postcode": "4883",
        "state": "Queensland"
    },
    {
        "suburb": "Carrington",
        "postcode": "4883",
        "state": "Queensland"
    },
    {
        "suburb": "Aurukun",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Badu Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Torres Strait",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Dirranbandi",
        "postcode": "4486",
        "state": "Queensland"
    },
    {
        "suburb": "Bollon",
        "postcode": "4488",
        "state": "Queensland"
    },
    {
        "suburb": "Mungindi",
        "postcode": "2406",
        "state": "Queensland"
    },
    {
        "suburb": "St George",
        "postcode": "4487",
        "state": "Queensland"
    },
    {
        "suburb": "Hebel",
        "postcode": "4486",
        "state": "Queensland"
    },
    {
        "suburb": "Bamaga",
        "postcode": "4876",
        "state": "Queensland"
    },
    {
        "suburb": "Moura",
        "postcode": "4718",
        "state": "Queensland"
    },
    {
        "suburb": "Camboon",
        "postcode": "4719",
        "state": "Queensland"
    },
    {
        "suburb": "Callide",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Pheasant Creek",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Westwood",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Dululu",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Wowan",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Dumpy Creek",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Dixalea",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Dumgree",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Ulogie",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Smoky Creek",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Kokotungo",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Baralaba",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Argoon",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Murchison",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Dakenba",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Biloela",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Valentine Plains",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Lawgi Dawes",
        "postcode": "4716",
        "state": "Queensland"
    },
    {
        "suburb": "Thangool",
        "postcode": "4716",
        "state": "Queensland"
    },
    {
        "suburb": "Prospect",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Orange Creek",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Greycliffe",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Warnoah",
        "postcode": "4718",
        "state": "Queensland"
    },
    {
        "suburb": "Alberta",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Roundstone",
        "postcode": "4718",
        "state": "Queensland"
    },
    {
        "suburb": "Glenmoral",
        "postcode": "4719",
        "state": "Queensland"
    },
    {
        "suburb": "Banana",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Woolein",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Theodore",
        "postcode": "4719",
        "state": "Queensland"
    },
    {
        "suburb": "Lonesome Creek",
        "postcode": "4719",
        "state": "Queensland"
    },
    {
        "suburb": "Tarramba",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Castle Creek",
        "postcode": "4715",
        "state": "Queensland"
    },
    {
        "suburb": "Isla",
        "postcode": "4719",
        "state": "Queensland"
    },
    {
        "suburb": "Barcaldine",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Grant",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Tara Station",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Saltern Creek",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Barcaldine Downs",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Home Creek",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Moombria",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Evora",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Windorah",
        "postcode": "4481",
        "state": "Queensland"
    },
    {
        "suburb": "Jundah",
        "postcode": "4736",
        "state": "Queensland"
    },
    {
        "suburb": "Springsure",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Rolleston",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Carnarvon Park",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Arcturus",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Orion",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Togara",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Humboldt",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Coorumbene",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Lowesby",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Albinia",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Cona Creek",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Nandowrie",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Arcadia Valley",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Rewan",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Consuelo",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Wealwandangie",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Buckland",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Mantuan Downs",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Minerva",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Cairdbeign",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Jimboomba",
        "postcode": "4280",
        "state": "Queensland"
    },
    {
        "suburb": "Munruben",
        "postcode": "4125",
        "state": "Queensland"
    },
    {
        "suburb": "Greenbank",
        "postcode": "4124",
        "state": "Queensland"
    },
    {
        "suburb": "New Beith",
        "postcode": "4124",
        "state": "Queensland"
    },
    {
        "suburb": "North Maclean",
        "postcode": "4280",
        "state": "Queensland"
    },
    {
        "suburb": "South Maclean",
        "postcode": "4280",
        "state": "Queensland"
    },
    {
        "suburb": "Park Ridge South",
        "postcode": "4125",
        "state": "Queensland"
    },
    {
        "suburb": "Chambers Flat",
        "postcode": "4133",
        "state": "Queensland"
    },
    {
        "suburb": "Logan Reserve",
        "postcode": "4133",
        "state": "Queensland"
    },
    {
        "suburb": "Buccan",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Logan Village",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Stockleigh",
        "postcode": "4280",
        "state": "Queensland"
    },
    {
        "suburb": "Cedar Grove",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Cedar Vale",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Tamborine",
        "postcode": "4270",
        "state": "Queensland"
    },
    {
        "suburb": "Mundoolun",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Cedar Creek",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Boyland",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Kagaru",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Undullah",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Tamborine Mountain",
        "postcode": "4272",
        "state": "Queensland"
    },
    {
        "suburb": "Witheren",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Cainbable",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Benobble",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Sarabah",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Canungra",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Ferny Glen",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Illinbah",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Flying Fox",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Wonglepong",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Beechmont",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Southern Lamington",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Binna Burra",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Running Creek",
        "postcode": "4287",
        "state": "Queensland"
    },
    {
        "suburb": "Lyons",
        "postcode": "4124",
        "state": "Queensland"
    },
    {
        "suburb": "Tabooba",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Gleneagle",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Veresdale",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Biddaddaba",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Tabragalba",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Birnam",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Beaudesert",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Barney",
        "postcode": "4287",
        "state": "Queensland"
    },
    {
        "suburb": "Kooralbyn",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Palen Creek",
        "postcode": "4287",
        "state": "Queensland"
    },
    {
        "suburb": "Rathdowney",
        "postcode": "4287",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Lindesay",
        "postcode": "4287",
        "state": "Queensland"
    },
    {
        "suburb": "Knapp Creek",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Bromelton",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Lamington",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Allenview",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Tamrookum Creek",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Chinghee Creek",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Gipps",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Cryna",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Hillview",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Barney View",
        "postcode": "4287",
        "state": "Queensland"
    },
    {
        "suburb": "Oaky Creek",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Nindooinbah",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Kerry",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Darlington",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Laravale",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Josephville",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Veresdale Scrub",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Christmas Creek",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Clermont",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Moranbah",
        "postcode": "4744",
        "state": "Queensland"
    },
    {
        "suburb": "Frankfield",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Elgin",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Pasha",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Laglan",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Mistake Creek",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Peak Vale",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Kilcummin",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Winchester",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Wolfang",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Gemini Mountains",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Wallumbilla",
        "postcode": "4428",
        "state": "Queensland"
    },
    {
        "suburb": "Yuleba",
        "postcode": "4427",
        "state": "Queensland"
    },
    {
        "suburb": "Warkon",
        "postcode": "4417",
        "state": "Queensland"
    },
    {
        "suburb": "Pickanjinnie",
        "postcode": "4428",
        "state": "Queensland"
    },
    {
        "suburb": "Yuleba North",
        "postcode": "4427",
        "state": "Queensland"
    },
    {
        "suburb": "Yuleba South",
        "postcode": "4427",
        "state": "Queensland"
    },
    {
        "suburb": "Jackson South",
        "postcode": "4426",
        "state": "Queensland"
    },
    {
        "suburb": "Wallumbilla North",
        "postcode": "4428",
        "state": "Queensland"
    },
    {
        "suburb": "Jackson North",
        "postcode": "4426",
        "state": "Queensland"
    },
    {
        "suburb": "Jackson",
        "postcode": "4426",
        "state": "Queensland"
    },
    {
        "suburb": "Coalstoun Lakes",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Biggenden",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Didcot",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Degilbo",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Coringa",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Dallarnil",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Lakeside",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Wateranga",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Blackall",
        "postcode": "4472",
        "state": "Queensland"
    },
    {
        "suburb": "Boigu Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Bunjurgen",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Croftby",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Kalbar",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Bunburra",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Boonah",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Milbong",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Mutdapilly",
        "postcode": "4307",
        "state": "Queensland"
    },
    {
        "suburb": "Cannon Creek",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Forbes",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Lower Mount Walker",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Warrill View",
        "postcode": "4307",
        "state": "Queensland"
    },
    {
        "suburb": "Rosevale",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Walker",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Merryvale",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Walker West",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Tarome",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Moorang",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Wilsons Plains",
        "postcode": "4307",
        "state": "Queensland"
    },
    {
        "suburb": "Radford",
        "postcode": "4307",
        "state": "Queensland"
    },
    {
        "suburb": "Silverdale",
        "postcode": "4307",
        "state": "Queensland"
    },
    {
        "suburb": "Kents Lagoon",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Anthony",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Washpool",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Woolooman",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Wyaralong",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Frazerview",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Morwincha",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Fassifern",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Aratula",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Edwards",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Moogerah",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Fassifern Valley",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Templin",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Mount French",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Charlwood",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Dugandan",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Frenches Creek",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Kents Pocket",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Milford",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Allandale",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Clumber",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Alford",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Maroon",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Burnett Creek",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Hoya",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Obum Obum",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Blantyre",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Teviotville",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Kulgun",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Coleyville",
        "postcode": "4307",
        "state": "Queensland"
    },
    {
        "suburb": "Eurella",
        "postcode": "4462",
        "state": "Queensland"
    },
    {
        "suburb": "Amby",
        "postcode": "4462",
        "state": "Queensland"
    },
    {
        "suburb": "Mitchell",
        "postcode": "4465",
        "state": "Queensland"
    },
    {
        "suburb": "Womalilla",
        "postcode": "4465",
        "state": "Queensland"
    },
    {
        "suburb": "Bindebango",
        "postcode": "4488",
        "state": "Queensland"
    },
    {
        "suburb": "Forestvale",
        "postcode": "4465",
        "state": "Queensland"
    },
    {
        "suburb": "Redford",
        "postcode": "4467",
        "state": "Queensland"
    },
    {
        "suburb": "Bargunyah",
        "postcode": "4465",
        "state": "Queensland"
    },
    {
        "suburb": "V Gate",
        "postcode": "4465",
        "state": "Queensland"
    },
    {
        "suburb": "Walhallow",
        "postcode": "4462",
        "state": "Queensland"
    },
    {
        "suburb": "Kilmorey Falls",
        "postcode": "4465",
        "state": "Queensland"
    },
    {
        "suburb": "Tyrconnel",
        "postcode": "4467",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Howe",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Mungallala South",
        "postcode": "4467",
        "state": "Queensland"
    },
    {
        "suburb": "Toko",
        "postcode": "4829",
        "state": "Queensland"
    },
    {
        "suburb": "Boulia",
        "postcode": "4829",
        "state": "Queensland"
    },
    {
        "suburb": "Buckingham",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Warburton",
        "postcode": "4823",
        "state": "Queensland"
    },
    {
        "suburb": "Sturt",
        "postcode": "4829",
        "state": "Queensland"
    },
    {
        "suburb": "Georgina",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Waverley",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Carrandotta",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Min Min",
        "postcode": "4829",
        "state": "Queensland"
    },
    {
        "suburb": "Warenda",
        "postcode": "4829",
        "state": "Queensland"
    },
    {
        "suburb": "Amaroo",
        "postcode": "4829",
        "state": "Queensland"
    },
    {
        "suburb": "Wills",
        "postcode": "4829",
        "state": "Queensland"
    },
    {
        "suburb": "Bowen",
        "postcode": "4805",
        "state": "Queensland"
    },
    {
        "suburb": "Bogie",
        "postcode": "4805",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Coolon",
        "postcode": "4804",
        "state": "Queensland"
    },
    {
        "suburb": "Scottville",
        "postcode": "4804",
        "state": "Queensland"
    },
    {
        "suburb": "Collinsville",
        "postcode": "4804",
        "state": "Queensland"
    },
    {
        "suburb": "Guthalungra",
        "postcode": "4805",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Wyatt",
        "postcode": "4804",
        "state": "Queensland"
    },
    {
        "suburb": "Acacia Ridge",
        "postcode": "4110",
        "state": "Queensland"
    },
    {
        "suburb": "Sunnybank Hills",
        "postcode": "4109",
        "state": "Queensland"
    },
    {
        "suburb": "Archerfield",
        "postcode": "4108",
        "state": "Queensland"
    },
    {
        "suburb": "Albion",
        "postcode": "4010",
        "state": "Queensland"
    },
    {
        "suburb": "Clayfield",
        "postcode": "4011",
        "state": "Queensland"
    },
    {
        "suburb": "Ascot",
        "postcode": "4007",
        "state": "Queensland"
    },
    {
        "suburb": "Alderley",
        "postcode": "4051",
        "state": "Queensland"
    },
    {
        "suburb": "Algester",
        "postcode": "4115",
        "state": "Queensland"
    },
    {
        "suburb": "Calamvale",
        "postcode": "4116",
        "state": "Queensland"
    },
    {
        "suburb": "Annerley",
        "postcode": "4103",
        "state": "Queensland"
    },
    {
        "suburb": "Tarragindi",
        "postcode": "4121",
        "state": "Queensland"
    },
    {
        "suburb": "Anstead",
        "postcode": "4070",
        "state": "Queensland"
    },
    {
        "suburb": "Hamilton",
        "postcode": "4007",
        "state": "Queensland"
    },
    {
        "suburb": "Ashgrove",
        "postcode": "4060",
        "state": "Queensland"
    },
    {
        "suburb": "Enoggera",
        "postcode": "4051",
        "state": "Queensland"
    },
    {
        "suburb": "The Gap",
        "postcode": "4061",
        "state": "Queensland"
    },
    {
        "suburb": "Aspley",
        "postcode": "4034",
        "state": "Queensland"
    },
    {
        "suburb": "Geebung",
        "postcode": "4034",
        "state": "Queensland"
    },
    {
        "suburb": "Bald Hills",
        "postcode": "4036",
        "state": "Queensland"
    },
    {
        "suburb": "Bracken Ridge",
        "postcode": "4017",
        "state": "Queensland"
    },
    {
        "suburb": "Bridgeman Downs",
        "postcode": "4035",
        "state": "Queensland"
    },
    {
        "suburb": "Carseldine",
        "postcode": "4034",
        "state": "Queensland"
    },
    {
        "suburb": "Balmoral",
        "postcode": "4171",
        "state": "Queensland"
    },
    {
        "suburb": "Bulimba",
        "postcode": "4171",
        "state": "Queensland"
    },
    {
        "suburb": "Hawthorne",
        "postcode": "4171",
        "state": "Queensland"
    },
    {
        "suburb": "Morningside",
        "postcode": "4170",
        "state": "Queensland"
    },
    {
        "suburb": "Banyo",
        "postcode": "4014",
        "state": "Queensland"
    },
    {
        "suburb": "Nudgee",
        "postcode": "4014",
        "state": "Queensland"
    },
    {
        "suburb": "Bardon",
        "postcode": "4065",
        "state": "Queensland"
    },
    {
        "suburb": "Auchenflower",
        "postcode": "4066",
        "state": "Queensland"
    },
    {
        "suburb": "Toowong",
        "postcode": "4066",
        "state": "Queensland"
    },
    {
        "suburb": "Paddington",
        "postcode": "4064",
        "state": "Queensland"
    },
    {
        "suburb": "Bellbowrie",
        "postcode": "4070",
        "state": "Queensland"
    },
    {
        "suburb": "Belmont",
        "postcode": "4153",
        "state": "Queensland"
    },
    {
        "suburb": "Mackenzie",
        "postcode": "4156",
        "state": "Queensland"
    },
    {
        "suburb": "Boondall",
        "postcode": "4034",
        "state": "Queensland"
    },
    {
        "suburb": "Bowen Hills",
        "postcode": "4006",
        "state": "Queensland"
    },
    {
        "suburb": "Brighton",
        "postcode": "4017",
        "state": "Queensland"
    },
    {
        "suburb": "Brookfield",
        "postcode": "4069",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Brookfield",
        "postcode": "4069",
        "state": "Queensland"
    },
    {
        "suburb": "Enoggera Reservoir",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Rochedale",
        "postcode": "4123",
        "state": "Queensland"
    },
    {
        "suburb": "Burbank",
        "postcode": "4156",
        "state": "Queensland"
    },
    {
        "suburb": "Camp Hill",
        "postcode": "4152",
        "state": "Queensland"
    },
    {
        "suburb": "Seven Hills",
        "postcode": "4170",
        "state": "Queensland"
    },
    {
        "suburb": "Coorparoo",
        "postcode": "4151",
        "state": "Queensland"
    },
    {
        "suburb": "Carina Heights",
        "postcode": "4152",
        "state": "Queensland"
    },
    {
        "suburb": "Cannon Hill",
        "postcode": "4170",
        "state": "Queensland"
    },
    {
        "suburb": "Murarrie",
        "postcode": "4172",
        "state": "Queensland"
    },
    {
        "suburb": "Carindale",
        "postcode": "4152",
        "state": "Queensland"
    },
    {
        "suburb": "Carina",
        "postcode": "4152",
        "state": "Queensland"
    },
    {
        "suburb": "Chandler",
        "postcode": "4155",
        "state": "Queensland"
    },
    {
        "suburb": "Chapel Hill",
        "postcode": "4069",
        "state": "Queensland"
    },
    {
        "suburb": "Kenmore Hills",
        "postcode": "4069",
        "state": "Queensland"
    },
    {
        "suburb": "Indooroopilly",
        "postcode": "4068",
        "state": "Queensland"
    },
    {
        "suburb": "Kenmore",
        "postcode": "4069",
        "state": "Queensland"
    },
    {
        "suburb": "Chelmer",
        "postcode": "4068",
        "state": "Queensland"
    },
    {
        "suburb": "Chermside",
        "postcode": "4032",
        "state": "Queensland"
    },
    {
        "suburb": "Chermside West",
        "postcode": "4032",
        "state": "Queensland"
    },
    {
        "suburb": "Brisbane City",
        "postcode": "4000",
        "state": "Queensland"
    },
    {
        "suburb": "Petrie Terrace",
        "postcode": "4000",
        "state": "Queensland"
    },
    {
        "suburb": "Spring Hill",
        "postcode": "4000",
        "state": "Queensland"
    },
    {
        "suburb": "Hendra",
        "postcode": "4011",
        "state": "Queensland"
    },
    {
        "suburb": "Wooloowin",
        "postcode": "4030",
        "state": "Queensland"
    },
    {
        "suburb": "Coopers Plains",
        "postcode": "4108",
        "state": "Queensland"
    },
    {
        "suburb": "Greenslopes",
        "postcode": "4120",
        "state": "Queensland"
    },
    {
        "suburb": "Corinda",
        "postcode": "4075",
        "state": "Queensland"
    },
    {
        "suburb": "Sherwood",
        "postcode": "4075",
        "state": "Queensland"
    },
    {
        "suburb": "Darra",
        "postcode": "4076",
        "state": "Queensland"
    },
    {
        "suburb": "Oxley",
        "postcode": "4075",
        "state": "Queensland"
    },
    {
        "suburb": "Sumner",
        "postcode": "4074",
        "state": "Queensland"
    },
    {
        "suburb": "Deagon",
        "postcode": "4017",
        "state": "Queensland"
    },
    {
        "suburb": "Forest Lake",
        "postcode": "4078",
        "state": "Queensland"
    },
    {
        "suburb": "Doolandella",
        "postcode": "4077",
        "state": "Queensland"
    },
    {
        "suburb": "Durack",
        "postcode": "4077",
        "state": "Queensland"
    },
    {
        "suburb": "Inala",
        "postcode": "4077",
        "state": "Queensland"
    },
    {
        "suburb": "Dutton Park",
        "postcode": "4102",
        "state": "Queensland"
    },
    {
        "suburb": "East Brisbane",
        "postcode": "4169",
        "state": "Queensland"
    },
    {
        "suburb": "Eight Mile Plains",
        "postcode": "4113",
        "state": "Queensland"
    },
    {
        "suburb": "Runcorn",
        "postcode": "4113",
        "state": "Queensland"
    },
    {
        "suburb": "Ellen Grove",
        "postcode": "4078",
        "state": "Queensland"
    },
    {
        "suburb": "Gaythorne",
        "postcode": "4051",
        "state": "Queensland"
    },
    {
        "suburb": "Everton Park",
        "postcode": "4053",
        "state": "Queensland"
    },
    {
        "suburb": "Stafford Heights",
        "postcode": "4053",
        "state": "Queensland"
    },
    {
        "suburb": "Fairfield",
        "postcode": "4103",
        "state": "Queensland"
    },
    {
        "suburb": "Yeronga",
        "postcode": "4104",
        "state": "Queensland"
    },
    {
        "suburb": "Ferny Grove",
        "postcode": "4055",
        "state": "Queensland"
    },
    {
        "suburb": "Fig Tree Pocket",
        "postcode": "4069",
        "state": "Queensland"
    },
    {
        "suburb": "Fortitude Valley",
        "postcode": "4006",
        "state": "Queensland"
    },
    {
        "suburb": "New Farm",
        "postcode": "4005",
        "state": "Queensland"
    },
    {
        "suburb": "Graceville",
        "postcode": "4075",
        "state": "Queensland"
    },
    {
        "suburb": "Grange",
        "postcode": "4051",
        "state": "Queensland"
    },
    {
        "suburb": "Wilston",
        "postcode": "4051",
        "state": "Queensland"
    },
    {
        "suburb": "Gumdale",
        "postcode": "4154",
        "state": "Queensland"
    },
    {
        "suburb": "Ransome",
        "postcode": "4154",
        "state": "Queensland"
    },
    {
        "suburb": "Norman Park",
        "postcode": "4170",
        "state": "Queensland"
    },
    {
        "suburb": "Moreton Bay",
        "postcode": "4178",
        "state": "Queensland"
    },
    {
        "suburb": "Port Of Brisbane",
        "postcode": "4178",
        "state": "Queensland"
    },
    {
        "suburb": "Wynnum",
        "postcode": "4178",
        "state": "Queensland"
    },
    {
        "suburb": "Lytton",
        "postcode": "4178",
        "state": "Queensland"
    },
    {
        "suburb": "Wynnum West",
        "postcode": "4178",
        "state": "Queensland"
    },
    {
        "suburb": "Hemmant",
        "postcode": "4174",
        "state": "Queensland"
    },
    {
        "suburb": "Nundah",
        "postcode": "4012",
        "state": "Queensland"
    },
    {
        "suburb": "Herston",
        "postcode": "4006",
        "state": "Queensland"
    },
    {
        "suburb": "Highgate Hill",
        "postcode": "4101",
        "state": "Queensland"
    },
    {
        "suburb": "Holland Park",
        "postcode": "4121",
        "state": "Queensland"
    },
    {
        "suburb": "Holland Park West",
        "postcode": "4121",
        "state": "Queensland"
    },
    {
        "suburb": "St Lucia",
        "postcode": "4067",
        "state": "Queensland"
    },
    {
        "suburb": "Taringa",
        "postcode": "4068",
        "state": "Queensland"
    },
    {
        "suburb": "Jamboree Heights",
        "postcode": "4074",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Ommaney",
        "postcode": "4074",
        "state": "Queensland"
    },
    {
        "suburb": "Jindalee",
        "postcode": "4074",
        "state": "Queensland"
    },
    {
        "suburb": "Kangaroo Point",
        "postcode": "4169",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Manchester",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Karana Downs",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Crosby",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Kholo",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Chuwar",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Banks Creek",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "England Creek",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Gordon Park",
        "postcode": "4031",
        "state": "Queensland"
    },
    {
        "suburb": "Kedron",
        "postcode": "4031",
        "state": "Queensland"
    },
    {
        "suburb": "Stafford",
        "postcode": "4053",
        "state": "Queensland"
    },
    {
        "suburb": "Kelvin Grove",
        "postcode": "4059",
        "state": "Queensland"
    },
    {
        "suburb": "Newmarket",
        "postcode": "4051",
        "state": "Queensland"
    },
    {
        "suburb": "Keperra",
        "postcode": "4054",
        "state": "Queensland"
    },
    {
        "suburb": "Mitchelton",
        "postcode": "4053",
        "state": "Queensland"
    },
    {
        "suburb": "Kuraby",
        "postcode": "4112",
        "state": "Queensland"
    },
    {
        "suburb": "Lota",
        "postcode": "4179",
        "state": "Queensland"
    },
    {
        "suburb": "Manly West",
        "postcode": "4179",
        "state": "Queensland"
    },
    {
        "suburb": "Lutwyche",
        "postcode": "4030",
        "state": "Queensland"
    },
    {
        "suburb": "McDowall",
        "postcode": "4053",
        "state": "Queensland"
    },
    {
        "suburb": "Macgregor",
        "postcode": "4109",
        "state": "Queensland"
    },
    {
        "suburb": "Manly",
        "postcode": "4179",
        "state": "Queensland"
    },
    {
        "suburb": "Mansfield",
        "postcode": "4122",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Gravatt East",
        "postcode": "4122",
        "state": "Queensland"
    },
    {
        "suburb": "Middle Park",
        "postcode": "4074",
        "state": "Queensland"
    },
    {
        "suburb": "Westlake",
        "postcode": "4074",
        "state": "Queensland"
    },
    {
        "suburb": "Milton",
        "postcode": "4064",
        "state": "Queensland"
    },
    {
        "suburb": "Moggill",
        "postcode": "4070",
        "state": "Queensland"
    },
    {
        "suburb": "Moorooka",
        "postcode": "4105",
        "state": "Queensland"
    },
    {
        "suburb": "Salisbury",
        "postcode": "4107",
        "state": "Queensland"
    },
    {
        "suburb": "Bulwer",
        "postcode": "4025",
        "state": "Queensland"
    },
    {
        "suburb": "Cowan Cowan",
        "postcode": "4025",
        "state": "Queensland"
    },
    {
        "suburb": "Kooringal",
        "postcode": "4025",
        "state": "Queensland"
    },
    {
        "suburb": "Moreton Island",
        "postcode": "4025",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Gravatt",
        "postcode": "4122",
        "state": "Queensland"
    },
    {
        "suburb": "Nathan",
        "postcode": "4111",
        "state": "Queensland"
    },
    {
        "suburb": "Teneriffe",
        "postcode": "4005",
        "state": "Queensland"
    },
    {
        "suburb": "Newstead",
        "postcode": "4006",
        "state": "Queensland"
    },
    {
        "suburb": "Northgate",
        "postcode": "4013",
        "state": "Queensland"
    },
    {
        "suburb": "Nudgee Beach",
        "postcode": "4014",
        "state": "Queensland"
    },
    {
        "suburb": "Wavell Heights",
        "postcode": "4012",
        "state": "Queensland"
    },
    {
        "suburb": "Willawong",
        "postcode": "4110",
        "state": "Queensland"
    },
    {
        "suburb": "Pallara",
        "postcode": "4110",
        "state": "Queensland"
    },
    {
        "suburb": "Heathwood",
        "postcode": "4110",
        "state": "Queensland"
    },
    {
        "suburb": "Parkinson",
        "postcode": "4115",
        "state": "Queensland"
    },
    {
        "suburb": "Drewvale",
        "postcode": "4116",
        "state": "Queensland"
    },
    {
        "suburb": "Pinjarra Hills",
        "postcode": "4069",
        "state": "Queensland"
    },
    {
        "suburb": "Pinkenba",
        "postcode": "4008",
        "state": "Queensland"
    },
    {
        "suburb": "Eagle Farm",
        "postcode": "4009",
        "state": "Queensland"
    },
    {
        "suburb": "Pullenvale",
        "postcode": "4069",
        "state": "Queensland"
    },
    {
        "suburb": "Red Hill",
        "postcode": "4059",
        "state": "Queensland"
    },
    {
        "suburb": "Richlands",
        "postcode": "4077",
        "state": "Queensland"
    },
    {
        "suburb": "Riverhills",
        "postcode": "4074",
        "state": "Queensland"
    },
    {
        "suburb": "Robertson",
        "postcode": "4109",
        "state": "Queensland"
    },
    {
        "suburb": "Sunnybank",
        "postcode": "4109",
        "state": "Queensland"
    },
    {
        "suburb": "Rocklea",
        "postcode": "4106",
        "state": "Queensland"
    },
    {
        "suburb": "Tennyson",
        "postcode": "4105",
        "state": "Queensland"
    },
    {
        "suburb": "Shorncliffe",
        "postcode": "4017",
        "state": "Queensland"
    },
    {
        "suburb": "Sandgate",
        "postcode": "4017",
        "state": "Queensland"
    },
    {
        "suburb": "Sinnamon Park",
        "postcode": "4073",
        "state": "Queensland"
    },
    {
        "suburb": "Seventeen Mile Rocks",
        "postcode": "4073",
        "state": "Queensland"
    },
    {
        "suburb": "South Brisbane",
        "postcode": "4101",
        "state": "Queensland"
    },
    {
        "suburb": "Woolloongabba",
        "postcode": "4102",
        "state": "Queensland"
    },
    {
        "suburb": "Stretton",
        "postcode": "4116",
        "state": "Queensland"
    },
    {
        "suburb": "Karawatha",
        "postcode": "4117",
        "state": "Queensland"
    },
    {
        "suburb": "Taigum",
        "postcode": "4018",
        "state": "Queensland"
    },
    {
        "suburb": "Fitzgibbon",
        "postcode": "4018",
        "state": "Queensland"
    },
    {
        "suburb": "Tingalpa",
        "postcode": "4173",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Kedron",
        "postcode": "4055",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Mount Gravatt",
        "postcode": "4122",
        "state": "Queensland"
    },
    {
        "suburb": "Virginia",
        "postcode": "4014",
        "state": "Queensland"
    },
    {
        "suburb": "Wacol",
        "postcode": "4076",
        "state": "Queensland"
    },
    {
        "suburb": "Wakerley",
        "postcode": "4154",
        "state": "Queensland"
    },
    {
        "suburb": "West End",
        "postcode": "4101",
        "state": "Queensland"
    },
    {
        "suburb": "Windsor",
        "postcode": "4030",
        "state": "Queensland"
    },
    {
        "suburb": "Wishart",
        "postcode": "4122",
        "state": "Queensland"
    },
    {
        "suburb": "Kalinga",
        "postcode": "4030",
        "state": "Queensland"
    },
    {
        "suburb": "Yeerongpilly",
        "postcode": "4105",
        "state": "Queensland"
    },
    {
        "suburb": "Zillmere",
        "postcode": "4034",
        "state": "Queensland"
    },
    {
        "suburb": "Clarke Creek",
        "postcode": "4705",
        "state": "Queensland"
    },
    {
        "suburb": "Lotus Creek",
        "postcode": "4705",
        "state": "Queensland"
    },
    {
        "suburb": "Dysart",
        "postcode": "4745",
        "state": "Queensland"
    },
    {
        "suburb": "The Percy Group",
        "postcode": "4707",
        "state": "Queensland"
    },
    {
        "suburb": "Middlemount",
        "postcode": "4746",
        "state": "Queensland"
    },
    {
        "suburb": "St Lawrence",
        "postcode": "4707",
        "state": "Queensland"
    },
    {
        "suburb": "Clairview",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Carmila",
        "postcode": "4739",
        "state": "Queensland"
    },
    {
        "suburb": "Mackenzie River",
        "postcode": "4705",
        "state": "Queensland"
    },
    {
        "suburb": "May Downs",
        "postcode": "4746",
        "state": "Queensland"
    },
    {
        "suburb": "Ilbilbie",
        "postcode": "4738",
        "state": "Queensland"
    },
    {
        "suburb": "Thargomindah",
        "postcode": "4492",
        "state": "Queensland"
    },
    {
        "suburb": "Nockatunga",
        "postcode": "4492",
        "state": "Queensland"
    },
    {
        "suburb": "Norley",
        "postcode": "4492",
        "state": "Queensland"
    },
    {
        "suburb": "Hungerford",
        "postcode": "4493",
        "state": "Queensland"
    },
    {
        "suburb": "Bulloo Downs",
        "postcode": "4492",
        "state": "Queensland"
    },
    {
        "suburb": "Bundaberg West",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Norville",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Millbank",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Walkervale",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Kalkie",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Thabeban",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Bundaberg Central",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Branyan",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Svensson Heights",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Bundaberg North",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Avenell Heights",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Bundaberg East",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Avoca",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Kensington",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Kepnock",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Bundaberg South",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Highland Plains",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Blythdale",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Muckadilla",
        "postcode": "4461",
        "state": "Queensland"
    },
    {
        "suburb": "Injune",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Simmie",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Baffle West",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Hutton",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Hutton Creek",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Westgrove",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Dawson",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Beilba",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Durham Downs",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Pony Hills",
        "postcode": "4454",
        "state": "Queensland"
    },
    {
        "suburb": "Mooga",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Cornwall",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Bymount",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Bindango",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Gunnewin",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Abundance",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Euthulla",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Bungil",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Orange Hill",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Tingun",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Eumamurrin",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Ayr",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Home Hill",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Rita Island",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Carstairs",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Inkerman",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Jarvisfield",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Mulgrave",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Rangemore",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Airville",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Osborne",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Majors Creek",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Horseshoe Lagoon",
        "postcode": "4809",
        "state": "Queensland"
    },
    {
        "suburb": "Colevale",
        "postcode": "4808",
        "state": "Queensland"
    },
    {
        "suburb": "Alva",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Groper Creek",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Wangaratta",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Brandon",
        "postcode": "4808",
        "state": "Queensland"
    },
    {
        "suburb": "Giru",
        "postcode": "4809",
        "state": "Queensland"
    },
    {
        "suburb": "Barratta",
        "postcode": "4809",
        "state": "Queensland"
    },
    {
        "suburb": "Jerona",
        "postcode": "4809",
        "state": "Queensland"
    },
    {
        "suburb": "Airdmillan",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Wunjunga",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Fredericksfield",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Swans Lagoon",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Haughton",
        "postcode": "4809",
        "state": "Queensland"
    },
    {
        "suburb": "Mona Park",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "McDesme",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Dalbeg",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Eight Mile Creek",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Clare",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Kelly",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Gidya",
        "postcode": "4824",
        "state": "Queensland"
    },
    {
        "suburb": "Nicholson",
        "postcode": "4830",
        "state": "Queensland"
    },
    {
        "suburb": "Gregory",
        "postcode": "4830",
        "state": "Queensland"
    },
    {
        "suburb": "Burketown",
        "postcode": "4830",
        "state": "Queensland"
    },
    {
        "suburb": "Coral Cove",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Innes Park",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Bargara",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Mon Repos",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Qunaba",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Elliott Heads",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Woongarra",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Burnett Heads",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Gooburrum",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Alloway",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Windermere",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Rubyanna",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Winfield",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Coonarr",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Moore Park Beach",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Sharon",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "South Bingera",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "South Kolan",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Bucca",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Watalgan",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Yandaran",
        "postcode": "4673",
        "state": "Queensland"
    },
    {
        "suburb": "Oakwood",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Elliott",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Avondale",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Waterloo",
        "postcode": "4673",
        "state": "Queensland"
    },
    {
        "suburb": "Abbotsford",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Pine Creek",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Moorland",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Calavos",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Meadowvale",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Kinkuna",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Welcome Creek",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Fairymead",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Mullett Creek",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Bellara",
        "postcode": "4507",
        "state": "Queensland"
    },
    {
        "suburb": "Banksia Beach",
        "postcode": "4507",
        "state": "Queensland"
    },
    {
        "suburb": "Bongaree",
        "postcode": "4507",
        "state": "Queensland"
    },
    {
        "suburb": "Woorim",
        "postcode": "4507",
        "state": "Queensland"
    },
    {
        "suburb": "White Patch",
        "postcode": "4507",
        "state": "Queensland"
    },
    {
        "suburb": "Beerburrum",
        "postcode": "4517",
        "state": "Queensland"
    },
    {
        "suburb": "Narangba",
        "postcode": "4504",
        "state": "Queensland"
    },
    {
        "suburb": "Burpengary",
        "postcode": "4505",
        "state": "Queensland"
    },
    {
        "suburb": "Morayfield",
        "postcode": "4506",
        "state": "Queensland"
    },
    {
        "suburb": "Caboolture",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Ningi",
        "postcode": "4511",
        "state": "Queensland"
    },
    {
        "suburb": "Bellmere",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Moodlu",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Sandstone Point",
        "postcode": "4511",
        "state": "Queensland"
    },
    {
        "suburb": "Donnybrook",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Toorbul",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Beachmere",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Burpengary East",
        "postcode": "4505",
        "state": "Queensland"
    },
    {
        "suburb": "Meldale",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Godwin Beach",
        "postcode": "4511",
        "state": "Queensland"
    },
    {
        "suburb": "Delaneys Creek",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Booroobin",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Stanmore",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Commissioners Flat",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Bellthorpe",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Stony Creek",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Woodford",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Wamuran Basin",
        "postcode": "4512",
        "state": "Queensland"
    },
    {
        "suburb": "D'Aguilar",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Wamuran",
        "postcode": "4512",
        "state": "Queensland"
    },
    {
        "suburb": "Bracalba",
        "postcode": "4512",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Mee",
        "postcode": "4521",
        "state": "Queensland"
    },
    {
        "suburb": "Neurum",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Cedarton",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Delaney",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Elimbah",
        "postcode": "4516",
        "state": "Queensland"
    },
    {
        "suburb": "Campbells Pocket",
        "postcode": "4521",
        "state": "Queensland"
    },
    {
        "suburb": "Moorina",
        "postcode": "4506",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Caboolture",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Rocksberg",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Deception Bay",
        "postcode": "4508",
        "state": "Queensland"
    },
    {
        "suburb": "Caboolture South",
        "postcode": "4510",
        "state": "Queensland"
    },
    {
        "suburb": "Redlynch",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Brinsmead",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Yorkeys Knob",
        "postcode": "4878",
        "state": "Queensland"
    },
    {
        "suburb": "Holloways Beach",
        "postcode": "4878",
        "state": "Queensland"
    },
    {
        "suburb": "Machans Beach",
        "postcode": "4878",
        "state": "Queensland"
    },
    {
        "suburb": "Smithfield",
        "postcode": "4878",
        "state": "Queensland"
    },
    {
        "suburb": "Caravonica",
        "postcode": "4878",
        "state": "Queensland"
    },
    {
        "suburb": "Barron Gorge",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Kamerunga",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Lamb Range",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Mooroobool",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Manunda",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Westcourt",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Bungalow",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Whitfield",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Manoora",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Portsmith",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Parramatta Park",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Cairns North",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Cairns City",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Freshwater",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Stratford",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Edge Hill",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Barron",
        "postcode": "4878",
        "state": "Queensland"
    },
    {
        "suburb": "Aeroglen",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Clifton Beach",
        "postcode": "4879",
        "state": "Queensland"
    },
    {
        "suburb": "Trinity Beach",
        "postcode": "4879",
        "state": "Queensland"
    },
    {
        "suburb": "Trinity Park",
        "postcode": "4879",
        "state": "Queensland"
    },
    {
        "suburb": "Palm Cove",
        "postcode": "4879",
        "state": "Queensland"
    },
    {
        "suburb": "Macalister Range",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Kewarra Beach",
        "postcode": "4879",
        "state": "Queensland"
    },
    {
        "suburb": "Ellis Beach",
        "postcode": "4879",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Sheridan",
        "postcode": "4868",
        "state": "Queensland"
    },
    {
        "suburb": "Bentley Park",
        "postcode": "4869",
        "state": "Queensland"
    },
    {
        "suburb": "Edmonton",
        "postcode": "4869",
        "state": "Queensland"
    },
    {
        "suburb": "Woree",
        "postcode": "4868",
        "state": "Queensland"
    },
    {
        "suburb": "Gordonvale",
        "postcode": "4865",
        "state": "Queensland"
    },
    {
        "suburb": "Green Island",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "East Trinity",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "White Rock",
        "postcode": "4868",
        "state": "Queensland"
    },
    {
        "suburb": "Bayview Heights",
        "postcode": "4868",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Peter",
        "postcode": "4869",
        "state": "Queensland"
    },
    {
        "suburb": "Wrights Creek",
        "postcode": "4869",
        "state": "Queensland"
    },
    {
        "suburb": "Packers Camp",
        "postcode": "4865",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Boughton",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Kanimbla",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "Earlville",
        "postcode": "4870",
        "state": "Queensland"
    },
    {
        "suburb": "East Russell",
        "postcode": "4861",
        "state": "Queensland"
    },
    {
        "suburb": "Fitzroy Island",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Green Hill",
        "postcode": "4865",
        "state": "Queensland"
    },
    {
        "suburb": "Aloomba",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Fishery Falls",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Little Mulgrave",
        "postcode": "4865",
        "state": "Queensland"
    },
    {
        "suburb": "Goldsborough",
        "postcode": "4865",
        "state": "Queensland"
    },
    {
        "suburb": "Bellenden Ker",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Bartle Frere",
        "postcode": "4861",
        "state": "Queensland"
    },
    {
        "suburb": "Ngatjan",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Waugh Pocket",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Eubenangee",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Bramston Beach",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Babinda",
        "postcode": "4861",
        "state": "Queensland"
    },
    {
        "suburb": "Mirriwinni",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Deeral",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Woopen Creek",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Boyne Island",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Tannum Sands",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Iveragh",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Wurdong Heights",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Calliope",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "River Ranch",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Benaraby",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Beecher",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Burua",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Machine Creek",
        "postcode": "4695",
        "state": "Queensland"
    },
    {
        "suburb": "Boyne Valley",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Larcom",
        "postcode": "4695",
        "state": "Queensland"
    },
    {
        "suburb": "Targinnie",
        "postcode": "4694",
        "state": "Queensland"
    },
    {
        "suburb": "Yarwun",
        "postcode": "4694",
        "state": "Queensland"
    },
    {
        "suburb": "West Stowe",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Wooderson",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Boynedale",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Diglum",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Curtis Island",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Tablelands",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Ambrose",
        "postcode": "4695",
        "state": "Queensland"
    },
    {
        "suburb": "Raglan",
        "postcode": "4697",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Alma",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Bracewell",
        "postcode": "4695",
        "state": "Queensland"
    },
    {
        "suburb": "The Narrows",
        "postcode": "4695",
        "state": "Queensland"
    },
    {
        "suburb": "Darts Creek",
        "postcode": "4695",
        "state": "Queensland"
    },
    {
        "suburb": "Aroona",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Currimundi",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Battery Hill",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Dicky Beach",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Moffat Beach",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Caloundra West",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Caloundra",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Shelly Beach",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Little Mountain",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Meridan Plains",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Coochin Creek",
        "postcode": "4519",
        "state": "Queensland"
    },
    {
        "suburb": "Pelican Waters",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Golden Beach",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Kings Beach",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Bells Creek",
        "postcode": "4551",
        "state": "Queensland"
    },
    {
        "suburb": "Minyama",
        "postcode": "4575",
        "state": "Queensland"
    },
    {
        "suburb": "Wurtulla",
        "postcode": "4575",
        "state": "Queensland"
    },
    {
        "suburb": "Parrearra",
        "postcode": "4575",
        "state": "Queensland"
    },
    {
        "suburb": "Warana",
        "postcode": "4575",
        "state": "Queensland"
    },
    {
        "suburb": "Buddina",
        "postcode": "4575",
        "state": "Queensland"
    },
    {
        "suburb": "Bokarina",
        "postcode": "4575",
        "state": "Queensland"
    },
    {
        "suburb": "Palmview",
        "postcode": "4553",
        "state": "Queensland"
    },
    {
        "suburb": "North Maleny",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Cambroon",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Curramore",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Kidaman Creek",
        "postcode": "4574",
        "state": "Queensland"
    },
    {
        "suburb": "Conondale",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Reesville",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Witta",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Elaman Creek",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Balmoral Ridge",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Maleny",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Crohamhurst",
        "postcode": "4519",
        "state": "Queensland"
    },
    {
        "suburb": "Glass House Mountains",
        "postcode": "4518",
        "state": "Queensland"
    },
    {
        "suburb": "Peachester",
        "postcode": "4519",
        "state": "Queensland"
    },
    {
        "suburb": "Wootha",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Bald Knob",
        "postcode": "4552",
        "state": "Queensland"
    },
    {
        "suburb": "Beerwah",
        "postcode": "4519",
        "state": "Queensland"
    },
    {
        "suburb": "Mooloolah Valley",
        "postcode": "4553",
        "state": "Queensland"
    },
    {
        "suburb": "Landsborough",
        "postcode": "4550",
        "state": "Queensland"
    },
    {
        "suburb": "Bribie Island North",
        "postcode": "4507",
        "state": "Queensland"
    },
    {
        "suburb": "Glenview",
        "postcode": "4553",
        "state": "Queensland"
    },
    {
        "suburb": "Diamond Valley",
        "postcode": "4553",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Mellum",
        "postcode": "4550",
        "state": "Queensland"
    },
    {
        "suburb": "Cambooya",
        "postcode": "4358",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Rascal",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Hodgson Vale",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Wyreema",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Westbrook",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Finnie",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Vale View",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Top Camp",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Preston",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Hirstglen",
        "postcode": "4359",
        "state": "Queensland"
    },
    {
        "suburb": "Budgee",
        "postcode": "4359",
        "state": "Queensland"
    },
    {
        "suburb": "Ascot",
        "postcode": "4360",
        "state": "Queensland"
    },
    {
        "suburb": "West Haldon",
        "postcode": "4359",
        "state": "Queensland"
    },
    {
        "suburb": "Ramsay",
        "postcode": "4358",
        "state": "Queensland"
    },
    {
        "suburb": "Felton",
        "postcode": "4358",
        "state": "Queensland"
    },
    {
        "suburb": "Nobby",
        "postcode": "4360",
        "state": "Queensland"
    },
    {
        "suburb": "East Greenmount",
        "postcode": "4359",
        "state": "Queensland"
    },
    {
        "suburb": "Greenmount",
        "postcode": "4359",
        "state": "Queensland"
    },
    {
        "suburb": "Murrigal",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Tully",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Cardwell",
        "postcode": "4849",
        "state": "Queensland"
    },
    {
        "suburb": "Rungoo",
        "postcode": "4849",
        "state": "Queensland"
    },
    {
        "suburb": "South Mission Beach",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "Wongaling Beach",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "Dunk",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "Hinchinbrook",
        "postcode": "4849",
        "state": "Queensland"
    },
    {
        "suburb": "Damper Creek",
        "postcode": "4849",
        "state": "Queensland"
    },
    {
        "suburb": "Cardstone",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Feluga",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "East Feluga",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Midgenoo",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Bulgun",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Merryburn",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Walter Hill",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Munro Plains",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Warrami",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Lumholtz",
        "postcode": "4849",
        "state": "Queensland"
    },
    {
        "suburb": "Tam O'Shanter",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "Carmoo",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "Hull Heads",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Tully Heads",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Rockingham",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Lower Tully",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Murray Upper",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Jarra Creek",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Euramo",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Silky Oak",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Bilyana",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Kennedy",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Carruchan",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Ellerbeck",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Dingo Pocket",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Normanton",
        "postcode": "4890",
        "state": "Queensland"
    },
    {
        "suburb": "Savannah",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Maramie",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Yagoonya",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Howitt",
        "postcode": "4890",
        "state": "Queensland"
    },
    {
        "suburb": "Karumba",
        "postcode": "4891",
        "state": "Queensland"
    },
    {
        "suburb": "Stokes",
        "postcode": "4823",
        "state": "Queensland"
    },
    {
        "suburb": "Fielding",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Carpentaria",
        "postcode": "4823",
        "state": "Queensland"
    },
    {
        "suburb": "Charters Towers City",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Richmond Hill",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Mosman Park",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Queenton",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Grand Secret",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Millchester",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Towers Hill",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Alabama Hill",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Toll",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Cherbourg",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Auburn",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Barakula",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Langlands",
        "postcode": "4410",
        "state": "Queensland"
    },
    {
        "suburb": "Cameby",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Cadarga",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Fairyland",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Kogan",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Beelbee",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Chinchilla",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Montrose",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Rywung",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Goombi",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Burra Burri",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Kragra",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Sujeewong",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Pelican",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Brigalow",
        "postcode": "4412",
        "state": "Queensland"
    },
    {
        "suburb": "Boonarga",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Chances Plain",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Canaga",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Burncluith",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Hopeland",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Darr Creek",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Blackswamp",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Wieambilla",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Wychie",
        "postcode": "4412",
        "state": "Queensland"
    },
    {
        "suburb": "Red Hill",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Durah",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Clifton",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Headington Hill",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Manapouri",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Missen Flat",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Nevilton",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Back Plains",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Ryeford",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Victoria Hill",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Elphinstone",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Molar",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Kings Creek",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Felton South",
        "postcode": "4358",
        "state": "Queensland"
    },
    {
        "suburb": "Sandy Camp",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Ellangowan",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Leyburn",
        "postcode": "4365",
        "state": "Queensland"
    },
    {
        "suburb": "Spring Creek",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Pilton",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Cloncurry",
        "postcode": "4824",
        "state": "Queensland"
    },
    {
        "suburb": "Dajarra",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Four Ways",
        "postcode": "4824",
        "state": "Queensland"
    },
    {
        "suburb": "Duchess",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Lakeland",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Cooktown",
        "postcode": "4895",
        "state": "Queensland"
    },
    {
        "suburb": "Yarraden",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Coen",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Iron Range",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Lockhart River",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Starcke",
        "postcode": "4895",
        "state": "Queensland"
    },
    {
        "suburb": "Mission River",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Lakefield",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Hope Vale",
        "postcode": "4895",
        "state": "Queensland"
    },
    {
        "suburb": "Archer River",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Wenlock",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Shelburne",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Rossville",
        "postcode": "4895",
        "state": "Queensland"
    },
    {
        "suburb": "Laura",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Jardine River",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Mapoon",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Lizard",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Palmer",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Bloomfield",
        "postcode": "4895",
        "state": "Queensland"
    },
    {
        "suburb": "Calico Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Wilsons Pocket",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Cooloola Cove",
        "postcode": "4580",
        "state": "Queensland"
    },
    {
        "suburb": "Tin Can Bay",
        "postcode": "4580",
        "state": "Queensland"
    },
    {
        "suburb": "Cooloola",
        "postcode": "4580",
        "state": "Queensland"
    },
    {
        "suburb": "Inskip",
        "postcode": "4581",
        "state": "Queensland"
    },
    {
        "suburb": "Rainbow Beach",
        "postcode": "4581",
        "state": "Queensland"
    },
    {
        "suburb": "Toolara Forest",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Neerdie",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Downsfield",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Kia Ora",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Goomboorian",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Wolvi",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Coondoo",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Neusa Vale",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Beenaam Valley",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Cedar Pocket",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Greens Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Canina",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Ross Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "East Deep Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Veteran",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "North Deep Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Araluen",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Banks Pocket",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Victory Heights",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Corella",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Tamaree",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Chatsworth",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Bells Bridge",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "The Palms",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Glastonbury",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Pie Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Mothar Mountain",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Tuchekoi",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Woondum",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Tandur",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "The Dawn",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Jones Hill",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Langshaw",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Marys Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Widgee Crossing South",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Scrubby Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Fishermans Pocket",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Widgee Crossing North",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Two Mile",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Nahrunda",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "McIntosh Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Long Flat",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Glastonbury",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Amamoor Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Borumba",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Imbil",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Brooloo",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Moy Pocket",
        "postcode": "4574",
        "state": "Queensland"
    },
    {
        "suburb": "Bollier",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Carters Ridge",
        "postcode": "4563",
        "state": "Queensland"
    },
    {
        "suburb": "Melawondi",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Kandanga Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Kandanga",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Amamoor",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Lagoon Pocket",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Gilldora",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Kybong",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Traveston",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Coles Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Kandanga",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Bella Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Mooloo",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Southside",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Gympie",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Monkland",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Glanmire",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Highfields",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Meringandan",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Cabarlah",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Geham",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Blue Mountain Heights",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Kleinton",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Birnam",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Cawdor",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Woolmer",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Emu Creek",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Ravensbourne",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Anduramba",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Groomsville",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Coalbank",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Pinelands",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Crows Nest",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Pechey",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Djuan",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Hampton",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Douglas",
        "postcode": "4354",
        "state": "Queensland"
    },
    {
        "suburb": "St Aubyn",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Thornville",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Palmtree",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Perseverance",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Cressbrook Creek",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Plainby",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Grapetree",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Glenaven",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Pinelands",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "The Bluff",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Mountain Camp",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Haden",
        "postcode": "4353",
        "state": "Queensland"
    },
    {
        "suburb": "Bergen",
        "postcode": "4353",
        "state": "Queensland"
    },
    {
        "suburb": "Cherry Creek",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Jones Gully",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Pierces Creek",
        "postcode": "4355",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Binga",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Googa Creek",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Merritts Creek",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Whichello",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Coralie",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Croydon",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Karron",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Blackbull",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "East Creek",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Claraville",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Esmeralda",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Victoria Vale",
        "postcode": "4822",
        "state": "Queensland"
    },
    {
        "suburb": "Bellfield",
        "postcode": "4822",
        "state": "Queensland"
    },
    {
        "suburb": "Dalby",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Valley Of Lagoons",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Pentland",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Basalt",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Reid River",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Paluma",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Dotswood",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Crimea",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Black Jack",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Ravenswood",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Greenvale",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Seventy Mile",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Breddan",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Broughton",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Southern Cross",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Hervey Range",
        "postcode": "4817",
        "state": "Queensland"
    },
    {
        "suburb": "Dauan Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Birdsville",
        "postcode": "4482",
        "state": "Queensland"
    },
    {
        "suburb": "Bedourie",
        "postcode": "4829",
        "state": "Queensland"
    },
    {
        "suburb": "Doomadgee",
        "postcode": "4830",
        "state": "Queensland"
    },
    {
        "suburb": "Gangalidda",
        "postcode": "4830",
        "state": "Queensland"
    },
    {
        "suburb": "Finlayvale",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Miallo",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Killaloe",
        "postcode": "4877",
        "state": "Queensland"
    },
    {
        "suburb": "Thornton Beach",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Port Douglas",
        "postcode": "4877",
        "state": "Queensland"
    },
    {
        "suburb": "Kimberley",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Low Isles",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Oak Beach",
        "postcode": "4877",
        "state": "Queensland"
    },
    {
        "suburb": "Cassowary",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Spurgeon",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Dedin",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Cape Tribulation",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Stewart Creek Valley",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Daintree",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Diwan",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Cow Bay",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Forest Creek",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Lower Daintree",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Wonga Beach",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Bamboo",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Rocky Point",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Newell",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Cooya Beach",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Mossman Gorge",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Mossman",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Bonnie Doon",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Shannonvale",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Syndicate",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Whyanbeel",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Craiglie",
        "postcode": "4877",
        "state": "Queensland"
    },
    {
        "suburb": "Wangetti",
        "postcode": "4877",
        "state": "Queensland"
    },
    {
        "suburb": "Blackwater",
        "postcode": "4717",
        "state": "Queensland"
    },
    {
        "suburb": "Barnard",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Balcomba",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Mackenzie",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Wallaroo",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Goowarra",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Dingo",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Goomally",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Mimosa",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Wooroona",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Dromedary",
        "postcode": "4718",
        "state": "Queensland"
    },
    {
        "suburb": "Coomoo",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Alsace",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Bingegang",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Jellinbah",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Gainsford",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Mungabunda",
        "postcode": "4718",
        "state": "Queensland"
    },
    {
        "suburb": "Oombabeer",
        "postcode": "4718",
        "state": "Queensland"
    },
    {
        "suburb": "Rhydding",
        "postcode": "4718",
        "state": "Queensland"
    },
    {
        "suburb": "Bluff",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Blackdown",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Yungaburra",
        "postcode": "4884",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Barrine",
        "postcode": "4884",
        "state": "Queensland"
    },
    {
        "suburb": "North Johnstone",
        "postcode": "4885",
        "state": "Queensland"
    },
    {
        "suburb": "Wooroonooran",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Palmerston",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Eacham",
        "postcode": "4884",
        "state": "Queensland"
    },
    {
        "suburb": "Topaz",
        "postcode": "4885",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Allyn",
        "postcode": "4885",
        "state": "Queensland"
    },
    {
        "suburb": "Peeramon",
        "postcode": "4885",
        "state": "Queensland"
    },
    {
        "suburb": "Malanda",
        "postcode": "4885",
        "state": "Queensland"
    },
    {
        "suburb": "Kureen",
        "postcode": "4885",
        "state": "Queensland"
    },
    {
        "suburb": "Jaggan",
        "postcode": "4885",
        "state": "Queensland"
    },
    {
        "suburb": "Tarzali",
        "postcode": "4885",
        "state": "Queensland"
    },
    {
        "suburb": "Moregatta",
        "postcode": "4886",
        "state": "Queensland"
    },
    {
        "suburb": "Minbun",
        "postcode": "4886",
        "state": "Queensland"
    },
    {
        "suburb": "Mungalli",
        "postcode": "4886",
        "state": "Queensland"
    },
    {
        "suburb": "Ellinjaa",
        "postcode": "4886",
        "state": "Queensland"
    },
    {
        "suburb": "Millaa Millaa",
        "postcode": "4886",
        "state": "Queensland"
    },
    {
        "suburb": "Middlebrook",
        "postcode": "4886",
        "state": "Queensland"
    },
    {
        "suburb": "Maalan",
        "postcode": "4886",
        "state": "Queensland"
    },
    {
        "suburb": "Beatrice",
        "postcode": "4886",
        "state": "Queensland"
    },
    {
        "suburb": "Wuruma Dam",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Abercorn",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Eidsvold",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Cynthia",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Eidsvold West",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Cheltenham",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Eidsvold East",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Grosvenor",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Malmoe",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Emerald",
        "postcode": "4720",
        "state": "Queensland"
    },
    {
        "suburb": "The Gemfields",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Gindie",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Lochington",
        "postcode": "4722",
        "state": "Queensland"
    },
    {
        "suburb": "Comet",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Willows",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Erub Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Toogoolawah",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Esk",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Lowood",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Buaraba",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Crossdale",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Fernvale",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Moore",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Scrub Creek",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Harlin",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Coominya",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Linville",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Gregors Creek",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Rifle Range",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Brightview",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Lockrose",
        "postcode": "4342",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Wivenhoe",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Dundas",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Biarra",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Stanley",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Fulham",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Bryden",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Wivenhoe Hill",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Esk",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Coal Creek",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Moombra",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Split Yard Creek",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Murrumba",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Caboonbah",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Redbank Creek",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Hallen",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Cressbrook",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Ivory Creek",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Yimbun",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Somerset Dam",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Coolana",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Cooeeimbardi",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Ottaba",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Avoca Vale",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Borallon",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Lower Cressbrook",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Patrick Estate",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Minden",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Tarampa",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Braemore",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Beppo",
        "postcode": "4313",
        "state": "Queensland"
    },
    {
        "suburb": "Fairney View",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Wanora",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Haigslea",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Marburg",
        "postcode": "4346",
        "state": "Queensland"
    },
    {
        "suburb": "Lark Hill",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Glamorgan Vale",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Vernor",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Wivenhoe Pocket",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Prenzlau",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Tarampa",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Colinton",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Byron",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Atkinsons Dam",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Eskdale",
        "postcode": "4312",
        "state": "Queensland"
    },
    {
        "suburb": "Strathmore",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Gilbert River",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Abingdon Downs",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Talaroo",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Einasleigh",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Lyndhurst",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Conjuboy",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Northhead",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Gilberton",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Surprise",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Georgetown",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Forsayth",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Gracemere",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Stanwell",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Kabra",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Pink Lily",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Thompson Point",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Ridgelands",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Bajool",
        "postcode": "4699",
        "state": "Queensland"
    },
    {
        "suburb": "Gogango",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Bouldercombe",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Glenroy",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Morinish South",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Wycarbah",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Bushley",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Garnant",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "South Yaamba",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Alton Downs",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Kalapa",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Morinish",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Port Curtis",
        "postcode": "4700",
        "state": "Queensland"
    },
    {
        "suburb": "Marmor",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Dalma",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Port Alma",
        "postcode": "4699",
        "state": "Queensland"
    },
    {
        "suburb": "Prairie",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Porcupine",
        "postcode": "4821",
        "state": "Queensland"
    },
    {
        "suburb": "Dutton River",
        "postcode": "4821",
        "state": "Queensland"
    },
    {
        "suburb": "Stamford",
        "postcode": "4821",
        "state": "Queensland"
    },
    {
        "suburb": "Torrens Creek",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Hughenden",
        "postcode": "4821",
        "state": "Queensland"
    },
    {
        "suburb": "Gatton",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Helidon",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Ma Ma Creek",
        "postcode": "4347",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Sylvia",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Egypt",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Withcott",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Murphys Creek",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Grantham",
        "postcode": "4347",
        "state": "Queensland"
    },
    {
        "suburb": "Woodbine",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Caffey",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Stockyard",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Whitestone",
        "postcode": "4347",
        "state": "Queensland"
    },
    {
        "suburb": "Derrymore",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Iredale",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Flagstone Creek",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Lilydale",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Ringwood",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Winwill",
        "postcode": "4347",
        "state": "Queensland"
    },
    {
        "suburb": "Carpendale",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Fordsdale",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Rockmount",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Silver Ridge",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Fifteen Mile",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "White Mountain",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Seventeen Mile",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Buaraba South",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Vinegar Hill",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Ropeley",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Rockside",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Ingoldsby",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Black Duck Creek",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "East Haldon",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Lefthand Branch",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Tenthill",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Woodlands",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Placid Hills",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Clarendon",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Adare",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Lawes",
        "postcode": "4345",
        "state": "Queensland"
    },
    {
        "suburb": "Lower Tenthill",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Postmans Ridge",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Helidon Spa",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Lockyer",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Lockyer",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Spring Creek",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Blanchview",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Ballard",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Wilson Valley",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Dirnbir",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Bon Accord",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Campbell Creek",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Branch Creek",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Mingo",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Steadman",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Wetheron",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Ginoondan",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Dundarrah",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Ban Ban",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Ban Ban Springs",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Aranbanga",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Gayndah",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Woodmillar",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Debateable",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Deep Creek",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Humphery",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Pile Gully",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Harriet",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "The Limits",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Ideraway",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Lawless",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Binjour",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Reids Creek",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Barlyne",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Penwhaupell",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Clinton",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Callemondah",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Gladstone Harbour",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "New Auckland",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Gladstone Central",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "West Gladstone",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Kin Kora",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Telina",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Sun Valley",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "South Gladstone",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Toolooa",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Eden",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Barney Point",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "South Trees",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "O'Connell",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Beenleigh",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Bethania",
        "postcode": "4205",
        "state": "Queensland"
    },
    {
        "suburb": "Waterford",
        "postcode": "4133",
        "state": "Queensland"
    },
    {
        "suburb": "Eagleby",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Edens Landing",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Holmview",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Woongoolba",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Steiglitz",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Jacobs Well",
        "postcode": "4208",
        "state": "Queensland"
    },
    {
        "suburb": "Southern Moreton Bay Islands",
        "postcode": "4209",
        "state": "Queensland"
    },
    {
        "suburb": "Pimpama",
        "postcode": "4209",
        "state": "Queensland"
    },
    {
        "suburb": "Gilberton",
        "postcode": "4208",
        "state": "Queensland"
    },
    {
        "suburb": "Norwell",
        "postcode": "4208",
        "state": "Queensland"
    },
    {
        "suburb": "Alberton",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Warren Park",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Ormeau",
        "postcode": "4208",
        "state": "Queensland"
    },
    {
        "suburb": "Yatala",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Luscombe",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Ormeau Hills",
        "postcode": "4208",
        "state": "Queensland"
    },
    {
        "suburb": "Stapylton",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Bannockburn",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Wolffdene",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Belivah",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Windaroo",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Bahrs Scrub",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Ashmore",
        "postcode": "4214",
        "state": "Queensland"
    },
    {
        "suburb": "Benowa",
        "postcode": "4217",
        "state": "Queensland"
    },
    {
        "suburb": "Labrador",
        "postcode": "4215",
        "state": "Queensland"
    },
    {
        "suburb": "Biggera Waters",
        "postcode": "4216",
        "state": "Queensland"
    },
    {
        "suburb": "Tugun",
        "postcode": "4224",
        "state": "Queensland"
    },
    {
        "suburb": "Currumbin",
        "postcode": "4223",
        "state": "Queensland"
    },
    {
        "suburb": "Bilinga",
        "postcode": "4225",
        "state": "Queensland"
    },
    {
        "suburb": "Mermaid Beach",
        "postcode": "4218",
        "state": "Queensland"
    },
    {
        "suburb": "Broadbeach",
        "postcode": "4218",
        "state": "Queensland"
    },
    {
        "suburb": "Broadbeach Waters",
        "postcode": "4218",
        "state": "Queensland"
    },
    {
        "suburb": "Bundall",
        "postcode": "4217",
        "state": "Queensland"
    },
    {
        "suburb": "Surfers Paradise",
        "postcode": "4217",
        "state": "Queensland"
    },
    {
        "suburb": "Burleigh Heads",
        "postcode": "4220",
        "state": "Queensland"
    },
    {
        "suburb": "Miami",
        "postcode": "4220",
        "state": "Queensland"
    },
    {
        "suburb": "Burleigh Waters",
        "postcode": "4220",
        "state": "Queensland"
    },
    {
        "suburb": "Merrimac",
        "postcode": "4226",
        "state": "Queensland"
    },
    {
        "suburb": "Carrara",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Coolangatta",
        "postcode": "4225",
        "state": "Queensland"
    },
    {
        "suburb": "Coombabah",
        "postcode": "4216",
        "state": "Queensland"
    },
    {
        "suburb": "Currumbin Waters",
        "postcode": "4223",
        "state": "Queensland"
    },
    {
        "suburb": "Tallebudgera",
        "postcode": "4228",
        "state": "Queensland"
    },
    {
        "suburb": "Tallebudgera Valley",
        "postcode": "4228",
        "state": "Queensland"
    },
    {
        "suburb": "Currumbin Valley",
        "postcode": "4223",
        "state": "Queensland"
    },
    {
        "suburb": "Elanora",
        "postcode": "4221",
        "state": "Queensland"
    },
    {
        "suburb": "Springbrook",
        "postcode": "4213",
        "state": "Queensland"
    },
    {
        "suburb": "Austinville",
        "postcode": "4213",
        "state": "Queensland"
    },
    {
        "suburb": "Clagiraba",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Lower Beechmont",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Guanaba",
        "postcode": "4210",
        "state": "Queensland"
    },
    {
        "suburb": "Advancetown",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Wongawallan",
        "postcode": "4210",
        "state": "Queensland"
    },
    {
        "suburb": "Helensvale",
        "postcode": "4212",
        "state": "Queensland"
    },
    {
        "suburb": "Hope Island",
        "postcode": "4212",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Coomera",
        "postcode": "4209",
        "state": "Queensland"
    },
    {
        "suburb": "Kingsholme",
        "postcode": "4208",
        "state": "Queensland"
    },
    {
        "suburb": "Willow Vale",
        "postcode": "4209",
        "state": "Queensland"
    },
    {
        "suburb": "Maudsland",
        "postcode": "4210",
        "state": "Queensland"
    },
    {
        "suburb": "Runaway Bay",
        "postcode": "4216",
        "state": "Queensland"
    },
    {
        "suburb": "Main Beach",
        "postcode": "4217",
        "state": "Queensland"
    },
    {
        "suburb": "South Stradbroke",
        "postcode": "4216",
        "state": "Queensland"
    },
    {
        "suburb": "Mermaid Waters",
        "postcode": "4218",
        "state": "Queensland"
    },
    {
        "suburb": "Clear Island Waters",
        "postcode": "4226",
        "state": "Queensland"
    },
    {
        "suburb": "Molendinar",
        "postcode": "4214",
        "state": "Queensland"
    },
    {
        "suburb": "Mudgeeraba",
        "postcode": "4213",
        "state": "Queensland"
    },
    {
        "suburb": "Bonogin",
        "postcode": "4213",
        "state": "Queensland"
    },
    {
        "suburb": "Reedy Creek",
        "postcode": "4227",
        "state": "Queensland"
    },
    {
        "suburb": "Robina",
        "postcode": "4226",
        "state": "Queensland"
    },
    {
        "suburb": "Neranwood",
        "postcode": "4213",
        "state": "Queensland"
    },
    {
        "suburb": "Nerang",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Highland Park",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Nathan",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Gilston",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Oxenford",
        "postcode": "4210",
        "state": "Queensland"
    },
    {
        "suburb": "Pacific Pines",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Gaven",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Palm Beach",
        "postcode": "4221",
        "state": "Queensland"
    },
    {
        "suburb": "Paradise Point",
        "postcode": "4216",
        "state": "Queensland"
    },
    {
        "suburb": "Hollywell",
        "postcode": "4216",
        "state": "Queensland"
    },
    {
        "suburb": "Parkwood",
        "postcode": "4214",
        "state": "Queensland"
    },
    {
        "suburb": "Arundel",
        "postcode": "4214",
        "state": "Queensland"
    },
    {
        "suburb": "Coomera",
        "postcode": "4209",
        "state": "Queensland"
    },
    {
        "suburb": "Southport",
        "postcode": "4215",
        "state": "Queensland"
    },
    {
        "suburb": "Varsity Lakes",
        "postcode": "4227",
        "state": "Queensland"
    },
    {
        "suburb": "Worongary",
        "postcode": "4213",
        "state": "Queensland"
    },
    {
        "suburb": "Tallai",
        "postcode": "4213",
        "state": "Queensland"
    },
    {
        "suburb": "Goondiwindi",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "Keriri Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Garnet",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Herberton",
        "postcode": "4887",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Ruth",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Kirrama",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Koombooloomba",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Innot Hot Springs",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Silver Valley",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Millstream",
        "postcode": "4888",
        "state": "Queensland"
    },
    {
        "suburb": "Ravenshoe",
        "postcode": "4888",
        "state": "Queensland"
    },
    {
        "suburb": "Tumoulin",
        "postcode": "4888",
        "state": "Queensland"
    },
    {
        "suburb": "Kaban",
        "postcode": "4888",
        "state": "Queensland"
    },
    {
        "suburb": "Evelyn",
        "postcode": "4888",
        "state": "Queensland"
    },
    {
        "suburb": "Kalunga",
        "postcode": "4887",
        "state": "Queensland"
    },
    {
        "suburb": "Wondecla",
        "postcode": "4887",
        "state": "Queensland"
    },
    {
        "suburb": "Moomin",
        "postcode": "4887",
        "state": "Queensland"
    },
    {
        "suburb": "Booral",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Burrum Heads",
        "postcode": "4659",
        "state": "Queensland"
    },
    {
        "suburb": "Toogoom",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Dundowran Beach",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Sunshine Acres",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Point Vernon",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Eli Waters",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Urraween",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Pialba",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Kawungan",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Scarness",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Torquay",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Wondunna",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Urangan",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "River Heads",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Burrum River",
        "postcode": "4659",
        "state": "Queensland"
    },
    {
        "suburb": "Takura",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Craignish",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Dundowran",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Walligan",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Bunya Creek",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Nikenbah",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Pacific Haven",
        "postcode": "4659",
        "state": "Queensland"
    },
    {
        "suburb": "Howard",
        "postcode": "4659",
        "state": "Queensland"
    },
    {
        "suburb": "Torbanlea",
        "postcode": "4662",
        "state": "Queensland"
    },
    {
        "suburb": "Tandora",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Great Sandy Strait",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Fraser Island",
        "postcode": "4581",
        "state": "Queensland"
    },
    {
        "suburb": "Susan River",
        "postcode": "4655",
        "state": "Queensland"
    },
    {
        "suburb": "Dundathu",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Aldershot",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Duckinwilla",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Burrum Town",
        "postcode": "4659",
        "state": "Queensland"
    },
    {
        "suburb": "Cherwell",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Burgowan",
        "postcode": "4659",
        "state": "Queensland"
    },
    {
        "suburb": "Beelbi Creek",
        "postcode": "4659",
        "state": "Queensland"
    },
    {
        "suburb": "Prawle",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Helens Hill",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Ingham",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Taylors Beach",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Halifax",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Forrest Beach",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Palm Island",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Abergowrie",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Garrawalt",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Fox",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Long Pocket",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Dalrymple Creek",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Lannercost",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Trebonne",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Hawkins Creek",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Gairloch",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Foresthome",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Bemerside",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Macknade",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Lucinda",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Yuruga",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Coolbie",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Stone",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Wharps",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Orient",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Braemeadows",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Blackrock",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Victoria Plantation",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Peacock Siding",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Iama Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Ilfracombe",
        "postcode": "4727",
        "state": "Queensland"
    },
    {
        "suburb": "Inglewood",
        "postcode": "4387",
        "state": "Queensland"
    },
    {
        "suburb": "Texas",
        "postcode": "4385",
        "state": "Queensland"
    },
    {
        "suburb": "Oman Ama",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Coolmunda",
        "postcode": "4387",
        "state": "Queensland"
    },
    {
        "suburb": "Bybera",
        "postcode": "4387",
        "state": "Queensland"
    },
    {
        "suburb": "Beebo",
        "postcode": "4385",
        "state": "Queensland"
    },
    {
        "suburb": "Terrica",
        "postcode": "4387",
        "state": "Queensland"
    },
    {
        "suburb": "Warroo",
        "postcode": "4387",
        "state": "Queensland"
    },
    {
        "suburb": "Smithlea",
        "postcode": "4385",
        "state": "Queensland"
    },
    {
        "suburb": "Canning Creek",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Bonshaw",
        "postcode": "4385",
        "state": "Queensland"
    },
    {
        "suburb": "Maidenhead",
        "postcode": "4385",
        "state": "Queensland"
    },
    {
        "suburb": "Riverton",
        "postcode": "4385",
        "state": "Queensland"
    },
    {
        "suburb": "Watsons Crossing",
        "postcode": "4385",
        "state": "Queensland"
    },
    {
        "suburb": "Glenarbon",
        "postcode": "4385",
        "state": "Queensland"
    },
    {
        "suburb": "Greenup",
        "postcode": "4387",
        "state": "Queensland"
    },
    {
        "suburb": "Limevale",
        "postcode": "4384",
        "state": "Queensland"
    },
    {
        "suburb": "Injinoo",
        "postcode": "4876",
        "state": "Queensland"
    },
    {
        "suburb": "Somerset",
        "postcode": "4876",
        "state": "Queensland"
    },
    {
        "suburb": "Leichhardt",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Brassall",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "One Mile",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Coalfalls",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Sadliers Crossing",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Churchill",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Yamanto",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Woodend",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Ipswich",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "North Ipswich",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Raceview",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Flinders View",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Eastern Heights",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Silkstone",
        "postcode": "4304",
        "state": "Queensland"
    },
    {
        "suburb": "Booval",
        "postcode": "4304",
        "state": "Queensland"
    },
    {
        "suburb": "Ripley",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Bundamba",
        "postcode": "4304",
        "state": "Queensland"
    },
    {
        "suburb": "Wulkuraka",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "West Ipswich",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Tivoli",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "North Tivoli",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Moores Pocket",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "East Ipswich",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Basin Pocket",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "North Booval",
        "postcode": "4304",
        "state": "Queensland"
    },
    {
        "suburb": "Dinmore",
        "postcode": "4303",
        "state": "Queensland"
    },
    {
        "suburb": "Riverview",
        "postcode": "4303",
        "state": "Queensland"
    },
    {
        "suburb": "Ebbw Vale",
        "postcode": "4304",
        "state": "Queensland"
    },
    {
        "suburb": "Blackstone",
        "postcode": "4304",
        "state": "Queensland"
    },
    {
        "suburb": "Deebing Heights",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Swanbank",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Amberley",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Newtown",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Karrabin",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Blacksoil",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Purga",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Camira",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "Gailes",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "Springfield",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "Bellbird Park",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "Goodna",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "Redbank Plains",
        "postcode": "4301",
        "state": "Queensland"
    },
    {
        "suburb": "Collingwood Park",
        "postcode": "4301",
        "state": "Queensland"
    },
    {
        "suburb": "Springfield Lakes",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "Redbank",
        "postcode": "4301",
        "state": "Queensland"
    },
    {
        "suburb": "Carole Park",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "New Chum",
        "postcode": "4303",
        "state": "Queensland"
    },
    {
        "suburb": "Augustine Heights",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "Brookwater",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "Spring Mountain",
        "postcode": "4124",
        "state": "Queensland"
    },
    {
        "suburb": "Karalee",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Barellan Point",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Pine Mountain",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Harrisville",
        "postcode": "4307",
        "state": "Queensland"
    },
    {
        "suburb": "Peak Crossing",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Ebenezer",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Mort",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Grandchester",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Goolman",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Willowbank",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Limestone Ridges",
        "postcode": "4305",
        "state": "Queensland"
    },
    {
        "suburb": "Calvert",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Woolshed",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "The Bluff",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Tallegalla",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Rosewood",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Ironbark",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Thagoona",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Walloon",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Woodgate",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Booyal",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Promisedland",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Buxton",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Redridge",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Cordalba",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "North Isis",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Doolbi",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Horton",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Childers",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Apple Tree Creek",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "South Isis",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Kullogum",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Isis River",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Eureka",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Isis Central",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Gregory River",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Farnsfield",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Abington",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Goodwood",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Duingal",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Isisford",
        "postcode": "4731",
        "state": "Queensland"
    },
    {
        "suburb": "Hobartville",
        "postcode": "4724",
        "state": "Queensland"
    },
    {
        "suburb": "Jericho",
        "postcode": "4728",
        "state": "Queensland"
    },
    {
        "suburb": "Alpha",
        "postcode": "4724",
        "state": "Queensland"
    },
    {
        "suburb": "Surbiton",
        "postcode": "4724",
        "state": "Queensland"
    },
    {
        "suburb": "Garfield",
        "postcode": "4728",
        "state": "Queensland"
    },
    {
        "suburb": "Dunrobin",
        "postcode": "4728",
        "state": "Queensland"
    },
    {
        "suburb": "Beaufort",
        "postcode": "4724",
        "state": "Queensland"
    },
    {
        "suburb": "Pine Hill",
        "postcode": "4724",
        "state": "Queensland"
    },
    {
        "suburb": "Port Wine",
        "postcode": "4724",
        "state": "Queensland"
    },
    {
        "suburb": "Sedgeford",
        "postcode": "4724",
        "state": "Queensland"
    },
    {
        "suburb": "Mexico",
        "postcode": "4728",
        "state": "Queensland"
    },
    {
        "suburb": "East Innisfail",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Innisfail",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Mourilyan Harbour",
        "postcode": "4858",
        "state": "Queensland"
    },
    {
        "suburb": "Gulngai",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Mourilyan",
        "postcode": "4858",
        "state": "Queensland"
    },
    {
        "suburb": "Japoonvale",
        "postcode": "4856",
        "state": "Queensland"
    },
    {
        "suburb": "Daradgee",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "East Palmerston",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Bingil Bay",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "El Arish",
        "postcode": "4855",
        "state": "Queensland"
    },
    {
        "suburb": "Silkwood",
        "postcode": "4856",
        "state": "Queensland"
    },
    {
        "suburb": "Mission Beach",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "Innisfail Estate",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Maria Creeks",
        "postcode": "4855",
        "state": "Queensland"
    },
    {
        "suburb": "Moresby",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Germantown",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "South Johnstone",
        "postcode": "4859",
        "state": "Queensland"
    },
    {
        "suburb": "Utchee Creek",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "No 6 Branch",
        "postcode": "4859",
        "state": "Queensland"
    },
    {
        "suburb": "Cowley Creek",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Goolboo",
        "postcode": "4856",
        "state": "Queensland"
    },
    {
        "suburb": "No 4 Branch",
        "postcode": "4856",
        "state": "Queensland"
    },
    {
        "suburb": "No 5 Branch",
        "postcode": "4856",
        "state": "Queensland"
    },
    {
        "suburb": "Maadi",
        "postcode": "4855",
        "state": "Queensland"
    },
    {
        "suburb": "Friday Pocket",
        "postcode": "4855",
        "state": "Queensland"
    },
    {
        "suburb": "Daveson",
        "postcode": "4855",
        "state": "Queensland"
    },
    {
        "suburb": "Jaffa",
        "postcode": "4855",
        "state": "Queensland"
    },
    {
        "suburb": "Lower Cowley",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "McCutcheon",
        "postcode": "4856",
        "state": "Queensland"
    },
    {
        "suburb": "Midgeree Bar",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "Basilisk",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Warrubullen",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Sandy Pocket",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Etty Bay",
        "postcode": "4858",
        "state": "Queensland"
    },
    {
        "suburb": "Martyville",
        "postcode": "4858",
        "state": "Queensland"
    },
    {
        "suburb": "Comoon Loop",
        "postcode": "4858",
        "state": "Queensland"
    },
    {
        "suburb": "Webb",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "South Innisfail",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Coquette Point",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Stockton",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Mighell",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Currajah",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Eaton",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Goondi Hill",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Wanjuru",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Coconuts",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Vasa Views",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Jubilee Heights",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Flying Fish Point",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Cullinane",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Goondi",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Garradunga",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Goondi Bend",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Sundown",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Bamboo Creek",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Belvedere",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Hudson",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "O'Briens Hill",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Djiru",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "Granadilla",
        "postcode": "4855",
        "state": "Queensland"
    },
    {
        "suburb": "Nerada",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Fitzgerald Creek",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Coorumba",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Wangan",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Mena Creek",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Kurrimine Beach",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Pin Gin Hill",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Cowley Beach",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Mundoo",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Stoters Hill",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Walter Lever Estate",
        "postcode": "4856",
        "state": "Queensland"
    },
    {
        "suburb": "Garners Beach",
        "postcode": "4852",
        "state": "Queensland"
    },
    {
        "suburb": "New Harbourline",
        "postcode": "4858",
        "state": "Queensland"
    },
    {
        "suburb": "Shell Pocket",
        "postcode": "4855",
        "state": "Queensland"
    },
    {
        "suburb": "Cooroo Lands",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Camp Creek",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Daradgee",
        "postcode": "4860",
        "state": "Queensland"
    },
    {
        "suburb": "Bombeeta",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Glenvale",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Cotswold Hills",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Wellcamp",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Gowrie Junction",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Torrington",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Gowrie Mountain",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Charlton",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Oakey",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "West Prairie",
        "postcode": "4403",
        "state": "Queensland"
    },
    {
        "suburb": "Formartin",
        "postcode": "4404",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Moriah",
        "postcode": "4403",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Irving",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Yargullen",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Purrawunda",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Athol",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Jondaryan",
        "postcode": "4403",
        "state": "Queensland"
    },
    {
        "suburb": "Norwin",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Bongeen",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Nangwee",
        "postcode": "4407",
        "state": "Queensland"
    },
    {
        "suburb": "Evanslea",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Wainui",
        "postcode": "4404",
        "state": "Queensland"
    },
    {
        "suburb": "Aubigny",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Umbiram",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Biddeston",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Southbrook",
        "postcode": "4363",
        "state": "Queensland"
    },
    {
        "suburb": "Bowenville",
        "postcode": "4404",
        "state": "Queensland"
    },
    {
        "suburb": "St Ruth",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Cecil Plains",
        "postcode": "4407",
        "state": "Queensland"
    },
    {
        "suburb": "Linthorpe",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Kilcoy",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Royston",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Archer",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Villeneuve",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Winya",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Kilcoy",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Sheep Station Creek",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Hazeldean",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Kingaham",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Monsildale",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Kenilworth",
        "postcode": "4574",
        "state": "Queensland"
    },
    {
        "suburb": "Jimna",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Glenfern",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Westvale",
        "postcode": "4514",
        "state": "Queensland"
    },
    {
        "suburb": "Sandy Creek",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Goomeri",
        "postcode": "4601",
        "state": "Queensland"
    },
    {
        "suburb": "Booubyjan",
        "postcode": "4601",
        "state": "Queensland"
    },
    {
        "suburb": "Windera",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Tansey",
        "postcode": "4601",
        "state": "Queensland"
    },
    {
        "suburb": "Mudlo",
        "postcode": "4600",
        "state": "Queensland"
    },
    {
        "suburb": "Woolooga",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Sexton",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Lower Wonga",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Widgee",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Oakview",
        "postcode": "4600",
        "state": "Queensland"
    },
    {
        "suburb": "Kilkivan",
        "postcode": "4600",
        "state": "Queensland"
    },
    {
        "suburb": "Manumbar",
        "postcode": "4601",
        "state": "Queensland"
    },
    {
        "suburb": "Wrattens Forest",
        "postcode": "4601",
        "state": "Queensland"
    },
    {
        "suburb": "Elgin Vale",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Johnstown",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Manyung",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Goomeribong",
        "postcode": "4601",
        "state": "Queensland"
    },
    {
        "suburb": "Boonara",
        "postcode": "4601",
        "state": "Queensland"
    },
    {
        "suburb": "Crownthorpe",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Cinnabar",
        "postcode": "4600",
        "state": "Queensland"
    },
    {
        "suburb": "Black Snake",
        "postcode": "4600",
        "state": "Queensland"
    },
    {
        "suburb": "Wattle Grove",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Benair",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Kingaroy",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Booie",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Haly Creek",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Corndale",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Crawford",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Memerambi",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Wooroolin",
        "postcode": "4608",
        "state": "Queensland"
    },
    {
        "suburb": "Taabinga",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Goodger",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Dangore",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Durong",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Gordonbrook",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Inverlaw",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Kumbia",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Boyneside",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Coolabunia",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Mannuem",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Dalysford",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "St Kilda",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Tirroan",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Gin Gin",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Drinan",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Dalga",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Kalpowar",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Wonbah Forest",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Bullyard",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Gaeta",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Wonbah",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Boolboonda",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Moolboolaman",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Nearum",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Doughboy",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "St Agnes",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Wallaville",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Redhill Farms",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Skyring Reserve",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Maroondan",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Horse Camp",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Bungadoo",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Good Night",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Morganville",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Kowanyama",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Moa Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Blenheim",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Mulgowie",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Townson",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Thornton",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Morton Vale",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Forest Hill",
        "postcode": "4342",
        "state": "Queensland"
    },
    {
        "suburb": "Laidley",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Regency Downs",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Glenore Grove",
        "postcode": "4342",
        "state": "Queensland"
    },
    {
        "suburb": "Summerholm",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Laidley South",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Kensington Grove",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Laidley Heights",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Plainland",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Hatton Vale",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Laidley North",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Lockyer Waters",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "College View",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Churchable",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Crowley Vale",
        "postcode": "4342",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Cairn",
        "postcode": "4342",
        "state": "Queensland"
    },
    {
        "suburb": "Laidley Creek West",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Berryman",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "The Caves",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Glenlee",
        "postcode": "4711",
        "state": "Queensland"
    },
    {
        "suburb": "Etna Creek",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Rockyview",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Glendale",
        "postcode": "4711",
        "state": "Queensland"
    },
    {
        "suburb": "Yeppoon",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Emu Park",
        "postcode": "4710",
        "state": "Queensland"
    },
    {
        "suburb": "Weerriba",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Rosslyn",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Causeway Lake",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Ogmore",
        "postcode": "4706",
        "state": "Queensland"
    },
    {
        "suburb": "Kunwarara",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Woodbury",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Farnborough",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Adelaide Park",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Coorooman",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Rossmoya",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Cawarral",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Tanby",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Tungamull",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Mulara",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Milman",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Yaamba",
        "postcode": "4704",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Chalmers",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Bungundarra",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Barmoya",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Wattlebank",
        "postcode": "4704",
        "state": "Queensland"
    },
    {
        "suburb": "Byfield",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Barmaryee",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Canoona",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Bondoola",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Ironpot",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Maryvale",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Zilzie",
        "postcode": "4710",
        "state": "Queensland"
    },
    {
        "suburb": "Pacific Heights",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Taranganba",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Kinka Beach",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Barlows Hill",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Inverness",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Lammermoor",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Meikleville Hill",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Nerimbera",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Nankin",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Greenlake",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Marlborough",
        "postcode": "4705",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Gardiner",
        "postcode": "4705",
        "state": "Queensland"
    },
    {
        "suburb": "Stockyard",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Taroomball",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Keppel Sands",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Coowonga",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Shoalwater",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Sandringham",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Canal Creek",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Joskeleigh",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Stanage",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Cooee Bay",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Mulambin",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Cobraball",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Hidden Valley",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Bangalee",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "The Keppels",
        "postcode": "4700",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Mary",
        "postcode": "4703",
        "state": "Queensland"
    },
    {
        "suburb": "Jardine",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Hillcrest",
        "postcode": "4118",
        "state": "Queensland"
    },
    {
        "suburb": "Regents Park",
        "postcode": "4118",
        "state": "Queensland"
    },
    {
        "suburb": "Browns Plains",
        "postcode": "4118",
        "state": "Queensland"
    },
    {
        "suburb": "Forestdale",
        "postcode": "4118",
        "state": "Queensland"
    },
    {
        "suburb": "Boronia Heights",
        "postcode": "4124",
        "state": "Queensland"
    },
    {
        "suburb": "Heritage Park",
        "postcode": "4118",
        "state": "Queensland"
    },
    {
        "suburb": "Cornubia",
        "postcode": "4130",
        "state": "Queensland"
    },
    {
        "suburb": "Carbrook",
        "postcode": "4130",
        "state": "Queensland"
    },
    {
        "suburb": "Daisy Hill",
        "postcode": "4127",
        "state": "Queensland"
    },
    {
        "suburb": "Shailer Park",
        "postcode": "4128",
        "state": "Queensland"
    },
    {
        "suburb": "Priestdale",
        "postcode": "4127",
        "state": "Queensland"
    },
    {
        "suburb": "Rochedale South",
        "postcode": "4123",
        "state": "Queensland"
    },
    {
        "suburb": "Park Ridge",
        "postcode": "4125",
        "state": "Queensland"
    },
    {
        "suburb": "Kingston",
        "postcode": "4114",
        "state": "Queensland"
    },
    {
        "suburb": "Slacks Creek",
        "postcode": "4127",
        "state": "Queensland"
    },
    {
        "suburb": "Loganlea",
        "postcode": "4131",
        "state": "Queensland"
    },
    {
        "suburb": "Logan Central",
        "postcode": "4114",
        "state": "Queensland"
    },
    {
        "suburb": "Loganholme",
        "postcode": "4129",
        "state": "Queensland"
    },
    {
        "suburb": "Tanah Merah",
        "postcode": "4128",
        "state": "Queensland"
    },
    {
        "suburb": "Meadowbrook",
        "postcode": "4131",
        "state": "Queensland"
    },
    {
        "suburb": "Crestmead",
        "postcode": "4132",
        "state": "Queensland"
    },
    {
        "suburb": "Marsden",
        "postcode": "4132",
        "state": "Queensland"
    },
    {
        "suburb": "Berrinba",
        "postcode": "4117",
        "state": "Queensland"
    },
    {
        "suburb": "Springwood",
        "postcode": "4127",
        "state": "Queensland"
    },
    {
        "suburb": "Underwood",
        "postcode": "4119",
        "state": "Queensland"
    },
    {
        "suburb": "Waterford West",
        "postcode": "4133",
        "state": "Queensland"
    },
    {
        "suburb": "Woodridge",
        "postcode": "4114",
        "state": "Queensland"
    },
    {
        "suburb": "Longreach",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Chorregon",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Maneroo",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Tocal",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Mabuiag Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Farleigh",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Ooralea",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Pleasant",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Rural View",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "West Mackay",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Bucasia",
        "postcode": "4750",
        "state": "Queensland"
    },
    {
        "suburb": "Beaconsfield",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "North Mackay",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "South Mackay",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "East Mackay",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Eimeo",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Andergrove",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Mackay",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Blacks Beach",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Slade Point",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Paget",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Racecourse",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Glenella",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Mackay Harbour",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Shoal Point",
        "postcode": "4750",
        "state": "Queensland"
    },
    {
        "suburb": "Dolphin Heads",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Nindaroo",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Richmond",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Pleystowe",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Walkerston",
        "postcode": "4751",
        "state": "Queensland"
    },
    {
        "suburb": "Te Kowai",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Bakers Creek",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Alexandra",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Palmyra",
        "postcode": "4751",
        "state": "Queensland"
    },
    {
        "suburb": "Midge Point",
        "postcode": "4799",
        "state": "Queensland"
    },
    {
        "suburb": "Laguna Quays",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Mentmore",
        "postcode": "4798",
        "state": "Queensland"
    },
    {
        "suburb": "Marian",
        "postcode": "4753",
        "state": "Queensland"
    },
    {
        "suburb": "Munbura",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Rosella",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Balnagowan",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Yalboroo",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "St Helens Beach",
        "postcode": "4798",
        "state": "Queensland"
    },
    {
        "suburb": "Calen",
        "postcode": "4798",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Ossa",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Kuttabul",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Seaforth",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Haliday Bay",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Ball Bay",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "The Leap",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Hampden",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Habana",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Sunnyside",
        "postcode": "4737",
        "state": "Queensland"
    },
    {
        "suburb": "Oakenden",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Balberra",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Eton",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Chelona",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Dunnrock",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Victoria Plains",
        "postcode": "4751",
        "state": "Queensland"
    },
    {
        "suburb": "Dumbleton",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Bloomsbury",
        "postcode": "4799",
        "state": "Queensland"
    },
    {
        "suburb": "Cape Hillsborough",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Eungella Hinterland",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Charlton",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Julia Creek",
        "postcode": "4823",
        "state": "Queensland"
    },
    {
        "suburb": "Taldora",
        "postcode": "4823",
        "state": "Queensland"
    },
    {
        "suburb": "Malpas-Trenton",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Bolwarra",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Koah",
        "postcode": "4881",
        "state": "Queensland"
    },
    {
        "suburb": "Springfield",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Watsonville",
        "postcode": "4887",
        "state": "Queensland"
    },
    {
        "suburb": "Kuranda",
        "postcode": "4881",
        "state": "Queensland"
    },
    {
        "suburb": "Chewko",
        "postcode": "4880",
        "state": "Queensland"
    },
    {
        "suburb": "Red River",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Staaten",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Biboohra",
        "postcode": "4880",
        "state": "Queensland"
    },
    {
        "suburb": "Gamboola",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Lyndside",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Speewah",
        "postcode": "4881",
        "state": "Queensland"
    },
    {
        "suburb": "Desailly",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Mareeba",
        "postcode": "4880",
        "state": "Queensland"
    },
    {
        "suburb": "Southedge",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Crystalbrook",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Dimbulah",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Chillagoe",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Julatten",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Barwidgi",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Amber",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Russell",
        "postcode": "4880",
        "state": "Queensland"
    },
    {
        "suburb": "Mutchilba",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Molloy",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Paddys Green",
        "postcode": "4880",
        "state": "Queensland"
    },
    {
        "suburb": "Wrotham",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Arriga",
        "postcode": "4880",
        "state": "Queensland"
    },
    {
        "suburb": "Rookwood",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Fossilbrook",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Almaden",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Thornborough",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Bulleringa",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Petford",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Carbine",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Arbouin",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Nychum",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Mona Mona",
        "postcode": "4881",
        "state": "Queensland"
    },
    {
        "suburb": "Forty Mile",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Groganville",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Hurricane",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Irvinebank",
        "postcode": "4887",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Mulgrave",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Bellevue",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Ravensworth",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Buderim",
        "postcode": "4556",
        "state": "Queensland"
    },
    {
        "suburb": "Mountain Creek",
        "postcode": "4557",
        "state": "Queensland"
    },
    {
        "suburb": "Mons",
        "postcode": "4556",
        "state": "Queensland"
    },
    {
        "suburb": "Sippy Downs",
        "postcode": "4556",
        "state": "Queensland"
    },
    {
        "suburb": "Kunda Park",
        "postcode": "4556",
        "state": "Queensland"
    },
    {
        "suburb": "Tanawha",
        "postcode": "4556",
        "state": "Queensland"
    },
    {
        "suburb": "Pacific Paradise",
        "postcode": "4564",
        "state": "Queensland"
    },
    {
        "suburb": "Coolum Beach",
        "postcode": "4573",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Coolum",
        "postcode": "4573",
        "state": "Queensland"
    },
    {
        "suburb": "Marcoola",
        "postcode": "4564",
        "state": "Queensland"
    },
    {
        "suburb": "Peregian Beach",
        "postcode": "4573",
        "state": "Queensland"
    },
    {
        "suburb": "Mudjimba",
        "postcode": "4564",
        "state": "Queensland"
    },
    {
        "suburb": "Peregian Springs",
        "postcode": "4573",
        "state": "Queensland"
    },
    {
        "suburb": "Point Arkwright",
        "postcode": "4573",
        "state": "Queensland"
    },
    {
        "suburb": "Yaroomba",
        "postcode": "4573",
        "state": "Queensland"
    },
    {
        "suburb": "Twin Waters",
        "postcode": "4564",
        "state": "Queensland"
    },
    {
        "suburb": "Maroochydore",
        "postcode": "4558",
        "state": "Queensland"
    },
    {
        "suburb": "Kuluin",
        "postcode": "4558",
        "state": "Queensland"
    },
    {
        "suburb": "Alexandra Headland",
        "postcode": "4572",
        "state": "Queensland"
    },
    {
        "suburb": "Mooloolaba",
        "postcode": "4557",
        "state": "Queensland"
    },
    {
        "suburb": "Parklands",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Nambour",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Coes Creek",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Burnside",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Highworth",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Towen Mountain",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Woombye",
        "postcode": "4559",
        "state": "Queensland"
    },
    {
        "suburb": "Perwillowen",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Image Flat",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Kulangoor",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Diddillibah",
        "postcode": "4559",
        "state": "Queensland"
    },
    {
        "suburb": "Bli Bli",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Rosemount",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Forest Glen",
        "postcode": "4556",
        "state": "Queensland"
    },
    {
        "suburb": "Kiels Mountain",
        "postcode": "4559",
        "state": "Queensland"
    },
    {
        "suburb": "Palmwoods",
        "postcode": "4555",
        "state": "Queensland"
    },
    {
        "suburb": "Chevallum",
        "postcode": "4555",
        "state": "Queensland"
    },
    {
        "suburb": "West Woombye",
        "postcode": "4559",
        "state": "Queensland"
    },
    {
        "suburb": "Kiamba",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Obi Obi",
        "postcode": "4574",
        "state": "Queensland"
    },
    {
        "suburb": "Belli Park",
        "postcode": "4562",
        "state": "Queensland"
    },
    {
        "suburb": "Cooloolabin",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Yandina",
        "postcode": "4561",
        "state": "Queensland"
    },
    {
        "suburb": "Eumundi",
        "postcode": "4562",
        "state": "Queensland"
    },
    {
        "suburb": "North Arm",
        "postcode": "4561",
        "state": "Queensland"
    },
    {
        "suburb": "Doonan",
        "postcode": "4562",
        "state": "Queensland"
    },
    {
        "suburb": "Verrierdale",
        "postcode": "4562",
        "state": "Queensland"
    },
    {
        "suburb": "Yandina Creek",
        "postcode": "4561",
        "state": "Queensland"
    },
    {
        "suburb": "Eerwah Vale",
        "postcode": "4562",
        "state": "Queensland"
    },
    {
        "suburb": "Ninderry",
        "postcode": "4561",
        "state": "Queensland"
    },
    {
        "suburb": "Kureelpa",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Maroochy River",
        "postcode": "4561",
        "state": "Queensland"
    },
    {
        "suburb": "Mapleton",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Flaxton",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Dulong",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Montville",
        "postcode": "4560",
        "state": "Queensland"
    },
    {
        "suburb": "Hunchy",
        "postcode": "4555",
        "state": "Queensland"
    },
    {
        "suburb": "Landers Shoot",
        "postcode": "4555",
        "state": "Queensland"
    },
    {
        "suburb": "Gheerulla",
        "postcode": "4574",
        "state": "Queensland"
    },
    {
        "suburb": "Valdora",
        "postcode": "4561",
        "state": "Queensland"
    },
    {
        "suburb": "Eudlo",
        "postcode": "4554",
        "state": "Queensland"
    },
    {
        "suburb": "Ilkley",
        "postcode": "4554",
        "state": "Queensland"
    },
    {
        "suburb": "Coolabine",
        "postcode": "4574",
        "state": "Queensland"
    },
    {
        "suburb": "Bridges",
        "postcode": "4561",
        "state": "Queensland"
    },
    {
        "suburb": "Weyba Downs",
        "postcode": "4562",
        "state": "Queensland"
    },
    {
        "suburb": "Maryborough",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Tinana",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Granville",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Beaver Rock",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Eurong",
        "postcode": "4581",
        "state": "Queensland"
    },
    {
        "suburb": "Maaroom",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Boonooroo",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Tuan",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Poona",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "The Dimonds",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Maryborough West",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Oakhurst",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Island Plantation",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "St Helens",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Walkers Point",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Boonooroo Plains",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Dowar Islet",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Mer Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Stonehenge",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Wattle Ridge",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Bulli Creek",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Condamine Plains",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Tummaville",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Cattle Creek",
        "postcode": "4407",
        "state": "Queensland"
    },
    {
        "suburb": "The Pines",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Kooroongarra",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Millmerran Woods",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Millmerran",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Clontarf",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Kurrowah",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Rocky Creek",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Forest Ridge",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Dunmore",
        "postcode": "4407",
        "state": "Queensland"
    },
    {
        "suburb": "Bringalily",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Pampas",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Cypress Gardens",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Grays Gate",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Yandilla",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Captains Mountain",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Emlyn",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Condamine Farms",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Millmerran Downs",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Lemontree",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Punchs Creek",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Millwood",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Domville",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Lavelle",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Western Creek",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Pinevale",
        "postcode": "4754",
        "state": "Queensland"
    },
    {
        "suburb": "Brightly",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Kinchant Dam",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Martin",
        "postcode": "4754",
        "state": "Queensland"
    },
    {
        "suburb": "Dalrymple Heights",
        "postcode": "4757",
        "state": "Queensland"
    },
    {
        "suburb": "Netherdale",
        "postcode": "4756",
        "state": "Queensland"
    },
    {
        "suburb": "Crediton",
        "postcode": "4757",
        "state": "Queensland"
    },
    {
        "suburb": "Pinnacle",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Septimus",
        "postcode": "4754",
        "state": "Queensland"
    },
    {
        "suburb": "Devereux Creek",
        "postcode": "4753",
        "state": "Queensland"
    },
    {
        "suburb": "Finch Hatton",
        "postcode": "4756",
        "state": "Queensland"
    },
    {
        "suburb": "Broken River",
        "postcode": "4757",
        "state": "Queensland"
    },
    {
        "suburb": "Mirani",
        "postcode": "4754",
        "state": "Queensland"
    },
    {
        "suburb": "Benholme",
        "postcode": "4754",
        "state": "Queensland"
    },
    {
        "suburb": "Eungella Dam",
        "postcode": "4757",
        "state": "Queensland"
    },
    {
        "suburb": "Hazledean",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Foreshores",
        "postcode": "4678",
        "state": "Queensland"
    },
    {
        "suburb": "Eurimbula",
        "postcode": "4677",
        "state": "Queensland"
    },
    {
        "suburb": "Seventeen Seventy",
        "postcode": "4677",
        "state": "Queensland"
    },
    {
        "suburb": "Rules Beach",
        "postcode": "4674",
        "state": "Queensland"
    },
    {
        "suburb": "Turkey Beach",
        "postcode": "4678",
        "state": "Queensland"
    },
    {
        "suburb": "Agnes Water",
        "postcode": "4677",
        "state": "Queensland"
    },
    {
        "suburb": "Deepwater",
        "postcode": "4674",
        "state": "Queensland"
    },
    {
        "suburb": "Round Hill",
        "postcode": "4677",
        "state": "Queensland"
    },
    {
        "suburb": "Captain Creek",
        "postcode": "4677",
        "state": "Queensland"
    },
    {
        "suburb": "Bororen",
        "postcode": "4678",
        "state": "Queensland"
    },
    {
        "suburb": "Colosseum",
        "postcode": "4677",
        "state": "Queensland"
    },
    {
        "suburb": "Gindoran",
        "postcode": "4676",
        "state": "Queensland"
    },
    {
        "suburb": "Rosedale",
        "postcode": "4674",
        "state": "Queensland"
    },
    {
        "suburb": "Miriam Vale",
        "postcode": "4677",
        "state": "Queensland"
    },
    {
        "suburb": "Rodds Bay",
        "postcode": "4678",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Tom",
        "postcode": "4677",
        "state": "Queensland"
    },
    {
        "suburb": "Lowmead",
        "postcode": "4676",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Maria",
        "postcode": "4674",
        "state": "Queensland"
    },
    {
        "suburb": "Euleilah",
        "postcode": "4674",
        "state": "Queensland"
    },
    {
        "suburb": "Mungungo",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Monto",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Coominglah Forest",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Cania",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Bancroft",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Monal",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Moonford",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Bukali",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Cannindah",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Mulgildie",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Tellebang",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Glenleigh",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Selene",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Rawbelle",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Harrami",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Coominglah",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Ventnor",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Splinter Creek",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Kapaldo",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Three Moon",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "West Wellesley Islands",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Gulf Of Carpentaria",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Wellesley Islands",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "South Wellesley Islands",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Gunpowder",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Isa",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Soldiers Hill",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Winston",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "The Gap",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Ryan",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Pioneer",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Sunset",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Townview",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Parkside",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Healy",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Happy Valley",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Mornington",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Menzies",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Isa City",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Miles End",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Mica Creek",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Spreadborough",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Kalkadoon",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Camooweal",
        "postcode": "4828",
        "state": "Queensland"
    },
    {
        "suburb": "Lawn Hill",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Barkly",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Fisher",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Walterhall",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Morgan",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "The Mine",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Limestone",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Johnsons Hill",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Struck Oil",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Leydens Hill",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Wura",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Oakey Creek",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Trotter Creek",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Walmul",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Mundubbera",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "O'Bil Bil",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Cattle Creek",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Hawkwood",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Beeron",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Coonambula",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Brovinia",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Dykehead",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Gurgeena",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Philpott",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Mundowran",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Boynewood",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Riverleigh",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Glenrae",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Toondahra",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Old Cooranga",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Derri Derra",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Monogorilby",
        "postcode": "4626",
        "state": "Queensland"
    },
    {
        "suburb": "Redgate",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Moffatdale",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Glenrock",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Kitoba",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Cobbs Hill",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Sunny Nook",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Murgon",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Wooroonden",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Cloyna",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Moondooner",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Silverleaf",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Barlil",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Merlwood",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Oakdale",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Warnung",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Byee",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Miles",
        "postcode": "4415",
        "state": "Queensland"
    },
    {
        "suburb": "Drillham",
        "postcode": "4424",
        "state": "Queensland"
    },
    {
        "suburb": "Hookswood",
        "postcode": "4415",
        "state": "Queensland"
    },
    {
        "suburb": "Condamine",
        "postcode": "4416",
        "state": "Queensland"
    },
    {
        "suburb": "Moraby",
        "postcode": "4416",
        "state": "Queensland"
    },
    {
        "suburb": "Yulabilla",
        "postcode": "4416",
        "state": "Queensland"
    },
    {
        "suburb": "Barramornie",
        "postcode": "4416",
        "state": "Queensland"
    },
    {
        "suburb": "Pine Hills",
        "postcode": "4416",
        "state": "Queensland"
    },
    {
        "suburb": "Dulacca",
        "postcode": "4425",
        "state": "Queensland"
    },
    {
        "suburb": "Drillham South",
        "postcode": "4424",
        "state": "Queensland"
    },
    {
        "suburb": "Columboola",
        "postcode": "4415",
        "state": "Queensland"
    },
    {
        "suburb": "Nangram",
        "postcode": "4416",
        "state": "Queensland"
    },
    {
        "suburb": "Dalwogon",
        "postcode": "4415",
        "state": "Queensland"
    },
    {
        "suburb": "Glenaubyn",
        "postcode": "4424",
        "state": "Queensland"
    },
    {
        "suburb": "Bogandilla",
        "postcode": "4425",
        "state": "Queensland"
    },
    {
        "suburb": "Gurulmundi",
        "postcode": "4415",
        "state": "Queensland"
    },
    {
        "suburb": "Myall Park",
        "postcode": "4415",
        "state": "Queensland"
    },
    {
        "suburb": "Pelham",
        "postcode": "4415",
        "state": "Queensland"
    },
    {
        "suburb": "Kowguran",
        "postcode": "4415",
        "state": "Queensland"
    },
    {
        "suburb": "Charleville",
        "postcode": "4470",
        "state": "Queensland"
    },
    {
        "suburb": "Augathella",
        "postcode": "4477",
        "state": "Queensland"
    },
    {
        "suburb": "Morven",
        "postcode": "4468",
        "state": "Queensland"
    },
    {
        "suburb": "Gowrie Station",
        "postcode": "4470",
        "state": "Queensland"
    },
    {
        "suburb": "Nive",
        "postcode": "4470",
        "state": "Queensland"
    },
    {
        "suburb": "Ward",
        "postcode": "4470",
        "state": "Queensland"
    },
    {
        "suburb": "Caroline Crossing",
        "postcode": "4477",
        "state": "Queensland"
    },
    {
        "suburb": "Clara Creek",
        "postcode": "4468",
        "state": "Queensland"
    },
    {
        "suburb": "Sommariva",
        "postcode": "4470",
        "state": "Queensland"
    },
    {
        "suburb": "Riversleigh",
        "postcode": "4470",
        "state": "Queensland"
    },
    {
        "suburb": "Murweh",
        "postcode": "4470",
        "state": "Queensland"
    },
    {
        "suburb": "Bakers Bend",
        "postcode": "4470",
        "state": "Queensland"
    },
    {
        "suburb": "Cooladdi",
        "postcode": "4479",
        "state": "Queensland"
    },
    {
        "suburb": "Langlo",
        "postcode": "4470",
        "state": "Queensland"
    },
    {
        "suburb": "Boatman",
        "postcode": "4468",
        "state": "Queensland"
    },
    {
        "suburb": "Tarong",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Nanango",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Wattle Camp",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Runnymede",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Glan Devon",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Kunioon",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Barker Creek Flat",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Brooklands",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Ellesmere",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Wengenville",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Bunya Mountains",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Maidenwell",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "South Nanango",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Teelah",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Blackbutt South",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Blackbutt",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Blackbutt North",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Benarkin North",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Taromeo",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Nukku",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "East Nanango",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Sandy Ridges",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Wyalla",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Bullcamp",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "South East Nanango",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Pimpimbudgee",
        "postcode": "4615",
        "state": "Queensland"
    },
    {
        "suburb": "Elphinstone",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Kemmis",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Eaglefield",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Turrawulla",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Blue Mountain",
        "postcode": "4737",
        "state": "Queensland"
    },
    {
        "suburb": "Epsom",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Nebo",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Oxford",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Glenden",
        "postcode": "4743",
        "state": "Queensland"
    },
    {
        "suburb": "Hail Creek",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Noosaville",
        "postcode": "4566",
        "state": "Queensland"
    },
    {
        "suburb": "Noosa Heads",
        "postcode": "4567",
        "state": "Queensland"
    },
    {
        "suburb": "Sunrise Beach",
        "postcode": "4567",
        "state": "Queensland"
    },
    {
        "suburb": "Sunshine Beach",
        "postcode": "4567",
        "state": "Queensland"
    },
    {
        "suburb": "Castaways Beach",
        "postcode": "4567",
        "state": "Queensland"
    },
    {
        "suburb": "Marcus Beach",
        "postcode": "4573",
        "state": "Queensland"
    },
    {
        "suburb": "Tewantin",
        "postcode": "4565",
        "state": "Queensland"
    },
    {
        "suburb": "Cooroy",
        "postcode": "4563",
        "state": "Queensland"
    },
    {
        "suburb": "Cooroibah",
        "postcode": "4565",
        "state": "Queensland"
    },
    {
        "suburb": "Pomona",
        "postcode": "4568",
        "state": "Queensland"
    },
    {
        "suburb": "Boreen Point",
        "postcode": "4565",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Macdonald",
        "postcode": "4563",
        "state": "Queensland"
    },
    {
        "suburb": "Cooran",
        "postcode": "4569",
        "state": "Queensland"
    },
    {
        "suburb": "Pinbarren",
        "postcode": "4568",
        "state": "Queensland"
    },
    {
        "suburb": "Black Mountain",
        "postcode": "4563",
        "state": "Queensland"
    },
    {
        "suburb": "Tinbeerwah",
        "postcode": "4563",
        "state": "Queensland"
    },
    {
        "suburb": "Noosa North Shore",
        "postcode": "4565",
        "state": "Queensland"
    },
    {
        "suburb": "Federal",
        "postcode": "4568",
        "state": "Queensland"
    },
    {
        "suburb": "Ringtail Creek",
        "postcode": "4565",
        "state": "Queensland"
    },
    {
        "suburb": "Cooroy Mountain",
        "postcode": "4563",
        "state": "Queensland"
    },
    {
        "suburb": "Kin Kin",
        "postcode": "4571",
        "state": "Queensland"
    },
    {
        "suburb": "Como",
        "postcode": "4571",
        "state": "Queensland"
    },
    {
        "suburb": "Cootharaba",
        "postcode": "4565",
        "state": "Queensland"
    },
    {
        "suburb": "Ridgewood",
        "postcode": "4563",
        "state": "Queensland"
    },
    {
        "suburb": "Cunnamulla",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Noorama",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Humeburn",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Jobs Gate",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Wyandra",
        "postcode": "4489",
        "state": "Queensland"
    },
    {
        "suburb": "Nebine",
        "postcode": "4488",
        "state": "Queensland"
    },
    {
        "suburb": "Tuen",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Yowah",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Linden",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Widgeegoara",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Coongoola",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Eulo",
        "postcode": "4491",
        "state": "Queensland"
    },
    {
        "suburb": "Capella",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Tieri",
        "postcode": "4709",
        "state": "Queensland"
    },
    {
        "suburb": "Wyuna",
        "postcode": "4720",
        "state": "Queensland"
    },
    {
        "suburb": "Crinum",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Lilyvale",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Bundoora",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Macarthur",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Cotherstone",
        "postcode": "4745",
        "state": "Queensland"
    },
    {
        "suburb": "Lowestoff",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Retro",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Cheeseborough",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Carbine Creek",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Theresa Creek",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Argyll",
        "postcode": "4721",
        "state": "Queensland"
    },
    {
        "suburb": "Gordonstone",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Chirnside",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Khosh Bulduk",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Belcong",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Hibernia",
        "postcode": "4723",
        "state": "Queensland"
    },
    {
        "suburb": "Yarrol",
        "postcode": "4630",
        "state": "Queensland"
    },
    {
        "suburb": "Mungy",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Perry",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Albany Creek",
        "postcode": "4035",
        "state": "Queensland"
    },
    {
        "suburb": "Bunya",
        "postcode": "4055",
        "state": "Queensland"
    },
    {
        "suburb": "Bray Park",
        "postcode": "4500",
        "state": "Queensland"
    },
    {
        "suburb": "Eatons Hill",
        "postcode": "4037",
        "state": "Queensland"
    },
    {
        "suburb": "Joyner",
        "postcode": "4500",
        "state": "Queensland"
    },
    {
        "suburb": "Warner",
        "postcode": "4500",
        "state": "Queensland"
    },
    {
        "suburb": "Cashmere",
        "postcode": "4500",
        "state": "Queensland"
    },
    {
        "suburb": "Kallangur",
        "postcode": "4503",
        "state": "Queensland"
    },
    {
        "suburb": "Murrumba Downs",
        "postcode": "4503",
        "state": "Queensland"
    },
    {
        "suburb": "Dakabin",
        "postcode": "4503",
        "state": "Queensland"
    },
    {
        "suburb": "North Lakes",
        "postcode": "4509",
        "state": "Queensland"
    },
    {
        "suburb": "Mango Hill",
        "postcode": "4509",
        "state": "Queensland"
    },
    {
        "suburb": "Griffin",
        "postcode": "4503",
        "state": "Queensland"
    },
    {
        "suburb": "Ferny Hills",
        "postcode": "4055",
        "state": "Queensland"
    },
    {
        "suburb": "Arana Hills",
        "postcode": "4054",
        "state": "Queensland"
    },
    {
        "suburb": "Everton Hills",
        "postcode": "4053",
        "state": "Queensland"
    },
    {
        "suburb": "Lawnton",
        "postcode": "4501",
        "state": "Queensland"
    },
    {
        "suburb": "Petrie",
        "postcode": "4502",
        "state": "Queensland"
    },
    {
        "suburb": "Strathpine",
        "postcode": "4500",
        "state": "Queensland"
    },
    {
        "suburb": "Brendale",
        "postcode": "4500",
        "state": "Queensland"
    },
    {
        "suburb": "Ocean View",
        "postcode": "4521",
        "state": "Queensland"
    },
    {
        "suburb": "Laceys Creek",
        "postcode": "4521",
        "state": "Queensland"
    },
    {
        "suburb": "Dayboro",
        "postcode": "4521",
        "state": "Queensland"
    },
    {
        "suburb": "Whiteside",
        "postcode": "4503",
        "state": "Queensland"
    },
    {
        "suburb": "Kurwongbah",
        "postcode": "4503",
        "state": "Queensland"
    },
    {
        "suburb": "Kobble Creek",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Armstrong Creek",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Samsonvale",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Glorious",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Nebo",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Highvale",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Wights Mountain",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Samson",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Closeburn",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Yugar",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Clear Mountain",
        "postcode": "4500",
        "state": "Queensland"
    },
    {
        "suburb": "Draper",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Samford Valley",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Samford Village",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Camp Mountain",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Rush Creek",
        "postcode": "4521",
        "state": "Queensland"
    },
    {
        "suburb": "Cedar Creek",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Jollys Lookout",
        "postcode": "4520",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Pleasant",
        "postcode": "4521",
        "state": "Queensland"
    },
    {
        "suburb": "Pittsworth",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Irongate",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Rossvale",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Scrubby Mountain",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Brookstead",
        "postcode": "4364",
        "state": "Queensland"
    },
    {
        "suburb": "St Helens",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Kincora",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Branchview",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Broxburn",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Yarranlea",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Springside",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Stoneleigh",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Pormpuraaw",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Poruma Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Quilpie",
        "postcode": "4480",
        "state": "Queensland"
    },
    {
        "suburb": "Adavale",
        "postcode": "4474",
        "state": "Queensland"
    },
    {
        "suburb": "Eromanga",
        "postcode": "4480",
        "state": "Queensland"
    },
    {
        "suburb": "Clontarf",
        "postcode": "4019",
        "state": "Queensland"
    },
    {
        "suburb": "Margate",
        "postcode": "4019",
        "state": "Queensland"
    },
    {
        "suburb": "Woody Point",
        "postcode": "4019",
        "state": "Queensland"
    },
    {
        "suburb": "Redcliffe",
        "postcode": "4020",
        "state": "Queensland"
    },
    {
        "suburb": "Newport",
        "postcode": "4020",
        "state": "Queensland"
    },
    {
        "suburb": "Scarborough",
        "postcode": "4020",
        "state": "Queensland"
    },
    {
        "suburb": "Kippa-Ring",
        "postcode": "4021",
        "state": "Queensland"
    },
    {
        "suburb": "Rothwell",
        "postcode": "4022",
        "state": "Queensland"
    },
    {
        "suburb": "Alexandra Hills",
        "postcode": "4161",
        "state": "Queensland"
    },
    {
        "suburb": "Wellington Point",
        "postcode": "4160",
        "state": "Queensland"
    },
    {
        "suburb": "Birkdale",
        "postcode": "4159",
        "state": "Queensland"
    },
    {
        "suburb": "Capalaba",
        "postcode": "4157",
        "state": "Queensland"
    },
    {
        "suburb": "Cleveland",
        "postcode": "4163",
        "state": "Queensland"
    },
    {
        "suburb": "Ormiston",
        "postcode": "4160",
        "state": "Queensland"
    },
    {
        "suburb": "Redland Bay",
        "postcode": "4165",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Cotton",
        "postcode": "4165",
        "state": "Queensland"
    },
    {
        "suburb": "Sheldon",
        "postcode": "4157",
        "state": "Queensland"
    },
    {
        "suburb": "Thorneside",
        "postcode": "4158",
        "state": "Queensland"
    },
    {
        "suburb": "Thornlands",
        "postcode": "4164",
        "state": "Queensland"
    },
    {
        "suburb": "Victoria Point",
        "postcode": "4165",
        "state": "Queensland"
    },
    {
        "suburb": "Coochiemudlo Island",
        "postcode": "4184",
        "state": "Queensland"
    },
    {
        "suburb": "Peel Island",
        "postcode": "4184",
        "state": "Queensland"
    },
    {
        "suburb": "Macleay Island",
        "postcode": "4184",
        "state": "Queensland"
    },
    {
        "suburb": "Russell Island",
        "postcode": "4184",
        "state": "Queensland"
    },
    {
        "suburb": "Lamb Island",
        "postcode": "4184",
        "state": "Queensland"
    },
    {
        "suburb": "Dunwich",
        "postcode": "4183",
        "state": "Queensland"
    },
    {
        "suburb": "North Stradbroke Island",
        "postcode": "4183",
        "state": "Queensland"
    },
    {
        "suburb": "Amity",
        "postcode": "4183",
        "state": "Queensland"
    },
    {
        "suburb": "Point Lookout",
        "postcode": "4183",
        "state": "Queensland"
    },
    {
        "suburb": "Karragarra Island",
        "postcode": "4184",
        "state": "Queensland"
    },
    {
        "suburb": "Richmond",
        "postcode": "4822",
        "state": "Queensland"
    },
    {
        "suburb": "Albion",
        "postcode": "4822",
        "state": "Queensland"
    },
    {
        "suburb": "Cambridge",
        "postcode": "4822",
        "state": "Queensland"
    },
    {
        "suburb": "Burleigh",
        "postcode": "4822",
        "state": "Queensland"
    },
    {
        "suburb": "Saxby",
        "postcode": "4822",
        "state": "Queensland"
    },
    {
        "suburb": "Woolgar",
        "postcode": "4822",
        "state": "Queensland"
    },
    {
        "suburb": "Maxwelton",
        "postcode": "4822",
        "state": "Queensland"
    },
    {
        "suburb": "Wandal",
        "postcode": "4700",
        "state": "Queensland"
    },
    {
        "suburb": "West Rockhampton",
        "postcode": "4700",
        "state": "Queensland"
    },
    {
        "suburb": "The Range",
        "postcode": "4700",
        "state": "Queensland"
    },
    {
        "suburb": "Allenstown",
        "postcode": "4700",
        "state": "Queensland"
    },
    {
        "suburb": "Rockhampton City",
        "postcode": "4700",
        "state": "Queensland"
    },
    {
        "suburb": "Park Avenue",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Kawana",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Frenchville",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Parkhurst",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Berserker",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Depot Hill",
        "postcode": "4700",
        "state": "Queensland"
    },
    {
        "suburb": "Norman Gardens",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Koongal",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Fairy Bower",
        "postcode": "4700",
        "state": "Queensland"
    },
    {
        "suburb": "Lakes Creek",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "The Common",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Limestone Creek",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Archer",
        "postcode": "4701",
        "state": "Queensland"
    },
    {
        "suburb": "Roma",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Meringandan West",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Glencoe",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Muniganeen",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Gowrie Little Plain",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Lilyvale",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Kingsthorpe",
        "postcode": "4400",
        "state": "Queensland"
    },
    {
        "suburb": "Evergreen",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Goombungee",
        "postcode": "4354",
        "state": "Queensland"
    },
    {
        "suburb": "Muldu",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Yarraman",
        "postcode": "4614",
        "state": "Queensland"
    },
    {
        "suburb": "Irvingdale",
        "postcode": "4404",
        "state": "Queensland"
    },
    {
        "suburb": "Maclagan",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Cooyar",
        "postcode": "4402",
        "state": "Queensland"
    },
    {
        "suburb": "Doctor Creek",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Kulpi",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Peranga",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Quinalow",
        "postcode": "4403",
        "state": "Queensland"
    },
    {
        "suburb": "Gilla",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Yalangur",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Boodua",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Cutella",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Greenwood",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Kelvinhaugh",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Silverleigh",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Malu",
        "postcode": "4403",
        "state": "Queensland"
    },
    {
        "suburb": "Acland",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "East Cooyar",
        "postcode": "4353",
        "state": "Queensland"
    },
    {
        "suburb": "Nutgrove",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Wutul",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Kooralgin",
        "postcode": "4402",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Yarraman",
        "postcode": "4614",
        "state": "Queensland"
    },
    {
        "suburb": "Balgowan",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Highland Plains",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Cooyar Creek",
        "postcode": "4402",
        "state": "Queensland"
    },
    {
        "suburb": "Rangemore",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Kilbirnie",
        "postcode": "4354",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Darry",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Malling",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "North Maclagan",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Woodleigh",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Devon Park",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Highgrove",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Narko",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Brymaroo",
        "postcode": "4403",
        "state": "Queensland"
    },
    {
        "suburb": "Saibai Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Sarina Beach",
        "postcode": "4737",
        "state": "Queensland"
    },
    {
        "suburb": "Hay Point",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Sarina",
        "postcode": "4737",
        "state": "Queensland"
    },
    {
        "suburb": "Campwin Beach",
        "postcode": "4737",
        "state": "Queensland"
    },
    {
        "suburb": "Alligator Creek",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Grasstree Beach",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Armstrong Beach",
        "postcode": "4737",
        "state": "Queensland"
    },
    {
        "suburb": "Koumala",
        "postcode": "4738",
        "state": "Queensland"
    },
    {
        "suburb": "Sarina Range",
        "postcode": "4737",
        "state": "Queensland"
    },
    {
        "suburb": "Seisia",
        "postcode": "4876",
        "state": "Queensland"
    },
    {
        "suburb": "Storm King",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Tully",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Lyra",
        "postcode": "4382",
        "state": "Queensland"
    },
    {
        "suburb": "Ballandean",
        "postcode": "4382",
        "state": "Queensland"
    },
    {
        "suburb": "Sundown",
        "postcode": "4382",
        "state": "Queensland"
    },
    {
        "suburb": "Stanthorpe",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Glenlyon",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Pikedale",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Pikes Creek",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Nundubbermere",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Greenlands",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Applethorpe",
        "postcode": "4378",
        "state": "Queensland"
    },
    {
        "suburb": "Thulimbah",
        "postcode": "4376",
        "state": "Queensland"
    },
    {
        "suburb": "Mingoola",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Springdale",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Fletcher",
        "postcode": "4381",
        "state": "Queensland"
    },
    {
        "suburb": "Pozieres",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Diamondvale",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "The Summit",
        "postcode": "4377",
        "state": "Queensland"
    },
    {
        "suburb": "Sugarloaf",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Amiens",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Aplin",
        "postcode": "4381",
        "state": "Queensland"
    },
    {
        "suburb": "Broadwater",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Severnlea",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Cannon Creek",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Thorndale",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Tambo",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Enniskillen",
        "postcode": "4472",
        "state": "Queensland"
    },
    {
        "suburb": "Yandarlo",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Macfarlane",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Minnie Downs",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Scrubby Creek",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Windeyer",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Weir River",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Moonie",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Meandarra",
        "postcode": "4422",
        "state": "Queensland"
    },
    {
        "suburb": "The Gums",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Weranga",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Tara",
        "postcode": "4421",
        "state": "Queensland"
    },
    {
        "suburb": "Hannaford",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Goranba",
        "postcode": "4421",
        "state": "Queensland"
    },
    {
        "suburb": "Glenmorgan",
        "postcode": "4423",
        "state": "Queensland"
    },
    {
        "suburb": "Inglestone",
        "postcode": "4422",
        "state": "Queensland"
    },
    {
        "suburb": "Southwood",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Flinton",
        "postcode": "4422",
        "state": "Queensland"
    },
    {
        "suburb": "Westmar",
        "postcode": "4422",
        "state": "Queensland"
    },
    {
        "suburb": "Marmadua",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Coomrith",
        "postcode": "4422",
        "state": "Queensland"
    },
    {
        "suburb": "Ghinghinda",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Gwambegwine",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Bungaban",
        "postcode": "4419",
        "state": "Queensland"
    },
    {
        "suburb": "Taroom",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Broadmere",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Clifford",
        "postcode": "4427",
        "state": "Queensland"
    },
    {
        "suburb": "Bundi",
        "postcode": "4427",
        "state": "Queensland"
    },
    {
        "suburb": "Woleebee",
        "postcode": "4419",
        "state": "Queensland"
    },
    {
        "suburb": "Wandoan",
        "postcode": "4419",
        "state": "Queensland"
    },
    {
        "suburb": "Roche Creek",
        "postcode": "4419",
        "state": "Queensland"
    },
    {
        "suburb": "Grosmont",
        "postcode": "4419",
        "state": "Queensland"
    },
    {
        "suburb": "Baroondah",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Waikola",
        "postcode": "4428",
        "state": "Queensland"
    },
    {
        "suburb": "Spring Creek",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Glebe",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Cockatoo",
        "postcode": "4419",
        "state": "Queensland"
    },
    {
        "suburb": "Glenhaughton",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Coorada",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Eurombah",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Kinnoul",
        "postcode": "4420",
        "state": "Queensland"
    },
    {
        "suburb": "Kelso",
        "postcode": "4815",
        "state": "Queensland"
    },
    {
        "suburb": "Kirwan",
        "postcode": "4817",
        "state": "Queensland"
    },
    {
        "suburb": "Thuringowa Central",
        "postcode": "4817",
        "state": "Queensland"
    },
    {
        "suburb": "Deeragun",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Condon",
        "postcode": "4815",
        "state": "Queensland"
    },
    {
        "suburb": "Rasmussen",
        "postcode": "4815",
        "state": "Queensland"
    },
    {
        "suburb": "Bushland Beach",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Low",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Burdell",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Jensen",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Shaw",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Rangewood",
        "postcode": "4817",
        "state": "Queensland"
    },
    {
        "suburb": "Bohle Plains",
        "postcode": "4817",
        "state": "Queensland"
    },
    {
        "suburb": "Granite Vale",
        "postcode": "4815",
        "state": "Queensland"
    },
    {
        "suburb": "Alice River",
        "postcode": "4817",
        "state": "Queensland"
    },
    {
        "suburb": "Lynam",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Bluewater",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Black River",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Yabulu",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Saunders Beach",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Toolakea",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Toomulla",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Balgal Beach",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Clemant",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Crystal Creek",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Bluewater Park",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Beach Holm",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Gootchie",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Curra",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Glenwood",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Glenbar",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "Thinoomba",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Tinnanbar",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "St Mary",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Yerra",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Mungar",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Antigua",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Urah",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Bauple",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Talegalla Weir",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Tuan Forest",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Anderleigh",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Scotchy Pocket",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Gunalda",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Kanigan",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Tiaro",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Paterson",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Theebine",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Echo",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Netherby",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Toowoomba City",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "South Toowoomba",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Centenary Heights",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "East Toowoomba",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Lofty",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Prince Henry Heights",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Redwood",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Wilsonton",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Wilsonton Heights",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Rockville",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "North Toowoomba",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Harlaxton",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Newtown",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Cranley",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Kynoch",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Kearneys Spring",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Middle Ridge",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Rangeville",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Darling Heights",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Drayton",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Harristown",
        "postcode": "4350",
        "state": "Queensland"
    },
    {
        "suburb": "Arafura Sea",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Prince Of Wales",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Thursday Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Punsand",
        "postcode": "4876",
        "state": "Queensland"
    },
    {
        "suburb": "New Mapoon",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Horn",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Aitkenvale",
        "postcode": "4814",
        "state": "Queensland"
    },
    {
        "suburb": "Townsville City",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "West End",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Castle Hill",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Cranbrook",
        "postcode": "4814",
        "state": "Queensland"
    },
    {
        "suburb": "Currajong",
        "postcode": "4812",
        "state": "Queensland"
    },
    {
        "suburb": "Douglas",
        "postcode": "4814",
        "state": "Queensland"
    },
    {
        "suburb": "Garbutt",
        "postcode": "4814",
        "state": "Queensland"
    },
    {
        "suburb": "Gulliver",
        "postcode": "4812",
        "state": "Queensland"
    },
    {
        "suburb": "Heatley",
        "postcode": "4814",
        "state": "Queensland"
    },
    {
        "suburb": "Hermit Park",
        "postcode": "4812",
        "state": "Queensland"
    },
    {
        "suburb": "Hyde Park",
        "postcode": "4812",
        "state": "Queensland"
    },
    {
        "suburb": "Mysterton",
        "postcode": "4812",
        "state": "Queensland"
    },
    {
        "suburb": "Arcadia",
        "postcode": "4819",
        "state": "Queensland"
    },
    {
        "suburb": "Florence Bay",
        "postcode": "4819",
        "state": "Queensland"
    },
    {
        "suburb": "Nelly Bay",
        "postcode": "4819",
        "state": "Queensland"
    },
    {
        "suburb": "Horseshoe Bay",
        "postcode": "4819",
        "state": "Queensland"
    },
    {
        "suburb": "Picnic Bay",
        "postcode": "4819",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Louisa",
        "postcode": "4814",
        "state": "Queensland"
    },
    {
        "suburb": "Town Common",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Mount St John",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Bohle",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Mundingburra",
        "postcode": "4812",
        "state": "Queensland"
    },
    {
        "suburb": "Annandale",
        "postcode": "4814",
        "state": "Queensland"
    },
    {
        "suburb": "North Ward",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Belgian Gardens",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Oonoonba",
        "postcode": "4811",
        "state": "Queensland"
    },
    {
        "suburb": "Cluden",
        "postcode": "4811",
        "state": "Queensland"
    },
    {
        "suburb": "Stuart",
        "postcode": "4811",
        "state": "Queensland"
    },
    {
        "suburb": "Idalia",
        "postcode": "4811",
        "state": "Queensland"
    },
    {
        "suburb": "Pallarenda",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Pimlico",
        "postcode": "4812",
        "state": "Queensland"
    },
    {
        "suburb": "Railway Estate",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Rosslea",
        "postcode": "4812",
        "state": "Queensland"
    },
    {
        "suburb": "Rowes Bay",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "South Townsville",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Roseneath",
        "postcode": "4811",
        "state": "Queensland"
    },
    {
        "suburb": "Vincent",
        "postcode": "4814",
        "state": "Queensland"
    },
    {
        "suburb": "Wulguru",
        "postcode": "4811",
        "state": "Queensland"
    },
    {
        "suburb": "Alligator Creek",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Cungulla",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Calcium",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Toonpan",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Julago",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Cape Cleveland",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Oak Valley",
        "postcode": "4811",
        "state": "Queensland"
    },
    {
        "suburb": "Ross River",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Ugar Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Yelarbon",
        "postcode": "4388",
        "state": "Queensland"
    },
    {
        "suburb": "Calingunee",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "Yagaburne",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "Billa Billa",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "Kioma",
        "postcode": "4498",
        "state": "Queensland"
    },
    {
        "suburb": "North Bungunya",
        "postcode": "4494",
        "state": "Queensland"
    },
    {
        "suburb": "Wyaga",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "Weengallon",
        "postcode": "4497",
        "state": "Queensland"
    },
    {
        "suburb": "Callandoon",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "Tarawera",
        "postcode": "4494",
        "state": "Queensland"
    },
    {
        "suburb": "Wondalli",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "Daymar",
        "postcode": "4497",
        "state": "Queensland"
    },
    {
        "suburb": "Boondandilla",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Kindon",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "South Talwood",
        "postcode": "4496",
        "state": "Queensland"
    },
    {
        "suburb": "Toobeah",
        "postcode": "4498",
        "state": "Queensland"
    },
    {
        "suburb": "Nandi",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Jandowae",
        "postcode": "4410",
        "state": "Queensland"
    },
    {
        "suburb": "Mowbullan",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Warra",
        "postcode": "4411",
        "state": "Queensland"
    },
    {
        "suburb": "Bell",
        "postcode": "4408",
        "state": "Queensland"
    },
    {
        "suburb": "Ducklo",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Springvale",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Blaxland",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Jimbour East",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Kumbarilla",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Halliford",
        "postcode": "4407",
        "state": "Queensland"
    },
    {
        "suburb": "Moola",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Tuckerang",
        "postcode": "4411",
        "state": "Queensland"
    },
    {
        "suburb": "Jinghi",
        "postcode": "4410",
        "state": "Queensland"
    },
    {
        "suburb": "Cooranga",
        "postcode": "4408",
        "state": "Queensland"
    },
    {
        "suburb": "Kaimkillenbun",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Pirrinuan",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Macalister",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Jimbour West",
        "postcode": "4406",
        "state": "Queensland"
    },
    {
        "suburb": "Warraber Islet",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Guijar Islet",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Burrar Islet",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Begonia",
        "postcode": "4487",
        "state": "Queensland"
    },
    {
        "suburb": "Ballaroo",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Weribone",
        "postcode": "4417",
        "state": "Queensland"
    },
    {
        "suburb": "Dunkeld",
        "postcode": "4465",
        "state": "Queensland"
    },
    {
        "suburb": "Teelba",
        "postcode": "4423",
        "state": "Queensland"
    },
    {
        "suburb": "Oberina",
        "postcode": "4417",
        "state": "Queensland"
    },
    {
        "suburb": "Parknook",
        "postcode": "4417",
        "state": "Queensland"
    },
    {
        "suburb": "Noorindoo",
        "postcode": "4417",
        "state": "Queensland"
    },
    {
        "suburb": "Wellesley",
        "postcode": "4417",
        "state": "Queensland"
    },
    {
        "suburb": "Wycombe",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Warwick",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Junabee",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Canningvale",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Sladevale",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Maryvale",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Freestone",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Colliery",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "The Falls",
        "postcode": "4373",
        "state": "Queensland"
    },
    {
        "suburb": "Loch Lomond",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Murrays Bridge",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Freestone",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Killarney",
        "postcode": "4373",
        "state": "Queensland"
    },
    {
        "suburb": "Yangan",
        "postcode": "4371",
        "state": "Queensland"
    },
    {
        "suburb": "Swan Creek",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Elbow Valley",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Morgan Park",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Swanfels",
        "postcode": "4371",
        "state": "Queensland"
    },
    {
        "suburb": "Tannymorel",
        "postcode": "4372",
        "state": "Queensland"
    },
    {
        "suburb": "The Hermitage",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Rosenthal Heights",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Willowvale",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Dalveen",
        "postcode": "4374",
        "state": "Queensland"
    },
    {
        "suburb": "Glengallan",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Wiyarra",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Sturt",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Emu Vale",
        "postcode": "4371",
        "state": "Queensland"
    },
    {
        "suburb": "Tregony",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "The Head",
        "postcode": "4373",
        "state": "Queensland"
    },
    {
        "suburb": "Silverwood",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Danderoo",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Womina",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "North Branch",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Allora",
        "postcode": "4362",
        "state": "Queensland"
    },
    {
        "suburb": "Berat",
        "postcode": "4362",
        "state": "Queensland"
    },
    {
        "suburb": "Ellinthorp",
        "postcode": "4362",
        "state": "Queensland"
    },
    {
        "suburb": "Forest Springs",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "Goomburra",
        "postcode": "4362",
        "state": "Queensland"
    },
    {
        "suburb": "Bony Mountain",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Hendon",
        "postcode": "4362",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Marshall",
        "postcode": "4362",
        "state": "Queensland"
    },
    {
        "suburb": "Deuchar",
        "postcode": "4362",
        "state": "Queensland"
    },
    {
        "suburb": "Massie",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Talgai",
        "postcode": "4362",
        "state": "Queensland"
    },
    {
        "suburb": "Rosehill",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Pratten",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Cunningham",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Karara",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Rodgers Creek",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Montrose",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Thane",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Thanes Creek",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Old Talgai",
        "postcode": "4365",
        "state": "Queensland"
    },
    {
        "suburb": "Palgrave",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Toolburra",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Wheatvale",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Wheatvale",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Greymare",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Leslie",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Leslie Dam",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Nanum",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Evans Landing",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Trunding",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Rocky Point",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Weipa Airport",
        "postcode": "4874",
        "state": "Queensland"
    },
    {
        "suburb": "Whitsundays",
        "postcode": "4802",
        "state": "Queensland"
    },
    {
        "suburb": "Proserpine",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Cannonvale",
        "postcode": "4802",
        "state": "Queensland"
    },
    {
        "suburb": "Jubilee Pocket",
        "postcode": "4802",
        "state": "Queensland"
    },
    {
        "suburb": "Dittmer",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Pluto",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Myrtlevale",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Foxdale",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Strathdickie",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Julian",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Sugarloaf",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Cannon Valley",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Gregory River",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Palm Grove",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Rooper",
        "postcode": "4802",
        "state": "Queensland"
    },
    {
        "suburb": "Riordanvale",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Brandy Creek",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Airlie Beach",
        "postcode": "4802",
        "state": "Queensland"
    },
    {
        "suburb": "Cape Conway",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Mandalay",
        "postcode": "4802",
        "state": "Queensland"
    },
    {
        "suburb": "Flametree",
        "postcode": "4802",
        "state": "Queensland"
    },
    {
        "suburb": "Hideaway Bay",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Dingo Beach",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Cape Gloucester",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Woodwark",
        "postcode": "4802",
        "state": "Queensland"
    },
    {
        "suburb": "Hamilton Plains",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Wilson Beach",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Shute Harbour",
        "postcode": "4802",
        "state": "Queensland"
    },
    {
        "suburb": "Breadalbane",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Goorganga Plains",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Silver Creek",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Goorganga Creek",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Gunyarra",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Isla",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Lethebrook",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Kelsey Creek",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Thoopara",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Proserpine",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Numinbah Valley",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Wallu",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Kirkwood",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Stuart",
        "postcode": "4811",
        "state": "Queensland"
    },
    {
        "suburb": "Greenmount",
        "postcode": "4751",
        "state": "Queensland"
    },
    {
        "suburb": "Munna Creek",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Cosgrove",
        "postcode": "4818",
        "state": "Queensland"
    },
    {
        "suburb": "Miva",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Munderra",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Marlow",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Cracow",
        "postcode": "4719",
        "state": "Queensland"
    },
    {
        "suburb": "Kianga",
        "postcode": "4718",
        "state": "Queensland"
    },
    {
        "suburb": "Brush Creek",
        "postcode": "4387",
        "state": "Queensland"
    },
    {
        "suburb": "Natural Bridge",
        "postcode": "4211",
        "state": "Queensland"
    },
    {
        "suburb": "Guluguba",
        "postcode": "4418",
        "state": "Queensland"
    },
    {
        "suburb": "Woodhill",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Warrego",
        "postcode": "4477",
        "state": "Queensland"
    },
    {
        "suburb": "Charlestown",
        "postcode": "4608",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Jukes",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Kynuna",
        "postcode": "4823",
        "state": "Queensland"
    },
    {
        "suburb": "Proston",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "Brooweena",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "Wondai",
        "postcode": "4606",
        "state": "Queensland"
    },
    {
        "suburb": "Surat",
        "postcode": "4417",
        "state": "Queensland"
    },
    {
        "suburb": "Yenda",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Duaringa",
        "postcode": "4712",
        "state": "Queensland"
    },
    {
        "suburb": "Midgee",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Winton",
        "postcode": "4735",
        "state": "Queensland"
    },
    {
        "suburb": "Belyando",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Barringun",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Yarrabilba",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Mungallala",
        "postcode": "4467",
        "state": "Queensland"
    },
    {
        "suburb": "Boogan",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Wallangarra",
        "postcode": "4383",
        "state": "Queensland"
    },
    {
        "suburb": "Gargett",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Coulson",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Gumlu",
        "postcode": "4805",
        "state": "Queensland"
    },
    {
        "suburb": "Diamantina Lakes",
        "postcode": "4735",
        "state": "Queensland"
    },
    {
        "suburb": "Owanyilla",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Middleton",
        "postcode": "4735",
        "state": "Queensland"
    },
    {
        "suburb": "Golden Fleece",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Springfield Central",
        "postcode": "4300",
        "state": "Queensland"
    },
    {
        "suburb": "Opalton",
        "postcode": "4735",
        "state": "Queensland"
    },
    {
        "suburb": "White Rock",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Tipton",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Kuridala",
        "postcode": "4824",
        "state": "Queensland"
    },
    {
        "suburb": "Cordelia",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Pindi Pindi",
        "postcode": "4798",
        "state": "Queensland"
    },
    {
        "suburb": "North Gregory",
        "postcode": "4660",
        "state": "Queensland"
    },
    {
        "suburb": "Birtinya",
        "postcode": "4575",
        "state": "Queensland"
    },
    {
        "suburb": "Grassdale",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Clarendon",
        "postcode": "4311",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Mceuen",
        "postcode": "4606",
        "state": "Queensland"
    },
    {
        "suburb": "Womblebank",
        "postcode": "4465",
        "state": "Queensland"
    },
    {
        "suburb": "Selwyn",
        "postcode": "4823",
        "state": "Queensland"
    },
    {
        "suburb": "Goovigen",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Pilton",
        "postcode": "4361",
        "state": "Queensland"
    },
    {
        "suburb": "East End",
        "postcode": "4695",
        "state": "Queensland"
    },
    {
        "suburb": "Homestead",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Hamilton Creek",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Camoola",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Tingoora",
        "postcode": "4608",
        "state": "Queensland"
    },
    {
        "suburb": "Mutarnee",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Conway Beach",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Bauhinia",
        "postcode": "4718",
        "state": "Queensland"
    },
    {
        "suburb": "Bambaroo",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Dagmar",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Drummondslope",
        "postcode": "4724",
        "state": "Queensland"
    },
    {
        "suburb": "Clintonvale",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Dunmora",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Grahams Creek",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Eungella",
        "postcode": "4757",
        "state": "Queensland"
    },
    {
        "suburb": "Horse Creek",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Boompa",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "Collaroy",
        "postcode": "4707",
        "state": "Queensland"
    },
    {
        "suburb": "McKinlay",
        "postcode": "4823",
        "state": "Queensland"
    },
    {
        "suburb": "Ashfield",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Tinana South",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Baree",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Cromarty",
        "postcode": "4809",
        "state": "Queensland"
    },
    {
        "suburb": "Ironpot",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "North Talwood",
        "postcode": "4496",
        "state": "Queensland"
    },
    {
        "suburb": "Thallon",
        "postcode": "4497",
        "state": "Queensland"
    },
    {
        "suburb": "Corfield",
        "postcode": "4733",
        "state": "Queensland"
    },
    {
        "suburb": "Teddington",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Innisplain",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Stewarton",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Kirknie",
        "postcode": "4806",
        "state": "Queensland"
    },
    {
        "suburb": "Wilkesdale",
        "postcode": "4608",
        "state": "Queensland"
    },
    {
        "suburb": "Noah",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Pelion",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Gundiah",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Doongul",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "Woowoonga",
        "postcode": "4621",
        "state": "Queensland"
    },
    {
        "suburb": "North Aramara",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "McEwens Beach",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Coverty",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "Bungunya",
        "postcode": "4494",
        "state": "Queensland"
    },
    {
        "suburb": "Lansdowne",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Somme",
        "postcode": "4382",
        "state": "Queensland"
    },
    {
        "suburb": "Goodar",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "Hivesville",
        "postcode": "4612",
        "state": "Queensland"
    },
    {
        "suburb": "Coppabella",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Djarawong",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Wigton",
        "postcode": "4612",
        "state": "Queensland"
    },
    {
        "suburb": "Kinleymore",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "Speedwell",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "Boondooma",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "Toobanna",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Vergemont",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Cowley",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Kings Siding",
        "postcode": "4400",
        "state": "Queensland"
    },
    {
        "suburb": "Sardine",
        "postcode": "4726",
        "state": "Queensland"
    },
    {
        "suburb": "Ficks Crossing",
        "postcode": "4606",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Mulligan",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Carneys Creek",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Millaroo",
        "postcode": "4807",
        "state": "Queensland"
    },
    {
        "suburb": "Wheatlands",
        "postcode": "4606",
        "state": "Queensland"
    },
    {
        "suburb": "Llanarth",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Campaspe",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Takilberan",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Benarkin",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Greenview",
        "postcode": "4606",
        "state": "Queensland"
    },
    {
        "suburb": "Diamondy",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Gadgarra",
        "postcode": "4884",
        "state": "Queensland"
    },
    {
        "suburb": "Brigooda",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "O'Reilly",
        "postcode": "4275",
        "state": "Queensland"
    },
    {
        "suburb": "Tanbar",
        "postcode": "4481",
        "state": "Queensland"
    },
    {
        "suburb": "Melrose",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "Dixie",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Moffatt",
        "postcode": "4465",
        "state": "Queensland"
    },
    {
        "suburb": "Larapinta",
        "postcode": "4110",
        "state": "Queensland"
    },
    {
        "suburb": "Ballogie",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Boolburra",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Umagico",
        "postcode": "4876",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Tyson",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Lanefield",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Mowbray",
        "postcode": "4877",
        "state": "Queensland"
    },
    {
        "suburb": "Marshlands",
        "postcode": "4611",
        "state": "Queensland"
    },
    {
        "suburb": "Keysland",
        "postcode": "4612",
        "state": "Queensland"
    },
    {
        "suburb": "Fork Lagoons",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Fairdale",
        "postcode": "4606",
        "state": "Queensland"
    },
    {
        "suburb": "Rollingstone",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Motley",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Leafdale",
        "postcode": "4606",
        "state": "Queensland"
    },
    {
        "suburb": "Berajondo",
        "postcode": "4674",
        "state": "Queensland"
    },
    {
        "suburb": "Stalworth",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Luke",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Kentville",
        "postcode": "4341",
        "state": "Queensland"
    },
    {
        "suburb": "Kawl Kawl",
        "postcode": "4612",
        "state": "Queensland"
    },
    {
        "suburb": "Silver Spur",
        "postcode": "4385",
        "state": "Queensland"
    },
    {
        "suburb": "Mondure",
        "postcode": "4611",
        "state": "Queensland"
    },
    {
        "suburb": "Erakala",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "West Point",
        "postcode": "4819",
        "state": "Queensland"
    },
    {
        "suburb": "Wairuna",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Gore",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Mosquito Creek",
        "postcode": "4387",
        "state": "Queensland"
    },
    {
        "suburb": "Woolmar",
        "postcode": "4515",
        "state": "Queensland"
    },
    {
        "suburb": "Bungeworgorai",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Bayrick",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Stonelands",
        "postcode": "4612",
        "state": "Queensland"
    },
    {
        "suburb": "Preston",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Lake Monduran",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Abbeywood",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "Coochin",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Yarrabah",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Monduran",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Kolonga",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Ernestina",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Brisbane Airport",
        "postcode": "4008",
        "state": "Queensland"
    },
    {
        "suburb": "Jeebropilly",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Daintree",
        "postcode": "4873",
        "state": "Queensland"
    },
    {
        "suburb": "Glen Niven",
        "postcode": "4377",
        "state": "Queensland"
    },
    {
        "suburb": "Kurumbul",
        "postcode": "4388",
        "state": "Queensland"
    },
    {
        "suburb": "Muirlea",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Coot-tha",
        "postcode": "4066",
        "state": "Queensland"
    },
    {
        "suburb": "Minnamoolka",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Boulder Creek",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Okeden",
        "postcode": "4613",
        "state": "Queensland"
    },
    {
        "suburb": "Tangorin",
        "postcode": "4821",
        "state": "Queensland"
    },
    {
        "suburb": "Junction View",
        "postcode": "4343",
        "state": "Queensland"
    },
    {
        "suburb": "Morella",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Bidwill",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Andromache",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Springlands",
        "postcode": "4804",
        "state": "Queensland"
    },
    {
        "suburb": "Upper Flagstone",
        "postcode": "4344",
        "state": "Queensland"
    },
    {
        "suburb": "Hodgson",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Dargal Road",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Pioneers Rest",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Turallin",
        "postcode": "4357",
        "state": "Queensland"
    },
    {
        "suburb": "Valkyrie",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Wallumbilla South",
        "postcode": "4428",
        "state": "Queensland"
    },
    {
        "suburb": "Bauple Forest",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Crystal Brook",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Sandiford",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Breakaway",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "King Scrub",
        "postcode": "4521",
        "state": "Queensland"
    },
    {
        "suburb": "Farrars Creek",
        "postcode": "4481",
        "state": "Queensland"
    },
    {
        "suburb": "Degarra",
        "postcode": "4895",
        "state": "Queensland"
    },
    {
        "suburb": "Cushnie",
        "postcode": "4608",
        "state": "Queensland"
    },
    {
        "suburb": "Columbia",
        "postcode": "4820",
        "state": "Queensland"
    },
    {
        "suburb": "Gladfield",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Newlands",
        "postcode": "4804",
        "state": "Queensland"
    },
    {
        "suburb": "Baffle Creek",
        "postcode": "4674",
        "state": "Queensland"
    },
    {
        "suburb": "Taunton",
        "postcode": "4674",
        "state": "Queensland"
    },
    {
        "suburb": "Damascus",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Molangul",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "McIlwraith",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "New Moonta",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Wahoon",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Blairmore",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Delan",
        "postcode": "4671",
        "state": "Queensland"
    },
    {
        "suburb": "Taragoola",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Gooroolba",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Stonehenge",
        "postcode": "4730",
        "state": "Queensland"
    },
    {
        "suburb": "Mp Creek",
        "postcode": "4606",
        "state": "Queensland"
    },
    {
        "suburb": "Barambah",
        "postcode": "4601",
        "state": "Queensland"
    },
    {
        "suburb": "Edward River",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Milora",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Lumeah",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Suttor",
        "postcode": "4743",
        "state": "Queensland"
    },
    {
        "suburb": "Patrick",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Welsby",
        "postcode": "4507",
        "state": "Queensland"
    },
    {
        "suburb": "Homebush",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Brookhill",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Byellee",
        "postcode": "4680",
        "state": "Queensland"
    },
    {
        "suburb": "Nome",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Woodstock",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Barringha",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Shelly Beach",
        "postcode": "4810",
        "state": "Queensland"
    },
    {
        "suburb": "Gumlow",
        "postcode": "4815",
        "state": "Queensland"
    },
    {
        "suburb": "Cameron Corner",
        "postcode": "4492",
        "state": "Queensland"
    },
    {
        "suburb": "Cuttaburra",
        "postcode": "4490",
        "state": "Queensland"
    },
    {
        "suburb": "Murray",
        "postcode": "4814",
        "state": "Queensland"
    },
    {
        "suburb": "Wallaman",
        "postcode": "4850",
        "state": "Queensland"
    },
    {
        "suburb": "Whetstone",
        "postcode": "4387",
        "state": "Queensland"
    },
    {
        "suburb": "Cement Mills",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Greenswamp",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Freshwater Point",
        "postcode": "4737",
        "state": "Queensland"
    },
    {
        "suburb": "Baking Board",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "Crossroads",
        "postcode": "4413",
        "state": "Queensland"
    },
    {
        "suburb": "North Eton",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Caldervale",
        "postcode": "4478",
        "state": "Queensland"
    },
    {
        "suburb": "Girraween",
        "postcode": "4382",
        "state": "Queensland"
    },
    {
        "suburb": "Fleurbaix",
        "postcode": "4375",
        "state": "Queensland"
    },
    {
        "suburb": "Goldfields",
        "postcode": "4374",
        "state": "Queensland"
    },
    {
        "suburb": "Aldoga",
        "postcode": "4694",
        "state": "Queensland"
    },
    {
        "suburb": "Nine Mile Creek",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Fletcher Creek",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Chahpingah",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Wujal Wujal",
        "postcode": "4895",
        "state": "Queensland"
    },
    {
        "suburb": "Moongan",
        "postcode": "4714",
        "state": "Queensland"
    },
    {
        "suburb": "Chelmsford",
        "postcode": "4606",
        "state": "Queensland"
    },
    {
        "suburb": "Alice Creek",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Piturie",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Electra",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "South Ripley",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Kairabah",
        "postcode": "4207",
        "state": "Queensland"
    },
    {
        "suburb": "Shirbourne",
        "postcode": "4809",
        "state": "Queensland"
    },
    {
        "suburb": "Oyster Creek",
        "postcode": "4674",
        "state": "Queensland"
    },
    {
        "suburb": "Owens Creek",
        "postcode": "4741",
        "state": "Queensland"
    },
    {
        "suburb": "Durham",
        "postcode": "4480",
        "state": "Queensland"
    },
    {
        "suburb": "Dalcouth",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Bapaume",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Narbethong",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Dows Creek",
        "postcode": "4754",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Marrow",
        "postcode": "4306",
        "state": "Queensland"
    },
    {
        "suburb": "Conway",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Spring Bluff",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Givelda",
        "postcode": "4670",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Britton",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Ashwell",
        "postcode": "4340",
        "state": "Queensland"
    },
    {
        "suburb": "Kyoomba",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Strathfield",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Cherry Gully",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Three Rivers",
        "postcode": "4824",
        "state": "Queensland"
    },
    {
        "suburb": "The Glen",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Surround",
        "postcode": "4809",
        "state": "Queensland"
    },
    {
        "suburb": "Gungaloon",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "Gunnawarra",
        "postcode": "4872",
        "state": "Queensland"
    },
    {
        "suburb": "Highbury",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Neumgna",
        "postcode": "4614",
        "state": "Queensland"
    },
    {
        "suburb": "Bullawarra",
        "postcode": "4492",
        "state": "Queensland"
    },
    {
        "suburb": "Byrnestown",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Miara",
        "postcode": "4673",
        "state": "Queensland"
    },
    {
        "suburb": "Tamrookum",
        "postcode": "4285",
        "state": "Queensland"
    },
    {
        "suburb": "Mia Mia",
        "postcode": "4754",
        "state": "Queensland"
    },
    {
        "suburb": "Foulden",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Tabor",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Sabine",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Rosalie Plains",
        "postcode": "4401",
        "state": "Queensland"
    },
    {
        "suburb": "Wallaces Creek",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Ingberry",
        "postcode": "4725",
        "state": "Queensland"
    },
    {
        "suburb": "Roadvale",
        "postcode": "4310",
        "state": "Queensland"
    },
    {
        "suburb": "Veradilla",
        "postcode": "4347",
        "state": "Queensland"
    },
    {
        "suburb": "Lanskey",
        "postcode": "4825",
        "state": "Queensland"
    },
    {
        "suburb": "Dagun",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Cottonvale",
        "postcode": "4375",
        "state": "Queensland"
    },
    {
        "suburb": "Yengarie",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Holroyd River",
        "postcode": "4892",
        "state": "Queensland"
    },
    {
        "suburb": "Ranges Bridge",
        "postcode": "4405",
        "state": "Queensland"
    },
    {
        "suburb": "Wildash",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Birkalla",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Burton",
        "postcode": "4742",
        "state": "Queensland"
    },
    {
        "suburb": "Munbilla",
        "postcode": "4309",
        "state": "Queensland"
    },
    {
        "suburb": "Ceratodus",
        "postcode": "4627",
        "state": "Queensland"
    },
    {
        "suburb": "Pinnacles",
        "postcode": "4815",
        "state": "Queensland"
    },
    {
        "suburb": "Dynevor",
        "postcode": "4492",
        "state": "Queensland"
    },
    {
        "suburb": "Stockhaven",
        "postcode": "4625",
        "state": "Queensland"
    },
    {
        "suburb": "Allan",
        "postcode": "4370",
        "state": "Queensland"
    },
    {
        "suburb": "Tablelands",
        "postcode": "4605",
        "state": "Queensland"
    },
    {
        "suburb": "Eukey",
        "postcode": "4380",
        "state": "Queensland"
    },
    {
        "suburb": "Pilerwa",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Passchendaele",
        "postcode": "4352",
        "state": "Queensland"
    },
    {
        "suburb": "Lundavra",
        "postcode": "4390",
        "state": "Queensland"
    },
    {
        "suburb": "Wyberba",
        "postcode": "4382",
        "state": "Queensland"
    },
    {
        "suburb": "Butchers Creek",
        "postcode": "4885",
        "state": "Queensland"
    },
    {
        "suburb": "Hodgleigh",
        "postcode": "4610",
        "state": "Queensland"
    },
    {
        "suburb": "Marodian",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Calgoa",
        "postcode": "4570",
        "state": "Queensland"
    },
    {
        "suburb": "Gigoomgan",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "Malarga",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "Teebar",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "Aramara",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "Woocoo",
        "postcode": "4620",
        "state": "Queensland"
    },
    {
        "suburb": "Magnolia",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Ferney",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Woorabinda",
        "postcode": "4713",
        "state": "Queensland"
    },
    {
        "suburb": "Masig Island",
        "postcode": "4875",
        "state": "Queensland"
    },
    {
        "suburb": "Bountiful Islands",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "Sweers Island",
        "postcode": "4809",
        "state": "Queensland"
    },
    {
        "suburb": "Belmunda",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Cremorne",
        "postcode": "4740",
        "state": "Queensland"
    },
    {
        "suburb": "Pauls Pocket",
        "postcode": "4800",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Elliot",
        "postcode": "4816",
        "state": "Queensland"
    },
    {
        "suburb": "Mount Mackay",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Kooroomool",
        "postcode": "4854",
        "state": "Queensland"
    },
    {
        "suburb": "Mamu",
        "postcode": "4871",
        "state": "Queensland"
    },
    {
        "suburb": "North Branch",
        "postcode": "4356",
        "state": "Queensland"
    },
    {
        "suburb": "Glenorchy",
        "postcode": "4650",
        "state": "Queensland"
    },
    {
        "suburb": "Kinbombi",
        "postcode": "4601",
        "state": "Queensland"
    },
    {
        "suburb": "Nine Mile",
        "postcode": "4702",
        "state": "Queensland"
    },
    {
        "suburb": "Orallo",
        "postcode": "4455",
        "state": "Queensland"
    },
    {
        "suburb": "Adelaide",
        "postcode": "5000",
        "state": "South Australia"
    },
    {
        "suburb": "North Adelaide",
        "postcode": "5006",
        "state": "South Australia"
    },
    {
        "suburb": "Aldgate",
        "postcode": "5154",
        "state": "South Australia"
    },
    {
        "suburb": "Mylor",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Bridgewater",
        "postcode": "5155",
        "state": "South Australia"
    },
    {
        "suburb": "Crafers",
        "postcode": "5152",
        "state": "South Australia"
    },
    {
        "suburb": "Stirling",
        "postcode": "5152",
        "state": "South Australia"
    },
    {
        "suburb": "Upper Sturt",
        "postcode": "5156",
        "state": "South Australia"
    },
    {
        "suburb": "Crafers West",
        "postcode": "5152",
        "state": "South Australia"
    },
    {
        "suburb": "Heathfield",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Ashton",
        "postcode": "5137",
        "state": "South Australia"
    },
    {
        "suburb": "Summertown",
        "postcode": "5141",
        "state": "South Australia"
    },
    {
        "suburb": "Horsnell Gully",
        "postcode": "5141",
        "state": "South Australia"
    },
    {
        "suburb": "Norton Summit",
        "postcode": "5136",
        "state": "South Australia"
    },
    {
        "suburb": "Piccadilly",
        "postcode": "5151",
        "state": "South Australia"
    },
    {
        "suburb": "Greenhill",
        "postcode": "5140",
        "state": "South Australia"
    },
    {
        "suburb": "Marble Hill",
        "postcode": "5137",
        "state": "South Australia"
    },
    {
        "suburb": "Mount George",
        "postcode": "5155",
        "state": "South Australia"
    },
    {
        "suburb": "Carey Gully",
        "postcode": "5144",
        "state": "South Australia"
    },
    {
        "suburb": "Basket Range",
        "postcode": "5138",
        "state": "South Australia"
    },
    {
        "suburb": "Montacute",
        "postcode": "5134",
        "state": "South Australia"
    },
    {
        "suburb": "Belair",
        "postcode": "5052",
        "state": "South Australia"
    },
    {
        "suburb": "Castambul",
        "postcode": "5076",
        "state": "South Australia"
    },
    {
        "suburb": "Ironbank",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Teringie",
        "postcode": "5072",
        "state": "South Australia"
    },
    {
        "suburb": "Woodforde",
        "postcode": "5072",
        "state": "South Australia"
    },
    {
        "suburb": "Rostrevor",
        "postcode": "5073",
        "state": "South Australia"
    },
    {
        "suburb": "Scott Creek",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Dorset Vale",
        "postcode": "5157",
        "state": "South Australia"
    },
    {
        "suburb": "Cherryville",
        "postcode": "5134",
        "state": "South Australia"
    },
    {
        "suburb": "Cleland",
        "postcode": "5152",
        "state": "South Australia"
    },
    {
        "suburb": "Uraidla",
        "postcode": "5142",
        "state": "South Australia"
    },
    {
        "suburb": "Longwood",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Bradbury",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Lower Hermitage",
        "postcode": "5131",
        "state": "South Australia"
    },
    {
        "suburb": "Cromer",
        "postcode": "5235",
        "state": "South Australia"
    },
    {
        "suburb": "Forreston",
        "postcode": "5233",
        "state": "South Australia"
    },
    {
        "suburb": "Cudlee Creek",
        "postcode": "5232",
        "state": "South Australia"
    },
    {
        "suburb": "Kersbrook",
        "postcode": "5231",
        "state": "South Australia"
    },
    {
        "suburb": "Birdwood",
        "postcode": "5234",
        "state": "South Australia"
    },
    {
        "suburb": "Gumeracha",
        "postcode": "5233",
        "state": "South Australia"
    },
    {
        "suburb": "Inglewood",
        "postcode": "5133",
        "state": "South Australia"
    },
    {
        "suburb": "Humbug Scrub",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Paracombe",
        "postcode": "5132",
        "state": "South Australia"
    },
    {
        "suburb": "Houghton",
        "postcode": "5131",
        "state": "South Australia"
    },
    {
        "suburb": "Chain Of Ponds",
        "postcode": "5231",
        "state": "South Australia"
    },
    {
        "suburb": "Kenton Valley",
        "postcode": "5233",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Torrens",
        "postcode": "5244",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Crawford",
        "postcode": "5351",
        "state": "South Australia"
    },
    {
        "suburb": "Millbrook",
        "postcode": "5231",
        "state": "South Australia"
    },
    {
        "suburb": "Upper Hermitage",
        "postcode": "5131",
        "state": "South Australia"
    },
    {
        "suburb": "Forest Range",
        "postcode": "5139",
        "state": "South Australia"
    },
    {
        "suburb": "Verdun",
        "postcode": "5245",
        "state": "South Australia"
    },
    {
        "suburb": "Balhannah",
        "postcode": "5242",
        "state": "South Australia"
    },
    {
        "suburb": "Oakbank",
        "postcode": "5243",
        "state": "South Australia"
    },
    {
        "suburb": "Woodside",
        "postcode": "5244",
        "state": "South Australia"
    },
    {
        "suburb": "Lenswood",
        "postcode": "5240",
        "state": "South Australia"
    },
    {
        "suburb": "Lobethal",
        "postcode": "5241",
        "state": "South Australia"
    },
    {
        "suburb": "Charleston",
        "postcode": "5244",
        "state": "South Australia"
    },
    {
        "suburb": "Port Elliot",
        "postcode": "5212",
        "state": "South Australia"
    },
    {
        "suburb": "Hindmarsh Island",
        "postcode": "5214",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Compass",
        "postcode": "5210",
        "state": "South Australia"
    },
    {
        "suburb": "Goolwa Beach",
        "postcode": "5214",
        "state": "South Australia"
    },
    {
        "suburb": "Hayborough",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Jagged",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Mosquito Hill",
        "postcode": "5214",
        "state": "South Australia"
    },
    {
        "suburb": "Middleton",
        "postcode": "5213",
        "state": "South Australia"
    },
    {
        "suburb": "Goolwa North",
        "postcode": "5214",
        "state": "South Australia"
    },
    {
        "suburb": "Currency Creek",
        "postcode": "5214",
        "state": "South Australia"
    },
    {
        "suburb": "Goolwa South",
        "postcode": "5214",
        "state": "South Australia"
    },
    {
        "suburb": "Goolwa",
        "postcode": "5214",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Observation",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Tooperang",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Nangkita",
        "postcode": "5210",
        "state": "South Australia"
    },
    {
        "suburb": "Prospect Hill",
        "postcode": "5201",
        "state": "South Australia"
    },
    {
        "suburb": "Hartley",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Strathalbyn",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Highland Valley",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Willunga Hill",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "Langhorne Creek",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Milang",
        "postcode": "5256",
        "state": "South Australia"
    },
    {
        "suburb": "Pages Flat",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "Finniss",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Clayton Bay",
        "postcode": "5256",
        "state": "South Australia"
    },
    {
        "suburb": "Point Sturt",
        "postcode": "5256",
        "state": "South Australia"
    },
    {
        "suburb": "Red Creek",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Kyeema",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "Gemmells",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Macclesfield",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Dingabledinga",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "Ashbourne",
        "postcode": "5157",
        "state": "South Australia"
    },
    {
        "suburb": "Blackfellows Creek",
        "postcode": "5201",
        "state": "South Australia"
    },
    {
        "suburb": "Bull Creek",
        "postcode": "5157",
        "state": "South Australia"
    },
    {
        "suburb": "Yundi",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Alexandrina",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Paris Creek",
        "postcode": "5201",
        "state": "South Australia"
    },
    {
        "suburb": "Kuitpo Colony",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "McHarg Creek",
        "postcode": "5157",
        "state": "South Australia"
    },
    {
        "suburb": "Woodchester",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Sandergrove",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Nurragi",
        "postcode": "5256",
        "state": "South Australia"
    },
    {
        "suburb": "Hope Forest",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Plains",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Tolderol",
        "postcode": "5256",
        "state": "South Australia"
    },
    {
        "suburb": "Angas Plains",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Bletchley",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Belvidere",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Willyaroo",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "The Range",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "Montarra",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Magnificent",
        "postcode": "5210",
        "state": "South Australia"
    },
    {
        "suburb": "Pukatja",
        "postcode": "872",
        "state": "South Australia"
    },
    {
        "suburb": "Mimili",
        "postcode": "872",
        "state": "South Australia"
    },
    {
        "suburb": "Anangu Pitjantjatjara Yankunytjatjara",
        "postcode": "5724",
        "state": "South Australia"
    },
    {
        "suburb": "Iwantja",
        "postcode": "5724",
        "state": "South Australia"
    },
    {
        "suburb": "Watarru",
        "postcode": "872",
        "state": "South Australia"
    },
    {
        "suburb": "Amata",
        "postcode": "872",
        "state": "South Australia"
    },
    {
        "suburb": "Kaltjiti",
        "postcode": "872",
        "state": "South Australia"
    },
    {
        "suburb": "Kalka",
        "postcode": "872",
        "state": "South Australia"
    },
    {
        "suburb": "Pipalyatjara",
        "postcode": "872",
        "state": "South Australia"
    },
    {
        "suburb": "Flaxman Valley",
        "postcode": "5235",
        "state": "South Australia"
    },
    {
        "suburb": "Angaston",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Nuriootpa",
        "postcode": "5355",
        "state": "South Australia"
    },
    {
        "suburb": "Light Pass",
        "postcode": "5355",
        "state": "South Australia"
    },
    {
        "suburb": "Penrice",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Stockwell",
        "postcode": "5355",
        "state": "South Australia"
    },
    {
        "suburb": "Moculta",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Mckenzie",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Williamstown",
        "postcode": "5351",
        "state": "South Australia"
    },
    {
        "suburb": "Cockatoo Valley",
        "postcode": "5351",
        "state": "South Australia"
    },
    {
        "suburb": "Springton",
        "postcode": "5235",
        "state": "South Australia"
    },
    {
        "suburb": "Barossa Goldfields",
        "postcode": "5351",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Pleasant",
        "postcode": "5235",
        "state": "South Australia"
    },
    {
        "suburb": "Pewsey Vale",
        "postcode": "5351",
        "state": "South Australia"
    },
    {
        "suburb": "Rosedale",
        "postcode": "5350",
        "state": "South Australia"
    },
    {
        "suburb": "Altona",
        "postcode": "5351",
        "state": "South Australia"
    },
    {
        "suburb": "Rowland Flat",
        "postcode": "5352",
        "state": "South Australia"
    },
    {
        "suburb": "Lyndoch",
        "postcode": "5351",
        "state": "South Australia"
    },
    {
        "suburb": "Kalbeeba",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Eden Valley",
        "postcode": "5235",
        "state": "South Australia"
    },
    {
        "suburb": "Taunton",
        "postcode": "5235",
        "state": "South Australia"
    },
    {
        "suburb": "Sandy Creek",
        "postcode": "5350",
        "state": "South Australia"
    },
    {
        "suburb": "Tanunda",
        "postcode": "5352",
        "state": "South Australia"
    },
    {
        "suburb": "Bethany",
        "postcode": "5352",
        "state": "South Australia"
    },
    {
        "suburb": "Vine Vale",
        "postcode": "5352",
        "state": "South Australia"
    },
    {
        "suburb": "Krondorf",
        "postcode": "5352",
        "state": "South Australia"
    },
    {
        "suburb": "Ward Hill",
        "postcode": "5522",
        "state": "South Australia"
    },
    {
        "suburb": "Port Broughton",
        "postcode": "5522",
        "state": "South Australia"
    },
    {
        "suburb": "Wokurna",
        "postcode": "5520",
        "state": "South Australia"
    },
    {
        "suburb": "Fisherman Bay",
        "postcode": "5522",
        "state": "South Australia"
    },
    {
        "suburb": "Mundoora",
        "postcode": "5555",
        "state": "South Australia"
    },
    {
        "suburb": "Tickera",
        "postcode": "5555",
        "state": "South Australia"
    },
    {
        "suburb": "Bute",
        "postcode": "5560",
        "state": "South Australia"
    },
    {
        "suburb": "Ninnes",
        "postcode": "5560",
        "state": "South Australia"
    },
    {
        "suburb": "Paskeville",
        "postcode": "5552",
        "state": "South Australia"
    },
    {
        "suburb": "South Hummocks",
        "postcode": "5550",
        "state": "South Australia"
    },
    {
        "suburb": "Alford",
        "postcode": "5555",
        "state": "South Australia"
    },
    {
        "suburb": "Kulpara",
        "postcode": "5552",
        "state": "South Australia"
    },
    {
        "suburb": "Melton",
        "postcode": "5552",
        "state": "South Australia"
    },
    {
        "suburb": "Clements Gap",
        "postcode": "5523",
        "state": "South Australia"
    },
    {
        "suburb": "Thomas Plain",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "Barmera",
        "postcode": "5345",
        "state": "South Australia"
    },
    {
        "suburb": "Overland Corner",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Loveday",
        "postcode": "5345",
        "state": "South Australia"
    },
    {
        "suburb": "Cobdogla",
        "postcode": "5346",
        "state": "South Australia"
    },
    {
        "suburb": "Berri",
        "postcode": "5343",
        "state": "South Australia"
    },
    {
        "suburb": "Glossop",
        "postcode": "5344",
        "state": "South Australia"
    },
    {
        "suburb": "Winkie",
        "postcode": "5343",
        "state": "South Australia"
    },
    {
        "suburb": "Monash",
        "postcode": "5342",
        "state": "South Australia"
    },
    {
        "suburb": "Katarapko",
        "postcode": "5343",
        "state": "South Australia"
    },
    {
        "suburb": "Burnside",
        "postcode": "5066",
        "state": "South Australia"
    },
    {
        "suburb": "Skye",
        "postcode": "5072",
        "state": "South Australia"
    },
    {
        "suburb": "Leabrook",
        "postcode": "5068",
        "state": "South Australia"
    },
    {
        "suburb": "Erindale",
        "postcode": "5066",
        "state": "South Australia"
    },
    {
        "suburb": "Beulah Park",
        "postcode": "5067",
        "state": "South Australia"
    },
    {
        "suburb": "Auldana",
        "postcode": "5072",
        "state": "South Australia"
    },
    {
        "suburb": "Hazelwood Park",
        "postcode": "5066",
        "state": "South Australia"
    },
    {
        "suburb": "Kensington Park",
        "postcode": "5068",
        "state": "South Australia"
    },
    {
        "suburb": "Wattle Park",
        "postcode": "5066",
        "state": "South Australia"
    },
    {
        "suburb": "Stonyfell",
        "postcode": "5066",
        "state": "South Australia"
    },
    {
        "suburb": "Rosslyn Park",
        "postcode": "5072",
        "state": "South Australia"
    },
    {
        "suburb": "Magill",
        "postcode": "5072",
        "state": "South Australia"
    },
    {
        "suburb": "Kensington Gardens",
        "postcode": "5068",
        "state": "South Australia"
    },
    {
        "suburb": "Glenunga",
        "postcode": "5064",
        "state": "South Australia"
    },
    {
        "suburb": "Glenside",
        "postcode": "5065",
        "state": "South Australia"
    },
    {
        "suburb": "Beaumont",
        "postcode": "5066",
        "state": "South Australia"
    },
    {
        "suburb": "Waterfall Gully",
        "postcode": "5066",
        "state": "South Australia"
    },
    {
        "suburb": "Leawood Gardens",
        "postcode": "5150",
        "state": "South Australia"
    },
    {
        "suburb": "Glen Osmond",
        "postcode": "5064",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Osmond",
        "postcode": "5064",
        "state": "South Australia"
    },
    {
        "suburb": "Eastwood",
        "postcode": "5063",
        "state": "South Australia"
    },
    {
        "suburb": "Linden Park",
        "postcode": "5065",
        "state": "South Australia"
    },
    {
        "suburb": "Toorak Gardens",
        "postcode": "5065",
        "state": "South Australia"
    },
    {
        "suburb": "Tusmore",
        "postcode": "5065",
        "state": "South Australia"
    },
    {
        "suburb": "St Georges",
        "postcode": "5064",
        "state": "South Australia"
    },
    {
        "suburb": "Frewville",
        "postcode": "5063",
        "state": "South Australia"
    },
    {
        "suburb": "Rose Park",
        "postcode": "5067",
        "state": "South Australia"
    },
    {
        "suburb": "Dulwich",
        "postcode": "5065",
        "state": "South Australia"
    },
    {
        "suburb": "Athelstone",
        "postcode": "5076",
        "state": "South Australia"
    },
    {
        "suburb": "Newton",
        "postcode": "5074",
        "state": "South Australia"
    },
    {
        "suburb": "Paradise",
        "postcode": "5075",
        "state": "South Australia"
    },
    {
        "suburb": "Campbelltown",
        "postcode": "5074",
        "state": "South Australia"
    },
    {
        "suburb": "Hectorville",
        "postcode": "5073",
        "state": "South Australia"
    },
    {
        "suburb": "Tranmere",
        "postcode": "5073",
        "state": "South Australia"
    },
    {
        "suburb": "Charra",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Thevenard",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Pimbaacla",
        "postcode": "5661",
        "state": "South Australia"
    },
    {
        "suburb": "Ceduna",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Nunjikompita",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "White Well Corner",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Koonibba",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Kalanbi",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Denial Bay",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Mudamuckla",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Ceduna Waters",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Smoky Bay",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Nadia",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Watraba",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Pureba",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Wandana",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Maltee",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Chinbingina",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Merghiny",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Puntabie",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Uworra",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Carawa",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "West Beach",
        "postcode": "5024",
        "state": "South Australia"
    },
    {
        "suburb": "Henley Beach South",
        "postcode": "5022",
        "state": "South Australia"
    },
    {
        "suburb": "Semaphore Park",
        "postcode": "5019",
        "state": "South Australia"
    },
    {
        "suburb": "Grange",
        "postcode": "5022",
        "state": "South Australia"
    },
    {
        "suburb": "Henley Beach",
        "postcode": "5022",
        "state": "South Australia"
    },
    {
        "suburb": "West Lakes",
        "postcode": "5021",
        "state": "South Australia"
    },
    {
        "suburb": "West Lakes Shore",
        "postcode": "5020",
        "state": "South Australia"
    },
    {
        "suburb": "Tennyson",
        "postcode": "5022",
        "state": "South Australia"
    },
    {
        "suburb": "Woodville South",
        "postcode": "5011",
        "state": "South Australia"
    },
    {
        "suburb": "Beverley",
        "postcode": "5009",
        "state": "South Australia"
    },
    {
        "suburb": "Flinders Park",
        "postcode": "5025",
        "state": "South Australia"
    },
    {
        "suburb": "Findon",
        "postcode": "5023",
        "state": "South Australia"
    },
    {
        "suburb": "Allenby Gardens",
        "postcode": "5009",
        "state": "South Australia"
    },
    {
        "suburb": "Woodville West",
        "postcode": "5011",
        "state": "South Australia"
    },
    {
        "suburb": "West Hindmarsh",
        "postcode": "5007",
        "state": "South Australia"
    },
    {
        "suburb": "Welland",
        "postcode": "5007",
        "state": "South Australia"
    },
    {
        "suburb": "Hindmarsh",
        "postcode": "5007",
        "state": "South Australia"
    },
    {
        "suburb": "Fulham Gardens",
        "postcode": "5024",
        "state": "South Australia"
    },
    {
        "suburb": "Kidman Park",
        "postcode": "5025",
        "state": "South Australia"
    },
    {
        "suburb": "Seaton",
        "postcode": "5023",
        "state": "South Australia"
    },
    {
        "suburb": "Royal Park",
        "postcode": "5014",
        "state": "South Australia"
    },
    {
        "suburb": "Albert Park",
        "postcode": "5014",
        "state": "South Australia"
    },
    {
        "suburb": "Hendon",
        "postcode": "5014",
        "state": "South Australia"
    },
    {
        "suburb": "Brompton",
        "postcode": "5007",
        "state": "South Australia"
    },
    {
        "suburb": "Woodville",
        "postcode": "5011",
        "state": "South Australia"
    },
    {
        "suburb": "Renown Park",
        "postcode": "5008",
        "state": "South Australia"
    },
    {
        "suburb": "Kilkenny",
        "postcode": "5009",
        "state": "South Australia"
    },
    {
        "suburb": "West Croydon",
        "postcode": "5008",
        "state": "South Australia"
    },
    {
        "suburb": "Woodville North",
        "postcode": "5012",
        "state": "South Australia"
    },
    {
        "suburb": "St Clair",
        "postcode": "5011",
        "state": "South Australia"
    },
    {
        "suburb": "Ridleyton",
        "postcode": "5008",
        "state": "South Australia"
    },
    {
        "suburb": "Bowden",
        "postcode": "5007",
        "state": "South Australia"
    },
    {
        "suburb": "Devon Park",
        "postcode": "5008",
        "state": "South Australia"
    },
    {
        "suburb": "Ovingham",
        "postcode": "5082",
        "state": "South Australia"
    },
    {
        "suburb": "Croydon",
        "postcode": "5008",
        "state": "South Australia"
    },
    {
        "suburb": "Woodville Park",
        "postcode": "5011",
        "state": "South Australia"
    },
    {
        "suburb": "Athol Park",
        "postcode": "5012",
        "state": "South Australia"
    },
    {
        "suburb": "Pennington",
        "postcode": "5013",
        "state": "South Australia"
    },
    {
        "suburb": "Cheltenham",
        "postcode": "5014",
        "state": "South Australia"
    },
    {
        "suburb": "Tothill Belt",
        "postcode": "5413",
        "state": "South Australia"
    },
    {
        "suburb": "Armagh",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Tothill Creek",
        "postcode": "5413",
        "state": "South Australia"
    },
    {
        "suburb": "Stanley Flat",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Hill River",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Mintaro",
        "postcode": "5415",
        "state": "South Australia"
    },
    {
        "suburb": "Penwortham",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Saddleworth",
        "postcode": "5413",
        "state": "South Australia"
    },
    {
        "suburb": "Polish Hill River",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Waterloo",
        "postcode": "5413",
        "state": "South Australia"
    },
    {
        "suburb": "Steelton",
        "postcode": "5413",
        "state": "South Australia"
    },
    {
        "suburb": "Black Springs",
        "postcode": "5413",
        "state": "South Australia"
    },
    {
        "suburb": "Stanley",
        "postcode": "5415",
        "state": "South Australia"
    },
    {
        "suburb": "Farrell Flat",
        "postcode": "5416",
        "state": "South Australia"
    },
    {
        "suburb": "Salter Springs",
        "postcode": "5401",
        "state": "South Australia"
    },
    {
        "suburb": "Clare",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Woolshed Flat",
        "postcode": "5412",
        "state": "South Australia"
    },
    {
        "suburb": "Undalya",
        "postcode": "5451",
        "state": "South Australia"
    },
    {
        "suburb": "Rhynie",
        "postcode": "5412",
        "state": "South Australia"
    },
    {
        "suburb": "Giles Corner",
        "postcode": "5411",
        "state": "South Australia"
    },
    {
        "suburb": "Halbury",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Boconnoc Park",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Spring Gully",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Gillentown",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Sevenhill",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Stockport",
        "postcode": "5410",
        "state": "South Australia"
    },
    {
        "suburb": "Auburn",
        "postcode": "5451",
        "state": "South Australia"
    },
    {
        "suburb": "Hoyleton",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Hilltown",
        "postcode": "5455",
        "state": "South Australia"
    },
    {
        "suburb": "Barinia",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Bungaree",
        "postcode": "5464",
        "state": "South Australia"
    },
    {
        "suburb": "Anama",
        "postcode": "5464",
        "state": "South Australia"
    },
    {
        "suburb": "Marrabel",
        "postcode": "5413",
        "state": "South Australia"
    },
    {
        "suburb": "Watervale",
        "postcode": "5452",
        "state": "South Australia"
    },
    {
        "suburb": "Benbournie",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Emu Flat",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Riverton",
        "postcode": "5412",
        "state": "South Australia"
    },
    {
        "suburb": "Tarlee",
        "postcode": "5411",
        "state": "South Australia"
    },
    {
        "suburb": "Alma",
        "postcode": "5401",
        "state": "South Australia"
    },
    {
        "suburb": "Manoora",
        "postcode": "5414",
        "state": "South Australia"
    },
    {
        "suburb": "Spring Farm",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Tarnma",
        "postcode": "5413",
        "state": "South Australia"
    },
    {
        "suburb": "Cleve",
        "postcode": "5640",
        "state": "South Australia"
    },
    {
        "suburb": "Wharminda",
        "postcode": "5603",
        "state": "South Australia"
    },
    {
        "suburb": "Darke Peak",
        "postcode": "5642",
        "state": "South Australia"
    },
    {
        "suburb": "Boonerdo",
        "postcode": "5633",
        "state": "South Australia"
    },
    {
        "suburb": "Hincks",
        "postcode": "5603",
        "state": "South Australia"
    },
    {
        "suburb": "Murlong",
        "postcode": "5642",
        "state": "South Australia"
    },
    {
        "suburb": "Campoona",
        "postcode": "5640",
        "state": "South Australia"
    },
    {
        "suburb": "Jamieson",
        "postcode": "5640",
        "state": "South Australia"
    },
    {
        "suburb": "Hambidge",
        "postcode": "5642",
        "state": "South Australia"
    },
    {
        "suburb": "Rudall",
        "postcode": "5642",
        "state": "South Australia"
    },
    {
        "suburb": "Verran",
        "postcode": "5603",
        "state": "South Australia"
    },
    {
        "suburb": "Arno Bay",
        "postcode": "5603",
        "state": "South Australia"
    },
    {
        "suburb": "Kielpa",
        "postcode": "5642",
        "state": "South Australia"
    },
    {
        "suburb": "Waddikee",
        "postcode": "5640",
        "state": "South Australia"
    },
    {
        "suburb": "Coober Pedy",
        "postcode": "5723",
        "state": "South Australia"
    },
    {
        "suburb": "Wallaroo",
        "postcode": "5556",
        "state": "South Australia"
    },
    {
        "suburb": "Kadina",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "Matta Flat",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "Jerusalem",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "Wallaroo Mines",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "Moonta Bay",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Kooroona",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Thrington",
        "postcode": "5552",
        "state": "South Australia"
    },
    {
        "suburb": "Willamulka",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "Wallaroo Plain",
        "postcode": "5556",
        "state": "South Australia"
    },
    {
        "suburb": "Moonta",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Moonta Mines",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Hamley",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Yelta",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "New Town",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "Cunliffe",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "East Moonta",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Port Hughes",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "North Beach",
        "postcode": "5556",
        "state": "South Australia"
    },
    {
        "suburb": "Boors Plain",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "North Moonta",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Warburto",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Jericho",
        "postcode": "5554",
        "state": "South Australia"
    },
    {
        "suburb": "Cross Roads",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Paramatta",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "North Yelta",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Talia",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Elliston",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Colton",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Venus Bay",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Port Kenny",
        "postcode": "5671",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Damper",
        "postcode": "5654",
        "state": "South Australia"
    },
    {
        "suburb": "Palkagee",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Sheringa",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Ulyerra",
        "postcode": "5633",
        "state": "South Australia"
    },
    {
        "suburb": "Tooligie",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Murdinga",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Polda",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Joy",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Lock",
        "postcode": "5633",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Wedge",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Kappawanta",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Bramfield",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Coolillie",
        "postcode": "5670",
        "state": "South Australia"
    },
    {
        "suburb": "Quorn",
        "postcode": "5433",
        "state": "South Australia"
    },
    {
        "suburb": "Barndioota",
        "postcode": "5434",
        "state": "South Australia"
    },
    {
        "suburb": "Cradock",
        "postcode": "5432",
        "state": "South Australia"
    },
    {
        "suburb": "Hawker",
        "postcode": "5434",
        "state": "South Australia"
    },
    {
        "suburb": "Moockra",
        "postcode": "5432",
        "state": "South Australia"
    },
    {
        "suburb": "Saltia",
        "postcode": "5433",
        "state": "South Australia"
    },
    {
        "suburb": "Yarrah",
        "postcode": "5433",
        "state": "South Australia"
    },
    {
        "suburb": "Wilmington",
        "postcode": "5485",
        "state": "South Australia"
    },
    {
        "suburb": "Bruce",
        "postcode": "5433",
        "state": "South Australia"
    },
    {
        "suburb": "Stephenston",
        "postcode": "5433",
        "state": "South Australia"
    },
    {
        "suburb": "Willochra",
        "postcode": "5433",
        "state": "South Australia"
    },
    {
        "suburb": "Cowell",
        "postcode": "5602",
        "state": "South Australia"
    },
    {
        "suburb": "Port Gibbon",
        "postcode": "5602",
        "state": "South Australia"
    },
    {
        "suburb": "Miltalie",
        "postcode": "5602",
        "state": "South Australia"
    },
    {
        "suburb": "Mangalo",
        "postcode": "5602",
        "state": "South Australia"
    },
    {
        "suburb": "Minbrie",
        "postcode": "5602",
        "state": "South Australia"
    },
    {
        "suburb": "Lucky Bay",
        "postcode": "5602",
        "state": "South Australia"
    },
    {
        "suburb": "Evanston",
        "postcode": "5116",
        "state": "South Australia"
    },
    {
        "suburb": "Evanston Park",
        "postcode": "5116",
        "state": "South Australia"
    },
    {
        "suburb": "Gawler East",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Gawler",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Willaston",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Evanston South",
        "postcode": "5116",
        "state": "South Australia"
    },
    {
        "suburb": "Gawler South",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Hillier",
        "postcode": "5116",
        "state": "South Australia"
    },
    {
        "suburb": "Gawler West",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Evanston Gardens",
        "postcode": "5116",
        "state": "South Australia"
    },
    {
        "suburb": "Kudla",
        "postcode": "5115",
        "state": "South Australia"
    },
    {
        "suburb": "Burra",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Pine Creek",
        "postcode": "5419",
        "state": "South Australia"
    },
    {
        "suburb": "Terowie",
        "postcode": "5421",
        "state": "South Australia"
    },
    {
        "suburb": "Eudunda",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Booborowie",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Canowie Belt",
        "postcode": "5420",
        "state": "South Australia"
    },
    {
        "suburb": "Bower",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Buchanan",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Brady Creek",
        "postcode": "5381",
        "state": "South Australia"
    },
    {
        "suburb": "Burra Eastern Districts",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Gum Creek",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Apoinga",
        "postcode": "5413",
        "state": "South Australia"
    },
    {
        "suburb": "Porter Lagoon",
        "postcode": "5416",
        "state": "South Australia"
    },
    {
        "suburb": "Hansborough",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Steinfeld",
        "postcode": "5356",
        "state": "South Australia"
    },
    {
        "suburb": "Dutton",
        "postcode": "5356",
        "state": "South Australia"
    },
    {
        "suburb": "Frankton",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Brownlow",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Hampden",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Bundey",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Ngapala",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "North Booborowie",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Bryan",
        "postcode": "5418",
        "state": "South Australia"
    },
    {
        "suburb": "Julia",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Franklyn",
        "postcode": "5421",
        "state": "South Australia"
    },
    {
        "suburb": "Ulooloo",
        "postcode": "5419",
        "state": "South Australia"
    },
    {
        "suburb": "Hallett",
        "postcode": "5419",
        "state": "South Australia"
    },
    {
        "suburb": "Whyte Yarcowie",
        "postcode": "5420",
        "state": "South Australia"
    },
    {
        "suburb": "Willalo",
        "postcode": "5419",
        "state": "South Australia"
    },
    {
        "suburb": "Canowie",
        "postcode": "5419",
        "state": "South Australia"
    },
    {
        "suburb": "Neales Flat",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Point Pass",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Peep Hill",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Mongolata",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Baldina",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Worlds End",
        "postcode": "5381",
        "state": "South Australia"
    },
    {
        "suburb": "Hanson",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Koonoona",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Emu Downs",
        "postcode": "5381",
        "state": "South Australia"
    },
    {
        "suburb": "Hallelujah Hills",
        "postcode": "5381",
        "state": "South Australia"
    },
    {
        "suburb": "Bright",
        "postcode": "5381",
        "state": "South Australia"
    },
    {
        "suburb": "Australia Plains",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Rocky Plain",
        "postcode": "5381",
        "state": "South Australia"
    },
    {
        "suburb": "Geranium Plains",
        "postcode": "5381",
        "state": "South Australia"
    },
    {
        "suburb": "Sutherlands",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Wonna",
        "postcode": "5419",
        "state": "South Australia"
    },
    {
        "suburb": "Robertstown",
        "postcode": "5381",
        "state": "South Australia"
    },
    {
        "suburb": "Leighton",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Allendale East",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Mingbool",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Glenburnie",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Moorak",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Suttontown",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Canunda",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Yahl",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Wye",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Donovans",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Square Mile",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Port Macdonnell",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Caroline",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Nene Valley",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Burrungule",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Compton",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Cape Douglas",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Dismal Swamp",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Worrolong",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Mil-Lel",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Caveton",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Pleasant Park",
        "postcode": "5277",
        "state": "South Australia"
    },
    {
        "suburb": "Wepar",
        "postcode": "5278",
        "state": "South Australia"
    },
    {
        "suburb": "Tantanoola",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Ob Flat",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Tarpeena",
        "postcode": "5277",
        "state": "South Australia"
    },
    {
        "suburb": "Carpenter Rocks",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Blackfellows Caves",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Eight Mile Creek",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Racecourse Bay",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Kongorong",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Schank",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Somerton Park",
        "postcode": "5044",
        "state": "South Australia"
    },
    {
        "suburb": "Glenelg",
        "postcode": "5045",
        "state": "South Australia"
    },
    {
        "suburb": "Glenelg North",
        "postcode": "5045",
        "state": "South Australia"
    },
    {
        "suburb": "Glenelg South",
        "postcode": "5045",
        "state": "South Australia"
    },
    {
        "suburb": "Glenelg East",
        "postcode": "5045",
        "state": "South Australia"
    },
    {
        "suburb": "Brighton",
        "postcode": "5048",
        "state": "South Australia"
    },
    {
        "suburb": "North Brighton",
        "postcode": "5048",
        "state": "South Australia"
    },
    {
        "suburb": "Kingston Park",
        "postcode": "5049",
        "state": "South Australia"
    },
    {
        "suburb": "South Brighton",
        "postcode": "5048",
        "state": "South Australia"
    },
    {
        "suburb": "Seacliff",
        "postcode": "5049",
        "state": "South Australia"
    },
    {
        "suburb": "Hove",
        "postcode": "5048",
        "state": "South Australia"
    },
    {
        "suburb": "Seacliff Park",
        "postcode": "5049",
        "state": "South Australia"
    },
    {
        "suburb": "Nepean Bay",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Bay Of Shoals",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Willoughby",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Ironstone",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "D'Estrees Bay",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Pelican Lagoon",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Emu Bay",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Wisanger",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Stun'Sail Boom",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Macgillivray",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Gosse",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Haines",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "American River",
        "postcode": "5221",
        "state": "South Australia"
    },
    {
        "suburb": "Penneshaw",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Kingscote",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Vivonne Bay",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Willson River",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Porky Flat",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "North Cape",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Antechamber Bay",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Parndana",
        "postcode": "5220",
        "state": "South Australia"
    },
    {
        "suburb": "Cygnet River",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Ballast Head",
        "postcode": "5221",
        "state": "South Australia"
    },
    {
        "suburb": "De Mole River",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Baudin Beach",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Dudley East",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Duncan",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Seal Bay",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Western River",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Muston",
        "postcode": "5221",
        "state": "South Australia"
    },
    {
        "suburb": "Cape Borda",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Cassini",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Sapphiretown",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Island Beach",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "American Beach",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Brown Beach",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Brownlow Ki",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Menzies",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Flinders Chase",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Seddon",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Newland",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Kangaroo Head",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Karatta",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Birchmore",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Cuttlefish Bay",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Kohinoor",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Stokes Bay",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Middle River",
        "postcode": "5223",
        "state": "South Australia"
    },
    {
        "suburb": "Galga",
        "postcode": "5308",
        "state": "South Australia"
    },
    {
        "suburb": "Marama",
        "postcode": "5307",
        "state": "South Australia"
    },
    {
        "suburb": "Mindarie",
        "postcode": "5309",
        "state": "South Australia"
    },
    {
        "suburb": "Karoonda",
        "postcode": "5307",
        "state": "South Australia"
    },
    {
        "suburb": "Sandalwood",
        "postcode": "5309",
        "state": "South Australia"
    },
    {
        "suburb": "Halidon",
        "postcode": "5309",
        "state": "South Australia"
    },
    {
        "suburb": "Mantung",
        "postcode": "5308",
        "state": "South Australia"
    },
    {
        "suburb": "Bowhill",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Wynarka",
        "postcode": "5306",
        "state": "South Australia"
    },
    {
        "suburb": "Bakara",
        "postcode": "5354",
        "state": "South Australia"
    },
    {
        "suburb": "Copeville",
        "postcode": "5308",
        "state": "South Australia"
    },
    {
        "suburb": "Borrika",
        "postcode": "5309",
        "state": "South Australia"
    },
    {
        "suburb": "Mercunda",
        "postcode": "5308",
        "state": "South Australia"
    },
    {
        "suburb": "Perponda",
        "postcode": "5308",
        "state": "South Australia"
    },
    {
        "suburb": "Wanbi",
        "postcode": "5310",
        "state": "South Australia"
    },
    {
        "suburb": "Koongawa",
        "postcode": "5650",
        "state": "South Australia"
    },
    {
        "suburb": "Kelly",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Wilcherry",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Kimba",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Solomon",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Cunyarie",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Barna",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Caralue",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Cootra",
        "postcode": "5650",
        "state": "South Australia"
    },
    {
        "suburb": "Yalanda",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Panitya",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Cortlinye",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Wangolina",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "West Range",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Keilira",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Blackford",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Coorong",
        "postcode": "5264",
        "state": "South Australia"
    },
    {
        "suburb": "Marcollat",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Tilley Swamp",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Cape Jaffa",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Sandy Grove",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Pinks Beach",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Kingston Se",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Wyomi",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Rosetown",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Avenue Range",
        "postcode": "5273",
        "state": "South Australia"
    },
    {
        "suburb": "Wudinna",
        "postcode": "5652",
        "state": "South Australia"
    },
    {
        "suburb": "Pygery",
        "postcode": "5652",
        "state": "South Australia"
    },
    {
        "suburb": "Warramboo",
        "postcode": "5650",
        "state": "South Australia"
    },
    {
        "suburb": "Kyancutta",
        "postcode": "5651",
        "state": "South Australia"
    },
    {
        "suburb": "Cocata",
        "postcode": "5654",
        "state": "South Australia"
    },
    {
        "suburb": "Karcultaby",
        "postcode": "5654",
        "state": "South Australia"
    },
    {
        "suburb": "Minnipa",
        "postcode": "5654",
        "state": "South Australia"
    },
    {
        "suburb": "Yaninee",
        "postcode": "5653",
        "state": "South Australia"
    },
    {
        "suburb": "Greenock",
        "postcode": "5360",
        "state": "South Australia"
    },
    {
        "suburb": "Kapunda",
        "postcode": "5373",
        "state": "South Australia"
    },
    {
        "suburb": "Kingsford",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Pinkerton Plains",
        "postcode": "5400",
        "state": "South Australia"
    },
    {
        "suburb": "Shea-Oak Log",
        "postcode": "5371",
        "state": "South Australia"
    },
    {
        "suburb": "Koonunga",
        "postcode": "5373",
        "state": "South Australia"
    },
    {
        "suburb": "Roseworthy",
        "postcode": "5371",
        "state": "South Australia"
    },
    {
        "suburb": "Ebenezer",
        "postcode": "5355",
        "state": "South Australia"
    },
    {
        "suburb": "Hewett",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Hamilton",
        "postcode": "5373",
        "state": "South Australia"
    },
    {
        "suburb": "Stone Well",
        "postcode": "5352",
        "state": "South Australia"
    },
    {
        "suburb": "Gawler Belt",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Ward Belt",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Bagot Well",
        "postcode": "5373",
        "state": "South Australia"
    },
    {
        "suburb": "Woolsheds",
        "postcode": "5400",
        "state": "South Australia"
    },
    {
        "suburb": "Wasleys",
        "postcode": "5400",
        "state": "South Australia"
    },
    {
        "suburb": "Allendale North",
        "postcode": "5373",
        "state": "South Australia"
    },
    {
        "suburb": "Hamley Bridge",
        "postcode": "5401",
        "state": "South Australia"
    },
    {
        "suburb": "Magdala",
        "postcode": "5400",
        "state": "South Australia"
    },
    {
        "suburb": "Freeling",
        "postcode": "5372",
        "state": "South Australia"
    },
    {
        "suburb": "Daveyston",
        "postcode": "5355",
        "state": "South Australia"
    },
    {
        "suburb": "Fords",
        "postcode": "5373",
        "state": "South Australia"
    },
    {
        "suburb": "Moppa",
        "postcode": "5355",
        "state": "South Australia"
    },
    {
        "suburb": "Nain",
        "postcode": "5360",
        "state": "South Australia"
    },
    {
        "suburb": "Templers",
        "postcode": "5371",
        "state": "South Australia"
    },
    {
        "suburb": "Reeves Plains",
        "postcode": "5502",
        "state": "South Australia"
    },
    {
        "suburb": "St Kitts",
        "postcode": "5356",
        "state": "South Australia"
    },
    {
        "suburb": "Morn Hill",
        "postcode": "5371",
        "state": "South Australia"
    },
    {
        "suburb": "Bethel",
        "postcode": "5373",
        "state": "South Australia"
    },
    {
        "suburb": "Truro",
        "postcode": "5356",
        "state": "South Australia"
    },
    {
        "suburb": "St Johns",
        "postcode": "5373",
        "state": "South Australia"
    },
    {
        "suburb": "Linwood",
        "postcode": "5410",
        "state": "South Australia"
    },
    {
        "suburb": "Seppeltsfield",
        "postcode": "5355",
        "state": "South Australia"
    },
    {
        "suburb": "Gomersal",
        "postcode": "5352",
        "state": "South Australia"
    },
    {
        "suburb": "Marananga",
        "postcode": "5355",
        "state": "South Australia"
    },
    {
        "suburb": "Coffin Bay",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Drummond",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Sleaford",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Lincoln National Park",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Tumby Bay",
        "postcode": "5605",
        "state": "South Australia"
    },
    {
        "suburb": "Cummins",
        "postcode": "5631",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Dutton Bay",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Wanilla",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "North Shields",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Tulka",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Edillilie",
        "postcode": "5630",
        "state": "South Australia"
    },
    {
        "suburb": "Coulta",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Yeelanna",
        "postcode": "5632",
        "state": "South Australia"
    },
    {
        "suburb": "Whites Flat",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Charlton Gully",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Fountain",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Boston",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Coomunga",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Green Patch",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Louth Bay",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Kiana",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Little Douglas",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Kellidie Bay",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Karkoo",
        "postcode": "5632",
        "state": "South Australia"
    },
    {
        "suburb": "Uley",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Hawson",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Point Boston",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Port Lincoln",
        "postcode": "5606",
        "state": "South Australia"
    },
    {
        "suburb": "Tiatukia",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Kapinnie",
        "postcode": "5632",
        "state": "South Australia"
    },
    {
        "suburb": "Whites River",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Mitchell",
        "postcode": "5632",
        "state": "South Australia"
    },
    {
        "suburb": "Duck Ponds",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Bookpurnong",
        "postcode": "5333",
        "state": "South Australia"
    },
    {
        "suburb": "Loxton",
        "postcode": "5333",
        "state": "South Australia"
    },
    {
        "suburb": "Kingston On Murray",
        "postcode": "5331",
        "state": "South Australia"
    },
    {
        "suburb": "Moorook South",
        "postcode": "5332",
        "state": "South Australia"
    },
    {
        "suburb": "New Residence",
        "postcode": "5333",
        "state": "South Australia"
    },
    {
        "suburb": "Pyap",
        "postcode": "5333",
        "state": "South Australia"
    },
    {
        "suburb": "Meribah",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Loxton North",
        "postcode": "5333",
        "state": "South Australia"
    },
    {
        "suburb": "Malpas",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Paruna",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Peebinga",
        "postcode": "5304",
        "state": "South Australia"
    },
    {
        "suburb": "Billiatt",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Alawoona",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Kringin",
        "postcode": "5304",
        "state": "South Australia"
    },
    {
        "suburb": "Schell Well",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Taldra",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Bugle Hut",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Woodleigh",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Taplan",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Yinkanie",
        "postcode": "5332",
        "state": "South Australia"
    },
    {
        "suburb": "Wigley Flat",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Wappilka",
        "postcode": "5332",
        "state": "South Australia"
    },
    {
        "suburb": "Woolpunda",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Moorook",
        "postcode": "5332",
        "state": "South Australia"
    },
    {
        "suburb": "Veitch",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Caliph",
        "postcode": "5310",
        "state": "South Australia"
    },
    {
        "suburb": "Wunkar",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Pata",
        "postcode": "5333",
        "state": "South Australia"
    },
    {
        "suburb": "Pyap West",
        "postcode": "5333",
        "state": "South Australia"
    },
    {
        "suburb": "Maggea",
        "postcode": "5311",
        "state": "South Australia"
    },
    {
        "suburb": "Naidia",
        "postcode": "5354",
        "state": "South Australia"
    },
    {
        "suburb": "Murbko",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Bakara Well",
        "postcode": "5354",
        "state": "South Australia"
    },
    {
        "suburb": "Qualco",
        "postcode": "5322",
        "state": "South Australia"
    },
    {
        "suburb": "Sunlands",
        "postcode": "5322",
        "state": "South Australia"
    },
    {
        "suburb": "Waikerie",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Paisley",
        "postcode": "5357",
        "state": "South Australia"
    },
    {
        "suburb": "Lowbank",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Good Hope Landing",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Markaranka",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Taylorville",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Stockyard Plain",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Notts Well",
        "postcode": "5357",
        "state": "South Australia"
    },
    {
        "suburb": "Boolgun",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Kanni",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Golden Heights",
        "postcode": "5322",
        "state": "South Australia"
    },
    {
        "suburb": "New Well",
        "postcode": "5357",
        "state": "South Australia"
    },
    {
        "suburb": "Holder Siding",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Ramco Heights",
        "postcode": "5322",
        "state": "South Australia"
    },
    {
        "suburb": "Fischer",
        "postcode": "5502",
        "state": "South Australia"
    },
    {
        "suburb": "Thompson Beach",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Webb Beach",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Lewiston",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Windsor",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Lower Light",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Wild Horse Plains",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Long Plains",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Port Gawler",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Barabba",
        "postcode": "5460",
        "state": "South Australia"
    },
    {
        "suburb": "Grace Plains",
        "postcode": "5502",
        "state": "South Australia"
    },
    {
        "suburb": "Parham",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Redbanks",
        "postcode": "5502",
        "state": "South Australia"
    },
    {
        "suburb": "Calomba",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Middle Beach",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Mallala",
        "postcode": "5502",
        "state": "South Australia"
    },
    {
        "suburb": "Two Wells",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Dublin",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Korunye",
        "postcode": "5502",
        "state": "South Australia"
    },
    {
        "suburb": "SA Remainder",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Clovelly Park",
        "postcode": "5042",
        "state": "South Australia"
    },
    {
        "suburb": "Dover Gardens",
        "postcode": "5048",
        "state": "South Australia"
    },
    {
        "suburb": "Darlington",
        "postcode": "5047",
        "state": "South Australia"
    },
    {
        "suburb": "Marino",
        "postcode": "5049",
        "state": "South Australia"
    },
    {
        "suburb": "Mitchell Park",
        "postcode": "5043",
        "state": "South Australia"
    },
    {
        "suburb": "Oaklands Park",
        "postcode": "5046",
        "state": "South Australia"
    },
    {
        "suburb": "Marion",
        "postcode": "5043",
        "state": "South Australia"
    },
    {
        "suburb": "Bedford Park",
        "postcode": "5042",
        "state": "South Australia"
    },
    {
        "suburb": "O'Halloran Hill",
        "postcode": "5158",
        "state": "South Australia"
    },
    {
        "suburb": "Warradale",
        "postcode": "5046",
        "state": "South Australia"
    },
    {
        "suburb": "Seaview Downs",
        "postcode": "5049",
        "state": "South Australia"
    },
    {
        "suburb": "Seacombe Heights",
        "postcode": "5047",
        "state": "South Australia"
    },
    {
        "suburb": "Seacombe Gardens",
        "postcode": "5047",
        "state": "South Australia"
    },
    {
        "suburb": "Sturt",
        "postcode": "5047",
        "state": "South Australia"
    },
    {
        "suburb": "Glandore",
        "postcode": "5037",
        "state": "South Australia"
    },
    {
        "suburb": "Glengowrie",
        "postcode": "5044",
        "state": "South Australia"
    },
    {
        "suburb": "Morphettville",
        "postcode": "5043",
        "state": "South Australia"
    },
    {
        "suburb": "Edwardstown",
        "postcode": "5039",
        "state": "South Australia"
    },
    {
        "suburb": "Ascot Park",
        "postcode": "5043",
        "state": "South Australia"
    },
    {
        "suburb": "South Plympton",
        "postcode": "5038",
        "state": "South Australia"
    },
    {
        "suburb": "Plympton Park",
        "postcode": "5038",
        "state": "South Australia"
    },
    {
        "suburb": "Park Holme",
        "postcode": "5043",
        "state": "South Australia"
    },
    {
        "suburb": "Hallett Cove",
        "postcode": "5158",
        "state": "South Australia"
    },
    {
        "suburb": "Trott Park",
        "postcode": "5158",
        "state": "South Australia"
    },
    {
        "suburb": "Lonsdale",
        "postcode": "5158",
        "state": "South Australia"
    },
    {
        "suburb": "Sheidow Park",
        "postcode": "5158",
        "state": "South Australia"
    },
    {
        "suburb": "Nildottie",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Cadell",
        "postcode": "5321",
        "state": "South Australia"
    },
    {
        "suburb": "Walker Flat",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Keyneton",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Five Miles",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Swan Reach",
        "postcode": "5354",
        "state": "South Australia"
    },
    {
        "suburb": "Blanchetown",
        "postcode": "5357",
        "state": "South Australia"
    },
    {
        "suburb": "Fisher",
        "postcode": "5354",
        "state": "South Australia"
    },
    {
        "suburb": "Port Mannum",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Claypans",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Morgan",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Beatty",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Rockleigh",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Pompoota",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Dutton East",
        "postcode": "5356",
        "state": "South Australia"
    },
    {
        "suburb": "Beaumonts",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Mannum",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Punyelroo",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Wongulla",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Langs Landing",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Marks Landing",
        "postcode": "5354",
        "state": "South Australia"
    },
    {
        "suburb": "Sunnydale",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Brenda Park",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Stuart",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Caurnamont",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Purnong",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Mary",
        "postcode": "5374",
        "state": "South Australia"
    },
    {
        "suburb": "Tepko",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Palmer",
        "postcode": "5237",
        "state": "South Australia"
    },
    {
        "suburb": "Maude",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Cowirra",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Wall Flat",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Caloote",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Cambrai",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Pellaring Flat",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Carlet",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "McBean Pound",
        "postcode": "5357",
        "state": "South Australia"
    },
    {
        "suburb": "Morphetts Flat",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Younghusband",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Old Teal Flat",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Burdett",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Milendella",
        "postcode": "5237",
        "state": "South Australia"
    },
    {
        "suburb": "Apamurra",
        "postcode": "5237",
        "state": "South Australia"
    },
    {
        "suburb": "Frayville",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Punthari",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Teal Flat",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Rocky Point",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Sanderston",
        "postcode": "5237",
        "state": "South Australia"
    },
    {
        "suburb": "Ponde",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Younghusband Holdings",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Frahns",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Towitta",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Angas Valley",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Black Hill",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Big Bend",
        "postcode": "5354",
        "state": "South Australia"
    },
    {
        "suburb": "Sandleton",
        "postcode": "5356",
        "state": "South Australia"
    },
    {
        "suburb": "Annadale",
        "postcode": "5356",
        "state": "South Australia"
    },
    {
        "suburb": "Eba",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Bolto",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Tungkillo",
        "postcode": "5236",
        "state": "South Australia"
    },
    {
        "suburb": "Wombats Rest",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Lindley",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Zadows Landing",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Julanka Holdings",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Coromandel Valley",
        "postcode": "5051",
        "state": "South Australia"
    },
    {
        "suburb": "Eden Hills",
        "postcode": "5050",
        "state": "South Australia"
    },
    {
        "suburb": "Craigburn Farm",
        "postcode": "5051",
        "state": "South Australia"
    },
    {
        "suburb": "Hawthorndene",
        "postcode": "5051",
        "state": "South Australia"
    },
    {
        "suburb": "Bellevue Heights",
        "postcode": "5050",
        "state": "South Australia"
    },
    {
        "suburb": "Glenalta",
        "postcode": "5052",
        "state": "South Australia"
    },
    {
        "suburb": "Blackwood",
        "postcode": "5051",
        "state": "South Australia"
    },
    {
        "suburb": "Lynton",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Torrens Park",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Brown Hill Creek",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Mitcham",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Hawthorn",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Urrbrae",
        "postcode": "5064",
        "state": "South Australia"
    },
    {
        "suburb": "Springfield",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Netherby",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Lower Mitcham",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Kingswood",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Clapham",
        "postcode": "5062",
        "state": "South Australia"
    },
    {
        "suburb": "Colonel Light Gardens",
        "postcode": "5041",
        "state": "South Australia"
    },
    {
        "suburb": "Pasadena",
        "postcode": "5042",
        "state": "South Australia"
    },
    {
        "suburb": "Panorama",
        "postcode": "5041",
        "state": "South Australia"
    },
    {
        "suburb": "Westbourne Park",
        "postcode": "5041",
        "state": "South Australia"
    },
    {
        "suburb": "St Marys",
        "postcode": "5042",
        "state": "South Australia"
    },
    {
        "suburb": "Cumberland Park",
        "postcode": "5041",
        "state": "South Australia"
    },
    {
        "suburb": "Daw Park",
        "postcode": "5041",
        "state": "South Australia"
    },
    {
        "suburb": "Melrose Park",
        "postcode": "5039",
        "state": "South Australia"
    },
    {
        "suburb": "Clarence Gardens",
        "postcode": "5039",
        "state": "South Australia"
    },
    {
        "suburb": "Totness",
        "postcode": "5250",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Barker",
        "postcode": "5251",
        "state": "South Australia"
    },
    {
        "suburb": "Nairne",
        "postcode": "5252",
        "state": "South Australia"
    },
    {
        "suburb": "Hahndorf",
        "postcode": "5245",
        "state": "South Australia"
    },
    {
        "suburb": "Littlehampton",
        "postcode": "5250",
        "state": "South Australia"
    },
    {
        "suburb": "Blakiston",
        "postcode": "5250",
        "state": "South Australia"
    },
    {
        "suburb": "Brukunga",
        "postcode": "5252",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Barker Springs",
        "postcode": "5251",
        "state": "South Australia"
    },
    {
        "suburb": "Bugle Ranges",
        "postcode": "5251",
        "state": "South Australia"
    },
    {
        "suburb": "Harrogate",
        "postcode": "5244",
        "state": "South Australia"
    },
    {
        "suburb": "St Ives",
        "postcode": "5252",
        "state": "South Australia"
    },
    {
        "suburb": "Kanmantoo",
        "postcode": "5252",
        "state": "South Australia"
    },
    {
        "suburb": "Biggs Flat",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Echunga",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Barker Summit",
        "postcode": "5251",
        "state": "South Australia"
    },
    {
        "suburb": "Dawesley",
        "postcode": "5252",
        "state": "South Australia"
    },
    {
        "suburb": "Petwood",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Paechtown",
        "postcode": "5245",
        "state": "South Australia"
    },
    {
        "suburb": "Meadows",
        "postcode": "5201",
        "state": "South Australia"
    },
    {
        "suburb": "Green Hills Range",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Kuitpo",
        "postcode": "5201",
        "state": "South Australia"
    },
    {
        "suburb": "Kangarilla",
        "postcode": "5157",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Barker Junction",
        "postcode": "5251",
        "state": "South Australia"
    },
    {
        "suburb": "Wistow",
        "postcode": "5251",
        "state": "South Australia"
    },
    {
        "suburb": "Callington",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Chapel Hill",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Hay Valley",
        "postcode": "5252",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Gambier",
        "postcode": "5290",
        "state": "South Australia"
    },
    {
        "suburb": "Wirrabara",
        "postcode": "5481",
        "state": "South Australia"
    },
    {
        "suburb": "Port Germein",
        "postcode": "5495",
        "state": "South Australia"
    },
    {
        "suburb": "Booleroo Centre",
        "postcode": "5482",
        "state": "South Australia"
    },
    {
        "suburb": "Germein Bay",
        "postcode": "5495",
        "state": "South Australia"
    },
    {
        "suburb": "Melrose",
        "postcode": "5483",
        "state": "South Australia"
    },
    {
        "suburb": "Winninowie",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Nectar Brook",
        "postcode": "5495",
        "state": "South Australia"
    },
    {
        "suburb": "Telowie",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Mambray Creek",
        "postcode": "5495",
        "state": "South Australia"
    },
    {
        "suburb": "Baroota",
        "postcode": "5495",
        "state": "South Australia"
    },
    {
        "suburb": "Morchard",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Woolundunga",
        "postcode": "5701",
        "state": "South Australia"
    },
    {
        "suburb": "Stone Hut",
        "postcode": "5480",
        "state": "South Australia"
    },
    {
        "suburb": "Bangor",
        "postcode": "5481",
        "state": "South Australia"
    },
    {
        "suburb": "Appila",
        "postcode": "5480",
        "state": "South Australia"
    },
    {
        "suburb": "Weeroona Island",
        "postcode": "5495",
        "state": "South Australia"
    },
    {
        "suburb": "Amyton",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Coomooroo",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Wongyarra",
        "postcode": "5481",
        "state": "South Australia"
    },
    {
        "suburb": "Murray Town",
        "postcode": "5481",
        "state": "South Australia"
    },
    {
        "suburb": "Murray Bridge",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Riverglades",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Murray Bridge East",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Avoca Dell",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "White Hill",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Monarto",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "White Sands",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Murrawong",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Pallamana",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Rocky Gully",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Gifford Hill",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Northern Heights",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Long Flat",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Toora",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Murray Bridge South",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Wellington",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Mulgundawa",
        "postcode": "5255",
        "state": "South Australia"
    },
    {
        "suburb": "Ettrick",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Chapman Bore",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Naturi",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Monarto South",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Mypolonga",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Jervois",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Woods Point",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Kepa",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Murray Bridge North",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Mobilong",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Riverglen",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Swanport",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Monteith",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Sunnyside",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Brinkley",
        "postcode": "5253",
        "state": "South Australia"
    },
    {
        "suburb": "Conmurra",
        "postcode": "5272",
        "state": "South Australia"
    },
    {
        "suburb": "Lucindale",
        "postcode": "5272",
        "state": "South Australia"
    },
    {
        "suburb": "Naracoorte",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Wrattonbully",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Hynam",
        "postcode": "5262",
        "state": "South Australia"
    },
    {
        "suburb": "Kybybolite",
        "postcode": "5262",
        "state": "South Australia"
    },
    {
        "suburb": "Joanna",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Frances",
        "postcode": "5262",
        "state": "South Australia"
    },
    {
        "suburb": "Coles",
        "postcode": "5272",
        "state": "South Australia"
    },
    {
        "suburb": "Bool Lagoon",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "The Gap",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Fox",
        "postcode": "5272",
        "state": "South Australia"
    },
    {
        "suburb": "Woolumbool",
        "postcode": "5272",
        "state": "South Australia"
    },
    {
        "suburb": "Keppoch",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Light",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Koppamurra",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Laurie Park",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Struan",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Lochaber",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Binnum",
        "postcode": "5262",
        "state": "South Australia"
    },
    {
        "suburb": "Spence",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Wild Dog Valley",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Cadgee",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Stewart Range",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Spalding",
        "postcode": "5454",
        "state": "South Australia"
    },
    {
        "suburb": "Washpool",
        "postcode": "5454",
        "state": "South Australia"
    },
    {
        "suburb": "Bundaleer Gardens",
        "postcode": "5491",
        "state": "South Australia"
    },
    {
        "suburb": "Gladstone",
        "postcode": "5473",
        "state": "South Australia"
    },
    {
        "suburb": "Huddleston",
        "postcode": "5523",
        "state": "South Australia"
    },
    {
        "suburb": "Beetaloo Valley",
        "postcode": "5523",
        "state": "South Australia"
    },
    {
        "suburb": "Yacka",
        "postcode": "5470",
        "state": "South Australia"
    },
    {
        "suburb": "Mannanarie",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Yatina",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Jamestown",
        "postcode": "5491",
        "state": "South Australia"
    },
    {
        "suburb": "Belalie North",
        "postcode": "5491",
        "state": "South Australia"
    },
    {
        "suburb": "Belalie East",
        "postcode": "5491",
        "state": "South Australia"
    },
    {
        "suburb": "Caltowie",
        "postcode": "5490",
        "state": "South Australia"
    },
    {
        "suburb": "Bundaleer North",
        "postcode": "5491",
        "state": "South Australia"
    },
    {
        "suburb": "Mayfield",
        "postcode": "5454",
        "state": "South Australia"
    },
    {
        "suburb": "Andrews",
        "postcode": "5454",
        "state": "South Australia"
    },
    {
        "suburb": "Euromina",
        "postcode": "5454",
        "state": "South Australia"
    },
    {
        "suburb": "Narridy",
        "postcode": "5523",
        "state": "South Australia"
    },
    {
        "suburb": "Georgetown",
        "postcode": "5472",
        "state": "South Australia"
    },
    {
        "suburb": "Gulnare",
        "postcode": "5471",
        "state": "South Australia"
    },
    {
        "suburb": "Caltowie West",
        "postcode": "5490",
        "state": "South Australia"
    },
    {
        "suburb": "Hacklins Corner",
        "postcode": "5454",
        "state": "South Australia"
    },
    {
        "suburb": "West Bundaleer",
        "postcode": "5491",
        "state": "South Australia"
    },
    {
        "suburb": "Tarcowie",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Hornsdale",
        "postcode": "5491",
        "state": "South Australia"
    },
    {
        "suburb": "Laura",
        "postcode": "5480",
        "state": "South Australia"
    },
    {
        "suburb": "Broughton River Valley",
        "postcode": "5454",
        "state": "South Australia"
    },
    {
        "suburb": "Caltowie North",
        "postcode": "5490",
        "state": "South Australia"
    },
    {
        "suburb": "Joslin",
        "postcode": "5070",
        "state": "South Australia"
    },
    {
        "suburb": "Marden",
        "postcode": "5070",
        "state": "South Australia"
    },
    {
        "suburb": "Glynde",
        "postcode": "5070",
        "state": "South Australia"
    },
    {
        "suburb": "Royston Park",
        "postcode": "5070",
        "state": "South Australia"
    },
    {
        "suburb": "Felixstow",
        "postcode": "5070",
        "state": "South Australia"
    },
    {
        "suburb": "Trinity Gardens",
        "postcode": "5068",
        "state": "South Australia"
    },
    {
        "suburb": "Payneham",
        "postcode": "5070",
        "state": "South Australia"
    },
    {
        "suburb": "Payneham South",
        "postcode": "5070",
        "state": "South Australia"
    },
    {
        "suburb": "Firle",
        "postcode": "5070",
        "state": "South Australia"
    },
    {
        "suburb": "St Morris",
        "postcode": "5068",
        "state": "South Australia"
    },
    {
        "suburb": "St Peters",
        "postcode": "5069",
        "state": "South Australia"
    },
    {
        "suburb": "Kent Town",
        "postcode": "5067",
        "state": "South Australia"
    },
    {
        "suburb": "Norwood",
        "postcode": "5067",
        "state": "South Australia"
    },
    {
        "suburb": "Kensington",
        "postcode": "5068",
        "state": "South Australia"
    },
    {
        "suburb": "Marryatville",
        "postcode": "5068",
        "state": "South Australia"
    },
    {
        "suburb": "Evandale",
        "postcode": "5069",
        "state": "South Australia"
    },
    {
        "suburb": "Maylands",
        "postcode": "5069",
        "state": "South Australia"
    },
    {
        "suburb": "Heathpool",
        "postcode": "5068",
        "state": "South Australia"
    },
    {
        "suburb": "College Park",
        "postcode": "5069",
        "state": "South Australia"
    },
    {
        "suburb": "Hackney",
        "postcode": "5069",
        "state": "South Australia"
    },
    {
        "suburb": "Stepney",
        "postcode": "5069",
        "state": "South Australia"
    },
    {
        "suburb": "Hackham West",
        "postcode": "5163",
        "state": "South Australia"
    },
    {
        "suburb": "Hackham",
        "postcode": "5163",
        "state": "South Australia"
    },
    {
        "suburb": "Huntfield Heights",
        "postcode": "5163",
        "state": "South Australia"
    },
    {
        "suburb": "Onkaparinga Hills",
        "postcode": "5163",
        "state": "South Australia"
    },
    {
        "suburb": "Willunga",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "McLaren Flat",
        "postcode": "5171",
        "state": "South Australia"
    },
    {
        "suburb": "Willunga South",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "McLaren Vale",
        "postcode": "5171",
        "state": "South Australia"
    },
    {
        "suburb": "Cherry Gardens",
        "postcode": "5157",
        "state": "South Australia"
    },
    {
        "suburb": "Clarendon",
        "postcode": "5157",
        "state": "South Australia"
    },
    {
        "suburb": "Blewitt Springs",
        "postcode": "5171",
        "state": "South Australia"
    },
    {
        "suburb": "Aldinga",
        "postcode": "5173",
        "state": "South Australia"
    },
    {
        "suburb": "Whites Valley",
        "postcode": "5172",
        "state": "South Australia"
    },
    {
        "suburb": "Old Noarlunga",
        "postcode": "5168",
        "state": "South Australia"
    },
    {
        "suburb": "Seaford Heights",
        "postcode": "5169",
        "state": "South Australia"
    },
    {
        "suburb": "Coromandel East",
        "postcode": "5157",
        "state": "South Australia"
    },
    {
        "suburb": "Chandlers Hill",
        "postcode": "5159",
        "state": "South Australia"
    },
    {
        "suburb": "Sellicks Hill",
        "postcode": "5174",
        "state": "South Australia"
    },
    {
        "suburb": "Tatachilla",
        "postcode": "5171",
        "state": "South Australia"
    },
    {
        "suburb": "Morphett Vale",
        "postcode": "5162",
        "state": "South Australia"
    },
    {
        "suburb": "Christies Beach",
        "postcode": "5165",
        "state": "South Australia"
    },
    {
        "suburb": "Christie Downs",
        "postcode": "5164",
        "state": "South Australia"
    },
    {
        "suburb": "O'Sullivan Beach",
        "postcode": "5166",
        "state": "South Australia"
    },
    {
        "suburb": "Noarlunga Downs",
        "postcode": "5168",
        "state": "South Australia"
    },
    {
        "suburb": "Port Noarlunga",
        "postcode": "5167",
        "state": "South Australia"
    },
    {
        "suburb": "Noarlunga Centre",
        "postcode": "5168",
        "state": "South Australia"
    },
    {
        "suburb": "Aberfoyle Park",
        "postcode": "5159",
        "state": "South Australia"
    },
    {
        "suburb": "Happy Valley",
        "postcode": "5159",
        "state": "South Australia"
    },
    {
        "suburb": "Flagstaff Hill",
        "postcode": "5159",
        "state": "South Australia"
    },
    {
        "suburb": "Sellicks Beach",
        "postcode": "5174",
        "state": "South Australia"
    },
    {
        "suburb": "Aldinga Beach",
        "postcode": "5173",
        "state": "South Australia"
    },
    {
        "suburb": "Seaford Rise",
        "postcode": "5169",
        "state": "South Australia"
    },
    {
        "suburb": "Port Willunga",
        "postcode": "5173",
        "state": "South Australia"
    },
    {
        "suburb": "Maslin Beach",
        "postcode": "5170",
        "state": "South Australia"
    },
    {
        "suburb": "Moana",
        "postcode": "5169",
        "state": "South Australia"
    },
    {
        "suburb": "Seaford",
        "postcode": "5169",
        "state": "South Australia"
    },
    {
        "suburb": "Seaford Meadows",
        "postcode": "5169",
        "state": "South Australia"
    },
    {
        "suburb": "Port Noarlunga South",
        "postcode": "5167",
        "state": "South Australia"
    },
    {
        "suburb": "Reynella",
        "postcode": "5161",
        "state": "South Australia"
    },
    {
        "suburb": "Woodcroft",
        "postcode": "5162",
        "state": "South Australia"
    },
    {
        "suburb": "Reynella East",
        "postcode": "5161",
        "state": "South Australia"
    },
    {
        "suburb": "Old Reynella",
        "postcode": "5161",
        "state": "South Australia"
    },
    {
        "suburb": "Orroroo",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Pekina",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Carrieton",
        "postcode": "5432",
        "state": "South Australia"
    },
    {
        "suburb": "Minburra",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Johnburgh",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Walloway",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Erskine",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Yalpara",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Yanyarrie",
        "postcode": "5432",
        "state": "South Australia"
    },
    {
        "suburb": "Yongala",
        "postcode": "5493",
        "state": "South Australia"
    },
    {
        "suburb": "Sunnybrae",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Parnaroo",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Nackara",
        "postcode": "5440",
        "state": "South Australia"
    },
    {
        "suburb": "Cavenagh",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Paratoo",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Minvalara",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Peterborough",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Oodla Wirra",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Dawson",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Hardy",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Craigmore",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Blakeview",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "One Tree Hill",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Hillbank",
        "postcode": "5112",
        "state": "South Australia"
    },
    {
        "suburb": "Elizabeth Downs",
        "postcode": "5113",
        "state": "South Australia"
    },
    {
        "suburb": "Gould Creek",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Elizabeth East",
        "postcode": "5112",
        "state": "South Australia"
    },
    {
        "suburb": "Elizabeth South",
        "postcode": "5112",
        "state": "South Australia"
    },
    {
        "suburb": "Elizabeth Vale",
        "postcode": "5112",
        "state": "South Australia"
    },
    {
        "suburb": "Elizabeth Grove",
        "postcode": "5112",
        "state": "South Australia"
    },
    {
        "suburb": "Elizabeth Park",
        "postcode": "5113",
        "state": "South Australia"
    },
    {
        "suburb": "Elizabeth North",
        "postcode": "5113",
        "state": "South Australia"
    },
    {
        "suburb": "Davoren Park",
        "postcode": "5113",
        "state": "South Australia"
    },
    {
        "suburb": "Elizabeth",
        "postcode": "5112",
        "state": "South Australia"
    },
    {
        "suburb": "Edinburgh North",
        "postcode": "5113",
        "state": "South Australia"
    },
    {
        "suburb": "Smithfield",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Yattalunga",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Sampson Flat",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Uleybury",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Bibaringa",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Waterloo Corner",
        "postcode": "5110",
        "state": "South Australia"
    },
    {
        "suburb": "Munno Para",
        "postcode": "5115",
        "state": "South Australia"
    },
    {
        "suburb": "Penfield Gardens",
        "postcode": "5121",
        "state": "South Australia"
    },
    {
        "suburb": "Virginia",
        "postcode": "5120",
        "state": "South Australia"
    },
    {
        "suburb": "Munno Para West",
        "postcode": "5115",
        "state": "South Australia"
    },
    {
        "suburb": "Munno Para Downs",
        "postcode": "5115",
        "state": "South Australia"
    },
    {
        "suburb": "Buckland Park",
        "postcode": "5120",
        "state": "South Australia"
    },
    {
        "suburb": "Angle Vale",
        "postcode": "5117",
        "state": "South Australia"
    },
    {
        "suburb": "Penfield",
        "postcode": "5121",
        "state": "South Australia"
    },
    {
        "suburb": "Andrews Farm",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Smithfield Plains",
        "postcode": "5114",
        "state": "South Australia"
    },
    {
        "suburb": "Valley View",
        "postcode": "5093",
        "state": "South Australia"
    },
    {
        "suburb": "Northfield",
        "postcode": "5085",
        "state": "South Australia"
    },
    {
        "suburb": "Manningham",
        "postcode": "5086",
        "state": "South Australia"
    },
    {
        "suburb": "Windsor Gardens",
        "postcode": "5087",
        "state": "South Australia"
    },
    {
        "suburb": "Hillcrest",
        "postcode": "5086",
        "state": "South Australia"
    },
    {
        "suburb": "Northgate",
        "postcode": "5085",
        "state": "South Australia"
    },
    {
        "suburb": "Oakden",
        "postcode": "5086",
        "state": "South Australia"
    },
    {
        "suburb": "Gilles Plains",
        "postcode": "5086",
        "state": "South Australia"
    },
    {
        "suburb": "Klemzig",
        "postcode": "5087",
        "state": "South Australia"
    },
    {
        "suburb": "Walkley Heights",
        "postcode": "5098",
        "state": "South Australia"
    },
    {
        "suburb": "Greenacres",
        "postcode": "5086",
        "state": "South Australia"
    },
    {
        "suburb": "Hampstead Gardens",
        "postcode": "5086",
        "state": "South Australia"
    },
    {
        "suburb": "Holden Hill",
        "postcode": "5088",
        "state": "South Australia"
    },
    {
        "suburb": "Broadview",
        "postcode": "5083",
        "state": "South Australia"
    },
    {
        "suburb": "Dernancourt",
        "postcode": "5075",
        "state": "South Australia"
    },
    {
        "suburb": "Kilburn",
        "postcode": "5084",
        "state": "South Australia"
    },
    {
        "suburb": "Dry Creek",
        "postcode": "5094",
        "state": "South Australia"
    },
    {
        "suburb": "Blair Athol",
        "postcode": "5084",
        "state": "South Australia"
    },
    {
        "suburb": "Clearview",
        "postcode": "5085",
        "state": "South Australia"
    },
    {
        "suburb": "Sefton Park",
        "postcode": "5083",
        "state": "South Australia"
    },
    {
        "suburb": "Enfield",
        "postcode": "5085",
        "state": "South Australia"
    },
    {
        "suburb": "Gepps Cross",
        "postcode": "5094",
        "state": "South Australia"
    },
    {
        "suburb": "North Haven",
        "postcode": "5018",
        "state": "South Australia"
    },
    {
        "suburb": "Ethelton",
        "postcode": "5015",
        "state": "South Australia"
    },
    {
        "suburb": "Largs North",
        "postcode": "5016",
        "state": "South Australia"
    },
    {
        "suburb": "Largs Bay",
        "postcode": "5016",
        "state": "South Australia"
    },
    {
        "suburb": "Taperoo",
        "postcode": "5017",
        "state": "South Australia"
    },
    {
        "suburb": "Birkenhead",
        "postcode": "5015",
        "state": "South Australia"
    },
    {
        "suburb": "Peterhead",
        "postcode": "5016",
        "state": "South Australia"
    },
    {
        "suburb": "Osborne",
        "postcode": "5017",
        "state": "South Australia"
    },
    {
        "suburb": "New Port",
        "postcode": "5015",
        "state": "South Australia"
    },
    {
        "suburb": "Semaphore",
        "postcode": "5019",
        "state": "South Australia"
    },
    {
        "suburb": "Semaphore South",
        "postcode": "5019",
        "state": "South Australia"
    },
    {
        "suburb": "Glanville",
        "postcode": "5015",
        "state": "South Australia"
    },
    {
        "suburb": "Exeter",
        "postcode": "5019",
        "state": "South Australia"
    },
    {
        "suburb": "Croydon Park",
        "postcode": "5008",
        "state": "South Australia"
    },
    {
        "suburb": "Mansfield Park",
        "postcode": "5012",
        "state": "South Australia"
    },
    {
        "suburb": "Wingfield",
        "postcode": "5013",
        "state": "South Australia"
    },
    {
        "suburb": "Woodville Gardens",
        "postcode": "5012",
        "state": "South Australia"
    },
    {
        "suburb": "Regency Park",
        "postcode": "5010",
        "state": "South Australia"
    },
    {
        "suburb": "Dudley Park",
        "postcode": "5008",
        "state": "South Australia"
    },
    {
        "suburb": "Ferryden Park",
        "postcode": "5010",
        "state": "South Australia"
    },
    {
        "suburb": "Gillman",
        "postcode": "5013",
        "state": "South Australia"
    },
    {
        "suburb": "Angle Park",
        "postcode": "5010",
        "state": "South Australia"
    },
    {
        "suburb": "Port Adelaide",
        "postcode": "5015",
        "state": "South Australia"
    },
    {
        "suburb": "Alberton",
        "postcode": "5014",
        "state": "South Australia"
    },
    {
        "suburb": "Ottoway",
        "postcode": "5013",
        "state": "South Australia"
    },
    {
        "suburb": "Rosewater",
        "postcode": "5013",
        "state": "South Australia"
    },
    {
        "suburb": "Queenstown",
        "postcode": "5014",
        "state": "South Australia"
    },
    {
        "suburb": "Port Augusta",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Stirling North",
        "postcode": "5710",
        "state": "South Australia"
    },
    {
        "suburb": "Miranda",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Wami Kata",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Commissariat Point",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Mundallio",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Port Augusta West",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Port Paterson",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Blanche Harbor",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Port Pirie",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Risdon Park",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Port Pirie South",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Port Pirie West",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Solomontown",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Risdon Park South",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Pirie East",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Wandearah East",
        "postcode": "5523",
        "state": "South Australia"
    },
    {
        "suburb": "Wandearah West",
        "postcode": "5523",
        "state": "South Australia"
    },
    {
        "suburb": "Crystal Brook",
        "postcode": "5523",
        "state": "South Australia"
    },
    {
        "suburb": "Redhill",
        "postcode": "5521",
        "state": "South Australia"
    },
    {
        "suburb": "Merriton",
        "postcode": "5523",
        "state": "South Australia"
    },
    {
        "suburb": "Lower Broughton",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Warnertown",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Koolunga",
        "postcode": "5464",
        "state": "South Australia"
    },
    {
        "suburb": "Coonamia",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Port Davis",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Nurom",
        "postcode": "5523",
        "state": "South Australia"
    },
    {
        "suburb": "Nelshaby",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Napperby",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Collinsfield",
        "postcode": "5555",
        "state": "South Australia"
    },
    {
        "suburb": "Bungama",
        "postcode": "5540",
        "state": "South Australia"
    },
    {
        "suburb": "Nailsworth",
        "postcode": "5083",
        "state": "South Australia"
    },
    {
        "suburb": "Prospect",
        "postcode": "5082",
        "state": "South Australia"
    },
    {
        "suburb": "Collinswood",
        "postcode": "5081",
        "state": "South Australia"
    },
    {
        "suburb": "Medindie Gardens",
        "postcode": "5081",
        "state": "South Australia"
    },
    {
        "suburb": "Thorngate",
        "postcode": "5082",
        "state": "South Australia"
    },
    {
        "suburb": "Fitzroy",
        "postcode": "5082",
        "state": "South Australia"
    },
    {
        "suburb": "Pike River",
        "postcode": "5340",
        "state": "South Australia"
    },
    {
        "suburb": "Gurra Gurra",
        "postcode": "5343",
        "state": "South Australia"
    },
    {
        "suburb": "Murtho",
        "postcode": "5340",
        "state": "South Australia"
    },
    {
        "suburb": "Lyrup",
        "postcode": "5343",
        "state": "South Australia"
    },
    {
        "suburb": "Paringa",
        "postcode": "5340",
        "state": "South Australia"
    },
    {
        "suburb": "Wonuarra",
        "postcode": "5340",
        "state": "South Australia"
    },
    {
        "suburb": "Mundic Creek",
        "postcode": "5340",
        "state": "South Australia"
    },
    {
        "suburb": "Yamba",
        "postcode": "5340",
        "state": "South Australia"
    },
    {
        "suburb": "Renmark",
        "postcode": "5341",
        "state": "South Australia"
    },
    {
        "suburb": "Cooltong",
        "postcode": "5341",
        "state": "South Australia"
    },
    {
        "suburb": "Renmark North",
        "postcode": "5341",
        "state": "South Australia"
    },
    {
        "suburb": "Renmark West",
        "postcode": "5341",
        "state": "South Australia"
    },
    {
        "suburb": "Renmark South",
        "postcode": "5341",
        "state": "South Australia"
    },
    {
        "suburb": "Robe",
        "postcode": "5276",
        "state": "South Australia"
    },
    {
        "suburb": "Boatswain Point",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Benson",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Bray",
        "postcode": "5276",
        "state": "South Australia"
    },
    {
        "suburb": "Greenways",
        "postcode": "5272",
        "state": "South Australia"
    },
    {
        "suburb": "Reedy Creek",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Nora Creina",
        "postcode": "5276",
        "state": "South Australia"
    },
    {
        "suburb": "Clay Wells",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Roxby Downs",
        "postcode": "5725",
        "state": "South Australia"
    },
    {
        "suburb": "Parafield Gardens",
        "postcode": "5107",
        "state": "South Australia"
    },
    {
        "suburb": "Salisbury",
        "postcode": "5108",
        "state": "South Australia"
    },
    {
        "suburb": "Salisbury Downs",
        "postcode": "5108",
        "state": "South Australia"
    },
    {
        "suburb": "Salisbury South",
        "postcode": "5106",
        "state": "South Australia"
    },
    {
        "suburb": "Parafield",
        "postcode": "5106",
        "state": "South Australia"
    },
    {
        "suburb": "Green Fields",
        "postcode": "5107",
        "state": "South Australia"
    },
    {
        "suburb": "Paralowie",
        "postcode": "5108",
        "state": "South Australia"
    },
    {
        "suburb": "Salisbury North",
        "postcode": "5108",
        "state": "South Australia"
    },
    {
        "suburb": "Salisbury East",
        "postcode": "5109",
        "state": "South Australia"
    },
    {
        "suburb": "Salisbury Park",
        "postcode": "5109",
        "state": "South Australia"
    },
    {
        "suburb": "Salisbury Plain",
        "postcode": "5109",
        "state": "South Australia"
    },
    {
        "suburb": "Gulfview Heights",
        "postcode": "5096",
        "state": "South Australia"
    },
    {
        "suburb": "Salisbury Heights",
        "postcode": "5109",
        "state": "South Australia"
    },
    {
        "suburb": "Brahma Lodge",
        "postcode": "5109",
        "state": "South Australia"
    },
    {
        "suburb": "Ingle Farm",
        "postcode": "5098",
        "state": "South Australia"
    },
    {
        "suburb": "Para Hills",
        "postcode": "5096",
        "state": "South Australia"
    },
    {
        "suburb": "Pooraka",
        "postcode": "5095",
        "state": "South Australia"
    },
    {
        "suburb": "Para Vista",
        "postcode": "5093",
        "state": "South Australia"
    },
    {
        "suburb": "Para Hills West",
        "postcode": "5096",
        "state": "South Australia"
    },
    {
        "suburb": "Mawson Lakes",
        "postcode": "5095",
        "state": "South Australia"
    },
    {
        "suburb": "Edinburgh",
        "postcode": "5111",
        "state": "South Australia"
    },
    {
        "suburb": "Burton",
        "postcode": "5110",
        "state": "South Australia"
    },
    {
        "suburb": "St Kilda",
        "postcode": "5110",
        "state": "South Australia"
    },
    {
        "suburb": "Cavan",
        "postcode": "5094",
        "state": "South Australia"
    },
    {
        "suburb": "Globe Derby Park",
        "postcode": "5110",
        "state": "South Australia"
    },
    {
        "suburb": "Bolivar",
        "postcode": "5110",
        "state": "South Australia"
    },
    {
        "suburb": "Lameroo",
        "postcode": "5302",
        "state": "South Australia"
    },
    {
        "suburb": "Parrakie",
        "postcode": "5301",
        "state": "South Australia"
    },
    {
        "suburb": "Parilla",
        "postcode": "5303",
        "state": "South Australia"
    },
    {
        "suburb": "Geranium",
        "postcode": "5301",
        "state": "South Australia"
    },
    {
        "suburb": "Jabuk",
        "postcode": "5301",
        "state": "South Australia"
    },
    {
        "suburb": "Ngarkat",
        "postcode": "5302",
        "state": "South Australia"
    },
    {
        "suburb": "Karte",
        "postcode": "5304",
        "state": "South Australia"
    },
    {
        "suburb": "Pinnaroo",
        "postcode": "5304",
        "state": "South Australia"
    },
    {
        "suburb": "Streaky Bay",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Eba Anchorage",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Sceale Bay",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Cungena",
        "postcode": "5660",
        "state": "South Australia"
    },
    {
        "suburb": "Petina",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Calca",
        "postcode": "5671",
        "state": "South Australia"
    },
    {
        "suburb": "Baird Bay",
        "postcode": "5671",
        "state": "South Australia"
    },
    {
        "suburb": "Koolgera",
        "postcode": "5661",
        "state": "South Australia"
    },
    {
        "suburb": "Kaldoonera",
        "postcode": "5655",
        "state": "South Australia"
    },
    {
        "suburb": "Chandada",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Inkster",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Cooper",
        "postcode": "5671",
        "state": "South Australia"
    },
    {
        "suburb": "Mortana",
        "postcode": "5671",
        "state": "South Australia"
    },
    {
        "suburb": "Tyringa",
        "postcode": "5671",
        "state": "South Australia"
    },
    {
        "suburb": "Perlubie",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Colley",
        "postcode": "5671",
        "state": "South Australia"
    },
    {
        "suburb": "Yanerbie",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Maryvale",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Piednippie",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Chilpenunda",
        "postcode": "5660",
        "state": "South Australia"
    },
    {
        "suburb": "Westall",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Haslam",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Wallala",
        "postcode": "5661",
        "state": "South Australia"
    },
    {
        "suburb": "Witera",
        "postcode": "5671",
        "state": "South Australia"
    },
    {
        "suburb": "Wirrulla",
        "postcode": "5661",
        "state": "South Australia"
    },
    {
        "suburb": "Bordertown",
        "postcode": "5268",
        "state": "South Australia"
    },
    {
        "suburb": "Mundulla",
        "postcode": "5270",
        "state": "South Australia"
    },
    {
        "suburb": "Keith",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Cannawigara",
        "postcode": "5268",
        "state": "South Australia"
    },
    {
        "suburb": "Wirrega",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Shaugh",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Laffer",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Petherick",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Sherwood",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Swede Flat",
        "postcode": "5270",
        "state": "South Australia"
    },
    {
        "suburb": "Padthaway",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Wolseley",
        "postcode": "5269",
        "state": "South Australia"
    },
    {
        "suburb": "Senior",
        "postcode": "5268",
        "state": "South Australia"
    },
    {
        "suburb": "Willalooka",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Pine Hill",
        "postcode": "5269",
        "state": "South Australia"
    },
    {
        "suburb": "Mundulla West",
        "postcode": "5270",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Charles",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Buckingham",
        "postcode": "5270",
        "state": "South Australia"
    },
    {
        "suburb": "Kongal",
        "postcode": "5270",
        "state": "South Australia"
    },
    {
        "suburb": "Carew",
        "postcode": "5270",
        "state": "South Australia"
    },
    {
        "suburb": "Brimbago",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Lowan Vale",
        "postcode": "5268",
        "state": "South Australia"
    },
    {
        "suburb": "Makin",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "McCallum",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Pooginagoric",
        "postcode": "5268",
        "state": "South Australia"
    },
    {
        "suburb": "Bangham",
        "postcode": "5268",
        "state": "South Australia"
    },
    {
        "suburb": "Custon",
        "postcode": "5269",
        "state": "South Australia"
    },
    {
        "suburb": "Western Flat",
        "postcode": "5268",
        "state": "South Australia"
    },
    {
        "suburb": "Bordertown South",
        "postcode": "5268",
        "state": "South Australia"
    },
    {
        "suburb": "Modbury North",
        "postcode": "5092",
        "state": "South Australia"
    },
    {
        "suburb": "Modbury Heights",
        "postcode": "5092",
        "state": "South Australia"
    },
    {
        "suburb": "Ridgehaven",
        "postcode": "5097",
        "state": "South Australia"
    },
    {
        "suburb": "Redwood Park",
        "postcode": "5097",
        "state": "South Australia"
    },
    {
        "suburb": "Surrey Downs",
        "postcode": "5126",
        "state": "South Australia"
    },
    {
        "suburb": "Banksia Park",
        "postcode": "5091",
        "state": "South Australia"
    },
    {
        "suburb": "Tea Tree Gully",
        "postcode": "5091",
        "state": "South Australia"
    },
    {
        "suburb": "Golden Grove",
        "postcode": "5125",
        "state": "South Australia"
    },
    {
        "suburb": "Yatala Vale",
        "postcode": "5126",
        "state": "South Australia"
    },
    {
        "suburb": "Fairview Park",
        "postcode": "5126",
        "state": "South Australia"
    },
    {
        "suburb": "Vista",
        "postcode": "5091",
        "state": "South Australia"
    },
    {
        "suburb": "Highbury",
        "postcode": "5089",
        "state": "South Australia"
    },
    {
        "suburb": "Wynn Vale",
        "postcode": "5127",
        "state": "South Australia"
    },
    {
        "suburb": "Greenwith",
        "postcode": "5125",
        "state": "South Australia"
    },
    {
        "suburb": "Modbury",
        "postcode": "5092",
        "state": "South Australia"
    },
    {
        "suburb": "St Agnes",
        "postcode": "5097",
        "state": "South Australia"
    },
    {
        "suburb": "Hope Valley",
        "postcode": "5090",
        "state": "South Australia"
    },
    {
        "suburb": "Deepwater",
        "postcode": "5266",
        "state": "South Australia"
    },
    {
        "suburb": "Tintinara",
        "postcode": "5266",
        "state": "South Australia"
    },
    {
        "suburb": "Coonalpyn",
        "postcode": "5265",
        "state": "South Australia"
    },
    {
        "suburb": "Peake",
        "postcode": "5301",
        "state": "South Australia"
    },
    {
        "suburb": "Tailem Bend",
        "postcode": "5260",
        "state": "South Australia"
    },
    {
        "suburb": "Sherlock",
        "postcode": "5301",
        "state": "South Australia"
    },
    {
        "suburb": "Meningie",
        "postcode": "5264",
        "state": "South Australia"
    },
    {
        "suburb": "Wellington East",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Albert",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Narrung",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Netherton",
        "postcode": "5301",
        "state": "South Australia"
    },
    {
        "suburb": "Elwomple",
        "postcode": "5260",
        "state": "South Australia"
    },
    {
        "suburb": "Field",
        "postcode": "5265",
        "state": "South Australia"
    },
    {
        "suburb": "Yumali",
        "postcode": "5261",
        "state": "South Australia"
    },
    {
        "suburb": "Colebatch",
        "postcode": "5266",
        "state": "South Australia"
    },
    {
        "suburb": "Waltowa",
        "postcode": "5264",
        "state": "South Australia"
    },
    {
        "suburb": "Cooke Plains",
        "postcode": "5261",
        "state": "South Australia"
    },
    {
        "suburb": "Culburra",
        "postcode": "5261",
        "state": "South Australia"
    },
    {
        "suburb": "Poltalloch",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Malinong",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Ashville",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Bunbury",
        "postcode": "5266",
        "state": "South Australia"
    },
    {
        "suburb": "Carcuma",
        "postcode": "5301",
        "state": "South Australia"
    },
    {
        "suburb": "Coombe",
        "postcode": "5267",
        "state": "South Australia"
    },
    {
        "suburb": "Coomandook",
        "postcode": "5261",
        "state": "South Australia"
    },
    {
        "suburb": "Meningie East",
        "postcode": "5264",
        "state": "South Australia"
    },
    {
        "suburb": "Ki Ki",
        "postcode": "5261",
        "state": "South Australia"
    },
    {
        "suburb": "Moorlands",
        "postcode": "5301",
        "state": "South Australia"
    },
    {
        "suburb": "Meningie West",
        "postcode": "5259",
        "state": "South Australia"
    },
    {
        "suburb": "Salt Creek",
        "postcode": "5264",
        "state": "South Australia"
    },
    {
        "suburb": "Brooker",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Koppio",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Port Neill",
        "postcode": "5604",
        "state": "South Australia"
    },
    {
        "suburb": "Cockaleechie",
        "postcode": "5631",
        "state": "South Australia"
    },
    {
        "suburb": "Ungarra",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Moody",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Butler",
        "postcode": "5605",
        "state": "South Australia"
    },
    {
        "suburb": "Yallunda Flat",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Lipson",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Parkside",
        "postcode": "5063",
        "state": "South Australia"
    },
    {
        "suburb": "Fullarton",
        "postcode": "5063",
        "state": "South Australia"
    },
    {
        "suburb": "Myrtle Bank",
        "postcode": "5064",
        "state": "South Australia"
    },
    {
        "suburb": "Highgate",
        "postcode": "5063",
        "state": "South Australia"
    },
    {
        "suburb": "Malvern",
        "postcode": "5061",
        "state": "South Australia"
    },
    {
        "suburb": "Unley",
        "postcode": "5061",
        "state": "South Australia"
    },
    {
        "suburb": "Clarence Park",
        "postcode": "5034",
        "state": "South Australia"
    },
    {
        "suburb": "Unley Park",
        "postcode": "5061",
        "state": "South Australia"
    },
    {
        "suburb": "Hyde Park",
        "postcode": "5061",
        "state": "South Australia"
    },
    {
        "suburb": "Everard Park",
        "postcode": "5035",
        "state": "South Australia"
    },
    {
        "suburb": "Black Forest",
        "postcode": "5035",
        "state": "South Australia"
    },
    {
        "suburb": "Millswood",
        "postcode": "5034",
        "state": "South Australia"
    },
    {
        "suburb": "Goodwood",
        "postcode": "5034",
        "state": "South Australia"
    },
    {
        "suburb": "Forestville",
        "postcode": "5035",
        "state": "South Australia"
    },
    {
        "suburb": "Wayville",
        "postcode": "5034",
        "state": "South Australia"
    },
    {
        "suburb": "Kings Park",
        "postcode": "5034",
        "state": "South Australia"
    },
    {
        "suburb": "Keswick",
        "postcode": "5035",
        "state": "South Australia"
    },
    {
        "suburb": "Encounter Bay",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Waitpinga",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Victor Harbor",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "McCracken",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Hindmarsh Valley",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Lower Inman Valley",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Hindmarsh Tiers",
        "postcode": "5202",
        "state": "South Australia"
    },
    {
        "suburb": "Inman Valley",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Back Valley",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Condowie",
        "postcode": "5464",
        "state": "South Australia"
    },
    {
        "suburb": "Owen",
        "postcode": "5460",
        "state": "South Australia"
    },
    {
        "suburb": "Lake View",
        "postcode": "5555",
        "state": "South Australia"
    },
    {
        "suburb": "Hope Gap",
        "postcode": "5555",
        "state": "South Australia"
    },
    {
        "suburb": "Nantawarra",
        "postcode": "5550",
        "state": "South Australia"
    },
    {
        "suburb": "Inkerman",
        "postcode": "5550",
        "state": "South Australia"
    },
    {
        "suburb": "Balaklava",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Burnsfield",
        "postcode": "5520",
        "state": "South Australia"
    },
    {
        "suburb": "Bumbunga",
        "postcode": "5520",
        "state": "South Australia"
    },
    {
        "suburb": "Snowtown",
        "postcode": "5520",
        "state": "South Australia"
    },
    {
        "suburb": "Hart",
        "postcode": "5464",
        "state": "South Australia"
    },
    {
        "suburb": "Kallora",
        "postcode": "5550",
        "state": "South Australia"
    },
    {
        "suburb": "Stockyard Creek",
        "postcode": "5460",
        "state": "South Australia"
    },
    {
        "suburb": "Kybunga",
        "postcode": "5453",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Templeton",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Everard Central",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Brinkworth",
        "postcode": "5464",
        "state": "South Australia"
    },
    {
        "suburb": "Saints",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Beaufort",
        "postcode": "5550",
        "state": "South Australia"
    },
    {
        "suburb": "Lochiel",
        "postcode": "5510",
        "state": "South Australia"
    },
    {
        "suburb": "Marola",
        "postcode": "5464",
        "state": "South Australia"
    },
    {
        "suburb": "Port Wakefield",
        "postcode": "5550",
        "state": "South Australia"
    },
    {
        "suburb": "Proof Range",
        "postcode": "5550",
        "state": "South Australia"
    },
    {
        "suburb": "Erith",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Blyth",
        "postcode": "5462",
        "state": "South Australia"
    },
    {
        "suburb": "Bowmans",
        "postcode": "5550",
        "state": "South Australia"
    },
    {
        "suburb": "Barunga Gap",
        "postcode": "5520",
        "state": "South Australia"
    },
    {
        "suburb": "Pinery",
        "postcode": "5460",
        "state": "South Australia"
    },
    {
        "suburb": "Watchman",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Whitwarta",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Avon",
        "postcode": "5501",
        "state": "South Australia"
    },
    {
        "suburb": "Rochester",
        "postcode": "5464",
        "state": "South Australia"
    },
    {
        "suburb": "Stow",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Bowillia",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Hoskin Corner",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Dalkey",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Goyder",
        "postcode": "5461",
        "state": "South Australia"
    },
    {
        "suburb": "Vale Park",
        "postcode": "5081",
        "state": "South Australia"
    },
    {
        "suburb": "Gilberton",
        "postcode": "5081",
        "state": "South Australia"
    },
    {
        "suburb": "Walkerville",
        "postcode": "5081",
        "state": "South Australia"
    },
    {
        "suburb": "Medindie",
        "postcode": "5081",
        "state": "South Australia"
    },
    {
        "suburb": "Penola",
        "postcode": "5277",
        "state": "South Australia"
    },
    {
        "suburb": "Nangwarry",
        "postcode": "5277",
        "state": "South Australia"
    },
    {
        "suburb": "Kalangadoo",
        "postcode": "5278",
        "state": "South Australia"
    },
    {
        "suburb": "Moerlong",
        "postcode": "5278",
        "state": "South Australia"
    },
    {
        "suburb": "Krongart",
        "postcode": "5278",
        "state": "South Australia"
    },
    {
        "suburb": "Coonawarra",
        "postcode": "5263",
        "state": "South Australia"
    },
    {
        "suburb": "Glenroy",
        "postcode": "5277",
        "state": "South Australia"
    },
    {
        "suburb": "Monbulla",
        "postcode": "5277",
        "state": "South Australia"
    },
    {
        "suburb": "Wattle Range East",
        "postcode": "5279",
        "state": "South Australia"
    },
    {
        "suburb": "Maaoupe",
        "postcode": "5277",
        "state": "South Australia"
    },
    {
        "suburb": "Comaum",
        "postcode": "5277",
        "state": "South Australia"
    },
    {
        "suburb": "German Flat",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Millicent",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Sebastopol",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Furner",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Glencoe",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Kangaroo Inn",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Wattle Range",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Beachport",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Southend",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Koorine",
        "postcode": "5279",
        "state": "South Australia"
    },
    {
        "suburb": "Short",
        "postcode": "5279",
        "state": "South Australia"
    },
    {
        "suburb": "Rocky Camp",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Burr",
        "postcode": "5279",
        "state": "South Australia"
    },
    {
        "suburb": "Magarey",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Hatherleigh",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Mcintyre",
        "postcode": "5279",
        "state": "South Australia"
    },
    {
        "suburb": "Thornlea",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Ashford",
        "postcode": "5035",
        "state": "South Australia"
    },
    {
        "suburb": "Thebarton",
        "postcode": "5031",
        "state": "South Australia"
    },
    {
        "suburb": "Marleston",
        "postcode": "5033",
        "state": "South Australia"
    },
    {
        "suburb": "Richmond",
        "postcode": "5033",
        "state": "South Australia"
    },
    {
        "suburb": "Hilton",
        "postcode": "5033",
        "state": "South Australia"
    },
    {
        "suburb": "Mile End",
        "postcode": "5031",
        "state": "South Australia"
    },
    {
        "suburb": "Cowandilla",
        "postcode": "5033",
        "state": "South Australia"
    },
    {
        "suburb": "Mile End South",
        "postcode": "5031",
        "state": "South Australia"
    },
    {
        "suburb": "Torrensville",
        "postcode": "5031",
        "state": "South Australia"
    },
    {
        "suburb": "Kurralta Park",
        "postcode": "5037",
        "state": "South Australia"
    },
    {
        "suburb": "Plympton",
        "postcode": "5038",
        "state": "South Australia"
    },
    {
        "suburb": "North Plympton",
        "postcode": "5037",
        "state": "South Australia"
    },
    {
        "suburb": "Keswick Terminal",
        "postcode": "5035",
        "state": "South Australia"
    },
    {
        "suburb": "Fulham",
        "postcode": "5024",
        "state": "South Australia"
    },
    {
        "suburb": "Novar Gardens",
        "postcode": "5040",
        "state": "South Australia"
    },
    {
        "suburb": "Lockleys",
        "postcode": "5032",
        "state": "South Australia"
    },
    {
        "suburb": "Underdale",
        "postcode": "5032",
        "state": "South Australia"
    },
    {
        "suburb": "Brooklyn Park",
        "postcode": "5032",
        "state": "South Australia"
    },
    {
        "suburb": "Camden Park",
        "postcode": "5038",
        "state": "South Australia"
    },
    {
        "suburb": "Netley",
        "postcode": "5037",
        "state": "South Australia"
    },
    {
        "suburb": "West Richmond",
        "postcode": "5033",
        "state": "South Australia"
    },
    {
        "suburb": "Adelaide Airport",
        "postcode": "5950",
        "state": "South Australia"
    },
    {
        "suburb": "Whyalla Norrie",
        "postcode": "5608",
        "state": "South Australia"
    },
    {
        "suburb": "Whyalla Jenkins",
        "postcode": "5609",
        "state": "South Australia"
    },
    {
        "suburb": "Flinders Ranges",
        "postcode": "5434",
        "state": "South Australia"
    },
    {
        "suburb": "Blinman",
        "postcode": "5730",
        "state": "South Australia"
    },
    {
        "suburb": "Flaxley",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Eba",
        "postcode": "5723",
        "state": "South Australia"
    },
    {
        "suburb": "Pelican Point",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "North West Bend",
        "postcode": "5320",
        "state": "South Australia"
    },
    {
        "suburb": "Whyalla",
        "postcode": "5600",
        "state": "South Australia"
    },
    {
        "suburb": "Whyalla Playford",
        "postcode": "5600",
        "state": "South Australia"
    },
    {
        "suburb": "Poochera",
        "postcode": "5655",
        "state": "South Australia"
    },
    {
        "suburb": "Ramco",
        "postcode": "5322",
        "state": "South Australia"
    },
    {
        "suburb": "Whyalla Stuart",
        "postcode": "5608",
        "state": "South Australia"
    },
    {
        "suburb": "Port Victoria",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Oakden Hills",
        "postcode": "5713",
        "state": "South Australia"
    },
    {
        "suburb": "Davenport",
        "postcode": "5700",
        "state": "South Australia"
    },
    {
        "suburb": "Warnes",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Kokatha",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Stansbury",
        "postcode": "5582",
        "state": "South Australia"
    },
    {
        "suburb": "Innamincka",
        "postcode": "5731",
        "state": "South Australia"
    },
    {
        "suburb": "Reid",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Middleback Range",
        "postcode": "5600",
        "state": "South Australia"
    },
    {
        "suburb": "Wallerberdina",
        "postcode": "5713",
        "state": "South Australia"
    },
    {
        "suburb": "Edithburgh",
        "postcode": "5583",
        "state": "South Australia"
    },
    {
        "suburb": "Corunna Station",
        "postcode": "5715",
        "state": "South Australia"
    },
    {
        "suburb": "Wirraminna",
        "postcode": "5719",
        "state": "South Australia"
    },
    {
        "suburb": "Wilgena",
        "postcode": "5719",
        "state": "South Australia"
    },
    {
        "suburb": "Kolendo",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Jupiter Creek",
        "postcode": "5153",
        "state": "South Australia"
    },
    {
        "suburb": "Parachilna",
        "postcode": "5730",
        "state": "South Australia"
    },
    {
        "suburb": "Mingary",
        "postcode": "5440",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Gairdner",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Point Turton",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Leigh Creek",
        "postcode": "5731",
        "state": "South Australia"
    },
    {
        "suburb": "Normanville",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Minlaton",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Lincoln Gap",
        "postcode": "5715",
        "state": "South Australia"
    },
    {
        "suburb": "Ardrossan",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Uno",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Buckleboo",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Gilles",
        "postcode": "5600",
        "state": "South Australia"
    },
    {
        "suburb": "Port Bonython",
        "postcode": "5601",
        "state": "South Australia"
    },
    {
        "suburb": "Mitchellville",
        "postcode": "5602",
        "state": "South Australia"
    },
    {
        "suburb": "Outer Harbor",
        "postcode": "5017",
        "state": "South Australia"
    },
    {
        "suburb": "Port Vincent",
        "postcode": "5581",
        "state": "South Australia"
    },
    {
        "suburb": "Bluff Beach",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Yorketown",
        "postcode": "5576",
        "state": "South Australia"
    },
    {
        "suburb": "Coobowie",
        "postcode": "5583",
        "state": "South Australia"
    },
    {
        "suburb": "Carrickalinga",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Moseley",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "James Well",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Fowlers Bay",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Macdonald Park",
        "postcode": "5121",
        "state": "South Australia"
    },
    {
        "suburb": "Myponga",
        "postcode": "5202",
        "state": "South Australia"
    },
    {
        "suburb": "Wedge Island",
        "postcode": "5606",
        "state": "South Australia"
    },
    {
        "suburb": "Hardwicke Bay",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Olympic Dam",
        "postcode": "5725",
        "state": "South Australia"
    },
    {
        "suburb": "Black Point",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Willowie",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Beltana",
        "postcode": "5730",
        "state": "South Australia"
    },
    {
        "suburb": "Nullarbor",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Ingomar",
        "postcode": "5723",
        "state": "South Australia"
    },
    {
        "suburb": "The Pines",
        "postcode": "5577",
        "state": "South Australia"
    },
    {
        "suburb": "Mahanewo",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Arcoona",
        "postcode": "5720",
        "state": "South Australia"
    },
    {
        "suburb": "Midgee",
        "postcode": "5602",
        "state": "South Australia"
    },
    {
        "suburb": "Woomera",
        "postcode": "5720",
        "state": "South Australia"
    },
    {
        "suburb": "Maitland",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Manna Hill",
        "postcode": "5440",
        "state": "South Australia"
    },
    {
        "suburb": "Buchfelde",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Murninnie Beach",
        "postcode": "5601",
        "state": "South Australia"
    },
    {
        "suburb": "Mullaquana",
        "postcode": "5601",
        "state": "South Australia"
    },
    {
        "suburb": "Rendelsham",
        "postcode": "5280",
        "state": "South Australia"
    },
    {
        "suburb": "Rogues Point",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Kingoonya",
        "postcode": "5719",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Everard",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Lyndhurst",
        "postcode": "5733",
        "state": "South Australia"
    },
    {
        "suburb": "Corny Point",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Pine Point",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Hope",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Wirrina Cove",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Direk",
        "postcode": "5110",
        "state": "South Australia"
    },
    {
        "suburb": "Wattle Flat",
        "postcode": "5203",
        "state": "South Australia"
    },
    {
        "suburb": "Yankalilla",
        "postcode": "5203",
        "state": "South Australia"
    },
    {
        "suburb": "Concordia",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Holder",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Belton",
        "postcode": "5432",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Clarence Station",
        "postcode": "5723",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Bryan East",
        "postcode": "5419",
        "state": "South Australia"
    },
    {
        "suburb": "Marion Bay",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Wandilo",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Collinsville",
        "postcode": "5418",
        "state": "South Australia"
    },
    {
        "suburb": "Willippa",
        "postcode": "5432",
        "state": "South Australia"
    },
    {
        "suburb": "Penong",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Whyalla Barson",
        "postcode": "5601",
        "state": "South Australia"
    },
    {
        "suburb": "William Creek",
        "postcode": "5723",
        "state": "South Australia"
    },
    {
        "suburb": "Clifton Hills Station",
        "postcode": "5733",
        "state": "South Australia"
    },
    {
        "suburb": "Mungeranie",
        "postcode": "5733",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Serle",
        "postcode": "5731",
        "state": "South Australia"
    },
    {
        "suburb": "Warraweena",
        "postcode": "5730",
        "state": "South Australia"
    },
    {
        "suburb": "Quinyambie",
        "postcode": "5440",
        "state": "South Australia"
    },
    {
        "suburb": "Arkaroola Village",
        "postcode": "5732",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Frome",
        "postcode": "5440",
        "state": "South Australia"
    },
    {
        "suburb": "Yumbarra",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Florina Station",
        "postcode": "5440",
        "state": "South Australia"
    },
    {
        "suburb": "Bollards Lagoon",
        "postcode": "5731",
        "state": "South Australia"
    },
    {
        "suburb": "Gawler Ranges",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Chowilla",
        "postcode": "5341",
        "state": "South Australia"
    },
    {
        "suburb": "Stuarts Creek",
        "postcode": "5720",
        "state": "South Australia"
    },
    {
        "suburb": "Andamooka Station",
        "postcode": "5719",
        "state": "South Australia"
    },
    {
        "suburb": "Wangary",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Woodlane",
        "postcode": "5254",
        "state": "South Australia"
    },
    {
        "suburb": "Curramulka",
        "postcode": "5580",
        "state": "South Australia"
    },
    {
        "suburb": "Clinton",
        "postcode": "5570",
        "state": "South Australia"
    },
    {
        "suburb": "Price",
        "postcode": "5570",
        "state": "South Australia"
    },
    {
        "suburb": "Ramsay",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Pearlah",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Chaffey",
        "postcode": "5341",
        "state": "South Australia"
    },
    {
        "suburb": "Port Rickaby",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Sheaoak Flat",
        "postcode": "5581",
        "state": "South Australia"
    },
    {
        "suburb": "Gammon Ranges",
        "postcode": "5732",
        "state": "South Australia"
    },
    {
        "suburb": "Laura Bay",
        "postcode": "5680",
        "state": "South Australia"
    },
    {
        "suburb": "Yalata",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Yellabinna",
        "postcode": "5690",
        "state": "South Australia"
    },
    {
        "suburb": "Poonindie",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Forster",
        "postcode": "5238",
        "state": "South Australia"
    },
    {
        "suburb": "Tarcoola",
        "postcode": "5701",
        "state": "South Australia"
    },
    {
        "suburb": "Yunta",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Yardea",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Farina",
        "postcode": "5733",
        "state": "South Australia"
    },
    {
        "suburb": "Yantanabie",
        "postcode": "5661",
        "state": "South Australia"
    },
    {
        "suburb": "Hammond",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Moyhall",
        "postcode": "5271",
        "state": "South Australia"
    },
    {
        "suburb": "Murputja",
        "postcode": "872",
        "state": "South Australia"
    },
    {
        "suburb": "Pinkawillinie",
        "postcode": "5641",
        "state": "South Australia"
    },
    {
        "suburb": "Leasingham",
        "postcode": "5452",
        "state": "South Australia"
    },
    {
        "suburb": "Nalyappa",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Oodnadatta",
        "postcode": "5724",
        "state": "South Australia"
    },
    {
        "suburb": "Pooginook",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Sedan",
        "postcode": "5353",
        "state": "South Australia"
    },
    {
        "suburb": "Gawler River",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Kainton",
        "postcode": "5552",
        "state": "South Australia"
    },
    {
        "suburb": "Taratap",
        "postcode": "5275",
        "state": "South Australia"
    },
    {
        "suburb": "Mundoo Island",
        "postcode": "5214",
        "state": "South Australia"
    },
    {
        "suburb": "Black Rock",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Danggali",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Bockelberg",
        "postcode": "5655",
        "state": "South Australia"
    },
    {
        "suburb": "Faraway Hill",
        "postcode": "5417",
        "state": "South Australia"
    },
    {
        "suburb": "Kanyaka",
        "postcode": "5434",
        "state": "South Australia"
    },
    {
        "suburb": "Eurelia",
        "postcode": "5431",
        "state": "South Australia"
    },
    {
        "suburb": "Kalamurina",
        "postcode": "5734",
        "state": "South Australia"
    },
    {
        "suburb": "Cultana",
        "postcode": "5600",
        "state": "South Australia"
    },
    {
        "suburb": "Melton Station",
        "postcode": "5440",
        "state": "South Australia"
    },
    {
        "suburb": "Balgowan",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Crescent",
        "postcode": "5341",
        "state": "South Australia"
    },
    {
        "suburb": "Kanpi",
        "postcode": "872",
        "state": "South Australia"
    },
    {
        "suburb": "Bon Bon",
        "postcode": "5719",
        "state": "South Australia"
    },
    {
        "suburb": "Gidgealpa",
        "postcode": "5731",
        "state": "South Australia"
    },
    {
        "suburb": "Point Lowly",
        "postcode": "5601",
        "state": "South Australia"
    },
    {
        "suburb": "Parawa",
        "postcode": "5203",
        "state": "South Australia"
    },
    {
        "suburb": "Delamere",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Second Valley",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Bald Hills",
        "postcode": "5203",
        "state": "South Australia"
    },
    {
        "suburb": "Torrens Vale",
        "postcode": "5203",
        "state": "South Australia"
    },
    {
        "suburb": "Rapid Bay",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Cape Jervis",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Silverton",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Deep Creek",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Hay Flat",
        "postcode": "5204",
        "state": "South Australia"
    },
    {
        "suburb": "Tunkalilla",
        "postcode": "5203",
        "state": "South Australia"
    },
    {
        "suburb": "Myponga Beach",
        "postcode": "5202",
        "state": "South Australia"
    },
    {
        "suburb": "Willow Creek",
        "postcode": "5211",
        "state": "South Australia"
    },
    {
        "suburb": "Arthurton",
        "postcode": "5572",
        "state": "South Australia"
    },
    {
        "suburb": "Tiddy Widdy Beach",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Point Pearce",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Port Julia",
        "postcode": "5580",
        "state": "South Australia"
    },
    {
        "suburb": "South Kilkerran",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Petersville",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Wauraltee",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Weetulta",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Urania",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Winulta",
        "postcode": "5570",
        "state": "South Australia"
    },
    {
        "suburb": "Cunningham",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Koolywurtie",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Sandilands",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Clinton Centre",
        "postcode": "5570",
        "state": "South Australia"
    },
    {
        "suburb": "Yorke Valley",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Sunnyvale",
        "postcode": "5552",
        "state": "South Australia"
    },
    {
        "suburb": "Port Arthur",
        "postcode": "5552",
        "state": "South Australia"
    },
    {
        "suburb": "Chinaman Wells",
        "postcode": "5573",
        "state": "South Australia"
    },
    {
        "suburb": "Brentwood",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Dowlingville",
        "postcode": "5571",
        "state": "South Australia"
    },
    {
        "suburb": "Agery",
        "postcode": "5558",
        "state": "South Australia"
    },
    {
        "suburb": "Warooka",
        "postcode": "5577",
        "state": "South Australia"
    },
    {
        "suburb": "Inneston",
        "postcode": "5577",
        "state": "South Australia"
    },
    {
        "suburb": "White Hut",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Wool Bay",
        "postcode": "5575",
        "state": "South Australia"
    },
    {
        "suburb": "Foul Bay",
        "postcode": "5577",
        "state": "South Australia"
    },
    {
        "suburb": "Port Moorowie",
        "postcode": "5576",
        "state": "South Australia"
    },
    {
        "suburb": "Point Souttar",
        "postcode": "5577",
        "state": "South Australia"
    },
    {
        "suburb": "Honiton",
        "postcode": "5576",
        "state": "South Australia"
    },
    {
        "suburb": "Sultana Point",
        "postcode": "5583",
        "state": "South Australia"
    },
    {
        "suburb": "Torrens Island",
        "postcode": "5960",
        "state": "South Australia"
    },
    {
        "suburb": "Garden Island",
        "postcode": "5960",
        "state": "South Australia"
    },
    {
        "suburb": "Spectacle Lake",
        "postcode": "5345",
        "state": "South Australia"
    },
    {
        "suburb": "Gerard",
        "postcode": "5343",
        "state": "South Australia"
    },
    {
        "suburb": "Devlins Pound",
        "postcode": "5330",
        "state": "South Australia"
    },
    {
        "suburb": "Iron Knob",
        "postcode": "5601",
        "state": "South Australia"
    },
    {
        "suburb": "Cockburn",
        "postcode": "5440",
        "state": "South Australia"
    },
    {
        "suburb": "Olary",
        "postcode": "5440",
        "state": "South Australia"
    },
    {
        "suburb": "Nepabunna",
        "postcode": "5732",
        "state": "South Australia"
    },
    {
        "suburb": "Copley",
        "postcode": "5732",
        "state": "South Australia"
    },
    {
        "suburb": "Marree",
        "postcode": "5733",
        "state": "South Australia"
    },
    {
        "suburb": "Moonaree",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Andamooka",
        "postcode": "5722",
        "state": "South Australia"
    },
    {
        "suburb": "Island Lagoon",
        "postcode": "5713",
        "state": "South Australia"
    },
    {
        "suburb": "Glendambo",
        "postcode": "5719",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Macfarlane",
        "postcode": "5717",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Eyre",
        "postcode": "5720",
        "state": "South Australia"
    },
    {
        "suburb": "Pimba",
        "postcode": "5720",
        "state": "South Australia"
    },
    {
        "suburb": "Lake Torrens",
        "postcode": "5713",
        "state": "South Australia"
    },
    {
        "suburb": "Mount Willoughby",
        "postcode": "5723",
        "state": "South Australia"
    },
    {
        "suburb": "Marla",
        "postcode": "5724",
        "state": "South Australia"
    },
    {
        "suburb": "Old Calperum",
        "postcode": "5341",
        "state": "South Australia"
    },
    {
        "suburb": "German Creek",
        "postcode": "5291",
        "state": "South Australia"
    },
    {
        "suburb": "Tootenilla",
        "postcode": "5607",
        "state": "South Australia"
    },
    {
        "suburb": "Kangaroo Flat",
        "postcode": "5118",
        "state": "South Australia"
    },
    {
        "suburb": "Dudley West",
        "postcode": "5222",
        "state": "South Australia"
    },
    {
        "suburb": "Ucolta",
        "postcode": "5422",
        "state": "South Australia"
    },
    {
        "suburb": "Lockyer",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Orana",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Melville",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Albany",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Yakamia",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Spencer Park",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Port Albany",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Mira Mar",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Clarence",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Seppings",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Emu Point",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Kalgan",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Collingwood Park",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Centennial Park",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Middleton Beach",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Elphinstone",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Torbay",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Kronkup",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Elleker",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Sandpatch",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "McKail",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Little Grove",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Torndirrup",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Walmsley",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Bayonet Head",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Lower King",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Vancouver Peninsula",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Napier",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Nanarup",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Cheynes",
        "postcode": "6328",
        "state": "Western Australia"
    },
    {
        "suburb": "Green Range",
        "postcode": "6328",
        "state": "Western Australia"
    },
    {
        "suburb": "Mettler",
        "postcode": "6328",
        "state": "Western Australia"
    },
    {
        "suburb": "Wellstead",
        "postcode": "6328",
        "state": "Western Australia"
    },
    {
        "suburb": "Youngs Siding",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Boxwood Hill",
        "postcode": "6338",
        "state": "Western Australia"
    },
    {
        "suburb": "Bornholm",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Green Valley",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Gledhow",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Collingwood Heights",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Manypeaks",
        "postcode": "6328",
        "state": "Western Australia"
    },
    {
        "suburb": "South Stirling",
        "postcode": "6324",
        "state": "Western Australia"
    },
    {
        "suburb": "Gnowellen",
        "postcode": "6328",
        "state": "Western Australia"
    },
    {
        "suburb": "Lowlands",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Redmond West",
        "postcode": "6327",
        "state": "Western Australia"
    },
    {
        "suburb": "Marbelup",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Milpara",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Lange",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Drome",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Redmond",
        "postcode": "6327",
        "state": "Western Australia"
    },
    {
        "suburb": "Goode Beach",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Kojaneerup South",
        "postcode": "6328",
        "state": "Western Australia"
    },
    {
        "suburb": "Nullaki",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Millbrook",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Robinson",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Cuthbert",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Willyung",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Warrenup",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Big Grove",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Palmdale",
        "postcode": "6328",
        "state": "Western Australia"
    },
    {
        "suburb": "West Cape Howe",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "King River",
        "postcode": "6330",
        "state": "Western Australia"
    },
    {
        "suburb": "Seville Grove",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Armadale",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Brookdale",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Camillo",
        "postcode": "6111",
        "state": "Western Australia"
    },
    {
        "suburb": "Kelmscott",
        "postcode": "6111",
        "state": "Western Australia"
    },
    {
        "suburb": "Roleystone",
        "postcode": "6111",
        "state": "Western Australia"
    },
    {
        "suburb": "Ashendon",
        "postcode": "6111",
        "state": "Western Australia"
    },
    {
        "suburb": "Piara Waters",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Forrestdale",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Richon",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Nasura",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Bedfordale",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Hilbert",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Champion Lakes",
        "postcode": "6111",
        "state": "Western Australia"
    },
    {
        "suburb": "Karragullen",
        "postcode": "6111",
        "state": "Western Australia"
    },
    {
        "suburb": "Wungong",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Haynes",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Harrisdale",
        "postcode": "6112",
        "state": "Western Australia"
    },
    {
        "suburb": "Lesley",
        "postcode": "6111",
        "state": "Western Australia"
    },
    {
        "suburb": "Talandji",
        "postcode": "6710",
        "state": "Western Australia"
    },
    {
        "suburb": "Onslow",
        "postcode": "6711",
        "state": "Western Australia"
    },
    {
        "suburb": "Peedamulla",
        "postcode": "6711",
        "state": "Western Australia"
    },
    {
        "suburb": "Mardie",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Paraburdoo",
        "postcode": "6754",
        "state": "Western Australia"
    },
    {
        "suburb": "Tom Price",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Yannarie",
        "postcode": "6710",
        "state": "Western Australia"
    },
    {
        "suburb": "Barrow Island",
        "postcode": "6712",
        "state": "Western Australia"
    },
    {
        "suburb": "Chichester",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Sheila",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Hamersley Range",
        "postcode": "6716",
        "state": "Western Australia"
    },
    {
        "suburb": "Rocklea",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Cane",
        "postcode": "6710",
        "state": "Western Australia"
    },
    {
        "suburb": "Nanutarra",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Innawanga",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Fortescue",
        "postcode": "6716",
        "state": "Western Australia"
    },
    {
        "suburb": "Millstream",
        "postcode": "6716",
        "state": "Western Australia"
    },
    {
        "suburb": "Boranup",
        "postcode": "6286",
        "state": "Western Australia"
    },
    {
        "suburb": "Gnarabup",
        "postcode": "6285",
        "state": "Western Australia"
    },
    {
        "suburb": "Redgate",
        "postcode": "6286",
        "state": "Western Australia"
    },
    {
        "suburb": "Hamelin Bay",
        "postcode": "6288",
        "state": "Western Australia"
    },
    {
        "suburb": "Deepdene",
        "postcode": "6290",
        "state": "Western Australia"
    },
    {
        "suburb": "Margaret River",
        "postcode": "6285",
        "state": "Western Australia"
    },
    {
        "suburb": "Leeuwin",
        "postcode": "6290",
        "state": "Western Australia"
    },
    {
        "suburb": "Augusta",
        "postcode": "6290",
        "state": "Western Australia"
    },
    {
        "suburb": "Bramley",
        "postcode": "6285",
        "state": "Western Australia"
    },
    {
        "suburb": "Warner Glen",
        "postcode": "6288",
        "state": "Western Australia"
    },
    {
        "suburb": "Treeton",
        "postcode": "6284",
        "state": "Western Australia"
    },
    {
        "suburb": "Courtenay",
        "postcode": "6288",
        "state": "Western Australia"
    },
    {
        "suburb": "Gracetown",
        "postcode": "6284",
        "state": "Western Australia"
    },
    {
        "suburb": "Prevelly",
        "postcode": "6285",
        "state": "Western Australia"
    },
    {
        "suburb": "Karridale",
        "postcode": "6288",
        "state": "Western Australia"
    },
    {
        "suburb": "Cowaramup",
        "postcode": "6284",
        "state": "Western Australia"
    },
    {
        "suburb": "Witchcliffe",
        "postcode": "6286",
        "state": "Western Australia"
    },
    {
        "suburb": "Rosa Glen",
        "postcode": "6285",
        "state": "Western Australia"
    },
    {
        "suburb": "Rosa Brook",
        "postcode": "6285",
        "state": "Western Australia"
    },
    {
        "suburb": "Burnside",
        "postcode": "6285",
        "state": "Western Australia"
    },
    {
        "suburb": "Molloy Island",
        "postcode": "6290",
        "state": "Western Australia"
    },
    {
        "suburb": "Nillup",
        "postcode": "6288",
        "state": "Western Australia"
    },
    {
        "suburb": "Alexandra Bridge",
        "postcode": "6288",
        "state": "Western Australia"
    },
    {
        "suburb": "Schroeder",
        "postcode": "6285",
        "state": "Western Australia"
    },
    {
        "suburb": "East Augusta",
        "postcode": "6290",
        "state": "Western Australia"
    },
    {
        "suburb": "Osmington",
        "postcode": "6285",
        "state": "Western Australia"
    },
    {
        "suburb": "Forest Grove",
        "postcode": "6286",
        "state": "Western Australia"
    },
    {
        "suburb": "Scott River",
        "postcode": "6288",
        "state": "Western Australia"
    },
    {
        "suburb": "Kudardup",
        "postcode": "6290",
        "state": "Western Australia"
    },
    {
        "suburb": "Baudin",
        "postcode": "6284",
        "state": "Western Australia"
    },
    {
        "suburb": "Bassendean",
        "postcode": "6054",
        "state": "Western Australia"
    },
    {
        "suburb": "Ashfield",
        "postcode": "6054",
        "state": "Western Australia"
    },
    {
        "suburb": "Eden Hill",
        "postcode": "6054",
        "state": "Western Australia"
    },
    {
        "suburb": "Bedford",
        "postcode": "6052",
        "state": "Western Australia"
    },
    {
        "suburb": "Morley",
        "postcode": "6062",
        "state": "Western Australia"
    },
    {
        "suburb": "Maylands",
        "postcode": "6051",
        "state": "Western Australia"
    },
    {
        "suburb": "Bayswater",
        "postcode": "6053",
        "state": "Western Australia"
    },
    {
        "suburb": "Embleton",
        "postcode": "6062",
        "state": "Western Australia"
    },
    {
        "suburb": "Noranda",
        "postcode": "6062",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Lawley",
        "postcode": "6050",
        "state": "Western Australia"
    },
    {
        "suburb": "Rivervale",
        "postcode": "6103",
        "state": "Western Australia"
    },
    {
        "suburb": "Kewdale",
        "postcode": "6105",
        "state": "Western Australia"
    },
    {
        "suburb": "Belmont",
        "postcode": "6104",
        "state": "Western Australia"
    },
    {
        "suburb": "Cloverdale",
        "postcode": "6105",
        "state": "Western Australia"
    },
    {
        "suburb": "Redcliffe",
        "postcode": "6104",
        "state": "Western Australia"
    },
    {
        "suburb": "Ascot",
        "postcode": "6104",
        "state": "Western Australia"
    },
    {
        "suburb": "Dale",
        "postcode": "6304",
        "state": "Western Australia"
    },
    {
        "suburb": "Westdale",
        "postcode": "6304",
        "state": "Western Australia"
    },
    {
        "suburb": "East Beverley",
        "postcode": "6304",
        "state": "Western Australia"
    },
    {
        "suburb": "Beverley",
        "postcode": "6304",
        "state": "Western Australia"
    },
    {
        "suburb": "Talbot West",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Bally Bally",
        "postcode": "6304",
        "state": "Western Australia"
    },
    {
        "suburb": "Morbinning",
        "postcode": "6304",
        "state": "Western Australia"
    },
    {
        "suburb": "Kokeby",
        "postcode": "6304",
        "state": "Western Australia"
    },
    {
        "suburb": "Flint",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Boddington",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "Marradong",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "Bannister",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "Wuraming",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "Lower Hotham",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "Upper Murray",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "Quindanning",
        "postcode": "6391",
        "state": "Western Australia"
    },
    {
        "suburb": "Crossman",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "Ranford",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "McAlinden",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Boyup Brook",
        "postcode": "6244",
        "state": "Western Australia"
    },
    {
        "suburb": "Kulikup",
        "postcode": "6244",
        "state": "Western Australia"
    },
    {
        "suburb": "Scotts Brook",
        "postcode": "6244",
        "state": "Western Australia"
    },
    {
        "suburb": "Wilga",
        "postcode": "6243",
        "state": "Western Australia"
    },
    {
        "suburb": "Benjinup",
        "postcode": "6255",
        "state": "Western Australia"
    },
    {
        "suburb": "Dinninup",
        "postcode": "6244",
        "state": "Western Australia"
    },
    {
        "suburb": "Chowerup",
        "postcode": "6244",
        "state": "Western Australia"
    },
    {
        "suburb": "Tonebridge",
        "postcode": "6244",
        "state": "Western Australia"
    },
    {
        "suburb": "Mayanup",
        "postcode": "6244",
        "state": "Western Australia"
    },
    {
        "suburb": "Bridgetown",
        "postcode": "6255",
        "state": "Western Australia"
    },
    {
        "suburb": "Winnejup",
        "postcode": "6255",
        "state": "Western Australia"
    },
    {
        "suburb": "Greenbushes",
        "postcode": "6254",
        "state": "Western Australia"
    },
    {
        "suburb": "Catterick",
        "postcode": "6255",
        "state": "Western Australia"
    },
    {
        "suburb": "North Greenbushes",
        "postcode": "6254",
        "state": "Western Australia"
    },
    {
        "suburb": "Hester Brook",
        "postcode": "6255",
        "state": "Western Australia"
    },
    {
        "suburb": "Wandillup",
        "postcode": "6256",
        "state": "Western Australia"
    },
    {
        "suburb": "Sunnyside",
        "postcode": "6256",
        "state": "Western Australia"
    },
    {
        "suburb": "Glenlynn",
        "postcode": "6256",
        "state": "Western Australia"
    },
    {
        "suburb": "Hester",
        "postcode": "6255",
        "state": "Western Australia"
    },
    {
        "suburb": "Yornup",
        "postcode": "6256",
        "state": "Western Australia"
    },
    {
        "suburb": "Kangaroo Gully",
        "postcode": "6255",
        "state": "Western Australia"
    },
    {
        "suburb": "Maranup",
        "postcode": "6256",
        "state": "Western Australia"
    },
    {
        "suburb": "Kingston",
        "postcode": "6256",
        "state": "Western Australia"
    },
    {
        "suburb": "Jelcobine",
        "postcode": "6306",
        "state": "Western Australia"
    },
    {
        "suburb": "Aldersyde",
        "postcode": "6306",
        "state": "Western Australia"
    },
    {
        "suburb": "Brookton",
        "postcode": "6306",
        "state": "Western Australia"
    },
    {
        "suburb": "Kweda",
        "postcode": "6306",
        "state": "Western Australia"
    },
    {
        "suburb": "Lagrange",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Dampier Peninsula",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Roebuck",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Cable Beach",
        "postcode": "6726",
        "state": "Western Australia"
    },
    {
        "suburb": "Broome",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Djugun",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Waterbank",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Minyirr",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Bilingurr",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Gingerah",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Eighty Mile Beach",
        "postcode": "6725",
        "state": "Western Australia"
    },
    {
        "suburb": "Broomehill West",
        "postcode": "6318",
        "state": "Western Australia"
    },
    {
        "suburb": "Broomehill East",
        "postcode": "6318",
        "state": "Western Australia"
    },
    {
        "suburb": "Broomehill Village",
        "postcode": "6318",
        "state": "Western Australia"
    },
    {
        "suburb": "Shackleton",
        "postcode": "6386",
        "state": "Western Australia"
    },
    {
        "suburb": "Babakin",
        "postcode": "6428",
        "state": "Western Australia"
    },
    {
        "suburb": "Ardath",
        "postcode": "6419",
        "state": "Western Australia"
    },
    {
        "suburb": "Bruce Rock",
        "postcode": "6418",
        "state": "Western Australia"
    },
    {
        "suburb": "Kwolyin",
        "postcode": "6385",
        "state": "Western Australia"
    },
    {
        "suburb": "South Bunbury",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Withers",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Usher",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Bunbury",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Carey Park",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "College Grove",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "East Bunbury",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Glen Iris",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Pelican Point",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Vittoria",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Picton",
        "postcode": "6229",
        "state": "Western Australia"
    },
    {
        "suburb": "Davenport",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Vasse",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Yallingup",
        "postcode": "6282",
        "state": "Western Australia"
    },
    {
        "suburb": "Naturaliste",
        "postcode": "6281",
        "state": "Western Australia"
    },
    {
        "suburb": "Yelverton",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Dunsborough",
        "postcode": "6281",
        "state": "Western Australia"
    },
    {
        "suburb": "Metricup",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Quindalup",
        "postcode": "6281",
        "state": "Western Australia"
    },
    {
        "suburb": "Jindong",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Abbey",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Broadwater",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "West Busselton",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Acton Park",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Busselton",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Kalgup",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Geographe",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Sabina River",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Yoongarillup",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Hithergreen",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Reinscourt",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Walsall",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Wonnerup",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Ruabon",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Jarrahwood",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "North Jindong",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Kaloorup",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Chapman Hill",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Carbunup River",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Kealy",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Ludlow",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Boallia",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Ambergate",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Yalyalup",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Bovell",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Wilyabrup",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Quedjinup",
        "postcode": "6281",
        "state": "Western Australia"
    },
    {
        "suburb": "Eagle Bay",
        "postcode": "6281",
        "state": "Western Australia"
    },
    {
        "suburb": "Yallingup Siding",
        "postcode": "6282",
        "state": "Western Australia"
    },
    {
        "suburb": "Marybrook",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Abba River",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Tutunup",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Anniebrook",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Yoganup",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "City Beach",
        "postcode": "6015",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Claremont",
        "postcode": "6010",
        "state": "Western Australia"
    },
    {
        "suburb": "Wembley",
        "postcode": "6014",
        "state": "Western Australia"
    },
    {
        "suburb": "Floreat",
        "postcode": "6014",
        "state": "Western Australia"
    },
    {
        "suburb": "West Leederville",
        "postcode": "6007",
        "state": "Western Australia"
    },
    {
        "suburb": "Jolimont",
        "postcode": "6014",
        "state": "Western Australia"
    },
    {
        "suburb": "Subiaco",
        "postcode": "6008",
        "state": "Western Australia"
    },
    {
        "suburb": "Wembley Downs",
        "postcode": "6015",
        "state": "Western Australia"
    },
    {
        "suburb": "Rossmoyne",
        "postcode": "6148",
        "state": "Western Australia"
    },
    {
        "suburb": "Willetton",
        "postcode": "6155",
        "state": "Western Australia"
    },
    {
        "suburb": "Shelley",
        "postcode": "6148",
        "state": "Western Australia"
    },
    {
        "suburb": "Riverton",
        "postcode": "6148",
        "state": "Western Australia"
    },
    {
        "suburb": "Bentley",
        "postcode": "6102",
        "state": "Western Australia"
    },
    {
        "suburb": "Wilson",
        "postcode": "6107",
        "state": "Western Australia"
    },
    {
        "suburb": "Parkwood",
        "postcode": "6147",
        "state": "Western Australia"
    },
    {
        "suburb": "Canning Vale",
        "postcode": "6155",
        "state": "Western Australia"
    },
    {
        "suburb": "St James",
        "postcode": "6102",
        "state": "Western Australia"
    },
    {
        "suburb": "Ferndale",
        "postcode": "6148",
        "state": "Western Australia"
    },
    {
        "suburb": "Lynwood",
        "postcode": "6147",
        "state": "Western Australia"
    },
    {
        "suburb": "East Cannington",
        "postcode": "6107",
        "state": "Western Australia"
    },
    {
        "suburb": "Queens Park",
        "postcode": "6107",
        "state": "Western Australia"
    },
    {
        "suburb": "Cannington",
        "postcode": "6107",
        "state": "Western Australia"
    },
    {
        "suburb": "Welshpool",
        "postcode": "6106",
        "state": "Western Australia"
    },
    {
        "suburb": "Leeming",
        "postcode": "6149",
        "state": "Western Australia"
    },
    {
        "suburb": "Dalyellup",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Gelorup",
        "postcode": "6230",
        "state": "Western Australia"
    },
    {
        "suburb": "Stratham",
        "postcode": "6237",
        "state": "Western Australia"
    },
    {
        "suburb": "The Plains",
        "postcode": "6237",
        "state": "Western Australia"
    },
    {
        "suburb": "Capel River",
        "postcode": "6271",
        "state": "Western Australia"
    },
    {
        "suburb": "Capel",
        "postcode": "6271",
        "state": "Western Australia"
    },
    {
        "suburb": "Stirling Estate",
        "postcode": "6271",
        "state": "Western Australia"
    },
    {
        "suburb": "Peppermint Grove Beach",
        "postcode": "6271",
        "state": "Western Australia"
    },
    {
        "suburb": "Boyanup",
        "postcode": "6237",
        "state": "Western Australia"
    },
    {
        "suburb": "Gwindinup",
        "postcode": "6237",
        "state": "Western Australia"
    },
    {
        "suburb": "North Boyanup",
        "postcode": "6237",
        "state": "Western Australia"
    },
    {
        "suburb": "Forrest Beach",
        "postcode": "6271",
        "state": "Western Australia"
    },
    {
        "suburb": "Elgin",
        "postcode": "6237",
        "state": "Western Australia"
    },
    {
        "suburb": "Eneabba",
        "postcode": "6518",
        "state": "Western Australia"
    },
    {
        "suburb": "Carnamah",
        "postcode": "6517",
        "state": "Western Australia"
    },
    {
        "suburb": "Inggarda",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Babbage Island",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Carnarvon",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "South Carnarvon",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Wooramel",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Morgantown",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Brockman",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Coral Bay",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Brown Range",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "East Carnarvon",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Yandoo Creek",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Kennedy Range",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "South Plantations",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Macleod",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Lyndon",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Minilya",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "North Plantations",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Bernier Island",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Dorre Island",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Waggrakine",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Howatharra",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Nabawa",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "East Nabawa",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Yetna",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Marrah",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Erin",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Narra Tarra",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Dindiloa",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Nolba",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Durawah",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "North Eradu",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "East Yuna",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Rockwell",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Nanson",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Buller",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "White Peak",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Oakajee",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Wannamal",
        "postcode": "6505",
        "state": "Western Australia"
    },
    {
        "suburb": "Bindoon",
        "postcode": "6502",
        "state": "Western Australia"
    },
    {
        "suburb": "Chittering",
        "postcode": "6084",
        "state": "Western Australia"
    },
    {
        "suburb": "Muchea",
        "postcode": "6501",
        "state": "Western Australia"
    },
    {
        "suburb": "Lower Chittering",
        "postcode": "6084",
        "state": "Western Australia"
    },
    {
        "suburb": "Mooliabeenee",
        "postcode": "6504",
        "state": "Western Australia"
    },
    {
        "suburb": "Claremont",
        "postcode": "6010",
        "state": "Western Australia"
    },
    {
        "suburb": "Swanbourne",
        "postcode": "6010",
        "state": "Western Australia"
    },
    {
        "suburb": "Garden Island",
        "postcode": "6168",
        "state": "Western Australia"
    },
    {
        "suburb": "Hamilton Hill",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "Coogee",
        "postcode": "6166",
        "state": "Western Australia"
    },
    {
        "suburb": "Spearwood",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "Munster",
        "postcode": "6166",
        "state": "Western Australia"
    },
    {
        "suburb": "Bibra Lake",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "Coolbellup",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "Yangebup",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "Beeliar",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "South Lake",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "Atwell",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "North Lake",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "Cockburn Central",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "Success",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "Jandakot",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "Henderson",
        "postcode": "6166",
        "state": "Western Australia"
    },
    {
        "suburb": "Wattleup",
        "postcode": "6166",
        "state": "Western Australia"
    },
    {
        "suburb": "Hammond Park",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "Banjup",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "Aubin Grove",
        "postcode": "6164",
        "state": "Western Australia"
    },
    {
        "suburb": "North Coogee",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "Rottnest Island",
        "postcode": "6161",
        "state": "Western Australia"
    },
    {
        "suburb": "Collie",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Preston Settlement",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Yourdamung Lake",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Palmer",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Worsley",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Shotts",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Allanson",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Mungalup",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Muja",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Collie Burn",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Harris River",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Buckingham",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Kambalda West",
        "postcode": "6442",
        "state": "Western Australia"
    },
    {
        "suburb": "Kambalda East",
        "postcode": "6442",
        "state": "Western Australia"
    },
    {
        "suburb": "Bullabulling",
        "postcode": "6429",
        "state": "Western Australia"
    },
    {
        "suburb": "Coolgardie",
        "postcode": "6429",
        "state": "Western Australia"
    },
    {
        "suburb": "Karramindie",
        "postcode": "6429",
        "state": "Western Australia"
    },
    {
        "suburb": "Londonderry",
        "postcode": "6429",
        "state": "Western Australia"
    },
    {
        "suburb": "Wallaroo",
        "postcode": "6429",
        "state": "Western Australia"
    },
    {
        "suburb": "Higginsville",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Boorabbin",
        "postcode": "6429",
        "state": "Western Australia"
    },
    {
        "suburb": "Victoria Rock",
        "postcode": "6429",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Burges",
        "postcode": "6429",
        "state": "Western Australia"
    },
    {
        "suburb": "Green Head",
        "postcode": "6514",
        "state": "Western Australia"
    },
    {
        "suburb": "Leeman",
        "postcode": "6514",
        "state": "Western Australia"
    },
    {
        "suburb": "Eganu",
        "postcode": "6515",
        "state": "Western Australia"
    },
    {
        "suburb": "Gunyidi",
        "postcode": "6513",
        "state": "Western Australia"
    },
    {
        "suburb": "Warradarge",
        "postcode": "6518",
        "state": "Western Australia"
    },
    {
        "suburb": "Marchagee",
        "postcode": "6515",
        "state": "Western Australia"
    },
    {
        "suburb": "Coorow",
        "postcode": "6515",
        "state": "Western Australia"
    },
    {
        "suburb": "Waddy Forest",
        "postcode": "6515",
        "state": "Western Australia"
    },
    {
        "suburb": "Gorge Rock",
        "postcode": "6375",
        "state": "Western Australia"
    },
    {
        "suburb": "Adamsvale",
        "postcode": "6375",
        "state": "Western Australia"
    },
    {
        "suburb": "Bullaring",
        "postcode": "6373",
        "state": "Western Australia"
    },
    {
        "suburb": "Kunjin",
        "postcode": "6375",
        "state": "Western Australia"
    },
    {
        "suburb": "Bulyee",
        "postcode": "6306",
        "state": "Western Australia"
    },
    {
        "suburb": "Corrigin",
        "postcode": "6375",
        "state": "Western Australia"
    },
    {
        "suburb": "Kurrenkutten",
        "postcode": "6375",
        "state": "Western Australia"
    },
    {
        "suburb": "Bilbarin",
        "postcode": "6375",
        "state": "Western Australia"
    },
    {
        "suburb": "Cottesloe",
        "postcode": "6011",
        "state": "Western Australia"
    },
    {
        "suburb": "Frankland River",
        "postcode": "6396",
        "state": "Western Australia"
    },
    {
        "suburb": "Cranbrook",
        "postcode": "6321",
        "state": "Western Australia"
    },
    {
        "suburb": "Tenterden",
        "postcode": "6322",
        "state": "Western Australia"
    },
    {
        "suburb": "Stirling Range National Park",
        "postcode": "6338",
        "state": "Western Australia"
    },
    {
        "suburb": "Commodine",
        "postcode": "6311",
        "state": "Western Australia"
    },
    {
        "suburb": "Wardering",
        "postcode": "6311",
        "state": "Western Australia"
    },
    {
        "suburb": "Popanyinning",
        "postcode": "6309",
        "state": "Western Australia"
    },
    {
        "suburb": "Stratherne",
        "postcode": "6309",
        "state": "Western Australia"
    },
    {
        "suburb": "Contine",
        "postcode": "6311",
        "state": "Western Australia"
    },
    {
        "suburb": "Dryandra",
        "postcode": "6311",
        "state": "Western Australia"
    },
    {
        "suburb": "Lol Gray",
        "postcode": "6311",
        "state": "Western Australia"
    },
    {
        "suburb": "Yornaning",
        "postcode": "6311",
        "state": "Western Australia"
    },
    {
        "suburb": "Cuballing",
        "postcode": "6311",
        "state": "Western Australia"
    },
    {
        "suburb": "Townsendale",
        "postcode": "6311",
        "state": "Western Australia"
    },
    {
        "suburb": "East Popanyinning",
        "postcode": "6309",
        "state": "Western Australia"
    },
    {
        "suburb": "West Popanyinning",
        "postcode": "6309",
        "state": "Western Australia"
    },
    {
        "suburb": "Cue",
        "postcode": "6640",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Austin",
        "postcode": "6640",
        "state": "Western Australia"
    },
    {
        "suburb": "Cunderdin",
        "postcode": "6407",
        "state": "Western Australia"
    },
    {
        "suburb": "Watercarrin",
        "postcode": "6407",
        "state": "Western Australia"
    },
    {
        "suburb": "Warding East",
        "postcode": "6405",
        "state": "Western Australia"
    },
    {
        "suburb": "Greenwoods Valley",
        "postcode": "6405",
        "state": "Western Australia"
    },
    {
        "suburb": "Quelagetting",
        "postcode": "6405",
        "state": "Western Australia"
    },
    {
        "suburb": "Waeel",
        "postcode": "6407",
        "state": "Western Australia"
    },
    {
        "suburb": "Meckering",
        "postcode": "6405",
        "state": "Western Australia"
    },
    {
        "suburb": "Youndegin",
        "postcode": "6407",
        "state": "Western Australia"
    },
    {
        "suburb": "Wyola West",
        "postcode": "6407",
        "state": "Western Australia"
    },
    {
        "suburb": "Xantippe",
        "postcode": "6609",
        "state": "Western Australia"
    },
    {
        "suburb": "Goodlands",
        "postcode": "6468",
        "state": "Western Australia"
    },
    {
        "suburb": "Jibberding",
        "postcode": "6612",
        "state": "Western Australia"
    },
    {
        "suburb": "East Damboring",
        "postcode": "6608",
        "state": "Western Australia"
    },
    {
        "suburb": "Marne",
        "postcode": "6608",
        "state": "Western Australia"
    },
    {
        "suburb": "Wubin",
        "postcode": "6612",
        "state": "Western Australia"
    },
    {
        "suburb": "Miamoon",
        "postcode": "6612",
        "state": "Western Australia"
    },
    {
        "suburb": "Kalannie",
        "postcode": "6468",
        "state": "Western Australia"
    },
    {
        "suburb": "Nugadong",
        "postcode": "6609",
        "state": "Western Australia"
    },
    {
        "suburb": "Dalwallinu",
        "postcode": "6609",
        "state": "Western Australia"
    },
    {
        "suburb": "Petrudor",
        "postcode": "6468",
        "state": "Western Australia"
    },
    {
        "suburb": "Pithara",
        "postcode": "6608",
        "state": "Western Australia"
    },
    {
        "suburb": "Buntine",
        "postcode": "6613",
        "state": "Western Australia"
    },
    {
        "suburb": "Cataby",
        "postcode": "6507",
        "state": "Western Australia"
    },
    {
        "suburb": "Regans Ford",
        "postcode": "6507",
        "state": "Western Australia"
    },
    {
        "suburb": "Mimegarra",
        "postcode": "6507",
        "state": "Western Australia"
    },
    {
        "suburb": "Jurien Bay",
        "postcode": "6516",
        "state": "Western Australia"
    },
    {
        "suburb": "Cervantes",
        "postcode": "6511",
        "state": "Western Australia"
    },
    {
        "suburb": "Boothendarra",
        "postcode": "6521",
        "state": "Western Australia"
    },
    {
        "suburb": "Badgingarra",
        "postcode": "6521",
        "state": "Western Australia"
    },
    {
        "suburb": "Dandaragan",
        "postcode": "6507",
        "state": "Western Australia"
    },
    {
        "suburb": "Yathroo",
        "postcode": "6507",
        "state": "Western Australia"
    },
    {
        "suburb": "Grey",
        "postcode": "6521",
        "state": "Western Australia"
    },
    {
        "suburb": "Wedge Island",
        "postcode": "6044",
        "state": "Western Australia"
    },
    {
        "suburb": "Hill River",
        "postcode": "6521",
        "state": "Western Australia"
    },
    {
        "suburb": "Cooljarloo",
        "postcode": "6507",
        "state": "Western Australia"
    },
    {
        "suburb": "Nambung",
        "postcode": "6521",
        "state": "Western Australia"
    },
    {
        "suburb": "Eaton",
        "postcode": "6232",
        "state": "Western Australia"
    },
    {
        "suburb": "Australind",
        "postcode": "6233",
        "state": "Western Australia"
    },
    {
        "suburb": "Millbridge",
        "postcode": "6232",
        "state": "Western Australia"
    },
    {
        "suburb": "Crooked Brook",
        "postcode": "6236",
        "state": "Western Australia"
    },
    {
        "suburb": "Wellington Mill",
        "postcode": "6236",
        "state": "Western Australia"
    },
    {
        "suburb": "Burekup",
        "postcode": "6227",
        "state": "Western Australia"
    },
    {
        "suburb": "Dardanup",
        "postcode": "6236",
        "state": "Western Australia"
    },
    {
        "suburb": "Dardanup West",
        "postcode": "6236",
        "state": "Western Australia"
    },
    {
        "suburb": "Wellington Forest",
        "postcode": "6236",
        "state": "Western Australia"
    },
    {
        "suburb": "Ferguson",
        "postcode": "6236",
        "state": "Western Australia"
    },
    {
        "suburb": "Picton East",
        "postcode": "6229",
        "state": "Western Australia"
    },
    {
        "suburb": "Paradise",
        "postcode": "6236",
        "state": "Western Australia"
    },
    {
        "suburb": "William Bay",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Scotsdale",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Ocean Beach",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Denmark",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Bow Bridge",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Parryville",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Shadforth",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Hay",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Nornalup",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Romance",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Hazelvale",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Lindesay",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Peaceful Bay",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Kentdale",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Trent",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Tingledale",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Kordabup",
        "postcode": "6333",
        "state": "Western Australia"
    },
    {
        "suburb": "Kimbolton",
        "postcode": "6728",
        "state": "Western Australia"
    },
    {
        "suburb": "Willare",
        "postcode": "6728",
        "state": "Western Australia"
    },
    {
        "suburb": "Derby",
        "postcode": "6728",
        "state": "Western Australia"
    },
    {
        "suburb": "Meda",
        "postcode": "6728",
        "state": "Western Australia"
    },
    {
        "suburb": "King Leopold Ranges",
        "postcode": "6728",
        "state": "Western Australia"
    },
    {
        "suburb": "St George Ranges",
        "postcode": "6728",
        "state": "Western Australia"
    },
    {
        "suburb": "Fitzroy Crossing",
        "postcode": "6765",
        "state": "Western Australia"
    },
    {
        "suburb": "Camballin",
        "postcode": "6728",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Hardman",
        "postcode": "6765",
        "state": "Western Australia"
    },
    {
        "suburb": "Geegully Creek",
        "postcode": "6728",
        "state": "Western Australia"
    },
    {
        "suburb": "Donnybrook",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Newlands",
        "postcode": "6251",
        "state": "Western Australia"
    },
    {
        "suburb": "Southampton",
        "postcode": "6253",
        "state": "Western Australia"
    },
    {
        "suburb": "Yabberup",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Paynedale",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Balingup",
        "postcode": "6253",
        "state": "Western Australia"
    },
    {
        "suburb": "Cundinup",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "Upper Capel",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Kirup",
        "postcode": "6251",
        "state": "Western Australia"
    },
    {
        "suburb": "Argyle",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Queenwood",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Beelerup",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Lowden",
        "postcode": "6240",
        "state": "Western Australia"
    },
    {
        "suburb": "Glen Mervyn",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Wilga West",
        "postcode": "6243",
        "state": "Western Australia"
    },
    {
        "suburb": "Mullalyup",
        "postcode": "6252",
        "state": "Western Australia"
    },
    {
        "suburb": "Brookhampton",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Thomson Brook",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Grimwade",
        "postcode": "6253",
        "state": "Western Australia"
    },
    {
        "suburb": "Noggerup",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Mumballup",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Charley Creek",
        "postcode": "6239",
        "state": "Western Australia"
    },
    {
        "suburb": "Brazier",
        "postcode": "6251",
        "state": "Western Australia"
    },
    {
        "suburb": "Ucarty",
        "postcode": "6462",
        "state": "Western Australia"
    },
    {
        "suburb": "Dowerin",
        "postcode": "6461",
        "state": "Western Australia"
    },
    {
        "suburb": "Hindmarsh",
        "postcode": "6462",
        "state": "Western Australia"
    },
    {
        "suburb": "Manmanning",
        "postcode": "6465",
        "state": "Western Australia"
    },
    {
        "suburb": "Koomberkine",
        "postcode": "6461",
        "state": "Western Australia"
    },
    {
        "suburb": "Minnivale",
        "postcode": "6462",
        "state": "Western Australia"
    },
    {
        "suburb": "Nippering",
        "postcode": "6350",
        "state": "Western Australia"
    },
    {
        "suburb": "Dumbleyung",
        "postcode": "6350",
        "state": "Western Australia"
    },
    {
        "suburb": "Dongolocking",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Datatine",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Tarin Rock",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "North Kukerin",
        "postcode": "6352",
        "state": "Western Australia"
    },
    {
        "suburb": "Moulyinning",
        "postcode": "6351",
        "state": "Western Australia"
    },
    {
        "suburb": "Kukerin",
        "postcode": "6352",
        "state": "Western Australia"
    },
    {
        "suburb": "Bullock Hills",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Merilup",
        "postcode": "6352",
        "state": "Western Australia"
    },
    {
        "suburb": "North Moulyinning",
        "postcode": "6351",
        "state": "Western Australia"
    },
    {
        "suburb": "Nairibin",
        "postcode": "6350",
        "state": "Western Australia"
    },
    {
        "suburb": "Eucla",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Norseman",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Balladonia",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Madura",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Caiguna",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Dundas",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Cocklebiddy",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Fraser Range",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "North Cascade",
        "postcode": "6445",
        "state": "Western Australia"
    },
    {
        "suburb": "East Fremantle",
        "postcode": "6158",
        "state": "Western Australia"
    },
    {
        "suburb": "Pardoo",
        "postcode": "6721",
        "state": "Western Australia"
    },
    {
        "suburb": "Marble Bar",
        "postcode": "6760",
        "state": "Western Australia"
    },
    {
        "suburb": "Nullagine",
        "postcode": "6758",
        "state": "Western Australia"
    },
    {
        "suburb": "Newman",
        "postcode": "6753",
        "state": "Western Australia"
    },
    {
        "suburb": "Telfer",
        "postcode": "6762",
        "state": "Western Australia"
    },
    {
        "suburb": "Merivale",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Gibson",
        "postcode": "6448",
        "state": "Western Australia"
    },
    {
        "suburb": "Bandy Creek",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "East Munglinup",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Dalyup",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Ney",
        "postcode": "6447",
        "state": "Western Australia"
    },
    {
        "suburb": "Howick",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Beaumont",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Israelite Bay",
        "postcode": "6452",
        "state": "Western Australia"
    },
    {
        "suburb": "Coomalbidgup",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Cape Le Grand",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "West Beach",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Cape Arid",
        "postcode": "6452",
        "state": "Western Australia"
    },
    {
        "suburb": "Condingup",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Pink Lake",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Scaddan",
        "postcode": "6447",
        "state": "Western Australia"
    },
    {
        "suburb": "Grass Patch",
        "postcode": "6446",
        "state": "Western Australia"
    },
    {
        "suburb": "Nulsen",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Castletown",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Chadwick",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Esperance",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Monjingup",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Windabout",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Sinclair",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Myrup",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Buraminya",
        "postcode": "6452",
        "state": "Western Australia"
    },
    {
        "suburb": "Cascade",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Lort River",
        "postcode": "6447",
        "state": "Western Australia"
    },
    {
        "suburb": "Boyatup",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Neridup",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Wittenoom Hills",
        "postcode": "6447",
        "state": "Western Australia"
    },
    {
        "suburb": "Salmon Gums",
        "postcode": "6445",
        "state": "Western Australia"
    },
    {
        "suburb": "Exmouth",
        "postcode": "6707",
        "state": "Western Australia"
    },
    {
        "suburb": "North West Cape",
        "postcode": "6707",
        "state": "Western Australia"
    },
    {
        "suburb": "Exmouth Gulf",
        "postcode": "6707",
        "state": "Western Australia"
    },
    {
        "suburb": "Ningaloo",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Learmonth",
        "postcode": "6707",
        "state": "Western Australia"
    },
    {
        "suburb": "Cape Range National Park",
        "postcode": "6707",
        "state": "Western Australia"
    },
    {
        "suburb": "Fremantle",
        "postcode": "6160",
        "state": "Western Australia"
    },
    {
        "suburb": "South Fremantle",
        "postcode": "6162",
        "state": "Western Australia"
    },
    {
        "suburb": "White Gum Valley",
        "postcode": "6162",
        "state": "Western Australia"
    },
    {
        "suburb": "Beaconsfield",
        "postcode": "6162",
        "state": "Western Australia"
    },
    {
        "suburb": "Samson",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "Hilton",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "O'Connor",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "North Fremantle",
        "postcode": "6159",
        "state": "Western Australia"
    },
    {
        "suburb": "Beachlands",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Geraldton",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Beresford",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Bluff Point",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Sunset Beach",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Wonthella",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Rangeway",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Webberton",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Utakarra",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Houtman Abrolhos",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "West End",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Spalding",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Mahomets Flats",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Tarcoola Beach",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Tarcoola",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Lancelin",
        "postcode": "6044",
        "state": "Western Australia"
    },
    {
        "suburb": "Granville",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Nilgen",
        "postcode": "6044",
        "state": "Western Australia"
    },
    {
        "suburb": "Karakin",
        "postcode": "6044",
        "state": "Western Australia"
    },
    {
        "suburb": "Ledge Point",
        "postcode": "6043",
        "state": "Western Australia"
    },
    {
        "suburb": "Cowalla",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Seabird",
        "postcode": "6042",
        "state": "Western Australia"
    },
    {
        "suburb": "Gabbadah",
        "postcode": "6041",
        "state": "Western Australia"
    },
    {
        "suburb": "Woodridge",
        "postcode": "6041",
        "state": "Western Australia"
    },
    {
        "suburb": "Guilderton",
        "postcode": "6041",
        "state": "Western Australia"
    },
    {
        "suburb": "Gingin",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Boonanarring",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Mindarra",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Moore River National Park",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Wanerie",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Beermullah",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Moondah",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Lennard Brook",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Neergabby",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Coonabidgee",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Muckenburra",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Breton Bay",
        "postcode": "6043",
        "state": "Western Australia"
    },
    {
        "suburb": "Red Gully",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Orange Springs",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Cullalla",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Wilbinga",
        "postcode": "6041",
        "state": "Western Australia"
    },
    {
        "suburb": "Caraban",
        "postcode": "6041",
        "state": "Western Australia"
    },
    {
        "suburb": "Yeal",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Breera",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Ginginup",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Bambun",
        "postcode": "6503",
        "state": "Western Australia"
    },
    {
        "suburb": "Ongerup",
        "postcode": "6336",
        "state": "Western Australia"
    },
    {
        "suburb": "Mills Lake",
        "postcode": "6336",
        "state": "Western Australia"
    },
    {
        "suburb": "North Stirlings",
        "postcode": "6338",
        "state": "Western Australia"
    },
    {
        "suburb": "Gnowangerup",
        "postcode": "6335",
        "state": "Western Australia"
    },
    {
        "suburb": "Kebaringup",
        "postcode": "6335",
        "state": "Western Australia"
    },
    {
        "suburb": "Toompup",
        "postcode": "6336",
        "state": "Western Australia"
    },
    {
        "suburb": "Monjebup",
        "postcode": "6338",
        "state": "Western Australia"
    },
    {
        "suburb": "Jackitup",
        "postcode": "6335",
        "state": "Western Australia"
    },
    {
        "suburb": "Magitup",
        "postcode": "6338",
        "state": "Western Australia"
    },
    {
        "suburb": "Mindarabin",
        "postcode": "6336",
        "state": "Western Australia"
    },
    {
        "suburb": "Borden",
        "postcode": "6338",
        "state": "Western Australia"
    },
    {
        "suburb": "Amelup",
        "postcode": "6338",
        "state": "Western Australia"
    },
    {
        "suburb": "Nalyerlup",
        "postcode": "6338",
        "state": "Western Australia"
    },
    {
        "suburb": "Walyormouring",
        "postcode": "6460",
        "state": "Western Australia"
    },
    {
        "suburb": "Cunjardine",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Mumberkine",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Goomalling",
        "postcode": "6460",
        "state": "Western Australia"
    },
    {
        "suburb": "Rossmore",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Karranadgin",
        "postcode": "6460",
        "state": "Western Australia"
    },
    {
        "suburb": "Wongamine",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Hulongine",
        "postcode": "6460",
        "state": "Western Australia"
    },
    {
        "suburb": "Konnongorring",
        "postcode": "6603",
        "state": "Western Australia"
    },
    {
        "suburb": "Ucarty West",
        "postcode": "6460",
        "state": "Western Australia"
    },
    {
        "suburb": "Jennacubbine",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Gosnells",
        "postcode": "6110",
        "state": "Western Australia"
    },
    {
        "suburb": "Maddington",
        "postcode": "6109",
        "state": "Western Australia"
    },
    {
        "suburb": "Thornlie",
        "postcode": "6108",
        "state": "Western Australia"
    },
    {
        "suburb": "Huntingdale",
        "postcode": "6110",
        "state": "Western Australia"
    },
    {
        "suburb": "Southern River",
        "postcode": "6110",
        "state": "Western Australia"
    },
    {
        "suburb": "Kenwick",
        "postcode": "6107",
        "state": "Western Australia"
    },
    {
        "suburb": "Langford",
        "postcode": "6147",
        "state": "Western Australia"
    },
    {
        "suburb": "Martin",
        "postcode": "6110",
        "state": "Western Australia"
    },
    {
        "suburb": "Beckenham",
        "postcode": "6107",
        "state": "Western Australia"
    },
    {
        "suburb": "Orange Grove",
        "postcode": "6109",
        "state": "Western Australia"
    },
    {
        "suburb": "Glenfield",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Moonyoonooka",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Strathalbyn",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Moresby",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Karloo",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Drummond Cove",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Narngulu",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Deepdale",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Wandina",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Woorree",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Cape Burney",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Rudds Gully",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Bringo",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Sandsprings",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "South Greenough",
        "postcode": "6528",
        "state": "Western Australia"
    },
    {
        "suburb": "Greenough",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Kojarena",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Bootenal",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Minnenooka",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Northern Gully",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Wicherina South",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Tibradden",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Wicherina",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Burma Road",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Eradu",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Hill",
        "postcode": "6528",
        "state": "Western Australia"
    },
    {
        "suburb": "East Chapman",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Walkaway",
        "postcode": "6528",
        "state": "Western Australia"
    },
    {
        "suburb": "Georgina",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Ellendale",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Eradu South",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Ord River",
        "postcode": "6770",
        "state": "Western Australia"
    },
    {
        "suburb": "Warmun",
        "postcode": "6743",
        "state": "Western Australia"
    },
    {
        "suburb": "Purnululu",
        "postcode": "6770",
        "state": "Western Australia"
    },
    {
        "suburb": "McBeath",
        "postcode": "6770",
        "state": "Western Australia"
    },
    {
        "suburb": "Halls Creek",
        "postcode": "6770",
        "state": "Western Australia"
    },
    {
        "suburb": "Mueller Ranges",
        "postcode": "6770",
        "state": "Western Australia"
    },
    {
        "suburb": "Sturt Creek",
        "postcode": "6770",
        "state": "Western Australia"
    },
    {
        "suburb": "Tanami",
        "postcode": "6770",
        "state": "Western Australia"
    },
    {
        "suburb": "Leschenault",
        "postcode": "6233",
        "state": "Western Australia"
    },
    {
        "suburb": "Binningup",
        "postcode": "6233",
        "state": "Western Australia"
    },
    {
        "suburb": "Cookernup",
        "postcode": "6220",
        "state": "Western Australia"
    },
    {
        "suburb": "Wokalup",
        "postcode": "6221",
        "state": "Western Australia"
    },
    {
        "suburb": "Harvey",
        "postcode": "6220",
        "state": "Western Australia"
    },
    {
        "suburb": "Mornington",
        "postcode": "6221",
        "state": "Western Australia"
    },
    {
        "suburb": "Myalup",
        "postcode": "6220",
        "state": "Western Australia"
    },
    {
        "suburb": "Hoffman",
        "postcode": "6220",
        "state": "Western Australia"
    },
    {
        "suburb": "Wellesley",
        "postcode": "6233",
        "state": "Western Australia"
    },
    {
        "suburb": "Roelands",
        "postcode": "6226",
        "state": "Western Australia"
    },
    {
        "suburb": "Brunswick",
        "postcode": "6224",
        "state": "Western Australia"
    },
    {
        "suburb": "Yarloop",
        "postcode": "6218",
        "state": "Western Australia"
    },
    {
        "suburb": "Uduc",
        "postcode": "6220",
        "state": "Western Australia"
    },
    {
        "suburb": "Warawarrup",
        "postcode": "6220",
        "state": "Western Australia"
    },
    {
        "suburb": "Beela",
        "postcode": "6224",
        "state": "Western Australia"
    },
    {
        "suburb": "Parkfield",
        "postcode": "6233",
        "state": "Western Australia"
    },
    {
        "suburb": "Dongara",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Irwin",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Adams",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Arrowsmith",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Port Denison",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Yardarino",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Bookara",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Allanooka",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Horner",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Milo",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Bonniefield",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Springfield",
        "postcode": "6525",
        "state": "Western Australia"
    },
    {
        "suburb": "Needilup",
        "postcode": "6336",
        "state": "Western Australia"
    },
    {
        "suburb": "Jerramungup",
        "postcode": "6337",
        "state": "Western Australia"
    },
    {
        "suburb": "Gairdner",
        "postcode": "6337",
        "state": "Western Australia"
    },
    {
        "suburb": "Bremer Bay",
        "postcode": "6338",
        "state": "Western Australia"
    },
    {
        "suburb": "Jacup",
        "postcode": "6337",
        "state": "Western Australia"
    },
    {
        "suburb": "West Fitzgerald",
        "postcode": "6337",
        "state": "Western Australia"
    },
    {
        "suburb": "Ocean Reef",
        "postcode": "6027",
        "state": "Western Australia"
    },
    {
        "suburb": "Heathridge",
        "postcode": "6027",
        "state": "Western Australia"
    },
    {
        "suburb": "Joondalup",
        "postcode": "6027",
        "state": "Western Australia"
    },
    {
        "suburb": "Edgewater",
        "postcode": "6027",
        "state": "Western Australia"
    },
    {
        "suburb": "Burns Beach",
        "postcode": "6028",
        "state": "Western Australia"
    },
    {
        "suburb": "Iluka",
        "postcode": "6028",
        "state": "Western Australia"
    },
    {
        "suburb": "Kinross",
        "postcode": "6028",
        "state": "Western Australia"
    },
    {
        "suburb": "Currambine",
        "postcode": "6028",
        "state": "Western Australia"
    },
    {
        "suburb": "Connolly",
        "postcode": "6027",
        "state": "Western Australia"
    },
    {
        "suburb": "Mullaloo",
        "postcode": "6027",
        "state": "Western Australia"
    },
    {
        "suburb": "Hillarys",
        "postcode": "6025",
        "state": "Western Australia"
    },
    {
        "suburb": "Sorrento",
        "postcode": "6020",
        "state": "Western Australia"
    },
    {
        "suburb": "Kallaroo",
        "postcode": "6025",
        "state": "Western Australia"
    },
    {
        "suburb": "Marmion",
        "postcode": "6020",
        "state": "Western Australia"
    },
    {
        "suburb": "Beldon",
        "postcode": "6027",
        "state": "Western Australia"
    },
    {
        "suburb": "Duncraig",
        "postcode": "6023",
        "state": "Western Australia"
    },
    {
        "suburb": "Craigie",
        "postcode": "6025",
        "state": "Western Australia"
    },
    {
        "suburb": "Kingsley",
        "postcode": "6026",
        "state": "Western Australia"
    },
    {
        "suburb": "Woodvale",
        "postcode": "6026",
        "state": "Western Australia"
    },
    {
        "suburb": "Greenwood",
        "postcode": "6024",
        "state": "Western Australia"
    },
    {
        "suburb": "Warwick",
        "postcode": "6024",
        "state": "Western Australia"
    },
    {
        "suburb": "Padbury",
        "postcode": "6025",
        "state": "Western Australia"
    },
    {
        "suburb": "Forrestfield",
        "postcode": "6058",
        "state": "Western Australia"
    },
    {
        "suburb": "High Wycombe",
        "postcode": "6057",
        "state": "Western Australia"
    },
    {
        "suburb": "Maida Vale",
        "postcode": "6057",
        "state": "Western Australia"
    },
    {
        "suburb": "Lesmurdie",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Kalamunda",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Walliston",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Carmel",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Pickering Brook",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Wattle Grove",
        "postcode": "6107",
        "state": "Western Australia"
    },
    {
        "suburb": "Bickley",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Gooseberry Hill",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Piesse Brook",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Canning Mills",
        "postcode": "6111",
        "state": "Western Australia"
    },
    {
        "suburb": "Reservoir",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Hacketts Gully",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Paulls Valley",
        "postcode": "6076",
        "state": "Western Australia"
    },
    {
        "suburb": "Somerville",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Hannans",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "South Kalgoorlie",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Boulder",
        "postcode": "6432",
        "state": "Western Australia"
    },
    {
        "suburb": "West Kalgoorlie",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "West Lamington",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Kalgoorlie",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Victory Heights",
        "postcode": "6432",
        "state": "Western Australia"
    },
    {
        "suburb": "South Boulder",
        "postcode": "6432",
        "state": "Western Australia"
    },
    {
        "suburb": "Lamington",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Piccadilly",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Williamstown",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Mullingar",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Trafalgar",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Brown Hill",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Broadwood",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Karlkurla",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Fimiston",
        "postcode": "6432",
        "state": "Western Australia"
    },
    {
        "suburb": "Kanowna",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Cundeelee",
        "postcode": "6434",
        "state": "Western Australia"
    },
    {
        "suburb": "Zanthus",
        "postcode": "6434",
        "state": "Western Australia"
    },
    {
        "suburb": "Rawlinna",
        "postcode": "6434",
        "state": "Western Australia"
    },
    {
        "suburb": "Yilkari",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Bulong",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Boorara",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Parkeston",
        "postcode": "6434",
        "state": "Western Australia"
    },
    {
        "suburb": "Binduli",
        "postcode": "6430",
        "state": "Western Australia"
    },
    {
        "suburb": "Emu Flat",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Moojebing",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Katanning",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Coblinine",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Ewlyamartup",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Carrolup",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Coyrecup",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Badgebup",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "South Datatine",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "South Glencoe",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Marracoonda",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Pinwernying",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Murdong",
        "postcode": "6317",
        "state": "Western Australia"
    },
    {
        "suburb": "Kellerberrin",
        "postcode": "6410",
        "state": "Western Australia"
    },
    {
        "suburb": "South Doodlakine",
        "postcode": "6411",
        "state": "Western Australia"
    },
    {
        "suburb": "North Kellerberrin",
        "postcode": "6410",
        "state": "Western Australia"
    },
    {
        "suburb": "North Baandee",
        "postcode": "6412",
        "state": "Western Australia"
    },
    {
        "suburb": "Daadenning Creek",
        "postcode": "6410",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Caroline",
        "postcode": "6410",
        "state": "Western Australia"
    },
    {
        "suburb": "Doodlakine",
        "postcode": "6411",
        "state": "Western Australia"
    },
    {
        "suburb": "Nyabing",
        "postcode": "6341",
        "state": "Western Australia"
    },
    {
        "suburb": "Mobrup",
        "postcode": "6395",
        "state": "Western Australia"
    },
    {
        "suburb": "Changerup",
        "postcode": "6394",
        "state": "Western Australia"
    },
    {
        "suburb": "Qualeup",
        "postcode": "6394",
        "state": "Western Australia"
    },
    {
        "suburb": "Ryansbrook",
        "postcode": "6395",
        "state": "Western Australia"
    },
    {
        "suburb": "Orchid Valley",
        "postcode": "6394",
        "state": "Western Australia"
    },
    {
        "suburb": "Muradup",
        "postcode": "6394",
        "state": "Western Australia"
    },
    {
        "suburb": "Cherry Tree Pool",
        "postcode": "6395",
        "state": "Western Australia"
    },
    {
        "suburb": "Kojonup",
        "postcode": "6395",
        "state": "Western Australia"
    },
    {
        "suburb": "Boilup",
        "postcode": "6394",
        "state": "Western Australia"
    },
    {
        "suburb": "Jingalup",
        "postcode": "6395",
        "state": "Western Australia"
    },
    {
        "suburb": "Boscabel",
        "postcode": "6394",
        "state": "Western Australia"
    },
    {
        "suburb": "Karlgarin",
        "postcode": "6358",
        "state": "Western Australia"
    },
    {
        "suburb": "Hyden",
        "postcode": "6359",
        "state": "Western Australia"
    },
    {
        "suburb": "Kondinin",
        "postcode": "6367",
        "state": "Western Australia"
    },
    {
        "suburb": "Badgerin Rock",
        "postcode": "6475",
        "state": "Western Australia"
    },
    {
        "suburb": "Newcarlbeon",
        "postcode": "6475",
        "state": "Western Australia"
    },
    {
        "suburb": "Mollerin",
        "postcode": "6475",
        "state": "Western Australia"
    },
    {
        "suburb": "Booralaming",
        "postcode": "6475",
        "state": "Western Australia"
    },
    {
        "suburb": "Koorda",
        "postcode": "6475",
        "state": "Western Australia"
    },
    {
        "suburb": "Kulja",
        "postcode": "6470",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Margarette",
        "postcode": "6475",
        "state": "Western Australia"
    },
    {
        "suburb": "Dukin",
        "postcode": "6475",
        "state": "Western Australia"
    },
    {
        "suburb": "Jitarning",
        "postcode": "6365",
        "state": "Western Australia"
    },
    {
        "suburb": "Kulin West",
        "postcode": "6365",
        "state": "Western Australia"
    },
    {
        "suburb": "Kulin",
        "postcode": "6365",
        "state": "Western Australia"
    },
    {
        "suburb": "Jilakin",
        "postcode": "6365",
        "state": "Western Australia"
    },
    {
        "suburb": "Pingaring",
        "postcode": "6357",
        "state": "Western Australia"
    },
    {
        "suburb": "Holt Rock",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "Little Italy",
        "postcode": "6359",
        "state": "Western Australia"
    },
    {
        "suburb": "Dudinin",
        "postcode": "6363",
        "state": "Western Australia"
    },
    {
        "suburb": "Calista",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Medina",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Leda",
        "postcode": "6170",
        "state": "Western Australia"
    },
    {
        "suburb": "Orelia",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Wellard",
        "postcode": "6170",
        "state": "Western Australia"
    },
    {
        "suburb": "Parmelia",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "The Spectacles",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Bertram",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Anketell",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Kwinana Town Centre",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Wandi",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Casuarina",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Kwinana Beach",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Naval Base",
        "postcode": "6165",
        "state": "Western Australia"
    },
    {
        "suburb": "Mandogalup",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Postans",
        "postcode": "6167",
        "state": "Western Australia"
    },
    {
        "suburb": "Magenta",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "Varley",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake King",
        "postcode": "6356",
        "state": "Western Australia"
    },
    {
        "suburb": "North Burngup",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "East Newdegate",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Grace",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "Beenong",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Camm",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "North Lake Grace",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "Kuender",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "Mallee Hill",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "South Newdegate",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "Dunn Rock",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Madden",
        "postcode": "6356",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Sheridan",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "South Lake Grace",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "Neendaling",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "Buniche",
        "postcode": "6353",
        "state": "Western Australia"
    },
    {
        "suburb": "Newdegate",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "Neale",
        "postcode": "6440",
        "state": "Western Australia"
    },
    {
        "suburb": "Laverton",
        "postcode": "6440",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Wells",
        "postcode": "6440",
        "state": "Western Australia"
    },
    {
        "suburb": "Cosmo Newbery",
        "postcode": "6440",
        "state": "Western Australia"
    },
    {
        "suburb": "Bandya",
        "postcode": "6440",
        "state": "Western Australia"
    },
    {
        "suburb": "Beadell",
        "postcode": "6440",
        "state": "Western Australia"
    },
    {
        "suburb": "Leinster",
        "postcode": "6437",
        "state": "Western Australia"
    },
    {
        "suburb": "Leonora",
        "postcode": "6438",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Darlot",
        "postcode": "6438",
        "state": "Western Australia"
    },
    {
        "suburb": "Dawesville",
        "postcode": "6211",
        "state": "Western Australia"
    },
    {
        "suburb": "Wannanup",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Falcon",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Halls Head",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Mandurah",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Dudley Park",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Silver Sands",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Coodanup",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "San Remo",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Meadow Springs",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Greenfields",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Madora Bay",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Clifton",
        "postcode": "6211",
        "state": "Western Australia"
    },
    {
        "suburb": "Herron",
        "postcode": "6211",
        "state": "Western Australia"
    },
    {
        "suburb": "Bouvard",
        "postcode": "6211",
        "state": "Western Australia"
    },
    {
        "suburb": "Erskine",
        "postcode": "6210",
        "state": "Western Australia"
    },
    {
        "suburb": "Parklands",
        "postcode": "6180",
        "state": "Western Australia"
    },
    {
        "suburb": "Lakelands",
        "postcode": "6180",
        "state": "Western Australia"
    },
    {
        "suburb": "Windy Harbour",
        "postcode": "6262",
        "state": "Western Australia"
    },
    {
        "suburb": "Yeagarup",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Dixvale",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Pemberton",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Eastbrook",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Manjimup",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Dingup",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Quinninup",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Muir",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Broke",
        "postcode": "6398",
        "state": "Western Australia"
    },
    {
        "suburb": "Mordalup",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Walpole",
        "postcode": "6398",
        "state": "Western Australia"
    },
    {
        "suburb": "Callcup",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Meerup",
        "postcode": "6262",
        "state": "Western Australia"
    },
    {
        "suburb": "Channybearup",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Collins",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Deanmill",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Linfarne",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Crowea",
        "postcode": "6262",
        "state": "Western Australia"
    },
    {
        "suburb": "Northcliffe",
        "postcode": "6262",
        "state": "Western Australia"
    },
    {
        "suburb": "Balbarrup",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Smith Brook",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Middlesex",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "North Walpole",
        "postcode": "6398",
        "state": "Western Australia"
    },
    {
        "suburb": "Jardee",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Shannon",
        "postcode": "6262",
        "state": "Western Australia"
    },
    {
        "suburb": "Perup",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Boorara Brook",
        "postcode": "6262",
        "state": "Western Australia"
    },
    {
        "suburb": "Yanmah",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Glenoran",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Diamond Tree",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Ringbark",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Upper Warren",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Palgarup",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Wilgarrup",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "Beedelup",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Meekatharra",
        "postcode": "6642",
        "state": "Western Australia"
    },
    {
        "suburb": "Kumarina",
        "postcode": "6642",
        "state": "Western Australia"
    },
    {
        "suburb": "Capricorn",
        "postcode": "6642",
        "state": "Western Australia"
    },
    {
        "suburb": "Peak Hill",
        "postcode": "6642",
        "state": "Western Australia"
    },
    {
        "suburb": "Bicton",
        "postcode": "6157",
        "state": "Western Australia"
    },
    {
        "suburb": "Palmyra",
        "postcode": "6157",
        "state": "Western Australia"
    },
    {
        "suburb": "Attadale",
        "postcode": "6156",
        "state": "Western Australia"
    },
    {
        "suburb": "Melville",
        "postcode": "6156",
        "state": "Western Australia"
    },
    {
        "suburb": "Willagee",
        "postcode": "6156",
        "state": "Western Australia"
    },
    {
        "suburb": "Kardinya",
        "postcode": "6163",
        "state": "Western Australia"
    },
    {
        "suburb": "Alfred Cove",
        "postcode": "6154",
        "state": "Western Australia"
    },
    {
        "suburb": "Myaree",
        "postcode": "6154",
        "state": "Western Australia"
    },
    {
        "suburb": "Winthrop",
        "postcode": "6150",
        "state": "Western Australia"
    },
    {
        "suburb": "Booragoon",
        "postcode": "6154",
        "state": "Western Australia"
    },
    {
        "suburb": "Applecross",
        "postcode": "6153",
        "state": "Western Australia"
    },
    {
        "suburb": "Ardross",
        "postcode": "6153",
        "state": "Western Australia"
    },
    {
        "suburb": "Brentwood",
        "postcode": "6153",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Pleasant",
        "postcode": "6153",
        "state": "Western Australia"
    },
    {
        "suburb": "Bateman",
        "postcode": "6150",
        "state": "Western Australia"
    },
    {
        "suburb": "Bull Creek",
        "postcode": "6149",
        "state": "Western Australia"
    },
    {
        "suburb": "Murdoch",
        "postcode": "6150",
        "state": "Western Australia"
    },
    {
        "suburb": "Kookynie",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Menzies",
        "postcode": "6436",
        "state": "Western Australia"
    },
    {
        "suburb": "Plumridge Lakes",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Merredin",
        "postcode": "6415",
        "state": "Western Australia"
    },
    {
        "suburb": "Norpa",
        "postcode": "6415",
        "state": "Western Australia"
    },
    {
        "suburb": "Goomarin",
        "postcode": "6415",
        "state": "Western Australia"
    },
    {
        "suburb": "Burracoppin",
        "postcode": "6421",
        "state": "Western Australia"
    },
    {
        "suburb": "Nokaning",
        "postcode": "6415",
        "state": "Western Australia"
    },
    {
        "suburb": "Hines Hill",
        "postcode": "6413",
        "state": "Western Australia"
    },
    {
        "suburb": "Nangeenan",
        "postcode": "6414",
        "state": "Western Australia"
    },
    {
        "suburb": "Tandegin",
        "postcode": "6415",
        "state": "Western Australia"
    },
    {
        "suburb": "South Burracoppin",
        "postcode": "6421",
        "state": "Western Australia"
    },
    {
        "suburb": "Nukarni",
        "postcode": "6480",
        "state": "Western Australia"
    },
    {
        "suburb": "Korbel",
        "postcode": "6415",
        "state": "Western Australia"
    },
    {
        "suburb": "Muntadgin",
        "postcode": "6420",
        "state": "Western Australia"
    },
    {
        "suburb": "Holmwood",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Mingenew",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Nangetty",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Budd",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Yarragadee",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Lockier",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Ikewa",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Mooriary",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Bundanoon",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Moora",
        "postcode": "6510",
        "state": "Western Australia"
    },
    {
        "suburb": "Watheroo",
        "postcode": "6513",
        "state": "Western Australia"
    },
    {
        "suburb": "Namban",
        "postcode": "6512",
        "state": "Western Australia"
    },
    {
        "suburb": "Miling",
        "postcode": "6575",
        "state": "Western Australia"
    },
    {
        "suburb": "Coomberdale",
        "postcode": "6512",
        "state": "Western Australia"
    },
    {
        "suburb": "Berkshire Valley",
        "postcode": "6510",
        "state": "Western Australia"
    },
    {
        "suburb": "Barberton",
        "postcode": "6510",
        "state": "Western Australia"
    },
    {
        "suburb": "Bindi Bindi",
        "postcode": "6574",
        "state": "Western Australia"
    },
    {
        "suburb": "Gabalong",
        "postcode": "6574",
        "state": "Western Australia"
    },
    {
        "suburb": "Walebing",
        "postcode": "6510",
        "state": "Western Australia"
    },
    {
        "suburb": "Koojan",
        "postcode": "6510",
        "state": "Western Australia"
    },
    {
        "suburb": "Koolanooka",
        "postcode": "6623",
        "state": "Western Australia"
    },
    {
        "suburb": "Morawa",
        "postcode": "6623",
        "state": "Western Australia"
    },
    {
        "suburb": "Pintharuka",
        "postcode": "6623",
        "state": "Western Australia"
    },
    {
        "suburb": "Merkanooka",
        "postcode": "6625",
        "state": "Western Australia"
    },
    {
        "suburb": "Canna",
        "postcode": "6627",
        "state": "Western Australia"
    },
    {
        "suburb": "Mosman Park",
        "postcode": "6012",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Magnet",
        "postcode": "6638",
        "state": "Western Australia"
    },
    {
        "suburb": "Cooladar Hill",
        "postcode": "6638",
        "state": "Western Australia"
    },
    {
        "suburb": "Paynesville",
        "postcode": "6638",
        "state": "Western Australia"
    },
    {
        "suburb": "Remlap",
        "postcode": "6472",
        "state": "Western Australia"
    },
    {
        "suburb": "Tampu",
        "postcode": "6472",
        "state": "Western Australia"
    },
    {
        "suburb": "Gabbin",
        "postcode": "6476",
        "state": "Western Australia"
    },
    {
        "suburb": "Mouroubra",
        "postcode": "6472",
        "state": "Western Australia"
    },
    {
        "suburb": "Cleary",
        "postcode": "6472",
        "state": "Western Australia"
    },
    {
        "suburb": "Bimbijy",
        "postcode": "6472",
        "state": "Western Australia"
    },
    {
        "suburb": "Bencubbin",
        "postcode": "6477",
        "state": "Western Australia"
    },
    {
        "suburb": "Welbungin",
        "postcode": "6477",
        "state": "Western Australia"
    },
    {
        "suburb": "Beacon",
        "postcode": "6472",
        "state": "Western Australia"
    },
    {
        "suburb": "Karroun Hill",
        "postcode": "6472",
        "state": "Western Australia"
    },
    {
        "suburb": "Wilgoyne",
        "postcode": "6479",
        "state": "Western Australia"
    },
    {
        "suburb": "Bonnie Rock",
        "postcode": "6479",
        "state": "Western Australia"
    },
    {
        "suburb": "Barbalin",
        "postcode": "6479",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Brown",
        "postcode": "6479",
        "state": "Western Australia"
    },
    {
        "suburb": "Mukinbudin",
        "postcode": "6479",
        "state": "Western Australia"
    },
    {
        "suburb": "Dandanning",
        "postcode": "6479",
        "state": "Western Australia"
    },
    {
        "suburb": "Wialki",
        "postcode": "6473",
        "state": "Western Australia"
    },
    {
        "suburb": "Wattoning",
        "postcode": "6479",
        "state": "Western Australia"
    },
    {
        "suburb": "Karloning",
        "postcode": "6479",
        "state": "Western Australia"
    },
    {
        "suburb": "North Wialki",
        "postcode": "6473",
        "state": "Western Australia"
    },
    {
        "suburb": "Tenindewa",
        "postcode": "6632",
        "state": "Western Australia"
    },
    {
        "suburb": "Mullewa",
        "postcode": "6630",
        "state": "Western Australia"
    },
    {
        "suburb": "Pindar",
        "postcode": "6631",
        "state": "Western Australia"
    },
    {
        "suburb": "Tardun",
        "postcode": "6628",
        "state": "Western Australia"
    },
    {
        "suburb": "West Casuarinas",
        "postcode": "6630",
        "state": "Western Australia"
    },
    {
        "suburb": "Nunierra",
        "postcode": "6630",
        "state": "Western Australia"
    },
    {
        "suburb": "Wongoondy",
        "postcode": "6630",
        "state": "Western Australia"
    },
    {
        "suburb": "Devils Creek",
        "postcode": "6630",
        "state": "Western Australia"
    },
    {
        "suburb": "Ambania",
        "postcode": "6632",
        "state": "Western Australia"
    },
    {
        "suburb": "Bellevue",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Swan View",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Darlington",
        "postcode": "6070",
        "state": "Western Australia"
    },
    {
        "suburb": "Parkerville",
        "postcode": "6081",
        "state": "Western Australia"
    },
    {
        "suburb": "Stoneville",
        "postcode": "6081",
        "state": "Western Australia"
    },
    {
        "suburb": "Mundaring",
        "postcode": "6073",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Helena",
        "postcode": "6082",
        "state": "Western Australia"
    },
    {
        "suburb": "Wooroloo",
        "postcode": "6558",
        "state": "Western Australia"
    },
    {
        "suburb": "Midvale",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Sawyers Valley",
        "postcode": "6074",
        "state": "Western Australia"
    },
    {
        "suburb": "Helena Valley",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Boya",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Glen Forrest",
        "postcode": "6071",
        "state": "Western Australia"
    },
    {
        "suburb": "Greenmount",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Hovea",
        "postcode": "6071",
        "state": "Western Australia"
    },
    {
        "suburb": "Chidlow",
        "postcode": "6556",
        "state": "Western Australia"
    },
    {
        "suburb": "Bailup",
        "postcode": "6082",
        "state": "Western Australia"
    },
    {
        "suburb": "Mahogany Creek",
        "postcode": "6072",
        "state": "Western Australia"
    },
    {
        "suburb": "Malmalling",
        "postcode": "6556",
        "state": "Western Australia"
    },
    {
        "suburb": "The Lakes",
        "postcode": "6556",
        "state": "Western Australia"
    },
    {
        "suburb": "Gorrie",
        "postcode": "6556",
        "state": "Western Australia"
    },
    {
        "suburb": "Beechina",
        "postcode": "6556",
        "state": "Western Australia"
    },
    {
        "suburb": "Murchison",
        "postcode": "6630",
        "state": "Western Australia"
    },
    {
        "suburb": "South Murchison",
        "postcode": "6635",
        "state": "Western Australia"
    },
    {
        "suburb": "Nerramyne",
        "postcode": "6630",
        "state": "Western Australia"
    },
    {
        "suburb": "East Murchison",
        "postcode": "6640",
        "state": "Western Australia"
    },
    {
        "suburb": "Woolgorong",
        "postcode": "6630",
        "state": "Western Australia"
    },
    {
        "suburb": "Furnissdale",
        "postcode": "6209",
        "state": "Western Australia"
    },
    {
        "suburb": "South Yunderup",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "North Yunderup",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "Pinjarra",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "Dwellingup",
        "postcode": "6213",
        "state": "Western Australia"
    },
    {
        "suburb": "Stake Hill",
        "postcode": "6181",
        "state": "Western Australia"
    },
    {
        "suburb": "Barragup",
        "postcode": "6209",
        "state": "Western Australia"
    },
    {
        "suburb": "West Coolup",
        "postcode": "6214",
        "state": "Western Australia"
    },
    {
        "suburb": "Birchmont",
        "postcode": "6214",
        "state": "Western Australia"
    },
    {
        "suburb": "Point Grey",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "Nirimba",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "Whittaker",
        "postcode": "6207",
        "state": "Western Australia"
    },
    {
        "suburb": "Banksiadale",
        "postcode": "6213",
        "state": "Western Australia"
    },
    {
        "suburb": "Teesdale",
        "postcode": "6213",
        "state": "Western Australia"
    },
    {
        "suburb": "Etmilyn",
        "postcode": "6213",
        "state": "Western Australia"
    },
    {
        "suburb": "Inglehope",
        "postcode": "6213",
        "state": "Western Australia"
    },
    {
        "suburb": "Coolup",
        "postcode": "6214",
        "state": "Western Australia"
    },
    {
        "suburb": "Blythewood",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "Ravenswood",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "Nambeelup",
        "postcode": "6207",
        "state": "Western Australia"
    },
    {
        "suburb": "Fairbridge",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "West Pinjarra",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "North Dandalup",
        "postcode": "6207",
        "state": "Western Australia"
    },
    {
        "suburb": "Meelon",
        "postcode": "6208",
        "state": "Western Australia"
    },
    {
        "suburb": "Myara",
        "postcode": "6207",
        "state": "Western Australia"
    },
    {
        "suburb": "Scott River East",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Jasper",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Nannup",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "Donnelly River",
        "postcode": "6258",
        "state": "Western Australia"
    },
    {
        "suburb": "East Nannup",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "Biddelia",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Carlotta",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "Darradup",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "Jalbarragup",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "Barrabup",
        "postcode": "6275",
        "state": "Western Australia"
    },
    {
        "suburb": "Peerabeelup",
        "postcode": "6260",
        "state": "Western Australia"
    },
    {
        "suburb": "Wadderin",
        "postcode": "6369",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Walker",
        "postcode": "6369",
        "state": "Western Australia"
    },
    {
        "suburb": "West Holleton",
        "postcode": "6369",
        "state": "Western Australia"
    },
    {
        "suburb": "Narembeen",
        "postcode": "6369",
        "state": "Western Australia"
    },
    {
        "suburb": "South Kumminin",
        "postcode": "6368",
        "state": "Western Australia"
    },
    {
        "suburb": "Cramphorne",
        "postcode": "6420",
        "state": "Western Australia"
    },
    {
        "suburb": "Narrogin",
        "postcode": "6312",
        "state": "Western Australia"
    },
    {
        "suburb": "Minigin",
        "postcode": "6312",
        "state": "Western Australia"
    },
    {
        "suburb": "Highbury",
        "postcode": "6313",
        "state": "Western Australia"
    },
    {
        "suburb": "Narrogin Valley",
        "postcode": "6312",
        "state": "Western Australia"
    },
    {
        "suburb": "Yilliminning",
        "postcode": "6312",
        "state": "Western Australia"
    },
    {
        "suburb": "Nomans Lake",
        "postcode": "6312",
        "state": "Western Australia"
    },
    {
        "suburb": "Boundain",
        "postcode": "6312",
        "state": "Western Australia"
    },
    {
        "suburb": "Dumberning",
        "postcode": "6312",
        "state": "Western Australia"
    },
    {
        "suburb": "Hillside",
        "postcode": "6312",
        "state": "Western Australia"
    },
    {
        "suburb": "Dalkeith",
        "postcode": "6009",
        "state": "Western Australia"
    },
    {
        "suburb": "Nedlands",
        "postcode": "6009",
        "state": "Western Australia"
    },
    {
        "suburb": "Shenton Park",
        "postcode": "6008",
        "state": "Western Australia"
    },
    {
        "suburb": "Karrakatta",
        "postcode": "6010",
        "state": "Western Australia"
    },
    {
        "suburb": "Warburton",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Gibson Desert South",
        "postcode": "872",
        "state": "Western Australia"
    },
    {
        "suburb": "Northam",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Wundowie",
        "postcode": "6560",
        "state": "Western Australia"
    },
    {
        "suburb": "Bakers Hill",
        "postcode": "6562",
        "state": "Western Australia"
    },
    {
        "suburb": "Mokine",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Buckland",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Southern Brook",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Meenaar",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Throssell",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Woottating",
        "postcode": "6562",
        "state": "Western Australia"
    },
    {
        "suburb": "Copley",
        "postcode": "6562",
        "state": "Western Australia"
    },
    {
        "suburb": "Clackline",
        "postcode": "6564",
        "state": "Western Australia"
    },
    {
        "suburb": "Katrine",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "Malabaine",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Burlong",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Irishtown",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Jennapullin",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Muluckine",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Spencers Brook",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Muresk",
        "postcode": "6401",
        "state": "Western Australia"
    },
    {
        "suburb": "Grass Valley",
        "postcode": "6403",
        "state": "Western Australia"
    },
    {
        "suburb": "Kalbarri",
        "postcode": "6536",
        "state": "Western Australia"
    },
    {
        "suburb": "Yallabatharra",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "West Binnu",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Ogilvie",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "Northampton",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "Ajana",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "East Bowes",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "Isseka",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "Horrocks",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "Kalbarri National Park",
        "postcode": "6536",
        "state": "Western Australia"
    },
    {
        "suburb": "Eurardy",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Sandy Gully",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "Nungarin",
        "postcode": "6490",
        "state": "Western Australia"
    },
    {
        "suburb": "Chandler",
        "postcode": "6490",
        "state": "Western Australia"
    },
    {
        "suburb": "Talgomine",
        "postcode": "6490",
        "state": "Western Australia"
    },
    {
        "suburb": "Burran Rock",
        "postcode": "6490",
        "state": "Western Australia"
    },
    {
        "suburb": "Elabbin",
        "postcode": "6490",
        "state": "Western Australia"
    },
    {
        "suburb": "Peppermint Grove",
        "postcode": "6011",
        "state": "Western Australia"
    },
    {
        "suburb": "Bunjil",
        "postcode": "6620",
        "state": "Western Australia"
    },
    {
        "suburb": "Perenjori",
        "postcode": "6620",
        "state": "Western Australia"
    },
    {
        "suburb": "Rothsay",
        "postcode": "6620",
        "state": "Western Australia"
    },
    {
        "suburb": "Latham",
        "postcode": "6616",
        "state": "Western Australia"
    },
    {
        "suburb": "Bowgada",
        "postcode": "6623",
        "state": "Western Australia"
    },
    {
        "suburb": "Perth",
        "postcode": "6000",
        "state": "Western Australia"
    },
    {
        "suburb": "West Perth",
        "postcode": "6005",
        "state": "Western Australia"
    },
    {
        "suburb": "Northbridge",
        "postcode": "6003",
        "state": "Western Australia"
    },
    {
        "suburb": "East Perth",
        "postcode": "6004",
        "state": "Western Australia"
    },
    {
        "suburb": "Crawley",
        "postcode": "6009",
        "state": "Western Australia"
    },
    {
        "suburb": "Kings Park",
        "postcode": "6005",
        "state": "Western Australia"
    },
    {
        "suburb": "Pingelly",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "East Pingelly",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "West Pingelly",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "Kendenup",
        "postcode": "6323",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Barker",
        "postcode": "6324",
        "state": "Western Australia"
    },
    {
        "suburb": "Narrikup",
        "postcode": "6326",
        "state": "Western Australia"
    },
    {
        "suburb": "Woogenellup",
        "postcode": "6324",
        "state": "Western Australia"
    },
    {
        "suburb": "Takalarup",
        "postcode": "6324",
        "state": "Western Australia"
    },
    {
        "suburb": "Forest Hill",
        "postcode": "6324",
        "state": "Western Australia"
    },
    {
        "suburb": "Perillup",
        "postcode": "6324",
        "state": "Western Australia"
    },
    {
        "suburb": "Rocky Gully",
        "postcode": "6397",
        "state": "Western Australia"
    },
    {
        "suburb": "Porongurup",
        "postcode": "6324",
        "state": "Western Australia"
    },
    {
        "suburb": "Denbarker",
        "postcode": "6324",
        "state": "Western Australia"
    },
    {
        "suburb": "Balla Balla",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Mundabullangana",
        "postcode": "6721",
        "state": "Western Australia"
    },
    {
        "suburb": "Boodarie",
        "postcode": "6722",
        "state": "Western Australia"
    },
    {
        "suburb": "Port Hedland",
        "postcode": "6721",
        "state": "Western Australia"
    },
    {
        "suburb": "South Hedland",
        "postcode": "6722",
        "state": "Western Australia"
    },
    {
        "suburb": "Pippingarra",
        "postcode": "6722",
        "state": "Western Australia"
    },
    {
        "suburb": "De Grey",
        "postcode": "6722",
        "state": "Western Australia"
    },
    {
        "suburb": "Wedgefield",
        "postcode": "6721",
        "state": "Western Australia"
    },
    {
        "suburb": "Indee",
        "postcode": "6721",
        "state": "Western Australia"
    },
    {
        "suburb": "Wallareenya",
        "postcode": "6721",
        "state": "Western Australia"
    },
    {
        "suburb": "Redbank",
        "postcode": "6721",
        "state": "Western Australia"
    },
    {
        "suburb": "Finucane",
        "postcode": "6722",
        "state": "Western Australia"
    },
    {
        "suburb": "Wamenusking",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "South Quairading",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Stirling",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Doodenanning",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Badjaling",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Quairading",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Yoting",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Cubbine",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Pantapin",
        "postcode": "6384",
        "state": "Western Australia"
    },
    {
        "suburb": "Dulbelling",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Dangin",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Ravensthorpe",
        "postcode": "6346",
        "state": "Western Australia"
    },
    {
        "suburb": "Hopetoun",
        "postcode": "6348",
        "state": "Western Australia"
    },
    {
        "suburb": "Munglinup",
        "postcode": "6450",
        "state": "Western Australia"
    },
    {
        "suburb": "Fitzgerald River National Park",
        "postcode": "6346",
        "state": "Western Australia"
    },
    {
        "suburb": "Jerdacuttup",
        "postcode": "6346",
        "state": "Western Australia"
    },
    {
        "suburb": "West River",
        "postcode": "6346",
        "state": "Western Australia"
    },
    {
        "suburb": "Fitzgerald",
        "postcode": "6337",
        "state": "Western Australia"
    },
    {
        "suburb": "Hatter Hill",
        "postcode": "6356",
        "state": "Western Australia"
    },
    {
        "suburb": "Shoalwater",
        "postcode": "6169",
        "state": "Western Australia"
    },
    {
        "suburb": "Rockingham",
        "postcode": "6168",
        "state": "Western Australia"
    },
    {
        "suburb": "Safety Bay",
        "postcode": "6169",
        "state": "Western Australia"
    },
    {
        "suburb": "Waikiki",
        "postcode": "6169",
        "state": "Western Australia"
    },
    {
        "suburb": "Port Kennedy",
        "postcode": "6172",
        "state": "Western Australia"
    },
    {
        "suburb": "Warnbro",
        "postcode": "6169",
        "state": "Western Australia"
    },
    {
        "suburb": "Cooloongup",
        "postcode": "6168",
        "state": "Western Australia"
    },
    {
        "suburb": "Golden Bay",
        "postcode": "6174",
        "state": "Western Australia"
    },
    {
        "suburb": "Secret Harbour",
        "postcode": "6173",
        "state": "Western Australia"
    },
    {
        "suburb": "Singleton",
        "postcode": "6175",
        "state": "Western Australia"
    },
    {
        "suburb": "Baldivis",
        "postcode": "6171",
        "state": "Western Australia"
    },
    {
        "suburb": "East Rockingham",
        "postcode": "6168",
        "state": "Western Australia"
    },
    {
        "suburb": "Hillman",
        "postcode": "6168",
        "state": "Western Australia"
    },
    {
        "suburb": "Karnup",
        "postcode": "6176",
        "state": "Western Australia"
    },
    {
        "suburb": "Keralup",
        "postcode": "6182",
        "state": "Western Australia"
    },
    {
        "suburb": "Peron",
        "postcode": "6168",
        "state": "Western Australia"
    },
    {
        "suburb": "Dampier Archipelago",
        "postcode": "6713",
        "state": "Western Australia"
    },
    {
        "suburb": "Maitland",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Nickol",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Sherlock",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Karratha Industrial Estate",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Bulgarra",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Anketell",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Roebourne",
        "postcode": "6718",
        "state": "Western Australia"
    },
    {
        "suburb": "Point Samson",
        "postcode": "6720",
        "state": "Western Australia"
    },
    {
        "suburb": "Cossack",
        "postcode": "6720",
        "state": "Western Australia"
    },
    {
        "suburb": "Stove Hill",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Wickham",
        "postcode": "6720",
        "state": "Western Australia"
    },
    {
        "suburb": "Dampier",
        "postcode": "6713",
        "state": "Western Australia"
    },
    {
        "suburb": "Pegs Creek",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Millars Well",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Baynton",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Karratha",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Gnoorea",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Whim Creek",
        "postcode": "6718",
        "state": "Western Australia"
    },
    {
        "suburb": "Mulataga",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Burrup",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Cleaverville",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Antonymyre",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Gap Ridge",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Cooya Pooya",
        "postcode": "6714",
        "state": "Western Australia"
    },
    {
        "suburb": "Sandstone",
        "postcode": "6639",
        "state": "Western Australia"
    },
    {
        "suburb": "Mundijong",
        "postcode": "6123",
        "state": "Western Australia"
    },
    {
        "suburb": "Byford",
        "postcode": "6122",
        "state": "Western Australia"
    },
    {
        "suburb": "Keysbrook",
        "postcode": "6126",
        "state": "Western Australia"
    },
    {
        "suburb": "Oldbury",
        "postcode": "6121",
        "state": "Western Australia"
    },
    {
        "suburb": "Jarrahdale",
        "postcode": "6124",
        "state": "Western Australia"
    },
    {
        "suburb": "Serpentine",
        "postcode": "6125",
        "state": "Western Australia"
    },
    {
        "suburb": "Mardella",
        "postcode": "6125",
        "state": "Western Australia"
    },
    {
        "suburb": "Cardup",
        "postcode": "6122",
        "state": "Western Australia"
    },
    {
        "suburb": "Hopeland",
        "postcode": "6125",
        "state": "Western Australia"
    },
    {
        "suburb": "Oakford",
        "postcode": "6121",
        "state": "Western Australia"
    },
    {
        "suburb": "Darling Downs",
        "postcode": "6122",
        "state": "Western Australia"
    },
    {
        "suburb": "Whitby",
        "postcode": "6123",
        "state": "Western Australia"
    },
    {
        "suburb": "Karrakup",
        "postcode": "6122",
        "state": "Western Australia"
    },
    {
        "suburb": "Shark Bay",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Denham",
        "postcode": "6537",
        "state": "Western Australia"
    },
    {
        "suburb": "Meadow",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Dirk Hartog Island",
        "postcode": "6537",
        "state": "Western Australia"
    },
    {
        "suburb": "Useless Loop",
        "postcode": "6537",
        "state": "Western Australia"
    },
    {
        "suburb": "Carbla",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Nerren Nerren",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Toolonga",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Woodleigh",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Coburn",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Gilroyd",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Talisker",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Yalardy",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Hamelin Pool",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Tamala",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Nanga",
        "postcode": "6537",
        "state": "Western Australia"
    },
    {
        "suburb": "South Perth",
        "postcode": "6151",
        "state": "Western Australia"
    },
    {
        "suburb": "Como",
        "postcode": "6152",
        "state": "Western Australia"
    },
    {
        "suburb": "Manning",
        "postcode": "6152",
        "state": "Western Australia"
    },
    {
        "suburb": "Kensington",
        "postcode": "6151",
        "state": "Western Australia"
    },
    {
        "suburb": "Salter Point",
        "postcode": "6152",
        "state": "Western Australia"
    },
    {
        "suburb": "Karawara",
        "postcode": "6152",
        "state": "Western Australia"
    },
    {
        "suburb": "Waterford",
        "postcode": "6152",
        "state": "Western Australia"
    },
    {
        "suburb": "Stirling",
        "postcode": "6021",
        "state": "Western Australia"
    },
    {
        "suburb": "Dianella",
        "postcode": "6059",
        "state": "Western Australia"
    },
    {
        "suburb": "Balcatta",
        "postcode": "6021",
        "state": "Western Australia"
    },
    {
        "suburb": "Glendalough",
        "postcode": "6016",
        "state": "Western Australia"
    },
    {
        "suburb": "Osborne Park",
        "postcode": "6017",
        "state": "Western Australia"
    },
    {
        "suburb": "Balga",
        "postcode": "6061",
        "state": "Western Australia"
    },
    {
        "suburb": "Joondanna",
        "postcode": "6060",
        "state": "Western Australia"
    },
    {
        "suburb": "Tuart Hill",
        "postcode": "6060",
        "state": "Western Australia"
    },
    {
        "suburb": "Nollamara",
        "postcode": "6061",
        "state": "Western Australia"
    },
    {
        "suburb": "Yokine",
        "postcode": "6060",
        "state": "Western Australia"
    },
    {
        "suburb": "Westminster",
        "postcode": "6061",
        "state": "Western Australia"
    },
    {
        "suburb": "Hamersley",
        "postcode": "6022",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Hawthorn",
        "postcode": "6016",
        "state": "Western Australia"
    },
    {
        "suburb": "Mirrabooka",
        "postcode": "6061",
        "state": "Western Australia"
    },
    {
        "suburb": "Herdsman",
        "postcode": "6017",
        "state": "Western Australia"
    },
    {
        "suburb": "Innaloo",
        "postcode": "6018",
        "state": "Western Australia"
    },
    {
        "suburb": "North Beach",
        "postcode": "6020",
        "state": "Western Australia"
    },
    {
        "suburb": "Watermans Bay",
        "postcode": "6020",
        "state": "Western Australia"
    },
    {
        "suburb": "Trigg",
        "postcode": "6029",
        "state": "Western Australia"
    },
    {
        "suburb": "Scarborough",
        "postcode": "6019",
        "state": "Western Australia"
    },
    {
        "suburb": "Karrinyup",
        "postcode": "6018",
        "state": "Western Australia"
    },
    {
        "suburb": "Carine",
        "postcode": "6020",
        "state": "Western Australia"
    },
    {
        "suburb": "Doubleview",
        "postcode": "6018",
        "state": "Western Australia"
    },
    {
        "suburb": "Woodlands",
        "postcode": "6018",
        "state": "Western Australia"
    },
    {
        "suburb": "Gwelup",
        "postcode": "6018",
        "state": "Western Australia"
    },
    {
        "suburb": "Churchlands",
        "postcode": "6018",
        "state": "Western Australia"
    },
    {
        "suburb": "Coolbinia",
        "postcode": "6050",
        "state": "Western Australia"
    },
    {
        "suburb": "Menora",
        "postcode": "6050",
        "state": "Western Australia"
    },
    {
        "suburb": "Inglewood",
        "postcode": "6052",
        "state": "Western Australia"
    },
    {
        "suburb": "Daglish",
        "postcode": "6008",
        "state": "Western Australia"
    },
    {
        "suburb": "Ballajura",
        "postcode": "6066",
        "state": "Western Australia"
    },
    {
        "suburb": "Malaga",
        "postcode": "6090",
        "state": "Western Australia"
    },
    {
        "suburb": "Beechboro",
        "postcode": "6063",
        "state": "Western Australia"
    },
    {
        "suburb": "Bennett Springs",
        "postcode": "6063",
        "state": "Western Australia"
    },
    {
        "suburb": "Lockridge",
        "postcode": "6054",
        "state": "Western Australia"
    },
    {
        "suburb": "Ellenbrook",
        "postcode": "6069",
        "state": "Western Australia"
    },
    {
        "suburb": "Caversham",
        "postcode": "6055",
        "state": "Western Australia"
    },
    {
        "suburb": "Woodbridge",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "The Vines",
        "postcode": "6069",
        "state": "Western Australia"
    },
    {
        "suburb": "Midland",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Middle Swan",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Kiara",
        "postcode": "6054",
        "state": "Western Australia"
    },
    {
        "suburb": "Hazelmere",
        "postcode": "6055",
        "state": "Western Australia"
    },
    {
        "suburb": "South Guildford",
        "postcode": "6055",
        "state": "Western Australia"
    },
    {
        "suburb": "Perth Airport",
        "postcode": "6105",
        "state": "Western Australia"
    },
    {
        "suburb": "Koongamia",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Red Hill",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Gidgegannup",
        "postcode": "6083",
        "state": "Western Australia"
    },
    {
        "suburb": "Avon Valley National Park",
        "postcode": "6084",
        "state": "Western Australia"
    },
    {
        "suburb": "Bullsbrook",
        "postcode": "6084",
        "state": "Western Australia"
    },
    {
        "suburb": "Walyunga National Park",
        "postcode": "6084",
        "state": "Western Australia"
    },
    {
        "suburb": "Melaleuca",
        "postcode": "6079",
        "state": "Western Australia"
    },
    {
        "suburb": "Upper Swan",
        "postcode": "6069",
        "state": "Western Australia"
    },
    {
        "suburb": "Belhus",
        "postcode": "6069",
        "state": "Western Australia"
    },
    {
        "suburb": "Baskerville",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Millendon",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Aveley",
        "postcode": "6069",
        "state": "Western Australia"
    },
    {
        "suburb": "Henley Brook",
        "postcode": "6055",
        "state": "Western Australia"
    },
    {
        "suburb": "West Swan",
        "postcode": "6055",
        "state": "Western Australia"
    },
    {
        "suburb": "Cullacabardee",
        "postcode": "6067",
        "state": "Western Australia"
    },
    {
        "suburb": "Lexia",
        "postcode": "6079",
        "state": "Western Australia"
    },
    {
        "suburb": "Whiteman",
        "postcode": "6068",
        "state": "Western Australia"
    },
    {
        "suburb": "Dayton",
        "postcode": "6055",
        "state": "Western Australia"
    },
    {
        "suburb": "Guildford",
        "postcode": "6055",
        "state": "Western Australia"
    },
    {
        "suburb": "Viveash",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Stratton",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Jane Brook",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Brigadoon",
        "postcode": "6069",
        "state": "Western Australia"
    },
    {
        "suburb": "Brabham",
        "postcode": "6055",
        "state": "Western Australia"
    },
    {
        "suburb": "Herne Hill",
        "postcode": "6056",
        "state": "Western Australia"
    },
    {
        "suburb": "Wansbrough",
        "postcode": "6320",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Toolbrunup",
        "postcode": "6320",
        "state": "Western Australia"
    },
    {
        "suburb": "Tambellup",
        "postcode": "6320",
        "state": "Western Australia"
    },
    {
        "suburb": "Bobalong",
        "postcode": "6320",
        "state": "Western Australia"
    },
    {
        "suburb": "Borderdale",
        "postcode": "6320",
        "state": "Western Australia"
    },
    {
        "suburb": "Moonies Hill",
        "postcode": "6320",
        "state": "Western Australia"
    },
    {
        "suburb": "Dartnall",
        "postcode": "6320",
        "state": "Western Australia"
    },
    {
        "suburb": "North Tammin",
        "postcode": "6409",
        "state": "Western Australia"
    },
    {
        "suburb": "South Tammin",
        "postcode": "6409",
        "state": "Western Australia"
    },
    {
        "suburb": "Tammin",
        "postcode": "6409",
        "state": "Western Australia"
    },
    {
        "suburb": "Three Springs",
        "postcode": "6519",
        "state": "Western Australia"
    },
    {
        "suburb": "Dudawa",
        "postcode": "6519",
        "state": "Western Australia"
    },
    {
        "suburb": "Kadathinni",
        "postcode": "6519",
        "state": "Western Australia"
    },
    {
        "suburb": "Arrowsmith East",
        "postcode": "6519",
        "state": "Western Australia"
    },
    {
        "suburb": "Womarden",
        "postcode": "6519",
        "state": "Western Australia"
    },
    {
        "suburb": "Toodyay",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "Wattening",
        "postcode": "6568",
        "state": "Western Australia"
    },
    {
        "suburb": "Dewars Pool",
        "postcode": "6567",
        "state": "Western Australia"
    },
    {
        "suburb": "Culham",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "Bejoording",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "Nunile",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "West Toodyay",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "Coondle",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "Julimar",
        "postcode": "6567",
        "state": "Western Australia"
    },
    {
        "suburb": "Moondyne",
        "postcode": "6567",
        "state": "Western Australia"
    },
    {
        "suburb": "Morangup",
        "postcode": "6083",
        "state": "Western Australia"
    },
    {
        "suburb": "Hoddys Well",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "Dumbarton",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "South Kununoppin",
        "postcode": "6489",
        "state": "Western Australia"
    },
    {
        "suburb": "Kununoppin",
        "postcode": "6489",
        "state": "Western Australia"
    },
    {
        "suburb": "Trayning",
        "postcode": "6488",
        "state": "Western Australia"
    },
    {
        "suburb": "Yelbeni",
        "postcode": "6487",
        "state": "Western Australia"
    },
    {
        "suburb": "North Yelbeni",
        "postcode": "6487",
        "state": "Western Australia"
    },
    {
        "suburb": "South Yelbeni",
        "postcode": "6487",
        "state": "Western Australia"
    },
    {
        "suburb": "North Trayning",
        "postcode": "6488",
        "state": "Western Australia"
    },
    {
        "suburb": "North Kununoppin",
        "postcode": "6489",
        "state": "Western Australia"
    },
    {
        "suburb": "South Trayning",
        "postcode": "6488",
        "state": "Western Australia"
    },
    {
        "suburb": "West Lyons River",
        "postcode": "6705",
        "state": "Western Australia"
    },
    {
        "suburb": "Gascoyne Junction",
        "postcode": "6705",
        "state": "Western Australia"
    },
    {
        "suburb": "Gascoyne River",
        "postcode": "6705",
        "state": "Western Australia"
    },
    {
        "suburb": "Victoria Park",
        "postcode": "6100",
        "state": "Western Australia"
    },
    {
        "suburb": "East Victoria Park",
        "postcode": "6101",
        "state": "Western Australia"
    },
    {
        "suburb": "Lathlain",
        "postcode": "6100",
        "state": "Western Australia"
    },
    {
        "suburb": "Carlisle",
        "postcode": "6101",
        "state": "Western Australia"
    },
    {
        "suburb": "Burswood",
        "postcode": "6100",
        "state": "Western Australia"
    },
    {
        "suburb": "Waddington",
        "postcode": "6509",
        "state": "Western Australia"
    },
    {
        "suburb": "Old Plains",
        "postcode": "6569",
        "state": "Western Australia"
    },
    {
        "suburb": "Wyening",
        "postcode": "6568",
        "state": "Western Australia"
    },
    {
        "suburb": "Gillingarra",
        "postcode": "6510",
        "state": "Western Australia"
    },
    {
        "suburb": "Yerecoin",
        "postcode": "6571",
        "state": "Western Australia"
    },
    {
        "suburb": "Carani",
        "postcode": "6566",
        "state": "Western Australia"
    },
    {
        "suburb": "Calingiri",
        "postcode": "6569",
        "state": "Western Australia"
    },
    {
        "suburb": "Bolgart",
        "postcode": "6568",
        "state": "Western Australia"
    },
    {
        "suburb": "Mogumber",
        "postcode": "6506",
        "state": "Western Australia"
    },
    {
        "suburb": "New Norcia",
        "postcode": "6509",
        "state": "Western Australia"
    },
    {
        "suburb": "Piawaning",
        "postcode": "6572",
        "state": "Western Australia"
    },
    {
        "suburb": "Yarawindah",
        "postcode": "6509",
        "state": "Western Australia"
    },
    {
        "suburb": "Glentromie",
        "postcode": "6509",
        "state": "Western Australia"
    },
    {
        "suburb": "Leederville",
        "postcode": "6007",
        "state": "Western Australia"
    },
    {
        "suburb": "North Perth",
        "postcode": "6006",
        "state": "Western Australia"
    },
    {
        "suburb": "Highgate",
        "postcode": "6003",
        "state": "Western Australia"
    },
    {
        "suburb": "Lime Lake",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Wagin",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Ballaying",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Jaloran",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Wedgecarrup",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Piesseville",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Minding",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Collanilling",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Cancanning",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Gundaring",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Wandering",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "Dwarda",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "Codjatotine",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "Pumphreys Bridge",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "Hastings",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "Springs",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "Wanneroo",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Mariginiup",
        "postcode": "6078",
        "state": "Western Australia"
    },
    {
        "suburb": "Hocking",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Jandabup",
        "postcode": "6077",
        "state": "Western Australia"
    },
    {
        "suburb": "Carramar",
        "postcode": "6031",
        "state": "Western Australia"
    },
    {
        "suburb": "Tapping",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Pearsall",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Sinagra",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Pinjar",
        "postcode": "6078",
        "state": "Western Australia"
    },
    {
        "suburb": "Carabooda",
        "postcode": "6033",
        "state": "Western Australia"
    },
    {
        "suburb": "Nowergup",
        "postcode": "6032",
        "state": "Western Australia"
    },
    {
        "suburb": "Neerabup",
        "postcode": "6031",
        "state": "Western Australia"
    },
    {
        "suburb": "Gnangara",
        "postcode": "6077",
        "state": "Western Australia"
    },
    {
        "suburb": "Banksia Grove",
        "postcode": "6031",
        "state": "Western Australia"
    },
    {
        "suburb": "Ashby",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Yanchep",
        "postcode": "6035",
        "state": "Western Australia"
    },
    {
        "suburb": "Quinns Rocks",
        "postcode": "6030",
        "state": "Western Australia"
    },
    {
        "suburb": "Ridgewood",
        "postcode": "6030",
        "state": "Western Australia"
    },
    {
        "suburb": "Clarkson",
        "postcode": "6030",
        "state": "Western Australia"
    },
    {
        "suburb": "Mindarie",
        "postcode": "6030",
        "state": "Western Australia"
    },
    {
        "suburb": "Two Rocks",
        "postcode": "6037",
        "state": "Western Australia"
    },
    {
        "suburb": "Eglinton",
        "postcode": "6034",
        "state": "Western Australia"
    },
    {
        "suburb": "Butler",
        "postcode": "6036",
        "state": "Western Australia"
    },
    {
        "suburb": "Tamala Park",
        "postcode": "6030",
        "state": "Western Australia"
    },
    {
        "suburb": "Jindalee",
        "postcode": "6036",
        "state": "Western Australia"
    },
    {
        "suburb": "Merriwa",
        "postcode": "6030",
        "state": "Western Australia"
    },
    {
        "suburb": "Marangaroo",
        "postcode": "6064",
        "state": "Western Australia"
    },
    {
        "suburb": "Girrawheen",
        "postcode": "6064",
        "state": "Western Australia"
    },
    {
        "suburb": "Koondoola",
        "postcode": "6064",
        "state": "Western Australia"
    },
    {
        "suburb": "Alexander Heights",
        "postcode": "6064",
        "state": "Western Australia"
    },
    {
        "suburb": "Landsdale",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Madeley",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Darch",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Wangara",
        "postcode": "6065",
        "state": "Western Australia"
    },
    {
        "suburb": "Preston Beach",
        "postcode": "6215",
        "state": "Western Australia"
    },
    {
        "suburb": "Waroona",
        "postcode": "6215",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Clifton",
        "postcode": "6215",
        "state": "Western Australia"
    },
    {
        "suburb": "Wagerup",
        "postcode": "6215",
        "state": "Western Australia"
    },
    {
        "suburb": "Nanga Brook",
        "postcode": "6215",
        "state": "Western Australia"
    },
    {
        "suburb": "Hamel",
        "postcode": "6215",
        "state": "Western Australia"
    },
    {
        "suburb": "Darkan",
        "postcode": "6392",
        "state": "Western Australia"
    },
    {
        "suburb": "Bowelling",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Moodiarrup",
        "postcode": "6393",
        "state": "Western Australia"
    },
    {
        "suburb": "Mokup",
        "postcode": "6394",
        "state": "Western Australia"
    },
    {
        "suburb": "Trigwell",
        "postcode": "6244",
        "state": "Western Australia"
    },
    {
        "suburb": "Bokal",
        "postcode": "6392",
        "state": "Western Australia"
    },
    {
        "suburb": "Duranillin",
        "postcode": "6393",
        "state": "Western Australia"
    },
    {
        "suburb": "Warrachuppin",
        "postcode": "6423",
        "state": "Western Australia"
    },
    {
        "suburb": "Westonia",
        "postcode": "6423",
        "state": "Western Australia"
    },
    {
        "suburb": "Carrabin",
        "postcode": "6423",
        "state": "Western Australia"
    },
    {
        "suburb": "Walgoolan",
        "postcode": "6422",
        "state": "Western Australia"
    },
    {
        "suburb": "Elachbutting",
        "postcode": "6479",
        "state": "Western Australia"
    },
    {
        "suburb": "Boodarockin",
        "postcode": "6423",
        "state": "Western Australia"
    },
    {
        "suburb": "Warralakin",
        "postcode": "6421",
        "state": "Western Australia"
    },
    {
        "suburb": "Toolibin",
        "postcode": "6312",
        "state": "Western Australia"
    },
    {
        "suburb": "Tincurrin",
        "postcode": "6361",
        "state": "Western Australia"
    },
    {
        "suburb": "Wickepin",
        "postcode": "6370",
        "state": "Western Australia"
    },
    {
        "suburb": "Yealering",
        "postcode": "6372",
        "state": "Western Australia"
    },
    {
        "suburb": "Malyalling",
        "postcode": "6370",
        "state": "Western Australia"
    },
    {
        "suburb": "Harrismith",
        "postcode": "6361",
        "state": "Western Australia"
    },
    {
        "suburb": "Gillimanning",
        "postcode": "6308",
        "state": "Western Australia"
    },
    {
        "suburb": "East Wickepin",
        "postcode": "6370",
        "state": "Western Australia"
    },
    {
        "suburb": "Kirk Rock",
        "postcode": "6372",
        "state": "Western Australia"
    },
    {
        "suburb": "Wogolin",
        "postcode": "6370",
        "state": "Western Australia"
    },
    {
        "suburb": "Williams",
        "postcode": "6391",
        "state": "Western Australia"
    },
    {
        "suburb": "Dardadine",
        "postcode": "6392",
        "state": "Western Australia"
    },
    {
        "suburb": "Meeking",
        "postcode": "6392",
        "state": "Western Australia"
    },
    {
        "suburb": "Wiluna",
        "postcode": "6646",
        "state": "Western Australia"
    },
    {
        "suburb": "Little Sandy Desert",
        "postcode": "6646",
        "state": "Western Australia"
    },
    {
        "suburb": "Wongan Hills",
        "postcode": "6603",
        "state": "Western Australia"
    },
    {
        "suburb": "West Ballidu",
        "postcode": "6606",
        "state": "Western Australia"
    },
    {
        "suburb": "Mocardy",
        "postcode": "6603",
        "state": "Western Australia"
    },
    {
        "suburb": "East Ballidu",
        "postcode": "6606",
        "state": "Western Australia"
    },
    {
        "suburb": "Cadoux",
        "postcode": "6466",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Ninan",
        "postcode": "6603",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Hinds",
        "postcode": "6603",
        "state": "Western Australia"
    },
    {
        "suburb": "Kondut",
        "postcode": "6605",
        "state": "Western Australia"
    },
    {
        "suburb": "Ballidu",
        "postcode": "6606",
        "state": "Western Australia"
    },
    {
        "suburb": "Westwood",
        "postcode": "6316",
        "state": "Western Australia"
    },
    {
        "suburb": "Cartmeticup",
        "postcode": "6316",
        "state": "Western Australia"
    },
    {
        "suburb": "Kenmare",
        "postcode": "6316",
        "state": "Western Australia"
    },
    {
        "suburb": "Boyerine",
        "postcode": "6316",
        "state": "Western Australia"
    },
    {
        "suburb": "Woodanilling",
        "postcode": "6316",
        "state": "Western Australia"
    },
    {
        "suburb": "Glencoe",
        "postcode": "6316",
        "state": "Western Australia"
    },
    {
        "suburb": "Beaufort River",
        "postcode": "6394",
        "state": "Western Australia"
    },
    {
        "suburb": "Wyalkatchem",
        "postcode": "6485",
        "state": "Western Australia"
    },
    {
        "suburb": "Korrelocking",
        "postcode": "6485",
        "state": "Western Australia"
    },
    {
        "suburb": "Cowcowing",
        "postcode": "6485",
        "state": "Western Australia"
    },
    {
        "suburb": "Nalkain",
        "postcode": "6485",
        "state": "Western Australia"
    },
    {
        "suburb": "Benjaberring",
        "postcode": "6463",
        "state": "Western Australia"
    },
    {
        "suburb": "Nembudding",
        "postcode": "6485",
        "state": "Western Australia"
    },
    {
        "suburb": "Mitchell Plateau",
        "postcode": "6740",
        "state": "Western Australia"
    },
    {
        "suburb": "Prince Regent River",
        "postcode": "6740",
        "state": "Western Australia"
    },
    {
        "suburb": "Durack",
        "postcode": "6743",
        "state": "Western Australia"
    },
    {
        "suburb": "Cambridge Gulf",
        "postcode": "6743",
        "state": "Western Australia"
    },
    {
        "suburb": "Oombulgurri",
        "postcode": "6740",
        "state": "Western Australia"
    },
    {
        "suburb": "Drysdale River",
        "postcode": "6740",
        "state": "Western Australia"
    },
    {
        "suburb": "Wyndham",
        "postcode": "6740",
        "state": "Western Australia"
    },
    {
        "suburb": "Kununurra",
        "postcode": "6743",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Argyle",
        "postcode": "6743",
        "state": "Western Australia"
    },
    {
        "suburb": "Kalumburu",
        "postcode": "6740",
        "state": "Western Australia"
    },
    {
        "suburb": "Gibb",
        "postcode": "6743",
        "state": "Western Australia"
    },
    {
        "suburb": "Yalgoo",
        "postcode": "6635",
        "state": "Western Australia"
    },
    {
        "suburb": "Paynes Find",
        "postcode": "6612",
        "state": "Western Australia"
    },
    {
        "suburb": "Bullfinch",
        "postcode": "6484",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Hampton",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Palmer",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Turkey Hill",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Koolyanobbing",
        "postcode": "6427",
        "state": "Western Australia"
    },
    {
        "suburb": "Yellowdine",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Moorine Rock",
        "postcode": "6425",
        "state": "Western Australia"
    },
    {
        "suburb": "Marvel Loch",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Dulyalbin",
        "postcode": "6425",
        "state": "Western Australia"
    },
    {
        "suburb": "Bodallin",
        "postcode": "6424",
        "state": "Western Australia"
    },
    {
        "suburb": "Corinthia",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Southern Cross",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Skeleton Rock",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Parker Range",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Ghooli",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Holleton",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "South Bodallin",
        "postcode": "6424",
        "state": "Western Australia"
    },
    {
        "suburb": "North Bodallin",
        "postcode": "6424",
        "state": "Western Australia"
    },
    {
        "suburb": "Ennuin",
        "postcode": "6484",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Deborah",
        "postcode": "6484",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Holland",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "York",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Kauring",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Flynn",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Inkpen",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Malebelling",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Daliak",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Balladong",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Cold Harbour",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Gwambygine",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Hardey",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Talbot",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Observation",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "St Ronans",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Wilberforce",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Quellington",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Burges",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Greenhills",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Gilgering",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Narraloggan",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Caljie",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Badgin",
        "postcode": "6302",
        "state": "Western Australia"
    },
    {
        "suburb": "Kurnalpi",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Wells",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "Karijini",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Cardiff",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Lyalls Mill",
        "postcode": "6225",
        "state": "Western Australia"
    },
    {
        "suburb": "Holyoake",
        "postcode": "6213",
        "state": "Western Australia"
    },
    {
        "suburb": "Marrinup",
        "postcode": "6213",
        "state": "Western Australia"
    },
    {
        "suburb": "Ularring",
        "postcode": "6436",
        "state": "Western Australia"
    },
    {
        "suburb": "Alkimos",
        "postcode": "6038",
        "state": "Western Australia"
    },
    {
        "suburb": "Kingsford",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Gibson Desert North",
        "postcode": "872",
        "state": "Western Australia"
    },
    {
        "suburb": "South Yuna",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Hope Valley",
        "postcode": "6165",
        "state": "Western Australia"
    },
    {
        "suburb": "North Bannister",
        "postcode": "6390",
        "state": "Western Australia"
    },
    {
        "suburb": "Pingrup",
        "postcode": "6343",
        "state": "Western Australia"
    },
    {
        "suburb": "Zuytdorp",
        "postcode": "6536",
        "state": "Western Australia"
    },
    {
        "suburb": "Solus",
        "postcode": "6207",
        "state": "Western Australia"
    },
    {
        "suburb": "Forrest",
        "postcode": "6434",
        "state": "Western Australia"
    },
    {
        "suburb": "Karalundi",
        "postcode": "6642",
        "state": "Western Australia"
    },
    {
        "suburb": "South Yilgarn",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Siesta Park",
        "postcode": "6280",
        "state": "Western Australia"
    },
    {
        "suburb": "Yuna",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Bowes",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "Cowalellup",
        "postcode": "6336",
        "state": "Western Australia"
    },
    {
        "suburb": "South Kukerin",
        "postcode": "6352",
        "state": "Western Australia"
    },
    {
        "suburb": "Pallinup",
        "postcode": "6335",
        "state": "Western Australia"
    },
    {
        "suburb": "Waterloo",
        "postcode": "6228",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Biddy",
        "postcode": "6355",
        "state": "Western Australia"
    },
    {
        "suburb": "Arthur River",
        "postcode": "6315",
        "state": "Western Australia"
    },
    {
        "suburb": "Lumeah",
        "postcode": "6395",
        "state": "Western Australia"
    },
    {
        "suburb": "Henty",
        "postcode": "6236",
        "state": "Western Australia"
    },
    {
        "suburb": "Dartmoor",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Wittenoom",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Kwelkan",
        "postcode": "6490",
        "state": "Western Australia"
    },
    {
        "suburb": "Gregory",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "Meru",
        "postcode": "6530",
        "state": "Western Australia"
    },
    {
        "suburb": "Wandana",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Maya",
        "postcode": "6614",
        "state": "Western Australia"
    },
    {
        "suburb": "Greys Plain",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Mundrabilla",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Lake Carnegie",
        "postcode": "6646",
        "state": "Western Australia"
    },
    {
        "suburb": "Strelley",
        "postcode": "6721",
        "state": "Western Australia"
    },
    {
        "suburb": "Mulga Downs",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Pannawonica",
        "postcode": "6716",
        "state": "Western Australia"
    },
    {
        "suburb": "Ngaanyatjarra-Giles",
        "postcode": "872",
        "state": "Western Australia"
    },
    {
        "suburb": "Daggar Hills",
        "postcode": "6638",
        "state": "Western Australia"
    },
    {
        "suburb": "Baandee",
        "postcode": "6412",
        "state": "Western Australia"
    },
    {
        "suburb": "Walyurin",
        "postcode": "6363",
        "state": "Western Australia"
    },
    {
        "suburb": "Angelo River",
        "postcode": "6642",
        "state": "Western Australia"
    },
    {
        "suburb": "Alma",
        "postcode": "6535",
        "state": "Western Australia"
    },
    {
        "suburb": "Mount Jackson",
        "postcode": "6426",
        "state": "Western Australia"
    },
    {
        "suburb": "Benger",
        "postcode": "6223",
        "state": "Western Australia"
    },
    {
        "suburb": "Juna Downs",
        "postcode": "6751",
        "state": "Western Australia"
    },
    {
        "suburb": "Binnu",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Sir Samuel",
        "postcode": "6437",
        "state": "Western Australia"
    },
    {
        "suburb": "Naraling",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Yandanooka",
        "postcode": "6522",
        "state": "Western Australia"
    },
    {
        "suburb": "Balkuling",
        "postcode": "6383",
        "state": "Western Australia"
    },
    {
        "suburb": "Woolocutty",
        "postcode": "6369",
        "state": "Western Australia"
    },
    {
        "suburb": "East Lyons River",
        "postcode": "6705",
        "state": "Western Australia"
    },
    {
        "suburb": "Forrestania",
        "postcode": "6359",
        "state": "Western Australia"
    },
    {
        "suburb": "Coolcalalaya",
        "postcode": "6532",
        "state": "Western Australia"
    },
    {
        "suburb": "Reedy",
        "postcode": "6640",
        "state": "Western Australia"
    },
    {
        "suburb": "Weld Range",
        "postcode": "6640",
        "state": "Western Australia"
    },
    {
        "suburb": "Burakin",
        "postcode": "6467",
        "state": "Western Australia"
    },
    {
        "suburb": "Arrino",
        "postcode": "6519",
        "state": "Western Australia"
    },
    {
        "suburb": "Massey Bay",
        "postcode": "6701",
        "state": "Western Australia"
    },
    {
        "suburb": "Ora Banda",
        "postcode": "6431",
        "state": "Western Australia"
    },
    {
        "suburb": "Gutha",
        "postcode": "6623",
        "state": "Western Australia"
    },
    {
        "suburb": "Widgiemooltha",
        "postcode": "6443",
        "state": "Western Australia"
    },
    {
        "suburb": "Bindoon Training Area",
        "postcode": "6502",
        "state": "Western Australia"
    },
    {
        "suburb": "Beaumaris",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount William",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Stieglitz",
        "postcode": "7216",
        "state": "Tasmania"
    },
    {
        "suburb": "Cornwall",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Mathinna",
        "postcode": "7214",
        "state": "Tasmania"
    },
    {
        "suburb": "Mangana",
        "postcode": "7214",
        "state": "Tasmania"
    },
    {
        "suburb": "Eddystone",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Four Mile Creek",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "The Gardens",
        "postcode": "7216",
        "state": "Tasmania"
    },
    {
        "suburb": "Binalong Bay",
        "postcode": "7216",
        "state": "Tasmania"
    },
    {
        "suburb": "St Marys",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Gray",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Weldborough",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Ansons Bay",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Chain Of Lagoons",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Fingal",
        "postcode": "7214",
        "state": "Tasmania"
    },
    {
        "suburb": "Falmouth",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Akaroa",
        "postcode": "7216",
        "state": "Tasmania"
    },
    {
        "suburb": "Scamander",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "St Helens",
        "postcode": "7216",
        "state": "Tasmania"
    },
    {
        "suburb": "Goshen",
        "postcode": "7216",
        "state": "Tasmania"
    },
    {
        "suburb": "Goulds Country",
        "postcode": "7216",
        "state": "Tasmania"
    },
    {
        "suburb": "Gladstone",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Lottah",
        "postcode": "7216",
        "state": "Tasmania"
    },
    {
        "suburb": "Upper Scamander",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Douglas River",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Seymour",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Royal George",
        "postcode": "7213",
        "state": "Tasmania"
    },
    {
        "suburb": "Douglas-Apsley",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Dromedary",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Herdsmans Cove",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Bridgewater",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Brighton",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Pontville",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Old Beach",
        "postcode": "7017",
        "state": "Tasmania"
    },
    {
        "suburb": "Gagebrook",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Tea Tree",
        "postcode": "7017",
        "state": "Tasmania"
    },
    {
        "suburb": "Honeywood",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Park Grove",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Mooreville",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Acton",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Havenview",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Romaine",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Parklands",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Upper Burnie",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Camdale",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Ocean Vista",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Brooklyn",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "South Burnie",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Heybridge",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "East Cam",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "West Mooreville",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Stowport",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Cooee",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Hillcrest",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Montello",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Emu Heights",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Round Hill",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Chasm Creek",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Shorewell Park",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Wivenhoe",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Burnie",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Downlands",
        "postcode": "7320",
        "state": "Tasmania"
    },
    {
        "suburb": "Hampshire",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Natone",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Ridgley",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Upper Natone",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Oonah",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "West Ridgley",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Upper Stowport",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Tewkesbury",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Highclere",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "East Ridgley",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Parrawe",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Forth",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Leith",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Abbotsham",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Gawler",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "West Ulverstone",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Ulverstone",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Penguin",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "Turners Beach",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Sulphur Creek",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "Preservation Bay",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "Howth",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "North Motton",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Cuprona",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "South Riana",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "Kindred",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Sprent",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Loyetea",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "Castra",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Upper Castra",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Preston",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Nietta",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Riana",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "Camena",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "West Pine",
        "postcode": "7316",
        "state": "Tasmania"
    },
    {
        "suburb": "Gunns Plains",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Loongana",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Spalford",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "South Preston",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Middlesex",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Walls Of Jerusalem",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Central Plateau",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Millers Bluff",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Ellendale",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Florentine",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Ouse",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Little Pine Lagoon",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Gretna",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Meadowbank",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Lake St Clair",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Arthurs Lake",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Bothwell",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Bronte Park",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Wayatinah",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Miena",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Doctors Point",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Tarraleah",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Steppes",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Bradys Lake",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Lake Sorell",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Interlaken",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Strickland",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Waddamana",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Derwent Bridge",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "London Lakes",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Dee",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Osterley",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Melton Mowbray",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Hermitage",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Victoria Valley",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Wilburville",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Shannon",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount Field",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Westerway",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Fentonbury",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "National Park",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Morass Bay",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Pelham",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Butlers Gorge",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Flintstone",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Tods Corner",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Hollow Tree",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Hamilton",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Cramps Bay",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Lower Marshes",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Apsley",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Breona",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Brandum",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Liawenee",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Reynolds Neck",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Southwest",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Montagu",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Brittons Swamp",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Edith Creek",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Alcomie",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Scopus",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Togari",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Woolnorth",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Irishtown",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Hellyer",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Lileah",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Smithton",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Scotchtown",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Marrawah",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Arthur River",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Nelson Bay",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Broadmeadows",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Christmas Hills",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Roger River",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Trowutta",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Nabageena",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "South Forest",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Forest",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Black River",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Wiltshire",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Stanley",
        "postcode": "7331",
        "state": "Tasmania"
    },
    {
        "suburb": "Mengha",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Crayfish Creek",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Cowrie Point",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Port Latta",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Rocky Cape",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Edgcumbe Beach",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Redpa",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "West Coast",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Mawbanna",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Montumana",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Temma",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Milabena",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Couta Rocks",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "West Montagu",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Mella",
        "postcode": "7330",
        "state": "Tasmania"
    },
    {
        "suburb": "Detention",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Sisters Creek",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Meunna",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "West Takone",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Risdon Vale",
        "postcode": "7016",
        "state": "Tasmania"
    },
    {
        "suburb": "Rose Bay",
        "postcode": "7015",
        "state": "Tasmania"
    },
    {
        "suburb": "Geilston Bay",
        "postcode": "7015",
        "state": "Tasmania"
    },
    {
        "suburb": "Montagu Bay",
        "postcode": "7018",
        "state": "Tasmania"
    },
    {
        "suburb": "Lindisfarne",
        "postcode": "7015",
        "state": "Tasmania"
    },
    {
        "suburb": "Bellerive",
        "postcode": "7018",
        "state": "Tasmania"
    },
    {
        "suburb": "Warrane",
        "postcode": "7018",
        "state": "Tasmania"
    },
    {
        "suburb": "Howrah",
        "postcode": "7018",
        "state": "Tasmania"
    },
    {
        "suburb": "Mornington",
        "postcode": "7018",
        "state": "Tasmania"
    },
    {
        "suburb": "South Arm",
        "postcode": "7022",
        "state": "Tasmania"
    },
    {
        "suburb": "Tranmere",
        "postcode": "7018",
        "state": "Tasmania"
    },
    {
        "suburb": "Rokeby",
        "postcode": "7019",
        "state": "Tasmania"
    },
    {
        "suburb": "Clarendon Vale",
        "postcode": "7019",
        "state": "Tasmania"
    },
    {
        "suburb": "Penna",
        "postcode": "7171",
        "state": "Tasmania"
    },
    {
        "suburb": "Richmond",
        "postcode": "7025",
        "state": "Tasmania"
    },
    {
        "suburb": "Opossum Bay",
        "postcode": "7023",
        "state": "Tasmania"
    },
    {
        "suburb": "Sandford",
        "postcode": "7020",
        "state": "Tasmania"
    },
    {
        "suburb": "Clifton Beach",
        "postcode": "7020",
        "state": "Tasmania"
    },
    {
        "suburb": "Cremorne",
        "postcode": "7024",
        "state": "Tasmania"
    },
    {
        "suburb": "Lauderdale",
        "postcode": "7021",
        "state": "Tasmania"
    },
    {
        "suburb": "Roches Beach",
        "postcode": "7170",
        "state": "Tasmania"
    },
    {
        "suburb": "Otago",
        "postcode": "7017",
        "state": "Tasmania"
    },
    {
        "suburb": "Risdon",
        "postcode": "7017",
        "state": "Tasmania"
    },
    {
        "suburb": "Rosny",
        "postcode": "7018",
        "state": "Tasmania"
    },
    {
        "suburb": "Rosny Park",
        "postcode": "7018",
        "state": "Tasmania"
    },
    {
        "suburb": "Oakdowns",
        "postcode": "7019",
        "state": "Tasmania"
    },
    {
        "suburb": "Acton Park",
        "postcode": "7170",
        "state": "Tasmania"
    },
    {
        "suburb": "Seven Mile Beach",
        "postcode": "7170",
        "state": "Tasmania"
    },
    {
        "suburb": "Cambridge",
        "postcode": "7170",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount Rumney",
        "postcode": "7170",
        "state": "Tasmania"
    },
    {
        "suburb": "Grasstree Hill",
        "postcode": "7017",
        "state": "Tasmania"
    },
    {
        "suburb": "Dulcot",
        "postcode": "7025",
        "state": "Tasmania"
    },
    {
        "suburb": "Campania",
        "postcode": "7026",
        "state": "Tasmania"
    },
    {
        "suburb": "Orielton",
        "postcode": "7172",
        "state": "Tasmania"
    },
    {
        "suburb": "New Norfolk",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Magra",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Molesworth",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Granton",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Boyer",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Glenlusk",
        "postcode": "7012",
        "state": "Tasmania"
    },
    {
        "suburb": "Malbina",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Sorell Creek",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Lawitta",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Claremont",
        "postcode": "7011",
        "state": "Tasmania"
    },
    {
        "suburb": "Lachlan",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Black Hills",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Bushy Park",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Glenora",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Maydena",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Tyenna",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount Lloyd",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Uxbridge",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Macquarie Plains",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Moogara",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Plenty",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Wellington Park",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Collinsvale",
        "postcode": "7012",
        "state": "Tasmania"
    },
    {
        "suburb": "Glenfern",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Hayes",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Rosegarland",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Fitzgerald",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Styx",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Karanja",
        "postcode": "7140",
        "state": "Tasmania"
    },
    {
        "suburb": "Broadmarsh",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Miandetta",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Devonport",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Spreyton",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Quoiba",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "East Devonport",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Ambleside",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Latrobe",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Don",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Stony Rise",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Tugrah",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Lillico",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Aberdeen",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Forthside",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Paloona",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Melrose",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Eugenana",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Musselroe Bay",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Derby",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Winnaleah",
        "postcode": "7265",
        "state": "Tasmania"
    },
    {
        "suburb": "Branxholm",
        "postcode": "7261",
        "state": "Tasmania"
    },
    {
        "suburb": "Tayene",
        "postcode": "7259",
        "state": "Tasmania"
    },
    {
        "suburb": "Legerwood",
        "postcode": "7263",
        "state": "Tasmania"
    },
    {
        "suburb": "Ringarooma",
        "postcode": "7263",
        "state": "Tasmania"
    },
    {
        "suburb": "Trenah",
        "postcode": "7263",
        "state": "Tasmania"
    },
    {
        "suburb": "Upper Esk",
        "postcode": "7214",
        "state": "Tasmania"
    },
    {
        "suburb": "Forester",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Waterhouse",
        "postcode": "7262",
        "state": "Tasmania"
    },
    {
        "suburb": "Cape Portland",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Tomahawk",
        "postcode": "7262",
        "state": "Tasmania"
    },
    {
        "suburb": "Bridport",
        "postcode": "7262",
        "state": "Tasmania"
    },
    {
        "suburb": "Pipers Brook",
        "postcode": "7254",
        "state": "Tasmania"
    },
    {
        "suburb": "Golconda",
        "postcode": "7254",
        "state": "Tasmania"
    },
    {
        "suburb": "North Scottsdale",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Scottsdale",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Pioneer",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Wyena",
        "postcode": "7254",
        "state": "Tasmania"
    },
    {
        "suburb": "Banca",
        "postcode": "7265",
        "state": "Tasmania"
    },
    {
        "suburb": "Herrick",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "South Mount Cameron",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Rushy Lagoon",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Moorina",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Alberton",
        "postcode": "7263",
        "state": "Tasmania"
    },
    {
        "suburb": "Talawa",
        "postcode": "7263",
        "state": "Tasmania"
    },
    {
        "suburb": "Warrentinna",
        "postcode": "7261",
        "state": "Tasmania"
    },
    {
        "suburb": "Telita",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Tulendeena",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Kamona",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Cuckoo",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Tonganah",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Springfield",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "South Springfield",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "West Scottsdale",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Nabowla",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Blumont",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Lietinna",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Boobyalla",
        "postcode": "7264",
        "state": "Tasmania"
    },
    {
        "suburb": "Jetsonville",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Palana",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Ranga",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Memana",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Emita",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Whitemark",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Cape Barren Island",
        "postcode": "7257",
        "state": "Tasmania"
    },
    {
        "suburb": "Lackrana",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Blue Rocks",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Lughrata",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Killiecrankie",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Leeka",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Strzelecki",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Lady Barron",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Wingaroo",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "Loccota",
        "postcode": "7255",
        "state": "Tasmania"
    },
    {
        "suburb": "George Town",
        "postcode": "7253",
        "state": "Tasmania"
    },
    {
        "suburb": "Low Head",
        "postcode": "7253",
        "state": "Tasmania"
    },
    {
        "suburb": "Hillwood",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Bell Bay",
        "postcode": "7253",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount Direction",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Pipers River",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Weymouth",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Stony Head",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Lulworth",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Long Reach",
        "postcode": "7253",
        "state": "Tasmania"
    },
    {
        "suburb": "Lower Turners Marsh",
        "postcode": "7267",
        "state": "Tasmania"
    },
    {
        "suburb": "Beechford",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Bellingham",
        "postcode": "7254",
        "state": "Tasmania"
    },
    {
        "suburb": "Retreat",
        "postcode": "7254",
        "state": "Tasmania"
    },
    {
        "suburb": "Lefroy",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Rheban",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Freycinet",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Bicheno",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Woodsdale",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Triabunna",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Coles Bay",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Little Swanport",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Swansea",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Apslawn",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Orford",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Dolphin Sands",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Buckland",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Runnymede",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Nugent",
        "postcode": "7172",
        "state": "Tasmania"
    },
    {
        "suburb": "Levendale",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Lake Leake",
        "postcode": "7210",
        "state": "Tasmania"
    },
    {
        "suburb": "Spring Beach",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Rocky Hills",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Pontypool",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Cranbrook",
        "postcode": "7190",
        "state": "Tasmania"
    },
    {
        "suburb": "Friendly Beaches",
        "postcode": "7215",
        "state": "Tasmania"
    },
    {
        "suburb": "Tooms Lake",
        "postcode": "7209",
        "state": "Tasmania"
    },
    {
        "suburb": "Berriedale",
        "postcode": "7011",
        "state": "Tasmania"
    },
    {
        "suburb": "Chigwell",
        "postcode": "7011",
        "state": "Tasmania"
    },
    {
        "suburb": "Austins Ferry",
        "postcode": "7011",
        "state": "Tasmania"
    },
    {
        "suburb": "Rosetta",
        "postcode": "7010",
        "state": "Tasmania"
    },
    {
        "suburb": "Glenorchy",
        "postcode": "7010",
        "state": "Tasmania"
    },
    {
        "suburb": "Montrose",
        "postcode": "7010",
        "state": "Tasmania"
    },
    {
        "suburb": "Lenah Valley",
        "postcode": "7008",
        "state": "Tasmania"
    },
    {
        "suburb": "West Moonah",
        "postcode": "7009",
        "state": "Tasmania"
    },
    {
        "suburb": "Moonah",
        "postcode": "7009",
        "state": "Tasmania"
    },
    {
        "suburb": "Derwent Park",
        "postcode": "7009",
        "state": "Tasmania"
    },
    {
        "suburb": "Goodwood",
        "postcode": "7010",
        "state": "Tasmania"
    },
    {
        "suburb": "New Town",
        "postcode": "7008",
        "state": "Tasmania"
    },
    {
        "suburb": "Lutana",
        "postcode": "7009",
        "state": "Tasmania"
    },
    {
        "suburb": "Dowsing Point",
        "postcode": "7010",
        "state": "Tasmania"
    },
    {
        "suburb": "Hobart",
        "postcode": "7000",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount Stuart",
        "postcode": "7000",
        "state": "Tasmania"
    },
    {
        "suburb": "South Hobart",
        "postcode": "7004",
        "state": "Tasmania"
    },
    {
        "suburb": "West Hobart",
        "postcode": "7000",
        "state": "Tasmania"
    },
    {
        "suburb": "North Hobart",
        "postcode": "7000",
        "state": "Tasmania"
    },
    {
        "suburb": "Sandy Bay",
        "postcode": "7005",
        "state": "Tasmania"
    },
    {
        "suburb": "Battery Point",
        "postcode": "7004",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount Nelson",
        "postcode": "7007",
        "state": "Tasmania"
    },
    {
        "suburb": "Dynnyrne",
        "postcode": "7005",
        "state": "Tasmania"
    },
    {
        "suburb": "Fern Tree",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Glebe",
        "postcode": "7000",
        "state": "Tasmania"
    },
    {
        "suburb": "Ridgeway",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Tolmans Hill",
        "postcode": "7007",
        "state": "Tasmania"
    },
    {
        "suburb": "Kingston",
        "postcode": "7050",
        "state": "Tasmania"
    },
    {
        "suburb": "Queens Domain",
        "postcode": "7000",
        "state": "Tasmania"
    },
    {
        "suburb": "Glen Huon",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Cygnet",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Ranelagh",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Port Huon",
        "postcode": "7116",
        "state": "Tasmania"
    },
    {
        "suburb": "Southport",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Cradoc",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Grove",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Charlotte Cove",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Franklin",
        "postcode": "7113",
        "state": "Tasmania"
    },
    {
        "suburb": "Nicholls Rivulet",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Lune River",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Recherche",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Dover",
        "postcode": "7117",
        "state": "Tasmania"
    },
    {
        "suburb": "Huonville",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Abels Bay",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Eggs And Bacon Bay",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Verona Sands",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Garden Island Creek",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Glaziers Bay",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Gardners Bay",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Deep Bay",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Middleton",
        "postcode": "7163",
        "state": "Tasmania"
    },
    {
        "suburb": "Wattle Grove",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Petcheys Bay",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Lymington",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Pelverata",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Upper Woodstock",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Snug",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Oyster Cove",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Lower Wattle Grove",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Woodstock",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Kaoota",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Lower Longley",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Mountain River",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Crabtree",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Lucaston",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Judbury",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Lonnavale",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Castle Forbes Bay",
        "postcode": "7116",
        "state": "Tasmania"
    },
    {
        "suburb": "Geeveston",
        "postcode": "7116",
        "state": "Tasmania"
    },
    {
        "suburb": "Glendevie",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Surges Bay",
        "postcode": "7116",
        "state": "Tasmania"
    },
    {
        "suburb": "Waterloo",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Brooks Bay",
        "postcode": "7116",
        "state": "Tasmania"
    },
    {
        "suburb": "Surveyors Bay",
        "postcode": "7116",
        "state": "Tasmania"
    },
    {
        "suburb": "Police Point",
        "postcode": "7116",
        "state": "Tasmania"
    },
    {
        "suburb": "Raminea",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Southport Lagoon",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Hastings",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Strathblane",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Ida Bay",
        "postcode": "7109",
        "state": "Tasmania"
    },
    {
        "suburb": "Randalls Bay",
        "postcode": "7112",
        "state": "Tasmania"
    },
    {
        "suburb": "Erriba",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Sheffield",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Moina",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Staverton",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Cethana",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "South Spreyton",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Stoodley",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Railton",
        "postcode": "7305",
        "state": "Tasmania"
    },
    {
        "suburb": "Acacia Hills",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Lower Barrington",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Lower Wilmot",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Wilmot",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Nook",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Gowrie Park",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Roland",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "West Kentish",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Claude Road",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Promised Land",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Sunnyside",
        "postcode": "7305",
        "state": "Tasmania"
    },
    {
        "suburb": "Paradise",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Beulah",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Nowhere Else",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Lorinna",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount Roland",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Lower Beulah",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Kimberley",
        "postcode": "7305",
        "state": "Tasmania"
    },
    {
        "suburb": "Mole Creek",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Liena",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Cradle Mountain",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Barrington",
        "postcode": "7306",
        "state": "Tasmania"
    },
    {
        "suburb": "Mersey Forest",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Merseylea",
        "postcode": "7305",
        "state": "Tasmania"
    },
    {
        "suburb": "Weegena",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "South Nietta",
        "postcode": "7315",
        "state": "Tasmania"
    },
    {
        "suburb": "Currie",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Naracoopa",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Pegarah",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Surprise Bay",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Bungaree",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Reekara",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Loorana",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Sea Elephant",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Nugara",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Grassy",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Yarra Creek",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Lymwood",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Pearshape",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Egg Lagoon",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Yambacoona",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Wickham",
        "postcode": "7256",
        "state": "Tasmania"
    },
    {
        "suburb": "Margate",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Blackmans Bay",
        "postcode": "7052",
        "state": "Tasmania"
    },
    {
        "suburb": "Kingston Beach",
        "postcode": "7050",
        "state": "Tasmania"
    },
    {
        "suburb": "Taroona",
        "postcode": "7053",
        "state": "Tasmania"
    },
    {
        "suburb": "Bonnet Hill",
        "postcode": "7053",
        "state": "Tasmania"
    },
    {
        "suburb": "Tinderbox",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Howden",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Barretta",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Lower Snug",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Coningham",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Allens Rivulet",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Electrona",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Sandfly",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Longley",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Leslie Vale",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Neika",
        "postcode": "7054",
        "state": "Tasmania"
    },
    {
        "suburb": "Huntingfield",
        "postcode": "7055",
        "state": "Tasmania"
    },
    {
        "suburb": "Alonnah",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "North Bruny",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "South Bruny",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Apollo Bay",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Adventure Bay",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Kettering",
        "postcode": "7155",
        "state": "Tasmania"
    },
    {
        "suburb": "Woodbridge",
        "postcode": "7162",
        "state": "Tasmania"
    },
    {
        "suburb": "Great Bay",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Gordon",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Simpsons Bay",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Dennes Point",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Lunawanna",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Barnes Bay",
        "postcode": "7150",
        "state": "Tasmania"
    },
    {
        "suburb": "Birchs Bay",
        "postcode": "7162",
        "state": "Tasmania"
    },
    {
        "suburb": "Flowerpot",
        "postcode": "7163",
        "state": "Tasmania"
    },
    {
        "suburb": "Port Sorell",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Shearwater",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Tarleton",
        "postcode": "7310",
        "state": "Tasmania"
    },
    {
        "suburb": "Wesley Vale",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Moriarty",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Northdown",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Squeaking Point",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Thirlstane",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Harford",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Hawley Beach",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Frankford",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Bakers Beach",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Sassafras",
        "postcode": "7307",
        "state": "Tasmania"
    },
    {
        "suburb": "Holwell",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "York Town",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Beaconsfield",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Launceston",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "West Launceston",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "South Launceston",
        "postcode": "7249",
        "state": "Tasmania"
    },
    {
        "suburb": "Summerhill",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Norwood",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Kings Meadows",
        "postcode": "7249",
        "state": "Tasmania"
    },
    {
        "suburb": "Newstead",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "East Launceston",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Ravenswood",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Waverley",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Invermay",
        "postcode": "7248",
        "state": "Tasmania"
    },
    {
        "suburb": "Rocherlea",
        "postcode": "7248",
        "state": "Tasmania"
    },
    {
        "suburb": "Mowbray",
        "postcode": "7248",
        "state": "Tasmania"
    },
    {
        "suburb": "Newnham",
        "postcode": "7248",
        "state": "Tasmania"
    },
    {
        "suburb": "Mayfield",
        "postcode": "7248",
        "state": "Tasmania"
    },
    {
        "suburb": "Windermere",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Youngtown",
        "postcode": "7249",
        "state": "Tasmania"
    },
    {
        "suburb": "Trevallyn",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Relbia",
        "postcode": "7258",
        "state": "Tasmania"
    },
    {
        "suburb": "Prospect",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Punchbowl",
        "postcode": "7249",
        "state": "Tasmania"
    },
    {
        "suburb": "St Leonards",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Dilston",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "Swan Bay",
        "postcode": "7252",
        "state": "Tasmania"
    },
    {
        "suburb": "White Hills",
        "postcode": "7258",
        "state": "Tasmania"
    },
    {
        "suburb": "Turners Marsh",
        "postcode": "7267",
        "state": "Tasmania"
    },
    {
        "suburb": "Nunamara",
        "postcode": "7259",
        "state": "Tasmania"
    },
    {
        "suburb": "Lilydale",
        "postcode": "7268",
        "state": "Tasmania"
    },
    {
        "suburb": "Myrtle Bank",
        "postcode": "7259",
        "state": "Tasmania"
    },
    {
        "suburb": "Upper Blessington",
        "postcode": "7212",
        "state": "Tasmania"
    },
    {
        "suburb": "North Lilydale",
        "postcode": "7268",
        "state": "Tasmania"
    },
    {
        "suburb": "Patersonia",
        "postcode": "7259",
        "state": "Tasmania"
    },
    {
        "suburb": "Bangor",
        "postcode": "7267",
        "state": "Tasmania"
    },
    {
        "suburb": "Karoola",
        "postcode": "7267",
        "state": "Tasmania"
    },
    {
        "suburb": "Tunnel",
        "postcode": "7254",
        "state": "Tasmania"
    },
    {
        "suburb": "Lebrina",
        "postcode": "7254",
        "state": "Tasmania"
    },
    {
        "suburb": "Underwood",
        "postcode": "7268",
        "state": "Tasmania"
    },
    {
        "suburb": "Targa",
        "postcode": "7259",
        "state": "Tasmania"
    },
    {
        "suburb": "Blessington",
        "postcode": "7212",
        "state": "Tasmania"
    },
    {
        "suburb": "Burns Creek",
        "postcode": "7212",
        "state": "Tasmania"
    },
    {
        "suburb": "Travellers Rest",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Blackstone Heights",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Prospect Vale",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Hadspen",
        "postcode": "7290",
        "state": "Tasmania"
    },
    {
        "suburb": "Weetah",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Dunorlan",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Bracknell",
        "postcode": "7302",
        "state": "Tasmania"
    },
    {
        "suburb": "Exton",
        "postcode": "7303",
        "state": "Tasmania"
    },
    {
        "suburb": "Westbury",
        "postcode": "7303",
        "state": "Tasmania"
    },
    {
        "suburb": "Elizabeth Town",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Deloraine",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Quamby Brook",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Parkham",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Chudleigh",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Meander",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Carrick",
        "postcode": "7291",
        "state": "Tasmania"
    },
    {
        "suburb": "Caveside",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Hagley",
        "postcode": "7292",
        "state": "Tasmania"
    },
    {
        "suburb": "Rosevale",
        "postcode": "7292",
        "state": "Tasmania"
    },
    {
        "suburb": "Quamby Bend",
        "postcode": "7292",
        "state": "Tasmania"
    },
    {
        "suburb": "Oaks",
        "postcode": "7303",
        "state": "Tasmania"
    },
    {
        "suburb": "Osmaston",
        "postcode": "7303",
        "state": "Tasmania"
    },
    {
        "suburb": "Reedy Marsh",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Birralee",
        "postcode": "7303",
        "state": "Tasmania"
    },
    {
        "suburb": "Moltema",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Montana",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Golden Valley",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Western Creek",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Mayberry",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Westwood",
        "postcode": "7292",
        "state": "Tasmania"
    },
    {
        "suburb": "Selbourne",
        "postcode": "7292",
        "state": "Tasmania"
    },
    {
        "suburb": "Liffey",
        "postcode": "7301",
        "state": "Tasmania"
    },
    {
        "suburb": "Jackeys Marsh",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Whitemore",
        "postcode": "7303",
        "state": "Tasmania"
    },
    {
        "suburb": "Cluan",
        "postcode": "7303",
        "state": "Tasmania"
    },
    {
        "suburb": "Needles",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Red Hills",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Dairy Plains",
        "postcode": "7304",
        "state": "Tasmania"
    },
    {
        "suburb": "Longford",
        "postcode": "7301",
        "state": "Tasmania"
    },
    {
        "suburb": "Perth",
        "postcode": "7300",
        "state": "Tasmania"
    },
    {
        "suburb": "Evandale",
        "postcode": "7212",
        "state": "Tasmania"
    },
    {
        "suburb": "Devon Hills",
        "postcode": "7300",
        "state": "Tasmania"
    },
    {
        "suburb": "Western Junction",
        "postcode": "7212",
        "state": "Tasmania"
    },
    {
        "suburb": "Breadalbane",
        "postcode": "7258",
        "state": "Tasmania"
    },
    {
        "suburb": "Ross",
        "postcode": "7209",
        "state": "Tasmania"
    },
    {
        "suburb": "Deddington",
        "postcode": "7212",
        "state": "Tasmania"
    },
    {
        "suburb": "Poatina",
        "postcode": "7302",
        "state": "Tasmania"
    },
    {
        "suburb": "Avoca",
        "postcode": "7213",
        "state": "Tasmania"
    },
    {
        "suburb": "Cressy",
        "postcode": "7302",
        "state": "Tasmania"
    },
    {
        "suburb": "Bishopsbourne",
        "postcode": "7301",
        "state": "Tasmania"
    },
    {
        "suburb": "Rossarden",
        "postcode": "7213",
        "state": "Tasmania"
    },
    {
        "suburb": "Blackwood Creek",
        "postcode": "7301",
        "state": "Tasmania"
    },
    {
        "suburb": "Campbell Town",
        "postcode": "7210",
        "state": "Tasmania"
    },
    {
        "suburb": "Nile",
        "postcode": "7212",
        "state": "Tasmania"
    },
    {
        "suburb": "Tunbridge",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Conara",
        "postcode": "7211",
        "state": "Tasmania"
    },
    {
        "suburb": "Epping Forest",
        "postcode": "7211",
        "state": "Tasmania"
    },
    {
        "suburb": "Powranna",
        "postcode": "7300",
        "state": "Tasmania"
    },
    {
        "suburb": "Toiberry",
        "postcode": "7301",
        "state": "Tasmania"
    },
    {
        "suburb": "Lemont",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Primrose Sands",
        "postcode": "7173",
        "state": "Tasmania"
    },
    {
        "suburb": "Sorell",
        "postcode": "7172",
        "state": "Tasmania"
    },
    {
        "suburb": "Carlton",
        "postcode": "7173",
        "state": "Tasmania"
    },
    {
        "suburb": "Lewisham",
        "postcode": "7173",
        "state": "Tasmania"
    },
    {
        "suburb": "Dodges Ferry",
        "postcode": "7173",
        "state": "Tasmania"
    },
    {
        "suburb": "Carlton River",
        "postcode": "7173",
        "state": "Tasmania"
    },
    {
        "suburb": "Pawleena",
        "postcode": "7172",
        "state": "Tasmania"
    },
    {
        "suburb": "Connellys Marsh",
        "postcode": "7173",
        "state": "Tasmania"
    },
    {
        "suburb": "Forcett",
        "postcode": "7173",
        "state": "Tasmania"
    },
    {
        "suburb": "Copping",
        "postcode": "7174",
        "state": "Tasmania"
    },
    {
        "suburb": "Wattle Hill",
        "postcode": "7172",
        "state": "Tasmania"
    },
    {
        "suburb": "Midway Point",
        "postcode": "7171",
        "state": "Tasmania"
    },
    {
        "suburb": "Dunalley",
        "postcode": "7177",
        "state": "Tasmania"
    },
    {
        "suburb": "Kellevie",
        "postcode": "7176",
        "state": "Tasmania"
    },
    {
        "suburb": "Boomer Bay",
        "postcode": "7177",
        "state": "Tasmania"
    },
    {
        "suburb": "Marion Bay",
        "postcode": "7175",
        "state": "Tasmania"
    },
    {
        "suburb": "Bream Creek",
        "postcode": "7175",
        "state": "Tasmania"
    },
    {
        "suburb": "Oatlands",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Tiberias",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Baden",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Colebrook",
        "postcode": "7027",
        "state": "Tasmania"
    },
    {
        "suburb": "Kempton",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount Seymour",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Mangalore",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Bagdad",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Stonehenge",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Jericho",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Rhyndaston",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Antill Ponds",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "York Plains",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Parattah",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Dysart",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Pawtella",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Andover",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Whitefoord",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Stonor",
        "postcode": "7119",
        "state": "Tasmania"
    },
    {
        "suburb": "Tunnack",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Swanston",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Elderslie",
        "postcode": "7030",
        "state": "Tasmania"
    },
    {
        "suburb": "Woodbury",
        "postcode": "7120",
        "state": "Tasmania"
    },
    {
        "suburb": "Cape Pillar",
        "postcode": "7182",
        "state": "Tasmania"
    },
    {
        "suburb": "Port Arthur",
        "postcode": "7182",
        "state": "Tasmania"
    },
    {
        "suburb": "Nubeena",
        "postcode": "7184",
        "state": "Tasmania"
    },
    {
        "suburb": "White Beach",
        "postcode": "7184",
        "state": "Tasmania"
    },
    {
        "suburb": "Saltwater River",
        "postcode": "7186",
        "state": "Tasmania"
    },
    {
        "suburb": "Taranna",
        "postcode": "7180",
        "state": "Tasmania"
    },
    {
        "suburb": "Eaglehawk Neck",
        "postcode": "7179",
        "state": "Tasmania"
    },
    {
        "suburb": "Koonya",
        "postcode": "7187",
        "state": "Tasmania"
    },
    {
        "suburb": "Fortescue",
        "postcode": "7182",
        "state": "Tasmania"
    },
    {
        "suburb": "Premaydena",
        "postcode": "7185",
        "state": "Tasmania"
    },
    {
        "suburb": "Cape Raoul",
        "postcode": "7184",
        "state": "Tasmania"
    },
    {
        "suburb": "Murdunna",
        "postcode": "7178",
        "state": "Tasmania"
    },
    {
        "suburb": "Sloping Main",
        "postcode": "7186",
        "state": "Tasmania"
    },
    {
        "suburb": "Highcroft",
        "postcode": "7183",
        "state": "Tasmania"
    },
    {
        "suburb": "Stormlea",
        "postcode": "7184",
        "state": "Tasmania"
    },
    {
        "suburb": "Wynyard",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Somerset",
        "postcode": "7322",
        "state": "Tasmania"
    },
    {
        "suburb": "Sisters Beach",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Boat Harbour",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Table Cape",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Flowerdale",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Moorleah",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Elliott",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Calder",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Mount Hicks",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Boat Harbour Beach",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Oldina",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Doctors Rocks",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Savage River",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Waratah",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Guildford",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Takone",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Preolenna",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Luina",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Myalla",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Henrietta",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Lapoinya",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Yolla",
        "postcode": "7325",
        "state": "Tasmania"
    },
    {
        "suburb": "Queenstown",
        "postcode": "7467",
        "state": "Tasmania"
    },
    {
        "suburb": "Strahan",
        "postcode": "7468",
        "state": "Tasmania"
    },
    {
        "suburb": "Rosebery",
        "postcode": "7469",
        "state": "Tasmania"
    },
    {
        "suburb": "Renison Bell",
        "postcode": "7469",
        "state": "Tasmania"
    },
    {
        "suburb": "Grindelwald",
        "postcode": "7277",
        "state": "Tasmania"
    },
    {
        "suburb": "Cairns Bay",
        "postcode": "7116",
        "state": "Tasmania"
    },
    {
        "suburb": "Pyengana",
        "postcode": "7216",
        "state": "Tasmania"
    },
    {
        "suburb": "Riverside",
        "postcode": "7250",
        "state": "Tasmania"
    },
    {
        "suburb": "Ben Lomond",
        "postcode": "7212",
        "state": "Tasmania"
    },
    {
        "suburb": "Lalla",
        "postcode": "7267",
        "state": "Tasmania"
    },
    {
        "suburb": "Zeehan",
        "postcode": "7469",
        "state": "Tasmania"
    },
    {
        "suburb": "Exeter",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Gormanston",
        "postcode": "7466",
        "state": "Tasmania"
    },
    {
        "suburb": "Granville Harbour",
        "postcode": "7469",
        "state": "Tasmania"
    },
    {
        "suburb": "Legana",
        "postcode": "7277",
        "state": "Tasmania"
    },
    {
        "suburb": "Tullah",
        "postcode": "7321",
        "state": "Tasmania"
    },
    {
        "suburb": "Gravelly Beach",
        "postcode": "7276",
        "state": "Tasmania"
    },
    {
        "suburb": "Lake Margaret",
        "postcode": "7467",
        "state": "Tasmania"
    },
    {
        "suburb": "Trial Harbour",
        "postcode": "7469",
        "state": "Tasmania"
    },
    {
        "suburb": "Beauty Point",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Kelso",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Kayena",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Greens Beach",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Lanena",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Blackwall",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Clarence Point",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Rosevears",
        "postcode": "7277",
        "state": "Tasmania"
    },
    {
        "suburb": "Swan Point",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Deviot",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Sidmouth",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Winkleigh",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Loira",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Robigana",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Badger Head",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Rowella",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Bridgenorth",
        "postcode": "7277",
        "state": "Tasmania"
    },
    {
        "suburb": "Glengarry",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Flowery Gully",
        "postcode": "7270",
        "state": "Tasmania"
    },
    {
        "suburb": "Notley Hills",
        "postcode": "7275",
        "state": "Tasmania"
    },
    {
        "suburb": "Lisle",
        "postcode": "7260",
        "state": "Tasmania"
    },
    {
        "suburb": "Cleveland",
        "postcode": "7211",
        "state": "Tasmania"
    },
    {
        "suburb": "Ciccone",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Stuart",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Irlpme",
        "postcode": "874",
        "state": "Northern Territory"
    },
    {
        "suburb": "Braitling",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Araluen",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "East Side",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Alice Springs",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ross",
        "postcode": "873",
        "state": "Northern Territory"
    },
    {
        "suburb": "Flynn",
        "postcode": "875",
        "state": "Northern Territory"
    },
    {
        "suburb": "Kilgariff",
        "postcode": "873",
        "state": "Northern Territory"
    },
    {
        "suburb": "Arumbera",
        "postcode": "873",
        "state": "Northern Territory"
    },
    {
        "suburb": "Connellan",
        "postcode": "873",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ilparpa",
        "postcode": "873",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gillen",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Larapinta",
        "postcode": "875",
        "state": "Northern Territory"
    },
    {
        "suburb": "Desert Springs",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Sadadeen",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Mount Johns",
        "postcode": "874",
        "state": "Northern Territory"
    },
    {
        "suburb": "The Gap",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Alpurrurulam",
        "postcode": "4825",
        "state": "Northern Territory"
    },
    {
        "suburb": "Angurugu",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Anindilyakwa",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Anmatjere",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Laramba",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Engawala",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wilora",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ti Tree",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Atitjere",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Hart",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Belyuen",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Binjari",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Borroloola",
        "postcode": "854",
        "state": "Northern Territory"
    },
    {
        "suburb": "Adelaide River",
        "postcode": "846",
        "state": "Northern Territory"
    },
    {
        "suburb": "Darwin River Dam",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Lake Bennett",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Batchelor",
        "postcode": "845",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tortilla Flats",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Coomalie Creek",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Camp Creek",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Finniss Valley",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Collett Creek",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Rum Jungle",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Stapleton",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Eva Valley",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Dundee Downs",
        "postcode": "840",
        "state": "Northern Territory"
    },
    {
        "suburb": "Dundee Beach",
        "postcode": "840",
        "state": "Northern Territory"
    },
    {
        "suburb": "Dundee Forest",
        "postcode": "840",
        "state": "Northern Territory"
    },
    {
        "suburb": "Bynoe Harbour",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Bynoe",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wagait Beach",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Cox Peninsula",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Litchfield Park",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Rakula",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Mandorah",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Charlotte",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Daguragu",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Kalkarindji",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Burrundie",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Claravale",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Daly River",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Nemarluk",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tipperary",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Douglas-Daly",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Peppimenarti",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Maranunga",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Nganmarriyanga",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Alawa",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Anula",
        "postcode": "812",
        "state": "Northern Territory"
    },
    {
        "suburb": "Woolner",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Bayview",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Brinkin",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Darwin City",
        "postcode": "800",
        "state": "Northern Territory"
    },
    {
        "suburb": "Coconut Grove",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Fannie Bay",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Jingili",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Karama",
        "postcode": "812",
        "state": "Northern Territory"
    },
    {
        "suburb": "Larrakeyah",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Leanyer",
        "postcode": "812",
        "state": "Northern Territory"
    },
    {
        "suburb": "Lee Point",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Lyons",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Buffalo Creek",
        "postcode": "812",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ludmilla",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Malak",
        "postcode": "812",
        "state": "Northern Territory"
    },
    {
        "suburb": "Marrara",
        "postcode": "812",
        "state": "Northern Territory"
    },
    {
        "suburb": "Millner",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Moil",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Nakara",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Casuarina",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "The Narrows",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Nightcliff",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Parap",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Rapid Creek",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Stuart Park",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "The Gardens",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tiwi",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wagaman",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wanguri",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Winnellie",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wulagi",
        "postcode": "812",
        "state": "Northern Territory"
    },
    {
        "suburb": "Berrimah",
        "postcode": "828",
        "state": "Northern Territory"
    },
    {
        "suburb": "Eaton",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Coonawarra",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Hidden Valley",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "East Arm",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Charles Darwin",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tivendale",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "East Arnhem",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Nhulunbuy",
        "postcode": "880",
        "state": "Northern Territory"
    },
    {
        "suburb": "Galiwinku",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Numburindi",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gapuwiyak",
        "postcode": "880",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ramingining",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Yirrkala",
        "postcode": "880",
        "state": "Northern Territory"
    },
    {
        "suburb": "West Arnhem",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Newcastle Waters",
        "postcode": "862",
        "state": "Northern Territory"
    },
    {
        "suburb": "Elliott",
        "postcode": "862",
        "state": "Northern Territory"
    },
    {
        "suburb": "Venn",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Edith",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Flying Fox",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Florina",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Manbulloo",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Sturt Plateau",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Larrimah",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Bulman Weemol",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Daly Waters",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Elsey",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wilton",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Pine Creek",
        "postcode": "847",
        "state": "Northern Territory"
    },
    {
        "suburb": "Limmen",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Durack",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Calvert",
        "postcode": "862",
        "state": "Northern Territory"
    },
    {
        "suburb": "Kakadu",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Jabiru",
        "postcode": "886",
        "state": "Northern Territory"
    },
    {
        "suburb": "Berry Springs",
        "postcode": "838",
        "state": "Northern Territory"
    },
    {
        "suburb": "Rosebery",
        "postcode": "832",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tennant Creek",
        "postcode": "860",
        "state": "Northern Territory"
    },
    {
        "suburb": "Marrakai",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Margaret River",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gunn",
        "postcode": "832",
        "state": "Northern Territory"
    },
    {
        "suburb": "Yarrawonga",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Bees Creek",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Alyangula",
        "postcode": "885",
        "state": "Northern Territory"
    },
    {
        "suburb": "Point Stuart",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Virginia",
        "postcode": "834",
        "state": "Northern Territory"
    },
    {
        "suburb": "Yulara",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Birdum",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Koolpinyah",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Bellamack",
        "postcode": "832",
        "state": "Northern Territory"
    },
    {
        "suburb": "Johnston",
        "postcode": "832",
        "state": "Northern Territory"
    },
    {
        "suburb": "Sandover",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Muirhead",
        "postcode": "810",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gunbalanya",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Zuccoli",
        "postcode": "832",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tablelands",
        "postcode": "862",
        "state": "Northern Territory"
    },
    {
        "suburb": "Lajamanu",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Katherine South",
        "postcode": "850",
        "state": "Northern Territory"
    },
    {
        "suburb": "Warumungu",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Farrar",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ampilatwatja",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Baines",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Nitmiluk",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Hotham",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Cossack",
        "postcode": "850",
        "state": "Northern Territory"
    },
    {
        "suburb": "Humpty Doo",
        "postcode": "836",
        "state": "Northern Territory"
    },
    {
        "suburb": "White Gums",
        "postcode": "870",
        "state": "Northern Territory"
    },
    {
        "suburb": "Undoolya",
        "postcode": "874",
        "state": "Northern Territory"
    },
    {
        "suburb": "Petermann",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Timber Creek",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "McArthur",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Coolalinga",
        "postcode": "839",
        "state": "Northern Territory"
    },
    {
        "suburb": "Mount Zeil",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Hale",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Delamere",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Hugh",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ghan",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Yuendumu",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Victoria River",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Milingimbi",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Minjilang",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Maningrida",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Blackmore",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gregory",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Umbakumba",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Milyakburra",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Pellew Islands",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Robinson River",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Willowra",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Yuelamu",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Jilkminggan",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Emungalan",
        "postcode": "850",
        "state": "Northern Territory"
    },
    {
        "suburb": "Katherine",
        "postcode": "850",
        "state": "Northern Territory"
    },
    {
        "suburb": "Katherine East",
        "postcode": "850",
        "state": "Northern Territory"
    },
    {
        "suburb": "Lansdowne",
        "postcode": "850",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tindal",
        "postcode": "853",
        "state": "Northern Territory"
    },
    {
        "suburb": "Uralla",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Knuckey Lagoon",
        "postcode": "828",
        "state": "Northern Territory"
    },
    {
        "suburb": "Micket Creek",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Holtze",
        "postcode": "829",
        "state": "Northern Territory"
    },
    {
        "suburb": "Shoal Bay",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Middle Point",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Weddell",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wickham",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tumbling Waters",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Lambells Lagoon",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Livingstone",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Darwin River",
        "postcode": "841",
        "state": "Northern Territory"
    },
    {
        "suburb": "Fly Creek",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Girraween",
        "postcode": "836",
        "state": "Northern Territory"
    },
    {
        "suburb": "Murrumujuk",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Howard Springs",
        "postcode": "835",
        "state": "Northern Territory"
    },
    {
        "suburb": "McMinns Lagoon",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Channel Island",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Acacia Hills",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Black Jungle",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wak Wak",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Lloyd Creek",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Glyde Point",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Noonamah",
        "postcode": "837",
        "state": "Northern Territory"
    },
    {
        "suburb": "Freds Pass",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Southport",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Herbert",
        "postcode": "836",
        "state": "Northern Territory"
    },
    {
        "suburb": "Daly",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Manton",
        "postcode": "837",
        "state": "Northern Territory"
    },
    {
        "suburb": "Hughes",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gunn Point",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Santa Teresa",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gunyangara",
        "postcode": "880",
        "state": "Northern Territory"
    },
    {
        "suburb": "Mataranka",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Nauiyu",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Numbulwar",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gulung Mardrulk",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Barunga",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Beswick",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Beswick Creek",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Bakewell",
        "postcode": "832",
        "state": "Northern Territory"
    },
    {
        "suburb": "Driver",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gray",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Palmerston City",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Moulden",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Woodroffe",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Archer",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Marlow Lagoon",
        "postcode": "830",
        "state": "Northern Territory"
    },
    {
        "suburb": "Pinelands",
        "postcode": "829",
        "state": "Northern Territory"
    },
    {
        "suburb": "Mitchell",
        "postcode": "832",
        "state": "Northern Territory"
    },
    {
        "suburb": "Simpson",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Kaltukatjara",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Imanpa",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Finke",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Mutitjulu",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Anatye",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Amoonguna",
        "postcode": "873",
        "state": "Northern Territory"
    },
    {
        "suburb": "Burt Plain",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Mount Bundey",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Vernon Islands",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Robin Falls",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Van Diemen Gulf",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Nicholson",
        "postcode": "862",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ranken",
        "postcode": "4825",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tanami East",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Pamayu",
        "postcode": "862",
        "state": "Northern Territory"
    },
    {
        "suburb": "Creswell",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Areyonga",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Nyirripi",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Haasts Bluff",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Hermannsburg",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Papunya",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Kintore",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Lake Mackay",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Mereenie",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Kunparrka",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Namatjira",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tanami",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Titjikala",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Canteen Creek",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wutunugurra",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tara",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ali Curung",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Davenport",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Costello",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Thamarrurr",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wadeye",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Tiwi Islands",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wurrumiyanga",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Milikapiti",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Pirlangimpi",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Buchanan",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Bradshaw",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Top Springs",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Gurindji",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Pigeon Hole",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Yarralin",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wallace Rockhole",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Mount Liebig",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Warruwi",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Cobourg",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Chilla Well",
        "postcode": "872",
        "state": "Northern Territory"
    },
    {
        "suburb": "Arnold",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Ngukurr",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "Miniyeri",
        "postcode": "852",
        "state": "Northern Territory"
    },
    {
        "suburb": "East Point",
        "postcode": "820",
        "state": "Northern Territory"
    },
    {
        "suburb": "Holmes",
        "postcode": "812",
        "state": "Northern Territory"
    },
    {
        "suburb": "Wishart",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "Elrundie",
        "postcode": "822",
        "state": "Northern Territory"
    },
    {
        "suburb": "ACT Remainder - Molonglo Valley",
        "postcode": "2601",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Belconnen",
        "postcode": "2601",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Acton",
        "postcode": "2601",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Ainslie",
        "postcode": "2602",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Amaroo",
        "postcode": "2914",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Gungahlin",
        "postcode": "2912",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Aranda",
        "postcode": "2614",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Banks",
        "postcode": "2906",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Barton",
        "postcode": "2600",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Belconnen",
        "postcode": "2617",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Bruce",
        "postcode": "2617",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Bonython",
        "postcode": "2905",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Braddon",
        "postcode": "2612",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Calwell",
        "postcode": "2905",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Isabella Plains",
        "postcode": "2905",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Campbell",
        "postcode": "2612",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Chapman",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Charnwood",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Chifley",
        "postcode": "2606",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Chisholm",
        "postcode": "2905",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "City",
        "postcode": "2601",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Conder",
        "postcode": "2906",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Cook",
        "postcode": "2614",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Curtin",
        "postcode": "2605",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Deakin",
        "postcode": "2600",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Dickson",
        "postcode": "2602",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Downer",
        "postcode": "2602",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Duffy",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Dunlop",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Evatt",
        "postcode": "2617",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Fadden",
        "postcode": "2904",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Farrer",
        "postcode": "2607",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Fisher",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Florey",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Flynn",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Forrest",
        "postcode": "2603",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Fraser",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Fyshwick",
        "postcode": "2609",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Garran",
        "postcode": "2605",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Gilmore",
        "postcode": "2905",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Giralang",
        "postcode": "2617",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Gordon",
        "postcode": "2906",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Gowrie",
        "postcode": "2904",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Greenway",
        "postcode": "2900",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Griffith",
        "postcode": "2603",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Harrison",
        "postcode": "2914",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Gungahlin",
        "postcode": "2912",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Franklin",
        "postcode": "2913",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Crace",
        "postcode": "2911",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Hackett",
        "postcode": "2602",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Hall",
        "postcode": "2618",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Jerrabomberra",
        "postcode": "2600",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Hawker",
        "postcode": "2614",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Higgins",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Holder",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Holt",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Hughes",
        "postcode": "2605",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Hume",
        "postcode": "2620",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Isaacs",
        "postcode": "2607",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Beard",
        "postcode": "2620",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Kaleen",
        "postcode": "2617",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Kambah",
        "postcode": "2902",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Kingston",
        "postcode": "2604",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Kowen",
        "postcode": "2620",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Latham",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Lyneham",
        "postcode": "2602",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Lyons",
        "postcode": "2606",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "McKellar",
        "postcode": "2617",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Lawson",
        "postcode": "2617",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Macarthur",
        "postcode": "2904",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Macgregor",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Macquarie",
        "postcode": "2614",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Canberra Airport",
        "postcode": "2609",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Majura",
        "postcode": "2609",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Mawson",
        "postcode": "2607",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Melba",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Mitchell",
        "postcode": "2911",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Monash",
        "postcode": "2904",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Narrabundah",
        "postcode": "2604",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Ngunnawal",
        "postcode": "2913",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Nicholls",
        "postcode": "2913",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Oaks Estate",
        "postcode": "2620",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "O'Connor",
        "postcode": "2602",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "O'Malley",
        "postcode": "2606",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Oxley",
        "postcode": "2903",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Page",
        "postcode": "2614",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Palmerston",
        "postcode": "2913",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Parkes",
        "postcode": "2600",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Capital Hill",
        "postcode": "2600",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Pearce",
        "postcode": "2607",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Phillip",
        "postcode": "2606",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Red Hill",
        "postcode": "2603",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Reid",
        "postcode": "2612",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Richardson",
        "postcode": "2905",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Rivett",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Russell",
        "postcode": "2600",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Scullin",
        "postcode": "2614",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Spence",
        "postcode": "2615",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Stirling",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Stromlo",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Tuggeranong",
        "postcode": "2900",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Symonston",
        "postcode": "2609",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Theodore",
        "postcode": "2905",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Torrens",
        "postcode": "2607",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Turner",
        "postcode": "2612",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Wanniassa",
        "postcode": "2903",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Waramanga",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Watson",
        "postcode": "2602",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Weetangera",
        "postcode": "2614",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Weston",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Weston Creek",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Yarralumla",
        "postcode": "2600",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Cotter River",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Rendezvous Creek",
        "postcode": "2620",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Booth",
        "postcode": "2620",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Bonner",
        "postcode": "2914",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Forde",
        "postcode": "2914",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Casey",
        "postcode": "2913",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Pialligo",
        "postcode": "2609",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Moncrieff",
        "postcode": "2914",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Paddys River",
        "postcode": "2620",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Wright",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Coree",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Coombs",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Throsby",
        "postcode": "2914",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Jacka",
        "postcode": "2914",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Tharwa",
        "postcode": "2620",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "ACT Remainder - Hall",
        "postcode": "2618",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Uriarra Village",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Molonglo",
        "postcode": "2617",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Denman Prospect",
        "postcode": "2611",
        "state": "Australian Capital Territory"
    },
    {
        "suburb": "Jervis Bay",
        "postcode": "2540",
        "state": "Other Territories"
    },
    {
        "suburb": "Christmas Island",
        "postcode": "6798",
        "state": "Other Territories"
    },
    {
        "suburb": "Home Island",
        "postcode": "6799",
        "state": "Other Territories"
    },
    {
        "suburb": "West Island",
        "postcode": "6799",
        "state": "Other Territories"
    },
    {
        "suburb": "Norfolk Island",
        "postcode": "2899",
        "state": "Other Territories"
    }
]