import {ReferenceField, Show, SimpleShowLayout, TextField} from "react-admin";
import * as React from "react";
import CustomDateTimeField from "../CustomFields/CustomDateTimeField";

const CustomAside = ({saving, save, ...props}) => (
    <div style={{width: 300, margin: '0 1em'}}>
        <Show {...props} actions={<React.Fragment/>} title={<React.Fragment/>}>
            <SimpleShowLayout>
                <ReferenceField source="createdBy" reference="users" linkType="">
                    <TextField source="username"/>
                </ReferenceField>
                <ReferenceField source="updatedBy" reference="users" linkType="">
                    <TextField source="username"/>
                </ReferenceField>
                <CustomDateTimeField source="createdAt"/>
                <CustomDateTimeField source="updatedAt"/>
            </SimpleShowLayout>
        </Show>
    </div>
);

export default CustomAside;