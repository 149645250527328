import {config} from './config/config'

const authProvider = {
    login: ({username, password}) => {
        return new Promise((resolve, reject) => {
            const data = {
                client_id: config.client_id,
                client_secret: config.client_secret,
                grant_type: 'password',
                username,
                password
            };

            let formBody = [];
            for (let property in data) {
                let encodedKey = encodeURIComponent(property);
                let encodedValue = encodeURIComponent(data[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            const request = new Request(`${config.oauthUrl}/oauth/token`, {
                method: 'POST',
                headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`}),
                body: formBody
            });
            fetch(request).then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(response);
                }
                return response.json()
            }).then(response => {
                console.log("addon_",response.data);
                localStorage.setItem('addon_user', JSON.stringify(response.data.user));
                localStorage.setItem('addon_token', response.data.access_token);
                
                resolve();
            }).catch((err) => {
                reject('Invalid username or password')
            });
        });
    },
    logout: () => {
        localStorage.removeItem('addon_user');
        localStorage.removeItem('addon_token');
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('addon_user');
            localStorage.removeItem('addon_token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('addon_token')
        ? Promise.resolve()
        : Promise.reject(),
    getPermissions: () => {
        const user = JSON.parse(localStorage.getItem('addon_user'));
        return user ? Promise.resolve(user) : Promise.reject();
    },
    getIdentity: () => {
        const user = JSON.parse(localStorage.getItem('addon_user'));
        if (user) {
            user.fullName = `${user.firstName} ${user.lastName}`;
            return Promise.resolve(user);
        }
        return Promise.reject();
    }
};

export default authProvider;