import * as React from "react";
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    email,
    maxLength,
    minLength,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    DateInput,
    PasswordInput,
    ImageInput,
    ImageField,
    NumberInput,
    FileInput,
    FileField
} from 'react-admin';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import {SUBURBS} from "../../constants/suburbs";
import { useFormState } from 'react-final-form';

const SectionTitle = ({label}) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};

export const styles = {
    inline: {display: 'inline-block', margin: '0 16px'},
    uploadSection: {background: 'aliceblue', borderRadius: '12px', border: '1px dashed darkcyan', display: 'inline-block', margin: '0 16px'}
};

const useStyles = makeStyles(styles);

const PostalCodeInput = props => {
    const { values } = useFormState();
    let choices;
    if (values.suburb) {
        SUBURBS.forEach((item) => {
            if (values.suburb === item.suburb) {
                choices = item
            }
        })
    }

    return (
        <TextInput
            initialValue={choices ? choices.postcode : undefined}
            validate={[required()]}
            {...props}
        />
    );
};

const StateInput = props => {
    const { values } = useFormState();
    let choices;
    if (values.suburb) {
        SUBURBS.forEach((item) => {
            if (values.suburb === item.suburb) {
                choices = item
            }
        })
    }

    return (
        <TextInput
            initialValue={choices ? choices.state : undefined}
            validate={[required()]}
            {...props}
        />
    );
};

export const UserCreate = props => {
    const classes = useStyles(props);

    return (
        <Create {...props} title="User Manager - Create User">
            <SimpleForm
                variant="outlined"
                redirect={'/users'}
            >
                <SectionTitle label="Identity"/>
                <ReferenceInput
                    source="roleId"
                    reference="roles"
                    label="User Type"
                    validate={[required()]}
                    formClassName={classes.inline}
                ><SelectInput optionText="name"/>
                </ReferenceInput>
                <TextInput source="username" validate={[required()]} formClassName={classes.inline}/>
                <TextInput source="firstName" validate={[required()]} formClassName={classes.inline}/>
                <TextInput source="lastName" validate={[required()]} formClassName={classes.inline}/>
                <DateInput source="dateOfBirth" formClassName={classes.inline}/>
                <PasswordInput source="password" validate={[required()]} formClassName={classes.inline}/>
                <SectionTitle label="Contact Details"/>
                <TextInput source="email" validate={[required(), email()]} formClassName={classes.inline}/>
                <TextInput source="phoneNumber" validate={[required(), minLength(10), maxLength(12)]} formClassName={classes.inline}/>
                <TextInput multiline label="Address" source="address" validate={[required()]} formClassName={classes.inline}/>
                <AutocompleteInput
                    source="suburb"
                    optionText="suburb"
                    resettable={true}
                    clearAlwaysVisible={true}
                    suggestionLimit={10}
                    shouldRenderSuggestions={(val) => { return val.trim().length > 3 }}
                    optionValue="suburb"
                    choices={SUBURBS}
                    formClassName={classes.inline}
                    validate={[required()]}
                />
                <PostalCodeInput source="postalCode" formClassName={classes.inline}/>
                <StateInput source="state" formClassName={classes.inline}/>
                <SectionTitle label="Business Details"/>
                <TextInput source="businessName" validate={[required()]} formClassName={classes.inline}/>
                <TextInput label="ABN" source="ABN" validate={[required()]} formClassName={classes.inline}/>
                <TextInput label="ACN" source="ACN" formClassName={classes.inline}/>
                <NumberInput source="commissionPercentage" min={0} max={100} formClassName={classes.inline}/>
                <NumberInput source="flatRate" min={0} formClassName={classes.inline}/>
                <SectionTitle label="Files"/>
                <ImageInput source="avatar" label="Avatar" accept="image/*" formClassName={classes.uploadSection}>
                    <ImageField source="avatar" title="title"/>
                </ImageInput>
                <FileInput source="files" label="Related files" formClassName={classes.uploadSection} multiple={true}>
                    <FileField source="src" title="title" />
                </FileInput>
                <SectionTitle label="Admin section"/>
                <RichTextInput label="Note" source="adminNote" fullWidth={false} formClassName={classes.inline}/>
                <BooleanInput source="isActive" label="(Inactive/Active)" formClassName={classes.inline}/>
            </SimpleForm>
        </Create>
    );
};