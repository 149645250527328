import * as React from 'react';
import {Pie} from 'react-chartjs-2';
import {Card} from '@material-ui/core';

export const Piechart = (props) => {
    const files = props.files;
    return (
        <Card>
            <div className="chart">
                <Pie
                    data={{
                        labels: ['PENDING', 'ACCEPTED', 'SETTLED', 'PAID', 'DECLINED'],
                        datasets: [{
                            label: 'file',
                            data: [
                                files.pending, files.accepted, files.settled, files.paid, files.declined
                            ],
                            backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(75, 192, 192, 0.6)', 'rgba(255, 159, 64, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)']
                        }]
                    }}
                    options={{
                        title: {
                            display: true,
                            text: props.tittle,
                            fontSize: 25
                        },
                        legend: {
                            display: true,
                            position: 'right'
                        }
                    }}
                />
            </div>
        </Card>
    )
};